<div class="summary-table">
  <div class="summary-table-col">
    <div class="summary-table-item grid-column-1 col-img">
      <ng-container *ngIf="isHw(); else non_hw">
        <img
          *ngIf="product.picturePreview"
          height="auto"
          width="50px"
          src="{{ product.picturePreview.href }}"
          alt="{{ product.name }}" />
      </ng-container>
      <ng-template #non_hw>
        <ng-container *ngIf="modify || productInShoppingCart.replacementFor">
          <img src="/assets/img/products/tariff_change_product_image.png" height="auto" width="50px" />
        </ng-container>
        <ng-container *ngIf="delete">
          <img src="/assets/img/products/remove_product_image.png" height="auto" width="50px" />
        </ng-container>
        <ng-container *ngIf="!modify && !delete && !productInShoppingCart.replacementFor">
          <img
            *ngIf="product.picturePreview"
            height="auto"
            width="50px"
            src="{{ product.picturePreview.href }}"
            alt="{{ product.name }}" />
          <img
            *ngIf="!product.picturePreview"
            src="/assets/img/products/tariff_default_product_image.png"
            height="auto"
            width="50px" />
        </ng-container>
      </ng-template>
    </div>
    <div class="summary-table-item grid-column-2 left">{{ product.name }}</div>
    <div class="summary-table-item grid-column-4">{{ productInShoppingCart.orderItems.length }}</div>
    <ng-container *ngIf="hasProductPrice(productInShoppingCart)">
      <ng-container *ngIf="showVat; else noVat">
        <div class="summary-table-item grid-column-3">
          <div *ngIf="productInShoppingCart.ocPrice != null" class="one-time">
            {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
          </div>
          <div
            *ngIf="productInShoppingCart.rcPrice != null">{{ 'wc.shopping.confirmation.price.RC.label' | translate }}</div>
        </div>
        <div class="summary-table-item grid-column-5">
          <div *ngIf="productInShoppingCart.ocPrice != null" class="one-time">
            <one-time-price [price]="productInShoppingCart.ocPrice"></one-time-price>
          </div>
          <div *ngIf="productInShoppingCart.rcPrice != null">
            <app-recurrent-price [disableTexts]="true" [price]="productInShoppingCart.rcPrice"></app-recurrent-price>
          </div>
        </div>
      </ng-container>
      <ng-template #noVat>
        <div class="summary-table-item grid-column-3">
          <div *ngIf="productInShoppingCart.ocPriceTax != null" class="one-time">
            {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
          </div>
          <div *ngIf="productInShoppingCart.rcPriceTax != null">
            {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
          </div>
        </div>
        <div class="summary-table-item grid-column-5">
          <div *ngIf="productInShoppingCart.ocPriceTax != null" class="one-time">
            <one-time-price [price]="productInShoppingCart.ocPriceTax"></one-time-price>
          </div>
          <div *ngIf="productInShoppingCart.rcPriceTax != null">
            <app-recurrent-price [disableTexts]="true" [price]="productInShoppingCart.rcPriceTax"></app-recurrent-price>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <ng-container *ngIf="childProducts.length > 0">
    <div *ngFor="let product of childProducts" class="summary-table-col-child">
      <ng-container>
        <div class="grid-column-2 left">{{ product.productDetail.name }}</div>
        <ng-container *ngIf="hasProductPrice(product); else noPrice">
          <ng-container *ngIf="showVat; else noVat">
            <div class="grid-column-3">
              <div *ngIf="product.ocPrice" class="one-time">
                {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
              </div>
              <div *ngIf="product.rcPrice">{{ 'wc.shopping.confirmation.price.RC.label' | translate }}</div>
            </div>
            <div class="grid-column-5">
              <div *ngIf="product.ocPrice" class="one-time">
                <one-time-price [price]="product.ocPrice"></one-time-price>
              </div>
              <div *ngIf="product.rcPrice">
                <app-recurrent-price [disableTexts]="true" [price]="product.rcPrice"></app-recurrent-price>
              </div>
            </div>
          </ng-container>
          <ng-template #noVat>
            <div class="grid-column-3">
              <div *ngIf="product.ocPriceTax" class="one-time">
                {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
              </div>
              <div *ngIf="product.rcPriceTax">{{ 'wc.shopping.confirmation.price.RC.label' | translate }}</div>
            </div>
            <div class="grid-column-5">
              <div *ngIf="product.ocPriceTax" class="one-time">
                <one-time-price [price]="product.ocPriceTax"></one-time-price>
              </div>
              <div *ngIf="product.rcPriceTax">
                <app-recurrent-price [disableTexts]="true" [price]="product.rcPriceTax"></app-recurrent-price>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #noPrice>
          <div class="grid-column-5">
            {{ 'wc.shopping.confirmation.summaryRow.freePrice.label' | translate }}
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>
