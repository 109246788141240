import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  CodebookService,
  CompareType,
  DmsService,
  EnableDynamicLoading,
  FormUtils,
  ServiceUtils,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { CodebookDto, CustomerDto, DmsFileDto, TicketDto, TicketNoteDto } from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Attachment } from '../../../form-attachments/form-attachments.component';
import { forkJoin } from 'rxjs';
import { TicketService } from '@service/ticket.service';
import { ModalFactoryService } from '@service/modal-factory.service';
import { Location } from '@angular/common';
import { CreateTicketModalComponent } from '../../tickets/create-ticket-modal/create-ticket-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-contact-history-details',
  templateUrl: './contact-history-details.component.html',
  styleUrls: ['./contact-history-details.component.css'],
})
@EnableDynamicLoading({ customName: ContactHistoryDetailsComponent.PAGE_ID })
export class ContactHistoryDetailsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ContactHistoryDetailsComponent';

  pageId(): string {
    return ContactHistoryDetailsComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketId = this.params.id;
      if (ticketId && ticketId !== 'newTicket') {
        this.loadTicket(ticketId);
      } else {
        this.newTicket = true;
        this.editMode = true;
        this.ticket = this.getNewInteraction();
        this.reloadTicket(this.ticket);
      }
    } else {
      this.ticket = undefined;
    }
  }

  contactParamNames = ContactParamNames;
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  ticket: TicketDto;
  dmsFiles: Map<string, DmsFileDto> = new Map<string, DmsFileDto>();
  editedNote: TicketNoteDto;
  customer: CustomerDto = null;
  customerAccountId = null;
  relatedTasks: Array<TicketDto> = [];
  pickerInputType = PickerInputType;
  channels;
  directions;

  editMode = false;

  noteEmptyError;
  subjectEmptyError;

  newTicket: boolean = false;

  form: FormGroup = this.formBuilder.group({
    subject: [null, Validators.required],
    description: [],
    attachments: [],
  });

  customerForm: FormGroup = this.formBuilder.group({
    cuRefNo: [null, Validators.required],
    caRefNo: null
  });

  createDmsFilesList = [];

  relatedTaskId;

  contactTypesCodebooks: CodebookDto[];
  contactTypes = [];
  areas = [];
  subAreas = [];

  constructor(
    protected router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private customerService: CustomerService,
    private dmsService: DmsService,
    private modalFactory: ModalFactoryService,
    public customerLocalStorageService: CustomerLocalStorageService,
    public codebookService: CodebookService,
    private ngModal: NgbModal,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.route.queryParams.subscribe(queryParams => {
      this.relatedTaskId = queryParams['relatedTaskId'];
    });

    this.codebookService
      .getCodebooks('TICKET_CONTACT_CHANNEL')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.channels = cb;
      });

    this.codebookService
      .getCodebooks('TICKET_CONTACT_DIRECTION')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.directions = cb;
      });

    this.codebookService
      .getCodebooks('TICKET_CONTACT_TYPE')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.contactTypesCodebooks = cb;
        this.contactTypes = this.contactTypesCodebooks.filter(codebook => !codebook.parentId);
      });
  }

  getNewInteraction() {
    let references = [];
    if (this.customerLocalStorageService.getCurrentCustomer()) {
      references.push({
        refType: "GENERAL",
        entityType: this.customerLocalStorageService.getCurrentCustomerAccount()
          ? "com.emeldi.ecc.be.crm.dto.CustomerAccount"
          : "com.emeldi.ecc.be.crm.dto.Customer",
        entityId: this.customerLocalStorageService.getCurrentCustomerAccount()
          ? this.customerLocalStorageService.getCurrentCustomerAccount()?.id
          : this.customerLocalStorageService.getCurrentCustomer()?.id
      });
    }

    return {
      id: null,
      type: {
        code: 'CUSTOMER_CONTACT',
        areaType: null,
      },
      subject: null,
      description: null,
      businessState: 'NOTE',
      assignmentState: 'NOT_ASSIGNED',
      parameters: [
        {
          name: ContactParamNames.CONTACT_CHANNEL,
          value: this.channels?.length > 0 ? this.channels[0].code : null,
        },
        {
          name: ContactParamNames.CONTACT_DIRECTION,
          value: this.directions?.length > 0 ? this.directions[0].code : null,
        },
        {
          name: ContactParamNames.CONTACT_TYPE,
          value: this.contactTypes?.length > 0 ? this.contactTypes[0].code : null,
        },
      ],
      attachments: [],
      notes: [],
      references: references
    };
  }

  loadTicket(ticketId) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (this.relatedTaskId) {
          TicketService.addReference(result, 'com.emeldi.ecc.be.ticket.dto.Ticket', this.relatedTaskId);

          const id = result.id;
          result.id = null;
          TicketService.clearFieldsBeforeUpdate(result);
          this.ticketingService
            .updateTicket(id, result)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(savedTicket => {
              this.reloadTicket(savedTicket);
            });
        } else {
          this.reloadTicket(result);
        }
      });
  }

  reloadTicket(ticketDto: TicketDto) {
    this.ticket = ticketDto;
    this.form.patchValue(this.ticket);
    this.editedNote = null;
    this.customer = null;
    this.relatedTasks = [];

    const cusRefNo = this.ticket.references.find(
      reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
    )?.entityId;

    if (cusRefNo) {
      this.customerService
        .getCustomer(cusRefNo)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.customer = result;
          this.customerForm.controls.cuRefNo.patchValue(this.customer);
        });
    }

    const caRefNo = this.ticket.references.find(
      reference => reference.entityType === "com.emeldi.ecc.be.crm.dto.CustomerAccount"
    )?.entityId;

    if (caRefNo) {
      this.customerForm.controls.caRefNo.patchValue(caRefNo);
    }

    const relatedTicketsIds = [];
    this.ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket')
      .forEach(reference => {
        if (reference.entityId) {
          relatedTicketsIds.push(reference.entityId);
        }
      });

    if (relatedTicketsIds.length > 0) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareTypeDtoEnum.IN,
        value: relatedTicketsIds,
      });
      this.ticketingService
        .getTicketsByFilter(search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.relatedTasks = result.data;
        });
    }

    const extIds = [];
    this.ticket.attachments.forEach(attachment => {
      if (attachment.extId && !this.dmsFiles.get(attachment.extId)) {
        extIds.push(attachment.extId);
      }
    });

    if (extIds.length > 0) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'extId',
        compareType: CompareTypeDtoEnum.IN,
        value: extIds,
      });
      this.dmsService
        .filterFiles(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          result.data.forEach(dmsFile => this.dmsFiles.set(dmsFile.extId, dmsFile));
        });
    }

    this.contactTypeChanged(this.getParamValue(this.ticket.parameters, ContactParamNames.CONTACT_TYPE), null, null);
    this.areaChanged(this.getParamValue(this.ticket.parameters, ContactParamNames.TYPE_AREA), null);
  }

  deleteRelatedTask(relatedTask: TicketDto) {
    this.ticket.references = this.ticket.references.filter(
      reference =>
        reference.entityType !== 'com.emeldi.ecc.be.ticket.dto.Ticket' || reference.entityId !== relatedTask.id
    );
    this.update();
  }

  addRelatedTask() {
    const modalRef = this.ngModal.open(CreateTicketModalComponent);
    const createTicketModalComponent = <CreateTicketModalComponent>modalRef.componentInstance;
    createTicketModalComponent.dialogRef = modalRef;
    createTicketModalComponent.createTicketEvent.subscribe(ticketForm => {
      if (ticketForm) {
        this.navigateSibling('TicketEditComponent', {
          id: 'newTicket',
          code: ticketForm.code,
          cuRefNo: this.ticket?.references?.find(
            reference => reference.entityType === "com.emeldi.ecc.be.crm.dto.Customer"
          )?.entityId,
          caRefNo: this.ticket?.references?.find(
            reference => reference.entityType === "com.emeldi.ecc.be.crm.dto.CustomerAccount"
          )?.entityId,
          relatedTicketId: this.ticket.id,
          backUrl: this.router.url.split('?')[0],
        });
      }
    });
  }

  getParam(params, paramName) {
    let param = params.find(findParam => findParam.name === paramName);
    if (!param) {
      param = { name: paramName, value: null };
      params.push(param);
    }
    if (paramName === ContactParamNames.CONTACT_CHANNEL && !param.value && this.channels.length > 0) {
      param.value = this.channels[0].code;
    }
    if (paramName === ContactParamNames.CONTACT_DIRECTION && !param.value && this.directions.length > 0) {
      param.value = this.directions[0].code;
    }
    if (paramName === ContactParamNames.CONTACT_TYPE && !param.value && this.contactTypes.length > 0) {
      param.value = this.contactTypes[0].code;
      this.contactTypeChanged(this.contactTypes[0].code, null, null);
    }
    if (paramName === ContactParamNames.TYPE_AREA && !param.value && this.areas.length > 0) {
      param.value = this.areas[0].code;
      this.areaChanged(this.areas[0].code, null);
    }
    if (paramName === ContactParamNames.TYPE_SUBAREA && !param.value && this.subAreas.length > 0) {
      param.value = this.subAreas[0].code;
    }

    return param;
  }

  getParamValue(params, paramName) {
    return params.find(findParam => findParam.name === paramName)?.value;
  }

  editInteraction() {
    this.editMode = true;
    this.form.enable();
  }

  fieldChanged(field, event) {
    if (
      [
        ContactParamNames.CONTACT_CHANNEL,
        ContactParamNames.CONTACT_DIRECTION,
        ContactParamNames.CONTACT_TYPE,
        ContactParamNames.TYPE_SUBAREA,
        ContactParamNames.TYPE_AREA
      ].indexOf(field) > -1
    ) {
      const param = this.getParam(this.ticket.parameters, field);
      this.getParam(this.ticket.parameters, field).value = event;
    } else {
      this.ticket[field] = event;
    }

    if (field === "Customer") {
      TicketService.replaceReferenceByType(this.ticket, "com.emeldi.ecc.be.crm.dto.Customer", event?.id);
      this.customerForm.controls.caRefNo.patchValue(null);
      TicketService.replaceReferenceByType(this.ticket, "com.emeldi.ecc.be.crm.dto.CustomerAccount", null);
    }

    if (field === "CustomerAccount") {
      TicketService.replaceReferenceByType(this.ticket, "com.emeldi.ecc.be.crm.dto.CustomerAccount", event?.id);
    }

    if (field === "subject") {
      this.checkSubjectEmpty();
    }

    this.update();
  }

  checkSubjectEmpty(): boolean {
    if (!this.ticket.subject || this.ticket.subject === "") {
      this.subjectEmptyError = true;
    } else {
      this.subjectEmptyError = false;
    }
    return this.subjectEmptyError;
  }

  update() {
    if (!this.editMode && !this.newTicket) {
      this.saveChanges();
    }
  }

  saveChanges() {
    this.ticket.attachments.forEach((attachment: Attachment) => {
      attachment.id = null;
      attachment.dmsFile.contentHref = null;
      attachment.dmsFile.size = null;
      if (!attachment.dmsFile?.id) {
        this.createDmsFilesList.push(attachment.dmsFile);
      }
      delete attachment.dmsFile;
    });
    this.ticket.attachments = this.ticket.attachments.filter(attachment => !attachment['delete']);

    FormUtils.validateAllFormFields(this.customerForm);
    if (this.checkSubjectEmpty() || !this.customerForm.valid) {
      return;
    } else {
      this.ticket.notes.forEach(note => {
        this.checkNotEmptyNote(note.note);
        note.created = null;
        note.createdBy = null;
        note.modified = null;
        note.modifiedBy = null;
      });

      if (!this.noteEmptyError) {
        if (this.ticket.recordVersion) {
          this.appBlockerService.block();
          const id = this.ticket.id;
          this.appBlockerService.block();
          this.ticket.id = null;
          TicketService.clearFieldsBeforeUpdate(this.ticket);

          if (id) {
            this.ticketingService
              .updateTicket(id, this.ticket)
              .pipe(finalize(this.appBlockerService.unblock))
              .subscribe(result => this.saveFiles(result));
          }
        } else {
          this.ticketingService
            .createTicket(this.ticket)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(result => this.saveFiles(result));
        }
      }
    }
  }

  saveFiles(ticket) {
    if (this.createDmsFilesList.length > 0) {
      const calls = [];
      this.createDmsFilesList.forEach(file => calls.push(this.dmsService.createFile(file)));
      forkJoin(calls)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.createDmsFilesList = [];
          this.newTicket = false;
          this.editMode = false;
          this.reloadTicket(ticket);
        });
    } else {
      this.newTicket = false;
      this.editMode = false;
      this.reloadTicket(ticket);
    }
  }

  discardChanges() {
    this.modalFactory.discardChangesModal(
      dialogReference => {
        this.form.reset();
        if (this.ticket.id) {
          this.loadTicket(this.ticket.id);
        }
        dialogReference.dismiss();
      },
      dialogReference => {
        dialogReference.dismiss();
      }
    );
  }

  checkNotEmpty(value: any) {
    return !value || value === '';
  }

  checkNotEmptySubject(value: any) {
    this.subjectEmptyError = this.checkNotEmpty(value);
  }

  checkNotEmptyNote(value: any) {
    this.noteEmptyError = this.checkNotEmpty(value);
  }

  backToListing() {
    this.location.back();
  }

  contactTypeChanged($event: any, areaSelect, subAreaSelect) {
    if (this.contactTypesCodebooks) {
      const cb = this.contactTypesCodebooks.find(codebook => codebook.code === $event);
      this.areas.length = 0;
      if (cb) {
        this.contactTypesCodebooks
          .filter(codebook => codebook.parentId === cb.id)
          .forEach(area => {
            this.areas.push(area);
          });
      }
      if (areaSelect) {
        const areaCode = this.areas[0]?.code;
        this.getParam(this.ticket.parameters, ContactParamNames.TYPE_AREA).value = areaCode;
        this.areaChanged(areaCode, subAreaSelect);
      }
    }
  }

  areaChanged($event: any, subAreaSelect) {
    const cb = this.contactTypesCodebooks?.find(codebook => codebook?.code === $event);
    this.subAreas.length = 0;
    if (cb) {
      this.contactTypesCodebooks.filter(codebook => codebook.parentId === cb.id)
        .forEach(subArea => this.subAreas.push(subArea));
    }
    if (this.subAreas.length > 0 && subAreaSelect) {
      this.getParam(this.ticket.parameters, ContactParamNames.TYPE_SUBAREA).value = this.subAreas[0].code;
    } else {
      this.getParam(this.ticket.parameters, ContactParamNames.TYPE_SUBAREA).value = null;
    }
  }

  tasksDeleted() {
    this.reloadTicket(this.ticket);
  }

  protected readonly PickerInputType = PickerInputType;

  cancel() {
    if (this.newTicket) {
      this.router.navigate(["/ecare/contact-history/"]);
    } else {
      this.form.reset();
      if (this.ticket.id) {
        this.loadTicket(this.ticket.id);
      }
      this.editMode = false;
    }
  }
}

export enum ContactParamNames {
  CONTACT_CHANNEL = "CONTACT_CHANNEL",
  CONTACT_DIRECTION = "CONTACT_DIRECTION",
  CONTACT_TYPE = "CONTACT_TYPE",
  TYPE_AREA = "TYPE_AREA",
  TYPE_SUBAREA = "TYPE_SUBAREA",
}
