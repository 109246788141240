import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartyElse } from '../../../models/party-holder';
import { AccountDto, OrderDto, TicketDto } from '@btl/order-bff';
import { PickerInputType } from 'app/components/form-field-modal-picker/form-field-modal-picker.component';

@Component({
  selector: 'app-ecare-search-table-mobile',
  templateUrl: './ecare-search-table-mobile.component.html',
})
export class EcareSearchTableMobileComponent {
  @Input()
  pickerInputType: PickerInputType;

  @Input()
  rows: any[] = null;

  @Output()
  readonly selectedRow = new EventEmitter<any>();
}
