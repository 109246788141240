<div>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
      <div class="-filter-fields -filter-fields-group">
        <input
          id="002_ticket_filter"
          type="text"
          formControlName="subject"
          class="filter-input-id"
          placeholder="{{ 'wc.ecare.ticketsFilter.subject.placeholder' | translate }}" />

        <app-advanced-filter
          clearHistoryTextLocalizedKey="wc.ecare.opportunity.clearFilters"
          [form]="filterForm"
          [isAdvancedFilterOpen]="isAdvancedFilterOpen"
          [changedFilterFieldsCount]="changedFilterFieldsCount"
          (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
          (resetFieldsEmitter)="resetFilterFields()">
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.area"
            controlName="area"
            optionLabel="name"
            optionValue="name"
            [fieldType]="'select'"
            [selectOptions]="ticketAreaTypes"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.type"
            controlName="type"
            optionLabel="code"
            optionValue="code"
            [fieldType]="'select'"
            [selectOptions]="ticketTypes"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event); typeChanged($event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.assignmentState"
            fieldType="select"
            isInternal="true">
            <ng-select
              formControlName="assignmentState"
              class="ecare-dropdown"
              id="assignmentState"
              [clearable]="false"
              [clearOnBackspace]="false"
              [searchable]="false"
              [multiple]="true"
              (change)="changeFilterField('assignmentState', $event)">
              <ng-option [value]="''">{{ 'wc.ecare.ticket.assignmentState.default' | translate }}</ng-option>
              <ng-option *ngFor="let assignmentState of assignmentStates" [value]="assignmentState.name">
                {{ 'wc.ecare.ticket.assignmentState.' + assignmentState.name | translate }}
              </ng-option>
            </ng-select>
          </app-advanced-filter-field>
          <app-advanced-filter-field
            [hidden]="assignedToMode == 'MY'"
            labelLocalizedKey="wc.ecare.ticket.assignedTo"
            controlName="assignedTo"
            placeholderLocalizedKey="wc.ecare.ticket.assignedTo.placeholder"
            isInternal="true"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field labelLocalizedKey="wc.ecare.ticket.businessState" fieldType="select">
            <ng-select
              formControlName="businessState"
              class="ecare-dropdown"
              id="businessState"
              [clearable]="false"
              [clearOnBackspace]="false"
              [searchable]="false"
              [multiple]="true"
              (change)="changeFilterField('businessState', $event)">
              <ng-option [value]="''">{{ 'wc.ecare.ticket.businessState.default' | translate }}</ng-option>
              <ng-option *ngFor="let businessState of businessStates" [value]="businessState.name">
                {{ 'wc.ecare.ticket.businessState.' + businessState.name | translate }}
              </ng-option>
            </ng-select>
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.owner"
            controlName="owner"
            placeholderLocalizedKey="wc.ecare.ticket.owner.placeholder"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.ticketRefNo"
            controlName="ticketRefNo"
            placeholderLocalizedKey="wc.ecare.ticket.ticketRefNo.placeholder"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.ticket.priority"
            controlName="priority"
            placeholderLocalizedKey="wc.ecare.ticket.priority.placeholder"
            [fieldType]="'number'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            isInternal="true"
            labelLocalizedKey="wc.ecare.ticket.createdDate"
            controlName="createdFrom"
            startOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.ticket.createdDateFrom.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            isInternal="true"
            controlName="createdTo"
            endOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.ticket.createdTo.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            isInternal="true"
            labelLocalizedKey="wc.ecare.ticket.followUpDateFrom"
            controlName="followUpDateFrom"
            startOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.ticket.followUpDateFrom.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            isInternal="true"
            controlName="followUpDateTo"
            endOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.ticket.followUpDateTo.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            *ngIf="!cuRefNo"
            labelLocalizedKey="wc.ecare.ticket.entityType"
            controlName="entityType"
            optionLabel="name"
            optionValue="name"
            [fieldType]="'select'"
            [selectOptions]="externalEntityTypes"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            *ngIf="!cuRefNo"
            labelLocalizedKey="wc.ecare.ticket.entity"
            controlName="entity"
            placeholderLocalizedKey="wc.ecare.ticket.entity.placeholder"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field fullWidth="true" labelLocalizedKey="wc.ecare.ticket.slaDueDate">
            <input
              id="slaDueDate"
              type="checkbox"
              formControlName="slaDueDateCheckBox"/>
          </app-advanced-filter-field>
        </app-advanced-filter>
      </div>
      <app-button
        variant="primary"
        id="004_ticket_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </form>
  </div>
</div>
