<div class="header__topmenu d-print-none">
  <div class="menu-wrapper container">
    <ng-template #customerBox let-data="data">
      <div *ngIf="isContextLoaded" class="customer-box">
        <a
          *blockUI="customerContextBlockUiElementName; template: blockTemplate"
          class="customer-box__name"
          id="003_{{ data }}navigation_menu"
          [routerLink]="['/ecare/dashboard']"
          [queryParams]="{ cuRefNo: customerLocalStorageService.getCurrentCustomer()?.id }"
          >{{ customerLocalStorageService.getCurrentCustomer().subject.firstName }}
          {{ customerLocalStorageService.getCurrentCustomer().subject.lastName }}</a
        >
        <div
          *ngIf="contextHealthScore"
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          class="customer-box__health-status {{ contextHealthScore }}"
          ngbTooltip="{{ 'wc.ecare.customerDetails.healthStatus' | translate }}"></div>
        <a
          class="customer-box__icon customer-box__icon-refresh"
          [inlineSVG]="'/assets/svg/refresh-icon.svg'"
          (click)="refreshContextCustomer()"></a>
        <a
          class="customer-box__icon customer-box__icon-close"
          [inlineSVG]="'/assets/svg/close-icon.svg'"
          ngbTooltip="{{ 'wc.common.close.button' | translate }}"
          (click)="customerLocalStorageService.removeCurrentCustomerFromContext()"></a>
      </div>
    </ng-template>
    <ng-template #topMenuLeftSide>
      <div class="header__topmenu-leftSide">
        <ng-container *ngIf="navigationType === NavigationTypeEnum.eshop; else notShopping">
          <ng-container *ngFor="let productGroupNode of productGroupsTree; let i = index">
            <app-top-menu-action
              *ngIf="!productGroupNode.internal; else internalMenuGroup"
              [routerLink]="getProductGroupNavigationUrl(productGroupNode)"
              [variant]="isCurrentGroupOrParent(productGroupNode.group?.id) ? 'selected' : null"
              label="{{ getGroupName(productGroupNode.group) }}" />
            <ng-template #internalMenuGroup>
              <app-acl-internal-evaluate-component class="internal-top-menu">
                <app-top-menu-action
                  [routerLink]="getProductGroupNavigationUrl(productGroupNode)"
                  [variant]="isCurrentGroupOrParent(productGroupNode.group?.id) ? 'selected' : null"
                  label="{{ getGroupName(productGroupNode.group) }}" />
              </app-acl-internal-evaluate-component>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #notShopping> </ng-template>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="topMenuLeftSide" />

    <ng-template #topMenuRightSide>
      <div class="header__topmenu-rightSide">
        <div
          *ngIf="isLoggedIn && accountInfo && !accountInfo.external"
          class="left-panel"
          [class.off]="!showInternalElements">
          <ng-template #eyeFeature>
            <div class="eye-feature" [ngClass]="{ off: !showInternalElements }">
              <label class="switch" (change)="toggleInternalView()">
                <input type="checkbox" [checked]="showInternalElements" />
                <span class="slider">
                  <span *ngIf="showInternalElements" class="label-text">{{
                    'wc.common.navigation.eyeFeature.on' | translate
                  }}</span>
                  <span *ngIf="!showInternalElements" class="label-text">{{
                    'wc.common.navigation.eyeFeature.off' | translate
                  }}</span>
                </span>
              </label>
            </div>
          </ng-template>

          <ng-container *ngTemplateOutlet="eyeFeature" />
          <ng-container *ngTemplateOutlet="customerBox" />
        </div>

        <ng-container *ngIf="isLoggedIn && accountInfo">
          <app-top-menu-action
            icon="ph_house"
            [routerLink]="!accountInfo.external ? '/ecare/homepage' : '/ecare/ext-homepage'"
            ngbTooltip="{{ 'wc.common.navigation.homepage.button' | translate }}" />
          <app-top-menu-action
            icon="ph_shopping-cart"
            [routerLink]="'/eshop/product-listing/hw'"
            ngbTooltip="{{ 'wc.common.navigation.go.shopping.button' | translate }}" />
          <app-top-menu-action
            *ngIf="!accountInfo.external"
            icon="ph_magnifying-glass"
            [routerLink]="'/ecare/search'"
            ngbTooltip="{{ 'wc.common.search.button' | translate }}" />
          <!-- Todo - change icon when there is unread notification -->
          <app-top-menu-action
            icon="ph_bell"
            [routerLink]="'/ecare/notifications'"
            ngbTooltip="{{ 'wc.common.userNavigation.notifications.button' | translate }}" />
        </ng-container>

        <div ngbDropdown placement="bottom auto">
          <ng-template #cookiesModal let-data="data">
            <div app-acl-internal-evaluate-component>
              <div *ngIf="isLoggedIn && isExtSystemId" class="cookies-modal-wrapper">
                <a
                  class="fa fa-volume-control-phone cookies-modal-icon"
                  id="041_{{ data }}navigation_menu"
                  (click)="openCookiesModal()"></a>
              </div>
            </div>
          </ng-template>
          <ng-container *ngTemplateOutlet="cookiesModal" />

          <ng-template #userInfoTemplate>
            <div *ngIf="showUserMenu()" class="user_menu">
              <div class="user_menu-footer">
                <ng-template [ngIf]="!accountInfo?.external">
                  <a
                    *ngFor="let pageModule of internalUserMenuItems"
                    class="dropdown-menu__item"
                    ngbDropdownItem
                    [id]="'navigation_menu_' + pageModule.code"
                    [routerLink]="[pageModule.parentUrlPattern + pageModule.urlPattern]"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="'nav-active'">
                    <i class="menu-icon" [inlineSVG]="'/assets/svg/' + pageModule.cssClass + '.svg'"></i>
                    {{ pageModules.getModuleText(pageModule.code) }}
                  </a>
                </ng-template>
                <ng-template [ngIf]="accountInfo?.external">
                  <a
                    *ngFor="let pageModule of externalUserMenuItems"
                    class="dropdown-menu__item"
                    ngbDropdownItem
                    [id]="'navigation_menu_' + pageModule.code"
                    [routerLink]="[pageModule.parentUrlPattern + pageModule.urlPattern]"
                    [routerLinkActiveOptions]="{ exact: true }"
                    [routerLinkActive]="'nav-active'">
                    <i class="menu-icon" [inlineSVG]="'/assets/svg/' + pageModule.cssClass + '.svg'"></i>
                    {{ pageModules.getModuleText(pageModule.code) }}
                  </a>
                </ng-template>
                <hr *ngIf="externalUserMenuBottomItems.length > 0" class="ecare-separator -indented" />

                <a id="014_navigation_menu" class="dropdown-menu__item" routerLink="/help" ngbDropdownItem>
                  <i class="menu-icon" [inlineSVG]="'/assets/svg/help-double.svg'"></i>
                  {{ 'wc.common.navigation.help.button' | translate }}
                </a>

                <a
                  id="015_navigation_menu"
                  class="dropdown-menu__item red-item"
                  ngbDropdownItem
                  (click)="logoutKeyCloak()">
                  <i class="menu-icon" [inlineSVG]="'/assets/svg/crm-signOut.svg'"></i>
                  {{ 'wc.common.navigation.logout.button' | translate }}
                </a>
              </div>
            </div>
          </ng-template>
          <div *ngIf="showUserMenu()">
            <app-top-menu-action
              class="never_hide"
              ngbDropdownToggle
              label="{{ userInfo.firstName + ' ' + userInfo.lastName }}"
              [isDropdown]="true">
              <user-initials>{{ userInfo.firstName.charAt(0) }}{{ userInfo.lastName.charAt(0) }}</user-initials>
            </app-top-menu-action>
            <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
              <ng-container *ngTemplateOutlet="userInfoTemplate" />
            </div>
          </div>

          <ng-template #loginUser>
            <app-top-menu-action
              *ngIf="!isLoggedIn"
              class="never_hide"
              style="text-wrap: nowrap"
              icon="ph_sign-in"
              label="{{ 'wc.common.navigation.login.button' | translate }}"
              (click)="loginKeyCloak()" />
          </ng-template>

          <ng-container *ngTemplateOutlet="loginUser" />
        </div>

        <app-top-menu-action
          icon="ph_question"
          [routerLink]="'/help'"
          ngbTooltip="{{ 'wc.common.navigation.help.button' | translate }}" />

        <ng-container *ngTemplateOutlet="flags" />
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="topMenuRightSide" />
  </div>
</div>

<ng-template #flags let-dropdownPosition="dropdownPosition">
  <div
    #languageDropdown="ngbDropdown"
    class="language-dropdown-container"
    ngbDropdown
    display="dynamic"
    placement="bottom-right auto">
    <app-top-menu-action
      class="language-dropdown-current initials never_hide"
      ngbDropdownToggle
      label="{{ currentLocaleService.getCurrentLanguage() }}"
      [isDropdown]="true">
      <language-flag
        ><i [inlineSVG]="'/assets/svg/' + currentLocaleService.getCurrentLanguage() + '-flag-icon_v2.svg'"></i>
      </language-flag>
    </app-top-menu-action>
    <div aria-labelledby="languageDropdown" ngbDropdownMenu>
      <div class="language-dropdown-menu">
        <ng-container *ngFor="let locale of locales">
          <div class="dropdown-menu__item" (click)="languageChange(locale); languageDropdown.close()">
            <i class="flag-icon" [inlineSVG]="'/assets/svg/' + locale + '-flag-icon_v2.svg'"></i>
            {{ 'wc.common.general.language.' + locale | translate }}
            <span class="locale-code">({{ locale }})</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
