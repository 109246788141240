import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AccountService,
  AclService,
  AuthFactoryService,
  AuthService,
  BlockTemplateComponent,
  CurrentLocaleService,
  ErrorOnEnum,
  getCurrentHost,
  GroupTreeNode,
  OrderingService,
  PageModule,
  PageModuleService,
  ProductGroupService,
  Search,
  ServiceUtils,
  UserInfo,
  WishListService
} from '@btl/btl-fe-wc-common';
import { NavigationTypeEnum } from '../navigation-menu.component';
import { BlockUIService } from 'ng-block-ui';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { OrderUtils } from '../../../../helpers/order-utils';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CookieService } from 'ngx-cookie-service';
import { SystemIdInterceptor } from '../../../../helpers/system-id-interceptor';
import { NavigationMenuService, UserMenuItems } from '@service/navigation-menu.service';
import { environment } from '../../../../../environments/environment';
import { from } from 'rxjs/internal/observable/from';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderErrorHandler } from '@service/errors/order-error-handler';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { Router } from '@angular/router';
import { AccountDto, CustomerDto, GroupDto, OrderDto, OrderParamDto, PagedCustomersDto } from '@btl/order-bff';
import { NavigationThirdMenuComponent } from '../../navigation-third-menu/navigation-third-menu.component';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
})
export class TopMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  protected readonly BlockTemplateComponent = BlockTemplateComponent;
  protected readonly NavigationTypeEnum = NavigationTypeEnum;
  public authService: AuthService;
  locales: string[] = environment.localization.locales;
  blockTemplate = BlockTemplateComponent;

  @Input()
  isContextLoaded: boolean;

  @Input()
  contextHealthScore: string;

  @Input()
  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;

  @Input()
  doNotRedirectAfterLogin: boolean = false;

  @Input() productGroupsTree: Array<GroupTreeNode>;

  @ViewChild('topMenuRightSide') topMenuRightSide: TemplateRef<any>;
  @ViewChild('topMenuLeftSide') topMenuLeftSide: TemplateRef<any>;

  role: string;
  isLoggedIn: boolean = false;
  userInfo: UserInfo;
  accountInfo: AccountDto;
  customerContextBlockUiElementName: string = 'contextCustomer';
  showInternalElements: boolean = true;
  isExtSystemId: boolean = false;
  showLanguagePanel: boolean = false;
  isOpen: boolean = false;
  currentProductGroup = null;

  internalUserMenuItems: Array<PageModule> = [];
  externalUserMenuItems: Array<PageModule> = [];
  internalUserMenuMiddleItems: Array<PageModule> = [];
  externalUserMenuMiddleItems: Array<PageModule> = [];
  internalUserMenuBottomItems: Array<PageModule> = [];
  externalUserMenuBottomItems: Array<PageModule> = [];

  constructor(
    private blockUIService: BlockUIService,
    public customerLocalStorageService: CustomerLocalStorageService,
    private productGroupService: ProductGroupService,
    private wcOrderingService: WcOrderingService,
    private orderingService: OrderingService,
    private ngbModal: NgbModal,
    private readonly cookieService: CookieService,
    private systemIdInterceptor: SystemIdInterceptor,
    private navigationMenuService: NavigationMenuService,
    public currentLocaleService: CurrentLocaleService,
    public pageModules: PageModuleService,
    private aclService: AclService,
    private wishListService: WishListService,
    private accountService: AccountService,
    protected errorHandler: OrderErrorHandler,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private customerService: CustomerService,
    public router: Router,
    private authFactoryService: AuthFactoryService,
  ) {
    this.authService = authFactoryService.getAuthService();
  }

  ngOnInit(): void {
    this.productGroupService
    .getCurrentProductGroup()
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(groupTreeNode => {
      this.currentProductGroup = groupTreeNode;
    });

    this.authService.accountChange.pipe(takeUntil(this.onDestroy$)).subscribe(account => {
      this.accountInfo = account;
      from(this.authService.getPersonalData())
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(userProfile => {
          this.userInfo = userProfile;
        });
    });

    this.navigationMenuService.getUserMenuItems().pipe(takeUntil(this.onDestroy$)).subscribe((menuItems: UserMenuItems) => {
      this.internalUserMenuItems = menuItems.internalUserMenuItems;
      this.externalUserMenuItems = menuItems.externalUserMenuItems;
      this.internalUserMenuMiddleItems = menuItems.internalUserMenuMiddleItems;
      this.externalUserMenuMiddleItems = menuItems.externalUserMenuMiddleItems;
      this.internalUserMenuBottomItems = menuItems.internalUserMenuBottomItems;
      this.externalUserMenuBottomItems = menuItems.externalUserMenuBottomItems;
    });
    this.showInternalElements = this.customerLocalStorageService.showInternalElements();

    this.customerLocalStorageService.currentInternalVisible
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: boolean) => {
        this.showInternalElements = value;
      });

    from(this.authService.isLoggedIn())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: boolean) => {
        this.isLoggedIn = value;
        if (this.isLoggedIn) {
          from(this.authService.getUserInfo())
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(userProfile => {
              this.userInfo = userProfile;
              this.accountInfo = this.authService.account;
              this.role = this.authService.getUserRoles()[this.authService.getUserRoles().length - 1];
              if (!this.authService.account) {
                this.accountService
                  .getAccountByLogin(this.authService.getUsername(), false, false)
                  .pipe(
                    catchError(error => {
                      if (error instanceof HttpErrorResponse && error.status == 404) {
                        this.errorHandler.handleError('Account by login not found', error, null);
                      } else {
                        this.errorHandler.handleError('Unable to get account', error, null);
                      }
                      setTimeout(() => this.logoutKeyCloak(), 2000);
                      return of(null);
                    }),
                  )
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(result => {
                    if (result) {
                      this.accountInfo = result;
                      this.authService.setAccount(this.accountInfo);
                      this.currentLocaleService.setCurrentLanguage(this.accountInfo.locale, this.authService);
                      if (!this.customerLocalStorageService.getCurrentCustomer() && this.accountInfo.external) {
                        this.propertyAccessorLocalService.isModuleBffCrmEnabled().pipe(takeUntil(this.onDestroy$)).subscribe(bffCrmEnabled => {
                          if (bffCrmEnabled) {
                            this.loadCustomer();
                          } else {
                            this.resumeLastOrder();
                          }
                        });
                      }
                    } else {
                      this.checkOrderCreatorAndAccountId();
                    }
                  });
              }
            });
        } else {
          this.authService.account = null;
          this.userInfo = null;
          this.accountInfo = null;
          this.role = '';
        }
        this.wishListService.wishListChange.emit(null);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit(): void {
    this.checkExtSystemId();

    const onExtIdChangeHandler = (): void => {
      this.checkExtSystemId();
    };
    this.systemIdInterceptor.extIdEvent.pipe(takeUntil(this.onDestroy$)).subscribe(onExtIdChangeHandler);
  }

  private checkExtSystemId(): void {
    this.isExtSystemId = this.cookieService.check('extSystemId') || this.cookieService.check('extSystemTrxId');
  }

  refreshContextCustomer(): void {
    this.blockUIService.start(this.customerContextBlockUiElementName);
    this.customerLocalStorageService.refreshCustomerContext().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
    });
  }

  public toggleInternalView(): void {
    if (this.showInternalElements) {
      this.customerLocalStorageService.setInternalVisible('false');
      this.customerLocalStorageService.currentInternalVisible.emit(false);
    } else {
      this.customerLocalStorageService.setInternalVisible('true');
      this.customerLocalStorageService.currentInternalVisible.emit(true);
    }
  }

  public openCookiesModal(): void {
    const dialogReference: NgbModalRef = this.ngbModal.open(ConfirmationDialogComponent, {
      windowClass: 'dialog dialog-confirmation',
    });

    const confirmationDialogComponent: ConfirmationDialogComponent = <ConfirmationDialogComponent>(
      dialogReference.componentInstance
    );
    confirmationDialogComponent.heading = 'wc.common.navigation.clear.sessionId';
    confirmationDialogComponent.texts.push('wc.common.navigation.clear.sessionId');
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef): void => {
      this.deleteSystemIdFromCookies();
    };
  }

  private deleteSystemIdFromCookies(): void {
    this.ngbModal.dismissAll();
    this.cookieService.delete('extSystemId', '/', null, false, 'Lax');
    this.cookieService.delete('extSystemTrxId', '/', null, false, 'Lax');
    this.isExtSystemId = false;

    const currentOrder: OrderDto = this.wcOrderingService.getCurrentOrderFromSession();
    if (!_.isEmpty(currentOrder)) {
      const orderParamsDto: Array<OrderParamDto> = [];
      OrderUtils.updateOrderAttr(orderParamsDto, 'extSystemId', '');
      OrderUtils.updateOrderAttr(orderParamsDto, 'extSystemTrxId', '');
      const orderAsMap = {
        orderAttributes: orderParamsDto,
        recordVersion: currentOrder.recordVersion,
      };
      this.wcOrderingService.patchOrder(currentOrder.id, orderAsMap).pipe(takeUntil(this.onDestroy$)).subscribe();
    }
  }

  showUserMenu(): boolean {
    return this.isLoggedIn && !!this.userInfo;
  }

  languageChange(locale: string): void {
    this.currentLocaleService.setCurrentLanguage(locale, this.authService);
    this.showLanguagePanel = false;
  }

  loginKeyCloak(): void {
    this.aclService.emptyPrivilegesCache();
    this.authService
      .login()
      .then()
      .catch(reason => {
        throw new Error(`Cannot login: ${reason}`);
      });
  }

  logoutKeyCloak(): void {
    this.aclService.emptyPrivilegesCache();
    this.authService
      .logout(getCurrentHost())
      .then()
      .catch(reason => {
        throw new Error(`Cannot logout: ${reason}`);
      });
    this.customerLocalStorageService.removeCurrentCustomerFromContext();
    this.wcOrderingService.removeCurrentOrderFromContext();
    this.wishListService.clearWishLists();
  }

  private loadCustomer(): void {
    this.customerService
      .getCustomers(ServiceUtils.getUnlimitedSearch())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((customers: PagedCustomersDto): void => {
        if (customers.data.length > 0) {
          this.customerService
            .getCustomer(customers.data[0].id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((customer: CustomerDto): void => {
              this.customerLocalStorageService.setCurrentCustomer(customer, false);
              this.resumeLastOrder();
            });
        } else {
          this.resumeLastOrder(null, true);
        }
      });
  }

  resumeLastOrder(customer?: CustomerDto, redirectToHomePage?) {
    this.wcOrderingService
      .getCurrentOrder()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(currentOrder => {
        if (currentOrder.orderItems.length === 0) {
          const search: Search = {
            filtering: [
              {
                column: 'orderType',
                compareType: 'EQUAL',
                value: 'SALES',
              },
              {
                column: 'accountId',
                compareType: 'DIFFERENT',
                value: null,
              },
              {
                column: 'orderStateType',
                compareType: 'EQUAL',
                value: 'CREATED',
              },
            ],
            sorting: [{ column: 'created', sortOrder: 'desc' }],
            paging: {
              page: 1,
              pageSize: 1,
            },
          };

          if (customer) {
            search.filtering.push({
              column: 'partyRefNo',
              compareType: 'EQUAL',
              value: customer.id,
            });
          }

          this.orderingService
            .filterOrders(search, null)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              if (result.data.length > 0 && result.data[0].orderItems.length > 0) {
                this.wcOrderingService.getOrderByRefNo(result.data[0].id).pipe(takeUntil(this.onDestroy$)).subscribe();
              } else {
                this.checkOrderCreatorAndAccountId(redirectToHomePage);
              }
            });
        } else {
          this.checkOrderCreatorAndAccountId(redirectToHomePage);
        }
      });
  }

  checkOrderCreatorAndAccountId(redirectToHomePage?): void {
    this.wcOrderingService
      .getCurrentOrder()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order: OrderDto): void => {
        if (order && (!order.accountId || !order.creator)) {
          const orderAsMap = {
            recordVersion: order.recordVersion,
          };
          this.wcOrderingService.addCreator(order, orderAsMap);
          this.wcOrderingService.addAccountId(order, orderAsMap);
          this.wcOrderingService
            .patchOrder(order.id, orderAsMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((orderDto: OrderDto): void => {
              this.wcOrderingService.orderChanged.emit(orderDto);
              if (redirectToHomePage) {
                this.redirectToHomePage();
              }
            });
        }
      });
  }

  getProductGroupNavigationUrl(navigationGroupNode: GroupTreeNode): string {
    return this.navigationMenuService.getProductGroupNavigationUrl(navigationGroupNode);
  }

  public getGroupName(group: GroupDto): string {
    return this.productGroupService.getGroupName(group);
  }

  isCurrentGroupOrParent(groupId) {
    let productGroup = this.currentProductGroup;
    while (productGroup?.group?.parentId) {
      productGroup = NavigationThirdMenuComponent.getProductGroupByGroupId(
        productGroup.group.parentId,
        this.productGroupsTree
      );
      if (productGroup?.group.id === groupId || productGroup?.group.parentId === groupId) {
        return true;
      }
    }

    return false;
  }

  redirectToHomePage(): void {
    if (!this.doNotRedirectAfterLogin) {
      if (this.accountInfo.external) {
        this.router.navigateByUrl('ecare/ext-homepage');
      } else {
        this.router.navigateByUrl('ecare/homepage');
      }
    }
  }

  protected readonly ErrorOnEnum = ErrorOnEnum;
}
