<!-- 001 Breadcrumbs -->
<div *ngIf="treeGroupPath" app-breadcrumb [groups]="treeGroupPath" [groupBasedBreadcrumbs]="true"></div>

<div class="newDesing">
  <div *ngIf="product" class="product__detail">
    <div class="product__detail-content">
      <!-- Product stickers -->
      <div *ngIf="product?.stickers" class="product__labels">
        <app-sticker *ngFor="let sticker of sortStickersByPriority(product.stickers)" variant="{{ sticker.type | stickerTypeClass }}">{{ getStickerText(sticker.texts) }}</app-sticker>
      </div>

      <div *ngIf="!isTariff(); else tariff_gallery" class="product__detail-gallery">
        <ng-container *ngIf="isUniversal">
          <img *ngIf="product?.picture?.href as mainPicture" [src]="mainPicture" [alt]="product.name" />
        </ng-container>
        <div
          *ngIf="!isUniversal"
          app-product-gallery
          [color]="selectedColor"
          [name]="product.name"
          [pictures]="product.pictures"></div>
      </div>
      <ng-template #tariff_gallery>
        <div class="product__detail-gallery" style="text-align: center">
          <img
            class="product__detail-galleryImage"
            src="/assets/img/products/tariff_default_product_image.png"
            alt="{{ product.name }}" />
        </div>
      </ng-template>
      <div class="product__detail-summary">
        <div class="product__detail-titleContainer">
          <h1 id="002_product_detail" class="product__detail-title">{{ product.name }}</h1>
          <span class="product__detail-contentShare">
            <app-content-share
              [url]="getCurrentHref()"
              [twitterButtonConfig]="twitterButtonConfig"
              [facebookButtonConfig]="facebookButtonConfig"
              [allowEmailSharing]="true"
              [contentSeoUrl]="product.seoUrl"></app-content-share>
          </span>
        </div>

        <!-- Product short description -->
        <div
          *ngIf="rating && product.parametersStatic['ratingEnabled'] === 'true'"
          class="product__detail-ratingMini"
          (click)="scrollToTabContainer(); nav.select(3)">
          <app-rating-stars class="stars" [rating]="rating" [onlyFull]="false"></app-rating-stars>
          <span class="ratingText">{{ reviewToFive }} ({{ reviewQuantity }} reviews)</span>
        </div>

        <div class="product__detail-shortDescription">
          <div *ngIf="product.shortDescription" [innerHTML]="product.shortDescription | stripHtmlTags | safeHtml"></div>
          <span>... </span>
          <a
            *ngIf="!isTariff()"
            id="003_product_detail"
            href="javascript:void(0)"
            (click)="scrollToTabContainer(); nav.select(1)"
            >{{ 'wc.shopping.gui_productDetail.description.more' | translate }}</a
          >
        </div>

        <div class="o2-indent o2-indent--tight-top">
          <div *ngIf="productBought" class="o2-badge o2-badge--margin">
            {{ 'wc.shopping.gui_productDetail.basicInfo.customersBoughtCount' | translate }} {{ productBought }}
          </div>
        </div>

        <!-- Product color component -->
        <div *ngIf="productColorVariants && productColorVariants.length > 0" class="product__detail-colors">
          <div class="product__detail-label">
            Color: <span>{{ selectedColor }}</span>
          </div>
          <fieldset class="color-select">
            <ng-container *ngFor="let productColorVariant of productColorVariants">
              <!-- Color -->
              <div
                class="colour-variant"
                placement="bottom"
                [ngClass]="{ active: productColorVariant.productCode === product.productCode }"
                [routerLink]="['/eshop/product-detail/' + productColorVariant.seoUrl]"
                ngbTooltip="{{ productColorVariant.name }}">
                <div class="colour" [style.background-color]="'rgb(' + productColorVariant.rgb + ')'"></div>
              </div>
            </ng-container>
          </fieldset>
        </div>

        <!-- Tariff bundle -->
        <ng-container *ngIf="!archivedFlag">
          <div *ngIf="tariffBundles && tariffBundles.length" class="tariffBundle-container">
            <div class="product__detail-label">
              Bundle:
              <span *ngIf="selectedTariffBundle">{{ getBundleTariffProduct(selectedTariffBundle).name }}</span>
              <span *ngIf="!selectedTariffBundle">{{
                'wc.shopping.gui_productDetail.variants.hardwareOnly' | translate
              }}</span>
            </div>
            <form action="#">
              <!-- only hardware -->
              <div>
                <input
                  #noDiscountRadio
                  class=""
                  data-toggleval-hide="#o2ev-box"
                  id="discount-none"
                  name="discount-radio"
                  type="radio"
                  checked />
                <label class="tariffBundle-item" for="discount-none" (click)="selectHwProductVariant()">
                  <div *ngIf="tariffBundles && tariffBundles.length" class="tariffBundle-title">
                    {{ 'wc.shopping.gui_productDetail.variants.hardwareOnly' | translate }}
                  </div>
                </label>
              </div>

              <!-- bundles -->
              <ng-container *ngFor="let tariffBundle of tariffBundles; first as isFirst; index as discountIndex">
                <div>
                  <input
                    #discountRadio
                    class=""
                    name="discount-radio"
                    type="radio"
                    [id]="'discount-' + getBundleTariffProduct(tariffBundle).productCode + discountIndex" />
                  <label
                    class="tariffBundle-item"
                    [for]="'discount-' + getBundleTariffProduct(tariffBundle).productCode + discountIndex"
                    (click)="selectTariffBundle(tariffBundle, discountRadio)">
                    <div class="tariffBundle-title">
                      {{ getBundleTariffProduct(tariffBundle).name }}
                      <div
                        *ngIf="
                          getBundleDiscountPrice(tariffBundle).price &&
                          getBundleDiscountPrice(tariffBundle).priceAppType === 'ABS'
                        "
                        class="discountTag"
                        [innerHTML]="'save ' + (getBundleDiscountPrice(tariffBundle).price | price)"></div>
                      <div
                        *ngIf="
                          getBundleDiscountPrice(tariffBundle).price &&
                          getBundleDiscountPrice(tariffBundle).priceAppType === 'REL'
                        "
                        class="discountTag">
                        save {{ getBundleDiscountPrice(tariffBundle).price }}%
                      </div>
                    </div>

                    <div *ngIf="getBundleTariffPrice(tariffBundle).price" class="tariffBundle-price">
                      <app-recurrent-price [price]="getBundleTariffPrice(tariffBundle).price"></app-recurrent-price>
                    </div>
                  </label>
                </div>
              </ng-container>
            </form>
          </div>

          <div *ngIf="giftBundles && giftBundles.length" class="tariffBundle-container giftBundle-container">
            <div class="product__detail-label">
              Gift:
              <span *ngIf="selectedGiftBundle">{{ getBundleGiftProduct(selectedGiftBundle).name }}</span>
              <span *ngIf="!selectedGiftBundle">{{
                'wc.shopping.gui_productDetail.gifts.noGiftOption' | translate
              }}</span>
            </div>
            <form action="#">
              <!-- Without gift options -->
              <div class="gift-container">
                <input class="" id="present-none" name="present-radio" type="radio" checked [value]="undefined" />
                <label class="tariffBundle-item" for="present-none" (click)="selectedGiftBundle = null">
                  <div class="tariffBundle-title">
                    {{ 'wc.shopping.gui_productDetail.gifts.noGiftOption' | translate }}
                  </div>
                </label>
              </div>

              <!-- gifts -->
              <div *ngFor="let gift of giftBundles">
                <div class="gift-container">
                  <input
                    class=""
                    name="present-radio"
                    type="radio"
                    [id]="'present-' + getBundleGiftProduct(gift).productCode"
                    [value]="gift" />
                  <label
                    class="tariffBundle-item"
                    [for]="'present-' + getBundleGiftProduct(gift).productCode"
                    (click)="selectedGiftBundle = gift">
                    <div class="tariffBundle-title">
                      {{ getBundleGiftProduct(gift).name }}
                    </div>

                    <div class="tariffBundle-price">
                      <div
                        *ngIf="getBundleDiscountPrice(gift).price as crossedPrice"
                        class="oldPriceTag">
                        <div class="crossed_price_container">
                          <span class="crossed_price_container_span" [innerHTML]="crossedPrice | price"></span>
                          <hr />
                        </div>
                      </div>
                      <div
                        *ngIf="
                          getBundleGiftProduct(gift).prices['OC'].price -
                            getAbsolutePriceDiscount(gift, getBundleGiftProduct(gift).prices['OC'].price) >
                            0;
                          else freeGift
                        "
                        [innerHTML]="
                          getBundleGiftProduct(gift).prices['OC'].price -
                            getAbsolutePriceDiscount(gift, getBundleGiftProduct(gift).prices['OC'].price) | price
                        "></div>
                      <ng-template #freeGift
                        ><div>{{ 'wc.shopping.gui_productDetail.forFree' | translate }}</div>
                      </ng-template>
                    </div>
                  </label>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </ng-container>

        <!-- Product pricing -->
        <div class="product__detail-price" id="hw-price">
          <div class="product__detail-price_container">
            <div class="product__detail-wrapper">
              <ng-container *ngIf="testHwLeasing(); else non_hw_leasing">
                <div *ngIf="selectedHwLeasingId; else leasing_not_selected_yet" class="product__detail-price">
                  <div class="product__detail-price-tag">
                    {{ 'wc.shopping.hwLeasingPrice.label' | translate }}
                  </div>
                  <div *ngIf="selectedHwLeasingDetail" class="product__detail-price-val pull-right"></div>
                </div>
                <ng-template #leasing_not_selected_yet>
                  <div class="product__detail-price">
                    <div class="product__detail-price-tag">
                      {{ 'wc.shopping.selectHwLeasing.text' | translate }}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #non_hw_leasing>
                <div class="product__detail-price_main product__detail-price_price-type-wrapper">
                  <div class="product__detail-price_label">
                    {{ 'wc.shopping.oneTimePayment.label' | translate }}
                  </div>
                  <div class="product__detail-prices">
                    <!-- Main price for HW -->
                    <div class="product__detail-streetPrice">
                      <div class="product__detail-streetPrice_discountTag">
                        <one-time-price *ngIf="priceDiscount > 0" [crossed]="true" [price]="product.prices['OC'].price"></one-time-price>
                      </div>
                      <one-time-price
                        *ngIf="product.prices['OC']; else tariffPrice"
                        class="product__detail-streetPrice_OC"
                        [price]="
                          tariffHwProduct
                            ? tariffHwProduct.prices['OC'].price
                            : product.prices['OC'].price - priceDiscount
                        "></one-time-price>
                      <ng-template #tariffPrice>
                        <app-recurrent-price
                          [price]="tariffHwProduct ? tariffHwProduct.prices['RC'].price : product.prices['RC'].price">
                        </app-recurrent-price>
                      </ng-template>
                    </div>

                    <!-- Main price without VAT for HW -->
                    <div
                      *ngIf="product.prices['OC']; else tariffTaxPrice"
                      class="product__detail-noTax"
                      [innerHTML]="
                        (product.prices['OC'].price - priceDiscount
                          | price: 1 + (product.prices['OC'].tax ? product.prices['OC'].tax : 0) / 100) +
                        ' ' +
                        ('wc.shopping.gui_productDetail.general.withoutVat.label' | translate)
                      "></div>
                    <ng-template #tariffTaxPrice>
                      <div
                        class="product__detail-noTax"
                        [innerHTML]="
                          (product.prices['RC'].price - priceDiscount
                            | price: 1 + (product.prices['RC'].tax ? product.prices['RC'].tax : 21) / 100) +
                          ' ' +
                          ('wc.shopping.gui_productDetail.general.withoutVat.label' | translate)
                        "></div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedTariffBundle" class="product__detail-price_price-type-wrapper">
                  <div class="product__detail-price_label">
                    {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                  </div>
                  <!-- Selected tariff bundle price -->
                  <div class="product__detail-price_RC">
                    <app-recurrent-price
                      [disableTexts]="true"
                      [price]="getBundleTariffPrice(selectedTariffBundle).price"></app-recurrent-price>
                    <div
                      class="product__detail-noTax"
                      [innerHTML]="
                        (getBundleTariffPrice(selectedTariffBundle).price
                          | price
                            : 1 +
                                (getBundleTariffPrice(selectedTariffBundle).tax
                                  ? getBundleTariffPrice(selectedTariffBundle).tax
                                  : 21) /
                                  100) +
                        ' ' +
                        ('wc.shopping.gui_productDetail.general.withoutVat.label' | translate)
                      "></div>
                  </div>
                </div>
              </ng-template>
            </div>
            <!-- Main Actions -->
            <div class="product__detail-actionContainer" id="{{ priceBoxElementId }}">
              <ng-container *ngIf="!checkScanRequired; else technology_check">
                <ng-container *ngIf="stockCentral$ | async as stock">
                  <app-button
                    *ngIf="stock.realStock > 0; else noStock"
                    id="product-detail-button-buy"
                    variant="primary"
                    iconPath="/assets/svg/shopping-cart-icon-small.svg"
                    size="fullWidth"
                    label="{{ 'wc.common.buyNow.button' | translate }}"
                    (click)="addAllToBasket()"></app-button>
                  <app-button
                    *ngIf="stock.realStock > 0"
                    id="product-detail-button-add-cart"
                    variant="whiteWithBorder"
                    size="fullWidth"
                    label="{{ 'wc.ecare.productListing.addToCart' | translate }}"
                    (click)="addAllToBasket(true)"></app-button>
                </ng-container>
                <ng-template #noStock>
                  <app-button
                    *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'"
                    id="product-detail-button-watchdog"
                    iconPath="assets/svg/price-box-watch.svg"
                    variant="whiteWithBorder"
                    size="fullWidth"
                    label="{{ 'wc.shopping.gui_productDetail.watchdog.formButton' | translate }}"
                    (click)="openWatchdogSubscriptionModal()"></app-button>
                </ng-template>
              </ng-container>
              <ng-template #technology_check>
                <app-button
                  id="product-detail-button-technology"
                  iconPath="/assets/svg/shopping-cart-icon-small.svg"
                  variant="primary"
                  size="fullWidth"
                  label="{{ 'wc.common.buyNow.button' | translate }}"
                  (click)="goToTechnologyCheck(product.id, selectedTariffId)">
                </app-button>
              </ng-template>
            </div>
          </div>
          <div class="product__detail-price_bottom">
            <div class="product__detail-price_bottom-stock_info">
              <div *ngIf="stockCentral$ | async as stock; else stockUnavailable">
                <div *ngIf="stock.realStock > 0; else noStock">
                  <span [innerHTML]="stock.text | safeHtml"></span>
                  <span *ngIf="deliveryDate"
                    >, {{ 'wc.shopping.gui_productDetail.delivery.deliveryDate' | translate }}
                    {{ deliveryDate | deliveryDate }}</span
                  >
                </div>
                <ng-template #noStock>
                  <div class="no-stock-info">
                    <span [innerHTML]="stock.text | safeHtml"></span>
                    <div
                      *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'"
                      ngbTooltip="{{ 'wc.shopping.gui_productDetail.delivery.noStockAvailabilityInfo' | translate }}">
                      <a id="004_product_detail" (click)="openWatchdogSubscriptionModal()">
                        <i [inlineSVG]="'assets/svg/feather-info-small.svg'"></i>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
              <ng-template #stockUnavailable>
                {{ 'wc.shopping.gui_productDetail.stockUnavailable' | translate }}
              </ng-template>
            </div>
            <div class="product__detail-price_bottom-icons_bar">
              <a
                *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'"
                class="product__detail-price_bottom-icon"
                id="005_product_detail"
                placement="bottom"
                ngbTooltip="{{ 'wc.shopping.gui_productDetail.watchdog.formButton' | translate }}"
                (click)="openWatchdogSubscriptionModal()">
                <i [inlineSVG]="'assets/svg/price-box-watch.svg'"></i>
              </a>
              <ng-container *ngIf="compareAvailable">
                <a
                  *ngIf="!isProductInComparison(product); else showRemove"
                  class="product__detail-price_bottom-icon"
                  id="007_product_detail"
                  placement="bottom"
                  ngbTooltip="{{ 'wc.shopping.gui_productDetail.productComparison.addLink' | translate }}"
                  (click)="addProductToComparison(product, maxProductsInComparison)">
                  <i [inlineSVG]="'assets/svg/price-box-compare.svg'"></i>
                </a>
                <ng-template #showRemove>
                  <a
                    class="product__detail-price_bottom-icon"
                    id="009_product_detail"
                    placement="bottom"
                    ngbTooltip="{{ 'wc.shopping.gui_productDetail.productComparison.removeLink' | translate }}"
                    (click)="removeProductFromComparison(product)">
                    <i [inlineSVG]="'assets/svg/price-box-compare.svg'"></i>
                  </a>
                </ng-template>
              </ng-container>
              <a
                *ngIf="product.parametersStatic['wishlistEnabled'] === 'true'"
                id="product-detail-button-add-wishlist"
                class="product__detail-price_bottom-icon"
                placement="bottom"
                ngbTooltip="{{ 'wc.shopping.gui_wishlist.addToWishList.heading' | translate }}"
                (click)="wishListPopup()">
                <i [inlineSVG]="'assets/svg/price-box-heart.svg'"></i>
              </a>
            </div>
          </div>
        </div>

        <app-sticky-price-bar
          [checkScanRequired]="checkScanRequired"
          [product]="product"
          [selectedTariffId]="selectedTariffId"
          [stockCentral$]="stockCentral$"
          [tariffHwProduct]="tariffHwProduct"
          [priceDiscount]="priceDiscount"
          (addToBasketAction)="addAllToBasket()"
          (goToTechnologyCheckAction)="goToTechnologyCheck(product.id, selectedTariffId)"></app-sticky-price-bar>
        <!-- Stock availability -->
        <div *ngIf="stockAvalibility" class="product__detail-availability">
          <a
            *ngIf="storeCount$ | async as storeCount"
            id="product-detail-button-store-count"
            class="link"
            (click)="selectPreferredShop()">
            {{ 'wc.shopping.gui_productDetail.storeCount' | translate: { storeCount: storeCount.storeCount } }}
          </a>
        </div>

        <div *ngIf="preferredStock$ | async as stock">
          <span *ngIf="stock && stock.text; else stockUnavailable" [innerHTML]="stock.text | safeHtml"></span>
        </div>
        <!-- END Stock availability -->
      </div>
    </div>

    <!-- Product features -->
    <app-section-tabs #sectionTabs #nav="ngbNav" ngbNav [destroyOnHide]="false">
      <li [ngbNavItem]="1">
        <a ngbNavLink>{{ 'wc.common.details' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="product__detail-longDescription">
            <div
              *ngIf="product.longDescription"
              class="product__detail-longDescription-context"
              [innerHTML]="product.longDescription | safeHtml"></div>
          </div>
        </ng-template>
      </li>
      <li *ngIf="keyFeatureParams.length > 0" [ngbNavItem]="2">
        <a ngbNavLink>{{ 'wc.shopping.gui_productDetail.keyFeatures.header' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="product__detail-features">
            <div class="product__detail-featuresImageWrapper">
              <img [src]="dimensionPictureHref ? dimensionPictureHref : '/assets/img/products/preview2.png'" />
            </div>
            <div
              *ngIf="keyFeatureParams"
              app-key-features
              class="product__detail-featuresList"
              id="product__detail-featuresList"
              [keyFeatureParams]="keyFeatureParams.slice(0, 8)"></div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="tabSwitchedToProductRating()">{{
          'wc.shopping.gui_productDetail.rating.header' | translate
        }}</a>
        <ng-template ngbNavContent>
          <div
            app-product-ratings-and-reviews
            [productCode]="this.product.productCode"
            [product]="this.product"
            (ratingLoaded)="setRating($event)"
            (reviewDetails)="setReviewDetails($event)"></div>
        </ng-template>
      </li>
      <li *ngIf="documents && documents.length > 0" [ngbNavItem]="4">
        <a ngbNavLink>{{ 'wc.shopping.gui_productDetail.documents.header' | translate }}</a>
        <ng-template ngbNavContent>
          <div *ngFor="let document of documents" class="product__detail-documents">
            <a
              id="0016_product_detail"
              href="javascript:void(0)"
              class="link"
              title="{{ getDmsFileText(document, 'DESCRIPTION') }}"
              (click)="download(document)">
              {{ getDmsFileText(document, 'NAME') }}
            </a>
          </div>
        </ng-template>
      </li>
      <li *ngIf="compatibleAccessories && compatibleAccessories.length" [ngbNavItem]="5">
        <a ngbNavLink>{{ 'wc.shopping.gui_productDetail.accessories.header' | translate }}</a>
        <ng-template ngbNavContent>
          <div #accessoriesAnchor id="eshop-accessories"></div>
          <div
            app-product-carousel
            layoutClass=""
            [count]="accessoriesCount"
            [productDetailDtos]="compatibleAccessories"
            description="{{ 'wc.shopping.gui_productDetail.accessories.info' | translate }}"></div>
        </ng-template>
      </li>
    </app-section-tabs>

    <div [ngbNavOutlet]="nav"></div>

    <div
      *ngIf="similarProducts && similarProducts.length && similarProductCount"
      app-product-carousel
      layoutClass=""
      [count]="similarProductCount"
      [productDetailDtos]="similarProducts"
      label="{{ 'wc.shopping.gui_productDetail.similarProducts.header' | translate }}"
      [nextPageAllowed]="true"></div>

    <div
      *ngIf="viewedProducts && viewedProducts.length"
      app-product-carousel
      layoutClass=""
      nextPageAllowed="true"
      [count]="viewedProductsCount"
      [productDetailDtos]="viewedProducts"
      label="{{ 'wc.shopping.gui_productDetail.viewedProducts.header' | translate }}"></div>

    <div
      *ngIf="soldProducts && soldProducts.length"
      app-product-carousel
      layoutClass=""
      nextPageAllowed="true"
      [count]="soldProductsCount"
      [productDetailDtos]="soldProducts"
      label="{{ 'wc.shopping.gui_productDetail.soldProducts.header' | translate }}"></div>

    <div
      *ngIf="additionalServices && additionalServices.length"
      app-product-carousel
      layoutClass=""
      nextPageAllowed="true"
      [productDetailDtos]="additionalServices"
      label="{{ 'wc.shopping.gui_productDetail.additionalServices.header' | translate }}"></div>
  </div>
</div>
