import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';
import { AssetDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-tv-tariff-widget, [app-ecare-tv-tariff-widget]',
  templateUrl: './ecare-tv-tariff-widget.component.html'
})
export class EcareTvTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {
  channelCount: number;
  serviceAddons: string;

  ngOnInit() {
    super.ngOnInit();
  }

  protected initTariffData() {
    super.initTariffData();
    const channelCountParameters = this.group.groupParams['widget_ChannelCountParamNames'];
    const unitParameters = this.group.groupParams['widget_UsageUnitsParamNames'];
    this.channelCount = Number.parseInt(
      this.getFirstFoundParameterValue(this.subscription.assets[0], channelCountParameters),
    );

    let addons: AssetDto[] = [];
    this.getAssetsByGroup(this.asset, this.getWidgetElementGroup('ADDONS')?.id, addons);

    this.serviceAddons = addons.map(asset => asset.product.name).join(',');
  }
}
