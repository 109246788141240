import { Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb, WishListService } from '@btl/btl-fe-wc-common';
import { Router } from '@angular/router';
import { WishListDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { DeleteWishListPopupComponent } from '../delete-wish-list-popup/delete-wish-list-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WishListPopupComponent } from '../wish-list-popup/wish-list-popup.component';

@Component({
  selector: 'app-wish-lists',
  templateUrl: './wish-lists.component.html',
})
export class WishListsComponent implements OnInit, OnDestroy {
  breadcrumbs: Array<Breadcrumb> = WishListsComponent.getBreadCrumbs();
  wishLists: Array<WishListDto> = [];
  quantityOfItemsInShoppingCart: number = 0;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private wishListService: WishListService,
    private shoppingCartService: ShoppingCartService,
    private ngbModal: NgbModal,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.initWishLists();
    this.wishListService.wishListChange.subscribe(wishList => {
      this.initWishLists();
    });
    this.quantityOfItemsInShoppingCart =
      this.shoppingCartService.preCalculatedShoppingCart?.quantityOfItemsInShoppingCart;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private initWishLists() {
    this.wishListService
      .getWishLists(false)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishLists => {
        this.wishLists.length = 0;
        wishLists.forEach(wishList => {
          this.wishLists.push(wishList);
        });
      });
  }

  public static getBreadCrumbs() {
    return [
      { name: 'wc.shopping.gui_wishlist.breadCrumb.wishlists', seoUrl: '/eshop/wish-lists' },
    ];
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
  }

  deleteWishlistPopup(wishList) {
    const modalRef = this.ngbModal.open(DeleteWishListPopupComponent, {
      size: 'sm',
    });
    const deleteWishListPopupComponent = <DeleteWishListPopupComponent>modalRef.componentInstance;
    deleteWishListPopupComponent.dialogRef = modalRef;
    deleteWishListPopupComponent.wishList = wishList;
  }
}
