import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityGeneratedFormComponent } from '../../entity-generated-form/entity-generated-form.component';
import { Subscription } from 'rxjs';
import { AddressDto } from '@btl/order-bff';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @ViewChild('addressEntityGeneratedForm', {static: false}) addressFormComponent: EntityGeneratedFormComponent;

  @Input() parentGroup: FormGroup;
  @Input() form: FormGroup;
  @Input() type: string;
  @Input() translationPrefix: string;
  @Input() main: boolean = false;
  @Input() simpleCustomer: boolean = false;
  @Input() columnsCount: number = 2;
  @Input() validate = true;
  @Input() entityGenerateForm = false;
  @Input() checkFormVisibility = true;
  @Input() showHeader = true;
  @Input() sectionView = true;
  @Input() formIdPrefix = '';
  @Input() formName: string;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  @Input() sameAsForm: FormGroup = null;

  @Input() sameAsObject: AddressDto = null;

  @Input() sameAsTranslationKey;

  @Input() hide = false;
  patchAddress;

  formGenerated = false;

  inputMode = true;

  doNotValidate = false;

  disabled = false;

  sameAsSubscription: Subscription;

  constructor(private fb: FormBuilder) {
  }

  public ngOnInit() {
    if (this.form) {
      this.form.get('type')?.setValue(this.type);
    }
  }

  public static getBaseFormConfiguration() {
    return {
      id: [null],
      type: [null],
    };
  }

  setSameAs(sameAs: boolean) {
    this.inputMode = !sameAs;

    if (sameAs) {
      if (this.sameAsForm) {
        this.sameAsSubscription = this.sameAsForm.valueChanges.subscribe(value => {
          let address = this.sameAsForm.getRawValue();
          address.id = null;
          this.form.patchValue(address);
          this.form.get('type').setValue(this.type);
        });
        let address = this.sameAsForm.getRawValue();
        address.id = null;
        this.form.patchValue(address);
        this.form.get('type').setValue(this.type);
        this.sameAsObject = null;
      }
      if (this.sameAsObject) {
        this.sameAsObject['id'] = null;
        this.form.patchValue(this.sameAsObject);
        this.form.get('type').setValue(this.type);
        this.form.disable();
        this.sameAsForm = null;
      }
    } else {
      this.form.enable();
      this.unsubscribeSameAs();
    }

    if (this.sameAsForm || this.sameAsObject) {
      this.main = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSameAs();
  }

  unsubscribeSameAs() {
    if (this.sameAsSubscription) {
      this.sameAsSubscription.unsubscribe();
    }
  }

  disable(disable: boolean) {
    this.disabled = disable;
  }

  addressFormGenerated() {
    if (this.addressFormComponent.formGenerated) {
      if (this.patchAddress) {
        this.form.patchValue(this.patchAddress);
      }
      this.formGenerated = true;
      this.patchAddress = null;
      this.formGeneratedEmitter.emit();
    }
  }

  resetForm() {
    if (this.addressFormComponent) {
      this.addressFormComponent.resetForm();
      this.form.get('type').setValue(this.type);
    }
    this.patchAddress = null;
  }

  public generateForm(address) {
    this.patchAddress = address;
    this.entityGenerateForm = true;
    return this.formGeneratedEmitter;
  }
}
