<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.payInvoice.heading' | translate }}
    <div class="modal-subtitle">
      {{ invoiceDto.id }}
    </div>
  </h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div *blockUI="invoicePayBlockUiElementName; template: blockTemplate" class="modal-body">
  <div class="invoice-data">
    <div class="-remaining-amount -col-60-pct">
      <h5>{{ 'wc.ecare.financialDocument.remainingAmount.header' | translate }}</h5>
      <span class="currency-symbol-{{ invoiceDto.remainingAmount.units }}">{{
        invoiceDto.remainingAmount.amount
      }}</span>
    </div>
    <div class="-issue-date -col-40-pct">
      <h5>{{ 'wc.ecare.financialDocument.issueDate.header' | translate }}</h5>
      {{ invoiceDto.billDate | date }}
    </div>
  </div>

  <div *ngIf="generalFailure" class="alert alert-danger">
    <div>
      {{ 'wc.ecare.recharge.generalFailure.text' | translate }}
    </div>
  </div>

  <form class="form invoice-pay-form" [formGroup]="payForm">
    <div class="form-group-row">
      <div class="radio">
        <input
          id="006_radio-pay-full"
          name="radio"
          type="radio"
          checked
          (change)="changePaymentAmountSelection('full')" />
        <label for="006_radio-pay-full" class="radio-label">{{
          'wc.ecare.payInvoice.payFullAmount.label' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group-row">
      <div class="radio -col-60-pct">
        <input id="007_radio-pay-custom" name="radio" type="radio" (change)="changePaymentAmountSelection('custom')" />
        <label for="007_radio-pay-custom" class="radio-label">{{
          'wc.ecare.payInvoice.payCustomAmount.label' | translate
        }}</label>
      </div>

      <div *ngIf="paymentAmountSelectionType === 'custom'" class="custom-pay-amount -col-40-pct">
        <app-input-row id="003_invoice_pay" fieldName="payAmount" [translationPrefix]="'wc.ecare'" [parentGroup]="payForm" [showLabel]="false">
        </app-input-row>
        <div class="currency-symbol currency-symbol-EUR"></div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button
    id="004_invoice_pay"
    variant="link"
    label="{{ 'wc.common.cancel.button' | translate }}"
    (click)="dialogRef.dismiss()">
  </app-button>
  <app-button
    iconPathAfter="/assets/svg/arrow-dropright-icon.svg"
    id="005_invoice_pay"
    variant="primary"
    label="{{ 'wc.ecare.payInvoice.pay.button' | translate }}"
    (click)="pay()">
  </app-button>
</div>
