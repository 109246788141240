<div class="agreement border">
  <div class="agreement-header" [formGroup]="agreementForm">
    <div>
      <input
        [attr.disabled]="(displayMode === 'ONLY_OPTIONAL' && agreementForm.value.agreed && agreementForm.value.type.mandatory) ? true : null"
        class="checkbox"
        formControlName="agreed"
        type="checkbox"
        [id]="
          'form_id_' +
          agreementForm.value.entityType +
          '_' +
          agreementForm.value.entityId +
          '_' +
          agreementForm.value.type.id
        "
        (ngModelChange)="agreedChanged()" />
      <label
        class="-align-center"
        [for]="
          'form_id_' +
          agreementForm.value.entityType +
          '_' +
          agreementForm.value.entityId +
          '_' +
          agreementForm.value.type.id
        ">
        {{ 'wc.shopping.customerCreation.agreementWith.label' | translate }}
        <span *ngIf="getAgreementTypeText(agreementForm.value.type, 'AgreementTypeName')"
              [innerHTML]="getAgreementTypeText(agreementForm.value.type, 'AgreementTypeName') | safeHtml">
        </span>
        <span
          *ngIf="agreementForm.value.type.mandatory"
          class="required"
          [for]="
            'form_id_' +
            agreementForm.value.entityType +
            '_' +
            agreementForm.value.entityId +
            '_' +
            agreementForm.value.type.id
          "
          >*
        </span>
        <app-input-errors
          suffix=".agreed"
          [control]="agreementForm.get('agreed')"
          [translationPrefix]="'wc.common.agreement'">
        </app-input-errors>
      </label>
    </div>
  </div>

  <div class="agreement-collapse" [class.open]="!collapsed"
       *ngIf="getAgreementTypeText(agreementForm.value.type, 'AgreementTypeDescription')">
    <div
      app-button
      class="showMore"
      variant="secondary"
      size="small"
      iconPathAfter="/assets/svg/menu-down-arrow.svg"
      label="{{ 'wc.shopping.gui_productDetail.description.more' | translate }}"
      (click)="collapsed = !collapsed"></div>
    <div class="agreement-collapse_text" *ngIf="!collapsed">
      <div
        [innerHTML]="getAgreementTypeText(agreementForm.value.type, 'AgreementTypeDescription')  | safeHtml">
      </div>
      <div *ngIf="pagedDocuments" class="agreement-documents">
        <ng-container *ngFor="let dmsFile of pagedDocuments.data">
          <div class="agreement-documents_item" (click)="download(dmsFile)">
            {{ dmsFile.name }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
