<tr class="ecare_listing-table-row clickable" (click)="onRowClick()">
  <td class="no-wrap">
    {{ customerAccount.displayName }}
  </td>
  <td class="no-wrap">
    {{ customerAccount.parameters['variableSymbol'] }}
  </td>
  <td class="no-wrap">
    {{ customerAccount.id }}
  </td>
  <td class="no-wrap">
    {{ customerAccount.extId }}
  </td>
  <td class="no-wrap">
    {{ customerAccount.parameters['paymentType'] }}
  </td>
  <td class="no-wrap content-sm">
    {{ customerAccount.address | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
  </td>
</tr>
