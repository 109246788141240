import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-header, [app-section-header]',
  templateUrl: './section-header.component.html',
})
export class SectionHeaderComponent {
  @Input() title: string;
  @Input() rightText: string;
  @Input() textAlign?: 'left' | 'right' | 'center';
  @Input() isCollapsed?: boolean;
  @Input() editable?: boolean;
  @Input() link?: string;
  @Input() size?: 'small';
}
