import { Component, OnInit } from '@angular/core';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-business-dashboard',
  templateUrl: './ecare-business-dashboard.component.html',
})
export class EcareBusinessDashboardComponent implements OnInit {
  customerAccounts: CustomerAccountDto[];
  customer: CustomerDto;

  constructor(private readonly customerLocalStorageService: CustomerLocalStorageService) {}

  ngOnInit(): void {
    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    this.customerAccounts = CustomerPartyUtil.getChildCustomerAccounts(this.customer.childParties);
  }
}
