<div class="sorting-wrapper">
  <div class="desc-text">{{ totalResults }} {{ 'wc.shopping.productFilter.results.label' | translate }}</div>
  <div class="sort-container" [formGroup]="form">
    <span class="desc-text">{{ 'wc.shopping.productFilter.sortBy.label' | translate }}:</span>
    <app-input-select-form-field
      placement="bottom-right"
      class="d-inline-block"
      formControlName="sort"
      translationPrefix="wc.shopping.gui_productListing.sortingType.productFilter"
      labelOption="label"
      valueOption="value"
      [doNotUseFilter]="true"
      [options]="sortings"
      [disableOptionsTranslate]="false"
      [noLabel]="true"
      (selectionChangeEmit)="changeSorting($event)" />
  </div>
</div>
