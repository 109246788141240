import { Component, Renderer2 } from '@angular/core';
import { ThemeService, ThemeType } from '@service/theme.service';
import { environment } from '../../../../../environments/environment';
import {
  AbstractPageComponent,
  AuthFactoryService,
  CurrentLocaleService,
  EnableDynamicLoading,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

export enum AppFontSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

@Component({
  selector: 'app-ecare-user-account-appearance',
  templateUrl: './ecare-user-account-appearance.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountAppearanceComponent.PAGE_ID })
export class EcareUserAccountAppearanceComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareUserAccountAppearanceComponent';

  pageId(): string {
    return EcareUserAccountAppearanceComponent.PAGE_ID;
  }

  locales = environment.localization.locales;

  selectedThemeCssClass: ThemeType;
  selectedFontSize = AppFontSize.NORMAL;
  selectedLocale = this.currentLocaleService.getCurrentLanguage();

  constructor(
    private themeService: ThemeService,
    private currentLocaleService: CurrentLocaleService,
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService,
    private renderer: Renderer2
  ) {
    super(router, route);
    this.themeService.getThemeColor().subscribe(theme => (this.selectedThemeCssClass = theme));
    this.currentLocaleService.currentLocaleChange.subscribe(
      () => (this.selectedLocale = this.currentLocaleService.getCurrentLanguage())
    );
  }

  get themeVariants(): ThemeType[] {
    return this.themeService.getColorVariants();
  }

  chooseThemeVariant(theme: ThemeType) {
    this.themeService.setThemeColor(theme);
    this.themeVariants.forEach(th => this.renderer.removeClass(document.body, th));
    this.renderer.addClass(document.body, theme);
  }

  get fontSizes(): AppFontSize[] {
    return Object.values(AppFontSize);
  }

  chooseFontSize(fontSize: AppFontSize) {
    this.selectedFontSize = fontSize;
  }

  chooseLanguage(locale: string) {
    this.currentLocaleService.setCurrentLanguage(locale, this.authFactoryService.getAuthService());
    this.selectedLocale = this.currentLocaleService.getCurrentLanguage();
  }
}
