<div *ngIf="depositOptions && this.scoringResult" class="checkout-page">
  <!-- Generate content for mobile -->
  <div class="d-lg-none">
    <app-checkout-shopping-cart-secondary-actions></app-checkout-shopping-cart-secondary-actions>
    <app-ordering-wizard></app-ordering-wizard>
  </div>
  <!-- END Generate content for mobile -->
  <div class="checkout-page_form">
    <ng-container>
      <app-checkout-deposit-items
        [resultFlag]="resultFlag"
        [order]="wcOrderingService.currentOrder"
        [manualDepositProducts]="manualDepositProducts"
        [scoringResult]="scoringResult"
        (depositChangedEmitter)="depositChanged($event)"
        (manualDepositChangedEmitter)="manualDepositChanged($event)"
        (manualDepositRemovedEmitter)="manualDepositRemoved($event)">
      </app-checkout-deposit-items>
      <div app-acl-internal-evaluate-component>
        <div class="section">
          <ng-container>
            <!-- Add new deposit -->
            <div *ngIf="resultFlag === 'ORANGE'" class="form-group-row">
              <label for="newDeposit">
                <button id="001_checkout_scoring" class="btn btn-default btn-sm" (click)="addManualDeposit()">
                  {{ 'wc.shopping.gui_checkoutScoring.addManualDeposit.label' | translate }}
                </button>
              </label>
              <div class="input select">
                <select id="newDeposit" [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedDeposit">
                  <option *ngFor="let deposit of getNotAddedDeposits()" [ngValue]="deposit">
                    {{ deposit.name }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- Right side of checkout - shopping cart -->
  <app-checkout-shopping-cart
    [disableContinueButton]="this.scoringResult.resultFlag === 'RED'"
    [checkFormAction]="continue.bind(this)">
  </app-checkout-shopping-cart>
</div>
