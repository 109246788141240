import { NtsPaymentDetails } from 'app/models/nts-payment-details';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PaymentComponent } from 'app/components/wizard/payment/payment.component';
import { OrderStateTypeEnum } from '../../../models/orderStateTypeEnum';
import { OrderUtils } from '../../../helpers/order-utils';
import { OrderingWizardService, PaymentStatusEnum } from '@service/ordering-wizard-service';
import { PaymentService } from '@service/payment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OrderDto } from '@btl/order-bff';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
})
export class PaymentResponseComponent implements OnDestroy, OnChanges {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  paymentFailure = false;
  paymentTechnicalFailure = false;
  paymentFailedReason = null;
  paymentCanceled = false;
  paymentDetails: NtsPaymentDetails = null;
  orderStateTypeEnum = OrderStateTypeEnum;
  paymentStatusEnum = PaymentStatusEnum;
  blockingReason: string;
  paymentStatus;

  orderId;

  @Input()
  customerFirstName;

  @Input()
  customerEmail;

  @Input()
  orderDto: OrderDto;

  @Input()
  paymentParams;

  @Output()
  reloadOrderEvent = new EventEmitter<void>();

  constructor(private paymentService: PaymentService) {}

  ngOnInit(): void {
    if (this.orderDto) {
      this.initOrderParams();
    }

    if (this.paymentParams) {
      this.orderId = this.paymentParams.orderId;

      if (this.paymentParams[PaymentComponent.PAYMENT_FAIL_URL_QUERY_PARAMETER]) {
        this.paymentFailure = true;
      }

      if (this.paymentParams[PaymentComponent.PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER]) {
        this.paymentTechnicalFailure = true;
      }
      const paymentId = this.paymentParams[PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER];
      this.paymentDetails = {
        ntsOrderId: paymentId
          ? paymentId
          : OrderUtils.getOrderAttributeValue(this.orderDto, OrderingWizardService.PAYMENT_ID_ATTR),
        internalOrderId: this.paymentParams[PaymentComponent.NTS_INTERNAL_ORDER_ID_URL_QUERY_PARAMETER],
        ntsTransactionId: this.paymentParams[PaymentComponent.NTS_TRANSACTION_ID_URL_QUERY_PARAMETER],
      };
    }
  }

  initOrderParams() {
    this.paymentFailedReason = OrderUtils.getOrderAttributeValue(this.orderDto, 'paymentFailedReason');
    this.paymentStatus = OrderUtils.getOrderAttributeValue(this.orderDto, OrderingWizardService.PAYMENT_STATUS_ATTR);
    this.blockingReason = OrderUtils.getOrderAttributeValue(this.orderDto, OrderingWizardService.BLOCKING_REASON_ATTR);
    this.paymentCanceled = this.paymentStatus === PaymentStatusEnum.CANCELED;
    this.paymentFailure = this.paymentStatus === PaymentStatusEnum.FAILED;
  }

  payOrder() {
    this.paymentService
      .reprocessOrderPayment(this.orderDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.reloadOrderEvent.emit();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderDto'] != null) {
      this.initOrderParams();
    }
  }
}
