<div class="section" [formGroup]="locationForm">
  <div class="inner" [formGroup]="locationForm">
    <h4 id="001_location_form">{{ 'wc.shopping.location.heading' | translate }} {{ this.locationId }}</h4>
    <ng-container>
      <app-input-row
        id="002_location_form"
        fieldName="address"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="003_location_form"
        fieldName="name"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="004_location_form"
        fieldName="block"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="005_location_form"
        fieldName="blockNo"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="006_location_form"
        fieldName="site"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="007_location_form"
        fieldName="adminCentre"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="008_location_form"
        fieldName="entrance"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="009_location_form"
        fieldName="floor"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="010_location_form"
        fieldName="apartment"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="011_location_form"
        fieldName="doorTitle"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="012_location_form"
        fieldName="extId"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="013_location_form"
        fieldName="municipality"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="014_location_form"
        fieldName="longitude"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="015_location_form"
        fieldName="latitude"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
      <app-input-row
        id="016_location_form"
        fieldName="note"
        translationPrefix="wc.shopping.location"
        [parentGroup]="locationForm">
      </app-input-row>
    </ng-container>

    <button *ngIf="!readOnly" id="017_location_form" class="btn btn-default">Set</button>
  </div>
</div>
