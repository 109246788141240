import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-text, [app-input-text]',
  templateUrl: './input-text.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputTextComponent {
  @Input() parentGroup: FormGroup;
  @Input() controlName: string;

  @Input() placeholder?: string;
  @Input() label?: string;
  @Input() inputId?: string;
  @Input() shape?: 'rectangle' | 'round' | 'semi-round';
  @Input() iconPath?;
  @Input() iconPathAfter?;
  @Input() size?: 'small' | 'medium' | 'large' | 'fullWidth';
  @Input() fullWidth?: boolean;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
}
