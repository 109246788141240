import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AddressHolder } from '../../../models/address-holder';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BlockTemplateComponent,
  CodebookService,
  CurrentLocaleService,
  ElasticsearchService,
  NetworkInventoryService,
  ProductGroupService,
  ProductService
} from '@btl/btl-fe-wc-common';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { FiltersComponent } from '../product-listing/filters/filters.component';
import { ProductElasticFilter } from '../../../models/product-elastic-filter';
import { AddressService } from '@service/address.service';
import { FilterService } from '@service/filter.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import {
  LocationDto,
  OrderDto,
  OrderParamDto,
  TechnologyScanInputSocketDto,
  TechnologyScanResultDto,
  TpSocketDto
} from '@btl/order-bff';
import { OrderUtils } from '../../../helpers/order-utils';
import { WcOrderingService } from '@service/wc-ordering.service';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { addressTypes, DisplayAddressPipe } from '../../../pipes/display-address.pipe';
import _ from 'lodash';
import { FormUtils } from '../../../helpers/form-utils';
import { OrderingWizardActionsComponent } from '../../page/ordering-wizard-actions/ordering-wizard-actions.component';
import { TicketService } from '@service/ticket.service';

import { Constants } from '../../../constants';
import { ProductAddingToCart } from '@service/product-listing.service';
import { Keys } from '@service/keys';

@Component({
  selector: 'app-technology-check',
  templateUrl: './technology-check.component.html',
  styleUrls: ['./technology-check.component.scss'],
})
export class TechnologyCheckComponent implements OnInit, OnDestroy {
  protected onDestroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  @ViewChild('addressSearch', { static: false }) private searchInput: ElementRef;
  @ViewChild('orderingWizardActions', { static: true }) orderingWizardActions: OrderingWizardActionsComponent;
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  PRODUCT_ID_URL_PARAM: string = 'productId';
  GIFT_PRODUCT_ID_URL_PARAM: string = 'giftProductId';
  DISCOUNT_PRODUCT_ID_URL_PARAM: string = 'discountProductId';
  HW_PRODUCT_ID_URL_PARAM: string = 'hwProductId';
  BACK_URL_PARAM: string = 'backUrl';
  SERVICE_NOT_AVAILABLE_URL_PARAM: string = 'serviceNotAvailable';
  paramProductId: string;
  paramGiftProductId: string;
  paramDiscountProductId: string;
  paramHwProductId: string;
  PRODUCT_PARTY_ID_URL_PARAM: string = 'originalProductPartyId';
  paramOriginalProductPartyId: string;

  PRODUCT_LOCATION_ID_URL_PARAM: string = 'originalProductLocationId';
  paramOriginalProductLocationId: string;

  PRODUCT_SOCKET_ID_URL_PARAM: string = 'originalProductSocketId';
  paramOriginalProductSocketId: string;

  private searchTerms = new Subject<string>();
  private autocompleteProducts: Observable<AddressHolder[]>;
  private maxAutocompleteResults: number;
  private maxAutocompleteResultsSubscription: Subscription;
  private filter: ProductElasticFilter;

  selectedLocation: LocationDto;
  selectedSocket: TechnologyScanInputSocketDto;
  selectedAddress: TechnologyScanInputAddressDto;

  locationsOptions: LocationDto[];
  socketOptions: TpSocketDto[];

  location: boolean = false;
  socket: boolean = false;
  serviceNotAvailable: boolean = false;
  canContinue: boolean = false;
  fillInputs: boolean = true;
  scanResult: Array<TechnologyScanResultDto>;
  addressTypes = addressTypes;

  technologyForm: FormGroup;
  private paramBackUrl: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private productGroupService: ProductGroupService,
    private addressService: AddressService,
    private filterService: FilterService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private wcOrderingService: WcOrderingService,
    private lightNetworkInventory: NetworkInventoryService,
    private productService: ProductService,
    private elasticSearchService: ElasticsearchService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.technologyForm = this.fb.group({
      address: [null, Validators.required],
      locationCheckBox: [{ value: false, disabled: true }],
      location: [null, Validators.required],
      socketCheckBox: [{ value: false, disabled: true }],
      socket: [null],
    });
  }

  ngOnInit() {
    this.fillProductIdUrlParams();
    this.initAddressAutocomplete();
    const handleGetCurrentOrder = () => {
      if (!this.wcOrderingService.currentOrder) {
        throw new Error('There is no current order!');
      }

      const opportunity = TicketService.getLocalOpportunity();
      if (this.wcOrderingService.currentOrder.orderType === 'QUOTE' && opportunity) {
        const addressRef = TicketService.getReferenceByType(opportunity, 'com.emeldi.ecc.be.address.dto.Address');
        if (addressRef) {
          const properties: Map<string, any> = new Map<string, any>();
          properties.set('_id', addressRef.entityId);
          this.elasticSearchService
            .query(ElasticsearchService.ADDRESS_INDEX, null, null, properties, null, 1, 1)
            .pipe(map(oph => oph.hits.hits))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(addresses => {
              if (addresses && addresses.length > 0) {
                this.selectAddress(addresses[0]);
                this.getSocket(this.paramOriginalProductLocationId);
              }
            });
        }
      }

      const serviceNotAvailable = this.route.snapshot.queryParamMap.get(this.SERVICE_NOT_AVAILABLE_URL_PARAM);
      if (serviceNotAvailable && serviceNotAvailable == 'true') {
        this.serviceNotAvailable = true;
        this.fillInputs = false;
        const technologyScan = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'technologyScan'));
        if (technologyScan) {
          this.scanResult = technologyScan[this.paramProductId]?.technologyScanResult;
          let technologyScanInput = technologyScan[this.paramProductId]?.technologyScanInput;
          if (technologyScanInput) {
            this.selectedAddress = technologyScanInput.address;
            this.selectedLocation = technologyScanInput.location;
            this.selectedSocket = technologyScanInput.socket;
          }
        }
      }
    };
    this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(handleGetCurrentOrder);


    if (this.paramOriginalProductLocationId) {
      this.lightNetworkInventory
        .getLocationById(this.paramOriginalProductLocationId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          location => {
            const properties: Map<string, any> = new Map<string, any>();
            properties.set('_id', location.adrRefNo);
            this.elasticSearchService
              .query(ElasticsearchService.ADDRESS_INDEX, null, null, properties, null, 1, 1)
              .pipe(map(oph => oph.hits.hits))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(addresses => {
                if (addresses && addresses.length > 0) {
                  this.selectAddress(addresses[0]);
                  this.getSocket(this.paramOriginalProductLocationId);
                }
              });
          },
          error => {
            // Stay when error 500
            this.router.navigate(['eshop/technology-check'], {
              queryParams: {
                productId: this.paramProductId,
              },
            });
          }
        );
    }
  }

  tryToAddProduct() {
    if (this.paramProductId && this.paramProductId !== '') {
      const getProduct$ = this.productService.getProductById(this.paramProductId);

      const getHwProduct$ = this.paramHwProductId
        ? this.productService.getProductById(this.paramHwProductId)
        : of(null);
      const getGiftProduct$ = this.paramGiftProductId
        ? this.productService.getProductById(this.paramGiftProductId)
        : of(null);
      const getDiscountProduct$ = this.paramDiscountProductId
        ? this.productService.getProductById(this.paramDiscountProductId)
        : of(null);

      forkJoin([getProduct$, getHwProduct$, getGiftProduct$, getDiscountProduct$])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([product, hwProduct, giftProduct, discountProduct]) => {
          const addOrderItem = (currentOrder: OrderDto) => {
            const navigateToShoppingCart = (order: OrderDto) => {
              if (order) {
                this.router.navigate(['eshop/shopping-cart']);
              }
            };
            const handelError = error => {
              const technologyScan = JSON.parse(OrderUtils.getOrderAttributeValue(currentOrder, 'technologyScan'));
              if (technologyScan) {
                const badRequestError = error.status === 400;
                const toFewSocketsFailure = error.error?.failures?.find(failure => failure.code === 15150 && failure.detail.indexOf('Too few (o) Sockets occupied'));

                if (toFewSocketsFailure && badRequestError) {
                  const queryParams = { ...this.route.snapshot.queryParams };
                  queryParams[this.SERVICE_NOT_AVAILABLE_URL_PARAM] = 'true';
                  window.sessionStorage.removeItem(Keys.KEY_CURRENT_ORDER);
                  this.wcOrderingService.currentOrder = null;
                  this.router.navigate([], { queryParams: queryParams });
                }
              }
              return of(null);
            };

            if (!_.isNil(this.paramOriginalProductPartyId)) {
              //tariff change
              this.wcOrderingService
                .addProductToShoppingCartWithParent(
                  currentOrder,
                  product as ProductAddingToCart,
                  null,
                  null,
                  this.paramOriginalProductPartyId,
                  'MERGE'
                )
                .pipe(takeUntil(this.onDestroy$))
                .pipe(catchError(handelError))
                .subscribe(navigateToShoppingCart);
            } else {
              if (hwProduct || giftProduct || discountProduct) {
                this.wcOrderingService
                  .addAllToBasket(hwProduct, giftProduct, discountProduct, product)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(order => {
                    this.paramBackUrl
                      ? this.router.navigate([this.paramBackUrl])
                      : this.router.navigate(['/eshop/shopping-cart']);
                  });
              } else {
                this.wcOrderingService
                  .addProductToShoppingCartWithParent(currentOrder, product as ProductAddingToCart)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(navigateToShoppingCart, handelError);
              }
            }
          };
          this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
        });
    }
  }

  continue() {
    if (this.technologyForm.valid) {
      if (typeof this.technologyForm.controls['address'].value === 'object') {
        const technologyScanInput = {
          address: this.technologyForm.controls['address'].value,
          location: this.technologyForm.controls['location'].value,
          socket: this.technologyForm.controls['socket'].value,
        };
        const technologyScanInputs = {
          technologyScanInput: technologyScanInput,
        };

        if (technologyScanInputs.technologyScanInput.address.gpsLocation) {
          technologyScanInputs.technologyScanInput.address['latitude'] =
            technologyScanInputs.technologyScanInput.address.gpsLocation.lat;
          technologyScanInputs.technologyScanInput.address['longitude'] =
            technologyScanInputs.technologyScanInput.address.gpsLocation.lon;
          delete technologyScanInputs.technologyScanInput.address.gpsLocation;
        }

        const technologyScan = {};
        technologyScan[this.paramProductId] = technologyScanInputs;

        const orderParamsDto: Array<OrderParamDto> = [];
        OrderUtils.updateOrderAttr(orderParamsDto, 'technologyScanInput', JSON.stringify(technologyScan));

        const orderAsMap = {
          orderAttributes: orderParamsDto,
          recordVersion: this.wcOrderingService.currentOrder.recordVersion,
        };
        this.wcOrderingService.addAccountId(this.wcOrderingService.currentOrder, orderAsMap);

        const handleGetCurrentOrder = (orderDto: OrderDto) => {
          this.wcOrderingService.currentOrder = orderDto;
          this.tryToAddProduct();
        };
        this.wcOrderingService
          .patchOrder(this.wcOrderingService.currentOrder.id, orderAsMap)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(handleGetCurrentOrder);
      } else {
        this.technologyForm.controls['address'].setErrors({ wrongType: true });
      }
    } else {
      setTimeout(() => {
        const errorElements = this.renderer.selectRootElement('app-input-errors div', true);
        errorElements.style.scrollMarginTop = Constants.NAVIGATION_HEIGHT;
        errorElements.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  private fillProductIdUrlParams() {
    const productId = this.route.snapshot.queryParamMap.get(this.PRODUCT_ID_URL_PARAM);
    if (productId && productId !== '') {
      this.paramProductId = productId;
    }

    const productGiftId = this.route.snapshot.queryParamMap.get(this.GIFT_PRODUCT_ID_URL_PARAM);
    if (productGiftId && productGiftId !== '') {
      this.paramGiftProductId = productGiftId;
    }

    const discountProductId = this.route.snapshot.queryParamMap.get(this.DISCOUNT_PRODUCT_ID_URL_PARAM);
    if (discountProductId && discountProductId !== '') {
      this.paramDiscountProductId = discountProductId;
    }

    const hwProductId = this.route.snapshot.queryParamMap.get(this.HW_PRODUCT_ID_URL_PARAM);
    if (hwProductId && hwProductId !== '') {
      this.paramHwProductId = hwProductId;
    }

    const originalProductPartyId = this.route.snapshot.queryParamMap.get(this.PRODUCT_PARTY_ID_URL_PARAM);
    if (originalProductPartyId && originalProductPartyId !== '') {
      this.paramOriginalProductPartyId = originalProductPartyId;
    }

    const backUrl = this.route.snapshot.queryParamMap.get(this.BACK_URL_PARAM);
    if (backUrl && backUrl !== '') {
      this.paramBackUrl = backUrl;
    }

    const originalProductLocationId = this.route.snapshot.queryParamMap.get(this.PRODUCT_LOCATION_ID_URL_PARAM);
    if (originalProductLocationId && originalProductLocationId !== '') {
      this.paramOriginalProductLocationId = originalProductLocationId;
    }

    const originalProductSocketId = this.route.snapshot.queryParamMap.get(this.PRODUCT_SOCKET_ID_URL_PARAM);
    if (originalProductSocketId && originalProductSocketId !== '') {
      this.paramOriginalProductSocketId = originalProductSocketId;
    }
  }

  private getSelectedLocation() {
    const locRefNo = this.technologyForm.controls['location'].value.locRefNo;
    if (locRefNo) {
      this.getSocket(locRefNo);
    }
  }

  private selectAddress(address) {
    const addressPipe = new DisplayAddressPipe(this.codebookService, this.currentLocaleService);
    if (this.selectedAddress !== address._source) {
      this.resetForm();
    }
    this.selectedAddress = address._source;
    this.technologyForm.controls['address'].patchValue(address._source);
    this.searchInput.nativeElement.value = addressPipe.transform(address._source, addressTypes.TECHNOLOGY_CHECK);
    const adrRefNo = this.selectedAddress.adrRefNo;
    if (adrRefNo) {
      this.getLocation(adrRefNo);
    }
  }

  private getLocation(adrRefNo: string) {
    this.location = true;
    this.technologyForm.controls['locationCheckBox'].setValue(true);
    this.lightNetworkInventory
      .getLocationsAtAddress(adrRefNo)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        location => {
          if (location.locationsAtAddress) {
            this.locationsOptions = location.locationsAtAddress;
            this.technologyForm.controls['location'].setValue(this.locationsOptions[0]);
            if (this.technologyForm.controls['location'].value) {
              const locRefNo = this.technologyForm.controls['location'].value.locRefNo;
              if (locRefNo) {
                this.getSocket(locRefNo);
              }
            }
            if (this.paramOriginalProductLocationId) {
              this.selectedLocation = this.locationsOptions.find(
                location => location.locRefNo === this.paramOriginalProductLocationId
              );
            }
          }
        },
        error => {
          // Stay when error 500
          this.router.navigate(['eshop/technology-check'], { queryParams: { productId: this.paramProductId } });
        }
      );
  }

  private getSocket(locRefNo: string) {
    this.lightNetworkInventory
      .getSocketsByLocation(locRefNo)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        socket => {
          if (socket.socketsAtLocation && !_.isEmpty(socket.socketsAtLocation)) {
            FormUtils.setValidation(this.technologyForm.controls['socket'], Validators.required);
            this.socket = true;
            this.technologyForm.controls['socketCheckBox'].setValue(true);
            this.technologyForm.controls['socketCheckBox'].enable();
            this.socketOptions = socket.socketsAtLocation;
            this.technologyForm.controls['socket'].setValue(this.socketOptions[0]);
            if (this.paramOriginalProductSocketId) {
              this.selectedSocket = this.socketOptions.find(
                socket => socket.socketRefNo === this.paramOriginalProductSocketId
              );
            }
          } else {
            FormUtils.setValidation(this.technologyForm.controls['socket'], null);
            this.technologyForm.controls['socketCheckBox'].setValue(false);
          }
        },
        error => {
          // Stay when error 500
          FormUtils.setValidation(this.technologyForm.controls['socket'], null);
          this.technologyForm.controls['socketCheckBox'].setValue(false);
          this.router.navigate(['eshop/technology-check'], { queryParams: { productId: this.paramProductId } });
        }
      );
  }

  /**
   * Called from GUI when search input changes
   * @param text
   */
  private searchChange(text: string) {
    this.searchTerms.next(text);
  }

  public autocompleteFocusOut(autocomplete: HTMLElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  private initAddressAutocomplete() {
    this.maxAutocompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(maxAutocompleteResults => (this.maxAutocompleteResults = maxAutocompleteResults));
    this.autocompleteProducts = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<AddressHolder[]>([]);
        } else {
          return this.addressService.searchByText(term, this.maxAutocompleteResults).pipe(source => {
            return source;
          });
        }
      })
    );
  }

  // reset location & socket when checkbox is false
  noLocation() {
    if (this.technologyForm.controls['locationCheckBox'].value) {
      this.location = true;
      if (this.locationsOptions) {
        this.technologyForm.controls['location'].setValue(this.locationsOptions[0]);
      }
    } else {
      this.resetLocationAndSocketField();
      this.location = false;
      this.socket = false;
    }
  }

  // reset socket when checkbox is false
  noSocket() {
    if (this.technologyForm.controls['socketCheckBox'].value) {
      this.socket = true;
      if (this.socketOptions) {
        this.technologyForm.controls['socket'].setValue(this.socketOptions[0]);
      }
    } else {
      FormUtils.setValidation(this.technologyForm.controls['socket'], null);
      this.technologyForm.controls['socket'].setValue(null);
      this.technologyForm.controls['socketCheckBox'].setValue(false);
      this.socket = false;
    }
  }

  // reset form when new address is selected
  resetForm() {
    this.location = false;
    this.socket = false;
    this.locationsOptions = null;
    this.socketOptions = null;
    this.technologyForm.controls['location'].setValue(null);
    this.technologyForm.reset();
    this.resetLocationAndSocketField();
  }

  resetLocationAndSocketField() {
    FormUtils.setValidation(this.technologyForm.controls['socket'], null);
    this.technologyForm.controls['address'].setValue(null);
    this.technologyForm.controls['socket'].setValue(null);
    this.technologyForm.controls['locationCheckBox'].setValue(false);
    this.technologyForm.controls['socketCheckBox'].setValue(false);
  }

  // back button to fill address
  back() {
    this.fillInputs = true;
    this.serviceNotAvailable = false;
    this.technologyForm = this.fb.group({
      address: [null, Validators.required],
      locationCheckBox: [{ value: false, disabled: true }],
      location: [null, Validators.required],
      socketCheckBox: [{ value: false, disabled: true }],
      socket: [null],
    });
    this.resetForm();
  }
}

// Move/fix to order-bff
interface TechnologyScanInputAddressDto {
  adrRefNo?: string;
  buildingNo?: string;
  city?: string;
  cityPart?: string;
  country?: string;
  countryCode?: string;
  county?: string;
  district?: string;
  extId?: string;
  lastValidationDate?: {};
  latitude?: number;
  longitude?: number;
  note?: string;
  street?: string;
  streetNo?: string;
  type?: string;
  zip?: string;
  radius?: number;
}
