import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDto, ProductDetailDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { switchMap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ProductService } from '@btl/btl-fe-wc-common';
import { OrderUtils } from '../../../helpers/order-utils';
import { OrderingWizardActionsComponent } from '../../page/ordering-wizard-actions/ordering-wizard-actions.component';
import { SuspendOrderComponent } from '../checkout-page/suspend-order/suspend-order.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddingToCart } from '@service/product-listing.service';

@Component({
  selector: 'app-checkout-scoring',
  templateUrl: './checkout-scoring.component.html',
  styleUrls: ['./checkout-scoring.component.scss'],
})
export class CheckoutScoringComponent implements OnInit, OnDestroy {
  @ViewChild('orderingWizardActions', { static: true }) orderingWizardActions: OrderingWizardActionsComponent;

  depositProducts;
  depositOptions: ProductDetailDto[];
  selectedDeposit: ProductDetailDto;

  resultFlag: string;

  manualDepositProducts: Array<ProductDetailDto> = [];
  scoringResult: ScoringResult;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    public wcOrderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private productService: ProductService,
    private ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {
    const handleGetCurrentOrder = () => {
      if (this.wcOrderingService.currentOrder) {
        this.loadManualDeposits();
      } else {
        throw new Error('There is no current order!');
      }
    };
    this.wcOrderingService.getCurrentOrder().subscribe(handleGetCurrentOrder);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  loadManualDeposits() {
    this.depositProducts = this.productService
      .getProductGroupByGroupCode('DEPOSIT_MANUAL')
      .pipe(
        switchMap(group =>
          group['id']
            ? this.productService.getGroupProducts([group['id']], null, null, this.wcOrderingService.currentOrder.id)
            : of([])
        )
      );
    this.depositProducts.subscribe((depositProducts: ProductDetailDto[]) => {
      this.depositOptions = depositProducts;
      if (!(Object.getOwnPropertyNames(this.wcOrderingService.currentOrder).length === 0)) {
        this.loadScoringResults(this.wcOrderingService.currentOrder);
      } else {
        throw new Error('There is no current order!');
      }
    });
  }

  loadScoringResults(order: OrderDto) {
    this.wcOrderingService.currentOrder = order;
    const scoringResult: ScoringResult = JSON.parse(OrderUtils.getOrderAttributeValue(order, 'scoringResult'));
    if (scoringResult) {
      this.resultFlag = scoringResult.resultFlag;
      this.scoringResult = scoringResult;

      this.wcOrderingService.currentOrder?.orderItems.forEach(orderItem => {
        const deposit = this.depositOptions.find(deposit => deposit.id === orderItem.productId);
        if (
          deposit &&
          !this.scoringResult.resultReasons.find(
            resultReason => resultReason.requiredProductCode === deposit.productCode,
          )
        ) {
          this.manualDepositProducts.push(deposit);
        }
      });
    }
  }

  /**
   * Result class for alert styling
   * @returns {String} class modificator
   */
  resultClass() {
    const result = this.resultFlag;
    if (result === 'ORANGE') {
      return 'warning';
    } else if (result === 'RED') {
      return 'danger';
    } else {
      return 'success';
    }
  }

  continue() {
    if (this.shoppingCartService.preCalculatedShoppingCart.isDeliveryOrUpFrontPaymentNeeded()) {
      this.router.navigateByUrl('/eshop/delivery');
    } else {
      this.router.navigateByUrl('/eshop/summary-page');
    }
  }

  addManualDeposit() {
    if (this.selectedDeposit) {
      this.manualDepositProducts.push(this.selectedDeposit);
      const addOrderItem = (currentOrder: OrderDto) => {
        this.wcOrderingService
          .addProductToShoppingCartWithParent(currentOrder,
            this.selectedDeposit as ProductAddingToCart, null, null, null, null, false)
          .subscribe(order => (this.wcOrderingService.currentOrder = order));
      };
      this.wcOrderingService.getCurrentOrder().subscribe(addOrderItem);
      this.selectedDeposit = null;
    }
  }

  suspendOrderPopup() {
    const modalRef = this.ngbModal.open(SuspendOrderComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });
    const suspendOrderComponent = <SuspendOrderComponent>modalRef.componentInstance;
    suspendOrderComponent.dialogRef = modalRef;
  }

  getNotAddedDeposits() {
    return this.depositOptions.filter(
      deposit =>
        (!this.manualDepositProducts ||
          !this.manualDepositProducts.find(existingDeposit => existingDeposit.id === deposit.id)) &&
        !this.scoringResult.resultReasons.find(resultReason => resultReason.requiredProductCode === deposit.productCode)
    );
  }

  manualDepositChanged(manualDeposit: ProductDetailDto) {
    this.manualDepositProducts.forEach(loopManualDeposit => {
      if (loopManualDeposit.id === manualDeposit.id) {
        loopManualDeposit.prices = manualDeposit.prices;
      }
    });
  }

  manualDepositRemoved(manualDeposit: ProductDetailDto) {
    this.manualDepositProducts = this.manualDepositProducts.filter(
      filterManualDeposit => filterManualDeposit.id !== manualDeposit.id
    );
  }

  depositChanged(order: OrderDto) {
    this.wcOrderingService.currentOrder = order;
    this.scoringResult = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'scoringResult'));
  }
}

export interface ScoringResult {
  resultFlag: string;
  resultReasons: Array<ScoringReason>;
}

export interface ScoringReason {
  requiredOC: number;
  requiredProductCode: string;
  scoringType: string;
  scoringTypeResult: number;
}
