import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleDto } from '@btl/admin-bff/model/roleDto';
import {
  AccountEntityRoleService,
  AccountService,
  AclService,
  AppBlockerService,
  FormUtils,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountDto, AccountEntityRoleDto, DynamicEnumService, EnumEntryDto } from '@btl/order-bff';
import { AccountEntityDetailsComponent } from '../account-entity-details/account-entity-details.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delegate-modal',
  templateUrl: './delegate-modal.component.html',
})
export class DelegateModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  private onDestroy$: Subject<void> = new Subject<void>();

  dialogRef;
  selectedAccountEntityRoleDto: AccountEntityRoleDto;
  newAccountEntityRoleDto: AccountEntityRoleDto;
  grantMode: boolean = true;
  entityTypes: EnumEntryDto[];
  aclRoles: RoleDto[] = [];
  rolesToSelect: RoleDto[];

  delegateForm: FormGroup = this.formBuilder.group({
    id: [null],
    recordVersion: [null],
    entityType: [{ value: null, disabled: true }],
    entityId: [{ value: null, disabled: true }],
    roleType: [null, Validators.required],
    startDateTime: [null, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
    endDateTime: [null, FormUtils.validateOrderOfStartEnd()],
    canDelegate: [false, Validators.required],
    delegatedByAccount: this.formBuilder.group({
      accountId: [null],
      login: [{ value: null, disabled: true }],
    }),
    delegatedFromId: [],
    account: [],
  });

  account;

  constructor(
    private formBuilder: FormBuilder,
    private aclService: AclService,
    private dynamicEnumService: DynamicEnumService,
    private accountEntityRoleService: AccountEntityRoleService,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private accountService: AccountService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    this.aclService
      .filterRoles(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.aclRoles = result.data;
        this.rolesToSelect = this.aclRoles.concat([]).sort((a, b) => a.id.localeCompare(b.id));
      });

    this.dynamicEnumService
      .getEnumEntries(
        AccountEntityDetailsComponent.SOURCE_NAME,
        AccountEntityDetailsComponent.EXTERNAL_ENTITY_TYPE_ENUM_NAME
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result.data;
      });
  }

  ngOnInit(): void {
    this.selectedAccountEntityRoleDto.created = new Date();
    this.delegateForm.patchValue(this.selectedAccountEntityRoleDto);
    this.delegateForm.controls['delegatedByAccount'].patchValue(this.selectedAccountEntityRoleDto.account);
    this.delegateForm.controls['delegatedFromId'].patchValue(this.selectedAccountEntityRoleDto.id);
    this.delegateForm.controls['account'].patchValue({ accountId: null, login: null });
    this.delegateForm.controls['id'].setValue(null);
    this.delegateForm.controls['recordVersion'].setValue(null);
  }

  ngAfterViewChecked() {
    this.delegateForm.controls['account'].setErrors({});
  }

  continue() {
    FormUtils.validateAllFormFields(this.delegateForm);
    if (this.delegateForm.valid) {
      this.newAccountEntityRoleDto = this.delegateForm.getRawValue();
      this.newAccountEntityRoleDto.delegatedByAccount.login = null;
      this.newAccountEntityRoleDto.startDateTime = new Date();
      if (
        !(
          this.newAccountEntityRoleDto.delegatedFromId === null ||
          this.newAccountEntityRoleDto.delegatedFromId === undefined
        )
      ) {
        delete this.newAccountEntityRoleDto.entityId;
        this.newAccountEntityRoleDto.entityType = null;
        this.newAccountEntityRoleDto.roleType = null;
      }
      this.dialogRef.close();
      this.appBlockerService.block();
      this.accountEntityRoleService
        .createAccountEntityRole(this.newAccountEntityRoleDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(
          result => {
            if (result) {
              this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
            }
          },
          error => {
            if (error) {
              this.stickyMessageService.addStickyErrorMessage('wc.admin.messages.sticky.ok');
            }
          }
        );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  accountChanged(account: AccountDto) {
    this.account = account || null;
    this.delegateForm.controls['roleType'].reset();
    this.rolesToSelect = [];
    if (account?.external) {
      this.rolesToSelect = this.aclRoles
        ?.filter(role =>
          this.selectedAccountEntityRoleDto.recordVersion
            ? role?.id === this.selectedAccountEntityRoleDto.roleType
            : role?.external === account?.external
        )
        .sort((a, b) => a.id.localeCompare(b.id));
    } else {
      this.rolesToSelect = this.aclRoles.concat([]).sort((a, b) => a.id.localeCompare(b.id));
    }
  }

  getAccountParamByName(name: string): string {
    let value = null;
    if (this.account) {
      value = this.account.parameters?.find(param => param.name === name)?.value;
    }
    return value;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
