import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { addressTypes } from '../../../../pipes/display-address.pipe';
import { CustomerService } from '@service/customer.service';
import { takeUntil } from 'rxjs/operators';
import { CompareType } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { BillCycleSpecificationDto, CustomerAccountDto, SortDto } from '@btl/order-bff';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-ecare-customer-account-widget, [app-ecare-customer-account-widget]',
  templateUrl: './ecare-customer-account-widget.component.html'
})
export class EcareCustomerAccountWidgetComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() editMode;
  customerAccount: CustomerAccountDto;

  addressTypes = addressTypes;
  address;

  sumPrice = 0;
  upToDate = false;
  billCycleSpecification: BillCycleSpecificationDto;
  maturity = new Date();
  paymentMethodAdditionalInfo: string;

  constructor(
    private customerLocalStorageService: CustomerLocalStorageService,
    private readonly customerService: CustomerService,
  ) {
  }

  ngOnInit(): void {
    this.customerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();
    if (this.customerAccount) {
      this.customerService
        .getOpenBalance(this.customerLocalStorageService.getCurrentCustomer().id, this.customerAccount.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.upToDate = result.dueAmount === 0 && result.overdueAmount === 0;
          }
        });

      const search = {
        filtering: [
          {
            column: 'name',
            compareType: CompareTypeDtoEnum.EQUAL,
            value: this.customerAccount.parameters['billCycle'],
          },
        ],
        sorting: [{ column: 'name', sortOrder: SortOrderDtoEnum.Desc }],
        paging: {
          page: 1,
          pageSize: 1,
        },
      };

      this.customerService
        .getBillCycles(search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result && result.billCycleSpecificationList?.length > 0) {
            this.billCycleSpecification = result.billCycleSpecificationList[0];
            if (this.billCycleSpecification?.paymentDueDateOffset >= this.maturity.getUTCDate()) {
              this.maturity.setMonth(this.maturity.getMonth() + 1);
            }

            this.maturity.setDate(this.billCycleSpecification?.paymentDueDateOffset);
          }
        });

      this.sumPrice = 0;
      this.customerAccount.childParties.forEach(childParty => {
        if (childParty.assets.length > 0) {
          const asset = childParty.assets[0];
          const tariffProduct = asset['product'];
          const coreProduct = CustomerPartyUtil.getChildAssetByCategoryId(asset, CategoryTypeEnum.PRODC_SU_CORE);
          this.sumPrice += CustomerPartyUtil.getTariffRC(tariffProduct, coreProduct);
        }
      });

      this.paymentMethodAdditionalInfo = this.getPaymentMethodAdditionalInfo();
      this.address = this.customerAccount.address;
      if (!this.address) {
        this.address = this.customerLocalStorageService.getCurrentCustomer().address;
      }
    }
  }

  getPaymentMethodAdditionalInfo() {
    if (this.customerAccount.parameters['paymentMethod'] === 'BANK_TRANSFER') {
      return this.customerAccount.parameters['bankAccount'];
    } else if (this.customerAccount.parameters['paymentMethod'] === 'PAYPAL') {
      return this.customerAccount.parameters['paypalAccount'];
    } else if (this.customerAccount.parameters['paymentMethod'] === 'PAYMENT_CARD') {
      return this.customerAccount.parameters['creditCardNo'];
    }
    return null;
  }
}
