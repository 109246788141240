import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-detail-side-box',
  templateUrl: './orders-detail-side-box.component.html',
})
export class OrdersDetailSideBoxComponent implements OnInit {
  @Input()
  simpleRows: Array<{ link: string; text: string }>;

  constructor() {}

  ngOnInit(): void {}
}
