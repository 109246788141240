<tr class="ecare_listing-table-row clickable" (click)="onRowClick()">
  <td class="no-wrap">
    {{ user.login }}
  </td>
  <td class="no-wrap">
    {{ getAccountParamByName('employeeId') }}
  </td>
  <td class="no-wrap">
    {{ getAccountParamByName('firstName') }}
  </td>
  <td class="no-wrap">
    {{ getAccountParamByName('lastName') }}
  </td>
  <td class="no-wrap">
    {{ user.extId }}
  </td>
  <td class="no-wrap content-sm">
    {{ 'wc.common.' + (user.external ? 'yes' : 'no') + '.button' | translate }}
  </td>
  <td class="no-wrap content-sm">
    {{ user.state }}
  </td>
  <td class="no-wrap content-sm">
    {{ user.created | date: 'dd.MM.yyyy' }}
  </td>
</tr>
