<tr class="ecare_listing-table-row" [ngClass]="{ clickable: file.params }" (click)="onRowClick()">
  <td>
    <input class="checkbox" type="checkbox" [id]="'document-' + index" [formControl]="selectFormArray.at(index)" />
    <label style="vertical-align: super" [for]="'document-' + index"> </label>
  </td>
  <td>
    <i
      *ngIf="file.params"
      id="001_ecare_order_row"
      class="arrow-triangle"
      [ngClass]="{ down: collapsed && file.params, up: !collapsed && file.params }"></i>
  </td>
  <td class="no-wrap">
    {{ file.mediaType }}
  </td>
  <td class="no-wrap content-sm order-created-datetime">
    {{ file.created | date: 'dd. MM. yyyy' }} <span class="-time">{{ file.created | date: 'HH:mm' }}</span>
  </td>
  <td class="no-wrap">
    {{ file.note }}
  </td>
  <td class="no-wrap content-sm">
    {{ file.name }}
  </td>
  <td class="no-wrap content-sm">
    {{ file.type }}
  </td>
  <td class="no-wrap mobile-right">
    <ng-container>
      <div ngbDropdown placement="bottom" class="listing-row-actions-entry" (click)="$event.stopPropagation()">
        <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
          <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
        </span>

        <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ file.id }}_popover-content">
          <div>
            <button class="btn btn-link action-list-more-actions-button" type="button" (click)="download()">
              {{ 'wc.ecare.notifications.downloadAttachment' | translate }}
            </button>
            <button class="btn btn-link action-list-more-actions-button" type="button" (click)="delete()">
              {{ 'wc.ecare.ticket.delete.button' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </td>
</tr>
<tr *ngIf="collapsed" class="notification-window">
  <td colspan="6">
    <div class="document-params">
      <div *ngFor="let paramName of objectKeys(file.params)" class="document-param">
        <label>{{ paramName }}</label>
        <input type="text" disabled [value]="file.params[paramName]" />
      </div>
    </div>
  </td>
</tr>
