<div
  class="summary-page-actions"
  [ngClass]="{
    'displayMode-full': displayMode === 'FULL',
    'displayMode-rollout': displayMode === 'ROLLOUT'
  }"
  [hidden]="!hasOrderItems()">
  <div *ngIf="displayMode === 'FULL'" class="discount-container">
    <div *ngIf="showDiscount">
      <input type="text" placeholder="{{ 'wc.shopping.cart.discount.placeholder' | translate }}" />
      <app-button variant="secondary" disabled="true" label="{{ 'wc.shopping.cart.apply' | translate }}"></app-button>
    </div>
    <app-button
      *ngIf="showBackToShopping"
      variant="secondary"
      size="shrink"
      class="desktop-back_bottom"
      label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
      [routerLink]="['/']"></app-button>
  </div>
  <div class="summary-container">
    <div *ngIf="preCalculatedShoppingCart" class="prices-container">
      <div class="summary-page-prices">
        <div *ngIf="preCalculatedShoppingCart.totalOcPrice" class="prices one-time-price">
          <div class="prices-label">
            {{ 'wc.shopping.confirmation.totalTodayPrice.label' | translate }}
          </div>
          <ng-container *ngIf="showVat; else noVatOc">
            <div class="prices-unit">
              <one-time-price [price]="preCalculatedShoppingCart.totalOcPrice"></one-time-price>
              <div *ngIf="preCalculatedShoppingCart.totalOcPriceTax !== 0 && displayMode" class="taxPrice">
                <one-time-price [price]="preCalculatedShoppingCart.totalOcPriceTax">
                </one-time-price>
                {{ 'wc.shopping.gui_productDetail.general.withoutVat.label' | translate }}
              </div>
            </div>
          </ng-container>
          <ng-template #noVatOc>
            <div class="prices-unit">
              <one-time-price [price]="preCalculatedShoppingCart.totalOcPriceTax"></one-time-price>
              <div *ngIf="preCalculatedShoppingCart.totalOcPriceTax !== 0" class="taxPrice">
                ({{ 'wc.shopping.confirmation.taxes.without' | translate }})
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="preCalculatedShoppingCart.totalRcPrice" class="prices monthly-price">
          <div class="prices-label">
            {{ 'wc.shopping.confirmation.monthlyPrice.label' | translate }}
          </div>
          <ng-container *ngIf="showVat; else noVatRc">
            <div class="prices-unit">
              <one-time-price [price]="preCalculatedShoppingCart.totalRcPrice"></one-time-price>
              <div *ngIf="preCalculatedShoppingCart.totalRcPriceTax !== 0 && displayMode" class="taxPrice">
                (<one-time-price
                  [price]="
                    preCalculatedShoppingCart.totalRcPrice - preCalculatedShoppingCart.totalRcPriceTax
                  "></one-time-price>
                {{ 'wc.shopping.confirmation.taxes.included' | translate }})
              </div>
            </div>
          </ng-container>
          <ng-template #noVatRc>
            <div class="prices-unit">
              <one-time-price [price]="preCalculatedShoppingCart.totalRcPriceTax"></one-time-price>
              <div *ngIf="preCalculatedShoppingCart.totalRcPriceTax !== 0" class="taxPrice">
                ({{ 'wc.shopping.confirmation.taxes.without' | translate }})
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="displayMode !== 'COMPACT'" class="taxes" [hidden]="displayMode === 'ROLLOUT'">
      <div *ngIf="showVatPrice" class="button-radio-container">
        <div class="tax-switch-button" *ngIf="showVatButton">
          <div class="label">{{ 'wc.shopping.confirmation.taxes.label' | translate }}</div>
          <app-toggle-switch checked (change)="changeCheckbox()"></app-toggle-switch>
        </div>
      </div>
      <p *ngIf="!showVatPrice">
        {{ 'wc.shopping.confirmation.taxes.info' | translate }}
      </p>
    </div>
    <div *ngIf="displayMode !== 'ROLLOUT'" class="navigation-container">
      <div class="navigation-item">
        <app-button
          *ngIf="displayMode !== 'FULL' && displayMode !== 'COMPACT'"
          label="View Cart"
          variant="primary"
          size="fullWidth"
          [routerLink]="['/eshop/shopping-cart']"></app-button>
      </div>
      <div *ngIf="showContinue" class="navigation-item">
        <app-button
          *ngIf="displayMode === 'FULL' || displayMode === 'COMPACT'"
          variant="primary"
          size="fullWidth"
          label="{{
            termsAndConditions
              ? 'Place Order'
              : displayMode === 'COMPACT'
              ? ('wc.common.continue.button' | translate) +
                ' to ' +
                ('wc.shopping.wizard.' + nextStepLabel + '.button' | translate)
              : 'Check out'
          }}"
          iconPath="{{ termsAndConditions ? 'assets/svg/safe-order.svg' : undefined }}"
          [disabled]="disableContinueButton"
          (click)="continue()"></app-button>
      </div>
      <div *ngIf="previousStep" class="navigation-item">
        <app-button
          *ngIf="displayMode === 'COMPACT'"
          variant="link"
          size="fullWidth"
          label="{{ 'Back to ' + ('wc.shopping.wizard.' + previousStepLabel + '.button' | translate) }}"
          (click)="back()"></app-button>
      </div>
    </div>
  </div>
</div>

<app-button
  *ngIf="displayMode === 'FULL' && !readOnlyOrder"
  variant="secondary"
  size="shrink"
  class="mobile-back_bottom"
  label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
  [routerLink]="['/']"></app-button>

<app-suspend-order
  *ngIf="suspendOrder && !readOnlyOrder"
  [suspendOrderPopup]="suspendOrder"
  (close)="suspendOrderPopup()">
</app-suspend-order>
