import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerService } from "app/services/customer.service";
import { CustomerPartyUtil } from "../helpers/customer-party.util";
import { CrmPartyRoleEnum } from "../models/CrmPartyRoleEnum";
import { AppBlockerService, AuthFactoryService } from "@btl/btl-fe-wc-common";
import { finalize, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CustomerAccountDto, CustomerDto, PartyDto } from '@btl/order-bff';

@Injectable()
export class CustomerLocalStorageService {
  static readonly KEY_CURRENT_CUSTOMER: string = 'currentCustomer';
  static readonly KEY_CURRENT_CUSTOMER_ACCOUNT: string = 'currentCustomerAccount';
  static readonly KEY_LAST_CUSTOMERS: string = 'lastCustomers';
  static readonly IS_INTERNAL_VISIBLE: string = 'internalVisible';

  contextChanged = new EventEmitter<CustomerDto>();
  contextChangedCustomerAccount = new EventEmitter<CustomerAccountDto>();
  prevCustomerContext = null;
  currentInternalVisible = new EventEmitter<boolean>();
  clearSearchField = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private authFactoryService: AuthFactoryService,
    private appBlockerService: AppBlockerService
  ) {}

  isCustomerContextPresent(): boolean {
    return !!this.getCurrentCustomer();
  }

  getCurrentCustomer(): CustomerDto {
    let currentCustomer: CustomerDto = null;

    try {
      this.authFactoryService.getAuthService().getUserRoles();
      currentCustomer = JSON.parse(window.sessionStorage.getItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER));
    } catch (e) {
      this.removeCurrentCustomerFromContext();
      return null;
    }
    return currentCustomer;
  }

  getLastCustomers(): StorageCustomerDto[] {
    let storageCustomerDtos = JSON.parse(window.localStorage.getItem(CustomerLocalStorageService.KEY_LAST_CUSTOMERS));
    if (!storageCustomerDtos) {
      storageCustomerDtos = [];
    }

    return storageCustomerDtos;
  }

  setCurrentCustomerWithLast(customer: CustomerDto) {
    this.setCurrentCustomer(customer, true);
  }

  selectSearchedCustomer(customerId: string, targetUrl?: string, inNewTab?: boolean) {
    this.appBlockerService.block();
    this.customerService
      .getCustomer(customerId, true)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(customerDto => {
        if (customerDto) {
          const navigateTo = targetUrl ? targetUrl : '/ecare/dashboard';
          if (inNewTab) {
            window.open(navigateTo, '_blank');
          } else {
            this.router.navigate([navigateTo], {
              queryParams: {
                cuRefNo: customerDto.id,
              },
            });
          }
        }
      });
  }

  setCurrentCustomer(customer: CustomerDto, addToLast: boolean) {
    this.prevCustomerContext = this.getCurrentCustomer();

    window.sessionStorage.setItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER, JSON.stringify(customer));

    if (addToLast) {
      this.addToLastCustomersList(customer);
    }

    this.contextChanged.emit(this.getCurrentCustomer());
    //this.removeCurrentCustomerAccountFromContext();
  }

  getCurrentCustomerAccount(): CustomerAccountDto {
    let currentCustomerAccount: CustomerAccountDto = null;

    try {
      this.authFactoryService.getAuthService().getUserRoles();
      currentCustomerAccount = JSON.parse(
        window.sessionStorage.getItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER_ACCOUNT)
      );
    } catch (e) {
      this.removeCurrentCustomerAccountFromContext();
      return null;
    }
    return currentCustomerAccount;
  }

  setCurrentCustomerAccount(customerAccount: CustomerAccountDto) {
    window.sessionStorage.setItem(
      CustomerLocalStorageService.KEY_CURRENT_CUSTOMER_ACCOUNT,
      JSON.stringify(customerAccount)
    );

    this.contextChangedCustomerAccount.emit(this.getCurrentCustomerAccount());
  }

  private addToLastCustomersList(customer: CustomerDto) {
    const storageCustomerDto: StorageCustomerDto = {
      id: customer.id,
      identityNumber: customer.subject.identityNumber,
      firstName: customer.subject.firstName,
      middleName: customer.subject.middleName,
      lastName: customer.subject.lastName,
    };

    const storageCustomerDtos = this.getLastCustomers();

    storageCustomerDtos.forEach((loopCustomer, index) => {
      if (loopCustomer.id === storageCustomerDto.id) {
        storageCustomerDtos.splice(index, 1);
      }
    });

    storageCustomerDtos.push(storageCustomerDto);
    if (storageCustomerDtos.length > 5) {
      storageCustomerDtos.splice(0, 1);
    }
    window.localStorage.setItem(CustomerLocalStorageService.KEY_LAST_CUSTOMERS, JSON.stringify(storageCustomerDtos));
  }

  removeCurrentCustomerFromContext() {
    if (window.sessionStorage.getItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER)) {
      this.removeCurrentCustomerAccountFromContext();
      window.sessionStorage.removeItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER);
      this.contextChanged.emit(null);

      try {
        this.authFactoryService.getAuthService().getUserRoles();
        this.router.navigate(['/ecare/homepage']);
      } catch (e) {
        this.router.navigate(['']);
      }
    }
  }

  removeCurrentCustomerAccountFromContext() {
    if (window.sessionStorage.getItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER_ACCOUNT)) {
      window.sessionStorage.removeItem(CustomerLocalStorageService.KEY_CURRENT_CUSTOMER_ACCOUNT);
      this.contextChangedCustomerAccount.emit(null);
    }
  }

  refreshCustomerContext(customerAccountId?: string): Observable<CustomerDto> {
    const currentCustomer = this.getCurrentCustomer();
    if (currentCustomer) {
      return this.customerService.getCustomer(currentCustomer.id).pipe(
        map(customerDto => {
          this.setCurrentCustomer(customerDto, false);
          if (customerAccountId) {
            this.setCurrentCustomerAccount(this.getContextCustomerCAById(customerAccountId));
          }
          return customerDto;
        }),
      );
    }
    return of(null);
  }

  getActiveCustomerAccounts(party?: PartyDto): CustomerAccountDto[] {
    const parties = [];

    if (!party) {
      party = this.getCurrentCustomer();
    }

    if (party) {
      party.childParties.forEach(party => {
        if (CustomerPartyUtil.hasPartyRole(party, CrmPartyRoleEnum.CustomerAccount) && party.state === 'CREATED') {
          parties.push(party);
        }
        this.getActiveCustomerAccounts(party).forEach(ca => parties.push(ca));
      });
    }
    return parties;
  }

  /**
   * Return customerAccount of customer in context by customerAccountId.
   * WARNING Only for Resident customer with flat CA hierarchy
   * @param customerAccountId
   */
  getContextCustomerCAById(customerAccountId: string): CustomerAccountDto {
    let customerAccount;
    const customer = this.getCurrentCustomer();

    if (customer) {
      customer.childParties.forEach(party => {
        const customerAccountAdded = false;
        if (
          CustomerPartyUtil.hasPartyRole(party, CrmPartyRoleEnum.CustomerAccount) &&
          party['id'] === customerAccountId
        ) {
          customerAccount = party;
        }
      });
    }
    return customerAccount;
  }

  /**
   * Search for party with id equals partyId in party tree
   * @param parties
   * @param partyId
   */
  getPartyByIdRecursive(parties: PartyDto[], partyId: string): any {
    let foundParty;

    if (parties) {
      parties.forEach(loopParty => {
        let party;
        if (loopParty.id === partyId) {
          party = loopParty;
        } else {
          party = this.getPartyByIdRecursive(loopParty.childParties, partyId);
        }

        if (party) {
          foundParty = party;
        }
      });
    }
    return foundParty;
  }

  /**
   * Return item from items with id equals itemId
   * @param items
   * @param itemId
   */
  getItemById(items: any[], itemId: string): any {
    let foundItem;

    if (items) {
      items.forEach(item => {
        if (item['id'] === itemId) {
          foundItem = item;
        }
      });
    }
    return foundItem;
  }

  showInternalElements(): boolean {
    const internalFromSession = window.sessionStorage.getItem(CustomerLocalStorageService.IS_INTERNAL_VISIBLE);
    if (internalFromSession === 'true' || !internalFromSession) {
      return true;
    } else {
      return false;
    }
  }

  setInternalVisible(value: string) {
    window.sessionStorage.setItem(CustomerLocalStorageService.IS_INTERNAL_VISIBLE, value);
  }

  getCurrentFilterPartyIds() {
    const partyRefNos: string[] = [];
    if (this.getCurrentCustomerAccount()) {
      partyRefNos.push(this.getCurrentCustomerAccount().id);
    } else {
      partyRefNos.push(this.getCurrentCustomer().id);
      partyRefNos.push(...this.getActiveCustomerAccounts().map(ca => ca.id));
    }
    return partyRefNos;
  }
}

export interface StorageCustomerDto {
  id?: string;
  identityNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}
