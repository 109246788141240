import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { Component, EventEmitter, HostListener, Input, ViewChild } from '@angular/core';
import { OrderStateTypeEnum } from '../../../../models/orderStateTypeEnum';
import { FormBuilder } from '@angular/forms';
import moment from 'moment';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';
import {
  EcareFilterAutocompleteBoxComponent
} from '../../ecare-general-components/ecare-filter-autocomplete-box/ecare-filter-autocomplete-box.component';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import { CustomerDto, PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-orders-filter',
  templateUrl: './ecare-orders-filter.component.html',
})
export class EcareOrdersFilterComponent extends ComplexFilter {
  protected advancedFilterFields = [
    'status',
    'channel',
    'stateDateFrom',
    'stateDateTo',
    'resourceId',
    'createdFrom',
    'createdTo',
    'customerId',
    'customerAccountId',
    'extId',
  ];

  @Input() party: PartyDto;
  @Input() selectMode = false;
  @Input() ecareSearch = false;

  @Input() filterOrderIdChangedEmitter: EventEmitter<string>;

  pickerInputType = PickerInputType;
  selectingField = false;
  currentCustomer: CustomerDto;

  filterForm = this.formBuilder.group({
    id: [],
    status: [],
    channel: [],
    stateDateFrom: [],
    stateDateTo: [],
    resourceId: [],
    createdFrom: [],
    createdTo: [],
    customerId: [],
    customerAccountId: [],
    partyRefNo: [],
    extId: [],
  });

  isModuleBffCrmEnabled = false;

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.status, 'orderCalcStateType'),
    new ControlFilterMiner(this.filterForm.controls.channel, 'channelType'),
    new ControlFilterMiner(this.filterForm.controls.resourceId, 'resourceId'),
    new ControlFilterMiner(this.filterForm.controls.extId, 'extId'),
    new ControlFilterMiner(this.filterForm.controls.partyRefNo, 'partyRefNo'),
    new ControlFilterMiner(this.filterForm.controls.stateDateFrom, 'stateHappened', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.stateDateTo, 'stateHappened', CompareTypeDtoEnum.SMALLEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdTo, 'created', CompareTypeDtoEnum.SMALLEREQ),
  ];

  orderChannels = [
    {
      labelKey: `wc.ecare.ordersFilter.channel.WEB`,
      code: 'WEB',
    },
  ];

  @ViewChild('customerSearch') customerSearch: EcareFilterAutocompleteBoxComponent;
  @ViewChild('customerAccountSearch') customerAccountSearch: EcareFilterAutocompleteBoxComponent;

  cAStructure: PartyDto[] = [];
  lastSearchedCu: string;
  orderStateTypes = [];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.selectingField) {
      this.closeAdvancedFilterOnGlobalClick(event);
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private customerService: CustomerService,
    public customerLocalStorageService: CustomerLocalStorageService
  ) {
    super();

    this.orderStateTypes.push({
      value: '',
      labelKey: 'wc.ecare.ordersFilter.status.defaultFilter',
    });
    this.orderStateTypes.push(
      ...Object.keys(OrderStateTypeEnum).map(value => {
        return {
          value: value,
          labelKey: `wc.common.orderState.${value}`,
        };
      })
    );
  }

  ngOnInit() {
    super.ngOnInit(true);
    if (this.filterOrderIdChangedEmitter) {
      this.filterOrderIdChangedEmitter.subscribe(id => {
        this.filterForm.controls['id'].patchValue(id);
      });
    }
    if (!this.filtering || this.filtering.length === 0) {
      this.resetFilterFields();
    }

    this.handleChange();
    this.currentCustomer = this.party && this.party.role === 'Customer' ? this.party : undefined;
    if (this.currentCustomer && !this.customerLocalStorageService.getCurrentCustomerAccount()) {
      this.fillCAStructure(this.party.id);
      this.lastSearchedCu = this.party.id;
    }
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.isModuleBffCrmEnabled = bffCrmEnabled));
  }

  handleAutocompleteSearch(isCA: boolean, value: string, resetStructure: boolean = true) {
    this.resetAutocompleteSearch();
    if (resetStructure) {
      this.cAStructure = [];
    }
    if (value) {
      if (isCA) {
        this.filterForm.value.customerAccountId = value;
      } else {
        this.lastSearchedCu = value;
        this.filterForm.value.customerId = value;
      }
      this.handleChange();
      if (!isCA) {
        this.fillCAStructure(value);
      }
    } else {
      this.handleChange();
    }
  }

  private fillCAStructure(value: string) {
    this.customerService
      .getCustomer(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(customer => {
        this.cAStructure.push(...this.customerLocalStorageService.getActiveCustomerAccounts(customer));
      });
  }

  resetAutocompleteSearch() {
    this.filterForm.controls.customerId.setValue(null);
    this.filterForm.controls.customerAccountId.setValue(null);
    this.filterForm.controls.partyRefNo.setValue(null);
  }

  handleChange() {
    const filterValue = this.filterForm.value;
    this.filterForm.controls.customerId.setValue(null);
    this.filterForm.controls.customerAccountId.setValue(null);
    if (filterValue.customerAccountId) {
      this.filterForm.controls.partyRefNo.setValue(filterValue.customerAccountId);
    } else {
      this.filterForm.controls.partyRefNo.setValue(null);
    }

    super.handleChange();
    this.filterForm.controls.customerId.setValue(filterValue.customerId);
    this.filterForm.controls.customerAccountId.setValue(filterValue.customerAccountId);
  }

  protected getDefaultFilterValues() {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 1);
    dateFrom.setHours(0, 0, 0, 0);

    return {
      status: '',
      channel: null,
      stateDateFrom: moment(dateFrom).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      customerId: this.party && this.party.role === 'Customer' ? this.party.id : null,
      customerAccountId: this.party && this.party.role === 'CustomerAccount' ? this.party.id : null,
      id: null,
      stateDateTo: null,
      createdFrom: null,
      createdTo: null,
      resourceId: null,
      partyRefNo: this.party ? this.party.id : null,
      extId: null,
    };
  }

  resetFilterFields() {
    super.resetFilterFields();
    if (this.customerSearch) {
      this.customerSearch.clearSearch();
    }
    if (this.customerAccountSearch) {
      this.customerAccountSearch.clearSearch();
    }
  }
}
