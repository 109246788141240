<div class="pick-up-point-input">
  <ng-container *ngIf="mapProvider && points">
    <app-open-layer-map
      *ngIf="mapProvider === mapProviderEnum.OPEN_LAYER && this.lat && this.lng"
      class="map"
      [zoom]="zoom"
      [currentLocation]="currentLocation"
      [lat]="lat"
      [lng]="lng"
      [hoveredPoint]="hoveredPoint"
      [selectedPoint]="selectedPoint"
      [points]="points"
      (pointHoverEmitter)="pointHoverEvent($event)"
      (pointSelectedEmitter)="pointClicked($event)"
      (distancesCalculatedEmitter)="fillPointsDistances()" />
    <app-google-map
      *ngIf="mapProvider === mapProviderEnum.GOOGLE_MAP"
      class="map"
      [zoom]="zoom"
      [currentLocation]="currentLocation"
      [lat]="lat"
      [lng]="lng"
      [hoveredPoint]="hoveredPoint"
      [selectedPoint]="selectedPoint"
      [points]="points"
      (pointHoverEmitter)="pointHoverEvent($event)"
      (pointSelectedEmitter)="pointClicked($event)"
      (distancesCalculatedEmitter)="fillPointsDistances()" />
    <div class="pick-up-point-search">
      <div class="technologyCheck__search" [formGroup]="form">
        <div class="technologyCheck__wrapper">
          <i
          id="019_navigation_menu"
          class="primary-menu__icon primary-menu__icon-search"
          [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
        <input
          #pointSearch
          type="text"
          id="searchMode"
          autocomplete="off"
          formControlName="pickUpPoint"
          [placeholder]="'wc.ecare.pickUpPoints.find.text' | translate"
          [readOnly]="disabled"
          [value]="filter?.attributes?.text ? filter?.attributes?.text : ''"
          (input)="searchChange(pointSearch.value)"
          (keyup.enter)="autocomplete.hidden = true"
          (focusout)="autocompleteFocusOut(autocomplete)"
          (focusin)="disabled ? (autocomplete.hidden = true) : (autocomplete.hidden = false)" />
        <div class="col-sm-2"></div>
        <div #autocomplete class="technologyCheck__autocomplete" [hidden]="true">
          <div *ngFor="let point of autocompletePoints | async; let i = index" class="autocomplete-items">
            <a href="javascript:void(0)" id="{{ i }}_005_delivery" (click)="selectPickUpPoint(point)">
              <span
                [innerHTML]="
                  point._source | displayAddress : addressTypes.PICK_UP_POINT | highlight : pointSearch.value
                "></span>
            </a>
          </div>
        </div>
        </div>

        <div class="close" (click)="setCurrentLocation()">
          <img src="/assets/img/current-location.svg" />
        </div>
      </div>

      <div class="pick-up-points">
        <ng-container *ngFor="let point of showPoints; let i = index">
          <div
            *ngIf="i < showPointsQuantity"
            class="pick-up-point cursor-pointer {{ i === 0 ? 'first' : i === showPoints.length - 1 ? 'last' : '' }} {{
              isHoveredPoint(point) ? 'map-hover' : ''
            }}"
            (mouseover)="pointHover(point)"
            (mouseout)="pointHover(null)"
            (click)="pointClicked(point)">
            <div class="name2">{{ point.name2 }}</div>
            <div class="name1">
              {{ point['distance'] ? (point['distance'] | number : '.1-1') + ' KM - ' : '' }}{{ point.name1 }}
            </div>
            <div *ngIf="isSelectedPoint(point)" [innerHTML]="point.description"></div>
            <button
              *ngIf="isSelectedPoint(point)"
              class="btn btn-default"
              (click)="selectPickUpPoint({ _source: point })">
              {{ 'wc.ecare.pickUpPoints.choose' | translate }}
            </button>
          </div>
        </ng-container>
        <div
          *ngIf="showPoints.length > showPointsQuantity"
          class="pick-up-point cursor-pointer show-more"
          (click)="showPointsQuantity = showPointsQuantity + DEFAULT_SHOW_POINTS_QUANTITY">
          {{ 'wc.ecare.pickUpPoints.showMore' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
