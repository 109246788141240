import { Input, Component, OnInit } from '@angular/core';
import { AggregatedRatingDto } from '@btl/order-bff';

@Component({
  selector: 'app-rating-star-info, [app-rating-star-info]',
  templateUrl: './rating-star-info.component.html',
  styleUrls: ['./rating-star-info.component.scss'],
})
export class RatingStarInfoComponent implements OnInit {
  @Input()
  starAmount;

  @Input()
  aggregatedRatingDto: AggregatedRatingDto;

  ratings = 0;

  procentage = 0;

  ngOnInit() {
    for (const [key, value] of Object.entries(this.aggregatedRatingDto.ratings)) {
      const keyNumber = +key;
      let lowerThreshold: number = (this.starAmount - 1) * 20;
      if (lowerThreshold === 0) {
        lowerThreshold = -1;
      }
      if (keyNumber > lowerThreshold && keyNumber <= this.starAmount * 20) {
        this.ratings += value;
      }
    }

    if (this.ratings > 0) {
      this.procentage = 100 * (this.ratings / this.aggregatedRatingDto.totalReviewAmount);
    }
  }
}
