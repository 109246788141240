import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PriceDto, ProductInfoDto } from '@btl/order-bff';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { TranslateService } from '@ngx-translate/core';
import { ProductCustomService } from '@service/product-custom.service';

@Component({
  selector: 'app-abstract-price',
  templateUrl: './abstract-price.component.html',
})
export abstract class AbstractPriceComponent implements OnInit {
  //region Data:

  /**
   * The currency of the price.
   */
  readonly CURRENCY: string = environment.currency;

  /**
   * Formatted price to display.
   */
  formattedPrice: string;

  //region IO:

  /**
   * A product holding the price to display.
   */
  _product: ProductInfoDto;

  @Input()
  set product(product: ProductInfoDto) {
    this._product = product;
    this.format();
  }

  /**
   * The price to display.
   */
  _price: number;

  @Input()
  set price(price: number) {
    if (this.negative) {
      this._price = -Math.abs(price);
    } else {
      this._price = price;
    }
    this.format();
  }

  @Input()
  negative;

  @Input()
  crossed = false;

  constructor(
    protected currentLocaleService: CurrentLocaleService,
    protected translateService: TranslateService,
    protected productCustomService: ProductCustomService
  ) {}

  //region Life-cycle:

  public ngOnInit(): void {
    if (this._product && !(this._price === null || this._price === undefined)) {
      throw new Error('Both price and product cannot be configured at the same time.');
    }

    if (this._product) {
      const priceWrapper = this.getPriceWrapper();
      if (priceWrapper) {
        this.price = priceWrapper.price;
      }
    }

    if (this._price === null) {
      this.price = 0;
    }

    this.translateService.onLangChange.subscribe(() => {
      this.format();
    });
  }

  protected abstract getPriceWrapper(): PriceDto;

  /**
   * Format the output using productCustomService.formatPrice.
   */
  protected format(): void {
    const locale = this.currentLocaleService.getCurrentLanguage();

    // get localized currency symbol based on message key for actual currency
    this.formattedPrice = this.productCustomService.formatPrice(this._price, locale, this.CURRENCY);
  }
}
