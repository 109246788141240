<div class="advanced-filter-wrapper">
  <app-button
    id="toggleButton"
    variant="whiteWithBorder"
    iconPath="/assets/svg/material-filter-list.svg"
    label="{{ 'wc.ecare.ordersFilter.advancedFilter.entry' | translate }}"
    (click)="advancedFilterEntryClick()">
    <div *ngIf="changedFilterFieldsCount > 0" class="advancedFilterCount">{{ changedFilterFieldsCount }}</div></app-button
  >
  <div *ngIf="isAdvancedFilterOpen" class="-advanced-filter-window">
    <div class="filter-flex-container">
      <ng-content></ng-content>
      <div class="-buttons">
        <button
          type="button"
          class="btn btn-link btn-clear-filters"
          (click)="resetFields()">
          {{ clearHistoryTextLocalizedKey | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
