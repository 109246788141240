import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WishListService } from '@btl/btl-fe-wc-common';
import { NgForm } from '@angular/forms';
import { WishListDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wish-list-popup',
  templateUrl: './wish-list-popup.component.html',
})
export class WishListPopupComponent implements OnInit, OnDestroy {
  wishLists: Array<WishListDto>;
  private readonly ADD_WISH_LIST_FIELD: string = 'addWishListField';

  public showAddWishListInput: boolean = false;
  public isInvalid: boolean = false;

  /* When present it opens the popup for specific product and offers the option to put product to existing wishlists;
   when empty it only allows to create new empty wishlist */
  @Input()
  productCode: string;

  // modal ref
  @Input() dialogRef;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private wishListService: WishListService) {}

  ngOnInit(): void {
    this.wishListService.wishListChange.pipe(takeUntil(this.onDestroy$)).subscribe(wishList => {
      this.loadWishLists();
    });
    this.showPopUp();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  closePopup() {
    this.wishLists = [];
    if (this.productCode) {
      this.showAddWishListInput = false;
    }
    this.isInvalid = false;
    this.dialogRef.dismiss();
  }

  showPopUp() {
    if (this.productCode) {
      this.loadWishLists();
    } else {
      this.showAddWishListInput = true;
    }
  }

  loadWishLists() {
    this.wishListService
      .getWishLists(true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishLists => {
        if (this.wishLists) {
          this.wishLists.length = 0;
          wishLists.forEach(wishList => {
            this.wishLists.push(wishList);
          });
        } else {
          this.wishLists = wishLists;
        }
        if (this.wishLists?.length === 0) {
          this.showAddWishListInput = true;
        }
      });
  }

  addNew(form: NgForm) {
    if (this.showAddWishListInput) {
      const wishListName = form.value[this.ADD_WISH_LIST_FIELD];
      if (wishListName) {
        this.isInvalid = false;
        this.wishListService
          .createWishlist(wishListName, this.productCode)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(wishList => {
            this.showAddWishListInput = false;
            this.loadWishLists();
            form.reset();
            if (!this.productCode) {
              this.closePopup();
            }
          });
      } else {
        this.isInvalid = true;
      }
    } else {
      this.showAddWishListInput = true;
    }
  }
}
