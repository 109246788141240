<div class="gallery">
  <ng-container>
    <gallery
      class="productGallery"
      gallerize="productGalleryModal"
      id="productGallery"
      loop="true"
      thumbHeight="90"
      thumbWidth="60"
      thumbImageSize="contain"
      [thumbAutosize]="true"
      [counter]="false"
      [items]="images"
      (click)="nameAndColor()" />
  </ng-container>
</div>
