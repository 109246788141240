import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { ActivatedRoute, Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import _ from 'lodash';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

@Component({
  selector: 'app-ordering-wizard-actions, [app-ordering-wizard-actions]',
  templateUrl: './ordering-wizard-actions.component.html',
})
export class OrderingWizardActionsComponent implements OnInit {
  @Input()
  showPrice: boolean = true;

  @Input()
  termsAndConditions: boolean = false;

  @Input()
  showVatPrice: boolean = false;

  @Input()
  displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';

  @Input()
  showSuspendButton: boolean = true;

  @Input()
  disableContinueButton: boolean = false;

  @Input()
  alwaysShowNavigationButtons: boolean = false;

  @Input()
  readOnlyOrder: boolean = false;

  @Input()
  showDiscount: boolean = false;

  @Input()
  showBackToShopping: boolean = true;

  @Input()
  showContinue: boolean = true;

  preCalculatedShoppingCart: PrecalculatedShoppingCart;

  previousStep: string;
  actualStep: string;
  nextStep: string;

  previousStepLabel: string;
  nextStepLabel: string;

  showVat: boolean;
  defaultChoice = 'vat';

  suspendOrder: boolean = false;

  showVatButton = true;

  @Output()
  readonly checkForm: EventEmitter<any> = new EventEmitter();

  constructor(
    private shoppingCartService: ShoppingCartService,
    private orderingWizardService: OrderingWizardService,
    private orderingService: WcOrderingService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    propertyAccessorLocalService.getSwitchVatButtonVisibleBoolean()
      .subscribe(property => this.showVatButton = property === 'true');
    this.shoppingCartService.onChange.subscribe((precalculatedShoppingCart: PrecalculatedShoppingCart) => {
      this.preCalculatedShoppingCart = precalculatedShoppingCart;
    });
  }

  ngOnInit(): void {
    this.showVat = true;
    if (!this.readOnlyOrder) {
      this.loadCurrentStep();
      this.preCalculatedShoppingCart = this.shoppingCartService.preCalculatedShoppingCart;
    } else {
      this.preCalculatedShoppingCart = this.shoppingCartService.readOnlyOrder;
    }
  }

  loadCurrentStep() {
    if (this.route.routeConfig.path) {
      const urlParts = this.route.routeConfig.path.split('/');
      const stepTypeInfo = this.orderingWizardService.stepTypesInfos.get(urlParts[0]);
      const stepTypesInfos = Array.from(this.orderingWizardService.stepTypesInfos.values());
      const stepTypeIndex = stepTypesInfos.indexOf(stepTypeInfo);
      this.actualStep = stepTypeInfo?.translation;
      // Find previous step
      if (stepTypeIndex !== 0 && stepTypeIndex !== 1) {
        for (let i = 1; i < stepTypesInfos.length; i++) {
          if (this.orderingWizardService.checkStepVisibility(stepTypesInfos[stepTypeIndex - i]?.scenarioStepTypeEnum)) {
            this.previousStep = stepTypesInfos[stepTypeIndex - i]?.url;
            this.previousStepLabel = stepTypesInfos[stepTypeIndex - i]?.translation;
            break;
          }
        }
      } else {
        this.previousStep = '/eshop/product-listing/';
      }
      // Find next step
      // TODO: check last index
      for (let i = 1; i < stepTypesInfos.length; i++) {
        if (this.orderingWizardService.checkStepVisibility(stepTypesInfos[stepTypeIndex + i]?.scenarioStepTypeEnum)) {
          this.nextStep = stepTypesInfos[stepTypeIndex + i]?.url;
          this.nextStepLabel = stepTypesInfos[stepTypeIndex + i]?.translation;
          break;
        }
      }
    }
  }

  back() {
    this.router.navigateByUrl(this.previousStep);
  }

  continue() {
    this.checkForm.emit();
  }

  continueToNextStep() {
    this.router.navigateByUrl(this.nextStep);
  }

  finishWizard() {
    this.router.navigate(['/eshop/thank-you-page'], { queryParams: { orderId: this.orderingService.currentOrder.id } });
  }

  changeCheckbox() {
    const customSwitch = document.getElementById('taxSwitch') as HTMLInputElement;

    if (customSwitch.checked) {
      this.showVat = true;
      this.orderingWizardService.showVat.next('vat');
    } else {
      this.showVat = false;
      this.orderingWizardService.showVat.next('noVat');
    }
  }

  hasOrderItems(): boolean {
    if (this.alwaysShowNavigationButtons) {
      return true;
    }
    return !_.isNil(this.preCalculatedShoppingCart) && !_.isEmpty(this.preCalculatedShoppingCart.products);
  }
}
