import { OrderItemDto, PriceDto, ProductDetailDto } from "@btl/order-bff";
import { AssetReplacement } from "./asset-replacement";
import { OrderItemPriceDto } from "@btl/order-bff/model/orderItemPriceDto";

/**
 * ProductInShoppingCart represents a virtual product in shopping cart. This is a replacement for order item quantities (unsupported in
 * BFF/BE). It allows to wrap some order items and handle them like one object.
 */
export class ProductInShoppingCart {
  /**
   * A list of the wrapped order items.
   */
  orderItems: OrderItemDto[] = [];

  /**
   * The OC price of the wrapped order items.
   */
  ocPrice = null;

  /**
   * The tax for OC prices of the wrapped order items.
   */
  ocPriceTax = null;

  /**
   * The RC price of the wrapped order items.
   */
  rcPrice = null;

  /**
   * The tax for RC prices of the wrapped order items.
   */
  rcPriceTax = null;

  children: ProductInShoppingCart[] = [];

  /**
   * An asset this product is replacing or null if this product isn't replacing an asset.
   */
  replacementFor: AssetReplacement;

  constructor(public productDetail: ProductDetailDto) {
    this.calculatePrices();
  }

  /**
   * Add an order item to this product in shopping cart. It also recalculates prices.
   *
   * @param orderItem An order item to add.
   */
  addOrderItem(orderItem: OrderItemDto): void {
    this.orderItems.push(orderItem);
    this.calculatePrices();
  }

  /**
   * Get true if at least one of the wrapped order items is non-NO_CHANGE.
   */
  get visibleActions(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action !== 'NO_CHANGE') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get true if at least one of the wrapped order items is MODIFY.
   */
  get modifyAction(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action === 'MODIFY') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get true if at least one of the wrapped order items is DELETE.
   */
  get deleteAction(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action === 'DELETE') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get quantity of wrapped non-NO_CHANGE order items.
   */
  get visibleOrderItemsQuantity(): number {
    let quantity = 0;
    for (const orderItem of this.orderItems) {
      if (orderItem.action !== 'NO_CHANGE') {
        quantity++;
      }
    }
    return quantity;
  }

  /**
   * Calculate prices for the product detail and the wrapped order items.
   */
  private calculatePrices(): void {
    if (this.productDetail && this.orderItems.length > 0) {
      const visibleOrderItemsQuantity = this.visibleOrderItemsQuantity;

      const ocPrice = this.orderItems[0].prices.find(price => price.priceType === 'OC');
      if (ocPrice) {
        this.ocPrice = ocPrice.priceWithTax * visibleOrderItemsQuantity;
        this.ocPriceTax = ocPrice.priceWithoutTax * visibleOrderItemsQuantity;
      }

      const rcPrice = this.orderItems[0].prices.find(price => price.priceType === 'RC');
      if (rcPrice) {
        this.rcPrice = rcPrice.priceWithTax * visibleOrderItemsQuantity;
        this.rcPriceTax = rcPrice.priceWithoutTax * visibleOrderItemsQuantity;
      }
    } else if (this.productDetail) {
      const ocPrice = this.productDetail.prices['OC'];
      if (ocPrice) {
        this.ocPrice = ocPrice.price;
        this.ocPriceTax = ocPrice.price / (1 + ocPrice.tax / 100);
      }

      const rcPrice = this.productDetail.prices['RC'];
      if (rcPrice) {
        this.rcPrice = rcPrice.price;
        this.rcPriceTax = rcPrice.price / (1 + rcPrice.tax / 100);
      }
    }
  }

  private getPriceAndTaxPrice(ocPriceWrapper, visibleOrderItemsQuantity): { price: number; priceWithTax: number } {
    const price = this.getOrderItemPrice(ocPriceWrapper);
    const totalPrice = price * visibleOrderItemsQuantity;
    const priceTax = (price / (1 + ocPriceWrapper.tax / 100)) * visibleOrderItemsQuantity;
    return { price: totalPrice, priceWithTax: priceTax };
  }

  private getOrderItemPrice(priceWrapper: PriceDto) {
    const allOverrides: Array<OrderItemPriceDto> = [];
    this.orderItems.forEach(oi => {
      if (oi.prices && oi.prices.length) {
        allOverrides.push(...oi.prices);
      }
    });
    const priceOverride = allOverrides.find(po => po.priceId === priceWrapper.id);
    if (priceOverride && priceOverride.overridingPrice) {
      return priceOverride.overridingPrice;
    } else {
      return priceWrapper.price;
    }
  }

  getPriorityWithName() {
    return this.productDetail.priority + this.productDetail.name;
  }
}
