import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TicketDto } from '@btl/order-bff';
import { FormArray } from '@angular/forms';
import { AppBlockerService, CompareType, Search, ServiceUtils, TicketingService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ecare-tasks-table',
  templateUrl: './ecare-tasks-table.component.html',
})
export class EcareTasksTableComponent implements OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  simpleMode = false;

  @Output()
  readonly editTaskEmitter = new EventEmitter<any>();

  @Output()
  readonly deleteTaskEmitter = new EventEmitter<string>();

  @Output()
  readonly sortChangedEmitter = new EventEmitter<void>();

  @Output()
  readonly selectDeselectAllEvent = new EventEmitter<void>();

  @Output()
  readonly duplicateTicketEvent = new EventEmitter<any>();

  @Input()
  ticketDtos: TicketDto[];

  @Input() cuRefNo;

  @Input() hideDueDate = false;
  @Input() hideState = false;

  @Input() selectFormArray: FormArray;
  @Input() search: Search;
  @Input() allTicketsSelected;
  @Input() anyTicketsSelected;

  selected: TicketDto;

  constructor(private readonly ticketingService: TicketingService,
              private readonly appBlockerService: AppBlockerService,) {
  }

  edit(ticketDto: TicketDto, inNewTab: boolean = false) {
    this.editTaskEmitter.emit({ id: ticketDto.id, inNewTab: inNewTab });
  }

  delete(ticketDto: TicketDto) {
    this.deleteTaskEmitter.emit(ticketDto.id);
  }

  duplicate(ticket: TicketDto) {
    this.duplicateTicketEvent.emit(ticket);
  }

  rowClicked(contact: TicketDto) {
    this.selected === contact ? (this.selected = null) : (this.selected = contact);
    if (this.selected && contact['tasks'].length === 0 && contact['tasksIds']?.length > 0) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareType.CompareTypeDtoEnum.IN,
        value: contact['tasksIds'],
      });
      this.ticketingService
        .getTicketsByFilter(search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(tasks => {
          contact['tasks'] = tasks.data;
        });
    }
  }
}
