import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WcOrderingService } from '@service/wc-ordering.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AddressDto,
  ContactDto,
  CustomerDto,
  ExternalLinkDto,
  GroupDto,
  OrderDto,
  OrderParamDto,
  PagedProcessingLogDto,
  ProductDetailDto
} from '@btl/order-bff';
import { OrderUtils } from '../../../../helpers/order-utils';
import { addressTypes } from '../../../../pipes/display-address.pipe';
import {
  AbstractPageComponent,
  AuthFactoryService,
  AuthService,
  CurrentLocaleService,
  EnableDynamicLoading,
  OrderingService,
  ProductService,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { OrderingWizardService, PaymentStatusEnum } from '@service/ordering-wizard-service';
import { Location } from '@angular/common';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { PrecalculatedShoppingCart } from '../../../../models/precalculated-shopping-cart';
import { ShoppingCartItemsComponent } from '../../../page/shopping-cart-items/shopping-cart-items.component';
import { OrderStateTypeEnum } from '../../../../models/orderStateTypeEnum';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import { PaymentService } from '@service/payment.service';
import { MessageDialogComponent } from '../../../page/message-dialog/message-dialog.component';

@Component({
  selector: 'app-ecare-orders-detail',
  templateUrl: './ecare-orders-detail.component.html',
  providers: [ShoppingCartService],
})
@EnableDynamicLoading({customName: EcareOrdersDetailComponent.PAGE_ID})
export class EcareOrdersDetailComponent extends AbstractPageComponent implements OnInit, OnDestroy {
  @ViewChild('shoppingCartItems', {static: true}) shoppingCartItemsComponent: ShoppingCartItemsComponent;

  public static readonly PAGE_ID = 'EcareOrdersDetailComponent';

  private PAYMENT_STATUS_TIMEOUTED_KEY = 'wc.shopping.payment.timeouted';
  private PAYMENT_STATUS_CANCELED_KEY = 'wc.shopping.payment.canceled';
  private PAYMENT_STATUS_CODEBOOK_PREFIX = 'wc.shopping.PAYMENT_TYPE.';
  private PAYMENT_STATUS_INFO_SUFFIX = '.info';
  private PAYMENT_STATUS_DESCRIPTION_SUFFIX = '.description';
  private PAYMENT_STATUS_CHECK_KEY = 'wc.shopping.payment.check.status';

  pageId(): string {
    return EcareOrdersDetailComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      this.authService = this.authFactoryService.getAuthService();
      const orderId = this.params.id;
      if (orderId) {
        this.reload(orderId);
      }
    } else {
      this.order = undefined;
    }
  }

  order: OrderDto;
  precalculatedShoppingCart: PrecalculatedShoppingCart;
  customer: CustomerDto;
  customerAccount: any;

  invoicing: any;
  onetimePaymentMethod: string;
  paymentMethod: ProductDetailDto;
  deliveryMethod: string;
  deliveryAddress: any;
  deliveryContact: any;

  defaultChoice: string = 'vat';
  showVat: boolean;

  paymentStatus: string;
  paymentFailedReason: string;
  isInternal: boolean;
  paymentStatusEnum = PaymentStatusEnum;
  orderStateTypeEnum = OrderStateTypeEnum;
  checkPaymentStatusManually: boolean = false;
  paymentStatusCheckCount: number = 0;
  readonly paymentStatusCheckMax: number = 40;

  addressTypes = addressTypes;

  totalOcPrice: number;
  totalOcPriceTax: number;
  totalRcPrice: number;
  totalRcPriceTax: number;

  externalLinks: ExternalLinkDto[];

  blockingReason: string;
  isBlocked: boolean = false;
  isProcessingError: boolean = false;
  showProcessingLogs = false;
  pagedProcessingLogDto: PagedProcessingLogDto;
  filesLength: number;

  constructor(
    private wcOrderingService: WcOrderingService,
    private orderingService: OrderingService,
    private productService: ProductService,
    private authService: AuthService,
    private authFactoryService: AuthFactoryService,
    protected route: ActivatedRoute,
    private location: Location,
    protected router: Router,
    private currentLocaleService: CurrentLocaleService,
    private ngbModal: NgbModal,
    private translateService: TranslateService,
    private paymentService: PaymentService,
    private stickyMessageService: StickyMessageService,
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.showVat = true;
    this.authService.accountChange.pipe(
      takeUntil(this.onDestroy$)).subscribe(() => {
      this.isInternal = !this.authService.account?.external;
    });

    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.order) {
        this.orderingService.getOrderExternalLinks(this.order.id).subscribe(result => {
          this.externalLinks = result;
        });
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private reload(orderId: string) {
    this.wcOrderingService
      .searchOrders(orderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order: OrderDto) => {
        this.loadOrder(order);
      });
  }

  loadOrder(order: OrderDto) {
    this.order = order;
    this.loadOrderAttributes();
    this.loadOrderItems();
    this.shoppingCartItemsComponent
      .useOrder(this.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(precalculatedShoppingCart => {
        this.precalculatedShoppingCart = precalculatedShoppingCart;
        this.paymentMethod = precalculatedShoppingCart.getProductDetailByCategory(CategoryTypeEnum.PRODC_GE_PAYMENT);
      });
    this.orderingService.getOrderExternalLinks(this.order.id).subscribe(result => {
      this.externalLinks = result;
    });
  }

  loadOrderAttributes() {
    this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'customer'));
    this.customerAccount = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'customerAccount'));
    this.paymentStatus = OrderUtils.getOrderAttributeValue(this.order, 'paymentStatus');
    this.onetimePaymentMethod = OrderUtils.getOrderAttributeValue(this.order, 'onetimePaymentMethod');
    this.deliveryMethod = OrderUtils.getOrderAttributeValue(this.order, 'deliveryMethod');
    this.blockingReason = OrderUtils.getOrderAttributeValue(this.order, OrderingWizardService.BLOCKING_REASON_ATTR);
    this.isBlocked = OrderUtils.getOrderAttributeValue(this.order, OrderingWizardService.IS_BLOCKED_ATTR) === 'true';
    this.isProcessingError = OrderUtils.getOrderAttributeValue(this.order, 'isProcessingError') === 'true';
    this.paymentFailedReason = OrderUtils.getOrderAttributeValue(this.order, 'paymentFailedReason');

    this.totalOcPrice = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceOC'));
    this.totalOcPriceTax = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceWithoutTaxOC'));
    this.totalRcPrice = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceRC'));
    this.totalRcPriceTax = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceWithoutTaxRC'));

    const deliveryCon = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'deliveryContact'));
    const deliveryAdd = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'deliveryAddress'));

    if (this.customer && deliveryAdd) {
      this.deliveryAddress = deliveryAdd;
    }
    if (this.customerAccount) {
      if (this.customerAccount.contacts) {
        this.customerAccount.contacts.forEach((contact: ContactDto) => {
          if (contact.type === ContactDto.TypeDtoEnum.INVOICING) {
            this.invoicing = contact;
          }
        });
      }
      if (!this.invoicing) {
        if (this.customer) {
          this.invoicing = this.customer;
        }
      }
      if (this.customerAccount.address) {
        if (this.customerAccount.address.type === AddressDto.TypeDtoEnum.INVOICING) {
          this.invoicing.address = this.customerAccount.address;
        }
      } else {
        if (this.customer && this.customer.address) {
          this.invoicing.address = this.customer.address;
        }
      }
      if (deliveryCon) {
        this.invoicing = deliveryCon;
      }
      if (deliveryAdd) {
        this.invoicing.address = deliveryAdd;
      }
    }
    if (deliveryCon) {
      this.deliveryContact = deliveryCon;
    }
    if (deliveryAdd) {
      this.deliveryAddress = deliveryAdd;
    }
  }

  loadOrderItems() {
    // In case if order is in CREATED state and deliveryMethod && paymentMethod is missing in orderAttribute then take it from OrderItems
    if (this.order.orderStateType === OrderStateTypeEnum.CREATED) {
      if (!this.deliveryMethod) {
        this.productService
          .getProductGroupByGroupCode('DELIVERY_ORDER')
          .pipe(takeUntil(this.onDestroy$))
          .pipe(
            mergeMap((groupDto: GroupDto) => {
              return of(
                this.productService
                  .getGroupProducts([groupDto.id], null, null, this.order.id)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe((deliveryProducts: ProductDetailDto[]) => {
                    this.deliveryMethod = deliveryProducts.filter(method =>
                      this.order?.orderItems.find(orderItem => orderItem?.productId === method.id)
                    )[0]?.id;
                  })
              );
            })
          )
          .subscribe();
      }
      if (!this.onetimePaymentMethod) {
        this.productService
          .getProductGroupByGroupCode('PAYMENT_ORDER')
          .pipe(takeUntil(this.onDestroy$))
          .pipe(
            mergeMap((groupDto: GroupDto) => {
              return of(
                this.productService
                  .getGroupProducts([groupDto.id], null, null, this.order.id)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe((paymentProducts: ProductDetailDto[]) => {
                    this.onetimePaymentMethod = paymentProducts.filter(method =>
                      this.order?.orderItems.find(orderItem => orderItem?.productId === method.id)
                    )[0]?.id;
                  })
              );
            })
          )
          .subscribe();
      }
    }
  }

  checkStatusManually() {
    this.wcOrderingService
      .getOrderByRefNo(this.order.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((orderDto: OrderDto) => {
        this.checkStatus(orderDto);
      });
  }

  checkStatus(orderDto: OrderDto) {
    const paymentStatus = OrderUtils.getOrderAttributeValue(orderDto, OrderingWizardService.PAYMENT_STATUS_ATTR);
    if (
      paymentStatus === PaymentStatusEnum.COMPLETED ||
      paymentStatus === PaymentStatusEnum.APPROVED ||
      paymentStatus === PaymentStatusEnum.CANCELED ||
      paymentStatus === PaymentStatusEnum.TIMEOUTED
    ) {
      this.paymentStatus = paymentStatus;
      this.paymentStatusCheckCount = 0;
    } else {
      if (this.paymentStatusCheckCount < this.paymentStatusCheckMax) {
        setTimeout(() => {
          this.wcOrderingService.doNotUnBlockOneCall = true;
          this.wcOrderingService
            .searchOrders(this.order.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((orderDto: OrderDto) => {
              this.paymentStatusCheckCount += 1;
              this.checkStatus(orderDto);
            });
        }, 3000);
      } else {
        this.checkPaymentStatusManually = true;
      }
    }
  }

  getPaymentStatusDescription(): string {
    switch (this.paymentStatus) {
      case PaymentStatusEnum.CREATED:
        const translation = this.translateService.instant(this.PAYMENT_STATUS_CHECK_KEY);
        return this.checkPaymentStatusManually ? translation : `${translation}...`;
      case PaymentStatusEnum.APPROVED:
        return this.translateService.instant(
          this.PAYMENT_STATUS_CODEBOOK_PREFIX + this.onetimePaymentMethod + this.PAYMENT_STATUS_INFO_SUFFIX
        );
      case PaymentStatusEnum.COMPLETED:
        return this.translateService.instant(
          this.PAYMENT_STATUS_CODEBOOK_PREFIX + this.onetimePaymentMethod + this.PAYMENT_STATUS_INFO_SUFFIX
        );
      case PaymentStatusEnum.CANCELED:
        return this.translateService.instant(this.PAYMENT_STATUS_CANCELED_KEY);
      case PaymentStatusEnum.TIMEOUTED:
        return this.translateService.instant(this.PAYMENT_STATUS_TIMEOUTED_KEY);
      case PaymentStatusEnum.NOT_APPLICABLE:
        return this.translateService.instant(
          this.PAYMENT_STATUS_CODEBOOK_PREFIX + this.onetimePaymentMethod + this.PAYMENT_STATUS_DESCRIPTION_SUFFIX
        );
      default:
        return null;
    }
  }

  backToListing() {
    this.location.back();
  }

  openInNewTab(externalLink: ExternalLinkDto) {
    window.open(externalLink.url, '_blank');
  }

  unblockOrder() {
    const dialogReference = this.ngbModal.open(ConfirmationDialogComponent, {
      windowClass: 'dialog dialog-confirmation',
    });

    const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
    confirmationDialogComponent.heading = 'wc.common.confirmationWindow';
    confirmationDialogComponent.texts.push(`wc.ecare.orderDetail.unblockReason.warning.${this.blockingReason}`);
    confirmationDialogComponent.confirmationLocalizedKey = 'wc.ecare.orderDetail.yesUnblock';
    confirmationDialogComponent.confirmationIconPath = '/assets/svg/feather-unlock.svg';
    confirmationDialogComponent.cancelLocalizedKey = 'wc.ecare.common.cancel';
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = () => {
      if (this.blockingReason === 'PAYMENT') {
        this.paymentService.updateOrderPaymentStatus(this.order.id, 'ABORTED').subscribe(result => {
          dialogReference.dismiss();
          this.loadOrder(result);
          this.unblockOrderMessage();
        });
      } else {
        const orderParamsDto: Array<OrderParamDto> = [];
        OrderUtils.updateOrderAttr(orderParamsDto, OrderingWizardService.IS_BLOCKED_ATTR, 'false');
        const quoteAsMap = {
          orderAttributes: orderParamsDto,
          recordVersion: this.order.recordVersion,
        };
        this.wcOrderingService
          .patchOrder(this.order.id, quoteAsMap)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            dialogReference.dismiss();
            this.loadOrder(result);
            this.unblockOrderMessage();
          });
      }
    };
  }

  unblockOrderMessage(): void {
    const modalRef = this.ngbModal.open(MessageDialogComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog'
    });
    let warningPopUp = modalRef.componentInstance;
    warningPopUp.dialogRef = modalRef;
    warningPopUp.headerTranslationKey = 'wc.ecare.orderDetail.unblockOrderPopup.heading';
    warningPopUp.messageTranslationKey = 'wc.ecare.orderDetail.unblockOrderPopup.message';
  }

  reprocess() {
    this.orderingService
      .resumeOrder(this.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.reload(this.order.id));
  }

  cancelOrder() {
    this.wcOrderingService
      .cancelOrder(this.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.orderCancelled.message');
        this.reload(this.order.id);
      });
  }

  showHideProcessingLogs() {
    this.showProcessingLogs = !this.showProcessingLogs;
    if (this.showProcessingLogs && !this.pagedProcessingLogDto) {
      this.orderingService
        .filterOrderProcessingLogs(this.order.id, ServiceUtils.getUnlimitedSearch())
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.pagedProcessingLogDto = result;
        });
    }
  }

  showProcessingLogFailures(content) {
    this.ngbModal.open(content, { size: 'lg', scrollable: true });
  }

  resumeOrder(order: OrderDto) {
    if (order.authCode) {
      this.router.navigate([`/eshop/shopping-cart/${order.authCode}`]);
    } else {
      this.router.navigate([`/eshop/shopping-cart/id/${order.id}`]);
    }
  }

  getFileLength($event) {
    this.filesLength = $event;
  }

  payOrder() {
    this.paymentService.reprocessOrderPayment(this.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
      });
  }

  getStickerType() {
    return OrderUtils.getOrderStickerType(this.order);
  }
}
