import { Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb } from '@btl/btl-fe-wc-common';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { CrmPartyRoleEnum } from '../../../models/CrmPartyRoleEnum';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerAccountDto, CustomerDto, PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-breadcrumbs',
  templateUrl: './ecare-breadcrumbs.component.html',
})
export class EcareBreadcrumbsComponent implements OnInit, OnDestroy {
  selectedParty: CustomerDto | CustomerAccountDto;
  customer: CustomerDto;
  breadcrumbs: Breadcrumb[] = [];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private customerLocalStorageService: CustomerLocalStorageService) {}

  ngOnInit(): void {
    this.customerLocalStorageService.contextChanged
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.initBreadcrumbs());
    this.customerLocalStorageService.contextChangedCustomerAccount
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.initBreadcrumbs());
    this.initBreadcrumbs();
  }

  private initBreadcrumbs() {
    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    const currentCustomerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();
    this.selectedParty = currentCustomerAccount ? currentCustomerAccount : this.customer;

    this.breadcrumbs = [];
    if (!this.selectedParty) {
      return;
    }

    const getName = (party: PartyDto): string => {
      if (CustomerPartyUtil.hasPartyRole(party, CrmPartyRoleEnum.Customer)) {
        const customer = <CustomerDto>party;
        const name =
          (customer.subject.firstName ? `${customer.subject.firstName} ` : '') +
          (customer.subject.lastName ? `${customer.subject.lastName} ` : '');
        return name ? name : 'wc.ecare.dashboard.breadcrumbs.labelDashboard';
      }
      return party.displayName || party.id;
    };

    const getUrl = (party: PartyDto): string =>
      CustomerPartyUtil.hasPartyRole(party, CrmPartyRoleEnum.CustomerAccount)
        ? `/ecare/dashboard/${this.customer.id}/${party.id}`
        : `/ecare/dashboard/${this.customer.id}`;

    this.breadcrumbs.push({
      name: getName(this.selectedParty),
      seoUrl: getUrl(this.selectedParty),
    });
    let party: PartyDto = this.selectedParty;
    while (party?.parentId) {
      party = this.customerLocalStorageService.getPartyByIdRecursive(this.customer.childParties, party.parentId);
      if (party) {
        this.breadcrumbs.push({
          name: getName(party),
          seoUrl: getUrl(party),
        });
      }
    }
    if (this.selectedParty?.id !== this.customer.id) {
      this.breadcrumbs.push({
        name: getName(this.customer),
        seoUrl: getUrl(this.customer),
      });
    }

    this.breadcrumbs.reverse();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
