<div class="tiles_wrapper">
  <span class="anchor" id="customer"></span>
  <app-customer-tile class="full_tile" [customer]="customer"> </app-customer-tile>
  <div class="tile -tile12">
    <div class="tile_inner">
      <div class="accordion">
        <app-ecare-business-customer-account
          *ngFor="let customerAccount of customerAccounts"
          [customerAccount]="customerAccount"
          [customer]>
        </app-ecare-business-customer-account>
      </div>
    </div>
  </div>
</div>
