<div class="nav-tariff-item" (click)="selectTariff()">
  <p class="nav-tariff-type">{{ tariffSpace.state }}</p>
  <div class="nav-tariff-icon-divider">
    <div class="nav-tariff-icon-wrapper">
      <i class="svg-icon nav-tariff-icon-disabled" [inlineSVG]="'../../../../assets/img/ecare/internet_icon.svg'"></i>
    </div>
  </div>
  <div class="nav-tariff-info">
    <p class="nav-tariff-name">{{ tariffProduct.name }}</p>
    <p class="nav-tariff-friendly-name">{{ tariffProduct.shortDescription }}</p>
  </div>
  <hr class="nav-tariff-resource-hr" />
  <ng-template #tariffIdentifiersList>
    <ul>
      <ng-container *ngFor="let tariffIdentifier of tariffIdentifiers">
        <li *ngIf="tariffIdentifier.resourceType === 'phoneNumber' && tariffIdentifier.resourceValue">
          {{ tariffIdentifier.resourceValue | phoneNumber }}
        </li>
        <li *ngIf="tariffIdentifier.resourceType !== 'phoneNumber'">{{ tariffIdentifier.resourceValue }}</li>
      </ng-container>
    </ul>
  </ng-template>
  <p
    class="nav-tariff-resource-identifiers"
    tooltipClass="ecare-tariff-identifiers-tooltip"
    placement="bottom"
    container="body"
    [ngbTooltip]="tariffIdentifiersList">
    <ng-container *ngFor="let tariffIdentifier of tariffIdentifiers">
      <span
        *ngIf="tariffIdentifier.resourceType === 'phoneNumber' && tariffIdentifier.resourceValue"
        class="-resource-value">
        {{ tariffIdentifier.resourceValue | phoneNumber }}
      </span>
      <span *ngIf="tariffIdentifier.resourceType !== 'phoneNumber'" class="-resource-value">{{
        tariffIdentifier.resourceValue
      }}</span>
    </ng-container>
  </p>
</div>
