import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AccountEntityRoleDto } from '@btl/order-bff';
import { AuthFactoryService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-ecare-account-entity-table',
  templateUrl: './ecare-account-entity-table.component.html',
})
export class EcareAccountEntityTableComponent {
  @Input()
  entityRoles: AccountEntityRoleDto[];
  @Input() selectFormArray: FormArray;
  @Output()
  readonly editEmitter: EventEmitter<AccountEntityRoleDto> = new EventEmitter<AccountEntityRoleDto>();
  @Output()
  readonly deleteEmitter: EventEmitter<AccountEntityRoleDto> = new EventEmitter<AccountEntityRoleDto>();
  @Output()
  readonly delegateEmitter: EventEmitter<AccountEntityRoleDto> = new EventEmitter<AccountEntityRoleDto>();

  constructor(private authFactoryService: AuthFactoryService) {}

  edit(entityRole: AccountEntityRoleDto) {
    this.editEmitter.emit(entityRole);
  }

  delegate(entityRole: AccountEntityRoleDto) {
    this.delegateEmitter.emit(entityRole);
  }

  getParamValue(params, paramName) {
    return params.find(findParam => findParam.name === paramName)?.value;
  }

  delete(entityRole: AccountEntityRoleDto) {
    this.deleteEmitter.emit(entityRole);
  }
}
