import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { DateService } from '@service/date.service';

@Pipe({
  name: 'deliveryDate',
})
export class DeliveryDatePipe implements PipeTransform {
  constructor(private dateService: DateService, @Inject(LOCALE_ID) private locale: string) {}

  transform(deliveryDate: Date): string {
    // TODO return langtext instead
    if (this.dateService.isToday(deliveryDate)) {
      return 'wc.shopping.gui_productDetail.delivery.deliveryToday';
    } else if (this.dateService.isTomorrow(deliveryDate)) {
      return 'wc.shopping.gui_productDetail.delivery.deliveryTomorrow';
    } else {
      return formatDate(deliveryDate, 'dd.MM.', this.locale);
    }
  }
}
