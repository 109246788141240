<div class="form ecare-filter" [formGroup]="filterForm">
  <div class="-filter-fields">
    <input
      id="002_financial_document_filter"
      type="text"
      formControlName="id"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.financialDocumentFilter.id.placeholder' | translate }}" />

    <app-advanced-filter
      clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
      [form]="filterForm"
      [isAdvancedFilterOpen]="isAdvancedFilterOpen"
      [changedFilterFieldsCount]="changedFilterFieldsCount"
      (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
      (resetFieldsEmitter)="resetFilterFields()">
      <app-advanced-filter-field
        *ngIf="!customerLocalStorageService.getCurrentCustomerAccount()"
        labelLocalizedKey="wc.ecare.financialDocument.caRefNo"
        fullWidth="true"
        idAttribute="001_financial_document_filter"
        controlName="caRefNo"
        optionLabel="label"
        optionValue="value"
        [fieldType]="'select'"
        [selectOptions]="customerAccounts"
        emptyLocalizedKey="wc.ecare.users.all"
        [showEmptyOption]="true"
        emptyValue=""
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        fullWidth="true"
        idAttribute="003_financial_document_filter"
        controlName="openAmountOnly"
        fieldType="checkbox"
        additionalCss="-filter-open-amount-only"
        labelLocalizedKey="wc.ecare.financialDocument.openAmountOnly"
        (changeFilterFieldAction)="handleChange()">
      </app-advanced-filter-field>
      <app-advanced-filter-field labelLocalizedKey="wc.ecare.financialDocument.category" fieldType="select">
        <ng-select
          formControlName="category"
          class="ecare-dropdown"
          [clearable]="false"
          [clearOnBackspace]="false"
          [searchable]="false"
          (change)="changeFilterField('category', $event.code)">
          <ng-option [value]="''">{{ 'wc.ecare.financialDocument.category.defaultFilter' | translate }}</ng-option>
          <ng-option *ngFor="let category of categories" [value]="category.code">
            <div
              *ngIf="codebookService.getCodebookTexts('DOCUMENT_TYPE') | async as codebook"
              class="-opportunity-type">
              {{ codebook[category.code] }}
            </div>
          </ng-option>
        </ng-select>
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.financialDocument.status"
        controlName="status"
        optionLabel="localizedTextKey"
        optionValue="code"
        [fieldType]="'select'"
        [selectOptions]="statusOptions"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.financialDocument.billPeriod"
        controlName="billDateFrom"
        placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
        [fieldType]="'date'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        controlName="billDateTo"
        placeholderLocalizedKey="wc.ecare.financialDocument.billPeriod.placeholder.endDate"
        [fieldType]="'date'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
    </app-advanced-filter>
  </div>
  <app-button
    id="004_financial_document_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()"></app-button>
</div>
