import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { CustomerAccountDto, CustomerDto, PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-customer-account-box',
  templateUrl: './ecare-customer-account-box.component.html',
})
export class EcareCustomerAccountBoxComponent implements OnInit {
  public readonly ARROW_DOWN_CSS_CLASS = 'arrow down';
  public readonly ARROW_UP_CSS_CLASS = 'arrow up';
  private readonly HIDDEN_CSS_CLASS = 'hidden';

  @Input()
  customer: CustomerDto;
  @Input()
  selectedAccount: PartyDto;

  rollingPartOpen: boolean = false;

  @ViewChild('rollingPart') rollingPartElement: ElementRef;
  @ViewChild('mainControl') mainControlElement: ElementRef;

  selectedParents: string[];
  allParties: Array<PartyDto> = [];
  allRootParties: Array<PartyDto> = [];
  omittedResults = [];

  private searchTimeout;
  searchMade: boolean = false;

  constructor(protected router: Router, private customerLocalStorageService: CustomerLocalStorageService) {
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (
      this.rollingPartOpen &&
      !this.rollingPartElement.nativeElement.contains(event.target) &&
      !this.mainControlElement.nativeElement.contains(event.target)
    ) {
      this.rollingPartOpen = false;
    }
  }

  ngOnInit(): void {
    if (this.customer) {
      this.allParties = this.getAllChildParties(this.customer).filter(party => party.role === 'CustomerAccount');
      this.initRootParties();
      this.selectedParents = this.getPrecedingParents(this.allParties, this.selectedAccount.id);
    }
  }

  getCACount(party: PartyDto) {
    const parties = party.childParties.filter(partyDto => partyDto.role === 'CustomerAccount');
    return parties.length ? parties.length : null;
  }

  private getAllChildParties(party: PartyDto) {
    if (party.childParties.length) {
      const childArrays = party.childParties.map(child => this.getAllChildParties(child));
      return [party].concat(...childArrays);
    } else {
      return [party];
    }
  }

  private getPrecedingParents(parties, originalId?, parentId?) {
    const usedId = parentId ? parentId : originalId;
    const currentParty = parties.find(obj => obj.id === usedId);
    if (!currentParty) {
      return [];
    }
    const element = originalId ? [] : [currentParty.id];
    const parentIds = this.getPrecedingParents(parties, undefined, currentParty.parentId);
    return element.concat(...parentIds);
  }

  private initRootParties() {
    this.allRootParties = this.allParties.filter(party => party.parentId === this.customer.id);
  }

  loadCustomerAccount(customerAccount: CustomerAccountDto, e): void {
    // prevent opening page on rolling button
    const isMainElement =
      e.target?.className &&
      e.target?.className.toString().includes('ca-element') &&
      e.target?.className.toString() !== 'ca-element-opener';
    if (isMainElement) {
      this.customerLocalStorageService.setCurrentCustomerAccount(customerAccount);
      const queryParams = {
        cuRefNo: this.customer.id,
        caRefNo: customerAccount.id,
      };
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: queryParams });
    }
  }

  goHome() {
    this.customerLocalStorageService.removeCurrentCustomerAccountFromContext();
    const queryParams = {
      cuRefNo: this.customer.id,
      caRefNo: this.selectedAccount.id,
    };
    this.router.navigate(['/ecare/dashboard'], { queryParams: queryParams });
  }

  goCustomer() {
    this.customerLocalStorageService.removeCurrentCustomerAccountFromContext();
    const queryParams = {
      cuRefNo: this.customer.id,
    };
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: queryParams });
  }

  searchCustomerAccount(searchString: string) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.manageSearch(searchString);
    }, 300);
  }

  private manageSearch(searchString: string) {
    if (searchString.length > 2) {
      this.searchMade = true;
      const results = this.allParties.filter(party => this.searchIncludesOneOfTheFields(party, searchString));
      const allChildren = [];
      const allParents = [];
      results.forEach(result => {
        allChildren.push(...this.getAllChildParties(result));
        allParents.push(...this.getPrecedingParents(this.allParties, result.id));
      });
      const childrenCodes = allChildren.map(child => child.id);
      this.omittedResults = this.allParties
        .filter(party => !childrenCodes.includes(party.id) && !allParents.includes(party.id))
        .map(party => party.id);
    } else {
      if (this.searchMade) {
        this.searchMade = false;
        this.omittedResults = [];
        this.initRootParties();
      }
    }
  }

  searchIncludesOneOfTheFields(party: PartyDto, searchString: string): boolean {
    const customName = party.parameters['customName'];
    const variableSymbol = party.parameters['variableSymbol'];
    return (
      (party.displayName && party.displayName.includes(searchString)) ||
      (party.extId && party.extId.includes(searchString)) ||
      (customName && customName.includes(searchString)) ||
      (variableSymbol && variableSymbol.includes(searchString))
    );
  }

  public toggle(elem: HTMLElement, arrow: HTMLElement) {
    elem.className = elem.className === this.HIDDEN_CSS_CLASS ? '' : this.HIDDEN_CSS_CLASS;
    arrow.className =
      arrow.className === this.ARROW_DOWN_CSS_CLASS ? this.ARROW_UP_CSS_CLASS : this.ARROW_DOWN_CSS_CLASS;
  }
}
