import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-contacts-mobile, [app-customer-contacts-mobile]',
  templateUrl: './customer-contacts-mobile.component.html',
})
export class CustomerContactsMobileComponent implements OnInit {
  @Input()
  contacts;

  ngOnInit(): void {}
}
