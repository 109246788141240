import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrderDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-collision-popup',
  templateUrl: './order-collision-popup.component.html',
})
export class OrderCollisionPopupComponent {

  @Input() urlToContinue: string;
  @Input() dialogRef;

  constructor(
    private router: Router,
    private orderingService: WcOrderingService,
    private location: Location,
  ) {}

  @HostListener('window:keyup.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    this.close();
  }

  cancelOrderAndStartNewScenario() {
    this.orderingService.getCurrentOrder().subscribe((order: OrderDto) => {
      if (order) {
        this.orderingService.cancelOrder(order).subscribe(() => {
          const queryParams = this.getParamsFromUrlString(this.urlToContinue);
          this.router.navigate([this.getRouteFromUrlString(this.urlToContinue)], {
            queryParams: this.getParamsFromUrlString(this.urlToContinue),
          });
        });
      }
    });
    this.dialogRef.dismiss();
  }

  close(){
    this.location.back();
    this.dialogRef.dismiss();
  }

  private getRouteFromUrlString(url: string) {
    return url.split('?')[0];
  }

  private getParamsFromUrlString(url: string) {
    const paramsObject = {};
    const paramsString = url.split('?')[1];
    if (paramsString) {
      const params = paramsString.split('&');

      for (const param of params) {
        const singleParam = param.split('=');
        paramsObject[singleParam[0]] = singleParam[1];
      }
    }

    return paramsObject;
  }
}
