<ng-template #fieldContent>
  <div
    class="advanced-filter-field {{ fieldType === 'date' ? '-advanced-filter-date-period' : '' }} {{ additionalCss }}">
    <label>{{ labelLocalizedKey ? (labelLocalizedKey | translate) : '&nbsp;' }}</label>
    <div
      class="{{ fieldType === 'input' || 'select' || 'date' || 'number' ? 'input' : '' }} {{
        fieldType === 'select' ? 'select' : ''
      }}">
      <div #ngContentRef>
        <ng-content></ng-content>
      </div>
      <ng-container *ngIf="!ngContentRef.children.length">
        <ng-container *ngIf="fieldType">
          <ng-container [ngSwitch]="fieldType">
            <ng-container *ngSwitchCase="'input'">
              <div *ngIf="form" [formGroup]="form">
                <input
                  type="text"
                  [attr.id]="idAttribute ? idAttribute : null"
                  formControlName="{{ controlName }}"
                  placeholder="{{ (placeholderLocalizedKey ? placeholderLocalizedKey : '') | translate }}"
                  (change)="callChangeFilterField(controlName, $event.target.value)"
                  (keyup)="callChangeFilterField(controlName, $event.target.value)" />
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <div *ngIf="form" [formGroup]="form">
                <input
                  type="number"
                  [attr.id]="idAttribute ? idAttribute : null"
                  formControlName="{{ controlName }}"
                  placeholder="{{ (placeholderLocalizedKey ? placeholderLocalizedKey : '') | translate }}"
                  (change)="callChangeFilterField(controlName, $event.target.value)"
                  (keyup)="callChangeFilterField(controlName, $event.target.value)" />
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <div *ngIf="form && (selectOptions || (isCodebook && codebookType))">
                <app-form-select-input
                  selectClass="ecare-dropdown"
                  [multipleSelect]="multipleSelect"
                  [controlName]="controlName"
                  [translationPrefix]="translationPrefix"
                  [parentGroup]="form"
                  [emptyLocalizedKey]="emptyLocalizedKey"
                  [emptyValue]="emptyValue"
                  [showEmptyOption]="showEmptyOption"
                  [disableOptionsTranslate]="disableOptionsTranslate"
                  [options]="selectOptions"
                  [labelOption]="optionLabel"
                  [valueOption]="optionValue"
                  [codebookType]="codebookType"
                  [inputType]="isCodebook ? 'codebook' : null"
                  [attr.id]="idAttribute ? idAttribute : null"
                  (selectionChangeEmit)="callChangeFilterField(controlName, $event)">
                </app-form-select-input>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              <div *ngIf="form" [formGroup]="form">
                <app-date-picker
                  class="date-picker"
                  formControlName="{{ controlName }}"
                  [showTime]="showTime"
                  [startOfDay]="startOfTheDay"
                  [endOfDay]="endOfTheDay"
                  placeholder="{{ (placeholderLocalizedKey ? placeholderLocalizedKey : '') | translate }}"
                  (ngModelChange)="callChangeFilterField(controlName, $event)"></app-date-picker>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'checkbox'">
              <div *ngIf="form" [formGroup]="form">
                <input
                  type="checkbox"
                  [attr.id]="idAttribute ? idAttribute : null"
                  formControlName="{{ controlName }}"
                  (change)="callChangeFilterField(controlName, $event.target.checked)" />
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="isInternal; else external">
  <div app-acl-internal-evaluate-component>
    <ng-container *ngTemplateOutlet="fieldContent"></ng-container>
  </div>
</ng-container>
<ng-template #external>
  <ng-container *ngTemplateOutlet="fieldContent"></ng-container>
</ng-template>
