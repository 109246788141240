<app-confirmation-dialog-border
  [cancellationVisible]="cancellationVisible"
  [confirmationLocalizedKey]="confirmationLocalizedKey"
  [confirmationIconPath]="confirmationIconPath"
  [cancelLocalizedKey]="cancelLocalizedKey"
  [cancellationDisabled]="cancellationDisabled"
  [cancellationHandler]="cancellationHandler"
  [confirmationDisabled]="confirmationDisabled"
  [confirmationHandler]="confirmationHandler"
  [dialogId]="confirmationDialogBlockUiElementName"
  [dialogReference]="dialogReference"
  [heading]="heading"
  [localizationParameters]="localizationParameters">
  <p *ngFor="let text of texts">
    {{ text | translate: localizationParameters }}
  </p>
</app-confirmation-dialog-border>
