<div class="ecare dashboard">
  <div class="ecare-header_container">
    <div class="ecare-header_headline">
      {{ 'wc.ecare.customers.headline' | translate }}
      <div class="ecare-numberOforders">
        {{ 'wc.ecare.orders.showing.label' | translate }} {{ customers.length }}
        {{ 'wc.ecare.customers.customers.label' | translate }}
      </div>
    </div>

    <app-customers-filter [filtering]="search.filtering" (filterChanged)="handleFilterChange()" />
  </div>

  <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
    <div class="tile -tile12">
      <div class="dashboard_search ecare-ordersSearch">
        <table *ngIf="customers.length > 0" class="ecare_listing-table orders-table table">
          <thead class="content-sm">
            <tr>
              <th>{{ 'wc.ecare.customers.table.firstName.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.lastName.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.identityNumber.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.compRegNo.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.customerNo.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.address.header' | translate }}</th>
              <th>{{ 'wc.ecare.customers.table.extNo.header' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <app-ecare-customer-row
              *ngFor="let customer of customers"
              [customer]="customer"
              (selectedCustomer)="customerSelected($event)" />
          </tbody>
        </table>
        <app-ecare-search-table-mobile
          pickerInputType="customer"
          [rows]="customers"
          (selectedRow)="customerSelected($event)" />

        <ng-container *ngIf="canDisplayNoCustomersText">
          <app-ecare-listing-no-results />
        </ng-container>
      </div>
      <div>
        <app-ecare-pagination
          [page]="search.paging.page"
          [pageSize]="search.paging.pageSize"
          [totalCount]="pagedDto?.totalItems"
          [pagesToShow]="pagedDto?.totalPages"
          (goPrev)="onPrevPage()"
          (goNext)="onNextPage()"
          (goPage)="onSpecificPage($event)"
          (pageSizeChanged)="onPageSizeChanged($event)" />
      </div>
    </div>
  </div>
</div>
