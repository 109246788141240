import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ElasticsearchService } from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { AddressHolder } from '../models/address-holder';
import { map } from 'rxjs/operators';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class PickUpPointService {
  constructor(private http: HttpClient, private elasticSearchService: ElasticsearchService) {}

  /**
   * Search elasticsearch for products by given text
   * @param term Text to search by
   * @param maxHits max hints amount
   * @param properties additional filtering properties
   */
  public searchByText(term: string, maxHits: number, properties?: Map<string, any>): Observable<AddressHolder[]> {
    return this.elasticSearchService
      .query(FilterService.PICK_UP_POINTSS_INDEX, null, term, properties, null, 1, maxHits)
      .pipe(map(oph => oph.hits.hits));
  }
}
