import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { ProductUtils } from 'app/helpers/product-utils';
import { ProductCustomService } from '@service/product-custom.service';

/**
 * Pipe for transforming number to formatted price (using locale) with currency
 */
@Pipe({
  name: 'price',
  pure: false,
})
export class PricePipe implements PipeTransform {
  private static currency: string = '';
  private static currencyKey: string = '';

  public static setCurrency(currency: string) {
    PricePipe.currency = currency;
  }

  public static setCurrencyKey(currencyKey: string) {
    PricePipe.currencyKey = currencyKey;
  }

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private translateService: TranslateService,
    private productCustomService: ProductCustomService
  ) {}

  transform(value: number, vatToDeduct: number = null): any {
    if (!PricePipe.currency && !PricePipe.currencyKey) {
      throw new Error('PricePipe does not have currency or currencyKey initialized.');
    }
    let currency = PricePipe.currency;
    if (!PricePipe.currency && PricePipe.currencyKey) {
      currency = this.translateService.instant(PricePipe.currencyKey);
    }

    if (value === null || value === undefined) {
      return value;
    }
    if (vatToDeduct) {
      value = value / vatToDeduct;
    }
    const currentLanguage = this.currentLocaleService.getCurrentLanguage();
    return this.format(value, currentLanguage, currency);
  }

  /**
   * Format the output using productCustomService.formatPrice.
   */
  public format(value: number, currentLanguage: string, currency: string): string {
    return this.productCustomService.formatPrice(value, currentLanguage, currency);
  }
}
