<div class="modal-body">
  <div class="pick-up-point-modal">
    <div class="close" (click)="closePopup()">
      <img src="/assets/img/map-close.svg" />
    </div>
    <app-form-pick-up-point-input
      [provider]="provider"
      [control]="form.controls['pickUpPoint']"
      (onChange)="selectEvent($event)">
    </app-form-pick-up-point-input>
  </div>
</div>
