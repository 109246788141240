<app-ecare-mobile-row-general
  *ngFor="let entityRole of entityRoles; let i = index"
  class="ecare_listing-table-row"
  [ngClass]="{ 'selected-row': selectFormArray.at(i).value }">
  <div class="mobile-table-row-wrapper" [for]="'ticket-' + i" (click)="toggleCheckbox(i)">
    <input
      class="checkbox"
      type="checkbox"
      [id]="'entityRole-' + i"
      [formControl]="selectFormArray.at(i)"
      [checked]="selectFormArray.at(i).value" />
    <div class="mobile-table-row-flex space-between">
      <div class="mobile-table-row-flex">
        <span class="mobile-table-row-flex information">{{ entityRole.account.login }}</span>
      </div>
      <ng-container>
        <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
          <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
            <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
          </span>

          <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ entityRole.id }}_popover-content">
            <div>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(entityRole)">
                {{ 'wc.ecare.ticket.edit.button' | translate }}
              </button>
              <button
                *ngIf="
                  entityRole.account.login &&
                  entityRole.account.login === authFactoryService.getAuthService().getUsername() &&
                  entityRole.canDelegate
                "
                class="btn btn-link action-list-more-actions-button"
                type="button"
                (click)="delegate(entityRole)">
                {{ 'wc.ecare.accountEntity.delegate.button' | translate }}
              </button>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="delete(entityRole)">
                {{ 'wc.ecare.ticket.delete.button' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="mobile-table-row-flex">
      <div class="mobile-table-row-flex highlight">
        {{ getParamValue(entityRole.account.parameters, 'firstName') }}
        {{ getParamValue(entityRole.account.parameters, 'lastName') }}
      </div>
      <app-sticker variant="neutral">{{ entityRole.entityId }}</app-sticker>
    </div>
    <div class="mobile-table-row-flex">
      {{ entityRole.roleType }}
    </div>
    <div *ngIf="entityRole.startDateTime" class="additional-info">
      {{ 'wc.ecare.accountEntity.validFrom' | translate }}: {{ entityRole.startDateTime | date : 'dd.MM.yyyy HH:mm' }}
    </div>
    <div *ngIf="entityRole.endDateTime" class="additional-info">
      {{ 'wc.ecare.accountEntity.validTo' | translate }}: {{ entityRole.endDateTime | date : 'dd.MM.yyyy HH:mm' }}
    </div>
    <div class="mobile-table-row-flex">
      {{ entityRole.entityType }}
    </div>

    <div class="mobile-table-row-flex">
      {{ entityRole.entityName }}
    </div>
  </div>
</app-ecare-mobile-row-general>
