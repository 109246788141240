import { Component } from '@angular/core';
import { EnableDynamicLoading, CmsWidgetBannerBrandListBaseComponent } from '@btl/btl-fe-wc-common';

import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

@Component({
  selector: '[app-cms-widget-banner-brand-list]',
  templateUrl: './cms-widget-banner-brand-list.component.html',
})
@EnableDynamicLoading({ customName: 'BN_BRAND_LIST_HP_BRAND_LIST' })
export class CmsWidgetBannerBrandListComponent extends CmsWidgetBannerBrandListBaseComponent {}
