<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.common.suspend.button' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <form class="form" [formGroup]="formUserDetails">
    <app-contact-form
      #hwInvoicingContactForm
      [type]="contactTypeEnum.HWINVOICING"
      [sectionView]="false"
      [main]="true"
      [account]="account"
      [form]="getHwInvoicingContactFormGroup"
      [parentGroup]="formUserDetails">
    </app-contact-form>
    <app-address-form
      #hwInvoicingAddressForm
      [type]="contactTypeEnum.HWINVOICING"
      [main]="true"
      [sectionView]="false"
      [entityGenerateForm]="true"
      [parentGroup]="formUserDetails"
      [form]="getHwInvoicingAddressFormGroup">
    </app-address-form>
  </form>
</div>

<div class="modal-footer">
  <div class="button-wrapper">
    <app-button variant="link" label="{{ 'wc.common.close.button' | translate }}" (click)="closePopup()"></app-button>
    <app-button variant="primary" label="{{ 'wc.common.suspend.button' | translate }}"></app-button>
  </div>
</div>
