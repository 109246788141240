import { Component, OnDestroy, OnInit } from '@angular/core';
import { Search, TicketingService } from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Subject, takeUntil } from 'rxjs';
import { ContactDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-contacts, [app-customer-contacts]',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.css'],
})
export class CustomerContactsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  constructor(private customerLocalStorageService: CustomerLocalStorageService,
    protected readonly ticketingService: TicketingService
  ) {}

  contacts = [ ];

  moreRecordsExists = false;
  canDisplayNoDataText = false;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'startFrom', sortOrder: 'asc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  public handleFilterChange(): void {
    this.search.paging.page = 1;
  }

  ngOnInit(): void {
    this.search.filtering.push({
      column: 'type',
      compareType: null,
      value: {
        code: 'CUSTOMER_CONTACT',
        areaType: 'CUSTOMER',
      },
    });

    if (this.customerLocalStorageService.getCurrentCustomer()) {
      let entityTypeId: string, entityId: string;
      if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
        entityTypeId = 'com.emeldi.ecc.be.crm.dto.CustomerAccount';
        entityId = this.customerLocalStorageService.getCurrentCustomerAccount().id;
      } else {
        entityTypeId = 'com.emeldi.ecc.be.crm.dto.Customer';
        entityId = this.customerLocalStorageService.getCurrentCustomer().id;
      }
      if (entityTypeId && entityId) {
        this.search.filtering.push({
          column: 'references',
          compareType: null,
          value: [
            {
              entityTypeId: entityTypeId,
              entityId: entityId,
            },
          ],
        });
      }
    }

    this.ticketingService
      .getTicketsByFilter(this.search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result && result.data.length > 0) {
          this.contacts = [
            {
              type: ContactDto.TypeDtoEnum.DELIVERY,
              role: 'ANONYMOUS',
              firstName: 'John',
              lastName: 'Doe',
              phone: '+420 602 905 001',
              email: 'john.doe@emeldi.com',
              channel: 'SMS',
            },
            {
              type: 'TP_INSTALLATION',
              role: 'IT_SPECIALIST',
              firstName: 'Edward',
              lastName: 'Cresswell',
              phone: '+420 602 905 002',
              email: 'edward.cresswell@emeldi.com',
              channel: 'EMAIL',
            },
            {
              type: 'TP_INSTALLATION',
              role: 'SHOP_ASSISTANT',
              firstName: 'Rukhsar ',
              lastName: 'Deleon',
              phone: '+420 602 905 004',
              email: 'rukhsar.deleon@emeldi.com',
              channel: 'EMAIL',
            },
          ];
        }
      });
  }
}
