import { Component, OnInit } from '@angular/core';
import { Breadcrumb, CodebookService, ProductService } from '@btl/btl-fe-wc-common';
import { ProductComparisonService } from '@service/product-comparison.service';
import { ComparedProduct } from '../../../models/compared-product';
import { ActivatedRoute, Router } from '@angular/router';
import { PictureUtils } from '../../../helpers/picture-utils';
import { PictureTypeEnum } from '../../../models/PictureTypeEnum';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ProductDetailComparisonCommonService } from '@service/product-detail-comparison-common.service';
import { ColorVariant } from '@service/color.service';
import { ProductCustomService } from '@service/product-custom.service';
import { AggregatedRatingDto, CodebookDto, ProductDetailDto, ProductParamMetadataDto } from '@btl/order-bff';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { ComparedProductParameterComponent } from './compared-product-parameter/compared-product-parameter.component';
//import { ProductListingComponent } from "../product-listing/product-listing.component";
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '@service/seo.service';
import { WishListPopupComponent } from 'app/components/wizard/wish-list/wish-list-popup/wish-list-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddingToCart, ProductListingService } from '@service/product-listing.service';

@Component({
  selector: 'app-product-comparison',
  templateUrl: './product-comparison.component.html',
  //providers: [DefaultErrorHandler, ProductService]
})
export class ProductComparisonComponent implements OnInit {
  SEO_URL_1_PARAM: string = 'seoUrl1';
  SEO_URL_2_PARAM: string = 'seoUrl2';
  SEO_URL_3_PARAM: string = 'seoUrl3';

  productDetailDtos: ProductDetailDto[] = [];
  comparedProducts: ComparedProduct[] = [];
  colorVariants: Array<Array<ColorVariant>> = [];
  sections$: Observable<Array<ComparisonSection>>;
  allSubscriptions: Array<Subscription> = new Array<Subscription>();
  ratings: Array<AggregatedRatingDto> = [];
  breadcrumbs: Array<Breadcrumb> = [];

  routeUrlParams: string[] = [];
  productToRemove: string;
  public similarProducts: Array<ProductDetailDto>;
  public similarProductCount: number;
  private paramHeights = new Map<string, Array<ComparedProductParameterComponent>>();

  constructor(
    private productComparisonService: ProductComparisonService,
    private productDetailAndComparisonCommonService: ProductDetailComparisonCommonService,
    private route: ActivatedRoute,
    private router: Router,
    private codebookService: CodebookService,
    private productCustomService: ProductCustomService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private translateService: TranslateService,
    private productServiceCommon: ProductService,
    private seoService: SeoService,
    private productListingService: ProductListingService,
    private ngbModal: NgbModal
  ) {
    this.translateService.onLangChange.subscribe(result => {
      if (this.comparedProducts) {
        this.loadProducts(true);
      }
    });
  }

  ngOnInit() {
    this.fillSeoUrlParams();
    this.loadProducts();
    const subscription = this.productComparisonService.comparisonChange.subscribe(() => {
      this.reloadProducts();
    });
    this.allSubscriptions.push(subscription);
    this.breadcrumbs.push({
      name: 'Comparison',
      seoUrl: this.getComparisonLink(this.comparedProducts),
    });
  }

  private reloadProducts() {
    this.routeUrlParams = this.productComparisonService.getComparisonList().map(p => p.seoUrl.toString());
    const navigateTo: string[] = [];
    navigateTo.push(this.productComparisonService.COMPARISON_PAGE_BASE_PATH);
    this.router.navigate(navigateTo.concat(this.routeUrlParams));
    this.loadProducts();
  }

  private loadProducts(notCached?) {
    // load session products
    this.comparedProducts = this.productComparisonService.getComparisonList();
    const productDtoObservables = new Array<Observable<ProductDetailDto>>();
    for (const routeParam of this.routeUrlParams) {
      productDtoObservables.push(this.productServiceCommon.getProductBySeoUrl(routeParam, null, notCached));
    }
    if (productDtoObservables.length > 0) {
      const subscription = forkJoin(productDtoObservables).subscribe(dtos => {
        this.productDetailDtos = dtos;

        //this.initRatings();
        this.initParameterList(notCached);
        this.initColorVariants();
        //this.initSimilarProducts();
      });
      this.allSubscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      subscription.unsubscribe();
    }
  }

  private removeProductFromComparison(seoUrl: string) {
    const recreatedUrlParams = this.removeProductAndRecreateParams(seoUrl, this.routeUrlParams);
    this.routeUrlParams = recreatedUrlParams;
    // if there are products in session remove them too
    this.comparedProducts = this.productComparisonService.getComparisonList();
    if (this.productToRemove && this.comparedProducts && this.comparedProducts.length > 0) {
      for (const comparedProduct of this.comparedProducts) {
        if (comparedProduct.seoUrl === this.productToRemove) {
          this.productComparisonService.removeFromComparison(comparedProduct.seoUrl);
        }
      }
    }
    const navigateTo: string[] = [];
    navigateTo.push(this.productComparisonService.COMPARISON_PAGE_BASE_PATH);
    this.router.navigate(navigateTo.concat(this.routeUrlParams));
  }

  private removeProductAndRecreateParams(removedProductSeoUrl: string, currentSeoUrls: string[]) {
    const newProductsSeoUrlArray: string[] = [];
    for (const currentSeoUrl of currentSeoUrls) {
      if (removedProductSeoUrl === currentSeoUrl) {
        this.productToRemove = currentSeoUrl;
      } else {
        newProductsSeoUrlArray.push(currentSeoUrl);
      }
    }
    return newProductsSeoUrlArray;
  }

  private fillSeoUrlParams() {
    const productSeoUrl1 = this.route.snapshot.paramMap.get(this.SEO_URL_1_PARAM);
    const productSeoUrl2 = this.route.snapshot.paramMap.get(this.SEO_URL_2_PARAM);
    const productSeoUrl3 = this.route.snapshot.paramMap.get(this.SEO_URL_3_PARAM);

    if (productSeoUrl1 && productSeoUrl1 !== '') {
      this.routeUrlParams.push(productSeoUrl1);
    }
    if (productSeoUrl2 && productSeoUrl2 !== '') {
      this.routeUrlParams.push(productSeoUrl2);
    }
    if (productSeoUrl3 && productSeoUrl3 !== '') {
      this.routeUrlParams.push(productSeoUrl3);
    }
  }

  private initParameterList(notCached?) {
    const productsCount: number = this.productDetailDtos.length;
    if (productsCount > 0) {
      const parameterListGroup$ = this.codebookService.getCodebooks('HW_COMPARISON_GROUP');
      const product1ParameterMetadata$ = this.productServiceCommon.getProductParameterMetadata(
        this.productDetailDtos[0].categoryId,
        notCached
      );
      if (productsCount > 1) {
        const product2ParameterMetadata$ = this.productServiceCommon.getProductParameterMetadata(
          this.productDetailDtos[1].categoryId,
          notCached
        );
        if (productsCount > 2) {
          const product3ParameterMetadata$ = this.productServiceCommon.getProductParameterMetadata(
            this.productDetailDtos[2].categoryId,
            notCached
          );
          this.fillSectionsWithThreeProducts(
            parameterListGroup$,
            product1ParameterMetadata$,
            product2ParameterMetadata$,
            product3ParameterMetadata$
          );
        } else {
          this.fillSectionsWithTwoProducts(parameterListGroup$, product1ParameterMetadata$, product2ParameterMetadata$);
        }
      } else {
        this.fillSectionsWithSingleProduct(parameterListGroup$, product1ParameterMetadata$);
      }
    }
  }

  private fillSectionsWithThreeProducts(
    parameterListGroup$,
    product1ParameterMetadata$,
    product2ParameterMetadata$,
    product3ParameterMetadata$
  ) {
    this.sections$ = forkJoin(
      parameterListGroup$,
      product1ParameterMetadata$,
      product2ParameterMetadata$,
      product3ParameterMetadata$
    ).pipe(
      map(
        ([parameterListGroup, product1ParameterMetadata, product2ParameterMetadata, product3ParameterMetadata]: [
          CodebookDto[],
          ProductParamMetadataDto[],
          ProductParamMetadataDto[],
          ProductParamMetadataDto[]
        ]) => {
          return this.createComparisonSections(
            parameterListGroup,
            product1ParameterMetadata,
            product2ParameterMetadata,
            product3ParameterMetadata
          );
        }
      ),
      share()
    );
  }

  private fillSectionsWithTwoProducts(parameterListGroup$, product1ParameterMetadata$, product2ParameterMetadata$) {
    this.sections$ = forkJoin(parameterListGroup$, product1ParameterMetadata$, product2ParameterMetadata$).pipe(
      map(
        ([parameterListGroup, product1ParameterMetadata, product2ParameterMetadata]: [
          CodebookDto[],
          ProductParamMetadataDto[],
          ProductParamMetadataDto[]
        ]) => {
          return this.createComparisonSections(
            parameterListGroup,
            product1ParameterMetadata,
            product2ParameterMetadata,
            null
          );
        }
      ),
      share()
    );
  }

  private fillSectionsWithSingleProduct(parameterListGroup$, productParameterMetadata$) {
    this.sections$ = forkJoin(parameterListGroup$, productParameterMetadata$).pipe(
      map(([parameterListGroup, product1ParameterMetadata]: [CodebookDto[], ProductParamMetadataDto[]]) => {
        return this.createComparisonSections(parameterListGroup, product1ParameterMetadata, null, null);
      }),
      share()
    );
  }

  private createComparisonSections(
    parameterListGroup,
    product1ParameterMetadata,
    product2ParameterMetadata,
    product3ParameterMetadata
  ) {
    const comparisonSections: Array<ComparisonSection> = new Array<ComparisonSection>();
    for (const codebook of parameterListGroup) {
      let productParameters: Array<ProductParameters> = new Array<ProductParameters>();
      productParameters = this.handleTheParams(
        codebook.code,
        product1ParameterMetadata,
        product2ParameterMetadata,
        product3ParameterMetadata
      );
      const section: ComparisonSection = {
        sectionName: codebook.text,
        products: productParameters,
      };
      comparisonSections.push(section);
    }
    this.setSeoData(comparisonSections);
    return comparisonSections;
  }

  getComparisonPictureHref(productDetail) {
    return PictureUtils.getPictureHrefByType(
      productDetail,
      PictureTypeEnum.PRODUCT_COMPARISON,
      'assets/img/products/phone_example-comparison.png'
    );
  }

  private handleTheParams(
    codebookCode: string,
    product1Metadata: ProductParamMetadataDto[],
    product2Metadata: ProductParamMetadataDto[],
    product3Metadata: ProductParamMetadataDto[]
  ) {
    const productParameters: Array<ProductParameters> = new Array<ProductParameters>();
    let thisMetadata;
    let allUniqueParamsWithPriorities: { priority: number; label: string; name: string }[];
    const mainProductParametersArray: Array<any> = new Array<any>();
    const productsCount = this.productDetailDtos.length;

    // filter comparison params and fill them for every product
    for (let repeat = 0; repeat < productsCount; repeat++) {
      if (repeat === 0 && product1Metadata) {
        thisMetadata = product1Metadata;
      } else if (repeat === 1 && product2Metadata) {
        thisMetadata = product2Metadata;
      } else if (repeat === 2 && product3Metadata) {
        thisMetadata = product3Metadata;
      }
      const filtered = thisMetadata
        .filter(param => param.metadata['guiCompareGroup'] === codebookCode)
        .sort((param1, param2) => {
          const param1Priority: number = parseInt(param1.metadata['guiComparePriority']);
          const param2Priority: number = parseInt(param2.metadata['guiComparePriority']);
          return this.getPriorityCompareCriteria(param1Priority, param2Priority);
        })
        .map(param => {
          return {
            label: param.label ? param.label : param.name,
            priority: param.metadata['guiComparePriority'],
            value:
              this.productDetailDtos[repeat].parametersStatic[param.name] === null
                ? null
                : this.productDetailAndComparisonCommonService.transformValueByType(
                    this.productDetailDtos[repeat].parametersStatic[param.name],
                    param.type,
                    param.metadata['unit']
                  ),
            name: param.name,
            type: param.type,
          };
        })
        .filter(param => !!param.value);
      mainProductParametersArray.push(filtered);
    }

    // assemble all used parameters of all products
    let allParamsOfAllProducts: Array<any> = new Array<any>();
    allParamsOfAllProducts = allParamsOfAllProducts.concat.apply(allParamsOfAllProducts, mainProductParametersArray);

    // find unique parameter values from all used and sort them by priority again
    allUniqueParamsWithPriorities = allParamsOfAllProducts
      .filter((paramPair, index, self) => {
        return (
          index ===
          self.findIndex(t => {
            return t.priority === paramPair.priority && t.label === paramPair.label;
          })
        );
      })
      .sort((a, b) => {
        return this.getPriorityCompareCriteria(a.priority, b.priority);
      });

    // compare group of params of every product with the list of all params used in comparison and fill missing with empty value to their position in the group
    for (let repeat = 0; repeat < productsCount; repeat++) {
      if (mainProductParametersArray[repeat].length !== allUniqueParamsWithPriorities.length) {
        for (let paramRepeat = 0; paramRepeat < allUniqueParamsWithPriorities.length; paramRepeat++) {
          if (
            mainProductParametersArray[repeat][paramRepeat] === undefined ||
            allUniqueParamsWithPriorities[paramRepeat].label !== mainProductParametersArray[repeat][paramRepeat].label
          ) {
            const newParam = {
              label: allUniqueParamsWithPriorities[paramRepeat].label,
              value: '',
              name: allUniqueParamsWithPriorities[paramRepeat].name,
            };
            mainProductParametersArray[repeat].splice(paramRepeat, 0, newParam);
          }
        }
      }
    }

    // finally create the product parameters array with id of the products to be handled by template
    for (let repeat = 0; repeat < productsCount; repeat++) {
      const productParams: ProductParameters = {
        seoUrl: this.productDetailDtos[repeat].seoUrl,
        parameters: mainProductParametersArray[repeat],
      };
      productParameters.push(productParams);
    }

    return productParameters;
  }

  private getPriorityCompareCriteria(param1Priority: number, param2Priority: number) {
    if (param1Priority === undefined) {
      param1Priority = 1000;
    }
    if (param2Priority === undefined) {
      param2Priority = 1000;
    }
    return param1Priority - param2Priority;
  }

  private initRatings() {
    /*    const ratingObservables: Array<Observable<AggregatedRatingDto>> = new Array<Observable<AggregatedRatingDto>>();
        for (const product of this.productDetailDtos) {
          if (product.parametersStatic['ratingEnabled'] !== 'true' || !product.parametersStatic['ean']) {
            ratingObservables.push(of(null));
          } else {
            const observable = this.prodRatingService.getProductAggregatedRating(product.parametersStatic['ean']);
            ratingObservables.push(observable);
          }
        }
        const allRatings$ = forkJoin(ratingObservables);
        const minRatingLimit$ = this.propertyAccessorLocalService.getMinRatingLimit();
        const subscription = forkJoin([allRatings$, minRatingLimit$]).subscribe(([ratings, minRatingLimit]) => {
          if (minRatingLimit) {
            this.ratings = ratings.map(rating => (rating && rating.averageRating >= minRatingLimit) ? rating : null);
          } else {
            this.ratings = ratings;
          }
          if (!this.ratings.some(rating => rating !== null)) {
            this.ratings = [];
          }
        });
        this.allSubscriptions.push(subscription);*/
  }

  private initColorVariants() {
    const colorObservables: Array<Observable<Array<ColorVariant>>> = new Array<Observable<Array<ColorVariant>>>();
    for (const product of this.productDetailDtos) {
      const observable = this.productCustomService.getColorVariants(product.productCode);
      colorObservables.push(observable);
    }
    const subscription = combineLatest(colorObservables).subscribe(colors => {
      this.colorVariants = colors;
    });
    this.allSubscriptions.push(subscription);
  }

  private initSimilarProducts() {
    /*    if (this.productDetailDtos && this.productDetailDtos.length > 0) {
          const similarProductsSubscription = this.productService.getGroupProducts(null, 'SIMILAR_PRODUCT', this.productDetailDtos[0].productCode)
            .subscribe(products => {
              this.similarProducts = products;
            });
          this.allSubscriptions.push(similarProductsSubscription);
        } else {
          this.similarProducts = undefined;
        }
        const subscription = this.propertyAccessorLocalService.getSimilarProductCount().subscribe(count => this.similarProductCount = count);
        this.allSubscriptions.push(subscription);*/
  }

  public moveLeft(comparisonTable: HTMLTableElement) {
    const relativeLocation = comparisonTable.scrollLeft / window.innerWidth;
    let index;
    if (relativeLocation % 1 !== 0) {
      index = Math.floor(relativeLocation);
    } else {
      index = relativeLocation - 1;
    }
    const newLeft = index * window.innerWidth;
    if (newLeft > 0) {
      comparisonTable.scroll(newLeft, comparisonTable.scrollTop);
    } else {
      comparisonTable.scroll(0, comparisonTable.scrollTop);
    }
  }

  public moveUp() {
    window.scrollTo({ top: 0 });
  }

  public moveRight(comparisonTable: HTMLTableElement) {
    const relativeLocation = comparisonTable.scrollLeft / window.innerWidth;
    const index = Math.floor(relativeLocation) + 1;
    const newLeft = index * window.innerWidth;
    if (newLeft > 0) {
      comparisonTable.scroll(newLeft, comparisonTable.scrollTop);
    } else {
      comparisonTable.scroll(0, comparisonTable.scrollTop);
    }
  }

  private setSeoData(comparisonSections: Array<ComparisonSection>) {
    const name = this.productDetailDtos.map(prod => prod.name).join(' & ');
    let keywords = name;
    keywords += `, ${comparisonSections.map(comparisonSection => comparisonSection.sectionName).join(', ')}`;
    keywords += `, ${SeoService.STATIC_KEYWORDS}`;
    this.seoService.setMeta(name, name, keywords);
  }

  getComparisonLink(comparedProducts: ComparedProduct[]) {
    const seoUrls: string[] = [this.productComparisonService.COMPARISON_PAGE_BASE_PATH];
    for (const comparedProduct of comparedProducts) {
      seoUrls.push(comparedProduct.seoUrl);
    }
    return this.router.createUrlTree(seoUrls).toString();
  }

  wishListPopup(productCode) {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = productCode;
  }

  addToCart(product: ProductDetailDto) {
    this.productListingService.handleProductAddingToCart(
      product as ProductAddingToCart,
      null,
      null,
      null,
      true
    );
  }
}

export interface ComparisonSection {
  sectionName: string;
  products: Array<ProductParameters>;
}

export interface ProductParameters {
  seoUrl: string;
  parameters: Array<ProductParameter>;
}

export interface ProductParameter {
  label: string;
  priority: number;
  value: string;
  name: string;
  type: string;
}
