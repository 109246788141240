import { Component, Input, OnInit } from '@angular/core';
import { BlockUIService } from 'ng-block-ui';
import { BusinessEventDto, BusinessEventTypeDto, MetaEntityAttributeDto } from '@btl/order-bff';
import { BlockTemplateComponent, CurrentLocaleService, ProductService } from '@btl/btl-fe-wc-common';
import { AttributeTypeEnum } from '../entity-generated-form/entity-generated-form.component';

@Component({
  selector: 'app-business-event-row',
  templateUrl: './business-event-row.component.html',
})
export class BusinessEventRowComponent implements OnInit {
  blockTemplate = BlockTemplateComponent;
  attributeTypeEnum = AttributeTypeEnum;

  @Input() event: BusinessEventDto;
  @Input() eventType: BusinessEventTypeDto;

  collapsed = false;

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private readonly productService: ProductService,
    private readonly blockService: BlockUIService
  ) {}

  ngOnInit(): void {
    this.eventType.metaDescription.attributes = this.eventType.metaDescription.attributes.sort((a, b) =>
      Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
    );
  }

  onRowClick(): void {
    if (this.collapsed === false) {
      this.collapsed = !this.collapsed;
      this.blockService.start(this.event.id);
    } else {
      this.collapsed = !this.collapsed;
    }
  }

  getAttributeLabel(attribute: MetaEntityAttributeDto): string {
    const ret = attribute.localizedTexts?.find(
      text => text.locale === this.currentLocaleService.getCurrentLanguage()
    )?.message;
    return ret ? ret : attribute.name;
  }
}
