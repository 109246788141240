import { Component, Input, OnDestroy } from '@angular/core';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { Router } from '@angular/router';
import { addressTypes } from 'app/pipes/display-address.pipe';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';
import { Subject } from 'rxjs';
import { CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-customer-card, [app-ecare-customer-card]',
  templateUrl: './ecare-customer-card.component.html',
})
export class EcareCustomerCardComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  customer: CustomerDto;
  addressTypes = addressTypes;

  constructor(
    private customerLocalStorageService: CustomerLocalStorageService,
    private customerService: CustomerService,
    private router: Router
  ) {}

  selectCustomer() {
    this.customerService
      .getCustomer(this.customer.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(customer => {
        this.customerLocalStorageService.setCurrentCustomer(customer, false);
        this.router.navigateByUrl('ecare/dashboard');
      });
  }

  getCustomerName() {
    return this.customer.subject.firstName
      ? `${this.customer.subject.firstName} ${this.customer.subject.lastName}`
      : `${this.customer.subject.lastName}`;
  }
}
