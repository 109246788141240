import { AfterViewChecked, Component, ElementRef, HostBinding, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-section-tabs, [app-section-tabs]',
  templateUrl: './section-tabs.component.html',
})
export class SectionTabsComponent implements AfterViewChecked {
  @HostBinding('class') class = 'app-section-tabs';

  @ViewChild('tabIndicator', { static: false }) tabIndicator: ElementRef<HTMLDivElement>;
  @ViewChild('tabContainer', { static: false }) tabContainer: ElementRef<HTMLDivElement>;

  constructor(public elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked() {
    const activeElement = document.querySelector('.nav-link.active');
    if (activeElement) {
      const activeTab = this.renderer.selectRootElement('.nav-link.active', true);
      if (activeTab !== null) this.setActiveTab(activeTab);
    }
  }

  setActiveTab(selectedTab) {
    const slider = this.tabIndicator;

    const getCalc = el => {
      const width = selectedTab.offsetWidth;
      const offset = el.offsetLeft;
      return { width: width, offset: offset };
    };

    const applyCalc = el => {
      this.renderer.setStyle(slider.nativeElement, 'maxWidth', `${getCalc(el).width}px`);
      this.renderer.setStyle(slider.nativeElement, 'transform', `translateX(${getCalc(el).offset}px)`);
    };

    applyCalc(selectedTab);
  }
}
