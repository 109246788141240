import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DmsFileDto, TicketDto, TicketNoteDto } from '@btl/order-bff';
import { AppBlockerService, CodebookService, DmsService, ServiceUtils, TicketingService } from '@btl/btl-fe-wc-common';
import { forkJoin, Subject } from 'rxjs';
import { Attachment, FormAttachmentsComponent } from '../../form-attachments/form-attachments.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { TicketService } from '@service/ticket.service';

@Component({
  selector: 'app-abstract-ticket-expanded-row',
  template: ''
})
export abstract class AbstractTicketExpandedRowComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() ticket;

  @Output() readonly addTicketRelatedTask = new EventEmitter<TicketDto>();

  @Output() readonly openTicketRelatedTask = new EventEmitter<string>();

  @ViewChild(FormAttachmentsComponent) formAttachmentsComponent: FormAttachmentsComponent;

  form: FormGroup = this.formBuilder.group({
    attachments: []
  });

  editedNote: TicketNoteDto;

  constructor(
    public codebookService: CodebookService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private dmsService: DmsService
  ) {
  }

  ngOnInit(): void {
    this.form.patchValue(this.ticket);
  }

  addRelatedTask() {
    this.addTicketRelatedTask.emit(this.ticket);
  }

  openRelatedTask(taskId: string) {
    this.openTicketRelatedTask.emit(taskId);
  }

  addNote() {
    this.editedNote = {
      note: '',
      noteType: 'GENERAL'
    };
  }

  fieldChanged(attachments) {
    const createDmsFilesList: DmsFileDto[] = [];
    this.ticket.attachments = attachments;
    this.ticket.attachments.forEach((attachment: Attachment) => {
      attachment.id = null;
      attachment.dmsFile.contentHref = null;
      attachment.dmsFile.size = null;
      if (!attachment.dmsFile?.id) {
        createDmsFilesList.push(attachment.dmsFile);
      }
      delete attachment.dmsFile;
    });
    this.ticket.attachments = this.ticket.attachments.filter(attachment => !attachment['delete']);

    this.saveTicket(createDmsFilesList);
  }

  saveFiles(createDmsFilesList: DmsFileDto[], ticket) {
    if (createDmsFilesList && createDmsFilesList.length > 0) {
      const calls = [];
      createDmsFilesList.forEach(file => calls.push(this.dmsService.createFile(file)));
      forkJoin(calls)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.reloadTicket(ticket);
        });
    } else {
      this.reloadTicket(ticket);
    }
  }

  saveTicket(createDmsFilesList?: DmsFileDto[]) {
    const id = this.ticket.id;
    this.appBlockerService.block();
    this.ticket.id = null;
    TicketService.clearFieldsBeforeUpdate(this.ticket);

    if (id) {
      this.ticketingService
        .updateTicket(id, this.ticket)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => this.saveFiles(createDmsFilesList, result));
    }
  }

  reloadTicket(ticket) {
    ServiceUtils.copyWithExclude(ticket, this.ticket);
    this.form.patchValue(this.ticket);
  }

  cancelEditNote() {
    this.editedNote = null;
  }

  confirmNote() {
    if (this.editedNote.note && this.editedNote.note != '') {
      this.ticket.notes.push(this.editedNote);
      this.saveTicket();
    }
    this.editedNote = null;
  }
}
