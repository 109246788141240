<div *ngIf="boxImagesMeta" class="cms-widget-box-images-list" [attr.id]="urlAnchor">
  <ng-container *ngFor="let boxImageMeta of boxImagesMeta">
    <div class="-box">
      <img
        class="-box-bg"
        alt="Background image of the 'Box images list' widget"
        [lazyLoad]="boxImageMeta.backgroundImage" />

      <div class="-box-text -color-{{ boxImageMeta.labelColor.toLowerCase() }}">
        <h5 class="-label">{{ boxImageMeta.label | selectLocaleProperty }}</h5>
        <div class="-content" app-cms-widget-rich-content [content]="boxImageMeta.content"></div>
      </div>
    </div>
  </ng-container>
</div>
