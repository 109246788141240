import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SocialNetworksService } from '@service/social-networks.service';

@Component({
  selector: 'app-facebook-comments',
  templateUrl: './facebook-comments.component.html',
})
export class FacebookCommentsComponent implements AfterViewInit {
  @Input() href;

  @Input() width = '100%';

  @Input() numOfPosts = 5;

  @Input() orderBy = 'Time';

  @Input() colorSchema = 'light';

  @ViewChild('facebookCommentsApp', { static: false }) facebookCommentsApp: ElementRef<HTMLDivElement>;

  constructor(private socialNetworksService: SocialNetworksService) {
    socialNetworksService.activateFacebook();
  }

  ngAfterViewInit(): void {
    window['FB'] && window['FB'].XFBML.parse();
  }
}
