import { Component, Input, OnInit } from '@angular/core';
import { BlockTemplateComponent, getCurrentHost, ProductService } from '@btl/btl-fe-wc-common';
import { CategoryTypeEnum, ProductFilter } from '../../../models/product-filter';
import {
  AssetDto, CustomerAccountDto,
  CustomerDto,
  OrderDto,
  OrderItemDto,
  ProductInfoDto,
  ProductSortAttributeDto,
  SortDto, TariffSpaceDto
} from '@btl/order-bff';
import { PaginationComponent } from '../pagination/pagination.component';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from '../../../helpers/order-utils';
import { NavigationExtras, Router } from '@angular/router';
import { BlockUIService } from 'ng-block-ui';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { FormUtils } from '../../../helpers/form-utils';
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-sim-change',
  templateUrl: './sim-change.component.html',
})
export class SimChangeComponent implements OnInit {
  //region Data:

  /**
   * The UI block template.
   */
  blockTemplate = BlockTemplateComponent;

  /**
   * The original SIM.
   */
  originalSim: AssetDto;

  /**
   * The ICC of the original SIM.
   */
  originalSimIcc: string;

  /**
   * A list of available reasons to change the SIM.
   */
  reasons: ProductInfoDto[] = [];

  /**
   * The SIM change form.
   */
  simChangeForm: FormGroup = this.formBuilder.group({
    newSimIcc: [null, [Validators.required, Validators.pattern('^\\d{20}$')]],
    reason: [
      null,
      [
        //Validators.required
      ],
    ],
  });

  /**
   * A flag specifying if the SIM change failed (from any reason).
   */
  generalFailure: boolean = false;

  //region IO:

  /**
   * The customer of the changed SIM.
   */
  @Input()
  customer: CustomerDto;

  /**
   * The customer account of the changed SIM.
   */
  @Input()
  customerAccount: CustomerAccountDto;

  /**
   * The tariff space of the changed SIM. It must be non-null.
   */
  @Input()
  tariffSpace: TariffSpaceDto;

  /**
   * The tariff of the changed SIM. It must be non-null.
   */
  @Input()
  tariff: AssetDto;

  /**
   * The dialog reference.
   */
  @Input()
  dialogRef;

  originalSimsIds: Array<string>;

  constructor(
    private productService: ProductService,
    private orderingService: WcOrderingService,
    private blockUIService: BlockUIService,
    private modal: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.validateInputs();

    this.originalSim = CustomerPartyUtil.getChildAssetByCategoryId(this.tariff, CategoryTypeEnum.PRODC_SU_TP_GSM);
    this.originalSimIcc = CustomerPartyUtil.getAssetProductCharacteristicValue(this.originalSim, 'simIcc');

    this.loadReasons();
  }

  //region Event handling:

  /**
   * Perform SIM change. A CHANGE_SIM order is created with products of the TP and the selected reason. If the selected reason has
   * attribute upfrontPayment with value "true", the user gets redirected to payment. Otherwise, the order is confirmed directly.
   */
  changeSim(): void {
    FormUtils.validateAllFormFields(this.simChangeForm);
    if (this.simChangeForm.invalid) {
      return;
    }

    this.generalFailure = false;
    this.blockUIService.start(this.simChangeBlockUiElementName);

    const newOrder: OrderDto = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.CHECKOUT_THANKYOU,
      ScenarioTypeEnum.CHANGE_SIM
    );
    OrderUtils.addCustomerAttributeToOrder(newOrder, this.customer);
    OrderUtils.addCustomerAccountAttributeToOrder(newOrder, this.customerAccount);

    this.orderingService.createOrder(newOrder, true).subscribe(order1 => {
      if (order1 && !this.generalFailure) {
        const orderItems: OrderItemDto[] = [];

        const simOrderItem: OrderItemDto = {
          productId: this.originalSim.product.id,
          parentOrderItemId: this.tariff.id,
          asRefNo: this.originalSim.id,
          action: 'MODIFY',
          partyRefNo: this.tariffSpace.id,
          attributes: [
            {
              name: 'simIcc',
              value: this.newSimIcc,
            },
          ],
        };
        orderItems.push(simOrderItem);

        if (this.selectedReason) {
          const reasonOrderItem: OrderItemDto = {
            productId: this.selectedReason.id,
            action: 'ADD',
            parentOrderItemId: this.originalSim.id,
            partyRefNo: this.tariffSpace.id,
          };
          orderItems.push(reasonOrderItem);
        }

        this.orderingService.updateOrderItems(order1, orderItems, true).subscribe(order2 => {
          if (order2 && !this.generalFailure) {
            if (this.selectedReason) {
              this.productService.getProductById(this.selectedReason.id).subscribe(reasonDetail => {
                const upfrontPayment = reasonDetail.parametersStatic['upfrontPayment'];
                if (upfrontPayment && upfrontPayment === 'true') {
                  this.finishByPayment(order2);
                } else {
                  this.finishByConfirmation(order2);
                }
              });
            } else {
              this.finishByConfirmation(order2);
            }
          }
        }, this.setFailure);
      }
    }, this.setFailure);
  }

  //region Helpers:

  /**
   * Get the selected reason of SIM change from the form.
   */
  get selectedReason(): ProductInfoDto {
    return this.simChangeForm.get('reason').value;
  }

  /**
   * Set the selected reason of SIM change from the form.
   */
  set selectedReason(selectedReason: ProductInfoDto) {
    this.simChangeForm.get('reason').setValue(selectedReason);
  }

  /**
   * Get the new SIM ICC from the form.
   */
  get newSimIcc(): string {
    return this.simChangeForm.get('newSimIcc').value;
  }

  /**
   * Load available reasons of SIM change.
   */
  private loadReasons(): void {
    this.blockUIService.start(this.simChangeBlockUiElementName);

    const productFilter: ProductFilter = {
      attributes: {
        categoryId: 'PRODC_SU_OCC_FEE_TP',
        parametersStatic: {},
        priceRange: {},
        text: '',
      },
      sorting: {
        column: ProductSortAttributeDto.NAME,
        sortOrder: SortOrderDtoEnum.Asc,
      },
      paging: {
        page: 1,
        pageSize: PaginationComponent.pageSizeOptions[0],
      },
    };

    // To load reasons, an order must exist in the context.
    this.orderingService.getCurrentOrder().subscribe(() => {
      this.productService
        .getProductsByFilter(
          productFilter,
          [this.originalSim.id],
          this.originalSim.product.productCode,
          this.tariffSpace.id
        )
        .pipe(
          finalize(() => {
            this.blockUIService.stop(this.simChangeBlockUiElementName);
          })
        )
        .subscribe(searchResults => {
          if (searchResults && searchResults.data && searchResults.data.length > 0) {
            this.reasons = searchResults.data;
            this.selectedReason = this.reasons[0];
          }
        });
    });
  }

  /**
   * Finish the SIM change by order confirmation. Stop progress bar, hide the window, display confirmation.
   *
   * @param order The order.
   */
  private finishByConfirmation(order: OrderDto): void {
    this.orderingService.confirmOrder(order).subscribe(order2 => {
      if (order2 && !this.generalFailure) {
        this.blockUIService.stop(this.simChangeBlockUiElementName);
        this.dialogRef.dismiss();

        const confirmationMessageRef = this.modal.open(MessageDialogComponent, { size: 'sm', backdrop: 'static' });
        const confirmationMessageComponent = <MessageDialogComponent>confirmationMessageRef.componentInstance;
        confirmationMessageComponent.dialogRef = confirmationMessageRef;
        confirmationMessageComponent.headerTranslationKey = 'wc.ecare.simChange.confirmation.heading';
        confirmationMessageComponent.messageTranslationKey = 'wc.ecare.simChange.confirmation.text';
        confirmationMessageComponent.enableClose = true;
        confirmationMessageComponent.closeButtonHandler = () => {};
      }
    }, this.setFailure);
  }

  /**
   * Finish the SIM change by redirecting to payment. Stop progress bar, hide the window, redirect to payment.
   *
   * @param order The order.
   */
  private finishByPayment(order: OrderDto): void {
    this.orderingService.getOrderStoringHandler(order).subscribe((order2: OrderDto) => {
      this.blockUIService.stop(this.simChangeBlockUiElementName);
      this.dialogRef.dismiss();

      const navigationExtras: NavigationExtras = {
        queryParams: {
          callbackUrlBase: `${getCurrentHost()}/ecare/`,
          successUrl: 'dashboard',
          errorUrl: 'dashboard',
        },
      };
      this.router.navigate(['/eshop/payment'], navigationExtras);
    });
  }

  /**
   * Validate component inputs.
   */
  private validateInputs(): void {
    if (!this.tariffSpace) {
      throw new Error('The tariffSpace parameter must have non-null value.');
    }
    if (!this.tariff) {
      throw new Error('The tariff parameter must have non-null value.');
    }
  }

  /**
   * Set SIM change failure.
   *
   * @param error The error.
   */
  private setFailure = (error: any) => {
    this.generalFailure = true;
    this.blockUIService.stop(this.simChangeBlockUiElementName);
  };

  /**
   * Get name of UI block element.
   */
  get simChangeBlockUiElementName() {
    return 'simChange';
  }
}
