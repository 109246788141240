<ng-container *ngIf="scriptLoaded">
  <google-map
    height="100%"
    width="100%"
    class="map"
    [options]="{
      gestureHandling: 'greedy',
      zoom: zoom,
      center: {
        lat: lat,
        lng: lng
      }
    }">
    <map-marker
      *ngFor="let point of points"
      [position]="{
        lat: point.adrLocation.lat,
        lng: point.adrLocation.lon
      }"
      [options]="{
        icon: getPointIcon(point)
      }"
      (mapClick)="pointClicked($event)"
      (mapMouseover)="pointHoverOn(point)"
      (mapMouseout)="pointHoverEmitter.emit(null)">
    </map-marker>
    <map-marker
      *ngIf="currentLocation"
      [position]="currentLocation"
      [options]="{
        icon: '/assets/img/location.png'
      }">
    </map-marker>
  </google-map>
</ng-container>
