import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormPickUpPointInputComponent, LocationDto, PointDto } from '../form-pick-up-point-input.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { ExternalScriptsService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
})
export class GoogleMapComponent implements OnChanges {
  @Input()
  points: Array<PointDto>;

  @Input()
  currentLocation: LocationDto;

  @Input()
  lng: number;

  @Input()
  lat: number;

  @Input()
  zoom: number;

  @Input()
  hoveredPoint: PointDto;

  @Input()
  selectedPoint: PointDto;

  @Output()
  readonly pointHoverEmitter = new EventEmitter<PointDto>();

  @Output()
  readonly pointSelectedEmitter = new EventEmitter<PointDto>();

  @Output()
  readonly distancesCalculatedEmitter = new EventEmitter<boolean>();

  scriptLoaded = false;

  constructor(
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private externalScriptsService: ExternalScriptsService
  ) {
    this.propertyAccessorLocalService.getGoogleMapApiKey().subscribe(result => {
      if (result) {
        this.externalScriptsService
          .loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${result}&libraries=visualization&libraries=geometry`
          )
          .then(() => {
            this.scriptLoaded = true;
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentLocation']) {
      this.points.forEach(point => {
        point['distance'] = this.getDistanceToCurrentLocation(point);
      });
      if (this.currentLocation) {
        this.distancesCalculatedEmitter.emit(true);
      }
    }
  }

  pointHoverOn(point) {
    if (!FormPickUpPointInputComponent.isSamePoint(this.selectedPoint, point)) {
      this.pointHoverEmitter.emit(point);
    }
  }

  pointClicked($event: google.maps.MapMouseEvent) {
    this.pointSelectedEmitter.emit(
      this.points.find(
        point => point.adrLocation.lon === $event.latLng.lng() && point.adrLocation.lat === $event.latLng.lat()
      )
    );
  }

  getDistanceToCurrentLocation(point) {
    if (this.currentLocation) {
      const pointLocation = new google.maps.LatLng(point.adrLocation.lat, point.adrLocation.lon);
      const currentLocation = new google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng);
      return google.maps.geometry.spherical.computeDistanceBetween(currentLocation, pointLocation) / 1000;
    }
    return null;
  }

  isSelectedPoint(point) {
    return FormPickUpPointInputComponent.isSamePoint(this.selectedPoint, point);
  }

  isHoverPoint(point) {
    return FormPickUpPointInputComponent.isSamePoint(this.hoveredPoint, point);
  }

  getPointIcon(point) {
    return FormPickUpPointInputComponent.getPointIcon(this.selectedPoint, this.hoveredPoint, point);
  }
}
