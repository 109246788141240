<div class="checkout-page">
  <!-- Generate content for mobile -->
  <div class="d-lg-none">
    <app-checkout-shopping-cart-secondary-actions></app-checkout-shopping-cart-secondary-actions>
    <app-ordering-wizard></app-ordering-wizard>
  </div>
  <!-- END Generate content for mobile -->
  <div
    [ngClass]="
      wcOrderingService.currentOrder?.scenarioType === scenarioTypeEnum.PRP_REGISTRATION
        ? 'checkout-page_registration'
        : 'checkout-page_form'
    ">
    <app-checkout-page
      *ngIf="!shoppingCartService.preCalculatedShoppingCart.containsService()"
      #checkout
      [childMode]="true"
      (formGeneratedEmitter)="checkOutFormGenerated()">
    </app-checkout-page>
    <ng-container
      *ngIf="
        shoppingCartService.preCalculatedShoppingCart.containsService() ||
        (checkout &&
          ((checkout.extUserAuthMode === 'AUTH_REQUIRED' && checkout.account) ||
            checkout.extUserAuthMode === 'AUTH_ANONYMOUS' ||
            checkout.extUserAuthMode === 'AUTH_AUTO_REGISTRATION'))
      ">
      <form class="form" [formGroup]="formDelivery">
        <!-- Delivery methods -->
        <div
          *ngIf="displayDelivery && deliveryMethods"
          class="delivery-container"
          app-delivery-method
          [class.halfColumn]="deliveryMethods?.length === 1"
          [deliveryMethods]="deliveryMethods"
          [changeDeliveryProduct]="changeDeliveryProduct.bind(this)"
          [form]="formDelivery"></div>
        <!-- Onetime Payment methods -->
        <div
          *ngIf="onetimePaymentMethods && onetimePaymentMethods.length"
          class="payment-container"
          app-payment-method
          [class.halfColumn]="onetimePaymentMethods?.length === 1"
          [onetimePaymentMethods]="onetimePaymentMethods"
          [selectedPaymentProduct]="selectedPaymentProduct"
          [changePaymentProduct]="changePaymentProduct.bind(this)"
          [form]="formDelivery"></div>
        <div *ngIf="hwScenario() && hwInvoicingContactVisible">
          <!-- Existing invoicing contact -->
          <div *ngIf="hwInvoicingContactDtos && hwInvoicingContactDtos.length > 0; else nonExistingInvoicingContact">
            <app-section-header
              id="collapseHeader"
              [title]="'wc.shopping.customerCreation.invoicingContact' | translate"
              [isCollapsed]="refEl.activeIds.length > 0"
              (click)="acc.toggle('toggleCategories')">
            </app-section-header>
            <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleCategories" [destroyOnHide]="false">
              <ngb-panel id="toggleCategories">
                <ng-template ngbPanelContent>
                  <div [formGroup]="selectedHwInvoicingContactForm">
                    <ng-select
                      class="contact-details-dropdown"
                      formControlName="hwInvoicingContact"
                      [class.single]="hwInvoicingContactDtos.length === 1"
                      [clearable]="false"
                      [clearOnBackspace]="false"
                      [searchable]="false"
                      [markFirst]="true"
                      [(ngModel)]="selectedInvoiceContact"
                      (change)="changedHwInvoicingContact($event)">
                      <ng-option *ngFor="let contact of hwInvoicingContactDtos" [value]="contact.id">
                        <div class="contact-details-dropdown_leftSide">
                          <div class="icon-container fullHeight">
                            <img [src]="'/assets/svg/invoice-details.svg'" />
                          </div>
                          <div class="contact-details-dropdown_wrapper">
                            <div class="fullName">{{ contact.firstName }} {{ contact.lastName }}</div>
                            <div>
                              {{ contact.email }} <span *ngIf="contact.phone1">• {{ contact.phone1 }}</span>
                            </div>
                            <div>
                              {{ contact.address | displayAddress }}
                            </div>
                          </div>
                        </div>
                        <div class="contact-details-dropdown_arrowSide">
                          Use different <i [inlineSVG]="'/assets/svg/section-arrow.svg'"></i>
                        </div>
                      </ng-option>
                    </ng-select>
                  </div>
                  <div [hidden]="true">
                    <app-contact-form
                      #hwInvoicingContactForm
                      [type]="contactTypeEnum.HWINVOICING"
                      [sectionView]="false"
                      [main]="true"
                      [account]="account"
                      [form]="getHwInvoicingContactFormGroup()"
                      [parentGroup]="formDelivery"
                      (formGeneratedEmitter)="hwInvoicingFormsGenerated()">
                    </app-contact-form>
                    <app-address-form
                      #hwInvoicingAddressForm
                      [type]="addressTypeEnum.HWINVOICING"
                      [main]="true"
                      [sectionView]="false"
                      [entityGenerateForm]="true"
                      [parentGroup]="formDelivery"
                      [form]="getHwInvoicingAddressFormGroup()"
                      (formGeneratedEmitter)="hwInvoicingFormsGenerated()">
                    </app-address-form>
                  </div>

                  <!-- Add new address -->
                  <div class="new-contact_buttons-container">
                    <app-button
                      variant="primary"
                      iconPath="/assets/svg/new-address.svg"
                      label="{{ 'wc.ecare.accountSettings.contacts.newContact.HW_INVOICING' | translate }}"
                      (click)="showNewInvoiceModal()">
                    </app-button>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>

        <!-- Invoicing contact doesn't exist yet -->
        <ng-template #nonExistingInvoicingContact>
          <app-contact-form
            #hwInvoicingContactForm
            [type]="contactTypeEnum.HWINVOICING"
            [sectionView]="false"
            [main]="!getPrimaryContactFormGroup()"
            [account]="account"
            [form]="getHwInvoicingContactFormGroup()"
            [parentGroup]="formDelivery"
            (formGeneratedEmitter)="hwInvoicingFormsGenerated()">
          </app-contact-form>
          <app-address-form
            #hwInvoicingAddressForm
            [type]="addressTypeEnum.HWINVOICING"
            [main]="!shoppingCartService.preCalculatedShoppingCart.containsService()"
            [sectionView]="false"
            [parentGroup]="formDelivery"
            [form]="getHwInvoicingAddressFormGroup()"
            (formGeneratedEmitter)="hwInvoicingFormsGenerated()">
          </app-address-form>
        </ng-template>
        <div *ngIf="this.selectedDeliveryProduct && !isDeliveryFree()" app-acl-internal-evaluate-component>
          <div class="checkbox">
            <div class="form-radio">
              <input
                formControlName="forgiveDeliveryFee"
                id="forgiveDeliveryFee"
                type="checkbox"
                (change)="forgiveDeliveryFeeChanged($event)" />
              <label for="forgiveDeliveryFee">{{
                'wc.shopping.customerCreation.deliveryMethod.forgiveFee' | translate
                }}</label>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedDeliveryProduct"
          [hidden]="
            !selectedDeliveryProduct?.parametersStatic['timeSlotProposalCount'] ||
            selectedDeliveryProduct?.parametersStatic['timeSlotProposalCount'] === '0'
          ">
          <app-time-slot-chips
            formControlName="timeSlot"
            [numberOfSlots]="selectedDeliveryProduct?.parametersStatic['timeSlotProposalCount']"
            [control]="formDelivery.get('timeSlot')"
            [timeSlotProposal]="deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposal']">
          </app-time-slot-chips>
        </div>

        <!-- Delivery contact -->
        <div *ngIf="showDeliveryContact()" [hidden]="!displayDelivery">
          <!-- Existing delivery contact -->
          <div *ngIf="deliveryContactDtos && deliveryContactDtos.length > 0; else nonExistingDeliveryContact">
            <app-section-header
              id="deliveryCollapseHeader"
              [title]="'wc.shopping.customerCreation.deliveryContact' | translate"
              [isCollapsed]="refEl.activeIds.length > 0"
              (click)="acc.toggle('deliveryToggleCategories')">
            </app-section-header>
            <ngb-accordion #acc="ngbAccordion" #refEl activeIds="deliveryToggleCategories" [destroyOnHide]="false">
              <ngb-panel id="deliveryToggleCategories">
                <ng-template ngbPanelContent>
                  <div [formGroup]="selectedDeliveryContactForm">
                    <ng-select
                      class="contact-details-dropdown"
                      formControlName="deliveryContact"
                      [class.single]="deliveryContactDtos.length === 1"
                      [clearable]="false"
                      [clearOnBackspace]="false"
                      [searchable]="false"
                      [markFirst]="true"
                      [(ngModel)]="selectedDeliveryContact"
                      (change)="changedDeliveryContact($event)">
                      <ng-option *ngFor="let contact of deliveryContactDtos" [value]="contact.id">
                        <div class="contact-details-dropdown_leftSide">
                          <div class="icon-container fullHeight">
                            <img [src]="'/assets/svg/delivery-details.svg'" />
                          </div>
                          <div class="contact-details-dropdown_wrapper">
                            <div class="fullName">{{ contact.firstName }} {{ contact.lastName }}</div>
                            <div>
                              {{ contact.email }} <span *ngIf="contact.phone1">• {{ contact.phone1 }}</span>
                            </div>
                            <div>
                              {{ contact.address | displayAddress : addressTypes.DELIVERY }}
                            </div>
                          </div>
                        </div>
                        <div class="contact-details-dropdown_arrowSide">
                          Use different <i [inlineSVG]="'/assets/svg/section-arrow.svg'"></i>
                        </div>
                      </ng-option>
                    </ng-select>
                  </div>
                  <div [hidden]="true">
                    <app-contact-form
                      #contactForm
                      [type]="contactTypeEnum.DELIVERY"
                      [sectionView]="false"
                      [main]="true"
                      [account]="account"
                      [form]="getContactFormGroup()"
                      [parentGroup]="formDelivery"
                      (formGeneratedEmitter)="deliveryFormsGenerated()">
                    </app-contact-form>
                    <app-address-form
                      #addressForm
                      [type]="addressTypeEnum.DELIVERY"
                      [main]="true"
                      [parentGroup]="formDelivery"
                      [form]="getAddressFormGroup()">
                    </app-address-form>
                  </div>
                  <!-- Add new & add delivery note -->
                  <div>
                    <ng-container *ngTemplateOutlet="deliveryNote"></ng-container>
                    <div class="new-contact_buttons-container">
                      <app-button
                        variant="primary"
                        iconPath="/assets/svg/new-address.svg"
                        label="{{ 'wc.ecare.accountSettings.contacts.newContact.DELIVERY' | translate }}"
                        (click)="showNewDeliveryModal()">
                      </app-button>
                      <app-button
                        *ngIf="!visibleDeliveryNote"
                        variant="link"
                        iconPath="/assets/img/ecare/feather-plus.svg"
                        label="{{ 'wc.shopping.customerCreation.deliveryMethod.deliveryNote' | translate }}"
                        (click)="showDeliveryNote()">
                      </app-button>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>

          <!-- Delivery contact doesn't exist yet -->
          <ng-template #nonExistingDeliveryContact>
            <app-contact-form
              #contactForm
              [type]="contactTypeEnum.DELIVERY"
              [sectionView]="false"
              [main]="!getPrimaryContactFormGroup() && !hwInvoicingContactVisible"
              [sameAsTranslationKey]="
                hwInvoicingContactVisible ? 'wc.shopping.checkout.sameAsHwInvoicingContact' : null
              "
              [sameAsForm]="hwInvoicingContactVisible ? getHwInvoicingContactFormGroup() : getPrimaryContactFormGroup()"
              [account]="account"
              [form]="getContactFormGroup()"
              [parentGroup]="formDelivery"
              (formGeneratedEmitter)="deliveryFormsGenerated()">
              <div class="deliveryNote-container">
                <ng-container *ngTemplateOutlet="deliveryNote"></ng-container>
                <app-button
                  *ngIf="!visibleDeliveryNote"
                  variant="link"
                  iconPath="/assets/img/ecare/feather-plus.svg"
                  label="{{ 'wc.shopping.customerCreation.deliveryMethod.deliveryNote' | translate }}"
                  (click)="showDeliveryNote()">
                </app-button>
              </div>
            </app-contact-form>
            <app-address-form
              #addressForm
              [type]="addressTypeEnum.DELIVERY"
              [main]="!getRegistrationAddressFormGroup() && !hwInvoicingContactVisible"
              [sameAsTranslationKey]="
                hwInvoicingContactVisible ? 'wc.shopping.checkout.sameAsHwInvoicingAddress' : null
              "
              [sameAsForm]="
                hwInvoicingContactVisible ? getHwInvoicingAddressFormGroup() : getRegistrationAddressFormGroup()
              "
              [parentGroup]="formDelivery"
              [form]="getAddressFormGroup()"
              (formGeneratedEmitter)="deliveryFormsGenerated()">
            </app-address-form>
          </ng-template>

          <ng-template #deliveryNote>
            <div *ngIf="visibleDeliveryNote">
              <label for="deliveryNote">{{
                'wc.shopping.customerCreation.deliveryMethod.deliveryNote' | translate
                }}</label>
              <textarea #deliveryNoteField type="text" formControlName="deliveryNote" id="deliveryNote"></textarea>
            </div>
          </ng-template>
        </div>

        <div [hidden]="!(isDeliveryToPickupPoint() || isDeliveryToShop() || isReservationAtStore())">
          <h2 id="002_delivery">
            {{ selectedDeliveryProduct?.name }}
          </h2>
          <div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label" for="searchMode">{{ selectedDeliveryProduct?.name }}</label>
              <div class="col-sm-10">
                <app-form-pick-up-point-input-with-modal
                  *ngIf="isDeliveryToPickupPoint() || isDeliveryToShop()"
                  controlName="pickUpPoint"
                  [provider]="selectedDeliveryProduct?.parametersStatic['provider']"
                  [parentGroup]="formDelivery">
                </app-form-pick-up-point-input-with-modal>
                <app-form-shop-selection-input-with-modal
                  *ngIf="isReservationAtStore()"
                  controlName="preferredShop"
                  [checkAvailability]="true"
                  [parentGroup]="formDelivery">
                </app-form-shop-selection-input-with-modal>
              </div>
              <div #autocomplete class="col-sm-2"></div>
              <div #autocomplete class="col-sm-10">
                <div
                  *ngFor="let pickUpPoints of autocompleteProducts | async; let i = index"
                  class="autocomplete-items">
                  <a href="javascript:void(0)" class="" id="{{ i }}_005_delivery" (click)="selectPoint(pickUpPoints)">{{
                    pickUpPoints._source.name1
                    }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="!isInstallationTerm()">
          <h2 id="007_delivery">
            {{ 'wc.shopping.customerCreation.installationTerms.heading' | translate }}
          </h2>
          <app-location-form
            [locationId]="1"
            [parentGroup]="formDelivery"
            [readOnly]="true"
            [setLocation]="firstLocation">
          </app-location-form>
          <app-location-form
            *ngIf="displaySecondForm"
            [locationId]="2"
            [readOnly]="true"
            [setLocation]="secondLocation"
            [parentGroup]="formDelivery">
          </app-location-form>
          <div class="checkbox">
            <div class="form-radio">
              <input
                type="checkbox"
                formControlName="differentContact"
                id="differentContact"
                [value]="true"
                (click)="differentContact($event)" />
              <label for="differentContact">{{
                'wc.shopping.customerCreation.deliveryMethod.differentContact' | translate
                }}</label>
            </div>
          </div>
          <div>
            <app-delivery-contact-form
              [parentGroup]="formDelivery"
              [readOnly]="true"
              [contactId]="1"
              [contactType]="'TP_INSTALLATION'">
            </app-delivery-contact-form>
            <app-delivery-contact-form
              *ngIf="displaySecondForm"
              [parentGroup]="formDelivery"
              [readOnly]="true"
              [contactId]="2"
              [contactType]="'TP_INSTALLATION'">
            </app-delivery-contact-form>
          </div>
          <div
            *ngIf="deliveryInstallProduct"
            [hidden]="
              !deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount'] ||
              deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount'] === '0'
            ">
            <app-time-slot-chips
              formControlName="timeSlot1"
              [parentGroup]="formDelivery"
              [locationId]="1"
              [numberOfSlots]="deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount']"
              [control]="formDelivery.get('timeSlot1')"
              [timeSlotProposal]="deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposal']">
            </app-time-slot-chips>
          </div>
          <div
            *ngIf="deliveryInstallProduct && displaySecondForm"
            [hidden]="
              !deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount'] ||
              deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount'] === '0'
            ">
            <app-time-slot-chips
              formControlName="timeSlot2"
              [parentGroup]="formDelivery"
              [locationId]="2"
              [numberOfSlots]="deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposalCount']"
              [control]="formDelivery.get('timeSlot1')"
              [timeSlotProposal]="deliveryInstallProduct?.productDetail.parametersStatic['timeSlotProposal']">
            </app-time-slot-chips>
          </div>
        </div>
        <div class="section"></div>
      </form>
    </ng-container>
  </div>

  <!-- Right side of checkout - shopping cart -->
  <app-checkout-shopping-cart
    [checkFormAction]="confirmDeliveryData.bind(this)"
    [disableContinueButton]="checkout && checkout.extUserAuthMode === 'AUTH_REQUIRED' && !checkout.account">
  </app-checkout-shopping-cart>
</div>
