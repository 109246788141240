<div class="section">
  <div class="inner" [formGroup]="customerForm">
    <input id="001_customer_form" formControlName="id" type="hidden" />
    <input id="002_customer_form" formControlName="extId" type="hidden" />
    <h4 *ngIf="showHeader" id="003_customer_form">{{ 'wc.shopping.customer.heading' | translate }}</h4>

    <div class="section">
      <app-entity-generated-form
        *ngIf="entityGenerateForm"
        #customerEntityGeneratedForm
        formIdPrefix="customer"
        sourceName="crm"
        entityType="Customer"
        [hide]="notHavingService()"
        [form]="customerForm"
        [formGroup]="customerForm"
        [formName]="formName"
        [resourceIdentification]="{ segment: getCurrentSegment() }"
        [defaultValues]="{
          segment: patchCustomer?.segment
        }"
        [context]="{}"
        [validate]="validate"
        [checkFormVisibility]="checkFormVisibility"
        [columnsCount]="columnsCount"
        (formGeneratedEmitter)="customerFormGenerated()" />
    </div>

    <div
      *ngIf="entityGenerateForm && globalHomeCountry && getCurrentSubjectType()"
      class="section"
      [hidden]="
        !customerFormFieldAvailable() ||
        (!subjectEntityGeneratedForm?.formVisibility && !personalIdEntityGeneratedForm?.formVisibility)
      ">
      <app-section-header
        id="collapseHeader"
        [title]="'wc.shopping.customer.subjectType.' + getCurrentSubjectType() + '.label' | translate"
        [isCollapsed]="refEl.activeIds.length > 0"
        (click)="acc.toggle('toggleSubjectForm')" />
      <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleSubjectForm" [destroyOnHide]="false">
        <ngb-panel id="toggleSubjectForm">
          <ng-template ngbPanelContent>
            <app-entity-generated-form
              #subjectEntityGeneratedForm
              formIdPrefix="customer"
              sourceName="crm"
              entityType="Subject"
              [formName]="formName"
              [hide]="notHavingService()"
              [form]="getSubjectFormGroup()"
              [formGroup]="getSubjectFormGroup()"
              [resourceIdentification]="{ subjectType: getCurrentSubjectType() }"
              [externalValues]="{
                subjectType: getSubjectType()
              }"
              [defaultValues]="{
                nationality: getDefaultNationality()
              }"
              [attributeOnChangeEvent]="{
                nationality: nationalityChangedEventEmitter,
                subjectType: subjectTypeChangedEventEmitter
              }"
              [context]="{}"
              [validate]="validate"
              [checkFormVisibility]="checkFormVisibility"
              [columnsCount]="columnsCount"
              (formGeneratedEmitter)="customerFormGenerated()" />

            <app-entity-generated-form
              *ngIf="subjectEntityGeneratedForm?.formGenerated"
              #personalIdEntityGeneratedForm
              formIdPrefix="customer"
              sourceName="crm"
              entityType="PersonalId"
              [formName]="formName"
              [hide]="notHavingService()"
              [form]="getPersonalIdtFormGroup()"
              [formGroup]="getPersonalIdtFormGroup()"
              [resourceIdentification]="{ subjectType: getCurrentSubjectType() }"
              [defaultValues]="{
                idIssueAuthority: getDefaultNationality()
              }"
              [context]="{}"
              [validate]="validate"
              [checkFormVisibility]="checkFormVisibility"
              [columnsCount]="columnsCount"
              (formGeneratedEmitter)="customerFormGenerated()" />
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <app-address-form
      *ngIf="entityGenerateForm && showAddress"
      #addressForm
      formIdPrefix="customer"
      [formName]="formName"
      [hide]="notHavingService()"
      [type]="AddressTypeEnum.REGISTRATION.toString()"
      [main]="true"
      [entityGenerateForm]="true"
      [parentGroup]="customerForm"
      [form]="getAddressFormGroup()"
      [checkFormVisibility]="checkFormVisibility"
      [simpleCustomer]="simpleCustomer"
      [columnsCount]="columnsCount"
      (formGeneratedEmitter)="customerFormGenerated()" />

    <app-contact-form
      *ngIf="entityGenerateForm && showContact"
      #contactForm
      formIdPrefix="customer"
      [formName]="formName"
      [customer]="customer"
      [type]="ContactTypeEnum.PRIMARY.toString()"
      [main]="true"
      [account]="account"
      [form]="getContactFormGroup()"
      [entityGenerateForm]="true"
      [checkFormVisibility]="checkFormVisibility"
      [parentGroup]="customerForm"
      [checkEmailUniqueness]="checkEmailUniqueness"
      [showCustomerData]="showCustomerData()"
      [columnsCount]="columnsCount"
      (formGeneratedEmitter)="customerFormGenerated()" />
  </div>
</div>
