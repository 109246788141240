import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { OrderDto } from '@btl/order-bff';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { ShoppingCartItemComponent } from '../shopping-cart-item/shopping-cart-item.component';
import _ from 'lodash';
import { Observable } from 'rxjs';

/**
 * Component for wrapping ShoppingCartItemComponents. It is passing inputs parameters to each ShoppingCartItemComponents and passing down
 * ShoppingCartItemComponent events. It is also summing RC, OC prices and taxes of containing items.
 */
@Component({
  selector: 'app-shopping-cart-items, [app-shopping-cart-items]',
  templateUrl: './shopping-cart-items.component.html',
})
export class ShoppingCartItemsComponent {
  //region IO:

  /**
   * An event emitter activated when some manipulation with order items happen.
   */
  @Output()
  readonly onChange: EventEmitter<OrderDto> = new EventEmitter<OrderDto>();

  @Input()
  notFromShoppingCart = false;

  /**
   * A flag specifying if quantity change and special offer part is shown or not.
   */
  @Input()
  showQuantityChange = true;

  @Input()
  showQuantity: boolean = true;

  /**
   * A flag specifying if remove all same product button is shown or not.
   */
  @Input()
  showRemoveBtn = true;

  /**
   * A flag specifying if configuration button is shown or not.
   */
  @Input()
  showConfigurationBtn = true;

  /**
   * Display mode.
   */
  @Input()
  displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';

  /**
   * A flag specifying if special offer can be shown or not.
   */
  @Input()
  showSpecialOffer = false;

  /**
   * A flag specifying if link to product listing when empty basket is shown or not.
   */
  @Input()
  showGoToProductListingLink = true;

  /**
   * A flag specifying if details (product attributes, etc.) can be shown or not.
   */
  @Input()
  showDetails = false;

  @Input()
  showAddons = false;

  @Input()
  showImg = true;

  @Input()
  readOnlyOrder: boolean = false;

  //region Children:

  @ViewChildren(ShoppingCartItemComponent)
  private shoppingCartItemComponents: QueryList<ShoppingCartItemComponent>;

  readOnlyPreCalculatedShoppingCart;

  get precalculatedShoppingCart(): PrecalculatedShoppingCart {
    if (this.readOnlyOrder) {
      return this.readOnlyPreCalculatedShoppingCart;
    } else {
      return this.shoppingCartService.preCalculatedShoppingCart;
    }
  }

  constructor(private shoppingCartService: ShoppingCartService) {}

  useOrder(orderDto: OrderDto): Observable<PrecalculatedShoppingCart> {
    return this.shoppingCartService.createNewPreCalculatedShoppingCart(orderDto).pipe(result => {
      this.readOnlyPreCalculatedShoppingCart = this.shoppingCartService.readOnlyOrder;
      return result;
    });
  }

  /**
   * Perform validate() on all child instances of ShoppingCartItemComponent.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    // If no details are displayed, there is nothing to validate.
    if (!this.showDetails) {
      return true;
    }

    let validationResult = true;
    this.shoppingCartItemComponents.forEach((itemComponent: ShoppingCartItemComponent) => {
      if (!itemComponent.validate()) {
        validationResult = false;
      }
    });

    return validationResult;
  }

  /**
   * Perform save() on all child instances of ShoppingCartItemComponent.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    this.shoppingCartItemComponents.forEach((itemComponent: ShoppingCartItemComponent) => {
      itemComponent.save(order);
    });
  }

  hasOrderItems(): boolean {
    return !_.isNil(this.precalculatedShoppingCart) && !_.isEmpty(this.precalculatedShoppingCart.products);
  }
}
