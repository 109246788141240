import { Component, Input, OnInit } from '@angular/core';
import {CustomerLocalStorageService} from "@service/customer-local-storage.service";

@Component({
  selector: 'app-ecare-listing-no-results',
  templateUrl: './ecare-listing-no-results.component.html',
})
export class EcareListingNoResultsComponent implements OnInit {
  private static readonly DEFAULT_HEADING_TEXT = 'wc.ecare.search.noResults.heading';
  private static readonly DEFAULT_LABEL_TEXT = 'wc.ecare.search.noResults';

  @Input()
  headingTextKey?: string;

  @Input()
  isHomepage: boolean = false;

  @Input()
  labelTextKey?: string;

  constructor(private customerLocalStorageService: CustomerLocalStorageService) {
  }

  ngOnInit(): void {
    if (!this.headingTextKey) {
      this.headingTextKey = EcareListingNoResultsComponent.DEFAULT_HEADING_TEXT;
    }
    if (!this.labelTextKey) {
      this.labelTextKey = EcareListingNoResultsComponent.DEFAULT_LABEL_TEXT;
    }
  }

  clearSearchField() {
    this.customerLocalStorageService.clearSearchField.emit(true);
  }
}
