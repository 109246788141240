import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '@service/customer.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';
import { PartyElse } from '../../../models/party-holder';
import { FormUtils } from '../../../helpers/form-utils';
import { ContactDto, NotificationRecipientDto } from '@btl/order-bff';

@Component({
  selector: 'app-add-recipient-modal',
  templateUrl: './add-recipient-modal.component.html',
})
export class AddRecipientModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  type: string;

  @Input()
  recipients: any;

  @Input()
  addHandler = (recipientList: FormArray) => {};

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  customersSearchFormGroup: FormGroup;

  customerSearchValue: string;

  customers: PartyElse[] = null;

  addNewRecipient: boolean = false;

  form: FormGroup = this.formBuilder.group({
    recipientName: [null, Validators.required],
    address: [null, [Validators.required, Validators.email]],
  });

  recipientListForm: FormGroup = this.formBuilder.group({
    recipientList: this.formBuilder.array([]),
  });

  getFormArray() {
    return this.recipientListForm.get('recipientList') as FormArray;
  }

  constructor(private customerService: CustomerService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.customersSearchFormGroup = this.formBuilder.group({
      customerSearch: [],
    });
    if (this.recipients) {
      this.recipients.forEach(recipient => {
        this.loadRecipientToArray(recipient);
      });
    }
  }

  customerSearch(): void {
    this.customerSearchValue = this.customersSearchFormGroup.get('customerSearch').value;
    if (this.customerSearchValue && !(this.customerSearchValue === '')) {
      this.customerSearchByValue(this.customerSearchValue);
    } else {
      this.customerSearchValue = null;
      this.customers = [];
    }
  }

  customerSearchByValue(customerSearch: string) {
    this.blockUIElement.start();
    this.customerService.searchByText(customerSearch, 33).subscribe(customerHolders => {
      this.customers = customerHolders.map(customerHolder => customerHolder._source);
      this.recipients = this.customers.map(customer => this.convertCustomerToRecipient(customer));
      this.recipients.push(...this.getFormArray().value);
      this.blockUIElement.stop();
    });
  }

  addRecipient() {
    if (this.form.valid) {
      const recipient: NotificationRecipientDto = this.form.getRawValue();
      recipient.recipientType = this.type;
      recipient.sent = false;
      recipient.recipientState = 'READY';
      const newDataFormGroup = FormUtils.createFormGroupFromObject(recipient);
      newDataFormGroup.patchValue(recipient);
      this.getFormArray().push(newDataFormGroup);
    }
  }

  add() {
    this.addHandler(this.getFormArray());
  }

  remove(formToRemove: AbstractControl) {
    let index = 1;
    let foundIndex = null;
    this.getFormArray().controls.forEach(control => {
      if (control === formToRemove) {
        foundIndex = index;
      }
      index++;
    });

    if (foundIndex) {
      this.getFormArray().removeAt(foundIndex - 1);
    }
  }

  handleCheckbox(recipient: NotificationRecipientDto) {
    if (this.isSelected(recipient)) {
      this.remove(this.getFormArray().controls.find(control => control?.value?.address === recipient.address));
    } else {
      this.loadRecipientToArray(recipient);
    }
  }

  isSelected(recipient: NotificationRecipientDto) {
    return this.getFormArray().controls.find(control => control?.value?.address === recipient.address);
  }

  loadRecipientToArray(recipient: NotificationRecipientDto) {
    const formGroup = FormUtils.createFormGroupFromObject(recipient);
    formGroup.patchValue(recipient);
    this.getFormArray().push(formGroup);
  }

  convertCustomerToRecipient(customer: PartyElse) {
    return {
      partyRefNo: customer.partyRefNo,
      recipientType: this.type,
      recipientName: `${customer.firstName} ${customer.lastName}`,
      address: customer?.contacts?.find(contact => contact?.contactType === ContactDto.TypeDtoEnum.PRIMARY)?.email,
      sent: false,
      recipientState: 'READY',
    };
  }

  getAdditionalInfo(recipient: NotificationRecipientDto, attribute: string) {
    const customer: PartyElse = this.customers?.find(customer => customer.partyRefNo === recipient.partyRefNo);

    if (!customer) {
      return;
    }

    switch (attribute) {
      case 'segment': {
        return customer.segment;
      }
      case 'subjectType': {
        return customer.subjectType === 'Person' ? customer.birthDate : customer.companyRegNo;
      }
      case 'identityNumber': {
        return `${customer.identityNumber} ${customer.extId ? `(${customer.extId})` : ''}`;
      }
      default: {
        break;
      }
    }
  }
}
