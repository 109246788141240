import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerDto, OrderDto, ProductDetailDto } from '@btl/order-bff';
import { ActivatedRoute } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartItemsComponent } from '../../page/shopping-cart-items/shopping-cart-items.component';
import {
  BlockTemplateComponent,
  CurrentLocaleService,
  GeneratedDocumentService,
  OrderingService
} from '@btl/btl-fe-wc-common';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { CategoryTypeEnum } from '../../../models/product-filter';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { NtsPaymentDetails } from '../../../models/nts-payment-details';
import { OrderUtils } from '../../../helpers/order-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderingWizardService, PaymentStatusEnum } from '@service/ordering-wizard-service';
import { addressTypes } from 'app/pipes/display-address.pipe';
import { TimeSlotProposal } from 'app/components/page/time-slot/time-slot.component';
import { BlockUIService } from 'ng-block-ui';
import { PaymentService } from '@service/payment.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  @ViewChild('shoppingCartItems', { static: false }) shoppingCartItemsComponent: ShoppingCartItemsComponent;

  blockTemplate = BlockTemplateComponent;

  paymentStatus;
  blockingReason: string;
  paymentStatusCheckCount = 0;
  readonly paymentStatusCheckMax = 40;
  checkPaymentStatusManually = false;
  orderId: string;
  routeSubscibe;

  /**
   * A flag specifying if an invoice exists for the just finished order.
   */
  invoiceExistence: boolean = false;

  /**
   * Blob of the invoice for the just finished order.
   */
  private invoice: Blob;

  /**
   * The payment details. If no payment proceeded, this field is null.
   */
  paymentDetails: NtsPaymentDetails;

  paymentParams;
  paymentStatusEnum = PaymentStatusEnum;
  private onDestroy$: Subject<void> = new Subject<void>();

  //TP2-59
  deliveryMethodProduct: ProductDetailDto;
  paymentMethodProduct: ProductDetailDto;

  addressTypes = addressTypes;

  customer: CustomerDto;
  customerEmail: string;
  customerFirstName: string;

  deliveryAddress;

  timeSlots: Array<TimeSlotProposal> = [];

  showVat: boolean = true;
  orderDto;

  constructor(
    public wcOrderingService: WcOrderingService,
    private orderingService: OrderingService,
    private orderingWizardService: OrderingWizardService,
    private generatedDocumentService: GeneratedDocumentService,
    private currentLocaleService: CurrentLocaleService,
    private route: ActivatedRoute,
    private blockUIService: BlockUIService,
    private paymentService: PaymentService,
  ) {
    this.orderingWizardService.showVat.subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.routeSubscibe = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.orderId = params["orderId"];
      this.paymentParams = params;

      const handleGetCurrentOrder = (order: OrderDto) => {
        if (order) {
          this.orderDto = order;
          this.shoppingCartItemsComponent
            .useOrder(order)
            .subscribe((precalculatedShoppingCart: PrecalculatedShoppingCart) => {
              this.paymentMethodProduct = precalculatedShoppingCart.getProductDetailByCategory(
                CategoryTypeEnum.PRODC_GE_PAYMENT
              );
              this.deliveryMethodProduct = precalculatedShoppingCart.getProductDetailByCategory(
                CategoryTypeEnum.PRODC_GE_DELIVERY
              );
              this.checkInvoiceExistence(precalculatedShoppingCart);
              // this.findTimeSlot(precalculatedShoppingCart);
            });
          //TP2-59
          this.deliveryAddress = JSON.parse(OrderUtils.getOrderAttributeValue(order, "deliveryAddress"));
          this.paymentStatus = OrderUtils.getOrderAttributeValue(order, OrderingWizardService.PAYMENT_STATUS_ATTR);
          this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(order, "customer"));
          this.findTimeSlots(order);
          if (this.customer) {
            this.customerEmail = this.customer.contact.email;
            this.customerFirstName = this.customer.contact.firstName;
          }
          if (this.paymentStatus !== PaymentStatusEnum.NOT_APPLICABLE) {
            this.blockUIService.start(this.paymentBlockUiElementName);
            this.checkStatus(order);
          }
        } else {
          throw new Error('There is no finished order!');
        }
      };
      if (this.orderId) {
        this.wcOrderingService
          .getOrderByRefNo(this.orderId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(handleGetCurrentOrder);
      }
    });
  }

  checkStatus(orderDto: OrderDto) {
    this.orderDto = orderDto;
    const paymentStatus = OrderUtils.getOrderAttributeValue(orderDto, OrderingWizardService.PAYMENT_STATUS_ATTR);
    this.blockingReason = OrderUtils.getOrderAttributeValue(orderDto, OrderingWizardService.BLOCKING_REASON_ATTR);

    if (
      paymentStatus === PaymentStatusEnum.COMPLETED ||
      paymentStatus === PaymentStatusEnum.APPROVED ||
      paymentStatus === PaymentStatusEnum.CANCELED ||
      paymentStatus === PaymentStatusEnum.TIMEOUTED ||
      paymentStatus === PaymentStatusEnum.FAILED
    ) {
      this.paymentStatus = paymentStatus;
      this.paymentStatusCheckCount = 0;
      this.blockUIService.stop(this.paymentBlockUiElementName);
    } else {
      if (this.paymentStatusCheckCount < this.paymentStatusCheckMax) {
        setTimeout(() => {
          this.wcOrderingService.doNotUnBlockOneCall = true;
          this.wcOrderingService
            .getOrderByRefNo(this.orderId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(orderDto => {
              this.paymentStatusCheckCount += 1;
              this.checkStatus(orderDto);
            });
        }, 3000);
      } else {
        this.checkPaymentStatusManually = true;
        this.blockUIService.stop(this.paymentBlockUiElementName);
      }
    }
  }

  checkStatusManually() {
    this.wcOrderingService
      .getOrderByRefNo(this.orderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(orderDto => {
        this.checkStatus(orderDto);
      });
  }

  //region Event handling:

  /**
   * Get an invoice for the just finished order.
   */
  getInvoice(): void {
    if (!this.invoice) {
      const locale = this.currentLocaleService.getCurrentLanguage();
      const orderId = this.orderId;

      const openInvoice = (invoice: Blob) => {
        this.invoice = invoice;
        window.open(URL.createObjectURL(this.invoice));
      };
      this.generatedDocumentService.generateOrderDocument('DOC_INVOICE_1', locale, orderId).subscribe(openInvoice);
    } else {
      window.open(URL.createObjectURL(this.invoice));
    }
  }

  //region Helpers:

  /**
   * Evaluate if an invoice exists for the just finished order.
   */
  checkInvoiceExistence(precalculatedShoppingCart: PrecalculatedShoppingCart): void {
    precalculatedShoppingCart.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (
          CategoryTypeEnum[productInShoppingCart.productDetail.categoryId] === CategoryTypeEnum.PRODC_SU_HW ||
          CategoryTypeEnum[productInShoppingCart.productDetail.categoryId] === CategoryTypeEnum.PRODC_FREE_HW
        ) {
          for (const orderItem of productInShoppingCart.orderItems) {
            if (orderItem.action === 'ADD') {
              this.invoiceExistence = true;
              return;
            }
          }
        }
      }
    });
  }

  findTimeSlots(orderDto: OrderDto) {
    if (this.deliveryMethodProduct?.productCode === 'DELIVERY_BY_COURIER') {
      orderDto.orderAttributes?.forEach(attribute => {
        if (attribute.attr === 'timeSlot') {
          this.timeSlots = JSON.parse(attribute.value);
          this.timeSlots?.forEach(slot => {
            slot.timeFrom = new Date(slot.timeFrom);
            slot.timeTo = new Date(slot.timeTo);
          });
        }
      });
    }
  }

  printPage() {
    print();
  }

  get paymentBlockUiElementName() {
    return 'paymentBlock';
  }

  payOrder() {
    this.paymentService
      .reprocessOrderPayment(this.orderDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.ngOnInit();
      });
  }
}
