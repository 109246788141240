import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractFilterComponent } from '@btl/btl-fe-wc-common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-duplicate-ticket-modal',
  templateUrl: './duplicate-ticket-modal.component.html',
})
export class DuplicateTicketModalComponent extends AbstractFilterComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  selectHandler;

  @Output()
  readonly duplicateTicketEvent: EventEmitter<string[]> = new EventEmitter<string[]>();

  items = ['coreAttributes', 'dynamicParameters', 'references'];
  preselectedItems = ['coreAttributes', 'dynamicParameters', 'references'];

  form: FormGroup = this.formBuilder.group({
    forDuplication: new FormArray([]),
  });

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.items.forEach(item => this.formArray.push(new FormControl(this.preselectedItems.indexOf(item) > -1)));
  }

  get formArray() {
    return this.form.get('forDuplication') as FormArray;
  }

  continue() {
    if (this.form.valid) {
      const selectedItems = [];

      for (let i = 0; i < this.form.getRawValue().forDuplication.length; i++) {
        if (this.form.getRawValue().forDuplication[i]) {
          selectedItems.push(this.items[i]);
        }
      }
      this.selectHandler(selectedItems);
      this.duplicateTicketEvent.emit(selectedItems);
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
