import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractPageComponent,
  AccountEntityRoleService,
  AccountService,
  AclService,
  AppBlockerService,
  AuthFactoryService,
  AuthorizationService,
  AuthService,
  BlockTemplateComponent,
  EnableDynamicLoading,
  FormUtils,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { AccountDto, AccountEntityRoleDto, DynamicEnumService, EnumEntryDto, RoleDto } from '@btl/order-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFactoryService } from '@service/modal-factory.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';

@Component({
  selector: 'app-account-entity-details',
  templateUrl: './account-entity-details.component.html',
})
@EnableDynamicLoading({ customName: AccountEntityDetailsComponent.PAGE_ID })
export class AccountEntityDetailsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'AccountEntityDetailsComponent';

  pageId(): string {
    return AccountEntityDetailsComponent.PAGE_ID;
  }

  @ViewChild('accountSearchInput') accountSearchInput: ElementRef;
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  public static readonly SOURCE_NAME = 'account';
  public static readonly EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.account.enums.ExternalEntityType';
  readonly ACCOUNT_ID_PARAM = 'account.accountId';
  readonly ENTITY_TYPE_PARAM = 'entityType';
  readonly ENTITY_ID_PARAM = 'entityId';
  readonly ROLE_TYPE_PARAM = 'roleType';
  readonly GRANT_MODE_PARAM = 'grantMode';
  readonly DELEGATED_ACCOUNT_ENTITY_ROLE_ID_PARAM = 'delegatedAccountEntityRoleId';

  newAccountEntity: boolean = false;
  editMode: boolean = false;
  isInternal: boolean = false;
  grantMode: boolean = true;
  pickerInputType = PickerInputType;

  private accountEntityRoleId: string;

  public accountEntityRoleDto: AccountEntityRoleDto = {
    startDateTime: new Date(),
  };

  aclRoles: RoleDto[] = [];
  rolesToSelect: RoleDto[] = [];
  entityTypes: EnumEntryDto[] = [];
  delegatedFrom: AccountEntityRoleDto;
  account: AccountDto;
  authService: AuthService;

  form: FormGroup = this.formBuilder.group({
    id: [null],
    recordVersion: [null],
    account: this.formBuilder.group({
      accountId: [null, Validators.required],
    }),
    entityType: [null, Validators.required],
    entityId: [null, Validators.required],
    roleType: [null, Validators.required],
    startDateTime: [null, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
    endDateTime: [null, FormUtils.validateOrderOfStartEnd()],
    canDelegate: [false, Validators.required],
    delegatedByAccount: this.formBuilder.group({
      accountId: [null],
      login: [null],
    }),
    delegatedFromId: [],
  });

  constructor(
    private accountEntityRoleService: AccountEntityRoleService,
    protected router: Router,
    protected route: ActivatedRoute,
    private dynamicEnumService: DynamicEnumService,
    private aclService: AclService,
    private location: Location,
    private modalFactory: ModalFactoryService,
    private stickyMessageService: StickyMessageService,
    private authFactoryService: AuthFactoryService,
    private appBlockerService: AppBlockerService,
    private accountService: AccountService,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private ngModal: NgbModal
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.loadServices();
    this.route.queryParams.subscribe(queryParams => {
      const filterAccountId = queryParams[this.ACCOUNT_ID_PARAM];
      const filterRoleType = queryParams[this.ROLE_TYPE_PARAM];
      const filterEntityType = queryParams[this.ENTITY_TYPE_PARAM];
      const filterEntityId = queryParams[this.ENTITY_ID_PARAM];
      const delegatedAccountEntityRoleType = queryParams[this.DELEGATED_ACCOUNT_ENTITY_ROLE_ID_PARAM];
      const grantMode = queryParams[this.GRANT_MODE_PARAM];

      if (!this.accountEntityRoleDto.id) {
        if (filterAccountId) {
          this.form.controls.account.patchValue({ accountId: filterAccountId });
        }
        if (filterEntityType) {
          this.form.controls.entityType.patchValue(filterEntityType);
        }
        if (filterEntityId) {
          this.form.controls.entityId.patchValue(filterEntityId);
        }
        if (filterRoleType) {
          this.form.controls.roleType.patchValue(filterRoleType);
        }
      }
      if (grantMode) {
        this.grantMode = grantMode === 'true';
      }
      if (!this.grantMode && delegatedAccountEntityRoleType) {
        this.form.controls.delegatedFromId.patchValue(delegatedAccountEntityRoleType);
        this.accountEntityRoleDto.delegatedFromId = delegatedAccountEntityRoleType;
        this.getDelegatedFrom();
      }
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const accountEntityId = this.params.id;
      const isAccountEntity = !accountEntityId || accountEntityId === 'newAccountEntity';

      this.authService = this.authFactoryService.getAuthService();
      this.isInternal = this.authorizationService.isInternal;

      if (isAccountEntity) {
        this.newAccountEntity = true;
        this.editMode = true;
        this.setAccountEntityRole(this.accountEntityRoleDto);
      } else {
        this.form.disable();
        this.loadAccountEntityRole(accountEntityId);
      }
    } else {
      this.accountEntityRoleDto = undefined;
    }
  }

  loadServices() {
    this.aclService
      .filterRoles(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.aclRoles = result.data;
        this.rolesToSelect = this.aclRoles.concat([]).sort((a, b) => a.id.localeCompare(b.id));
      });
    this.dynamicEnumService
      .getEnumEntries(
        AccountEntityDetailsComponent.SOURCE_NAME,
        AccountEntityDetailsComponent.EXTERNAL_ENTITY_TYPE_ENUM_NAME
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result.data;
      });
  }

  getDelegatedFrom() {
    this.accountEntityRoleService
      .getAccountEntityRoleById(this.accountEntityRoleDto.delegatedFromId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((accountEntity: AccountEntityRoleDto) => {
        this.delegatedFrom = accountEntity;
        if (this.delegatedFrom) {
          this.form.controls.delegatedFromId.patchValue(this.delegatedFrom.delegatedFromId);
          this.form.controls.delegatedByAccount.patchValue(this.delegatedFrom.account);
        }
      });
  }

  loadAccountEntityRole(id: string) {
    this.appBlockerService.block();
    this.accountEntityRoleService
      .getAccountEntityRoleById(id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((accountEntity: AccountEntityRoleDto) => {
        this.setAccountEntityRole(accountEntity);
        this.accountEntityRoleId = this.accountEntityRoleDto.id;
        if (this.accountEntityRoleDto.delegatedFromId) {
          this.getDelegatedFrom();
        }
      });
  }

  setAccountEntityRole(accountEntityRoleDto: AccountEntityRoleDto) {
    this.accountEntityRoleDto = accountEntityRoleDto;
    this.form.patchValue(this.accountEntityRoleDto);
    if (this.accountEntityRoleDto.recordVersion) {
      this.form.controls['entityType'].disable();
      this.form.controls['entityId'].disable();
      this.form.controls['roleType'].disable();
      this.form.get('delegatedByAccount.login').disable();
    }
    this.accountChanged(this.accountEntityRoleDto.account);
  }

  accountChanged(account: AccountDto) {
    this.account = account || null;
    this.form.controls['roleType'].reset();
    this.rolesToSelect = [];
    if (account?.external) {
      this.rolesToSelect = this.aclRoles
        ?.filter(role =>
          this.accountEntityRoleDto.recordVersion
            ? role?.id === this.accountEntityRoleDto.roleType
            : role?.external === account?.external
        )
        .sort((a, b) => a.id.localeCompare(b.id));
    } else {
      this.rolesToSelect = this.aclRoles.concat([]).sort((a, b) => a.id.localeCompare(b.id));
    }
  }

  edit() {
    this.editMode = true;
    this.form.enable();
  }

  backToListing() {
    this.location.back();
  }

  deleteAccountEntity() {
    this.modalFactory.deleteEntityModal((dialogReference: NgbModalRef) => {
      this.accountEntityRoleService
        .deleteAccountEntityRole(this.accountEntityRoleDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.accountEntity.accountEntityDeleted');
          this.navigateSibling('EcareAccountEntityComponent');
          dialogReference.dismiss();
        });
    });
  }

  saveChanges(skipFormValidation: boolean = false) {
    if (!skipFormValidation) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return true;
      } else {
        this.accountEntityRoleDto = this.form.getRawValue();
      }
    }

    this.appBlockerService.block();
    if (this.accountEntityRoleId !== undefined) {
      this.accountEntityRoleDto.entityType = null;
      this.accountEntityRoleDto.entityId = null;
      this.accountEntityRoleDto.roleType = null;
      this.accountEntityRoleDto.delegatedByAccount.login = null;
      this.accountEntityRoleService
        .updateAccountEntityRole(this.accountEntityRoleId, this.accountEntityRoleDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((accountEntity: AccountEntityRoleDto) => this.reloadAccountEntity(accountEntity));
    } else {
      this.accountEntityRoleService
        .createAccountEntityRole(this.accountEntityRoleDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((accountEntity: AccountEntityRoleDto) => this.reloadAccountEntity(accountEntity));
    }
  }

  discardChanges() {
    this.modalFactory.discardChangesModal(
      dialogReference => {
        this.saveChanges();
        dialogReference.dismiss();
      },
      dialogReference => {
        this.form.reset();
        this.newAccountEntity = false;
        this.editMode = false;
        this.reloadAccountEntity(this.accountEntityRoleDto);
        dialogReference.dismiss();
      }
    );
  }

  reloadAccountEntity(accountEntityRole: AccountEntityRoleDto) {
    this.accountEntityRoleDto = accountEntityRole;
    this.form.patchValue(this.accountEntityRoleDto);
  }

  cancel() {
    this.navigateSibling('EcareAccountEntityComponent');
  }

  getAccountParamByName(name: string): string {
    let value = null;
    if (this.account) {
      value = this.account.parameters?.find(param => param.name === name)?.value;
    }
    return value;
  }

  delegate() {
    const modalRef = this.ngModal.open(DelegateModalComponent);
    const delegateModalComponent = <DelegateModalComponent>modalRef.componentInstance;
    delegateModalComponent.dialogRef = modalRef;
    delegateModalComponent.selectedAccountEntityRoleDto = this.accountEntityRoleDto;
    delegateModalComponent.grantMode = false;
  }

  protected readonly PickerInputType = PickerInputType;
}
