import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {PartyHolder} from "../../../../models/party-holder";
import {Subject} from "rxjs/internal/Subject";
import {CustomerService} from "@service/customer.service";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {Constants} from "../../../../constants";
import {CustomerLocalStorageService} from "@service/customer-local-storage.service";

@Component({
  selector: 'app-ecare-filter-autocomplete-box',
  templateUrl: './ecare-filter-autocomplete-box.component.html'
})
export class EcareFilterAutocompleteBoxComponent implements OnInit {

  @Input()
  searchedEntity: 'Customer' | 'CustomerAccount' = 'Customer';

  @Output() handleChangeEvent = new EventEmitter<string>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('autocompleteItem') autocompleteItem: ElementRef;

  focusOn: boolean = false;
  searchPerformed: boolean = false;

  searchSubject = new Subject<{term: string, entity: string}>();
  searchResultHolders: Array<PartyHolder>;

  constructor(private customerService: CustomerService,
              private customerLocalStorageService: CustomerLocalStorageService) { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.autocompleteItem && this.searchInput) {
      if (this.autocompleteItem.nativeElement.contains(event.target) || this.searchInput.nativeElement.contains(event.target)) {
        this.focusOn = true;
      } else {
        this.focusOn = false;
      }
    }
  }

  ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
      switchMap(({term, entity}) => {
        return this.customerService.searchByText(term, 3, entity);
      })
    ).subscribe((ph) => {
      this.searchResultHolders = ph;
    });
  }


  public searchEntity(term: string, entity?: string) {
    this.focusOn = true;
    if (term && term.length > 2) {
      this.searchSubject.next({term, entity});
    } else {
      this.searchResultHolders = null;
      if (term.length === 0 && this.searchPerformed) {
        this.handleSearch(null);
        this.searchPerformed = false;
      }
    }
  }

  clearSearch() {
    this.searchResultHolders = null;
    this.searchInput.nativeElement.value = '';
    if (this.searchPerformed) {
      this.handleSearch(null);
      this.searchPerformed = false;
    }
  }

  handleSearch(entityId: string) {
    this.handleChangeEvent.next(entityId);
    this.searchPerformed = true;
    this.focusOn = false;
  }


}
