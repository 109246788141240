<div class="overview">
  <div class="one-line">
    <label class="info"> {{ 'wc.ecare.ticket.description' | translate }}: </label>
    <label class="value">
      {{ ticket.description }}
    </label>
  </div>

  <div class="middle-info last-info">
    <div class="more-lines" [formGroup]="form">
      <label class="info">{{ 'wc.ecare.ticket.attachments' | translate }}:</label>
      <app-form-attachments
        extIdPrefix="TICKET_"
        dmsType="TICKET_GENERAL"
        formControlName="attachments"
        [showHeader]="false"
        [uploadButtonBelow]="false"
        [control]="form.get('attachments')"
        [types]="['GENERAL']"
        (onChange)="fieldChanged($event)">
      </app-form-attachments>
    </div>
    <div class="more-lines notes">
      <label class="info">{{ 'wc.ecare.ticket.notes' | translate }}:</label>
      <div *ngFor="let note of ticket.notes" class="note">
        <div class="border-text">{{ ticket.modifiedBy }} {{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }}</div>
        <div class="text"><textarea [value]="note.note" [disabled]="true"></textarea></div>
      </div>
      <div *ngIf="editedNote" class="note">
        <div class="new-note">
          <div class="text"><textarea [(ngModel)]="editedNote.note"></textarea></div>
          <div class="buttons">
            <app-button variant="secondary" iconPath="/assets/svg/close-icon.svg" (click)="cancelEditNote()">
            </app-button>
            <app-button variant="primary" iconPath="/assets/svg/check.svg" (click)="confirmNote()"></app-button>
          </div>
        </div>
      </div>
      <app-button class="notif-icon-container" variant="secondary" iconPath="/assets/svg/add.svg" (click)="addNote()">
      </app-button>
    </div>
  </div>
</div>
