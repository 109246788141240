<div class="tariffChange-container mb-5">
  <h4 class="tariffChange-title text-center m-4">
    {{ 'wc.shopping.tariffChange.currentTariff.heading' | translate }}
  </h4>
  <div class="newDesing">
    <div class="product__detail">
      <div class="product__detail-content">
        <div class="product__detail-gallery">
          <img src="/assets/img/products/tariff_default_product_image.png" />
        </div>
        <div class="product__detail-summary">
          <h1 id="002_tariff_change" class="product__detail-title">
            {{ currentTariff.product.name | selectLocaleProperty }}
          </h1>
          <div class="product__detail-shortDescription">
            <div [innerHTML]="currentTariff.product.shortDescription | selectLocaleProperty | safeHtml"></div>
          </div>
          <div class="product__detail-pricing" id="hw-price">
            <div class="product__detail-price">
              <div class="product__detail-prices">
                <div class="product__detail-price">
                  <div class="product__detail-streetPrice">
                    <app-recurrent-price [price]="currentTariff.product.prices['RC']?.price"></app-recurrent-price>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h4 class="tariffChange-title text-center m-4">
    {{ 'wc.shopping.tariffChange.newTariff.heading' | translate }}
  </h4>

  <div class="product-listing">
    <div
      app-tariff-listing
      [products]="tariffs"
      [originalProduct]="currentUsage.product"
      [originalProductPartyId]="tariffSpaceId"
      [isTariffChange]="true"
      (btnBuyClick)="changeTariff($event)"></div>
  </div>
</div>
