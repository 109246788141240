import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-top-menu-action',
  templateUrl: './top-menu-action.component.html',
})
export class TopMenuActionComponent {
  @HostBinding('class') class = 'app-top-menu-action';

  @Input() label?: string;
  @Input() link?: string;
  @Input() variant?: 'default' | 'selected' | 'active';
  @Input() icon?;
  @Input() isDropdown?: boolean = false;
}
