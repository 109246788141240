<div>
  <div>
    <h4 id="001_time_slot">
      {{ 'wc.shopping.timeSlot.heading' | translate }} {{ this.timeSlotId ? this.timeSlotId : '' }}
    </h4>
    <div style="display: flex">
      <div style="flex: 0 0 60%; padding: 15px; display: flex">
        <div style="height: 100%; width: 100%">
          <select #slotSelect id="002_time_slot" style="height: 100%" multiple>
            <option *ngFor="let timeSlot of timeSlots; let i = index" [value]="i" [selected]="i === 0">
              <ng-container *ngIf="timeSlot; else emptySlot">
                {{ timeSlot.selected ? '* ' : ''
                }}{{ timeSlot.timeFrom.toLocaleString() + ' - ' + timeSlot.timeTo.toLocaleString() }}
              </ng-container>
              <ng-template #emptySlot>
                {{ 'wc.shopping.timeSlot.emptySlot' | translate }}
              </ng-template>
            </option>
          </select>
        </div>
        <div *ngIf="timeSlots.length > 1" style="align-self: center">
          <button id="003_time_slot" (click)="moveUp(slotSelect.value)">&uArr;</button><br />
          <button id="004_time_slot" (click)="moveDown(slotSelect.value)">&dArr;</button>
        </div>
      </div>
      <div style="flex: 1; padding: 15px">
        <div>
          <div>
            <label for="dateFrom">{{ 'wc.shopping.timeSlot.dateFrom' | translate }}:</label>
            <div>
              <app-date-picker class="date-picker" #dateFrom style="display: flex" id="dateFrom"
                               [showTime]="true"></app-date-picker>
            </div>
          </div>

          <div>
            <label for="dateTo">{{ 'wc.shopping.timeSlot.dateTo' | translate }}</label>
            <div>
              <app-date-picker class="date-picker" #dateTo style="display: flex" id="dateTo"
                               [showTime]="true"></app-date-picker>
            </div>
          </div>

          <app-button
            variant="primary"
            label="{{ 'wc.shopping.timeSlot.set.button.label' | translate }}"
            (click)="setDateTime(dateFrom, dateTo, slotSelect.value)"></app-button>
          <app-button
            variant="secondary"
            label="{{ 'wc.shopping.timeSlot.clear.button.label' | translate }}"
            (click)="clearDateTime(slotSelect.value)"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
