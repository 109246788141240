<form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
  <div class="-filter-fields -filter-fields-group">
    <input
      id="002_customers_filter"
      type="text"
      formControlName="customName"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.customerAccounts.customName.placeholder' | translate }}" />

    <app-advanced-filter [form]="filterForm"
                         [isAdvancedFilterOpen]="isAdvancedFilterOpen"
                         (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
                         [changedFilterFieldsCount]="changedFilterFieldsCount"
                         clearHistoryTextLocalizedKey="wc.ecare.customersFilter.clearFilters"
                         (resetFieldsEmitter)="resetFilterFields()">
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.customerAccounts.variableSymbol"
                                 controlName="variableSymbol"
                                 placeholderLocalizedKey="wc.ecare.customerAccounts.variableSymbol"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.customerAccounts.id"
                                 controlName="id"
                                 placeholderLocalizedKey="wc.ecare.customerAccounts.id"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.customerAccounts.extId"
                                 controlName="extId"
                                 placeholderLocalizedKey="wc.ecare.customerAccounts.extId"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field *ngIf="canChangeCustomer" labelLocalizedKey="wc.ecare.customerAccounts.customer" fieldType="select">
        <app-form-field-modal-picker
          valueField="id"
          labelField="id"
          controlName="recursiveParentId"
          [parentGroup]="filterForm"
          [pickerType]="pickerInputType.CUSTOMER"
          (selectionStarts)="selectingField = true"
          (selectionEnds)="selectingField = false"
          (selectionChanged)="changeFilterField('recursiveParentId', $event)">
        </app-form-field-modal-picker>
      </app-advanced-filter-field>
    </app-advanced-filter>
  </div>

  <app-button
    variant="primary"
    id="004_customers_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()">
  </app-button>
</form>
