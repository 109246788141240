import { AfterViewInit, Component, forwardRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormUtils } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-time-slot-chips',
  templateUrl: './time-slot-chips.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSlotChipsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => TimeSlotChipsComponent),
    },
  ],
})
export class TimeSlotChipsComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  public init = new Date();
  public resetModel = new Date(0);
  public CLOSE_ON_SELECTED = false;

  value;
  minDate: Date;
  maxDate: Date;
  new: boolean;

  totalTimeSlots: number = 0;

  propagateChange: any = () => {
  };

  propagateOnTouched: any = () => {
  };

  discloseValidatorChange: any = () => {
  };

  @Input() numberOfSlots: number = 0;
  @Input() timeSlotProposal: string;
  @Input() parentGroup: FormGroup;
  @Input() locationId;
  @Input() control: AbstractControl;

  @ViewChildren('picker') _picker: QueryList<MatDatepicker<Date>>;

  constructor(private formBuilder: FormBuilder) {
  }

  form: FormGroup = this.formBuilder.group({
    slots: this.formBuilder.array([]),
  });

  ngOnInit() {
    if (this.control) {
    } else {
      this.control = new FormControl('');
      this.parentGroup.addControl(`timeSlot${this.locationId}`, this.control);
    }

    const self = this;
    const origFunc = this.control.markAsTouched;
    this.control.markAsTouched = function() {
      FormUtils.validateAllFormFields(self.form);
      if (!self.form.valid) {
        self.control.setErrors(self.form.errors);
        self.discloseValidatorChange();
      } else {
        self.control.setErrors(null);
      }
      origFunc.apply(this, arguments);
    };

    this.minDate = new Date();
    this.countTotalTimeSlots();
    this.parseTimeSlotProposal();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  ngAfterViewInit() {
    this.countTotalTimeSlots();
    if (this.slots().length === 0) this.newTimeslotArea();
  }

  public countTotalTimeSlots() {
    this.totalTimeSlots = 0;
    this.slots().value.forEach(object => {
      const slotDatesCount = object.slotDates.length;
      this.totalTimeSlots += slotDatesCount;
    });
  }

  // Function that will be called when user click on specific date from datepicker
  public dateChanged(event: MatDatepickerInputEvent<Date>, formGroup: FormGroup, slotNum: number): void {
    this.resetModel = new Date(0);
    // close datepicker dropdown once max number of slots is reached
    if (this.numberOfSlots > this.totalTimeSlots) {
      const date = event.value;
      const index = this._findDate(date);
      if (index === -1) {
        (formGroup.get('slotDates') as FormArray).push(this.formBuilder.control(event.value));
      } else {
        this.removeChip(date);
      }

      if (this.numberOfSlots !== this.totalTimeSlots + 1) {
        const closeFn = this._picker.toArray()[slotNum].close;
        this._picker.toArray()[slotNum].close = () => {};
        this._picker.toArray()[slotNum]['_componentRef'].instance._calendar.monthView._createWeekCells();
        setTimeout(() => {
          this._picker.toArray()[slotNum].close = closeFn;
        });
      }
    }

    this.countTotalTimeSlots();
  }

  private parseTimeSlotProposal() {
    this.slots().clear();
    let timeSlotParsed: Array<TimeSlotProposal>;
    if (this.value) {
      const groups = {};

      timeSlotParsed = JSON.parse(this.value);
      if (timeSlotParsed && timeSlotParsed.length > 0) {
        timeSlotParsed.forEach(timeSlot => {
          if (groups[timeSlot.slotNum]) {
            groups[timeSlot.slotNum].push(timeSlot);
          } else {
            groups[timeSlot.slotNum] = [timeSlot];
          }
        });
        const result = [];
        for (const slotNum in groups) {
          const group = groups[slotNum];
          const times = group.map(obj => {
            return obj.timeFrom;
          });
          this.addToFormArray({
            slotDates: times,
            timeHoursFrom: new Date(group[0].timeFrom).getHours().toString(),
            timeMinutesFrom: new Date(group[0].timeFrom).getMinutes().toString(),
            timeHoursTo: new Date(group[0].timeTo).getHours().toString(),
            timeMinutesTo: new Date(group[0].timeTo).getMinutes().toString(),
          });
        }
      }
    }
    this.new = false;
  }

  addToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group(TimeSlotChipsComponent.getSubFormControlConfig(this.formBuilder), {
      validator: timeRangeValidator,
    });
    value?.slotDates?.forEach(time => {
      (newDataFormGroup.controls.slotDates as FormArray).push(new FormControl(new Date(time)));
    });
    newDataFormGroup.patchValue(value);
    this.slots().push(newDataFormGroup);
  }

  public removeChip(dateToRemove: Date): void {
    this.slots().controls.forEach(slotArea => {
      const dateIndex = slotArea.get('slotDates')['controls'].findIndex((control: FormControl) => {
        const dateControl = new Date(control.value);
        dateControl.setHours(0, 0, 0);
        dateToRemove = new Date(dateToRemove);
        dateToRemove.setHours(0, 0, 0);
        return dateControl.getTime() === dateToRemove.getTime();
      });
      if (dateIndex >= 0) {
        (slotArea.get('slotDates') as FormArray).removeAt(dateIndex);
      }
    });
    this.countTotalTimeSlots();
  }

  public removeTimeslotArea(slotNum: number): void {
    this.slots().removeAt(slotNum);
    this.countTotalTimeSlots();
  }

  newTimeslotArea() {
    // create new form group
    const newDataFormGroup = this.formBuilder.group(TimeSlotChipsComponent.getSubFormControlConfig(this.formBuilder), {
      validator: timeRangeValidator,
    });
    this.slots().push(newDataFormGroup);
  }

  public setTimeFrom(date: Date, hoursFrom: number, minutesFrom: number): Date {
    const dateFrom: Date = new Date(date.setHours(hoursFrom, minutesFrom, 0));
    return dateFrom;
  }

  public setTimeTo(date: Date, hoursTo: number, minutesTo: number): Date {
    const dateTo: Date = new Date(date.setHours(hoursTo, minutesTo, 0));
    return dateTo;
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) >= 0) {
      return ['selected'];
    }
    return [];
  };

  private _findDate(date: Date): number {
    const arrayOfDates = [];
    if (this.slots().value) {
      this.slots().value.forEach(slot => {
        slot.slotDates.forEach(slot => {
          const dateObject = new Date(slot);
          dateObject.setHours(0, 0, 0);
          arrayOfDates.push(dateObject.toString());
        });
      });
      return arrayOfDates.indexOf(date.toString());
    }
  }

  // Following functions are part of ControlValueAccessor form connection
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.parseTimeSlotProposal();
  }

  getValue() {
    const slotsArray: Array<TimeSlotProposal> = [];
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.form.get('slots').value.forEach((slotArea, index: number) => {
        slotArea.slotDates.forEach(slot => {
          const slotIndex: number = index;
          const slotDateFrom: Date = this.setTimeFrom(new Date(slot), slotArea.timeHoursFrom, slotArea.timeMinutesFrom);
          const slotDateTo: Date = this.setTimeTo(new Date(slot), slotArea.timeHoursTo, slotArea.timeMinutesTo);
          slotsArray.push({
            selected: true,
            timeFrom: slotDateFrom,
            timeTo: slotDateTo,
            slotNum: slotIndex,
          });
        });
      });
      return JSON.stringify(slotsArray);
    }
    return null;
  }

  public static getSubFormControlConfig(formBuilder: FormBuilder) {
    return {
      slotDates: formBuilder.array([]),
      timeHoursFrom: ['08', [Validators.required, Validators.min(0), Validators.max(23)]],
      timeMinutesFrom: ['00', [Validators.required, Validators.min(0), Validators.max(59)]],
      timeHoursTo: ['22', [Validators.required, Validators.min(0), Validators.max(23)]],
      timeMinutesTo: ['00', [Validators.required, Validators.min(0), Validators.max(59)]],
    };
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  slots(): FormArray {
    return this.form.get('slots') as FormArray;
  }
}

function timeRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const fromHour = parseInt(control.get('timeHoursFrom').value, 10);
  const fromMinute = parseInt(control.get('timeMinutesFrom').value, 10);
  const toHour = parseInt(control.get('timeHoursTo').value, 10);
  const toMinute = parseInt(control.get('timeMinutesTo').value, 10);

  if (fromMinute > 59 || fromMinute < 0 || toMinute > 59 || toMinute < 0) {
    return { invalid: true };
  }

  if (toHour < fromHour || (toHour === fromHour && toMinute <= fromMinute)) {
    return { invalid: true };
  }

  return null;
}

export interface TimeSlotProposal {
  selected: boolean;
  timeFrom: Date;
  timeTo: Date;
  slotNum?: number;
}
