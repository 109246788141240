import { Component } from '@angular/core';
import { CmsWidgetBannerFullImageBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-banner-full-image-hp-hero]',
  templateUrl: './cms-widget-banner-full-image-hp-hero.component.html',
})
@EnableDynamicLoading({ customName: 'BN_FULL_IMAGE_HP_HERO' })
export class CmsWidgetBannerFullImageHpHeroComponent extends CmsWidgetBannerFullImageBaseComponent {
}
