<header #headerElement [class.checkout]="checkout">
  <div class="header-container">
    <!-- Collapsable drawer for shopping cart -->
    <app-shopping-cart-side-menu
      [preCalculatedShoppingCart]="preCalculatedShoppingCart"
      [navigationType]="navigationType">
    </app-shopping-cart-side-menu>
    <!-- END Collapsable drawer for shopping cart -->
    <app-top-menu
      #topMenu
      [isContextLoaded]="isContextLoaded"
      [doNotRedirectAfterLogin]="doNotRedirectAfterLogin"
      [navigationType]="navigationType"
      [contextHealthScore]="contextHealthScore"
      [productGroupsTree]="topMenuGroups">
    </app-top-menu>
    <app-customer-menu
      #customerMenu
      [showMobileMenu]="showMobileMenu"
      [preCalculatedShoppingCart]="preCalculatedShoppingCart"
      [isContextLoaded]="isContextLoaded"
      [navigationType]="navigationType"
      (topMenuGroups)="getTopMenuGroups($event)"
      (showMobileMenuChanged)="onShowMenuChanged($event)">
    </app-customer-menu>
    <app-mobile-menu
      [navigationType]="customerMenu.navigationType"
      [showMobileMenu]="showMobileMenu"
      [mainNavigation]="customerMenu.mainNavigation"
      [navigation]="customerMenu.navigation"
      [topMenuRightSide]="topMenu.topMenuRightSide"
      [topMenuLeftSide]="topMenu.topMenuLeftSide"
      (showMobileMenuChanged)="onShowMenuChanged($event)"></app-mobile-menu>
  </div>
</header>
