<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.tasks.newTask' | translate }}
  </h4>
  <div class="modal-close" (click)="cancel()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="newTicketForm" class="generated-form">
    <div class="generated-input-container col12">
      <label for="ticket-channel">
        {{ 'wc.ecare.ticket.channel' | translate }}
      </label>
      <select
        formControlName="areaType"
        class="full"
        (ngModelChange)="ticketAreaChange($event)"
        id="ticket-channel">
        <option *ngFor="let area of areas" [value]="area.areaType">
          {{ area.areaType }}
        </option>
      </select>
      <app-input-errors
        [control]="newTicketForm.controls['areaType']"
        [translationPrefix]="'wc.ecare.ticket.areaType'">
      </app-input-errors>
    </div>
    <div class="generated-input-container col12">
      <label for="ticket-code">
        {{ 'wc.ecare.ticket.code' | translate }}
      </label>
      <select
        formControlName="code"
        class="full"
        id="ticket-code">
        <option *ngFor="let code of codes" [value]="code.code">
          {{ code.code }}
        </option>
      </select>
      <app-input-errors
        [control]="newTicketForm.controls['code']"
        [translationPrefix]="'wc.ecare.ticket.code'"></app-input-errors>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button
  variant="secondary"
  ngbAutofocus
  label="{{ 'wc.common.cancel.button' | translate }}"
  (click)="cancel()">
</app-button>
  <app-button
    variant="primary"
    label="{{ 'wc.common.continue.button' | translate }}"
    (click)="continue()">
  </app-button>
</div>
