<div class="overview">
  <div *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook"
       class="one-line notif-detail-attrs">
    <div
      app-acl-internal-evaluate-component
      *ngIf="ticket.customer"
      class="notif-icon-container"
      placement="bottom"
      ngbTooltip="{{ 'wc.ecare.ticket.customer' | translate }}">
      <i [inlineSVG]="'/assets/img/notifications/notif-id.svg'"></i>
      {{ ticket.customer }}
    </div>
    <div
      app-acl-internal-evaluate-component
      *ngIf="ticket.customerAccount"
      class="notif-icon-container"
      placement="bottom"
      ngbTooltip="{{ 'wc.ecare.dashboard.customerAccount.header' | translate }}">
      <i [inlineSVG]="'/assets/img/notifications/notif-id.svg'"></i>
      {{ ticket.customerAccount }}
    </div>
    <div
      *ngIf="ticket.contactType"
      class="notif-icon-container"
      placement="bottom"
      ngbTooltip="{{ 'wc.ecare.ticket.contactType' | translate }}">
      <i [inlineSVG]="'/assets/img/notifications/notif-id.svg'"></i>
      {{ codebook[ticket.contactType] }}
    </div>
    <div
      *ngIf="ticket.area"
      class="notif-icon-container"
      placement="bottom"
      ngbTooltip="{{ 'wc.ecare.ticket.area' | translate }}">
      <i [inlineSVG]="'/assets/img/notifications/notif-type.svg'"></i>
      {{ codebook[ticket.area] }}
    </div>
    <div
      *ngIf="ticket.subArea"
      class="notif-icon-container"
      placement="bottom"
      ngbTooltip="{{ 'wc.ecare.ticket.subArea' | translate }}">
      <i [inlineSVG]="'/assets/img/notifications/notif-gateway.svg'"></i>
      {{ codebook[ticket.subArea] }}
    </div>
  </div>
  <div class="one-line">
    <label class="info"> {{ 'wc.ecare.ticket.description' | translate }}: </label>
    <label class="value">
      {{ ticket.description }}
    </label>
  </div>

  <app-acl-internal-evaluate-component>
    <div class="middle-info">
      <div class="one-line notif-detail-attrs">
        <label class="info"> {{ 'wc.ecare.contactHistory.relatedTasks' | translate }}: </label>
        <div *ngFor="let task of ticket.tasks" class="notif-icon-container">
          <a class="cursor-pointer" (click)="openRelatedTask(task.id)">{{ task.subject }}</a>
        </div>
        <app-button
          class="notif-icon-container"
          variant="secondary"
          iconPath="/assets/svg/add.svg"
          (click)="addRelatedTask()">
        </app-button>
      </div>
      <div class="more-lines" [formGroup]="form">
        <label class="info">{{ 'wc.ecare.ticket.attachments' | translate }}:</label>
        <app-form-attachments
          extIdPrefix="TICKET_"
          dmsType="TICKET_GENERAL"
          formControlName="attachments"
          [showHeader]="false"
          [uploadButtonBelow]="false"
          [control]="form.get('attachments')"
          [types]="['GENERAL']"
          (onChange)="fieldChanged($event)">
        </app-form-attachments>
      </div>
      <div class="more-lines notes">
        <label class="info">{{ 'wc.ecare.ticket.notes' | translate }}:</label>
        <div *ngFor="let note of ticket.notes" class="note">
          <div class="border-text">{{ ticket.modifiedBy }} {{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }}</div>
          <div class="text"><textarea [value]="note.note" [disabled]="true"></textarea></div>
        </div>
        <div *ngIf="editedNote" class="note">
          <div class="new-note">
            <div class="text"><textarea [(ngModel)]="editedNote.note"></textarea></div>
            <div class="buttons">
              <app-button variant="secondary" iconPath="/assets/svg/close-icon.svg" (click)="cancelEditNote()">
              </app-button>
              <app-button variant="primary" iconPath="/assets/svg/check.svg" (click)="confirmNote()"></app-button>
            </div>
          </div>
        </div>
        <app-button class="notif-icon-container" variant="secondary" iconPath="/assets/svg/add.svg" (click)="addNote()">
        </app-button>
      </div>
    </div>
  </app-acl-internal-evaluate-component>
</div>
