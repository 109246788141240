import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";
import { FormUtils } from "@btl/btl-fe-wc-common";

@Component({
  selector: 'app-form-phone-number',
  templateUrl: './form-phone-number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPhoneNumberComponent),
      multi: true,
    },
  ],
})
export class FormPhoneNumberComponent implements OnInit, ControlValueAccessor {
  @Input() value: string;

  @Input() fieldName: string;

  @Input() mandatory = false;

  @Input() control: AbstractControl;

  @Input() translationPrefix: string;

  @Input() showLabel = true;

  @Input()
  validate = true;

  @Output() readonly onChange = new EventEmitter<any>();

  directionals = ['+386', '+420', '+421'];

  new = true;

  form: FormGroup = this.formBuilder.group({
    directional: [null, Validators.required],
    phone: [null, Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid && self.validate) {
          self.control.setErrors(self.form.get('phone').errors, { emitEvent: true });
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => {
      this.propagateChange(this.getValue());
    });
  }

  valueChanged() {
    if (!this.value) {
      this.value = null;
      this.form.get('directional').setValue('+420');
    } else {
      if (this.value.startsWith('+')) {
        const numbers = this.value.split(' ');
        if (numbers.length > 0) {
          this.form.get('directional').setValue(numbers[0]);
        }
        if (numbers.length > 1) {
          numbers.shift();
          this.form.get('phone').setValue(numbers.join(' '));
        }
      } else {
        this.form.get('directional').setValue('+420');
        this.form.get('phone').setValue(this.value);
      }
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    const phone = this.form.get('phone').value;
    if (!phone) {
      return null;
    }
    return `${this.form.get('directional').value} ${this.form.get('phone').value}`;
  }
}
