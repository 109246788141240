import { Component, Input, OnInit } from '@angular/core';
import { WatchdogSubscriptionService } from '@btl/btl-fe-wc-common';
import { NgForm } from '@angular/forms';
import { WatchdogSubscriptionDto } from '@btl/order-bff';
import { HttpResponse } from '@angular/common/http';
import { PropertyAccessorLocalService } from 'app/services/property-accessor-local.service';
import { FormUtils } from '../../../../../helpers/form-utils';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-watchdog-subscription',
  templateUrl: './watchdog-subscription.component.html',
})
export class WatchdogSubscriptionComponent implements OnInit {
  @Input()
  dialogRef;

  FIELD_EMAIL: string = 'watch-dog-email';
  FIELD_NOTE: string = 'watch-dog-note';
  FIELD_GDPR: string = 'watch-dog-gdpr';

  formSubmitted: boolean = false;
  emailPattern = FormUtils.emailPattern;
  emailField: string;
  noteField: string;
  gdprCheckbox: boolean = false;
  @Input() productCode: string;
  public showForm: boolean = true;
  successful: boolean = false;
  @Input() showFeature: boolean;
  isLoading: boolean = false;
  public formHidden: boolean = true;

  constructor(
    private watchdogSubscriptionService: WatchdogSubscriptionService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.formSubmitted = false;
  }

  onFormSubmit(form: NgForm) {
    this.isLoading = true;
    this.formSubmitted = true;
    if (form.invalid) {
      this.isLoading = false;
      return;
    } else {
      this.emailField = form.value[this.FIELD_EMAIL];
      this.noteField = form.value[this.FIELD_NOTE];

      const stockAvailableRequest = this.getWatchdogRequest('WD_STOCK_AVAILABLE');
      const priceDropRequest = this.getWatchdogRequest('WD_PRICE_DROP');
      const preorderAvailableRequest = this.getWatchdogRequest('WD_PREORDER_AVAILABLE');
      forkJoin([stockAvailableRequest, priceDropRequest, preorderAvailableRequest]).subscribe(
        ([stockAvailableResp, priceDropResp, preorderAvailableResp]) => {
          this.showForm = false;
          if (
            stockAvailableResp.status === 200 &&
            priceDropResp.status === 200 &&
            preorderAvailableResp.status === 200
          ) {
            this.successful = true;
          }
        }
      );
      this.formSubmitted = false;
    }
  }

  private getWatchdogRequest(event: 'WD_PRICE_DROP' | 'WD_STOCK_AVAILABLE' | 'WD_PREORDER_AVAILABLE') {
    return this.watchdogSubscriptionService.subscribeEvent(
      null,
      null,
      event,
      'SUBSCRIBE',
      this.emailField,
      'EMAIL',
      this.productCode,
      'PRODUCT_CODE',
      null,
      this.noteField,
      null,
      'response',
      true
    );
  }

  resetForm() {
    this.showForm = true;
    this.successful = false;
    this.formHidden = true;
    this.dialogRef.dismiss();
  }
}
