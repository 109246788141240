import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rgb',
})
export class RgbPipe implements PipeTransform {
  transform(value: string, colors: Map<string, { name: string; rgb: string }>): any {
    return colors.get(value) ? `rgb(${colors.get(value).rgb})` : 'rgba(0,0,0,0)';
  }
}
