import { Component, Input, OnDestroy } from '@angular/core';
import { AssetDto, OrderItemDto, ProductDetailDto, ProductInfoDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Subject } from 'rxjs';
import { AppBlockerService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-addon-activation-addon',
  templateUrl: './addon-activation-addon.component.html',
  styleUrls: ['./addon-activation-addon.component.scss'],
})
export class AddonActivationAddonComponent implements OnDestroy {
  @Input()
  count;

  @Input()
  addon: ProductInfoDto | AssetDto;

  @Input()
  partyId: string;

  @Input()
  parentId = '';

  @Input()
  isChangeInProgress = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private orderingService: WcOrderingService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  createAddonOrder(activate: boolean) {
    const orderItems: OrderItemDto[] = [];
    if (activate) {
      orderItems.push({
        productId: this.addonProduct.id,
        parentOrderItemId: this.addonProduct['parentInstanceId'],
        partyRefNo: this.partyId,
        action: 'ADD',
        operation: OrderItemDto.OperationDtoEnum.ADDORUPDATE,
      });
    } else {
      orderItems.push({
        productId: this.addonProduct.id,
        parentOrderItemId: this.addon['parentId'],
        asRefNo: this.addon.id,
        action: 'DELETE',
        partyRefNo: this.partyId,
        operation: OrderItemDto.OperationDtoEnum.REMOVE,
      });
    }

    this.isChangeInProgress = true;
    this.appBlockerService.block();
    this.orderingService
      .createFastOrder(orderItems)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        fastOrder => {
          const message = activate
            ? 'wc.ecare.dashboard.addonActivation.ok'
            : 'wc.ecare.dashboard.addonDeactivation.ok';
          this.stickyMessageService.addStickySuccessMessage(message);
        },
        err => {
          console.error(err);
          const message = activate
            ? 'wc.ecare.dashboard.addonActivation.notOk'
            : 'wc.ecare.dashboard.addonDeactivation.notOk';
          this.stickyMessageService.addStickyErrorMessage(message);
          this.isChangeInProgress = false;
        }
      );
  }

  get addonProduct(): ProductInfoDto | ProductDetailDto {
    if (this.addon['product']) {
      return this.addon['product'];
    }
    return this.addon;
  }

  get addonState(): string {
    if (this.isChangeInProgress) {
      return 'IN_PROGRESS';
    }
    if (this.addon && this.addon['state']) {
      return this.addon['state'];
    }
    return 'DEACTIVATED';
  }
}
