<form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
  <div class="-filter-fields -filter-fields-group">
    <input
      id="002_users_filter"
      type="text"
      formControlName="login"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.users.login.placeholder' | translate }}" />


    <app-advanced-filter [form]="filterForm"
                         [isAdvancedFilterOpen]="isAdvancedFilterOpen"
                         (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
                         [changedFilterFieldsCount]="changedFilterFieldsCount"
                         clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
                         (resetFieldsEmitter)="resetFilterFields()">
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.users.firstName"
                                 controlName="firstName"
                                 placeholderLocalizedKey="wc.ecare.users.firstName"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.users.lastName"
                                 controlName="lastName"
                                 placeholderLocalizedKey="wc.ecare.users.lastName"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.users.extId"
                                 controlName="extId"
                                 placeholderLocalizedKey="wc.ecare.users.extId"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field [fieldType]="'input'"
                                 labelLocalizedKey="wc.ecare.users.employeeId"
                                 controlName="employeeId"
                                 placeholderLocalizedKey="wc.ecare.users.employeeId"
                                 (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field *ngIf="includeExternal" labelLocalizedKey="wc.ecare.users.internalExternal" fieldType="select" fullWidth="true">
        <ng-select
          formControlName="external"
          class="ecare-dropdown"
          [clearable]="false"
          [clearOnBackspace]="false"
          [searchable]="false">
          <ng-option value="">{{ 'wc.ecare.users.all' | translate }}</ng-option>
          <ng-option [value]="false">{{ 'wc.ecare.users.internal' | translate }}</ng-option>
          <ng-option [value]="true">{{ 'wc.ecare.users.external' | translate }}</ng-option>
        </ng-select>
        <input id="external" type="checkbox" formControlName="external" />
      </app-advanced-filter-field>
    </app-advanced-filter>

  </div>

  <app-button
    variant="primary"
    id="004_users_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()">
  </app-button>
</form>
