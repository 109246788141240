<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Remove all items</h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">Are you sure you would like to remove all items from shopping cart?</div>
<div class="modal-footer">
  <div>
    <app-button
      variant="link"
      ngbAutofocus
      label="{{ 'wc.common.close.button' | translate }}"
      (click)="dialogRef.dismiss()"></app-button>
    <app-button
      variant="danger"
      label="Yes, remove all items"
      iconPath="/assets/svg/remove.svg"
      (click)="cancelOrder()"></app-button>
  </div>
</div>
