<app-ecare-mobile-row-general *ngFor="let ticket of tickets">
  <div class="mobile-table-row-wrapper" (dblclick)="ecareOpportunitiesComponent.showDetails(ticket)">
    <div class="mobile-table-row-flex space-between">
      <div class="mobile-table-row-flex information">
        <div>{{ ticket.subject }}</div>
        <div *ngIf="ticket['state']">
          <span
            *ngIf="codebookService.getCodebookTexts('TICKET_OPPORTUNITY_STATE') | async as codebook"
            class="-opportunity-state {{ticket['state']}}">
            {{ codebook[ticket['state']] }}
          </span>
        </div>
      </div>
      <ng-container>
        <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
          <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
            <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
          </span>

          <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ ticket.id }}_popover-content">
            <div>
              <button
                class="btn btn-link action-list-more-actions-button"
                type="button"
                (click)="ecareOpportunitiesComponent.showDetails(ticket)">
                {{ 'wc.ecare.opportunity.showDetails.button' | translate }}
              </button>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="delete(ticket)">
                {{ 'wc.ecare.opportunity.delete.button' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="mobile-table-row-flex space-between">
      <span
        *ngIf="ticket['probability']"
        class="probability">
        <div class="progress-bar_container">
          <div
            class="progress-bar_value-bar probability-bar-{{
              ecareOpportunitiesComponent.getOpportunityProbabilityType(ticket['probability'])
            }}"
            [style.width.%]="ticket['probability']"></div>
        </div>

        <div class="progress-bar_value-label">
          {{ ticket['probability'] }} %
        </div>
      </span>
    </div>
    <div class="mobile-table-row-flex space-between">
      <div class="left-part">
        <div class="wrapped filled">
          <i [inlineSVG]="'/assets/svg/sort-ascending.svg'"></i>
          <div class="additional-info">
            {{ 'wc.ecare.opportunity.priority' | translate }}
          </div>
        </div>
        <div class="wrapped">
          <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'"></i>
          <div class="highlight">{{ 'wc.ecare.ticket.task.priorities.' + ticket.priority | translate }}</div>
        </div>
      </div>
      <div class="right-part">
        <div *ngIf="ticket['expectedRevenue']" class="wrapped filled">
          <i [inlineSVG]="'/assets/svg/hand-coins.svg'"></i>
          <div class="additional-info">
            {{ 'wc.ecare.ticket.expectedRevenue' | translate }}
          </div>
        </div>

        <div
          *ngIf="ticket['expectedRevenue']"
          class="-expected-revenue highlight">
          {{ ticket['expectedRevenue'] }}
          {{ ecareOpportunitiesComponent.appCurrency }}
        </div>
      </div>
    </div>
    <div class="mobile-table-row-flex space-between">
      <div class="left-part">
        <div class="wrapped filled">
          <i [inlineSVG]="'/assets/svg/user.svg'"></i>
          <div class="additional-info">
            {{ 'wc.ecare.opportunity.assignedTo' | translate }}
          </div>
        </div>
        <div class="highlight">{{ ticket.assignedTo }}</div>
      </div>
    </div>
  </div>
  <div
    *ngIf="ticket['customer']"
    class="mobile-table-row-wrapper row-bottom"
    (dblclick)="ecareOpportunitiesComponent.showDetails(ticket)">
    <div class="mobile-table-row-flex highlight">
      <div class="title-text">{{ ticket['customer'] }}</div>
    </div>
  </div>
</app-ecare-mobile-row-general>
