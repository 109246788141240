<div class="ecare dashboard">
  <div class="ecare-header tiles_wrapper">
    <div class="ecare-header_container">
      <div class="ecare-header_headline">
        {{ 'wc.ecare.ticket.headline' | translate }}
      </div>
      <div class="headline-buttons">
        <button type="button" class="edit" (click)="new()">
          {{ 'wc.ecare.ticket.newOpportunity' | translate }}
        </button>
      </div>
    </div>
    <div class="ecare-numberOforders">
      {{ 'wc.common.showing.label' | translate }} {{ tickets.length }} {{ 'wc.ecare.ticket.tickets' | translate }}
    </div>
  </div>
  <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
    <div class="dashboard_search">
      <div class="tiles_wrapper">
        <app-ticket-filter class="tile -tile12" [filtering]="search.filtering" (filterChanged)="handleFilterChange()">
        </app-ticket-filter>
        <div class="tile -tile12">
          <ng-container *ngIf="tickets.length > 0; else no_results">
            <table class="ecare_listing-table opportunities-table table">
              <thead>
                <tr>
                  <th>{{ 'wc.ecare.ticket.type' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.refNo' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.subject' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.priority' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.businessState' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.created' | translate }}</th>
                  <th *ngIf="showAssignTo()">{{ 'wc.ecare.ticket.assignedTo' | translate }}</th>
                  <th>{{ 'wc.ecare.ticket.action' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ticket of tickets" class="ecare_listing-table-row pointer" (dblclick)="edit(ticket)">
                  <td>
                    <span class="bold">{{ ticket.type.code }}</span>
                  </td>
                  <td>
                    <span class="bold">{{ ticket.id }}</span>
                  </td>
                  <td>
                    <span class="bold">{{ ticket.subject }}</span>
                  </td>
                  <td>
                    <span class="bold">{{ ticket.priority }}</span>
                  </td>
                  <td>
                    <span class="bold">{{ ticket.businessState }}</span>
                  </td>
                  <td>
                    <span class="bold">{{ ticket.created | date: 'dd.MM.yyyy HH:mm' }}</span>
                  </td>
                  <td *ngIf="showAssignTo()">
                    <span class="bold">{{ ticket.assignedTo }}</span>
                  </td>
                  <td class="no-wrap mobile-right">
                    <ng-container>
                      <div ngbDropdown placement="bottom" class="listing-row-actions-entry">
                        <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                          <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                        </span>

                        <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ ticket.id }}_popover-content">
                          <div>
                            <button
                              class="btn btn-link action-list-more-actions-button"
                              type="button"
                              (click)="edit(ticket)">
                              {{ 'wc.ecare.ticket.edit.button' | translate }}
                            </button>
                            <button
                              class="btn btn-link action-list-more-actions-button"
                              type="button"
                              (click)="edit(ticket, true)">
                              {{ 'wc.ecare.ticket.editInNewTab.button' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #no_results>
            <app-ecare-listing-no-results></app-ecare-listing-no-results>
          </ng-template>

          <div *ngIf="moreRecordsExists">
            {{ 'wc.ecare.ticket.scrollDownForMoreRecords.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
