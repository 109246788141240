<form *ngIf="userRoles" class="ecare-filter dashboard-search_filter opportunity-form" [formGroup]="filterForm">
  <div class="main-search-wrapper">
    <div class="-filter-fields -filter-fields-group">
      <input
        id="002_contact_history_filter"
        type="text"
        formControlName="subject"
        class="filter-input-id"
        placeholder="{{ 'wc.ecare.contactHistoryFilter.subject.placeholder' | translate }}" />
      <app-advanced-filter
        clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
        [form]="filterForm"
        [isAdvancedFilterOpen]="isAdvancedFilterOpen"
        [changedFilterFieldsCount]="changedFilterFieldsCount"
        (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
        (resetFieldsEmitter)="resetFilterFields()">
        <app-advanced-filter-field
          fullWidth="true"
          labelLocalizedKey="wc.ecare.contactHistoryFilter.relatedTo"
          controlName="relatedTo"
          idAttribute="008_contact_history_filter"
          placeholderLocalizedKey="wc.ecare.contactHistoryFilter.relatedTo.placeholder"
          [fieldType]="'input'"
          [hidden]="customerLocalStorageService.getCurrentCustomer()"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.opportunity.state"
          fieldType="select"
          fullWidth="true"
          emptyLocalizedKey="wc.ecare.opportunity.state.default"
          showEmptyOption="true"
          controlName="status"
          codebookType="TICKET_OPPORTUNITY_STATE"
          translationPrefix="wc.ecare.opportunity.state"
          emptyValue=""
          isCodebook="true"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.contactHistory.createdDate"
          controlName="createdDateFrom"
          startOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.contactHistory.createdDate"
          controlName="createdDateTo"
          endOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateTo.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.contactHistoryFilter.paramName"
          controlName="paramName"
          idAttribute="005_contact_history_filter"
          [showEmptyOption]="true"
          fieldType="select"
          optionLabel="label"
          optionValue="value"
          emptyValue=""
          [selectOptions]="parametersOptions"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
        </app-advanced-filter-field>
        <app-advanced-filter-field labelLocalizedKey="wc.ecare.contactHistoryFilter.paramValue">
          <ng-container *ngIf="!selectedParameter; else parameterSelected">
            <input
              type="text"
              formControlName="paramValue"
              placeholder="{{ 'wc.ecare.contactHistoryFilter.paramValue.placeholder' | translate }}"
              (change)="changeFilterField('paramValue', $event.target.value)"
              (keyup)="changeFilterField('paramValue', $event.target.value)" />
          </ng-container>
          <ng-template #parameterSelected>
            <div [ngSwitch]="selectedParameter.dataType">
              <app-date-picker
                *ngSwitchCase="'DATE'"
                class="date-picker"
                formControlName="paramValue"
                [showTime]="false"
                (ngModelChange)="changeFilterField('paramValue', $event)">
              </app-date-picker>

              <app-date-picker
                *ngSwitchCase="'DATE_TIME'"
                class="date-picker"
                formControlName="paramValue"
                [showTime]="true"
                (ngModelChange)="changeFilterField('paramValue', $event)">
              </app-date-picker>

              <app-codebook-selection
                *ngSwitchCase="'CODEBOOK'"
                class="full-width"
                formControlName="paramValue"
                [type]="selectedParameter.dataTypeDetail"
                (onChange)="changeFilterField('paramValue', $event)">
              </app-codebook-selection>

              <app-enum-select
                *ngSwitchCase="'ENUM'"
                formControlName="paramValue"
                [sourceName]="'ticket'"
                [enumName]="selectedParameter.dataTypeDetail"
                (onChange)="changeFilterField('paramValue', $event)">
              </app-enum-select>
              <input
                *ngSwitchDefault
                [type]="selectedParameter.dataType === 'DECIMAL' ? 'number' : 'text'"
                formControlName="paramValue"
                placeholder="{{ 'wc.ecare.contactHistoryFilter.paramValue.placeholder' | translate }}"
                (change)="changeFilterField('paramValue', $event.target.value)"
                (keyup)="changeFilterField('paramValue', $event.target.value)" />
            </div>
          </ng-template>
        </app-advanced-filter-field>
      </app-advanced-filter>
    </div>
    <app-button
      variant="primary"
      id="004_opportunity_filter"
      iconPath="/assets/svg/search-icon.svg"
      label="{{ 'wc.common.search.button' | translate }}"
      (click)="handleChange()">
    </app-button>
  </div>
</form>
