import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductCustomService } from '@service/product-custom.service';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { CustomerService } from 'app/services/customer.service';
import { CodebookService, ProductService } from '@btl/btl-fe-wc-common';
import {
  AddressDto,
  BalancesAmountResultDto,
  CustomerAccountDto,
  CustomerDto,
  OrderDto,
  PartyDto,
  TariffSpaceDto
} from '@btl/order-bff';
import { addressTypes } from '../../../pipes/display-address.pipe';

@Component({
  selector: 'app-ecare-resident-customer-account',
  templateUrl: './ecare-resident-customer-account.component.html',
})
export class EcareResidentCostumerAccountComponent implements OnInit {
  @Input()
  customerAccount: CustomerAccountDto;

  @Input()
  customer: CustomerDto;

  isBusiness: boolean;
  openBalanceResult: BalancesAmountResultDto;
  PaymentTypeEnum = PaymentTypeEnum;
  isCollapsed = true;
  invoiceAddress;
  addressTypes = addressTypes;
  tariffs: PartyDto[];

  tariffSpaceToShow: TariffSpaceDto;

  /**
   * The current order or null if there is no current order.
   */
  order: OrderDto;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private productService: ProductService,
    private productCustomService: ProductCustomService,
    private activatedRoute: ActivatedRoute,
    private orderingService: WcOrderingService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private customerService: CustomerService,
    private ngbModal: NgbModal,
    public codebookService: CodebookService,
  ) {
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit() {
    if (this.customerAccount.address && this.customerAccount.address.type === AddressDto.TypeDtoEnum.INVOICING) {
      this.invoiceAddress = this.customerAccount.address;
    }

    if (this.customer && this.customer.id) {
      this.customerService.getOpenBalance(this.customer.id, this.customerAccount.id).subscribe(openBalance => {
        this.openBalanceResult = openBalance;
      });
    }
    this.tariffs = CustomerPartyUtil.getChildTariffs(this.customerAccount.childParties) || [];
    this.tariffSpaceToShow = this.tariffs.length ? this.tariffs[0] : null;
  }

  selectedTariff(selectedTs: TariffSpaceDto) {
    this.tariffSpaceToShow = selectedTs;
  }

  /**
   * Redirect the user to financial-documents.
   */
  goToFinancialDocuments(): void {
    this.router.navigate(['ecare', 'financial-documents'], {
      queryParams: {
        customerAccountId: this.customerAccount.id,
      },
    });
  }

  gotToCa(create: boolean) {
    // redirect to: /ecare/dashboard/:cuRefNo/:caRefNo/new-ca
    this.router.navigateByUrl(
      `/ecare/dashboard/${this.customer.id}/${create ? '-' : this.customerAccount.id}/${create ? 'new-ca' : 'edit'}`
    );
  }
}

export enum PaymentTypeEnum {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
}
