import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntersectionObserverHooks } from 'ng-lazyload-image';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  private http: HttpClient;

  constructor(http: HttpClient) {
    super();
    this.http = http;
  }

  isDisabled() {
    return false;
  }

  skipLazyLoading() {
    return isPlatformServer(this.platformId) || this.isIE11();
  }

  private isIE11() {
    // @ts-ignore
    return window && document && !!window.MSInputMethodContext && !!document.documentMode;
  }
}
