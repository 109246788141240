import { NgxLoggerLevel } from 'ngx-logger';
import { getCurrentHost, getEnvProperty, getLocales } from '@btl/btl-fe-wc-common';
import projectConfig from './project.config.json';

export const environment = {
  production: true,
  projectConfigRuntime: projectConfig.runtime,
  useDefaultLanguageForMissingTranslation: false,
  browserLoggingLevel: NgxLoggerLevel.DEBUG,
  remoteLoggingLevel: NgxLoggerLevel.DEBUG,
  localization: {
    defaultLocale: getEnvProperty('${DEFAULT_LOCALE}', 'en'),
    locales: getLocales('${LOCALES}', ['en', 'cs', 'sk', 'bg', 'sl']),
    localeData: {},
    modules: ['wc.common', 'wc.shopping', 'wc.homepage', 'wc.ecare'],
  },
  currency: getEnvProperty('${CURRENCY}', '€'),
  country: 'CZE',
  scoringServiceEndPoint: 'http://trinec.emeldi.com:8218/mockedService/scoring',
  invoiceServiceEndPoint: 'http://trinec.emeldi.com:8218',
  // KEYCLOAK or NCAS
  authenticationMethod: getEnvProperty('${AUTH_METHOD}', 'KEYCLOAK'),
  authenticationServerUri: `${getEnvProperty('${AUTH_SERVER_URI}', getCurrentHost())}/auth/`,
  authenticationRoles: getEnvProperty('${AUTH_ROLES}', ''),
  authenticationIdleTimeout: getEnvProperty('${AUTH_IDLE_TIMEOUT}', '30'),
  cmsDynamicRoutesEnabled: getEnvProperty('${CMS_DYNAMIC_ROUTES_ENABLED}', 'true'),
};
