import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CustomerBillDto, OrderDto } from '@btl/order-bff';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { Router } from '@angular/router';
import { BlockTemplateComponent, CurrentLocaleService, getCurrentHost } from '@btl/btl-fe-wc-common';
import { BlockUIService } from 'ng-block-ui';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';

@Component({
  selector: 'app-invoice-pay',
  templateUrl: './invoice-pay.component.html',
})
export class InvoicePayComponent {
  //region Data:

  /**
   * The UI block template.
   */
  blockTemplate = BlockTemplateComponent;

  /**
   * A flag specifying if payment failed (from any reason).
   */
  generalFailure: boolean = false;

  /**
   * The payment form.
   */
  payForm: FormGroup;

  //region IO:

  invoiceDto: CustomerBillDto;

  @Input()
  customerAccountExtId;

  /**
   * The dialog reference.
   */
  @Input()
  dialogRef;

  /**
   * Message key holding localization for currency name shown with price
   */
  currencySymbolMessage: string;

  paymentAmountSelectionType: PaymentAmountSelectionType = 'full';

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbModal,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private blockUIService: BlockUIService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService,
    private customerLocalStorageService: CustomerLocalStorageService
  ) {
    this.currencySymbolMessage = this.currentLocaleService.getCurrencyMsgKey();
    this.payForm = this.formBuilder.group({
      payAmount: [6, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
    });
  }

  /**
   * Get the pay amount from the form.
   */
  get payAmount(): string {
    return this.payForm.controls['payAmount'].value;
  }

  /**
   * Get name of UI block element.
   */
  get invoicePayBlockUiElementName() {
    return 'invoicePay';
  }

  //region Helpers:

  /**
   * Perform pay. A INVOICE_PAYMENT order is created and the pay amount is set as its attribute. The user gets redirected to
   * financial-documents.
   */
  pay() {
    Object.keys(this.payForm.controls).forEach(fieldName => {
      const formGroupControl = this.payForm.get(fieldName);
      formGroupControl.markAsTouched({ onlySelf: true });
    });
    if (this.payForm.invalid) {
      return;
    }

    this.generalFailure = false;
    this.blockUIService.start(this.invoicePayBlockUiElementName);

    const newOrder: OrderDto = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.CHECKOUT_THANKYOU,
      ScenarioTypeEnum.INVOICE_PAYMENT
    );
    const customer = this.customerLocalStorageService.getCurrentCustomer();
    OrderUtils.addCustomerAttributeToOrder(newOrder, customer);
    let customerAccount;
    customer.childParties.forEach(loopCustomerAccount => {
      if (loopCustomerAccount.extId === this.customerAccountExtId) {
        customerAccount = loopCustomerAccount;
      }
    });
    OrderUtils.addCustomerAccountAttributeToOrder(newOrder, customerAccount);
    //OrderUtils.addAttributeToOrder(newOrder, 'CUSTOMER_REF', this.customerAccountExtId);
    OrderUtils.addAttributeToOrder(newOrder, 'AMOUNT', this.payAmount);
    OrderUtils.addAttributeToOrder(newOrder, 'DOCUMENT_REF ', this.invoiceDto.billNo);

    this.orderingService.createOrder(newOrder, true).subscribe((order1: OrderDto) => {
      if (order1 && !this.generalFailure) {
        this.orderingService.getOrderStoringHandler(order1).subscribe((order2: OrderDto) => {
          if (order2 && !this.generalFailure) {
            this.blockUIService.stop(this.invoicePayBlockUiElementName);
            this.dialogRef.dismiss();

            this.router.navigate(['/eshop/payment'], {
              queryParams: {
                callbackUrlBase: `${getCurrentHost()}/ecare/`,
                successUrl: 'financial-documents',
                errorUrl: 'financial-documents',
                backParams: `customerAccountExtId=${this.customerAccountExtId}`,
              },
            });
          }
        }, this.setFailure);
      }
    }, this.setFailure);
  }

  setInvoice(invoiceDto: CustomerBillDto) {
    this.invoiceDto = invoiceDto;
    this.payForm.controls['payAmount'].setValue(invoiceDto.remainingAmount.value);
  }

  /**
   * Set SIM change failure.
   *
   * @param error The error.
   */
  private setFailure = (error: any) => {
    this.generalFailure = true;
    this.blockUIService.stop(this.invoicePayBlockUiElementName);
  };

  changePaymentAmountSelection(paymentSelection: PaymentAmountSelectionType) {
    this.paymentAmountSelectionType = paymentSelection;
  }
}

export type PaymentAmountSelectionType = 'custom' | 'full';
