<div *ngIf="eventTypes && businessEventDtos.length > 0" class="activity-box">

  <div *ngFor="let businessEventDto of businessEventDtos" class="single-activity">
    <div class="border-wrapper">
      <div class="activity-icon business-type-{{businessEventDto.type.code}}-{{businessEventDto.type.areaType}}">
        <i [inlineSVG]="'/assets/svg/business-events/business-event-' + businessEventDto.type.code+ '-' + businessEventDto.type.areaType + '.svg'"></i>
      </div>
      <div class="activity-content">
        <div class="activity-label">
          {{ 'wc.shopping.businessEvent.' + businessEventDto.type.code + '.' + businessEventDto.type.areaType + '.description' | translate : this.businessEventDto.params }}
        </div>
        <div class="activity-description">
          {{ businessEventDto.happened | date: 'dd.MM.yyyy HH:mm' }} &bull; {{ businessEventDto.id }}
        </div>
      </div>
    </div>
  </div>
</div>
