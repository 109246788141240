<div class="ecare dashboard">
  <div class="ecare-header_container">
    <div class="ecare-header_headline">
      {{ 'wc.ecare.users.headline' | translate }}
      <div class="ecare-numberOforders">
        {{ 'wc.ecare.orders.showing.label' | translate }} {{ users.length }}
        {{ 'wc.ecare.users.users.label' | translate }}
      </div>
    </div>
    <app-users-filter
      [includeExternal]="includeExternal"
      [filtering]="search.filtering"
      (filterChanged)="handleFilterChange()" />
  </div>
  <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
    <div class="tile -tile12">
      <div class=""></div>

      <div class="dashboard_search ecare-ordersSearch">
        <table *ngIf="users.length > 0" class="ecare_listing-table orders-table table">
          <thead class="content-sm">
            <tr>
              <th>{{ 'wc.ecare.users.table.login.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.employeeId.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.firstName.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.lastName.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.externalId.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.isExternal.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.status.header' | translate }}</th>
              <th>{{ 'wc.ecare.users.table.created.header' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <app-ecare-user-row *ngFor="let user of users" [user]="user" (selectedUser)="userSelected($event)" />
          </tbody>
        </table>
        <app-ecare-search-table-mobile pickerInputType="user" [rows]="users" (selectedRow)="userSelected($event)" />

        <ng-container *ngIf="canDisplayNoUsersText">
          <app-ecare-listing-no-results />
        </ng-container>
      </div>
      <div>
        <app-ecare-pagination
          [page]="search.paging.page"
          [pageSize]="search.paging.pageSize"
          [totalCount]="pagedDto?.totalItems"
          [pagesToShow]="pagedDto?.totalPages"
          (goPrev)="onPrevPage()"
          (goNext)="onNextPage()"
          (goPage)="onSpecificPage($event)"
          (pageSizeChanged)="onPageSizeChanged($event)" />
      </div>
    </div>
  </div>
</div>
