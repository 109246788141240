import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthFactoryService, AuthService, UserInfo, WishListService } from '@btl/btl-fe-wc-common';
import { NgForm } from '@angular/forms';
import { WishListDetailDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { FormUtils } from '../../../../helpers/form-utils';

@Component({
  selector: 'app-share-wish-list-popup',
  templateUrl: './share-wish-list-popup.component.html',
})
export class ShareWishListPopupComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  // public showPopup: boolean;

  public readonly SENDER_FIELD: string = 'senderField';
  public readonly RECEIVER_FIELD: string = 'receiverField';
  public readonly RECEIVER_MESSAGE_FIELD: string = 'receiverMessageField';
  public readonly CAN_MANAGE_CHECKBOX: string = 'canManageCheckbox';

  formSubmitted: boolean = false;
  emailPattern = FormUtils.emailPattern;

  authService: AuthService;

  userInfo: UserInfo;
  userEmail = '';

  @Input()
  wishList: WishListDetailDto;
  // modal ref
  @Input() dialogRef;

  constructor(private wishListService: WishListService,
              private authFactoryService: AuthFactoryService) {
    this.authService = this.authFactoryService.getAuthService();
  }

  ngOnInit(): void {
    this.loggedUserInfo()
      .then(userInfo => {
        this.userInfo = userInfo;
        this.userEmail = userInfo ? userInfo.email : '';
      })
      .catch(reason => {
        throw new Error(`Cannot get logged user info: ${reason}`);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  shareWishList(form: NgForm) {
    this.formSubmitted = true;
    const sender = form.value[this.SENDER_FIELD];
    const receiver = form.value[this.RECEIVER_FIELD];
    const receiverMessage = form.value[this.RECEIVER_MESSAGE_FIELD];
    const canManage = form.value[this.CAN_MANAGE_CHECKBOX];
    if (!form.invalid) {
      this.wishListService.shareWishList(this.wishList.id, sender, receiver, receiverMessage, canManage);
      this.resetForm(form);
      this.dialogRef.dismiss();
    }
  }

  private resetForm(form: NgForm) {
    form.reset();
    this.formSubmitted = false;
  }

  loggedUserInfo(): Promise<UserInfo> {
    return this.authService
      .isLoggedIn()
      .then(isLoggedIn => {
        return isLoggedIn ? this.authService.getUserInfo() : null;
      })
      .catch(reason => {
        throw new Error(`Cannot check login status: ${reason}`);
      });
  }
}
