import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { OrderingWizardService, StepStatusEnum, StepTypeInfo } from '@service/ordering-wizard-service';

@Component({
  selector: 'app-ordering-wizard, [app-ordering-wizard]',
  templateUrl: './ordering-wizard.component.html',
})
export class OrderingWizardComponent implements OnInit {
  stepStatuses = StepStatusEnum;

  //region Data:

  orderingWizardStepStatuses: Array<StepStatusEnum> = new Array<StepStatusEnum>();
  stepTypesInfos: Array<StepTypeInfo>;

  //region IO:

  /**
   * A flag specifying if the ordering wizard is clickable (if the user can click the particular steps to navigate through the ordering).
   */
  @Input()
  clickable: boolean = true;

  constructor(public orderingWizardService: OrderingWizardService, private renderer: Renderer2) {}

  //region Life-cycle:

  ngOnInit() {
    this.loadSteps();
  }

  //region Helpers:

  /**
   * Load steps.
   */
  private loadSteps(): void {
    this.stepTypesInfos = Array.from(this.orderingWizardService.stepTypesInfos.values());
    this.orderingWizardStepStatuses = this.orderingWizardService.orderingWizardStepStatuses;
  }

  getWidth() {
    let count: number = 0;
    for (let i = 0; i < this.orderingWizardStepStatuses.length; i++) {
      if (
        this.stepTypesInfos[i].visibility === true &&
        this.orderingWizardService.checkStepVisibility(this.stepTypesInfos[i].scenarioStepTypeEnum)
      ) {
        count++;
      }
    }
    if (count >= 5) {
      return `${100 / count}%`;
    }
  }
}
