import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryTypeEnum, ProductFilter, UNLIMITED_PRODUCT_PAGE_SIZE } from '../../../models/product-filter';
import { ProductListingViewType } from '../product-listing/product-listing-view-type.type';
import { AssetDto, PartyDto, ProductSortAttributeDto, SortDto } from '@btl/order-bff';
import { ActivatedRoute } from '@angular/router';
import { AppBlockerService, ProductService } from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProductCustomService } from '@service/product-custom.service';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { ProductListingService } from '@service/product-listing.service';
import { Product } from 'app/models/product';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-tariff-change',
  templateUrl: './tariff-change.component.html',
})
export class TariffChangeComponent implements OnInit, OnDestroy {
  CategoryTypeEnum = CategoryTypeEnum;

  ProductListingViewType = ProductListingViewType;

  //region URL query parameters:

  static TARIFF_SPACE_ID_QUERY_PARAMETER: string = 'tariffSpaceId';

  static TARIFF_LOCATION_ID_QUERY_PARAMETER: string = 'locationId';

  static TARIFF_SOCKET_ID_QUERY_PARAMETER: string = 'socketId';

  static TARIFF_ID_QUERY_PARAMETER: string = 'tariffId';

  //region Data:

  /**
   * A filter filtering alternative tariffs. No specific criterion is set.
   */
  filter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_TARIFF,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Asc,
    },
    paging: {
      page: 1,
      pageSize: UNLIMITED_PRODUCT_PAGE_SIZE,
    },
  };

  /**
   * The ID of the tariff space of the current tariff.
   */
  tariffSpaceId: string;

  locationId: string;

  socketId: string;

  /**
   * The ID of the current tariff that should be changed.
   */
  currentTariffId: string;

  customerAccountId: string;

  /**
   * The current tariff.
   */
  currentTariff: AssetDto;
  currentUsage: AssetDto;
  private onDestroy$: Subject<void> = new Subject<void>();
  tariffs = [];

  constructor(
    private customerLocalStorageService: CustomerLocalStorageService,
    private productService: ProductService,
    private productCustomService: ProductCustomService,
    private activatedRoute: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private productListingService: ProductListingService
  ) {}

  //region Life-cycle:

  ngOnInit(): void {
    this.currentTariffId = this.activatedRoute.snapshot.queryParamMap.get(
      TariffChangeComponent.TARIFF_ID_QUERY_PARAMETER
    );
    this.tariffSpaceId = this.activatedRoute.snapshot.queryParamMap.get(
      TariffChangeComponent.TARIFF_SPACE_ID_QUERY_PARAMETER
    );
    this.locationId = this.activatedRoute.snapshot.queryParamMap.get(
      TariffChangeComponent.TARIFF_LOCATION_ID_QUERY_PARAMETER
    );
    this.socketId = this.activatedRoute.snapshot.queryParamMap.get(
      TariffChangeComponent.TARIFF_SOCKET_ID_QUERY_PARAMETER
    );
    this.customerAccountId = this.activatedRoute.snapshot.queryParamMap.get(
      OrderingWizardService.CUSTOMER_ACCOUNT_ID_QUERY_PARAMETER
    );

    this.populateCurrentTariff();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  //region Helpers:

  /**
   * Populate the current tariff by its ID and its tariff space's ID.
   */
  private populateCurrentTariff(): void {
    const currentCustomer = this.customerLocalStorageService.getCurrentCustomer();
    if (currentCustomer && currentCustomer.childParties) {
      currentCustomer.childParties.forEach((customerAccount: PartyDto) => {
        if (!this.currentTariff) {
          const currentTariffSpace = customerAccount.childParties.find(
            tariffSpace => tariffSpace.id === this.tariffSpaceId
          );
          if (currentTariffSpace && currentTariffSpace.assets) {
            this.currentTariff = currentTariffSpace.assets.find(asset => asset.id === this.currentTariffId);
            this.currentUsage = CustomerPartyUtil.getChildAssetByProductParameterWithValue(
              this.currentTariff,
              'isUsageTariff',
              'true'
            );

            this.productService
              .getTargetRelationshipProducts(this.currentTariff.product.productCode, 'TARIFF_CHANGE')
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(result => {
                const products = Array.from(result, product => product.productCode);
                if (result && result.length) {
                  const productsSubscription = this.productCustomService
                    .getProductsFromElasticByProductCodes(products, false)
                    .pipe(finalize(this.appBlockerService.unblock))
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(elasticProducts => {
                      this.tariffs = elasticProducts;
                    });
                } else {
                  this.tariffs = [];
                }
              });
          }
        }
      });
    }
  }

  changeTariff(product: Product) {
    this.productListingService.handleProductAddingToCart(
      ProductListingService.asProductAddingToCart(product),
      this.tariffSpaceId,
      this.customerAccountId,
      this.currentTariff.parentId,
      this.currentTariff.parentId
    );
  }
}
