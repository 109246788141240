import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupTreeNode, PageModule, PageModuleService } from '@btl/btl-fe-wc-common';
import { PageModuleIconMap } from '../helpers/page-module-icon-map';
import { map } from 'rxjs/operators';

export interface UserMenuItems {
  internalUserMenuItems: PageModule[];
  externalUserMenuItems: PageModule[];
  internalUserMenuMiddleItems: PageModule[];
  externalUserMenuMiddleItems: PageModule[];
  internalUserMenuBottomItems: PageModule[];
  externalUserMenuBottomItems: PageModule[];
  customerMenuItems: PageModule[];
}

/**
 * Service for calling shopping cart popup from navigation menu
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationMenuService {
  public openShoppingCartPopup: EventEmitter<void> = new EventEmitter<void>();
  public eshopMenuItemsChanged: EventEmitter<void> = new EventEmitter<void>();

  private _eshopMenuItems: GroupTreeNode[];

  getUserMenuItems(): Observable<UserMenuItems> {
    return PageModuleService.getPageModulesSortedByPriority().pipe(
      map((pageModules: PageModule[]): UserMenuItems => {
        const menuItems: UserMenuItems = {
          internalUserMenuItems: [],
          externalUserMenuItems: [],
          internalUserMenuMiddleItems: [],
          externalUserMenuMiddleItems: [],
          internalUserMenuBottomItems: [],
          externalUserMenuBottomItems: [],
          customerMenuItems: [],
        };
        pageModules.forEach(codeBook => {
          codeBook.cssClass = PageModuleIconMap.getIcon(codeBook.code);
          if (
            codeBook.dropZones.find(dropZone => dropZone === 'internalUserMenu') ||
            codeBook.dropZones.find(dropZone => dropZone === 'internalUserMenuMiddle') ||
            codeBook.dropZones.find(dropZone => dropZone === 'internalUserMenuBottom')
          ) {
            menuItems.internalUserMenuItems.push(codeBook);
          }
          if (
            codeBook.dropZones.find(dropZone => dropZone === 'externalUserMenu') ||
            codeBook.dropZones.find(dropZone => dropZone === 'externalUserMenuMiddle') ||
            codeBook.dropZones.find(dropZone => dropZone === 'externalUserMenuBottom')
          ) {
            menuItems.externalUserMenuItems.push(codeBook);
          }
          // if (codeBook.dropZones.find(dropZone => dropZone === 'internalUserMenuMiddle')) {
          //   menuItems.internalUserMenuMiddleItems.push(codeBook);
          // }
          // if (codeBook.dropZones.find(dropZone => dropZone === 'externalUserMenuMiddle')) {
          //   menuItems.externalUserMenuMiddleItems.push(codeBook);
          // }
          // if (codeBook.dropZones.find(dropZone => dropZone === 'internalUserMenuBottom')) {
          //   menuItems.internalUserMenuBottomItems.push(codeBook);
          // }
          // if (codeBook.dropZones.find(dropZone => dropZone === 'externalUserMenuBottom')) {
          //   menuItems.externalUserMenuBottomItems.push(codeBook);
          // }
          if (codeBook.dropZones.find(dropZone => dropZone === 'customerMenu')) {
            menuItems.customerMenuItems.push(codeBook);
          }
        });
        return menuItems;
      })
    );
  }

  // todo: refactor also this method once the NavigationMethodComponent is refactored
  setEshopMenuItems(navigationGroupsTree: GroupTreeNode[]) {
    this._eshopMenuItems = navigationGroupsTree;
    this.eshopMenuItemsChanged.emit();
  }

  // todo: refactor also this method once the NavigationMethodComponent is refactored
  getEshopMenuItems(): GroupTreeNode[] {
    return this._eshopMenuItems;
  }

  getProductGroupNavigationUrl(navigationGroupNode: GroupTreeNode): string {
    let seoUrl = navigationGroupNode.group.seoUrl;

    if (
      navigationGroupNode &&
      navigationGroupNode.group.groupParams.guiDisplayType &&
      navigationGroupNode.group.groupParams.guiDisplayType.startsWith('OFFER')
    ) {
      let groupNode = navigationGroupNode;
      let level = 0;
      let maxLevel = 0;
      if (groupNode.group.parentId) {
        level++;
        maxLevel = 1;
      }
      while (groupNode.children.length > 0 && level < 2) {
        groupNode = groupNode.children[0];
        if (groupNode && groupNode.group && (groupNode.children.length === 0 || level === maxLevel)) {
          seoUrl = groupNode.group.seoUrl;
        }
        level++;
      }
    }

    return `/eshop/product-listing/${seoUrl}`;
  }
}
