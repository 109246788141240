import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AbstractPageComponent,
  AuthFactoryService,
  BlockTemplateComponent,
  EnableDynamicLoading,
  PageModule,
  PageModuleService,
} from '@btl/btl-fe-wc-common';
import { PageModuleIconMap } from '../../../helpers/page-module-icon-map';
import { AccountDto } from '@btl/order-bff';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ModalFactoryService } from '@service/modal-factory.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormAgreementsComponent } from 'app/components/form-agreements/form-agreements.component';

@Component({
  selector: 'app-ecare-user-account',
  templateUrl: './ecare-user-account.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountComponent.PAGE_ID })
export class EcareUserAccountComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountComponent';
  LOGIN_PARAMETER = 'login';
  FIRSTNAME_PARAMETER = 'firstName';
  LASTNAME_PARAMETER = 'lastName';
  LOCALE_PARAMETER = 'locale';
  ROLENAME_PARAMETER = 'roleName';
  BIRTHDATE_PARAMETER = 'birthdate';

  @ViewChild('formAgreementsComponent', { static: false }) formAgreementsComponent: FormAgreementsComponent;

  pageId(): string {
    return EcareUserAccountComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  internalUserMenuItems: Array<PageModule> = [];
  externalUserMenuItems: Array<PageModule> = [];

  account: AccountDto;

  agreementsForm: FormGroup = this.formBuilder.group({
    agreements: [],
  });

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService,
    public pageModules: PageModuleService,
    private modalFactoryService: ModalFactoryService,
    private formBuilder: FormBuilder
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.account = this.authFactoryService.getAuthService().account;
    this.authFactoryService
      .getAuthService()
      .accountChange.pipe(takeUntil(this.onDestroy$))
      .subscribe(account => {
        this.account = account;
      });

    PageModuleService.getPageModulesSortedByPriority().subscribe(pageModules => {
      pageModules.forEach(codeBook => {
        codeBook.cssClass = PageModuleIconMap.getIcon(codeBook.code);
        if (codeBook.dropZones.find(dropZone => dropZone === 'internalUserAccountMenu')) {
          this.internalUserMenuItems.push(codeBook);
        }
        if (codeBook.dropZones.find(dropZone => dropZone === 'externalUserAccountMenu')) {
          this.externalUserMenuItems.push(codeBook);
        }
      });
    });
  }

  showSecurity() {
    this.modalFactoryService.securitySettingsModal()
  }


  userDetails() {
    this.modalFactoryService.userDetailsSettingsModal()
  }

  generateAccountDetails(parameter) {
    if (this.account){
      if (parameter===this.LOGIN_PARAMETER) {
        return this.account.login ? this.account.login : '-';
      }
      if (parameter===this.LOCALE_PARAMETER) {
        return this.account.locale ? this.account.locale : '-';
      }
      if (parameter===this.ROLENAME_PARAMETER) {
        return this.account.roles && this.account.roles.length ? this.account.roles[0].roleName : '-';
      }
      else {
        const nameParameter = this.account.parameters.find(i=>i.name===parameter).value;
        return nameParameter ? nameParameter : '-';
      }
    }
  }
}
