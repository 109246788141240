<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "wc.ecare.accountSettings.password" | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  <div *ngIf="response || notTheSame || noMatchPolicy" class="block-info {{response === 'ok' ? '' : 'info-alert'}}">
    <div class="main">
      <div class="icon">
        <i [inlineSVG]="response === 'error' || notTheSame || noMatchPolicy ? '/assets/svg/danger-icon.svg' : '/assets/svg/icon-check-green.svg'"></i>
      </div>
      <div class="info {{response === 'ok' ? '' : 'info-alert'}}">
        <div class="message">
          <span *ngIf="response">{{ 'wc.ecare.accountDetails.updatePasswordModal.' + (response === 'error' ? 'error' : 'success') | translate }}</span>
          <span *ngIf="notTheSame">{{'wc.ecare.accountDetails.updatePasswordModal.differentPasswords.error' | translate}}</span>
          <span *ngIf="noMatchPolicy">{{'wc.ecare.accountDetails.updatePasswordModal.notMatchingPolicy.error' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="generated-form" [formGroup]="passwordDataForm">
    <div class="col12">
      <app-input-row [translationPrefix]="'wc.ecare.account.passwordData'"
                     class="input full"
                     isPassword="true"
                     mandatory="true"
                     [parentGroup]="passwordDataForm"
                     labelKey="wc.ecare.accountSettings.currentPassword.label"
                     [fieldName]="'currentPassword'"/>
    </div>
    <div class="col12">
      <app-input-row [translationPrefix]="'wc.ecare.account.passwordData'"
                     class="input full"
                     isPassword="true"
                     mandatory="true"
                     [parentGroup]="passwordDataForm"
                     labelKey="wc.ecare.accountSettings.newPassword.label"
                     [fieldName]="'newPassword'"/>
    </div>
    <div class="col12">
      <app-input-row [translationPrefix]="'wc.ecare.account.passwordData'"
                     class="input full"
                     isPassword="true"
                     mandatory="true"
                     [parentGroup]="passwordDataForm"
                     labelKey="wc.ecare.accountSettings.confirmPassword.label"
                     [fieldName]="'confirmPassword'"/>
    </div>
  </div>
  <div class="sidebox-row-wrapper">
    <div class="sidebox-label">
      <div>
        {{'wc.ecare.accountDetails.updatePasswordModal.eightCharsMinHint' | translate}}
        <br>
        {{'wc.ecare.accountDetails.updatePasswordModal.oneNumberMinHint' | translate}}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="button-wrapper">
    <app-button
      variant="link"
      label="{{ 'wc.common.close.button' | translate }}"
      (click)="closePopup()"
    ></app-button>
    <app-button
      variant="primary"
      label="{{ 'wc.common.save.button' | translate }}"
      (click)="submitForm()"
    ></app-button>
  </div>
</div>
