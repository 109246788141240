<div class="ecare-account-settings -sub-section">
  <div class="headline">
    {{ 'wc.ecare.accountSettings.agreements' | translate }}
  </div>

  <div class="section">
    <app-form-agreements *ngIf="account" entityType="com.emeldi.ecc.be.account.dto.Account" [entityId]="account.id">
    </app-form-agreements>
  </div>
</div>
