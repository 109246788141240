<div style="display: flex; flex-direction: row">
  <input type="text" disabled [value]="getContactString()"/>
  <app-button
    variant="primary"
    iconPath="/assets/svg/edit.svg"
    label="{{'wc.common.edit.button' | translate }}"
    (click)="edit(editContact)">
  </app-button>
</div>

<ng-template #editContact let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.ecare.productConfiguration.simpleContact.heading' | translate }}
    </h4>
    <div class="modal-close" (click)="c('Close click')">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <div class="generated-form">
        <div app-input-row
             fieldName="firstName"
             translationPrefix="wc.shopping.contact"
             mainCssClass="generated-input-container"
             [parentGroup]="form">
        </div>
        <div app-input-row
             fieldName="lastName"
             translationPrefix="wc.shopping.contact"
             mainCssClass="generated-input-container"
             [parentGroup]="form">
        </div>
        <div app-input-row
             fieldName="email"
             translationPrefix="wc.shopping.contact"
             mainCssClass="generated-input-container"
             [parentGroup]="form">
        </div>
        <div app-input-row
             fieldName="phone1"
             translationPrefix="wc.shopping.contact"
             mainCssClass="generated-input-container"
             [parentGroup]="form">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div>
      <app-button
        variant="link"
        ngbAutofocus
        label="{{ 'wc.common.cancel.button' | translate }}"
        (click)="c('Close click')">
      </app-button>
      <app-button
        variant="primary"
        iconPath="/assets/svg/save.svg"
        label="{{ 'wc.ecare.productConfiguration.set.button' | translate }}"
        (click)="save(c)">
      </app-button>
    </div>
  </div>
</ng-template>
