import { Component, HostListener, Input, OnInit } from '@angular/core';
import { EcareOrderRowComponent } from './ecare-order-row.component';

@Component({
  selector: 'app-ecare-order-row-mobile',
  templateUrl: './ecare-order-row-mobile.component.html',
})
export class EcareOrderRowMobileComponent extends EcareOrderRowComponent implements OnInit {

  @Input()
  displayMode: 'default' | 'lastOderWidget' = 'default';

  ngOnInit(): void {
    super.ngOnInit();
    this.updateMaxThumbnailsToShow();
  }

  @HostListener('window:resize', []) updateMaxThumbnailsToShow() {
    const countedMax = Math.floor((this.elRef.nativeElement.offsetWidth - 100) / 50);
    if (countedMax !== this.maxThumbnailsToShow && countedMax > 0) {
      this.maxThumbnailsToShow = countedMax;
      this.thumbnailsForRow.length = 0;
      this.unshowedThumbnails = null;
      this.prepareThumbnailsFromMap();
    }
  }
}
