<div class="ecare-horizontal-swiper">
  <swiper
    [slidesPerView]="4"
    [centeredSlides]="false"
    [slidesPerGroupSkip]="1"
    [grabCursor]="false"
    [keyboard]="{ enabled: true }"
    [breakpoints]="{
      '769': {
        slidesPerView: 2,
        slidesPerGroup: 2
      }
    }"
    [scrollbar]="false"
    [navigation]="true"
    [pagination]="false">
    <ng-template swiperSlide *ngFor="let tariff of tariffs">
      <app-subscription-tile
        [class.active]="selectedTariffId === tariff.id"
        [asset]="tariff.assets[0]"
        [customerAccount]="customerAccount"
        [customer]="customer"
        [isBusiness]="true"
        [tariffSpace]="tariff"
        (selectedTariff)="selectTariff($event)">
      </app-subscription-tile>
    </ng-template>
  </swiper>
</div>
