<div class="modal-header callMeBackHeader">
  <div class="callMe-title">
    {{ 'wc.shopping.callMeBack.header' | translate }}
  </div>
  <div class="callMe-description">
    {{ 'wc.shopping.callMeBack.text' | translate }}
  </div>
</div>
<div class="modal-body callMeBackBody">
  <form class="form" [formGroup]="form">
    <app-input-row
      class="callMeBack-input"
      fieldName="phoneNumber"
      translationPrefix="wc.shopping.contact"
      [parentGroup]="form"
      [showLabel]="false"
      [placeholder]="'wc.shopping.contact.phoneNumber.label' | translate">
    </app-input-row>
  </form>
  <app-button
    size="fullWidth"
    label="{{ 'wc.shopping.callMeBack.button' | translate }}"
    (click)="callMeBack()"></app-button>
  <div class="callMe-information">
    <i [inlineSVG]="'/assets/svg/shieldCheck.svg'"></i>
    <div>{{ 'wc.shopping.callMeBack.number.marketingPurposes' | translate }}</div>
  </div>
</div>
