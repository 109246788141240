import { Component, Input, Renderer2 } from '@angular/core';

export type ServiceUnitsIconType = 'internet_icon' | 'sms_icon' | 'minutes_icon';
export type ServiceUnitsType = 'GB' | 'MB' | 'SMS' | 'min';

export interface ServiceUnitsData {
  packageName: string;
  usedAmount: number;
  remainingAmount: number;
  units: ServiceUnitsType;
  iconType: ServiceUnitsIconType;
}

@Component({
  selector: 'app-tariff-shared-units-tile',
  templateUrl: './ecare-tariff-shared-units-tile.component.html',
})
export class EcareTariffSharedUnitsTileComponent {
  @Input()
  unitsData: ServiceUnitsData;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'ecare');
  }
}
