import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BlockTemplateComponent, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { Product } from 'app/models/product';
import { ProductCustomService } from '@service/product-custom.service';
import { Subscription } from 'rxjs';
import { ProductDetailDto } from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-product-carousel,[app-product-carousel]',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})
export class ProductCarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label?: string;
  @Input() description: string;
  @Input() layoutClass: string;
  @Input() productDetailDtos: Array<ProductDetailDto | Product>;
  @Input() count: number;
  @Input() nextPageAllowed: boolean = false;
  public page: number = 1;
  public transformedProductDetails: Array<Product>;
  private productsSubscription: Subscription;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  constructor(private currentLocaleService: CurrentLocaleService, private productCustomService: ProductCustomService) {}

  ngOnInit() {
    this.transformProducts();
  }

  private transformProducts() {
    let elasticProducts = false;
    if (this.productDetailDtos && this.productDetailDtos.length > 0) {
      if (this.productDetailDtos[0]['parameters']) {
        elasticProducts = true;
        this.transformedProductDetails = [];
        this.productDetailDtos.forEach(productDetailDto => {
          this.transformedProductDetails.push(<Product>productDetailDto);
        });
      }
    }

    if (!elasticProducts) {
      const products = this.productDetailDtos.map((p: ProductDetailDto) => p.productCode);
      if (this.productDetailDtos && this.productDetailDtos.length > 0) {
        if (this.productsSubscription) {
          this.productsSubscription.unsubscribe();
        }
        this.productsSubscription = this.productCustomService
          .getProductsFromElasticByProductCodes(products, true)
          .subscribe(elasticProducts => {
            this.transformedProductDetails = elasticProducts;
          });
      } else {
        this.transformedProductDetails = [];
      }
    }
  }

  ngOnDestroy(): void {
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.transformProducts();
  }

  public getPaginatedProducts(): Array<Product> {
    if (!this.count) {
      return this.transformedProductDetails;
    } else {
      return this.transformedProductDetails.slice(0, this.count * this.page);
    }
  }

  private nextPage() {
    this.page++;
  }
}
