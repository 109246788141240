import { Component, Input } from '@angular/core';
import { WcOrderingService } from '@service/wc-ordering.service';
import { Router } from '@angular/router';
import { OrderDto } from '@btl/order-bff';
import { ScenarioTypeEnum } from '../../../helpers/order-utils';
import { TicketService } from '@service/ticket.service';

@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
})
export class CancelOrderComponent {
  @Input() dialogRef;

  constructor(private router: Router, private orderingService: WcOrderingService) {}

  /**
   * Cancel the current order and navigate to the homepage.
   */
  cancelOrder() {
    TicketService.removeLocalOpportunity();
    this.orderingService.getCurrentOrder().subscribe((order: OrderDto) => {
      if (order) {
        if (order.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT) {
          const opportunity = TicketService.getLocalOpportunity();
          TicketService.removeLocalOpportunity();
          this.orderingService.removeCurrentOrderFromContext();
          this.dialogRef.dismiss();
          this.router.navigate(['/ecare/opportunities/', opportunity.id]);
        } else {
          this.orderingService.cancelOrder(order).subscribe(() => {
            this.dialogRef.dismiss();
            // Create new order
            this.orderingService.getCurrentOrder().subscribe(() => {
              this.router.navigate(['/']);
            });
          });
        }
      }
    });
  }
}
