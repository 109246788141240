import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { DefaultErrorHandler, ErrorOnEnum, ErrorService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { OrderDto } from '@btl/order-bff';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from '../../helpers/order-utils';

@Injectable()
export class OrderErrorHandler extends DefaultErrorHandler {
  orderingService: WcOrderingService;

  constructor(
    protected logger: NGXLogger,
    protected router: Router,
    protected stickyMessageService: StickyMessageService,
    protected errorService: ErrorService,
  ) {
    super(logger, router, stickyMessageService, errorService);
  }

  handleError(message: string, error: any, errorOn?: ErrorOnEnum) {
    this.logger.error(message, error);
    this.errorService.errors.push({ error: error, message: message });
    if (error.status === 409) {
      const newOrderDto: OrderDto = OrderUtils.getInitOrder(
        ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
        ScenarioTypeEnum.PRODUCT_MANAGEMENT
      );
      this.orderingService.createOrder(newOrderDto).subscribe(orderDto => {
        return orderDto;
      });
    }

    if (errorOn === ErrorOnEnum.ORDER_CONFIRMATION) {
      const respFailure = error.error?.failures[0];
      this.stickyMessageService.closeAllMessages();
      this.stickyMessageService.addStickyErrorMessage(
        respFailure?.contextData?.errorCode || 'wc.common.error'
      );
    } else {
      this.router.navigate(['/error'], {
        skipLocationChange: true,
        queryParams: {
          errorOn: errorOn,
        },
      });
    }
  }
}
