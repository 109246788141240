<table class="tariff_table">
  <tr *ngIf="customerAccount.displayName" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.customName.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.displayName }}</td>
  </tr>
  <tr app-acl-internal-evaluate-component class="tariff_row">
    <td>{{ 'wc.ecare.orderSearch.state.label' | translate }}</td>
    <td>{{ customerAccount.state }}</td>
    <td>{{ 'wc.ecare.dashboard.identification.label' | translate }}: {{ customerAccount.id }}</td>
  </tr>
  <ng-container *ngIf="customerAccount.parameters['paymentResponsible']">
    <tr app-acl-internal-evaluate-component class="tariff_row">
      <td>{{ 'wc.ecare.dashboard.paymentType.label' | translate }}</td>
      <td colspan="2">{{ customerAccount.parameters['paymentType'] }}</td>
    </tr>
    <tr class="tariff_row">
      <td>{{ 'wc.ecare.dashboard.actualBalance.label' | translate }}</td>
      <td>
        <one-time-price *ngIf="openBalanceResult" [price]="openBalanceResult.openAmount"></one-time-price>
      </td>
      <td *ngIf="customerAccount.parameters['paymentType'] === PaymentTypeEnum.POSTPAID" colspan="2">
        <app-acl-evaluate privilege="PAY_TELCO_BILL">
          <button id="001_customer_account_detail" class="btn" (click)="goToFinancialDocuments()">
            {{ 'wc.ecare.dashboard.financialDocument.button.label' | translate }}
          </button>
        </app-acl-evaluate>
      </td>
    </tr>
    <tr *ngIf="customerAccount.parameters['paymentType'] === PaymentTypeEnum.POSTPAID" class="tariff_row">
      <td>{{ 'wc.ecare.dashboard.overdueAmount.label' | translate }}</td>
      <td colspan="2">
        <one-time-price *ngIf="openBalanceResult" [price]="openBalanceResult.dueAmount"></one-time-price>
      </td>
    </tr>
  </ng-container>
  <tr *ngIf="codebookService.getCodebookTexts('BILL_MEDIUM') | async as codebook" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.invoiceDeliveryMethod.label' | translate }}</td>
    <td colspan="2">{{ codebook[customerAccount.parameters['billMedium']] }}</td>
  </tr>
  <tr class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.paymentMethod.label' | translate }}</td>
    <td colspan="2">
      {{
        'wc.shopping.customerAccount.paymentMethod.' + customerAccount.parameters['paymentMethod'] + '.label'
          | translate
      }}
    </td>
  </tr>
  <tr *ngIf="customerAccount.parameters['paymentMethod'] === PaymentMethodEnum.BANK_TRANSFER" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.bankNumber.label' | translate }}</td>
    <td colspan="2">
      <ng-container *ngIf="codebookService.getCodebookTexts('BANK_CODE') | async as codebook">
        {{ customerAccount.parameters['bankAccountPrefix'] ? customerAccount.parameters['bankAccountPrefix'] + '-' : ''
        }}{{ customerAccount.parameters['bankAccount'] }} / {{ codebook[customerAccount.parameters['bankCode']] }}
      </ng-container>
    </td>
  </tr>
  <tr *ngIf="customerAccount.parameters['paymentMethod'] === PaymentMethodEnum.DIRECT_DEBIT" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.bankNumber.label' | translate }}</td>
    <td colspan="2">
      <ng-container *ngIf="codebookService.getCodebookTexts('BANK_CODE') | async as codebook">
        {{ customerAccount.parameters['bankAccountPrefix'] ? customerAccount.parameters['bankAccountPrefix'] + '-' : ''
        }}{{ customerAccount.parameters['bankAccount'] }} / {{ codebook[customerAccount.parameters['bankCode']] }}
      </ng-container>
    </td>
  </tr>
  <tr *ngIf="customerAccount.parameters['paymentMethod'] === PaymentMethodEnum.DIRECT_DEBIT" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.directDebitLimit.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.parameters['directDebitLimit'] }}</td>
  </tr>
  <tr *ngIf="customerAccount.parameters['variableSymbol']" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.variableSymbol.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.parameters['variableSymbol'] }}</td>
  </tr>
  <tr *ngIf="customerAccount.parameters['specificSymbol']" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.specificSymbol.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.parameters['specificSymbol'] }}</td>
  </tr>
  <tr *ngIf="customerAccount.parameters['billCycle']" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.billingCycle.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.parameters['billCycle'] }}</td>
  </tr>
  <tr *ngIf="customerAccount.parameters['dueDate']" app-acl-internal-evaluate-component class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.dueDate.label' | translate }}</td>
    <td colspan="2">{{ customerAccount.parameters['dueDate'] }}</td>
  </tr>
  <tr
    *ngIf="customerAccount.billingContact && customerAccount.billingContact.preferredChannel !== 'NONE'"
    class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.billingContactPreferredChannel.label' | translate }}</td>
    <td colspan="2">
      <label
        *ngIf="
          customerAccount.billingContact.preferredChannel === 'SMS' ||
          customerAccount.billingContact.preferredChannel === 'EMAIL'
        ">
        {{ customerAccount.billingContact.preferredChannel }}:
        <span *ngIf="customerAccount.billingContact.preferredChannel === 'SMS'"
          >{{ customerAccount.billingContact.phone1 | phoneNumber }}
        </span>
        <span *ngIf="customerAccount.billingContact.preferredChannel === 'EMAIL'"
          >{{ customerAccount.billingContact.email }}
        </span>
      </label>
      <label *ngIf="customerAccount.billingContact.preferredChannel === 'ALL'">
        SMS: {{ customerAccount.billingContact.phone1 | phoneNumber }}, EMAIL:
        {{ customerAccount.billingContact.email }}
      </label>
    </td>
  </tr>
  <tr class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.invoiceAddress.label' | translate }}</td>
    <td colspan="2">
      <label>
        <ng-container *ngIf="invoiceAddress; else sameAsCustomer">
          {{ invoiceAddress | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
        </ng-container>
        <ng-template #sameAsCustomer>
          {{ 'wc.ecare.dashboard.invoiceAddress.sameAsCustomer.label' | translate }}
        </ng-template>
      </label>
    </td>
  </tr>
  <tr *ngIf="billingContact" class="tariff_row">
    <td>{{ 'wc.ecare.dashboard.billingContact.label' | translate }}</td>
    <td colspan="2">
      <label [innerHTML]="billingContact | displayContact: billingContact.preferredChannel"> </label>
    </td>
  </tr>
  <tr class="tariff_row last">
    <ng-container *ngIf="invoicingContact">
      <td>{{ 'wc.ecare.dashboard.invoiceContact.label' | translate }}</td>
      <td colspan="2">
        <label [innerHTML]="invoicingContact | displayContact: invoicingContact.preferredChannel"> </label>
      </td>
    </ng-container>
  </tr>
</table>
