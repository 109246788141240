import { Component, Input, OnInit } from '@angular/core';
import { AclService } from '@btl/btl-fe-wc-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-button, [app-button]',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() label?: string;
  @Input() noPrivilegeToolTip?: string;
  @Input() link?: string;
  @Input() variant?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'danger' | 'whiteWithBorder' | 'light' | 'dark';
  @Input() iconPath?;
  @Input() iconPathAfter?;
  @Input() size?: 'small' | 'medium' | 'large' | 'shrink' | 'fullWidth';
  @Input() buttonNewTab?: boolean;
  @Input() disabled?: boolean;
  @Input() buttonType?: 'button' | 'reset' | 'submit';
  @Input() privilege;

  noPrivilege = false;

  constructor(private aclService: AclService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.privilege) {
      this.aclService.evaluatePrivilege(this.privilege).subscribe(evaluationResult => {
        this.noPrivilege = this.disabled = !(evaluationResult && evaluationResult.canAccessResource);
      });
    }
  }

  clicked($event) {
    if (this.disabled) {
      $event.stopPropagation();
    }
  }
}
