import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProductCustomService } from '@service/product-custom.service';
import { AppBlockerService, CmsWidgetProductsInRowBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { Product } from '../../../../models/product';

@Component({
  selector: '[app-cms-widget-products-in-row]',
  templateUrl: './cms-widget-products-in-row.component.html',
})
@EnableDynamicLoading({ customName: 'PRODUCT_LIST' })
export class CmsWidgetProductsInRowComponent extends CmsWidgetProductsInRowBaseComponent implements OnInit, OnDestroy {
  products: Array<Product>;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private appBlockerService: AppBlockerService, private productCustomService: ProductCustomService) {
    super();
  }

  ngOnInit(): void {
    const productCodes = this.productsInRowMeta.map(meta => meta.productCode);
    this.productCustomService
      .getProductsFromElasticByProductCodes(productCodes, true)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(elseProducts => {
        this.products = elseProducts;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
