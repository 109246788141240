import { Component } from '@angular/core';
import { CmsWidgetBannerBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ContentDto, PagedContentsDto } from '@btl/order-bff';

@Component({
  selector: '[app-cms-widget-banner]',
  templateUrl: './cms-widget-banner.component.html',
})
@EnableDynamicLoading({ customName: 'BANNER' })
export class CmsWidgetBannerComponent extends CmsWidgetBannerBaseComponent {
  handleContents(pagedContents: PagedContentsDto) {
    super.handleContents(pagedContents);

    // todo: move the following code to wc-common (CmsWidgetBannerBaseComponent)
    this.renderingContentConfigs.forEach(bannerContent => {
      const bannerTargeter = this.getBannerTargetArea(bannerContent);
      if (bannerTargeter) {
        bannerContent.contentVersion.widgets.forEach(bannerWidget => {
          bannerWidget['targetArea'] = bannerTargeter;
        });
      }
    });
  }

  // todo: jjaros - move this function to wc-common (CmsWidgetBannerBaseComponent)
  getBannerTargetArea(banner: ContentDto): string {
    return banner.contentMaster.keys.find(key => key.keyId === 'targetArea')?.keyValue;
  }
}
