<div *ngIf="wishList" class="wish-list-form-row" [ngClass]="wishListItem ? 'selected' : ''">
  <label class="wish-list-item-container" for="wish-list-{{ id }}">
    {{ wishList.name }} ({{ wishList.productCount }})
    <input
      type="checkbox"
      id="wish-list-{{ id }}"
      [checked]="wishListItem"
      (change)="toggleItemInWishList(wishList.id, $event)" />
    <span class="wish-list-checkmark"></span>
  </label>
  <a class="wish-list-detail-link" [routerLink]="['/eshop/wish-lists', wishList.id]">View list</a>
</div>
