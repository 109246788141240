import { Component } from '@angular/core';
import { CmsWidgetPageImageBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-page-image]',
  templateUrl: './cms-widget-page-image.component.html',
  styleUrls: ['./cms-widget-page-image.component.scss'],
})
@EnableDynamicLoading({ customName: 'PAGE_IMAGE' })
export class CmsWidgetPageImageComponent extends CmsWidgetPageImageBaseComponent {
  getBackgroundImage() {
    return window.screen.width > 768 || !this.backgroundImageMobile ? this.backgroundImage : this.backgroundImageMobile;
  }
}
