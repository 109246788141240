<div class="time-slot-chips">
  <app-section-header
    id="collapseHeader"
    [title]="
      (!locationId ? 'wc.shopping.checkout.timeslot.title' : 'wc.shopping.checkout.installation.timeslot.title')
        | translate
    "
    [isCollapsed]="refEl.activeIds.length > 0"
    (click)="acc.toggle('timeSlotChips')" />
  <ngb-accordion #acc="ngbAccordion" #refEl activeIds="timeSlotChips" [destroyOnHide]="false">
    <ngb-panel id="timeSlotChips">
      <ng-template ngbPanelContent>
        <div class="timeSlot-form" [formGroup]="form">
          <div
            *ngFor="let timeSlotArea of this.slots().controls; let slotAreaIndex = index"
            formArrayName="slots"
            class="time-slot-item">
            <ng-container [formGroup]="timeSlotArea">
              <div class="dates-wrapper">
                <label>{{ 'wc.shopping.checkout.timeslot.label' | translate }} {{ slotAreaIndex + 1 }}</label>
                <mat-form-field floatLabel="never">
                  <span class="placeholder">{{ 'wc.shopping.checkout.timeslot.date.text' | translate }}</span>
                  <div
                    class="time-slot-wrapper"
                    ngbTooltip="{{
                      totalTimeSlots >= numberOfSlots
                        ? ('wc.shopping.checkout.timeslot.maximum.tooltip' | translate)
                        : ''
                    }}"
                    (click)="picker.open()">
                    <mat-chip-grid #chipList formArrayName="slotDates">
                      <mat-chip-row
                        *ngFor="let value of timeSlotArea.get('slotDates').value; let slotDateIndex = index"
                        [removable]="true"
                        (click)="$event.stopPropagation()"
                        (removed)="removeChip(value)">
                        {{ value | date }}
                        <i matChipRemove [inlineSVG]="'assets/svg/closeModal.svg'"></i>
                      </mat-chip-row>
                      <input
                        matInput
                        hidden
                        [disabled]="totalTimeSlots >= numberOfSlots ? 'true' : 'false'"
                        [min]="minDate"
                        [max]="maxDate"
                        [value]="resetModel"
                        [matDatepicker]="picker"
                        [matChipInputFor]="chipList"
                        (dateChange)="dateChanged($event, timeSlotArea, slotAreaIndex)" />
                    </mat-chip-grid>
                    <mat-datepicker-toggle matPrefix [for]="picker">
                      <i matDatepickerToggleIcon [inlineSVG]="'assets/img/ecare/calendar.svg'"></i>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker [startAt]="init" [dateClass]="dateClass" />
                  </div>
                </mat-form-field>
              </div>
              <ng-container>
                <div class="input-wrapper">
                  <label>{{ 'wc.shopping.checkout.timeslot.availableFrom.label' | translate }}</label>
                  <div class="time-inputs-wrapper">
                    <input
                      type="text"
                      maxlength="2"
                      inputmode="numeric"
                      pattern="\d*"
                      formControlName="timeHoursFrom"
                      [id]="'timeHoursFrom' + slotAreaIndex" />
                    <span class="colon">:</span>
                    <input
                      type="text"
                      maxlength="2"
                      inputmode="numeric"
                      pattern="\d*"
                      formControlName="timeMinutesFrom"
                      [id]="'timeMinutesFrom' + slotAreaIndex" />
                    <span class="long-dash">—</span>
                  </div>
                </div>
                <div class="input-wrapper">
                  <label>{{ 'wc.shopping.checkout.timeslot.availableUntil.label' | translate }}</label>
                  <div class="time-inputs-wrapper">
                    <input
                      type="text"
                      maxlength="2"
                      inputmode="numeric"
                      pattern="\d*"
                      formControlName="timeHoursTo"
                      [id]="'timeHoursTo' + slotAreaIndex" />
                    <span class="colon">:</span>
                    <input
                      type="text"
                      maxlength="2"
                      inputmode="numeric"
                      pattern="\d*"
                      formControlName="timeMinutesTo"
                      [id]="'timeMinutesTo' + slotAreaIndex" />
                    <a class="timeslot-remove" (click)="removeTimeslotArea(slotAreaIndex)">
                      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
                    </a>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="timeSlotArea.errors?.invalid" class="timeslot-error">
              {{ 'wc.shopping.checkout.timeslot.time.error' | translate }}
            </div>
          </div>
          <app-input-errors [control]="control" [translationPrefix]="'wc.shopping.checkout.timeslot.error'" />
          <div
            app-button
            class="new-timeslot"
            variant="secondary"
            iconPath="/assets/img/ecare/feather-plus.svg"
            [label]="'wc.shopping.checkout.timeslot.new.label' | translate"
            ngbTooltip="{{
              totalTimeSlots >= numberOfSlots ? ('wc.shopping.checkout.timeslot.maximum.tooltip' | translate) : ''
            }}"
            [disabled]="totalTimeSlots >= numberOfSlots"
            (click)="totalTimeSlots >= numberOfSlots ? null : newTimeslotArea()"></div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
