<div class="account-selection">
  <div class="account-selection_info">
    <div class="icon-container">
      <i [inlineSVG]="'/assets/svg/log-in.svg'"></i>
    </div>
    {{ 'wc.shopping.checkout.loginBenefit.heading' | translate }}
    <i
      tooltipClass="loginBenefits"
      placement="bottom"
      triggers="mouseenter:mouseleave"
      [inlineSVG]="'assets/svg/awesome-info-circle.svg'"
      [ngbPopover]="popContent"></i>
    <ng-template #popContent>
      {{ 'wc.shopping.checkout.loginBenefit.label.info' | translate }}
      <div class="tooltip-row">
        <i [inlineSVG]="'assets/svg/login-benefits/fast-order.svg'"></i
        >{{ 'wc.shopping.checkout.loginBenefit.label.fastOrder' | translate }}
      </div>
      <div class="tooltip-row">
        <i [inlineSVG]="'assets/svg/login-benefits/previous-orders.svg'"></i
        >{{ 'wc.shopping.checkout.loginBenefit.label.previousOrders' | translate }}
      </div>
      <div class="tooltip-row">
        <i [inlineSVG]="'assets/svg/login-benefits/manage-account.svg'"></i
        >{{ 'wc.shopping.checkout.loginBenefit.label.manageAccount' | translate }}
      </div>
    </ng-template>
  </div>
  <div class="account-selection_buttons">
    <app-button
      variant="primary"
      size="shrink"
      label="{{ 'wc.shopping.checkout.loginToAccount.button' | translate }}"
      (click)="loginKeyCloak()">
    </app-button>
  </div>
</div>
