import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner
} from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-contact-history-filter',
  templateUrl: './contact-history-filter.component.html',
})
export class ContactHistoryFilterComponent extends ComplexFilter {
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  protected advancedFilterFields = ['relatedTo', 'createdDateFrom', 'createdDateTo', 'paramName', 'paramValue'];

  filterForm = this.formBuilder.group({
    id: [],
    createdDateFrom: [],
    createdDateTo: [],
    //relatedTo: [],
    paramName: [],
    paramValue: [],
    //relatedToType: ['com.emeldi.ecc.be.crm.dto.Customer'],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),

    new ControlFilterMiner(this.filterForm.controls.createdDateFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdDateTo, 'created', CompareTypeDtoEnum.SMALLEREQ),

    // new ControlStructSingletonFilterMiner(this.filterForm.controls.relatedTo, 'references', 'entityId', null),
    // new ControlStructSingletonFilterMiner(this.filterForm.controls.relatedToType, 'references', 'entityTypeId', null),

    new ControlStructSingletonFilterMiner(this.filterForm.controls.paramName, 'parameters', 'name', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.paramValue, 'parameters', 'value', null),
  ];

  constructor(
    private formBuilder: FormBuilder,
    public readonly customerLocalStorageService: CustomerLocalStorageService
  ) {
    super();
  }

  protected getDefaultFilterValues() {
    return {
      createdDateFrom: '',
      createdDateTo: '',
      paramName: '',
      paramValue: ''
    };
  }
}
