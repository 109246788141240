import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIService } from 'ng-block-ui';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
  /**
   * The localization key of the dialog heading.
   */
  @Input()
  heading: string;

  /**
   * A list of localization keys of texts of the dialog body.
   */
  @Input()
  texts: string[] = [];

  /**
   * Any object containing parameters for all localized text in the dialog.
   */
  @Input()
  localizationParameters: any;

  /**
   * The dialog reference.
   */
  @Input()
  dialogReference: NgbModalRef;

  /**
   * A handler handling user's click to Confirm in the dialog.
   */
  @Input()
  confirmationHandler: (dialogReference: NgbModalRef) => void;

  /**
   * A handler handling user's click to Cancel in the dialog.
   */
  @Input()
  cancellationHandler: (dialogReference: NgbModalRef) => void;

  /**
   * A flag specifying if Confirm is disabled to be clicked in the dialog.
   */
  @Input()
  confirmationDisabled: boolean;

  /**
   * A flag specifying if Cancel is disabled to be clicked in the dialog.
   */
  @Input()
  cancellationDisabled: boolean;

  @Input()
  confirmationLocalizedKey: string;

  @Input()
  confirmationIconPath: string;

  @Input()
  cancelLocalizedKey: string;

  @Input()
  cancellationVisible = true;

  constructor(protected blockUIService: BlockUIService) {}

  //region Life-cycle:

  /**
   * Get name of UI block element.
   */
  get confirmationDialogBlockUiElementName() {
    return this.heading;
  }

  //region Helpers:

  ngOnInit(): void {
    this.validateInputs();
  }

  /**
   * Start progress of processing. It displays progress bar and makes Confirm disabled.
   */
  startProgress(): void {
    this.confirmationDisabled = true;
    this.blockUIService.start(this.confirmationDialogBlockUiElementName);
  }

  /**
   * Stop progress of processing. It hides progress bar and makes Confirm enabled.
   */
  stopProgress(): void {
    this.blockUIService.stop(this.confirmationDialogBlockUiElementName);
    this.confirmationDisabled = false;
  }

  /**
   * Validate component inputs.
   */
  protected validateInputs(): void {
    if (!this.heading) {
      throw new Error('The heading parameter must have non-null value.');
      this.stopProgress();
    }
    if (!this.dialogReference) {
      throw new Error('The dialogReference parameter must have non-null value.');
      this.stopProgress();
    }
    if (!this.confirmationHandler) {
      throw new Error('The confirmationHandler parameter must have non-null value.');
      this.stopProgress();
    }
  }
}
