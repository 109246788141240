import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { timer } from 'rxjs/internal/observable/timer';
import { map, takeUntil, takeWhile } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AuthFactoryService, getCurrentHost } from '@btl/btl-fe-wc-common';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from '../components/page/message-dialog/message-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceSessionService implements OnDestroy {
  static readonly WARNING_BEFORE_TIMEOUT: number = 5;
  private readonly WARNING_MODAL_HEADER_KEY = 'wc.common.sessionExpireWarning.heading';
  private readonly WARNING_MODAL_TEXT_KEY = 'wc.common.sessionExpireWarning.text';

  public sessionWarningTimer$;

  public modalCountDown$;
  private sessionWarningUnSubscribe$ = new Subject<void>();
  private sessionUnSubscribe$ = new Subject<void>();

  warningPopUp;

  lastUserAction: Date;

  constructor(
    private modal: NgbModal,
    private router: Router,
    private datePipe: DatePipe,
    private authFactoryService: AuthFactoryService,
    private translateService: TranslateService,
    private orderingService: WcOrderingService,
    private customerLocalStorageService: CustomerLocalStorageService
  ) {
    authFactoryService.getAuthService().accountChange.subscribe(() => {
      this.restartSessionTimeout();
    });
    window.addEventListener('mousemove', () => {
      this.userAction();
    });
    window.addEventListener('keydown', () => {
      this.userAction();
    });
  }

  userAction() {
    const actionDate = new Date();
    if (!this.lastUserAction) {
      this.lastUserAction = actionDate;
    }

    //Update token on user action not often then every minute
    if (
      this.authFactoryService.getAuthService().account &&
      (!this.lastUserAction || Math.round((actionDate.valueOf() - this.lastUserAction.valueOf()) / 1000) > 60)
    ) {
      if (this.sessionWarningTimer$) {
        this.sessionWarningTimer$.unsubscribe();
      }
      this.authFactoryService
        .getAuthService()
        .updateToken()
        .then(updated => {
          if (updated) {
            this.restartSessionTimeout();
            this.closeWarningPopup();
          } else {
            this.cleanSession();
            this.closeWarningPopup();
            console.warn('Session expired. All session data was flushed');
          }
        });
      this.lastUserAction = new Date(actionDate.valueOf());
    }
  }

  closeWarningPopup() {
    if (this.warningPopUp) {
      this.warningPopUp.dialogRef.dismiss();
      this.warningPopUp = null;
      this.finishSubscriptions();
    }
  }

  restartSessionTimeout() {
    // For universal SSR do not refresh session
    // @ts-ignore
    if (typeof process !== 'undefined' && typeof process.versions.node !== 'undefined') {
      return;
    }

    this.sessionWarningUnSubscribe$.next();
    this.sessionUnSubscribe$.next();
    this.authFactoryService
      .getAuthService()
      .isLoggedIn()
      .then(isLogged => {
        const authenticationIdleTimeout = this.authFactoryService.getAuthService().getRefreshTokenExp();
        const warningIdleTimeout = new Date(authenticationIdleTimeout?.valueOf());
        warningIdleTimeout.setMinutes(
          warningIdleTimeout.getMinutes() - AuthServiceSessionService.WARNING_BEFORE_TIMEOUT
        );
        if (isLogged && authenticationIdleTimeout && authenticationIdleTimeout > warningIdleTimeout) {
          this.sessionWarningTimer$ = timer(warningIdleTimeout)
            .pipe(takeUntil(this.sessionWarningUnSubscribe$))
            .subscribe(() => {
              if (this.warningPopUp) {
                this.closeWarningPopup();
              }
              console.warn('warning session expire');
              const modalRef = this.modal.open(MessageDialogComponent, {
                size: 'sm',
                backdrop: 'static',
                windowClass: 'dialog'
              });
              this.warningPopUp = modalRef.componentInstance;
              this.warningPopUp.dialogRef = modalRef;
              this.warningPopUp.headerTranslationKey = this.WARNING_MODAL_HEADER_KEY;
              this.warningPopUp.messageTranslationEnable = false;
              this.warningPopUp.closeButtonHandler = () => {
                this.closeWarningPopup();
              };
              const remainingTime = Math.round((authenticationIdleTimeout.valueOf() - new Date().valueOf()) / 1000);
              this.modalCountDown$ = timer(0, 1000)
                .pipe(takeUntil(this.sessionUnSubscribe$))
                .pipe(
                  map(n => (remainingTime - n) * 1000),
                  takeWhile(n => n >= 0)
                )
                .subscribe(n => {
                  if (this.warningPopUp) {
                    const sessionExpireWarning = this.translateService.instant(this.WARNING_MODAL_TEXT_KEY);
                    this.warningPopUp.messageTranslationKey = `${sessionExpireWarning} ${this.datePipe.transform(
                      n,
                      'HH:mm:ss',
                      'UTC+0'
                    )}`;
                  }
                });
            });
        } else {
          if (this.sessionWarningTimer$) {
            this.sessionWarningTimer$.unsubscribe();
          }
        }
      });
  }

  cleanSession() {
    window.sessionStorage.clear();
    this.authFactoryService.getAuthService().logout(getCurrentHost());
    this.customerLocalStorageService.removeCurrentCustomerFromContext();
    this.orderingService.removeCurrentOrderFromContext();
  }

  finishSubscriptions() {
    this.sessionWarningUnSubscribe$.next();
    this.sessionWarningUnSubscribe$.complete();

    this.sessionUnSubscribe$.next();
    this.sessionUnSubscribe$.complete();
  }

  ngOnDestroy() {
    this.finishSubscriptions();
  }
}
