import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalScriptsService } from '@btl/btl-fe-wc-common';
import { EmailContentSharingModalComponent } from './email-content-sharing/email-content-sharing-modal.component';
import { ViberContentSharingModalComponent } from './viber-content-sharing/viber-content-sharing-modal.component';
import { SocialNetworksService } from '@service/social-networks.service';

export declare type TwitterButtonSize = '';

/**
 * see: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/parameter-reference1
 */
export interface TwitterButtonConfig {
  /**
   * Button size
   */
  buttonSize?: 'default' | 'large';
  /**
   * Pre-populated text highlighted in the Tweet composer.
   */
  text?: string;
  /**
   * URL included with the Tweet.
   */
  url?: string;
  /**
   * A list of hashtags to be appended to default Tweet text.
   */
  hashtags?: string[];
  /**
   * Attribute the source of a Tweet to a Twitter username.
   */
  via?: string;
  /**
   * A list of accounts related to the content of the shared URI.
   */
  related?: string[];
}

/**
 * see: https://developers.facebook.com/docs/plugins/share-button/
 */
export interface FacebookButtonConfig {
  /**
   * The absolute URL of the page that will be shared.
   */
  href?: string;

  /**
   * 'true' means use the browser's lazy-loading mechanism by setting the loading="lazy" iframe attribute.
   * The effect is that the browser does not render the plugin if it's not close to the viewport
   * and might never be seen.
   */
  lazy?: boolean;

  /**
   * Selects one of the different layouts that are available for the plugin.
   * Can be one of box_count, button_count, button or icon_link (default).
   */
  layout?: 'icon_link' | 'box_count' | 'button_count' | 'button';

  /**
   * The button is offered in 2 sizes i.e. 'large' and 'small'.
   */
  size?: 'small' | 'large';
}

@Component({
  selector: 'app-content-share',
  templateUrl: './content-share-icons.component.html',
})
export class ContentShareIconsComponent {
  @Input() twitterButtonConfig?: TwitterButtonConfig;

  @Input() facebookButtonConfig?: FacebookButtonConfig;

  @Input() allowEmailSharing: boolean;

  @Input() contentSeoUrl: string;

  @Input() url;

  constructor(
    private externalScriptsService: ExternalScriptsService,
    private socialNetworksService: SocialNetworksService,
    private ngbModal: NgbModal
  ) {
    socialNetworksService.activateFacebook();
    socialNetworksService.activateTwitter();
  }

  openEmailContentSharingModal() {
    const modalRef = this.ngbModal.open(EmailContentSharingModalComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
      backdrop: 'static',
      keyboard: false,
    });
    const emailContentSharingModalComponent = <EmailContentSharingModalComponent>modalRef.componentInstance;
    emailContentSharingModalComponent.contentSeoUrl = this.contentSeoUrl;
    emailContentSharingModalComponent.dialogRef = modalRef;
  }

  openViberContentSharingModal() {
    const modalRef = this.ngbModal.open(ViberContentSharingModalComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
      backdrop: 'static',
      keyboard: false,
    });
    const viberContentSharingModalComponent = <ViberContentSharingModalComponent>modalRef.componentInstance;
    viberContentSharingModalComponent.url = this.url;
    viberContentSharingModalComponent.dialogRef = modalRef;
  }

  /**
   * An event fired when the dropdown is opened or closed.
   * @param wasOpened true - the dropdown was opened; false - the dropdown was closed
   */
  toggleSharingOptions(wasOpened: boolean) {
    if (wasOpened) {
      // render facebook button
      window['FB'] && window['FB'].XFBML.parse();
    }
  }
}
