import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
  WidgetElementInfo,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-ecare-fix-voice-tariff-widget, [app-ecare-fix-voice-tariff-widget]',
  templateUrl: './ecare-fix-voice-tariff-widget.component.html',
})
export class EcareFixVoiceTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {
  callsWidgetInfo: WidgetElementInfo;

  protected initTariffData() {
    super.initTariffData();

    this.callsWidgetInfo = this.widgetElementsInfos.get('CALLS');
  }
}
