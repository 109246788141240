<tr
  class="ecare_listing-table-row"
  [ngClass]="{ clickable: true }"
  (click)="selectMode ? selectedOrder.emit(order) : buttonInRowClick($event, order)">
  <td class="no-wrap">
    <div class="order-id-wrapper">
      <div>{{ order.id }}</div>
      <i *ngIf="isBlocked" [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
      <i *ngIf="isProcessingError" class="error" [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
    </div>
  </td>
  <td *ngIf="isModuleBffCrmEnabled" app-acl-internal-evaluate-component class="content-md font-highlight">
    <ng-container *ngIf="selectMode; else notSelectMode">
      {{ order.extId }}
    </ng-container>
    <ng-template #notSelectMode>
      <ng-container *ngIf="getCustomer(order)">
        <a
          *ngIf="getCustomer(order).id; else nolink"
          id="010_ecare_search"
          class="link"
          (click)="selectCustomerInRow(getCustomer(order).id)">
          {{ getCustomer(order).subject?.firstName }} {{ getCustomer(order).subject?.middleName }}
          {{ getCustomer(order).subject?.lastName }}</a
        >
        <ng-template #nolink>
          {{ getCustomer(order).subject?.firstName }} {{ getCustomer(order).subject?.middleName }}
          {{ getCustomer(order).subject?.lastName }}
        </ng-template>
      </ng-container>
      <ng-container *ngIf="getCustomerExtIdFromOrder(order)"> ({{ getCustomerExtIdFromOrder(order) }}) </ng-container>
    </ng-template>
  </td>
  <td app-acl-internal-evaluate-component class="content-sm">
    {{ order.creator }}
  </td>
  <td class="content-sm order-created-datetime">
    <span placement="top" ngbTooltip="{{ order.created | date : 'dd.MM.yyyy HH:mm' }}">{{
      order.created | date : 'dd. MM. yyyy'
    }}</span>
  </td>
  <td class="content-sm order-created-datetime">
    <span placement="top" ngbTooltip="{{ order.stateHappened | date : 'dd.MM.yyyy HH:mm' }}">{{
      order.stateHappened | date : 'dd. MM. yyyy'
    }}</span>
  </td>
  <td class="mobile-right order-status-column">
    <app-sticker [variant]="getStickerType()">
      {{ 'wc.common.orderState.' + order.orderCalcStateType | translate }}
    </app-sticker>
  </td>
  <td *ngIf="priceOCexists" class="font-highlight">
    <one-time-price *ngIf="totalPriceOC" [price]="totalPriceOC" />
  </td>
  <td *ngIf="priceRCexists" class="font-highlight">
    <app-recurrent-price *ngIf="totalPriceRC" [price]="totalPriceRC" />
  </td>
  <td class="thumbnails-cell">
    <app-thumbnail-container
      *ngIf="thumbnailsForRow && thumbnailsForRow.length"
      [thumbnailsForRow]="thumbnailsForRow"
      [unshowedThumbnails]="unshowedThumbnails"
      [tooltipPosition]="'bottom'" />
  </td>
  <td>
    <app-button
      *ngIf="order.orderStateType === orderStateTypeEnum.CREATED && order.orderItems.length > 0"
      id="resume-order-button"
      variant="whiteWithBorder"
      size="medium"
      label="{{ 'wc.ecare.orders.resume.button' | translate }}"
      (click)="resumeOrder(order, $event)" />
  </td>
</tr>

<app-documents-popup entityType="Order" [entityId]="order.id" />
