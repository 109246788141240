import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { CodebookService, CurrentLocaleService, FormUtils } from '@btl/btl-fe-wc-common';
import { AddressHolder } from '../../models/address-holder';
import { ProductElasticFilter } from '../../models/product-elastic-filter';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FiltersComponent } from '../wizard/product-listing/filters/filters.component';
import { AddressService } from '@service/address.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { addressTypes, DisplayAddressPipe } from '../../pipes/display-address.pipe';
import { Constants } from '../../constants';

@Component({
  selector: 'app-form-address-search-input',
  templateUrl: './form-address-search-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAddressSearchInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormAddressSearchInputComponent),
    },
  ],
})
export class FormAddressSearchInputComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy, OnChanges {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value;

  @Input()
  disabled = false;

  @Input()
  control: AbstractControl;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  form: FormGroup = this.formBuilder.group({
    addressSearch: [],
    address: [],
  });

  @ViewChild('addressSearch', { static: false })
  private searchInput: ElementRef;
  private searchTerms = new Subject<string>();
  public autocompleteProducts: Observable<AddressHolder[]>;
  private maxAutocompleteResults: number;
  private maxAutocompleteResultsSubscription: Subscription;
  public filter: ProductElasticFilter;
  addressTypes = addressTypes;

  constructor(
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled = changes['disabled'].currentValue;
      if (this.disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  public searchChange(text: string) {
    this.searchTerms.next(text);
    if (text === '') {
      this.selectAddress(null);
    }
  }

  public autocompleteFocusOut(autocomplete: HTMLElement) {
    if (!this.form.controls.address.value || this.searchInput.nativeElement.value === '') {
      this.selectAddress(null);
    } else {
      this.selectAddress(this.form.controls.address.value);
    }
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  private initAddressAutocomplete() {
    this.maxAutocompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .subscribe(maxAutocompleteResults => (this.maxAutocompleteResults = maxAutocompleteResults));
    this.autocompleteProducts = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<AddressHolder[]>([]);
        } else {
          return this.addressService.searchByText(term, this.maxAutocompleteResults).pipe(source => {
            return source;
          });
        }
      })
    );
  }

  private selectAddress(selectAddress) {
    if (selectAddress) {
      const addressPipe = new DisplayAddressPipe(this.codebookService, this.currentLocaleService);
      this.form.controls['address'].patchValue(selectAddress);
      this.searchInput.nativeElement.value = addressPipe.transform(selectAddress, addressTypes.TECHNOLOGY_CHECK);
    } else {
      this.form.controls['address'].patchValue(selectAddress);
      if (this.searchInput) {
        this.searchInput.nativeElement.value = null;
      }
    }

    this.onChange.emit(this.getValue());
  }

  ngOnInit(): void {
    this.initAddressAutocomplete();
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    this.selectAddress(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    //this.selectAddress(this.value);
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid || this.form.disabled) {
      return this.form.getRawValue().address;
    }
    return null;
  }
}
