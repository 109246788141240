<ng-container *ngIf="entityRoles?.length">
  <table class="ecare_listing-table entity-role-table responsive-table table">
    <thead>
      <tr>
        <th></th>
        <th>{{ 'wc.ecare.accountEntity.delegatedBy' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.accountLogin' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.firstName' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.lastName' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.role' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.entityType' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.entityId' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.entityName' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.validFrom' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.validTo' | translate }}</th>
        <th>{{ 'wc.ecare.accountEntity.canDelegate' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let entityRole of entityRoles; let i = index" class="ecare_listing-table-row">
        <td>
          <input class="checkbox" type="checkbox" [id]="'entityRole-' + i" [formControl]="selectFormArray.at(i)" />
          <label style="vertical-align: super" [for]="'entityRole-' + i"> </label>
        </td>
        <td>
          <span class="bold">{{ entityRole.delegatedByAccount.login }}</span>
        </td>
        <td>
          <span class="information">{{ entityRole.account.login }}</span>
        </td>
        <td>
          <span class="bold">{{ getParamValue(entityRole.account.parameters, 'firstName') }}</span>
        </td>
        <td>
          <span class="bold">{{ getParamValue(entityRole.account.parameters, 'lastName') }}</span>
        </td>
        <td>
          <span>{{ entityRole.roleType }}</span>
        </td>
        <td>
          <span>{{ entityRole.entityType }}</span>
        </td>
        <td>
          <app-sticker variant="neutral">{{ entityRole.entityId }}</app-sticker>
        </td>
        <td>
          <span class="bold">{{ entityRole.entityName }}</span>
        </td>
        <td>
          <span class="additional-info">{{ entityRole.startDateTime | date: 'dd.MM.yyyy HH:mm' }}</span>
        </td>
        <td>
          <span class="additional-info">{{ entityRole.endDateTime | date: 'dd.MM.yyyy HH:mm' }}</span>
        </td>
        <td>
          <span>{{ 'wc.ecare.global.boolean.' + entityRole.canDelegate | translate }}</span>
        </td>
        <td class="no-wrap mobile-right">
          <ng-container>
            <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
              <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
              </span>

              <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ entityRole.id }}_popover-content">
                <div>
                  <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(entityRole)">
                    {{ 'wc.ecare.ticket.edit.button' | translate }}
                  </button>
                  <button
                    *ngIf="
                      entityRole.account.login &&
                      entityRole.account.login === authFactoryService.getAuthService().getUsername() &&
                      entityRole.canDelegate
                    "
                    class="btn btn-link action-list-more-actions-button"
                    type="button"
                    (click)="delegate(entityRole)">
                    {{ 'wc.ecare.accountEntity.delegate.button' | translate }}
                  </button>
                  <button
                    class="btn btn-link action-list-more-actions-button"
                    type="button"
                    (click)="delete(entityRole)">
                    {{ 'wc.ecare.ticket.delete.button' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
