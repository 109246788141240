export enum OrderStateTypeEnum {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  BLOCKED = 'BLOCKED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  EMPTY = 'EMPTY',
}
