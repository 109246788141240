<div class="tariff-usages">
  <div class="-usage-box">
    <div class="-usage-chart-wrapper">
      <div class="-usage-chart">
        <div class="-icon-wrapper">
          <i class="-icon-img" [inlineSVG]="'../../../../assets/img/ecare/' + unitsData.iconType + '.svg'"></i>
        </div>
      </div>
    </div>
    <div class="-usage-info">
      <div class="-usage-name">{{ unitsData.packageName }}</div>
      <div class="-usage-amounts">
        <div class="-usage-amount">
          <div class="-label">Used:</div>
          <div class="-value">{{ unitsData.usedAmount }} {{ unitsData.units }}</div>
        </div>
        <div class="-usage-amount -remains">
          <div class="-label">Remains:</div>
          <div *ngIf="unitsData.remainingAmount >= 0" class="-value">
            {{ unitsData.remainingAmount }} {{ unitsData.units }}
          </div>
          <div *ngIf="unitsData.remainingAmount < 0" class="-value">Unlimited</div>
        </div>
      </div>
      <button class="btn -btn-upgrade">Upgrade</button>
    </div>
  </div>
</div>
