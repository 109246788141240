import { Component, Input } from '@angular/core';
import { BundleDto } from '@btl/order-bff';
import moment from 'moment';

@Component({
  selector: 'app-tariff-space-consumption-info',
  templateUrl: './tariff-space-consumption-info.component.html',
})
export class TariffSpaceConsumptionInfoComponent {
  @Input()
  bundleDto: BundleDto;

  bundleTypeEnum = BundleTypeEnum;

  getDayRemaining() {
    return moment(this.bundleDto.expiryDateTime).diff(moment(), 'days');
  }

  getRemainingPercent() {
    let total = Number(this.bundleDto.amountTotal);
    let remaining = Number(this.bundleDto.amountRemaining);
    if (BundleTypeEnum.VOICE === this.bundleDto.type) {
      total = Number(this.bundleDto.amountTotal.split(':')[0]) * 60 + Number(this.bundleDto.amountTotal.split(':')[1]);
      remaining =
        Number(this.bundleDto.amountRemaining.split(':')[0]) * 60 +
        Number(this.bundleDto.amountRemaining.split(':')[1]);
    }
    return (remaining * 100) / total;
  }
}

export enum BundleTypeEnum {
  DATA = 'DATA',
  VOICE = 'VOICE',
  SMS = 'SMS',
}
