<div class="o2-indent">
  <div *ngIf="sincePrice" class="o2-typo--size-7 o2-typo--color-light-grey">
    <strong>{{ sincePrice | price }}</strong>
  </div>
  <del *ngIf="crossedPrice" class="o2-typo--size-7 o2-typo--color-light-grey">
    <strong>{{ crossedPrice | price }}</strong>
  </del>
  <div class="o2-typo--size-4">
    <strong>{{ productDetail.prices['OC'].price | price }}</strong>
  </div>
</div>
