import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  EcareConfirmationDialogComponent
} from "../components/page/ecare-confirmation-dialog/ecare-confirmation-dialog.component";
import {
  EntityGenerateDocumentModalComponent,
  GenerateDocumentEntityType
} from "../components/entity-generate-document-modal/entity-generate-document-modal.component";
import {
  CookiePolicySettingsModalComponent
} from "../components/page/footer/cookie-policy/cookie-policy-settings/cookie-policy-settings-modal.component";
import { CookieAgreementDto } from "@btl/order-bff";
import {
  EcareUserAccountSecurityComponent
} from "app/components/wizard/ecare-user-account/user-account-security/ecare-user-account-security.component";
import {
  EcareUserAccountDetailsComponent
} from "app/components/wizard/ecare-user-account/user-account-details/ecare-user-account-details.component";

@Injectable()
export class ModalFactoryService {
  constructor(private ngbModal: NgbModal) {}

  discardChangesModal(
    confirmHandler: (dialogReference: NgbModalRef) => void,
    cancelHandler: (dialogReference: NgbModalRef) => void
  ): EcareConfirmationDialogComponent {
    const dialogReference = this.ngbModal.open(EcareConfirmationDialogComponent, {
      windowClass: EcareConfirmationDialogComponent.POPUP_WINDOW_CSS_CLASSES,
    });
    const discardModal = <EcareConfirmationDialogComponent>dialogReference.componentInstance;
    discardModal.dialogReference = dialogReference;
    discardModal.heading = 'wc.ecare.discardChanges.title';
    discardModal.messagesTextKey = ['wc.ecare.discardChanges.message'];
    discardModal.buttonYesTextKey = "wc.ecare.discardChanges.btnDiscard";
    discardModal.buttonNoTextKey = "wc.common.cancel.button";
    discardModal.cancellationHandler = cancelHandler;
    discardModal.confirmationHandler = confirmHandler;
    return discardModal;
  }

  deleteEntityModal(confirmHandler: (dialogReference: NgbModalRef) => void): EcareConfirmationDialogComponent {
    const dialogReference = this.ngbModal.open(EcareConfirmationDialogComponent, { size: 'sm' });
    const deleteModal = <EcareConfirmationDialogComponent>dialogReference.componentInstance;
    deleteModal.dialogReference = dialogReference;
    deleteModal.heading = 'wc.ecare.deleteEntity.title';
    deleteModal.messagesTextKey = ['wc.ecare.deleteEntity.message'];
    deleteModal.buttonNoTextKey = 'wc.ecare.deleteEntity.btnCancel';
    deleteModal.buttonYesTextKey = 'wc.ecare.deleteEntity.btnDelete';
    deleteModal.buttonYesIconPath = '/assets/svg/delete.svg';
    deleteModal.buttonYesAdditionalClass = 'btn-delete';
    deleteModal.confirmationHandler = confirmHandler;
    return deleteModal;
  }

  entityGenerateDocumentModal(
    entityType: GenerateDocumentEntityType,
    documentName: string,
    generateDocumentUrl: string,
    attachDocumentHandler: (dialogRef: NgbModalRef, documentName: string) => void
  ): EntityGenerateDocumentModalComponent {
    const dialogReference = this.ngbModal.open(EntityGenerateDocumentModalComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input generate-document_modal',
    });
    const generateDocumentModal = <EntityGenerateDocumentModalComponent>dialogReference.componentInstance;
    generateDocumentModal.dialogReference = dialogReference;
    generateDocumentModal.entityType = entityType;
    generateDocumentModal.documentUrl = generateDocumentUrl;
    generateDocumentModal.documentName = documentName;
    generateDocumentModal.attachDocumentHandler = attachDocumentHandler;
    return generateDocumentModal;
  }

  cookiePolicySettingsModal(
    availableCookieAgreements: CookieAgreementDto[],
    moduleId: string
  ): CookiePolicySettingsModalComponent {
    const modalRef = this.ngbModal.open(CookiePolicySettingsModalComponent, { size: 'sm' });
    const cookiePolicySettingsModalComponent = <CookiePolicySettingsModalComponent>modalRef.componentInstance;
    cookiePolicySettingsModalComponent.dialogRef = modalRef;
    cookiePolicySettingsModalComponent.availableCookieAgreements = availableCookieAgreements;
    cookiePolicySettingsModalComponent.moduleId = moduleId;
    return cookiePolicySettingsModalComponent;
  }

  securitySettingsModal(
  ): EcareUserAccountSecurityComponent {
    const modalRef = this.ngbModal.open(EcareUserAccountSecurityComponent, { size: 'sm' });
    const securitySettingsModalComponent = <EcareUserAccountSecurityComponent>modalRef.componentInstance;
    securitySettingsModalComponent.dialogRef = modalRef;
    return securitySettingsModalComponent;
  }

  userDetailsSettingsModal(
    ): EcareUserAccountDetailsComponent {
      const modalRef = this.ngbModal.open(EcareUserAccountDetailsComponent, { size: 'sm', windowClass: 'modal-account-settings' });
      const securitySettingsModalComponent = <EcareUserAccountDetailsComponent>modalRef.componentInstance;
      securitySettingsModalComponent.dialogRef = modalRef;
      return securitySettingsModalComponent;
    }
}
