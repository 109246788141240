import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-stars, [app-rating-stars]',
  templateUrl: './rating-stars.component.html',
})
export class RatingStarsComponent implements OnInit {
  @Input()
  rating = 0;

  @Input()
  onlyFull = true;

  @Input()
  additionalStyle = '';

  stars;

  ngOnInit() {
    this.stars = [0, 0, 0, 0, 0];
    for (let i = 0; i < this.rating; i = i + 20) {
      if (this.rating >= i + 10 || (this.rating < i + 10 && this.onlyFull)) {
        this.stars[i / 20] = 1;
      } else {
        this.stars[i / 20] = 2;
      }
    }
    if (this.rating === 0) {
      this.stars[0] = this.onlyFull ? 1 : 2;
    }
  }
}
