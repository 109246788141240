<div class="homepage-hero container hp-container">
  <div class="homepage-hero-swiper">
    <swiper
      [slidesPerView]="1"
      [centeredSlides]="true"
      [slidesPerGroupSkip]="1"
      [allowTouchMove]="true"
      [grabCursor]="false"
      [pagination]="{ clickable: true }"
      [autoplay]="{
        delay: 6000
      }"
      [effect]="'fade'"
      [breakpoints]="{
        '1200': {
          allowTouchMove: false
        }
      }">
      <ng-template swiperSlide *ngFor="let content of renderingContentConfigs">
        <app-cms-widgets-sequential-renderer [widgets]="content.contentVersion.widgets">
        </app-cms-widgets-sequential-renderer>
      </ng-template>
    </swiper>
  </div>
</div>
