import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailDto } from '@btl/order-bff';
import { ProductCustomService } from '@service/product-custom.service';

@Component({
  selector: 'app-compared-product-price',
  templateUrl: './compared-product-price.component.html',
  styleUrls: ['./compared-product-price.component.scss'],
})
export class ComparedProductPriceComponent implements OnInit {
  @Input() productDetail: ProductDetailDto;

  crossedPrice: number = null;
  sincePrice: number = null;

  constructor(private productCustomService: ProductCustomService) {}

  ngOnInit() {
    const crossedAndSincePrice = this.productCustomService.getCrossedAndSincePrice(this.productDetail);
    this.crossedPrice = crossedAndSincePrice.crossedPrice;
    this.sincePrice = crossedAndSincePrice.sincePrice;
  }
}
