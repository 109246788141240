import { Component, OnDestroy } from '@angular/core';
import { Breadcrumb, WishListService } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { OrderDto, OrderItemDto, PriceDto, ProductDetailDto, WishListDetailDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StockCustomService } from '@service/stock-custom.service';
import { BlockUIService } from 'ng-block-ui';
import { WishListsComponent } from '../wish-lists/wish-lists.component';
import { DeleteWishListPopupComponent } from '../delete-wish-list-popup/delete-wish-list-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareWishListPopupComponent } from '../share-wish-list-popup/share-wish-list-popup.component';
import { NavigationMenuService } from '@service/navigation-menu.service';
import { PrecalculatedShoppingCart } from '../../../../models/precalculated-shopping-cart';
import { ProductAddingToCart } from '@service/product-listing.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

@Component({
  selector: 'app-wish-list-detail',
  templateUrl: './wish-list-detail.component.html',
})
export class WishListDetailComponent implements OnDestroy {
  breadcrumbs: Array<Breadcrumb> = [];

  selectedWishList: WishListDetailDto;
  selectedWishListItems = [];
  wishListId: string;
  orderItemsArray: Array<OrderItemDto> = [];

  private onDestroy$: Subject<void> = new Subject<void>();

  productsStock = {};
  totalPriceOC = 0;
  totalPriceRC = 0;
  totalPriceOCtax = 0;
  totalPriceRCtax = 0;

  showVat: boolean = true;
  showVatButton = true;
  defaultChoice = 'vat';

  constructor(
    private wishListService: WishListService,
    private route: ActivatedRoute,
    private router: Router,
    private orderingService: WcOrderingService,
    private stockCustomService: StockCustomService,
    private blockUIService: BlockUIService,
    private ngbModal: NgbModal,
    private navigationMenuService: NavigationMenuService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    propertyAccessorLocalService
      .getSwitchVatButtonVisibleBoolean()
      .subscribe(property => (this.showVatButton = property === 'true'));
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
      const authCode = queryParams['authCode'];
      if (authCode) {
        this.wishListService
          .getSharedWishListByAuthCode(authCode)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(wishList => {
            this.router.navigate(['/eshop/wish-lists', wishList.id]);
          });
      }
    });

    this.route.url.pipe(takeUntil(this.onDestroy$)).subscribe(urlParts => {
      if (urlParts.length > 0) {
        this.wishListId = urlParts[0].path;
        this.wishListService
          .getWishList(this.wishListId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(wishList => {
            if (wishList) {
              this.selectedWishList = wishList;
              this.getWishListItems(wishList.id);
              this.breadcrumbs.length = 0;
              WishListsComponent.getBreadCrumbs().forEach(breadcrumb => this.breadcrumbs.push(breadcrumb));
              this.breadcrumbs.push({
                name: wishList.name,
                seoUrl: `/eshop/wish-lists/${wishList.id}`,
              });
            }
          });
        this.wishListService.wishListChange.subscribe(wishList => {
          if (this.selectedWishList && wishList?.id === this.selectedWishList.id) {
            this.getWishListItems(this.selectedWishList.id);
          }
        });
      } else {
        this.selectedWishList = undefined;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getWishListItems(wishListId: string) {
    this.totalPriceOC = 0;
    this.totalPriceRC = 0;
    this.totalPriceOCtax = 0;
    this.totalPriceRCtax = 0;
    const stocksCalls = [];
    this.selectedWishListItems = [];
    this.blockUIService.start('main');
    this.wishListService
      .getWishListItems(wishListId, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishListItems => {
        this.selectedWishListItems.length = 0;
        wishListItems.forEach(wishListItem => {
          this.selectedWishListItems.push(wishListItem);
          stocksCalls.push(this.stockCustomService.getStockCentral(wishListItem.productCode));
          if (wishListItem['product']?.prices['OC']?.price) {
            this.totalPriceOC += wishListItem['product'].prices['OC'].price;
            this.totalPriceOCtax +=
              wishListItem['product'].prices['OC'].price -
              (wishListItem['product'].prices['OC'].price / 100) * wishListItem['product'].prices['OC'].tax;
          } else if (wishListItem['product']?.prices['RC']?.price) {
            this.totalPriceRC += wishListItem['product'].prices['RC'].price;
            this.totalPriceRCtax +=
              wishListItem['product'].prices['RC'].price -
              (wishListItem['product'].prices['RC'].price / 100) * wishListItem['product'].prices['RC'].tax;
          }
        });
        this.blockUIService.stop('main');

        forkJoin(stocksCalls)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(results => {
            for (let i = 0; i < wishListItems.length; i++) {
              const wishList = wishListItems[i];
              const stock = results[i];
              if (wishList && stock) {
                this.productsStock[wishList.productCode] = stock;
              }
            }
          });
      });
  }

  addProductToCart(product: ProductDetailDto): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(currentOrder, product as ProductAddingToCart)
        .subscribe(() => this.navigationMenuService.openShoppingCartPopup.emit());
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }

  deleteProductFromWishList(wishListId: string, itemId: string) {
    this.wishListService.deleteWishListItem(wishListId, itemId);
  }

  private filterAvailableItems() {
    this.orderItemsArray = [];
    for (const wishListItem of this.selectedWishListItems) {
      if (this.productsStock[wishListItem.productCode].realStock > 0) {
        this.orderItemsArray.push(this.createOrderItem(wishListItem.product));
      }
    }
  }

  addAllProductsToCart() {
    this.filterAvailableItems();
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .updateOrderItems(currentOrder, this.orderItemsArray)
        .pipe(takeUntil(this.onDestroy$))
        //ToDo Use handleProductAddingToCart to handle adding tariff when developed
        .subscribe(() => this.router.navigate(['/eshop/shopping-cart']));
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }

  goBackToWishLists() {
    this.router.navigate(['/eshop/wish-lists']);
  }

  private createOrderItem(product: ProductDetailDto): OrderItemDto {
    return {
      productId: product.id,
      action: 'ADD',
    };
  }

  isHw(orderItem: OrderItemDto): boolean {
    return PrecalculatedShoppingCart.isOrderItemWithShipment(orderItem);
  }

  hasProductPrice(productPrice): boolean {
    return productPrice.prices['RC']?.price > 0 || productPrice?.prices['OC']?.price > 0;
  }

  hasWishListItems(): boolean {
    return this.selectedWishListItems.length > 0;
  }

  getTaxPrice(price: PriceDto) {
    if (price) {
      return price.price - (price.price / 100) * price.tax;
    } else {
      return null;
    }
  }

  deleteWishlistPopup(wishList) {
    const modalRef = this.ngbModal.open(DeleteWishListPopupComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-confirmation',
    });
    const deleteWishListPopupComponent = <DeleteWishListPopupComponent>modalRef.componentInstance;
    deleteWishListPopupComponent.dialogRef = modalRef;
    deleteWishListPopupComponent.wishList = wishList;
    deleteWishListPopupComponent.redirectBack = true;
  }

  changeCheckbox() {
    const customSwitch = document.getElementById('taxSwitch') as HTMLInputElement;
    customSwitch.checked ? (this.showVat = true) : (this.showVat = false);
  }

  shareWishlistPopup(wishList) {
    const modalRef = this.ngbModal.open(ShareWishListPopupComponent, {
      size: 'sm',
    });
    const shareWishListPopupComponent = <ShareWishListPopupComponent>modalRef.componentInstance;
    shareWishListPopupComponent.dialogRef = modalRef;
    shareWishListPopupComponent.wishList = wishList;
  }

  showBuyAll(): boolean {
    const noAvailableProduct = this.selectedWishListItems.find(
      wishList => this.productsStock[wishList.product.productCode]?.realStock === 0
    );
    return noAvailableProduct == null;
  }
}
