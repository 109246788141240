<div *ngIf="isAccessoryGroup" class="o2-section__outdent">
  <div class="o2-pane o2-pane--grey o2-pane--no-border">
    <div class="o2-indent">
      <div class="o2-control__wrapper">
        <!-- 014 -->
        <label for="select-phoneBrands" class="o2-control__label">{{
          'wc.shopping.gui_productListing.header.accessories' | translate
        }}</label>
        <!-- /014 -->

        <!-- 015 -->
        <div *ngIf="phoneBrands" class="o2-control__inner-container">
          <select
            class="o2-control__select o2-control--full-width"
            id="select-phoneBrands"
            (change)="selectPhoneBrand($event.target.value)">
            <option value="" [selected]="!selectedPhoneBrand">
              {{ 'wc.shopping.gui_productListing.brandCombo.default' | translate }}
            </option>
            <option
              *ngFor="let phoneBrand of phoneBrands"
              [value]="phoneBrand"
              [ngValue]="phoneBrand"
              [selected]="selectedPhoneBrand === phoneBrand">
              {{ phoneBrand }}
            </option>
          </select>
        </div>
        <!-- /015 -->
      </div>
    </div>

    <!-- 016 -->
    <div *ngIf="phoneModels" class="o2-indent o2-indent--tight-top">
      <div class="o2-control__wrapper">
        <div class="o2-control__inner-container">
          <select
            class="o2-control__select o2-control--full-width"
            id="select-phoneModels"
            (change)="selectPhoneModel($event.target.value)">
            <option value="" [selected]="!selectedPhoneModel">
              {{ 'wc.shopping.gui_productListing.modelCombo.default' | translate }}
            </option>
            <option
              *ngFor="let phoneModel of phoneModels"
              [value]="phoneModel"
              [selected]="selectedPhoneModel === phoneModel">
              {{ phoneModel }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- /016 -->
  </div>
</div>
