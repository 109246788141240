import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum ThemeType {
  ECARE_THEME_1 = 'ecare-theme-1',
  ECARE_THEME_2 = 'ecare-theme-2',
  ECARE_THEME_3 = 'ecare-theme-3',
  ECARE_THEME_4 = 'ecare-theme-4',
  ECARE_THEME_5 = 'ecare-theme-5',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private static readonly DEFAULT_THEME_TYPE = ThemeType.ECARE_THEME_1;
  private static readonly STORAGE_ITEM_NAME = 'wc-theme';

  private colorTheme = new BehaviorSubject(
    sessionStorage.getItem(ThemeService.STORAGE_ITEM_NAME)
      ? (sessionStorage.getItem(ThemeService.STORAGE_ITEM_NAME) as ThemeType)
      : ThemeService.DEFAULT_THEME_TYPE
  );

  constructor() {
    this.initTheme();
  }

  setThemeColor(theme: ThemeType) {
    sessionStorage.setItem(ThemeService.STORAGE_ITEM_NAME, theme);
    this.colorTheme.next(theme);
  }

  getThemeColor(): BehaviorSubject<ThemeType> {
    return this.colorTheme;
  }

  getColorVariants(): ThemeType[] {
    return Object.values(ThemeType);
  }

  private initTheme() {
    const theme = sessionStorage.getItem(ThemeService.STORAGE_ITEM_NAME) as ThemeType;
    this.setThemeColor(theme ? theme : ThemeService.DEFAULT_THEME_TYPE);
  }
}
