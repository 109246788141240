export enum ProductListingViewType {
  GRID = 'GRID',

  LIST = 'LIST',

  SELECT_BOX = 'SELECT_BOX',

  RADIO = 'RADIO',

  MIXED = 'MIXED',
}
