import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-ecare-mobile-usage-tariff-widget, [app-ecare-mobile-usage-tariff-widget]',
  templateUrl: './ecare-mobile-usage-tariff-widget.component.html',
})
export class EcareMobileUsageTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {

}
