<ng-container *ngIf="!editable">
  <div class="product__rating">
    <ng-container *ngFor="let ratingItem of availableOptions; let i = index">
      <ng-container *ngIf="ratingItem[1] !== 0">
        <i *ngIf="ratingItem[1] <= rating; else emptyStar" class="fa fa-star" id="{{ i }}_001_product_rating"></i>
        <ng-template #emptyStar>
          <i class="fa fa-star-o" id="{{ i }}_002_product_rating"></i>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="editable">
  <div class="select-select">
    <select id="003_product_rating" [(ngModel)]="rating" (change)="handleChange()">
      <option *ngFor="let option of availableOptions" value="{{ option[0] }}">
        {{ 'wc.shopping.productFilter.customerRating.rating' + option[1] + '.label' | translate }}
      </option>
    </select>
  </div>
</ng-container>
