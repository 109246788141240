<app-ecare-mobile-row-general *ngFor="let contact of contacts">
  <div class="mobile-table-row-wrapper">
    <div class="mobile-table-row-flex information">
      <div>{{ contact.type }}</div>
    </div>
    <div class="mobile-table-row-flex highlight">
      <div class="title-text">{{ contact.firstName }} {{ contact.lastName }}</div>
      <app-sticker variant="neutral">{{ contact.role }}</app-sticker>
    </div>
    <div class="prefferedChannel">
      <div>{{ contact.phone }}</div>
      <div *ngIf="contact.channel === 'EMAIL' || contact.channel === 'ALL'">
        <i
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}"
          [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
      </div>
    </div>
    <div class="prefferedChannel">
      <div>{{ contact.email }}</div>
      <div *ngIf="contact.channel === 'SMS' || contact.channel === 'ALL'">
        <i
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}"
          [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
      </div>
    </div>
  </div>
</app-ecare-mobile-row-general>
