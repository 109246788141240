<div>
  <div class="tile_content" *ngIf="reportAreas">
    <form class="ecare-filter dashboard-search_filter flex-filter" [formGroup]="filterForm">
      <div class="-filter-fields -filter-fields-group flex-input">
        <ng-select
          id="001_report_filter"
          class="ecare-dropdown -search-entity full-dropdown"
          formControlName="areas"
          [clearable]="false"
          [clearOnBackspace]="false"
          [searchable]="false"
          placeholder="{{ 'wc.ecare.reports.filter.showAll.areas' | translate }}"
          (change)="onChangeArea($event)"
          (open)="toggleEntitySelection = true"
          (close)="toggleEntitySelection = false">
          <ng-option [value]="null">{{ 'wc.ecare.reports.filter.showAll.areas' | translate }}</ng-option>
          <ng-option *ngFor="let reportArea of reportAreas" [value]="reportArea">
            {{ reportArea.area }}
          </ng-option>
        </ng-select>
        <ng-select
          id="002_report_filter"
          class="ecare-dropdown -search-entity full-dropdown"
          formControlName="types"
          placeholder="{{ 'wc.ecare.reports.filter.showAll.types' | translate }}"
          [clearable]="false"
          [clearOnBackspace]="false"
          [searchable]="false"
          (change)="onChangeType($event)"
          (open)="toggleEntitySelection = true"
          (close)="toggleEntitySelection = false">
          <ng-option [value]="null">{{ 'wc.ecare.reports.filter.showAll.types' | translate }}</ng-option>
          <ng-option *ngFor="let reportType of reportTypes" [value]="reportType">
            {{ reportType.type }}
          </ng-option>
        </ng-select>
      </div>
      <app-button
        class="filter-search-button"
        variant="primary"
        id="004_notification_history_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </form>
  </div>
</div>
