<div class="modal-header">
  <h5>
    {{ 'wc.ecare.accountEntity.delegation.headline' | translate }}
  </h5>
  <button type="button" class="close" (click)="dialogRef.dismiss()">x</button>
</div>
<div *ngIf="this.entityTypes && this.rolesToSelect" class="modal-body" ng [formGroup]="delegateForm">
  <div class="form-group-row">
    <div class="ecare-detail-page-subheader">
      <div>{{ 'wc.ecare.accountEntity.delegatedFrom' | translate }}</div>
    </div>
    <div class="section-wrapper inline-form" formGroupName="delegatedByAccount">
      <div class="input">
        <label for="021_account_entity_delegate">{{ 'wc.ecare.accountEntity.login' | translate }}</label>
        <input
          type="text"
          id="021_account_entity_delegate"
          [disabled]="true"
          [value]="selectedAccountEntityRoleDto?.account?.login" />
      </div>
      <div class="input">
        <label for="022_account_entity_delegate">{{ 'wc.ecare.accountEntity.delegated' | translate }}</label>
        <input
          type="text"
          id="022_account_entity_delegate"
          [disabled]="true"
          [value]="selectedAccountEntityRoleDto?.created | date: 'dd.MM.yyyy HH:mm'" />
      </div>
    </div>
  </div>
  <div class="form-group-row">
    <div class="ecare-detail-page-subheader">
      <div>{{ 'wc.ecare.accountEntity.account' | translate }}</div>
    </div>
    <div class="section-wrapper inline-form">
      <app-form-account-input
        formControlName="account"
        [control]="delegateForm.get('account')"
        (onChange)="accountChanged($event)">
      </app-form-account-input>
      <div class="input">
        <label for="009_account_entity_delegate">{{ 'wc.ecare.account.firstName' | translate }}</label>
        <input
          type="text"
          id="009_account_entity_delegate"
          [disabled]="true"
          [value]="getAccountParamByName('firstName')" />
      </div>
      <div class="input">
        <label for="010_account_entity_delegate">{{ 'wc.ecare.account.lastName' | translate }}</label>
        <input
          type="text"
          id="010_account_entity_delegate"
          [disabled]="true"
          [value]="getAccountParamByName('lastName')" />
      </div>
    </div>
  </div>
  <div class="form-group-row">
    <div class="ecare-detail-page-subheader">
      <div id="025_account_entity_delegate">{{ 'wc.ecare.accountEntity.entity' | translate }}</div>
    </div>
    <div class="section-wrapper inline-form">
      <div class="input">
        <label [for]="'013_account_entity_delegate'">{{ 'wc.ecare.accountEntity.entityType' | translate }}</label>
        <app-form-select-input
          selectClass="ecare-dropdown"
          controlName="entityType"
          translationPrefix="wc.ecare.accountEntity.entityTypes"
          [parentGroup]="delegateForm"
          [options]="entityTypes"
          [disableOptionsTranslate]="true"
          [labelOption]="'name'"
          [valueOption]="'name'"
          [id]="'013_account_entity_delegate'">
        </app-form-select-input>
      </div>
      <div class="input">
        <label for="014_account_entity_delegate">{{ 'wc.ecare.accountEntity.entityId' | translate }}</label>
        <input type="text" formControlName="entityId" id="014_account_entity_delegate" />
        <app-input-errors
          [control]="delegateForm.get('entityId')"
          [translationPrefix]="'wc.ecare.accountEntity.entityId'">
        </app-input-errors>
      </div>
      <div class="input">
        <label [for]="'015_account_entity_delegate'">{{ 'wc.ecare.accountEntity.roleType' | translate }}</label>
        <app-form-select-input
          selectClass="ecare-dropdown"
          controlName="roleType"
          translationPrefix="wc.ecare.accountEntity.roleType"
          [parentGroup]="delegateForm"
          [options]="rolesToSelect"
          [labelOption]="'id'"
          [valueOption]="'id'"
          [id]="'015_account_entity_delegate'">
        </app-form-select-input>
      </div>
    </div>
  </div>
  <div class="form-group-row">
    <div class="ecare-detail-page-subheader">
      <div>{{ 'wc.ecare.accountEntity.entityRoleDetail' | translate }}</div>
    </div>
    <div class="section-wrapper inline-form">
      <div>
        <label [for]="'017_account_entity_delegate'">{{ 'wc.ecare.accountEntity.startDateTime' | translate }}</label>
        <app-date-picker
          class="input__group date-picker"
          style="display: flex"
          [formControlName]="'startDateTime'"
          [id]="'017_account_entity_delegate'"
          [showTime]="false">
        </app-date-picker>
        <app-input-errors
          [control]="delegateForm.get('startDateTime')"
          [translationPrefix]="'wc.ecare.accountEntity.startDateTime'">
        </app-input-errors>
      </div>
      <div>
        <label [for]="'018_account_entity_delegate'">{{ 'wc.ecare.accountEntity.endDateTime' | translate }}</label>
        <app-date-picker
          class="input__group date-picker"
          style="display: flex"
          [formControlName]="'endDateTime'"
          [id]="'018_account_entity_delegate'"
          [showTime]="false">
        </app-date-picker>
        <app-input-errors
          [control]="delegateForm.get('endDateTime')"
          [translationPrefix]="'wc.ecare.accountEntity.startDateTime'">
        </app-input-errors>
      </div>
    </div>
    <div>
      <div class="checkbox">
        <input type="checkbox" formControlName="canDelegate" id="019_account_entity_delegate" />
        <label for="019_account_entity_delegate">{{ 'wc.ecare.accountEntity.canDelegate' | translate }}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button color="btn btn-cancel" type="button" (click)="cancel()">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <app-button variant="primary" [label]="'wc.common.continue.button' | translate" (click)="continue()"> </app-button>
</div>
