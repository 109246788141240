import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AbstractService, DefaultErrorHandler } from '@btl/btl-fe-wc-common';
import { CustomerDto, OrderDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { environment } from 'environments/environment';
import { OrderUtils } from 'app/helpers/order-utils';

@Injectable()
export class ScoringService extends AbstractService {
  constructor(
    protected errorHandler: DefaultErrorHandler,
    private orderingService: WcOrderingService,
    private http: HttpClient
  ) {
    super(errorHandler);
  }

  getScoring(orderDto: OrderDto): Observable<any> {
    const customer: CustomerDto = JSON.parse(OrderUtils.getOrderAttributeValue(orderDto, 'customer'));

    let params = new HttpParams();
    params = params.append('timestamp', new Date().toISOString());
    params = params.append('orderId', orderDto.id);
    params = params.append('user', 'test');
    params = params.append('egn', customer.subject.identityNumber);

    const errorMessage = `Scoring request failed.`;
    return this.http
      .get<any>(environment.scoringServiceEndPoint, { params: params })
      .pipe(catchError(this.handleError(errorMessage, null)));
  }
}

export interface ScoringResponse {
  responseCode: number;
  scoringLevel: ScoringLevelEnum;
}

export enum ScoringLevelEnum {
  WHITE = 'WHITE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  BLACK = 'BLACK',
}
