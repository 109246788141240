import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockTemplateComponent, EnumDataTypeService, ProductService } from '@btl/btl-fe-wc-common';
import { DataTypeEnumDto, OrderDto, OrderItemDto, ProductDetailDto, ProductParamMetadataDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderUtils } from '../../../helpers/order-utils';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { ProductCustomService } from '@service/product-custom.service';

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.css'],
})
export class ProductConfigurationComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  blockTemplate = BlockTemplateComponent;

  @Input()
  orderItem: OrderItemDto;

  @Input()
  order: OrderDto;

  @Input()
  product: ProductDetailDto;

  @Input()
  dialogRef;

  @Input()
  dynamicProductParameters: Array<ProductParamMetadataDto>;

  dataTypeEnums: { [name: string]: DataTypeEnumDto };

  addressTypes = addressTypes;

  form = this.fb.group({});

  constructor(
    private productService: ProductService,
    private productCustomService: ProductCustomService,
    private fb: FormBuilder,
    private enumDataTypeService: EnumDataTypeService,
    private orderingService: WcOrderingService
  ) {}

  ngOnInit(): void {
    this.enumDataTypeService.getEnums('pc').subscribe(result => {
      this.dataTypeEnums = {};
      result.forEach(e => (this.dataTypeEnums[e.name] = e));
    });

    if (this.dynamicProductParameters) {
      this.createForm();
    } else {
      this.productCustomService
        .getVisibleProductParameterMetadata(this.product.categoryId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: Array<ProductParamMetadataDto>) => {
          this.dynamicProductParameters = result;
          this.createForm();
        });
    }
  }

  createForm() {
    this.form = this.fb.group({});
    this.dynamicProductParameters.forEach(param => {
      const orderParamDto = this.orderItem.attributes.find(orderAttr => orderAttr.name === param.name);
      let value = null;
      if (orderParamDto) {
        value = orderParamDto.value;
      }
      this.form.addControl(param.name, this.fb.control(value, [Validators.pattern(param.validationRegex)]));
    });
  }

  clear() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        if (control.enabled) {
          control.patchValue(null);
        }
      }
    });
  }

  set() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        OrderUtils.updateOrderItemAttr(this.orderItem.attributes, field, control.value);
      }
    });
    if (this.orderItem.action === 'NO_OPERATION') {
      this.orderItem.action = 'UPDATE';
    }
    this.orderingService.updateOrderItems(this.order, [this.orderItem]).subscribe(() => this.dialogRef.dismiss());
  }
}
