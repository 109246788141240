/* eslint-disable @angular-eslint/prefer-standalone-component */
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CurrentLocaleService, FormUtils, MetaDescriptionService } from '@btl/btl-fe-wc-common';
import {
  EntityMetaDescriptionDto,
  EntityMetaDescriptionInputDto,
  MetaEntityAttributeDto,
  ValidationFrontendService,
  ValidationResultDto,
} from '@btl/order-bff';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-entity-generated-form',
  templateUrl: './entity-generated-form.component.html',
})
export class EntityGeneratedFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();

    this.currentNativeAttributes.forEach(oldAttr => {
      this.form.removeControl(oldAttr);
    });

    this.currentNoNativeAttributes.forEach(oldAttr => {
      this.getParametersForm().removeControl(oldAttr);
    });
  }

  @Input() formName: string;
  @Input() sourceName: string;
  @Input() entityType: string;
  @Input() entityId: string;
  @Input() attributeType: EntityAttributeTypeEnum = EntityAttributeTypeEnum.ALL;

  @Input() resourceIdentification: {
    [key: string]: string;
  };

  @Input() externalValues: {
    [key: string]: Array<ExternalValue>;
  } = {};

  @Input() defaultValues: {
    [key: string]: any;
  } = {};

  @Input() attributeOnChangeEvent: {
    [key: string]: EventEmitter<any>;
  } = {};

  @Input() additionalValidators: {
    [key: string]: Array<ValidatorFn>;
  } = {};

  @Input() additionalAsyncValidators: {
    [key: string]: Array<AsyncValidatorFn>;
  } = {};

  @Input() columnsCount: number = 2;
  @Input() context;
  @Input() applyDefaultValues: boolean = true;
  @Input() nonNativeAttributesFieldName = 'parameters';
  @Input() form = this.formBuilder.group({});
  @Input() validate = true;
  @Input() checkFormVisibility = true;
  @Input() formIdPrefix = '';
  @Input() customer?;
  @Input() showCustomerData? = false;
  @Input() hide = false;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  metaDescription: EntityMetaDescriptionDto;
  formAttributes: Array<MetaEntityAttributeDto> = [];
  currentNativeAttributes = [];
  currentNoNativeAttributes = [];
  formGenerated = false;
  formGeneratedEmitted = false;
  currentLocale: string;
  guiElementTypeEnum = GuiElementTypeEnum;
  attributeTypeEnum = AttributeTypeEnum;
  formVisibility = false;
  formValuesBeforeOnFocus: {
    [key: string]: any;
  } = {};

  constructor(
    private formBuilder: FormBuilder,
    private currentLocaleService: CurrentLocaleService,
    private metaDescriptionService: MetaDescriptionService,
    private validationFrontendService: ValidationFrontendService
  ) {
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
      this.rebuildForm();
    });
  }

  ngAfterViewChecked(): void {
    if (this.formGenerated && !this.formGeneratedEmitted) {
      this.formGeneratedEmitter.emit();
      this.formGeneratedEmitted = true;
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGenerated) {
      const resourceIdentificationChanges = changes['resourceIdentification'];
      if (
        resourceIdentificationChanges &&
        resourceIdentificationChanges.currentValue !== resourceIdentificationChanges.previousValue &&
        !resourceIdentificationChanges.firstChange
      ) {
        this.rebuildForm();
      }
      const validateChanges = changes['validate'];
      if (
        validateChanges &&
        validateChanges.currentValue !== validateChanges.previousValue &&
        !validateChanges.firstChange
      ) {
        this.updateValidators();
      }
    }
  }

  updateValidators() {
    this.currentNativeAttributes.forEach(attr => {
      const metaAttribute = this.formAttributes.find(findAttr => findAttr.name === attr);
      this.setValidators(this.form.get(attr), metaAttribute);
    });

    this.currentNoNativeAttributes.forEach(attr => {
      const metaAttribute = this.formAttributes.find(findAttr => findAttr.name === attr);
      this.setValidators(this.getParametersForm().get(attr), metaAttribute);
    });
  }

  setValidators(control: AbstractControl, metaAttribute: MetaEntityAttributeDto) {
    control.setValidators(this.getValidators(metaAttribute));
    control.setAsyncValidators(this.getAsyncValidators(metaAttribute));
    control.updateValueAndValidity();
  }

  overrideFormParameters(attributes: { [key: string]: string }) {
    Object.keys(attributes).forEach(key => {
      if (attributes[key + this.formName] != null) {
        attributes[key] = attributes[key + this.formName];
      }
    });
  }

  rebuildForm() {
    this.formGenerated = false;
    this.formGeneratedEmitted = false;
    const entityMetaDescriptionInputDto: EntityMetaDescriptionInputDto = {
      resourceIdentification: this.resourceIdentification,
      context: this.context,
    };
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.metaDescriptionService
      .getEntityMetaDescriptions(this.sourceName, this.entityType, entityMetaDescriptionInputDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.metaDescription = result;
        this.overrideFormParameters(this.metaDescription.metaParameters);
        if (this.form && (this.metaDescription.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)) {
          this.formVisibility = !this.hide;
          this.formAttributes.length = 0;
          this.metaDescription.attributes.forEach(attr => this.overrideFormParameters(attr.metaParameters));
          this.formAttributes = this.metaDescription.attributes
            .filter(attr => {
              if (!attr.metaParameters['guiVisible']) {
                if (this.form.contains(attr.name)) {
                  this.form.removeControl(attr.name);
                }
                return false;
              }
              if (this.attributeType === EntityAttributeTypeEnum.NATIVE && attr.nativeAttribute) {
                return true;
              }
              if (this.attributeType === EntityAttributeTypeEnum.NON_NATIVE && !attr.nativeAttribute) {
                return true;
              }
              return this.attributeType === EntityAttributeTypeEnum.ALL;
            })
            .sort((a, b) =>
              Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
            );

          if (!this.form.contains(this.nonNativeAttributesFieldName)) {
            this.form.addControl(this.nonNativeAttributesFieldName, this.formBuilder.group([]));
          }

          const oldNativeAttributes = this.currentNativeAttributes;
          const oldNoNativeAttributes = this.currentNoNativeAttributes;
          this.currentNativeAttributes = [];
          this.currentNoNativeAttributes = [];

          this.formAttributes.forEach(attr => {
            if (attr.nativeAttribute) {
              this.currentNativeAttributes.push(attr.name);
              this.createOrUpdateControl(this.form.get(attr.name) as FormControl, attr);
            } else {
              this.currentNoNativeAttributes.push(attr.name);
              this.createOrUpdateControl(this.getFormParameterByName(attr.name), attr);
            }
          });

          oldNativeAttributes.forEach(oldAttr => {
            if (!this.currentNativeAttributes.find(currentAttr => oldAttr === currentAttr)) {
              this.form.removeControl(oldAttr);
            }
          });

          oldNoNativeAttributes.forEach(oldAttr => {
            if (!this.currentNoNativeAttributes.find(currentAttr => oldAttr === currentAttr)) {
              this.getParametersForm().removeControl(oldAttr);
            }
          });
        } else {
          this.formVisibility = false;
        }
        this.formGenerated = true;
      });
  }

  createOrUpdateControl(control: FormControl, attr: MetaEntityAttributeDto) {
    if (!control) {
      control = new FormControl(null, this.getValidators(attr), this.getAsyncValidators(attr));
      if (attr.nativeAttribute) {
        this.form.addControl(attr.name, control);
      } else {
        this.getParametersForm().addControl(attr.name, control);
      }
    } else {
      control.setValidators(this.getValidators(attr));
      control.setAsyncValidators(this.getAsyncValidators(attr));
    }
    if (!control.value) {
      control.patchValue(this.getDefaultValue(attr));
    }
    this.enableDisableAttrControl(control, attr);
  }

  resetForm() {
    this.form.reset();
    this.rebuildForm();
  }

  enableDisableAttrControl(control: FormControl, attr: MetaEntityAttributeDto) {
    if (attr.metaParameters['isEditable'] !== 'true') {
      control.disable();
    } else {
      control.enable();
    }
  }

  getParametersForm(): FormGroup {
    return this.form.get(this.nonNativeAttributesFieldName) as FormGroup;
  }

  getFormParameterByName(paramName): FormControl {
    return this.getParametersForm().get(paramName) as FormControl;
  }

  getFormControlByName(controlName): FormControl {
    return this.form.get(controlName) as FormControl;
  }

  getValidators(attribute: MetaEntityAttributeDto): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (
      this.validate &&
      !this.hide &&
      (attribute.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)
    ) {
      if (attribute.metaParameters['isMandatory'] === 'true') {
        validators.push(Validators.required);
      }
      if (attribute.validationRegex) {
        validators.push(Validators.pattern(attribute.validationRegex));
      }
      const additionalValidators = this.additionalValidators[attribute.name];
      if (additionalValidators) {
        additionalValidators.forEach(validator => validators.push(validator));
      }
    }

    return validators;
  }

  getAsyncValidators(attribute: MetaEntityAttributeDto): AsyncValidatorFn[] {
    const validators: AsyncValidatorFn[] = [];

    if (
      this.validate &&
      !this.hide &&
      (attribute.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)
    ) {
      const additionalValidators = this.additionalAsyncValidators[attribute.name];
      if (additionalValidators) {
        additionalValidators.forEach(validator => validators.push(validator));
      }
      if (attribute.metaParameters['validationUrl'] != null) {
        validators.push(
          metaValidator(
            this.validationFrontendService,
            attribute.name,
            attribute.metaParameters['validationUrl'],
            this.formValuesBeforeOnFocus
          )
        );
      }
    }

    return validators;
  }

  getDefaultValue(attribute: MetaEntityAttributeDto) {
    let defaultValue = null;
    if (this.defaultValues && this.defaultValues[attribute.name]) {
      defaultValue = this.defaultValues[attribute.name];
    }
    if (this.applyDefaultValues && !defaultValue && attribute.defaultValue) {
      if (attribute.type === 'INTEGER' || attribute.type === 'FLOAT') {
        defaultValue = Number(attribute.defaultValue);
      } else if (attribute.type === 'BOOLEAN') {
        defaultValue = attribute.defaultValue === 'true';
      } else if (attribute.type === 'DATE') {
        defaultValue = new Date(attribute.defaultValue);
      } else if (attribute.type === 'ENUM') {
        defaultValue = this.getEnumValues(attribute.typeDetail).find(enumValue => enumValue === attribute.defaultValue);
      } else {
        defaultValue = attribute.defaultValue;
      }
    }
    if (!defaultValue && this.externalValues && this.externalValues[attribute.name]) {
      defaultValue = this.externalValues[attribute.name][0]?.value;
    }

    return defaultValue;
  }

  getEnumValues(enumValues: string): string[] {
    return enumValues.split(',');
  }

  getAttributeLabel(attribute: MetaEntityAttributeDto): string {
    const ret = attribute.localizedTexts?.find(text => text.locale === this.currentLocale)?.message;
    return ret ? ret : attribute.name;
  }

  generatePrimaryContact(attributeName: string) {
    if (!this.showCustomerData) return false;
    switch (attributeName) {
      case 'firstName':
      case 'lastName':
      case 'email':
        return true;
      default:
        return false;
    }
  }

  generateColumn(formName: string) {
    return `col${12 / this.columnsCount}`;
  }

  public getAssignmentStateFromMinDate(): Date {
    return new Date(1920, 1, 1);
  }

  public getAssignmentStateFromMaxDate(): Date {
    return new Date(2040, 1, 1);
  }

  fieldValueChanged(paramName: string) {
    if (!this.formValuesBeforeOnFocus[paramName]) {
      this.formValuesBeforeOnFocus[paramName] = this.form.get(paramName)?.value;
    }
  }
}

export function metaValidator(
  validationFrontendService: ValidationFrontendService,
  attrName: string,
  validationUrl: string,
  formValuesBeforeOnFocus
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> | null => {
    const value = control.value;
    const orginalValue = formValuesBeforeOnFocus[attrName];
    if (value && value != orginalValue && formValuesBeforeOnFocus.hasOwnProperty(attrName)) {
      const validationUrlSplit = validationUrl.split('/');
      return validationFrontendService.validate(validationUrlSplit[0], validationUrlSplit[1], { value: value }).pipe(
        map((response: ValidationResultDto) => {
          const error = {};
          error[response.message] = true;
          return !response.valid ? error : null;
        })
      );
    }
    return of(null);
  };
}

export class ExternalValue {
  value: any;
  labelKey?: string;
  label?: string;
}

export enum EntityAttributeTypeEnum {
  NATIVE,
  NON_NATIVE,
  ALL,
}

export enum GuiElementTypeEnum {
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_TIME = 'datetime',
  SELECT = 'select',
  PHONE = 'phone',
  EMAIL = 'email',
  BUILDING_NUMBER = 'buildingNumber',
  STREET_NUMBER = 'streetNumber',
  ZIP_CODE = 'zipCode',
}

export enum AttributeTypeEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  CODEBOOK = 'CODEBOOK',
  ENUM = 'ENUM',
}
