<div app-breadcrumb [breadcrumbs]="breadcrumbs"></div>

<app-page-header title="{{ 'wc.shopping.gui_wishlist.myShoppingLists' | translate }}"></app-page-header>

<div class="wish-lists">
  <div
    *ngIf="quantityOfItemsInShoppingCart > 0"
    class="wish-list go-to-shopping-cart"
    [routerLink]="['/eshop/shopping-cart']">
    <div class="name">
      {{ 'Shopping cart' | translate }}
    </div>
    <div class="description">
      {{
        "We've noticed that you put some items into your shopping cart. They’re not reserved so they could sell out."
          | translate
      }}
    </div>
    <app-button
      iconPath="/assets/svg/shopping-cart-icon.svg"
      variant="whiteWithBorder"
      size="fullWidth"
      label="{{ 'wc.shopping.gui_wishlist.wishListDetail.goToShoppingCart' | translate }}"></app-button>
  </div>
  <div
    *ngFor="let wishList of wishLists; let i = index"
    class="wish-list"
    [routerLink]="['/eshop/wish-lists/' + wishList.id]">
    <div
      class="delete-icon"
      ngbTooltip="{{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.deleteWishlist' | translate }}"
      (click)="deleteWishlistPopup(wishList); $event.stopPropagation()">
      <i [inlineSVG]="'/assets/svg/delete.svg'"></i>
    </div>
    <div class="name">
      {{ wishList.name }}
    </div>
    <div *ngIf="wishList.productCount > 0" class="itemsTag">{{ wishList.productCount }} {{ 'items' | translate }}</div>
    <div class="hand">
      <img *ngIf="i < 7" height="103" width="62" [src]="'/assets/svg/hand-icon-' + (i + 1) + '.png'" />
      <img *ngIf="i >= 7" height="103" width="62" [src]="'/assets/svg/hand-icon-4.png'" />
    </div>
  </div>
  <div class="wish-list" (click)="wishListPopup()">
    <div class="name">
      {{ 'wc.shopping.gui_wishlist.wishListDetail.addNewList' | translate }}
    </div>
    <div class="add-icon">
      <i [inlineSVG]="'/assets/svg/add.svg'"></i>
    </div>
  </div>
</div>
