<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.deleteWishlist' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="wish-list-delete">
    <i [inlineSVG]="'/assets/svg/delete.svg'"></i>
    {{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.title' | translate }}
  </div>
</div>

<div class="modal-footer">
  <app-button
    variant="link"
    label="{{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.cancel' | translate }}"
    (click)="closePopup()"></app-button>
  <app-button
    variant="danger"
    label="{{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.delete' | translate }}"
    (click)="deleteWishList()"></app-button>
</div>
