import { Component } from '@angular/core';
import { Product } from '../../../models/product';
import { SortTypeEnum } from '../../../models/product-elastic-filter';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Scrollbar, Navigation, Pagination, EffectFade, Autoplay]);

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
})
export class HomepageComponent {
  sorting = SortTypeEnum;

  get fakeTariffProductsOffer(): Product[] {
    // todo: load product offer instead of fake data
    return [
      {
        name: {
          en: 'Basic mobile tariff',
          cs: 'Základní mobilní tarif',
        },
        image: {
          thumbnail: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/unlimited-internet.svg',
          medium: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/unlimited-internet.svg',
        },
        description: {
          en: 'Unlimited calls and messages to all networks. You will enjoy unlimited data in the Czech Republic and the EU',
          cs: 'Neomezené zprávy a volaní do všech sítí. Užijte si neomezená data v ČR i v EU',
        },
      },
      {
        name: {
          en: 'Flexi BOX tariff',
          cs: 'Flexi BOX tarif',
        },
        image: {
          thumbnail: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/internetAndTv.svg',
          medium: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/internetAndTv.svg',
        },
        description: {
          en: 'No extra fees, no annual contracts, no surprises',
          cs: 'Bez dalších poplatků, bez smlouvy, bez překvapení',
        },
        stickers: [
          {
            name: {
              en: 'popular',
              cs: 'oblíbené',
            },
            priority: 1,
            image: '',
          },
        ],
      },
      {
        name: {
          en: 'Basic TV tariff',
          cs: 'Základní TV tarif',
        },
        image: {
          thumbnail: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/tv.svg',
          medium: 'node_modules/@btl/btl-fe-theme/assets/svg/tariffs/tv.svg',
        },
        description: {
          en: 'TV channels for the whole family anywhere in Czech Republic',
          cs: 'TV kanály pro celou rodinu kdekoliv v ČR',
        },
      },
    ];
  }
}
