<div class="product__detail-reviewsList">
  <div class="product__detail-reviewsList-item">
    <div class="product__detail-reviewsList-user">
      <div class="product__detail-reviewsList-userImage">JD</div>
      <div class="product__detail-reviewsList-userName">John Doe</div>
    </div>
    <div class="product__detail-reviewsList-content">
      <div class="product__detail-reviewsList-title">
        <div class="product__detail-reviewsList-titleText">Customer review title goes here</div>
        <div class="product__detail-reviewsList-titleDate">{{ reviewDto.reviewedOn }}</div>
      </div>
      <div class="product__detail-reviewsList-rating">
        <app-rating-stars [rating]="reviewDto.rating"> </app-rating-stars>
      </div>
      <div class="product__detail-reviewsList-text">
        {{ reviewDto.reviewSummary }}
      </div>
      <div class="product__detail-reviewsList-review">
        <div class="product__detail-reviewsList-pros">
          {{ reviewDto.reviewPros }}
        </div>
        <div class="product__detail-reviewsList-cons">
          {{ reviewDto.reviewCons }}
        </div>
      </div>
    </div>
  </div>
</div>
