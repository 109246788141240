<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.simChange.heading' | translate }}
  </h4>
</div>

<div *blockUI="simChangeBlockUiElementName; template: blockTemplate" class="modal-body">
  <div *ngIf="generalFailure" class="alert alert-danger">
    <div>
      {{ 'wc.ecare.simChange.generalFailure.text' | translate }}
    </div>
  </div>

  <form class="form" [formGroup]="simChangeForm">
    <div class="form-group-row">
      <label>
        {{ 'wc.ecare.simChange.originalSimIcc.label' | translate }}
      </label>
      <div class="input">
        <input id="001_sim_change" type="text" value="{{ originalSimIcc }}" [disabled]="true" />
      </div>
    </div>
    <app-input-row
      id="002_sim_change"
      fieldName="newSimIcc"
      translationPrefix="wc.ecare.simChange"
      [parentGroup]="simChangeForm">
    </app-input-row>
    <!-- TODO: Reasons aren't currently configured in PC. -->
    <div class="form-group-row">
      <label>
        {{ 'wc.ecare.simChange.reason.label' | translate }}
      </label>
      <div class="select">
        <select id="003_sim_change" formControlName="reason">
          <option *ngFor="let reason of reasons" [ngValue]="reason">
            {{ reason.name }} (<one-time-price [product]="reason"></one-time-price>)
          </option>
        </select>
        <app-input-errors translationPrefix="wc.ecare.simChange.reason" [control]="simChangeForm.controls['reason']">
        </app-input-errors>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <button id="004_sim_change" type="button" class="btn btn-cancel" ngbAutofocus (click)="dialogRef.dismiss()">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
    <button id="005_sim_change" type="button" class="btn btn-default" (click)="changeSim()">
      {{ 'wc.ecare.changeSim.button' | translate }}
    </button>
  </div>
</div>
