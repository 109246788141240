import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrderDto,
  OrderItemDto,
  PictureTypeDto,
  ProductDetailDto,
  ProductPictureHrefDto,
  ProductSortAttributeDto,
  SortDto,
} from '@btl/order-bff';
import { BlockTemplateComponent, ProductService } from '@btl/btl-fe-wc-common';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { of, throwError } from 'rxjs';
import { CategoryTypeEnum, ProductFilter, UNLIMITED_PRODUCT_PAGE_SIZE } from 'app/models/product-filter';
import { ProductListingViewType } from 'app/components/wizard/product-listing/product-listing-view-type.type';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { Location } from '@angular/common';
import {
  IccidAttributeConfigurationComponent
} from 'app/components/page/iccid-attribute-configuration/iccid-attribute-configuration.component';
//import { ProductGalleryComponent } from 'app/components/page/product-gallery/product-gallery.component';
import {
  ProductGalleryComponent
} from 'app/components/wizard/detail/product-detail/product-gallery/product-gallery.component';
//import { ProductListingComponent } from 'app/components/wizard/product-listing/product-listing.component';
import _ from 'lodash';
import { ProductUtils } from '../../../../helpers/product-utils';
import { finalize, switchMap } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProductAddingToCart } from "@service/product-listing.service";
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
})
export class ServiceDetailComponent implements OnInit, AfterViewChecked {
  @ViewChild('iccidAttributeConfigurationComponent', { static: false })
  iccidAttributeConfigurationComponent: IccidAttributeConfigurationComponent;
  @ViewChild('gallery', { static: false }) productGalleryComponent: ProductGalleryComponent;
  //@ViewChild('tariffProductListing', {static: false}) tariffProductListing: ProductListingComponent;

  @BlockUI('blockUICommitmentElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  targetImage: HTMLElement;
  routeSubscibe;
  product: ProductDetailDto;
  tariffHwProduct: ProductDetailDto;
  parentTariff: ProductDetailDto;
  selectedTariffId: string;
  selectedTariff: OrderItemDto;
  selectedTariffIds: Array<string>;
  selectedHwLeasingId: string;
  selectedHwLeasingDetail: ProductDetailDto;
  productPictureHint: ProductPictureHrefDto;
  coreProductInShoppingCart;
  commitmentProduct: ProductDetailDto;

  categoryTypeEnum = CategoryTypeEnum;
  productListingViewTypes = ProductListingViewType;

  get preCalculatedShoppingCart(): PrecalculatedShoppingCart {
    return this.shoppingCartService.preCalculatedShoppingCart;
  }

  cartOrderItemDto: OrderItemDto;

  filter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_HW,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: 8,
    },
  };

  tariffFilter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_TARIFF,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  hwLeasingFilter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_OCC_LEASING,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: UNLIMITED_PRODUCT_PAGE_SIZE,
    },
  };

  addonsFilter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_VAS_CORE,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: 4,
    },
  };

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private productService: ProductService,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.routeSubscibe = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      let productId = params['productId'];
      const cartOrderItemId = params['orderItemId'];
      this.selectedTariffId = params['selectedTariffId'];
      if (cartOrderItemId && this.shoppingCartService.preCalculatedShoppingCart) {
        this.cartOrderItemDto = this.shoppingCartService.preCalculatedShoppingCart.getOrderItemById(cartOrderItemId);
        if (this.cartOrderItemDto) {
          productId = this.cartOrderItemDto.productId;
        }
      }
      this.loadProduct(productId);

      if (this.preCalculatedShoppingCart) {
        const selectedTariff = this.preCalculatedShoppingCart.order.orderItems.find(
          (orderItem: OrderItemDto) => this.selectedTariffId === orderItem.productId
        );
        this.selectedTariffIds.push(selectedTariff.id);
      }
    });

    this.shoppingCartService.onChange.subscribe((precalculatedShoppingCart: PrecalculatedShoppingCart) => {
      if (this.product && this.cartOrderItemDto) {
        this.setProductOrderId(precalculatedShoppingCart.getOrderItemById(this.cartOrderItemDto.id));
      }
    });
  }

  loadProduct(productId): void {
    if (productId) {
      this.productService.getProductById(productId).subscribe(productDetailDto => {
        this.product = productDetailDto;
        if (this.product) {
          if (this.product.pictures.length > 0 && this.product.pictures[0].pictureMap[PictureTypeDto.DETAIL]) {
            this.productPictureHint = this.product.pictures[0].pictureMap[PictureTypeDto.DETAIL];
          }
          this.setCommitmentProduct();
          this.coreProductInShoppingCart = this.preCalculatedShoppingCart.getTariffProductInCartByCategoryId(
            this.product.id,
            CategoryTypeEnum.PRODC_SU_CORE_GSM
          );
        }
      });
    } else {
      throwError('ProductId is required.');
    }
  }

  ngAfterViewChecked(): void {
    /*    if (this.productGalleryComponent) {
          this.productGalleryComponent.move('prev', false);
        }*/
  }

  setProductOrderId(orderItemDto: OrderItemDto): void {
    if (orderItemDto) {
      this.cartOrderItemDto = orderItemDto;
      if (orderItemDto.parentOrderItemId) {
        const parentOrderItem = this.shoppingCartService.preCalculatedShoppingCart.getOrderItemById(
          orderItemDto.parentOrderItemId
        );
        if (parentOrderItem) {
          this.productService.getProductById(parentOrderItem.productId).subscribe(product => {
            this.parentTariff = product;
          });
        }
      }
    } else {
      this.cartOrderItemDto = undefined;
    }
  }

  tariffSelectionChanged(selectedTariffId): void {
    if (selectedTariffId) {
      this.selectedTariffId = selectedTariffId;
    } else {
      this.tariffHwProduct = undefined;
      this.selectedTariffId = undefined;
      this.filterHwTariffProduct(null);
    }
    this.getTariffHwProduct(selectedTariffId);
  }

  getTariffHwProduct(selectedTariffId): void {
    this.productService.getProductById(selectedTariffId).subscribe(tariffProduct => {
      if (tariffProduct) {
        this.filterHwTariffProduct(tariffProduct);
      }
    });
  }

  hwLeasingChanged(selectedHwLeasingId: string): void {
    if (selectedHwLeasingId) {
      this.selectedHwLeasingId = selectedHwLeasingId;
      this.productService.getProductById(this.selectedHwLeasingId).subscribe(hwLeasingDetail => {
        if (hwLeasingDetail) {
          this.selectedHwLeasingDetail = hwLeasingDetail;
        }
      });
    } else {
      this.selectedHwLeasingId = undefined;
      this.selectedHwLeasingDetail = undefined;
    }
  }

  filterHwTariffProduct(tariffProduct): void {
    this.filter.attributes.parametersStatic = { sapCode: this.product.parametersStatic['sapCode'] };
    if (!tariffProduct) {
      this.filter.attributes.categoryId = CategoryTypeEnum.PRODC_FREE_HW;
    }
    this.productService
      .getProductsByFilter(this.filter, null, tariffProduct ? tariffProduct.productCode : '')
      .subscribe(productSearchResults => {
        this.tariffHwProduct = productSearchResults.data[0];
      });
    this.filter.attributes.parametersStatic = {};
    this.filter.attributes.categoryId = CategoryTypeEnum.PRODC_SU_HW;
  }

  goBack(): void {
    this.location.back();
  }

  changeDetailPicture(productPictureHint: ProductPictureHrefDto): void {
    this.productPictureHint = productPictureHint;
  }

  configure(): void {
    if (this.iccidAttributeConfigurationComponent && this.iccidAttributeConfigurationComponent.validate() === true) {
      //ToDo save configuration
      this.router.navigate(['/eshop/shopping-cart']);
    }
  }

  handleProductAddingToCart(product: ProductDetailDto): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(currentOrder, product as ProductAddingToCart, this.selectedTariffId)
        .subscribe((order: OrderDto) => {
          if (order) {
            if (this.testHwLeasing()) {
              const hw = order.orderItems.find((orderItem: OrderItemDto) => {
                return product.id === orderItem.productId;
              });

              this.orderingService
                .addProductToShoppingCartWithParent(
                  order,
                  this.selectedHwLeasingDetail as ProductAddingToCart,
                  this.selectedTariff.productId,
                  this.selectedTariffIds[0]
                )
                .subscribe((order2: OrderDto) => {
                  if (order2) {
                    this.router.navigate(['eshop/shopping-cart']);
                  }
                });
            } else {
              this.router.navigate(['eshop/shopping-cart']);
            }
          }
        });
    };
    this.orderingService.getCurrentOrder().subscribe(addOrderItem);
  }

  open(target, content): void {
    this.targetImage = target.src;
    this.modalService.open(content, { windowClass: 'modal product-detail-modal' });
  }

  canDisplayBrandName(): boolean {
    return (
      !_.isNil(this.product.parametersStatic) &&
      PrecalculatedShoppingCart.isProductWithShipment(this.coreProductInShoppingCart)
    );
  }

  canDisplayProductCommitmentInfo(): boolean {
    return this.isTariff() && !_.isNil(this.commitmentProduct);
  }

  isTariff(): boolean {
    return ProductUtils.isTariff(this.product);
  }

  setCommitmentProduct(): void {
    if (_.isNil(this.preCalculatedShoppingCart)) {
      this.getCommitmentProductFromProductService();
    } else {
      const commitmentProductInShoppingCart = this.preCalculatedShoppingCart.getTariffProductInCartByCategoryId(
        this.product.id,
        CategoryTypeEnum.PRODC_SU_COMMIT
      );
      if (_.isNil(commitmentProductInShoppingCart)) {
        this.getCommitmentProductFromProductService();
      } else {
        this.commitmentProduct = commitmentProductInShoppingCart.productDetail;
      }
    }
  }

  private getCommitmentProductFromProductService(): void {
    this.blockUIElement.start();
    const filter: ProductFilter = {
      attributes: {
        categoryId: CategoryTypeEnum.PRODC_SU_COMMIT,
      },
      sorting: {
        column: ProductSortAttributeDto.NAME,
        sortOrder: SortOrderDtoEnum.Desc,
      },
      paging: {
        page: 1,
        pageSize: 4,
      },
    };

    this.productService
      .getProductsByFilter(filter, null, this.product.productCode)
      .pipe(
        switchMap(pagedProductInfosDto => {
          if (pagedProductInfosDto.data.length) {
            return this.productService.getProductById(pagedProductInfosDto.data[0].id);
          } else {
            return of(null);
          }
        }),
        finalize(() => this.blockUIElement.stop())
      )
      .subscribe(productDetail => (this.commitmentProduct = productDetail));
  }

  /**
   * Test if the context product is HW to lease.
   */
  testHwLeasing(): boolean {
    return ProductUtils.isOfCategory(this.product, CategoryTypeEnum.PRODC_SU_HW_LEASING);
  }
}
