import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodebookService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-delivery-contact-form',
  templateUrl: './delivery-contact-form.component.html',
  styleUrls: ['./delivery-contact-form.component.scss'],
})
export class DeliveryContactFormComponent implements OnInit {
  @Input() parentGroup: FormGroup;
  @Input() contactType: string;
  @Input() simpleContact: boolean;
  @Input() contactId;
  @Input() readOnly;

  deliveryContactForm: FormGroup;

  preferredChannels = ['EMAIL', 'SMS', 'NONE', 'ALL'];

  constructor(private fb: FormBuilder, public codebookService: CodebookService) {
  }

  ngOnInit() {
    if (this.parentGroup) {
      if (!this.simpleContact) {
        this.deliveryContactForm = this.fb.group({
          type: [this.contactType ? this.contactType : null],
          firstName: [null],
          preferredChannel: ['SMS'],
          middleName: [null],
          lastName: [null],
          phone1: [null],
          email: [null, [Validators.email]],
          id: null,
        });
        if (this.readOnly) {
          this.deliveryContactForm.disable();
        }
        this.parentGroup.setControl(
          this.contactId ? `contact${this.contactId}` : 'deliveryContact',
          this.deliveryContactForm
        );
      } else {
        this.deliveryContactForm = this.fb.group({
          type: [this.contactType ? this.contactType : null],
          fullName: [null],
        });
        this.parentGroup.addControl(`simpleContact${this.contactId}`, this.deliveryContactForm);
      }
    }
  }
}
