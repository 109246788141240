import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { takeUntil } from 'rxjs/operators';
import { ComplexFilter } from '@btl/btl-fe-wc-common';
import { ReportFrontendService, ReportTypeDto } from '@btl/order-bff';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
})
export class ReportFilterComponent extends ComplexFilter {
  filterForm = this.formBuilder.group({
    areas: [],
    types: [],
  });

  responseReportTypes;
  reportAreas: Array<ReportTypeDto>;
  reportTypes: Array<ReportTypeDto>;
  toggleEntitySelection: boolean;

  @Output()
  readonly onChangeAreaEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly onChangeTypeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private reportFrontendService: ReportFrontendService
  ) {
    super();

    this.reportFrontendService
      .getReportTypes(null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.responseReportTypes = result.data;
        if (result.data?.length > 0) {
          this.reportAreas = [...new Map(this.responseReportTypes.map(item => [item.area, item])).values()];
        }

      });
  }

  ngOnInit(disableInitSearch?:boolean) {

  }

  protected getDefaultFilterValues() {
    return {
      areas: '',
      types: '',
    };
  }

  onChangeArea(event) {
    if (event) {
      this.filterForm.controls['types'].reset();
      if (event.area) {
        this.reportTypes = this.responseReportTypes.filter(area => area.area === event.area);
      }
      this.onChangeAreaEvent.emit(event);
    }
  }

  onChangeType(event) {
    if (event) {
      this.onChangeTypeEvent.emit(event);
    }
  }
}
