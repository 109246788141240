/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AppBlockerService,
  AuthFactoryService,
  AuthService,
  BlockTemplateComponent,
  CodebookService,
  CompareType,
  CurrentLocaleService,
  DmsService,
  ElasticsearchService,
  EnableDynamicLoading,
  FormUtils,
  QuoteService,
  ServiceUtils,
  StickyMessageService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {
  AccountDto,
  AddressDto,
  ContactDto,
  CustomerAccountDto,
  CustomerDto,
  DmsFileDto,
  DynamicEnumService,
  OrderDto,
  OrderParamDto,
  RoleDto,
  TicketAttachmentDto,
  TicketDto,
  TicketNoteDto,
  TicketTypeDto,
  TicketTypeParamDto
} from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Attachment } from '../../../form-attachments/form-attachments.component';
import { forkJoin } from 'rxjs';
import { WcOrderingService } from '@service/wc-ordering.service';
import { from } from 'rxjs/internal/observable/from';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { TicketService } from '@service/ticket.service';
import { Observable } from 'rxjs/internal/Observable';
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFactoryService } from '@service/modal-factory.service';
import { CustomerFormComponent } from '../../../page/customer-form/customer-form.component';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import {
  FormAddressSearchInputComponent
} from '../../../form-address-search-input/form-address-search-input.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import {
  GenerateDocumentEntityType
} from '../../../entity-generate-document-modal/entity-generate-document-modal.component';
import { QuoteDetailsPopupComponent } from './quote-details-popup/quote-details-popup.component';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';
import {
  ContactEditPopupComponent
} from '../../ecare-user-account/user-account-contacts/contact-edit-popup/contact-edit-popup.component';
import { environment } from '../../../../../environments/environment';
import {
  CreateCustomerPopupComponent
} from '../../ecare-homepage/create-customer-popup/create-customer-popup.component';
import { TicketEditComponent } from '../../tickets/edit/ticket-edit.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import QuoteStateDtoEnum = OrderDto.QuoteStateDtoEnum;

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
})
@EnableDynamicLoading({ customName: OpportunityDetailsComponent.PAGE_ID })
export class OpportunityDetailsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'OpportunityDetailsComponent';
  pickerInputType = PickerInputType;

  formParameters: TicketTypeParamDto[] = [];

  pageId(): string {
    return OpportunityDetailsComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketId = this.params.id;

      const isNewOpportunity = !ticketId || ticketId === 'newTicket';

      this.authService = this.authFactoryService.getAuthService();
      this.ticketingService
        .getTicketTypes()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(ticketTypes => {
          this.ticketTypeConfiguration = ticketTypes.find(
            type => type.code === OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE
          );

          this.formParameters = this.ticketTypeConfiguration.parameters.filter(param =>
            param.paramMetas.find(metaParam => metaParam.name === 'guiElementType' && metaParam.value === 'configurableForm')
          );

          if (isNewOpportunity) {
            this.newTicket = true;
            this.editMode = true;

            if (this.duplicationId) {
              this.ticketService
                .getTicketForDuplication(this.duplicationId, this.forDuplication)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(ticket => this.reloadTicket(ticket, true));
            } else {
              const references = [];
              if (this.customerLocalStorageService.getCurrentCustomer()) {
                references.push({
                  refType: 'GENERAL',
                  entityType: 'com.emeldi.ecc.be.crm.dto.Customer',
                  entityId: this.customerLocalStorageService.getCurrentCustomer()?.id,
                });

                if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
                  references.push({
                    refType: 'GENERAL',
                    entityType: 'com.emeldi.ecc.be.crm.dto.CustomerAccount',
                    entityId: this.customerLocalStorageService.getCurrentCustomerAccount()?.id,
                  });
                }
              }

              this.ticket = {
                id: null,
                type: {
                  code: OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE,
                },
                subject: null,
                description: null,
                businessState: 'NOTE',
                assignmentState: 'NOT_ASSIGNED',
                parameters: [],
                attachments: [],
                notes: [],
                references: references,
                priority: this.ticketTypeConfiguration?.defaultPriority,
              };

              if (this.customerLocalStorageService.getCurrentCustomer()) {
                this.contacts.push(...this.customerLocalStorageService.getCurrentCustomer().contacts);
                if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
                  this.contacts.push(...this.customerLocalStorageService.getCurrentCustomerAccount().contacts);
                }
                this.setPreferredContact(this.contacts[0], 0);
              } else {
                this.contacts = [];
              }

              this.reloadTicket(this.ticket, true);
            }
          } else {
            this.loadTicket(ticketId, true);
          }
        });
    } else {
      this.ticket = undefined;
    }
  }

  public static readonly OPPORTUNITY_TICKET_TYPE = 'OPPORTUNITY';

  private readonly opportunityTicketParams: Set<string> = new Set<string>([
    TicketService.OPPORTUNITY_TYPE_PARAM_NAME,
    TicketService.OPPORTUNITY_STATE_PARAM_NAME,
    TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME,
    TicketService.EXPECTED_REVENUE_PARAM_NAME,
    TicketService.CURRENT_OPERATOR_PARAM_NAME,
    TicketService.REJECT_REASON_PARAM_NAME,
    TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME,
    TicketService.OPPORTUNITY_CONTACT_PARAM_NAME,
    TicketService.PREFERRED_CONTACT_PARAM_NAME,
    TicketService.TEAM_ASSIGNMENT_PARAM_NAME,
  ]);

  @ViewChild('formAddressSearchInputComponent', { static: true })
  formAddressSearchInputComponent: FormAddressSearchInputComponent;

  @ViewChild('customerForm', { static: false })
  customerFormComponent: CustomerFormComponent;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  quoteStateDtoEnum = QuoteStateDtoEnum;

  ticket: TicketDto;
  editedNote: TicketNoteDto;
  customer: CustomerDto;
  contacts: ContactDto[] = [];
  relatedTasks: Array<TicketDto> = [];
  relatedOrders: Array<OrderDto> = [];
  relatedQuotes: Array<OrderDto> = [];
  attachments: Attachment[] = [];

  editMode = false;
  newTicket: boolean = false;

  form: FormGroup;

  authService: AuthService;

  existingDmsFiles: Set<string> = new Set();
  salesTeamRoles: RoleDto[];
  userSalesTeamRoles: RoleDto[] = [];

  selectedContactIndex = -1;
  relatedTaskId: string;
  duplicationId: string;
  forDuplication: string[];
  phoneNumber = null;
  ticketTypeConfiguration: TicketTypeDto;
  contactTypes = ContactDto.TypeDtoEnum;
  newContactForm = false;
  ticketEntityType = [];
  showAddressForm = false;

  constructor(
    protected router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private modalFactory: ModalFactoryService,
    private ngbModal: NgbModal,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private ticketService: TicketService,
    private customerService: CustomerService,
    public codebookService: CodebookService,
    private aclService: AclService,
    private dmsService: DmsService,
    private authFactoryService: AuthFactoryService,
    private orderingService: WcOrderingService,
    private quoteService: QuoteService,
    private stickyMessageService: StickyMessageService,
    public currentLocaleService: CurrentLocaleService,
    public customerLocalStorageService: CustomerLocalStorageService,
    protected route: ActivatedRoute,
    private propertyAccessorService: PropertyAccessorLocalService,
    private elasticSearchService: ElasticsearchService,
    private dynamicEnumService: DynamicEnumService
  ) {
    super(router, route);
    this.dynamicEnumService
      .getEnumEntries(TicketEditComponent.SOURCE_NAME, TicketEditComponent.TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketEntityType = result.data.map(value => value.name).sort();
        this.showAddressForm =
          this.ticketEntityType.filter(entityType => entityType === 'com.emeldi.ecc.be.address.dto.Address').length > 0;
      });

    this.route.queryParams.subscribe(queryParams => {
      this.relatedTaskId = queryParams['relatedTaskId'];
      this.forDuplication = queryParams['forDuplication'];
      this.duplicationId = queryParams['duplicationId'];
    });
  }

  loadTicket(ticketId, buildForm = false) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (this.relatedTaskId) {
          TicketService.addReference(result, 'com.emeldi.ecc.be.ticket.dto.Ticket', this.relatedTaskId);

          const id = result.id;
          result.id = null;
          TicketService.clearFieldsBeforeUpdate(result);
          this.ticketingService
            .updateTicket(id, result)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(savedTicket => {
              this.reloadTicket(savedTicket, buildForm);
            });
        } else if (this.duplicationId) {
          result.id = null;
          result.recordVersion = null;

          TicketService.clearFieldsBeforeUpdate(result, true);
          this.reloadTicket(result, buildForm);
        } else {
          this.reloadTicket(result, buildForm);
        }
      });
  }

  reloadTicket(ticketDto: TicketDto, buildForm = false) {
    this.ticket = ticketDto;
    if (buildForm) {
      this.buildForm();
    } else {
      this.form.patchValue(this.ticket);
      this.patchFormWithTicketParams();
    }

    this.editedNote = null;
    this.customer = null;
    this.relatedTasks = [];
    this.relatedOrders = [];
    this.relatedQuotes = [];
    this.ticket.attachments?.forEach(attachment => this.existingDmsFiles.add(attachment.extId));

    this.loadCustomer();
    this.loadRelatedTasks();
    this.loadRelatedOrdersAndQuotes();
    this.loadAttachments();
    this.addAddressToForm();
  }

  checkPreferredContact() {
    const preferredContactString = this.getOpportunityParamValue(TicketService.PREFERRED_CONTACT_PARAM_NAME);
    if (preferredContactString) {
      const preferedContact = JSON.parse(preferredContactString);
      this.selectedContactIndex = this.contacts.findIndex(
        contact => contact.phone1 === preferedContact.phone1 && contact.email === preferedContact.email
      );
    }
  }

  addAddressToForm() {
    const address = TicketService.getReferenceByType(this.ticket, 'com.emeldi.ecc.be.address.dto.Address');
    if (address) {
      const properties: Map<string, any> = new Map<string, any>();
      properties.set('_id', address.entityId);
      this.elasticSearchService
        .query(ElasticsearchService.ADDRESS_INDEX, null, null, properties, null, 1, 1)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(map(oph => oph.hits.hits))
        .subscribe(addresses => {
          if (addresses && addresses.length > 0) {
            this.form.get('address').patchValue(addresses[0]?._source);
          }
        });
    }
  }

  getOpportunityParamValue(paramName: string, defaultValue: string = ''): string {
    const opportunityParam = this.ticket?.parameters.find(param => param.name === paramName);
    return opportunityParam ? opportunityParam.value : defaultValue;
  }

  getOrderAttributeValue(order: OrderDto, attrName: string): string {
    return order.orderAttributes.find(findParam => findParam.attr === attrName)?.value;
  }

  getQuoteLabel(quote: OrderDto): string {
    // todo: translate product code to product name
    return quote.orderItems?.map(oi => oi.productId).join(', ');
  }

  edit() {
    this.editMode = true;
    this.form.enable();

    this.checkNewContact();
  }

  changeTicketField(fieldName: string, event, saveWhenEditMode = false) {
    if (this.opportunityTicketParams.has(fieldName)) {
      this.setOpportunityTicketParameter(fieldName, event);
    } else {
      this.ticket[fieldName] = event;
    }
    if (saveWhenEditMode) {
      this.updateOpportunityDetail();
    }
  }

  private updateOpportunityDetail() {
    if (!this.editMode && !this.newTicket) {
      this.saveChanges(true);
    }
  }

  saveChanges(skipFormValidation = false) {
    if (!skipFormValidation) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return;
      }
    }

    this.ticket.attachments = this.attachments.map((attachment: Attachment): TicketAttachmentDto => {
      return { extId: attachment.extId, type: attachment.type };
    });
    this.ticket.subject = this.getFormControlValue('subject');
    this.ticket.assignedTo = this.getFormControlValue('assignedTo');
    this.ticket.description = this.getFormControlValue('description');
    this.ticket.priority = this.getFormControlValue('priority');
    this.ticket.slaDueDate = this.getFormControlValue('slaDueDate');
    this.setOpportunityTicketParameter(TicketService.TEAM_ASSIGNMENT_PARAM_NAME, this.getFormControlValue('salesTeam'));
    this.setOpportunityTicketParameter(
      TicketService.OPPORTUNITY_TYPE_PARAM_NAME,
      this.getFormControlValue('opportunityType')
    );
    this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_STATE_PARAM_NAME, this.getFormControlValue('status'));
    this.setOpportunityTicketParameter(
      TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME,
      this.getFormControlValue('probability')
    );
    this.setOpportunityTicketParameter(
      TicketService.EXPECTED_REVENUE_PARAM_NAME,
      this.getFormControlValue('expectedRevenue')
    );
    this.setOpportunityTicketParameter(
      TicketService.CURRENT_OPERATOR_PARAM_NAME,
      this.getFormControlValue('currentOperator')
    );
    this.setOpportunityTicketParameter(TicketService.VISITED_PAGE_URL_PARAM_NAME, null);

    this.formParameters.forEach(param => {
      return this.setOpportunityTicketParameter(param.name, JSON.stringify(this.getFormControlValue(param.name)))
    });

    const address = this.getFormControlValue('address');
    this.ticket.references = this.ticket.references.filter(
      ref => ref.entityType !== 'com.emeldi.ecc.be.address.dto.Address'
    );
    if (address) {
      this.ticket = TicketService.addReference(this.ticket, 'com.emeldi.ecc.be.address.dto.Address', address.adrRefNo);
    }
    if (this.newTicket) {
      this.setOpportunityTicketParameter(TicketService.REJECT_REASON_PARAM_NAME, null);
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME, null);
    }
    TicketService.replaceReferenceByType(
      this.ticket,
      'com.emeldi.ecc.be.crm.dto.Customer',
      this.getFormControlValue('cuRefNo')?.id
    );
    TicketService.replaceReferenceByType(
      this.ticket,
      'com.emeldi.ecc.be.crm.dto.CustomerAccount',
      this.getFormControlValue('caRefNo')?.id
    );

    if (this.selectedContactIndex < 0 && this.form.get('newContact')) {
      const newContact = (this.form.get('newContact') as FormGroup).getRawValue();
      this.setPreferredContact(newContact);
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME, JSON.stringify(newContact));
    } else {
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME, null);
    }

    this.ticket.notes.forEach(note => {
      note.created = null;
      note.createdBy = null;
      note.modified = null;
      note.modifiedBy = null;
    });
    if (this.ticket.recordVersion) {
      this.appBlockerService.block();
      this.saveTicket();
    } else {
      this.ticketingService
        .createTicket(this.ticket)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => this.saveFiles(result));
    }
  }

  handleAttachmentsChange(eventAttachments: Attachment[]) {
    this.attachments = eventAttachments.filter(attachment => !attachment['delete']);
    this.updateOpportunityDetail();
  }

  private saveTicket() {
    const id = this.ticket.id;
    this.ticket.id = null;
    TicketService.clearFieldsBeforeUpdate(this.ticket);

    this.ticketingService
      .updateTicket(id, this.ticket)
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => this.saveFiles(result));
  }

  private saveFiles(ticket: TicketDto) {
    const calls = this.attachments
      ?.filter(attachment => !this.existingDmsFiles.has(attachment.extId))
      .map(attachment => this.getCreateFileCall(attachment.dmsFile));
    if (this.customer?.id) {
      const id = this.customer.id;
      this.customer.id = null;
      calls.push(this.customerService.updateCustomer(id, this.customer));

      if (this.form.controls.caRefNo.value) {
        calls.push(
          this.customerService.updateCustomerAccount(
            this.form.controls.caRefNo.value.id,
            this.form.controls.caRefNo.value
          )
        );
      }
    }
    if (calls.length) {
      forkJoin(calls)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(results => {
          this.finalizeSave(ticket);
        });
    } else {
      this.finalizeSave(ticket);
    }
  }

  finalizeSave(ticket: TicketDto) {
    if (this.newTicket) {
      this.navigateSelf({
        id: ticket.id,
      });
    } else {
      this.editMode = false;
      this.reloadTicket(ticket);
    }
  }

  private getCreateFileCall(file: DmsFileDto): Observable<DmsFileDto> {
    file.size = null;
    file.contentHref = null;
    return this.dmsService.createFile(file);
  }

  discardChanges() {
    this.modalFactory.discardChangesModal(
      dialogReference => {
        this.saveChanges();
        dialogReference.dismiss();
      },
      dialogReference => {
        this.form.reset();
        this.newTicket = false;
        this.editMode = false;
        this.reloadTicket(this.ticket);
        dialogReference.dismiss();
      }
    );
  }

  cancel() {
    if (this.newTicket) {
      this.navigateSibling('EcareOpportunitiesComponent');
    } else {
      if (this.ticket.id) {
        this.reloadTicket(this.ticket);
      }
      this.editMode = false;
      this.form.disable();
      this.checkNewContact();
    }
  }

  deleteTicket() {
    this.modalFactory.deleteEntityModal((dialogReference: NgbModalRef) => {
      this.ticketingService
        .deleteTicket(this.ticket.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.opportunityDeleted');
          this.navigateSibling('EcareOpportunitiesComponent');
          dialogReference.dismiss();
        });
    });
  }

  quoteToOrder(quote: OrderDto) {
    this.quoteService
      .quoteToOrder(quote.id, this.ticket.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.ticket = TicketService.addReference(this.ticket, 'com.emeldi.ecc.be.order.dto.Order', order.id);

        this.saveTicket();

        this.relatedOrders.push(order);
        this.orderingService.getOrderByRefNo(order.id).subscribe(order => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.quoteToOrder.success');
        });
      });
  }

  private setOpportunityTicketParameter(paramName: string, paramValue: string = null) {
    const existingParam = this.ticket.parameters.find(param => param.name === paramName);
    if (existingParam) {
      existingParam.value = paramValue;
    } else {
      this.ticket.parameters.push({ name: paramName, value: paramValue });
    }
  }

  private loadSalesTeamRoles() {
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'parameters',
      compareType: null,
      value: [{ name: 'isSalesRole', value: true }],
    });
    this.aclService
      .filterRoles(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(roles => {
        this.salesTeamRoles = roles.data;

        const responseRolesIds = roles.data.map(role => role.id);
        const userSalesRole = this.authService.getUserRoles()?.find(role => responseRolesIds.includes(role));
        if (this.newTicket && userSalesRole) {
          this.form.get('salesTeam')?.setValue(userSalesRole);
        }
      });
  }

  setPreferredContact(contact: ContactDto, index?: number) {
    if (this.selectedContactIndex === index || this.newContactForm) {
      return;
    }
    const newContactSerialized = JSON.stringify(contact);
    if (this.getOpportunityParamValue(TicketService.PREFERRED_CONTACT_PARAM_NAME) === newContactSerialized) {
      return;
    }
    this.changeTicketField(TicketService.PREFERRED_CONTACT_PARAM_NAME, newContactSerialized);
    if (index >= 0) {
      this.selectedContactIndex = index;
    }
  }

  private getFormControlValue(controlName: string): any {
    return this.form.controls[controlName].value;
  }

  private loadRelatedTasks() {
    const relatedTicketsIds = this.ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket')
      .map(reference => reference.entityId);
    if (relatedTicketsIds.length) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareTypeDtoEnum.IN,
        value: relatedTicketsIds,
      });
      this.ticketingService
        .getTicketsByFilter(search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.relatedTasks = result.data;
        });
    }
  }

  private loadRelatedOrdersAndQuotes() {
    const relatedOrdersIds = this.ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.order.dto.Order')
      .map(ref => ref.entityId);
    if (relatedOrdersIds?.length) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareTypeDtoEnum.IN,
        value: relatedOrdersIds,
      });
      // todo: use getOrdersByFilter (BFF API must be changed)

      const relatedOrdersObservables = relatedOrdersIds.map(orderId => this.orderingService.searchOrders(orderId));
      forkJoin(relatedOrdersObservables)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(orders => {
          orders.forEach(order => {
            if (order.orderItems.length > 0) {
              if (order.orderType === 'SALES') {
                this.relatedOrders.push(order);
              } else if (order.orderType === 'QUOTE') {
                this.relatedQuotes.push(order);
              }
            }
          });
        });
    }
  }

  private loadAttachments() {
    this.attachments = this.ticket.attachments
      .filter(attachment => attachment.extId)
      .map(attachment => {
        return { extId: attachment.extId, type: attachment.type };
      });
  }

  private loadCustomer() {
    const referenceCustomer = this.ticket.references.find(
      reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
    );
    const paramCustomer = this.getOpportunityParamValue(TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME);
    // J.Faust confirmed that referenced Customer is preferred before Customer stored in ticket parameter
    if (referenceCustomer) {
      this.customerService
        .getCustomer(referenceCustomer.entityId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          const caRefNo = this.ticket.references.find(
            reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
          )?.entityId;

          if (caRefNo) {
            const ca: CustomerAccountDto = this.customerLocalStorageService.getPartyByIdRecursive(
              result.childParties,
              caRefNo
            );
            this.form.controls.caRefNo.patchValue(ca);
          }
          if (!this.editMode) {
            this.form.disable();
          }
          this.customerChanged(result, false);
          this.form.controls.cuRefNo.patchValue(this.customer);
        });
    } else if (paramCustomer) {
      this.customer = JSON.parse(paramCustomer);
      this.checkNewContact();
    } else {
      this.checkNewContact();
    }

    if (!this.editMode) {
      this.form.disable();
    }
  }

  private buildForm() {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
      if (queryParams) {
        this.phoneNumber = queryParams['phoneNumber'];
      }
      this.form = this.formBuilder.group({
        subject: [this.ticket.subject, Validators.required],
        opportunityType: [null, Validators.required],
        status: [null, Validators.required],
        assignedTo: [this.ticket.assignedTo],
        salesTeam: [null],
        slaDueDate: [this.ticket.slaDueDate],
        probability: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
        priority: [this.ticket.priority, Validators.required],
        expectedRevenue: [null],
        currentOperator: [null],
        description: [this.ticket.description, Validators.required],
        formAttachments: [],
        cuRefNo: [],
        caRefNo: [],
        address: [],
      });

      this.form.addControl(
        'newContact',
        this.formBuilder.group({
          firstName: [null],
          lastName: [null],
          email: [null, Validators.email],
          phone1: [this.phoneNumber],
        })
      );
      this.form.controls.newContact.setValidators(this.phoneOrEmailRequires);

      if (this.ticketTypeConfiguration) {
        this.form
          .get('priority')
          .setValidators([
            Validators.min(this.ticketTypeConfiguration.minPriority),
            Validators.max(this.ticketTypeConfiguration.maxPriority),
          ]);
      }

      if (this.newTicket) {
        this.setAssignedToUser();
      }
      this.patchFormWithTicketParams();
      this.loadSalesTeamRoles();
      this.formParameters.forEach(param => {
        this.form.addControl(param.name, new FormControl(this.getOpportunityParamValue(param.name)));
      });
    });
  }

  phoneOrEmailRequires(formGroup: AbstractControl): any {
    const email = (formGroup as FormGroup).controls['email'].value;
    const phone1 = (formGroup as FormGroup).controls['phone1'].value;
    return !email && !phone1 ? { phoneOrEmailRequires: true } : null;
  }

  private patchFormWithTicketParams() {
    this.form
      .get('opportunityType')
      .setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_TYPE_PARAM_NAME, null));
    this.form.get('status').setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_STATE_PARAM_NAME, null));
    this.form
      .get('probability')
      .setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME));
    this.form
      .get('expectedRevenue')
      .setValue(this.getOpportunityParamValue(TicketService.EXPECTED_REVENUE_PARAM_NAME, null));
    this.form.get('currentOperator').setValue(this.getOpportunityParamValue(TicketService.CURRENT_OPERATOR_PARAM_NAME));
    this.form.get('salesTeam').setValue(this.getOpportunityParamValue(TicketService.TEAM_ASSIGNMENT_PARAM_NAME, null));
  }

  addQuote() {
    const newOrder = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
      ScenarioTypeEnum.QUOTE_MANAGEMENT
    );
    newOrder.orderType = 'QUOTE';
    newOrder.quoteState = QuoteStateDtoEnum.InProgress;
    OrderUtils.addAttributeToOrder(newOrder, 'quoteVersion', '1');
    OrderUtils.addAttributeToOrder(
      newOrder,
      'quoteValidFor',
      JSON.stringify({ startDateTime: null, endDateTime: null })
    );
    OrderUtils.addAttributeToOrder(newOrder, 'qEffectiveQuoteCompletionDate', null);
    if (!this.customer?.id) {
      const customer = this.customer;
      const address = this.form.controls.address.value;
      if (address) {
        customer.address = CustomerPartyUtil.getDefaultAddress(AddressDto.TypeDtoEnum.REGISTRATION);
        customer.address.city = address.city;
        customer.address.street = address.street;
        customer.address.streetNumber = address.streetNo;
        customer.address.buildingNumber = address.buildingNo;
        customer.address.zipCode = address.zip;
        customer.address.district = address.district;
        customer.address.country = address.countryCode;
      }

      let contactJson = TicketService.getParamValue(this.ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME);
      if (!contactJson) {
        contactJson = TicketService.getParamValue(this.ticket, TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
      }

      const contact = JSON.parse(contactJson);
      customer.contact = CustomerPartyUtil.getDefaultContact(ContactDto.TypeDtoEnum.PRIMARY);
      customer.contact.firstName = contact.firstName;
      customer.contact.lastName = contact.lastName;
      customer.contact.email = contact.email;
      customer.contact.phone1 = contact.phone1;

      OrderUtils.addCustomerAttributeToOrder(newOrder, customer);
    }

    this.orderingService.createOrderObservable = null;
    this.orderingService.removeCurrentOrderFromContext();
    this.orderingService.createOrder(newOrder, false).subscribe(newOrder => {
      if (this.customer?.id) {
        this.customerLocalStorageService.setCurrentCustomer(this.customer, false);
      }
      this.router.navigateByUrl('/');
    });

    TicketService.setLocalOpportunity(this.ticket);
  }

  handleCurrentOperatorChange(operatorCode: string) {
    if (typeof operatorCode !== 'string') {
      this.form.get('currentOperator').setValue(null);
    }
  }

  private setAssignedToUser() {
    from(this.authService.getUserInfo()).subscribe(user => this.form.get('assignedTo').setValue(user.username));
  }

  continueQuote(quote: OrderDto) {
    if (this.isQuoteClickable(quote)) {
      TicketService.setLocalOpportunity(this.ticket);
      this.router.navigate([`/eshop/shopping-cart/id/${quote.id}`]);
    }
  }

  backToListing() {
    this.location.back();
  }

  addRelatedTask() {
    this.navigateSibling('TicketEditComponent', {
      id: 'newTicket',
      relatedTicketId: this.ticket.id,
      code: 'OPPORTUNITY_TASK',
      backUrl: this.router.url.split('?')[0],
    });
  }

  getDefaultEmailList() {
    let contact = null;
    let contactJson = this.getOpportunityParamValue(TicketService.PREFERRED_CONTACT_PARAM_NAME);
    if (contactJson) {
      contact = JSON.parse(contactJson);
      if (!contact || !contact.email) {
        contactJson = this.getOpportunityParamValue(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
        if (contactJson) {
          contact = JSON.parse(contactJson);
        }
      }
    }

    return contact ? contact.email : null;
  }

  getOpportunityPriorities(): number[] {
    return this.ticketingService.getTicketPriorities(
      this.ticketTypeConfiguration?.minPriority,
      this.ticketTypeConfiguration?.maxPriority
    );
  }

  generateQuoteDocument(quote: OrderDto) {
    const formatTimestampPart = t => (t < 10 ? '0' : '') + t;

    const now = new Date();
    const timestamp =
      now.getFullYear() +
      formatTimestampPart(now.getMonth() + 1) +
      formatTimestampPart(now.getDate()) +
      formatTimestampPart(now.getHours()) +
      formatTimestampPart(now.getMinutes());

    const documentName = `Quote#${quote.id}_${timestamp}.pdf`;
    const locale = this.currentLocaleService.getCurrentLanguage();
    from(this.authService.getUserInfo()).subscribe(user => {
      this.modalFactory.entityGenerateDocumentModal(
        GenerateDocumentEntityType.QUOTE,
        documentName,
        this.quoteService.getGenerateQuoteDocumentUrl(locale, quote.id, user.username),
        (dialogRef, documentName) => this.attachQuoteDocument(quote, documentName, dialogRef)
      );
    });
  }

  attachQuoteDocument(quote: OrderDto, documentName: string, dialogRef: NgbModalRef) {
    from(this.authService.getUserInfo()).subscribe(user => {
      const locale = this.currentLocaleService.getCurrentLanguage();

      this.quoteService
        .attachQuoteDocument(documentName, quote.id, this.ticket.id, user.username, locale)
        .subscribe(updatedTicket => {
          this.reloadTicket(updatedTicket);
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.attachQuoteDocument');
          dialogRef.close();
        });
    });
  }

  getQuoteState(quote: OrderDto, icon?: boolean): string {
    return OpportunityDetailsComponent.getQuoteState(
      quote.quoteState,
      this.getOrderAttributeValue(quote, 'quoteValidFor'),
      icon
    );
  }

  public static getQuoteState(quoteState: QuoteStateDtoEnum, quoteValidFor: string, icon?: boolean): string {
    const processingStates = [QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved];
    if (quoteState && processingStates.includes(quoteState)) {
      if (!quoteValidFor) {
        return 'processing';
      }

      const validFor = JSON.parse(quoteValidFor);
      if (!validFor['endDateTime']) {
        return 'processing';
      }

      if (OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return 'processing';
      }

      if (icon) return 'cancelled';
    }

    return quoteState;
  }

  isExpired(quote: OrderDto) {
    const processingStates = [QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved];
    if (quote.quoteState && processingStates.includes(quote.quoteState)) {
      const quoteValidFor = this.getOrderAttributeValue(quote, 'quoteValidFor');
      if (!quoteValidFor) {
        return false;
      }

      const validFor = JSON.parse(quoteValidFor);
      if (!validFor['endDateTime']) {
        return false;
      }

      if (OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return false;
      }

      return true;
    }
    return false;
  }

  public static dateNotExpired(date: Date) {
    const now = new Date();
    return (
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() && date.getMonth() > now.getMonth()) ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() >= now.getDate())
    );
  }

  approveQuote(quote: OrderDto) {
    this.propertyAccessorService.getQuotesDefaultValidityPeriod().subscribe(defaultValidityPeriod => {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + defaultValidityPeriod);

      quote.quoteState = QuoteStateDtoEnum.Approved;
      const orderParamsDto: Array<OrderParamDto> = [];
      OrderUtils.updateOrderAttr(
        orderParamsDto,
        'quoteValidFor',
        JSON.stringify({
          startDateTime: startDate,
          endDateTime: endDate,
        })
      );
      this.patchQuote(quote.id, {
        quoteState: quote.quoteState,
        orderAttributes: orderParamsDto,
        recordVersion: quote.recordVersion,
      });
    });
  }

  rejectQuote(quote: OrderDto) {
    quote.quoteState = QuoteStateDtoEnum.Rejected;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', new Date());
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  reviseQuote(quote: OrderDto) {
    const quoteVersionAttribute = this.getOrderAttributeValue(quote, 'quoteVersion');
    const quoteVersionValue = quoteVersionAttribute ? Number(quoteVersionAttribute) + 1 : 1;

    quote.quoteState = QuoteStateDtoEnum.InProgress;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'quoteVersion', quoteVersionValue);
    OrderUtils.updateOrderAttr(
      orderParamsDto,
      'quoteValidFor',
      JSON.stringify({ startDateTime: null, endDateTime: null })
    );
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', null);
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  cancelQuote(quote: OrderDto) {
    quote.quoteState = QuoteStateDtoEnum.Cancelled;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', new Date());
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  private patchQuote(quoteId: string, quoteAsMap: any) {
    this.orderingService.patchOrder(quoteId, quoteAsMap, true).subscribe(quote => {
      CustomerPartyUtil.copyWithExclude(
        quote,
        this.relatedQuotes.find(relQuote => relQuote.id === quote.id)
      );
    });
  }

  isQuoteToOrderVisible(quote: OrderDto): boolean {
    const isStateValid = quote.quoteState === QuoteStateDtoEnum.Approved;
    if (!isStateValid) {
      return false;
    }

    const validForJson = this.getOrderAttributeValue(quote, 'quoteValidFor');
    if (validForJson) {
      const validFor = JSON.parse(validForJson);
      if (!validFor['endDateTime'] || OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return true;
      }
    }
    return false;
  }

  isQuoteClickable(quote: OrderDto): boolean {
    if (quote.quoteState === QuoteStateDtoEnum.InProgress || quote.quoteState === QuoteStateDtoEnum.Pending) {
      const validForJson = this.getOrderAttributeValue(quote, 'quoteValidFor');
      if (validForJson) {
        const validFor = JSON.parse(validForJson);
        if (!validFor['endDateTime'] || OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
          return true;
        }
      }
    }
    return false;
  }

  showQuoteDetailsPopup(quote: OrderDto) {
    const dialogReference = this.ngbModal.open(QuoteDetailsPopupComponent, {
      size: 'md',
      windowClass: QuoteDetailsPopupComponent.POPUP_WINDOW_CSS_CLASSES,
    });
    const quoteDetailsPopup = <QuoteDetailsPopupComponent>dialogReference.componentInstance;
    quoteDetailsPopup.dialogReference = dialogReference;
    quoteDetailsPopup.quote = quote;
    quoteDetailsPopup.saveChangesHandler = (quote: OrderDto) => {
      CustomerPartyUtil.copyWithExclude(
        quote,
        this.relatedQuotes.find(relQuote => relQuote.id === quote.id)
      );
    };
  }

  tasksDeleted() {
    this.reloadTicket(this.ticket);
  }

  customerChanged(customer: any, resetCa = true) {
    this.customer = customer;
    this.contacts.length = 0;

    this.checkNewContact();
    this.setCustomerContact();

    if (resetCa) {
      this.form.controls.caRefNo.patchValue(null);
    }
  }

  setCustomerContact() {
    if (this.customer?.id) {
      this.contacts.length = 0;
      this.contacts.push(...this.customer.contacts.filter(contact => contact.email || contact.phone1));
      if (this.form.controls.caRefNo.value) {
        this.contacts.push(
          ...this.form.controls.caRefNo.value.contacts.filter(contact => contact.email || contact.phone1)
        );
      }

      this.checkPreferredContact();
      if (this.selectedContactIndex < 0 && this.contacts.length > 0) {
        this.setPreferredContact(this.contacts[0], 0);
      } else if (this.contacts.length < 1) {
        this.selectedContactIndex = -1;
      }
    }
  }

  accountChanged(account: AccountDto) {
    this.userSalesTeamRoles = [];
    if (account) {
      this.userSalesTeamRoles.push(
        ...this.salesTeamRoles.filter(role =>
          account.roles.find(
            accountRole => accountRole.roleName === role.id || accountRole.roleName.indexOf('ADMIN') > 0
          )
        )
      );

      if (this.userSalesTeamRoles.length === 0) {
        this.form.controls.salesTeam.patchValue(null);
      } else {
        if (
          this.form.controls.salesTeam.value &&
          !this.userSalesTeamRoles.find(role => role.id === this.form.controls.salesTeam.value)
        ) {
          this.form.controls.salesTeam.patchValue(this.userSalesTeamRoles[0].id);
        }
      }
    } else {
      this.userSalesTeamRoles.push(...this.salesTeamRoles);
    }
    if (this.userSalesTeamRoles.length > 0) {
      this.form.controls.salesTeam.enable();
    } else {
      this.form.controls.salesTeam.disable();
    }
  }

  checkNewContact() {
    const paramContact = this.getOpportunityParamValue(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
    this.useNewContactForm(!this.customer?.id ? true : false);
    if (paramContact) {
      this.form.controls.newContact.patchValue(JSON.parse(paramContact));
    }
  }

  lastCuRefNo = null;
  lastCaRefNo = null;
  lastSelectedContactIndex = null;

  useNewContactForm(useForm: boolean) {
    this.newContactForm = useForm;
    if (useForm && this.editMode) {
      this.form.controls.newContact?.enable();
    } else {
      this.form.controls.newContact?.disable();
    }
  }

  addContact() {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.contact = null;
    contactEditPopupComponent.sourceName = 'crm';
    contactEditPopupComponent.contactType = ContactDto.TypeDtoEnum.OPPORTUNITY;
    contactEditPopupComponent.preferredContactWithoutNone = true;
    contactEditPopupComponent.handler = (contact: ContactDto) => {
      contact.type = ContactDto.TypeDtoEnum.OPPORTUNITY;
      delete contact['address'];
      if (this.form.controls.caRefNo.value) {
        //Maybe patch will be require
        this.form.controls.caRefNo.value.contacts.push(contact);
      } else {
        this.customer.contacts.push(contact);
      }
      this.contacts.push(contact);
      this.setPreferredContact(contact, this.contacts.length - 1);
      modalRef.close();
    };
  }

  editContact(contact: ContactDto, index: number) {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.contact = contact;
    contactEditPopupComponent.sourceName = 'crm';
    contactEditPopupComponent.contactType = ContactDto.TypeDtoEnum.OPPORTUNITY;
    contactEditPopupComponent.preferredContactWithoutNone = true;
    contactEditPopupComponent.handler = (contact: ContactDto) => {
      const contactFromContacts = this.contacts[index];
      this.contacts[index] = contact;
      const indexOfCustomer = this.customer.contacts.indexOf(contactFromContacts);
      if (indexOfCustomer > 0) {
        this.customer.contacts[indexOfCustomer] = contact;
      } else if (this.form.controls.caRefNo.value) {
        const indexOfCA = this.form.controls.caRefNo.value.contacts.indexOf(contactFromContacts);
        this.form.controls.caRefNo.value.contacts[indexOfCA] = contact;
      }

      if (this.selectedContactIndex === index) {
        this.selectedContactIndex = -1;
        this.setPreferredContact(contact, index);
      }
      modalRef.close();
    };
  }

  removeContact(contact: ContactDto) {
    const contactIndex = this.contacts.indexOf(contact);
    this.contacts = this.contacts.filter(loopContact => loopContact != contact);
    const indexOfCustomer = this.customer.contacts.indexOf(contact);
    if (indexOfCustomer > 0) {
      this.customer.contacts = this.customer.contacts.filter(loopContact => loopContact != contact);
    } else if (this.form.controls.caRefNo.value) {
      this.form.controls.caRefNo.value.contacts = this.form.controls.caRefNo.value.contacts.filter(
        loopContact => loopContact != contact
      );
    }

    if (contactIndex === this.selectedContactIndex) {
      this.selectedContactIndex = -1;
      this.setPreferredContact(this.contacts[0], 0);
    } else if (contactIndex < this.selectedContactIndex) {
      this.selectedContactIndex--;
    }
  }

  get appCurrency(): string {
    return environment.currency;
  }

  duplicate() {
    this.ticketService
      .getDuplicateParameters(this.ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSelf(duplicateParamters);
        }
      });
  }

  createCustomerPopup() {
    const modalRef = this.ngbModal.open(CreateCustomerPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const createCustomerComponent = <CreateCustomerPopupComponent>modalRef.componentInstance;
    createCustomerComponent.dialogRef = modalRef;
    createCustomerComponent.handler = (customer: CustomerDto) => {
      this.form.controls.cuRefNo.patchValue(customer);
      this.customerChanged(customer);
    };
  }
}
