<app-documents-popup entityType="Subscription" [entityId]="tariffSpace.id"></app-documents-popup>
<div *ngIf="tariffProduct" class="row tariff-details">
  <div class="col-12 col-xl-6 tariff-info">
    <div class="-product-name">
      <ng-container *ngIf="isPostpaid(); else notPostpaid">
        {{ tariffProduct && tariffProduct['name'] ? tariffProduct['name'] : 'TARIFF' }}
      </ng-container>
      <ng-template #notPostpaid>
        {{ getMsisdn() | phoneNumber }}{{ tariffProduct ? ' - ' + tariffProduct['shortDescription'] : '' }}
      </ng-template>
    </div>
    <span class="-product-code-wrapper" app-acl-internal-evaluate-component>
      <p class="ecare_tariff_product_code">{{ tariffProduct.productCode }} ({{ tariffSpace.id }})</p>
    </span>
    <div class="-tariff-name">
      {{ tariffProduct.shortDescription }}

      <span
        class="ecare-dashboard-icon-wrapper -bg-white"
        tooltipClass="ecare-settings-tooltip"
        placement="bottom"
        ngbTooltip="{{ 'wc.ecare.customerDetails.editName' | translate }}">
        <img class="ecare-dashboard-pencil" src="../../../../assets/img/ecare/edit_icon.svg" width="22" height="22" />
      </span>
    </div>
    <div class="-action-buttons">
      <button
        *ngIf="isPostpaid()"
        id="001_subscription_detail"
        class="btn -btn-tariff-change"
        routerLink="/eshop/tariff-change"
        [queryParams]="{
          tariffSpaceId: tariffSpace.id,
          tariffId: asset.id,
          customerAccountId: customerAccount?.id ? customerAccount.id : tariffSpace.parentId,
          locationId: getLocationId(),
          socketId: getSocketId()
        }">
        <i class="fa fa-exchange tariff-change-icon"></i>
        {{ 'wc.ecare.dashboard.tariffChange.label' | translate }}
      </button>
      <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS">
        <button
          *ngIf="tariffSpace?.assets[0]['state'] !== 'DEACTIVATED'"
          id="008_subscription_detail"
          class="btn -btn-tariff-restriction"
          (click)="restrictTariff()">
          <img src="../../../../assets/img/ecare/ant-design_stop-outlined.svg" /> Tariff restriction
        </button>
      </app-acl-evaluate>
      <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS">
        <button
          *ngIf="tariffSpace?.assets[0]['state'] !== 'DEACTIVATED'"
          id="007_subscription_detail"
          class="btn -btn-tariff-deactivation"
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.dashboard.tariffDeactivation.label' | translate }}"
          (click)="deactivateTariff()">
          <img src="../../../../assets/img/ecare/ionic-ios-power.svg" />
        </button>
      </app-acl-evaluate>
      <button class="btn -btn-tariff-change" (click)="openDocumentPopUp()">
        {{ 'wc.ecare.dashboard.documents.label' | translate }}
      </button>
    </div>
  </div>

  <div class="col-12 col-xl-6 payment-summary">
    <div class="-title">Payment summary</div>
    <ng-container *ngIf="isPostpaid()">
      <div class="-billing-acount">{{ getPayerRefNo() }}</div>
    </ng-container>
    <div class="payment-summary-row">
      <div class="-label -tariff-rc-price-label">{{ 'wc.ecare.dashboard.bundleFee.label' | translate }}</div>
      <div class="-tariff-rc-price">
        <app-recurrent-price *ngIf="isPostpaid()" [price]="tariffProductPrice"></app-recurrent-price>
      </div>
    </div>
    <div *ngIf="tariffAddonsInGroups?.length > 0">
      <div class="payment-summary-row">
        <div class="-label">{{ 'wc.ecare.dashboard.addOnsfee.label' | translate }}</div>
        <div class="-value"><one-time-price [price]="addOnsFee"></one-time-price></div>
      </div>
    </div>
    <div class="payment-summary-row">
      <div class="-label">{{ 'wc.ecare.dashboard.discounts.label' | translate }}</div>
      <div class="-value">
        <span *ngIf="discounts !== 0">-</span><one-time-price [price]="discounts"></one-time-price>
      </div>
    </div>
    <div class="payment-summary-row"></div>
  </div>
</div>

<div class="tariff-banner">
  <div>Upgrade<br /><span class="big">Fix HyperSpeed+ 300</span></div>
  <div>
    300Mbps<br /><img src="../../../../assets/img/ecare/campaign_transfer-arrow-down-circle.svg" /><br /><span
      >download</span
    >
  </div>
  <div>
    20Mbps<br /><img
      src="../../../../assets/img/ecare/campaign_transfer-arrow-down-circle.svg"
      style="transform: rotate(180deg)" /><br /><span>upload</span>
  </div>
  <div>Unlimited<br /><img src="../../../../assets/img/ecare/campaign_transfer-alt.svg" /><br /><span>data</span></div>
  <button
    [routerLink]="['/eshop/tariff-change']"
    [queryParams]="{
      tariffSpaceId: tariffSpace.id,
      tariffId: asset.id,
      customerAccountId: customerAccount?.id ? customerAccount.id : tariffSpace.parentId,
      locationId: getLocationId(),
      socketId: getSocketId()
    }">
    Upgrade for only $59.80
  </button>
</div>

<div class="tariff-shared-units">
  <div class="ecare-header_headline">Tariff shared units</div>
  <app-tariff-shared-units-swiper></app-tariff-shared-units-swiper>
</div>

<div class="tariff-shared-units">
  <div class="ecare-header_headline">Services</div>
  <div class="ecare-services">
    <div class="-basic-data">
      <p class="-commercial-name">{{ fakeTariffService.commercialName }}</p>
      <p class="-custom-name">
        {{ fakeTariffService.customName }}
        <span
          class="ecare-dashboard-icon-wrapper -bg-white"
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.customerDetails.editName' | translate }}">
          <img class="ecare-dashboard-pencil" src="../../../../assets/img/ecare/edit_icon.svg" width="22" height="22" />
        </span>
      </p>
      <p *ngIf="!fakeTariffService.isMobileTariff" class="-contacts">{{ fakeTariffService.identifier }}</p>
      <p *ngIf="fakeTariffService.isMobileTariff" class="-contacts">
        <span style="font-weight: bold; font-size: 1.1em">Phone number</span><br />{{ fakeTariffService.identifier }}
      </p>
    </div>
    <!--    <div class="-swiper">-->
    <!--      <app-services-swiper></app-services-swiper>-->
    <!--    </div>-->
  </div>
</div>
<div *ngIf="fakeTariffServices?.length > 2" class="ecare_show_all_services">
  <button class="btn ecare_show_all_btn">
    <span>Show all services <img src="../../../../assets/img/ecare/arrow-down.svg" /> </span>
  </button>
</div>
<div *ngIf="tariffAddonsInGroups?.length > 0" class="tariff-addons">
  <div class="ecare-header_headline">{{ 'wc.ecare.dashboard.activeAddons.label' | translate }}</div>
  <app-addon-activation-groups
    *ngFor="let addonsInGroup of tariffAddonsInGroups"
    [addonsGroup]="addonsInGroup"
    [partyId]="tariffSpace.id">
  </app-addon-activation-groups>
</div>
