import { Injectable } from '@angular/core';
import {OrderDto, OrderItemDto, ProductDetailDto} from "@btl/order-bff";
import {ProductUtils} from "../helpers/product-utils";
import {CategoryTypeEnum} from "../models/product-filter";
import {ThumbnailMap} from "../models/orders-dtos";
import {Observable} from "rxjs/internal/Observable";
import {ProductService} from "@btl/btl-fe-wc-common";
import {PrecalculatedShoppingCart} from "../models/precalculated-shopping-cart";

@Injectable({
  providedIn: 'root'
})
export class ThumbnailHelperService {

  constructor(private productService: ProductService) { }

  DISALLOWED_THUMBNAIL_PRODUCTS: string[] = [CategoryTypeEnum.PRODC_GE_DELIVERY, CategoryTypeEnum.PRODC_GE_PAYMENT];


  private getProductThumbnail(productDto: ProductDetailDto) {
    let thumbnail = null;
    for (const picture of productDto.pictures) {
      if (picture.pictureMap['PRODUCT_GENERAL_THUMBNAIL']) {
        thumbnail = picture.pictureMap['PRODUCT_GENERAL_THUMBNAIL'].href;
        break;
      }
    }
    return thumbnail;
  }

  private isItemThumbnailAllowed(product: ProductDetailDto) {
    return (
      product.parametersStatic['orderSummary'] == 'true' &&
      !ProductUtils.isOfAnyCategories(product, this.DISALLOWED_THUMBNAIL_PRODUCTS) &&
      (ProductUtils.isOfCategory(product, CategoryTypeEnum.PRODC) ||
        ProductUtils.isOfCategory(product, CategoryTypeEnum.PRD_L))
    );
  }

  getThumbnailMapRecord(productDto: ProductDetailDto) : ThumbnailMap {
    const thumbnail = this.getProductThumbnail(productDto);
    return {
      productId: productDto.id,
      productName: productDto.name,
      thumbnail: thumbnail,
      omit: !this.isItemThumbnailAllowed(productDto),
    }
  }

  assembleUniqueProductIds(orders: OrderDto[]): string[] {
    const assembledCodesGrouped = orders
      .map(order =>
        order.orderItems
          ?.filter(orderItem => orderItem.action === 'ADD' && !orderItem.parentOrderItemId)
          .map(item => item.productId)
      )
      .filter(item => item);
    const assembledCodesConcatenated = [].concat(...assembledCodesGrouped);
    return [...new Set(assembledCodesConcatenated)];
  }

  getProductDetailObservableList(productCodes: string[]): Array<Observable<ProductDetailDto>> {
    const productObservables: Array<Observable<ProductDetailDto>> = [];
    if (productCodes.length > 0) {
      productCodes.forEach(productCode => {
        productObservables.push(this.productService.getProductById(productCode));
      });
    }
    return productObservables;
  }

  isWithShitment(orderItem: OrderItemDto): boolean {
    return PrecalculatedShoppingCart.isOrderItemWithShipment(orderItem);
  }
}
