import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-key-features, [app-key-features]',
  templateUrl: './key-features.component.html',
})
export class KeyFeaturesComponent {
  @Input()
  keyFeatureParams = Array<any>();
}
