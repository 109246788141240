<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.accountDetails.updateDataModal.title' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div *ngIf="response" class="block-info {{ response === 'error' ? 'info-alert' : '' }}">
    <div class="main">
      <div class="icon">
        <i [inlineSVG]="response === 'error' ? '/assets/svg/danger-icon.svg' : '/assets/svg/icon-check-green.svg'"></i>
      </div>
      <div class="info {{ response === 'error' ? 'info-alert' : '' }}">
        <div class="message">
          {{ 'wc.ecare.accountDetails.updateDataModal.' + (response === 'error' ? 'error' : 'success') | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="generated-form" [formGroup]="personalDataForm">
    <div class="col12">
      <app-input-row
        class="input full"
        labelKey="wc.ecare.contact.firstName"
        [translationPrefix]="'wc.ecare.account.updateData'"
        [parentGroup]="personalDataForm"
        [fieldName]="'firstName'" />
    </div>
    <div class="col12">
      <app-input-row
        class="input full"
        labelKey="wc.ecare.contact.lastName"
        [translationPrefix]="'wc.ecare.account.updateData'"
        [parentGroup]="personalDataForm"
        [fieldName]="'lastName'" />
    </div>
    <div class="col12">
      <app-input-row
        itemIconsPath="/assets/img/ecare/mail.svg"
        class="input full"
        labelKey="wc.ecare.contact.email"
        [translationPrefix]="'wc.ecare.account.updateData'"
        [parentGroup]="personalDataForm"
        [fieldName]="'email'" />
    </div>

    <div class="generated-input-container col12">
      <label>{{ 'wc.shopping.customer.birthDate.label' | translate }}</label>
      <div class="input full">
        <app-date-picker
          formControlName="birthDate"
          class="full date-picker"
          style="display: flex"
          [minDate]="{ year: 1900, month: 1, day: 1 }"
          [maxDate]="maxDate"
          [showTime]="false" />
        <app-input-errors
          [translationPrefix]="'wc.ecare.account.updateData.birthDate'"
          [control]="personalDataForm.controls['birthDate']" />
      </div>
    </div>
  </div>
  <app-ecare-user-account-acl />
</div>
<div class="modal-footer">
  <div class="button-wrapper">
    <app-button variant="link" label="{{ 'wc.common.close.button' | translate }}" (click)="closePopup()" />
    <app-button variant="primary" label="{{ 'wc.common.save.button' | translate }}" (click)="postPersonalData()" />
  </div>
</div>
