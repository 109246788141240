import { EventEmitter, Injectable, Output } from '@angular/core';
import { Product } from '../models/product';
import { ComparedProduct } from '../models/compared-product';
import { DmsService, ProductService } from '@btl/btl-fe-wc-common';
import { CodebookFrontendService } from '@btl/order-bff';

@Injectable({
  providedIn: 'root',
})
export class ProductComparisonService {
  comparedProducts: ComparedProduct[] = [];
  COMPARED_PRODUCTS = 'comparedProducts';
  COMPARISON_PAGE_BASE_PATH = '/compare';
  comparisonInProgress = false;

  @Output()
  readonly comparisonChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private codebookService: CodebookFrontendService,
    private productServiceCommon: ProductService,
    private dmsService: DmsService
  ) {}

  /*
  Adds attributes of id, seoURL and product names of given product to array of comparedProducts and save all
  array to the session
   */
  public addToComparison(product: Product, maxProductsInComparison: number) {
    if (this.comparisonInProgress === false) {
      this.comparisonInProgress = true;
      if (!this.comparedProducts) {
        this.comparedProducts = [];
      }
      let productIsAlreadyPresent = false;
      for (const comparedProduct of this.comparedProducts) {
        if (comparedProduct.seoUrl === product.seoUrl) {
          productIsAlreadyPresent = true;
          this.comparisonInProgress = false;
          break;
        }
      }
      if (!productIsAlreadyPresent) {
        if (this.comparedProducts.length >= maxProductsInComparison) {
          // if there are three elements in the comparedProducts array then removes the last one
          this.comparedProducts.splice(maxProductsInComparison - 1);
          this.storeComparedProductsToSession();
        }
        this.comparedProducts.push(
          new ComparedProduct(product.seoUrl, product.name.cs, product.name.en, product.image.thumbnail)
        );
        this.storeComparedProductsToSession();
        this.comparisonChange.emit();
        this.comparisonInProgress = false;
      }
    }
  }

  /*
  Removes specific product from the array of comparedProducts based on its product id
   */
  public removeFromComparison(comparedProductSeoUrl: string) {
    for (let i = 0; i < this.comparedProducts.length; i++) {
      if (this.comparedProducts[i].seoUrl === comparedProductSeoUrl) {
        this.comparedProducts.splice(i, 1);
      }
    }
    this.storeComparedProductsToSession();
    this.comparisonChange.emit();
  }

  /*
  Clears the comparedProducts array and removes the key from session
   */
  public removeAllFromComparison() {
    this.comparedProducts = [];
    sessionStorage.removeItem(this.COMPARED_PRODUCTS);
    this.comparisonChange.emit();
  }

  /*
  Returns array of comparedProducts from session
   */
  public getComparisonList() {
    const sessionComparedProducts = sessionStorage.getItem(this.COMPARED_PRODUCTS);
    this.comparedProducts = JSON.parse(sessionComparedProducts);
    if (!this.comparedProducts) {
      this.comparedProducts = new Array<ComparedProduct>();
    }
    return this.comparedProducts;
  }

  /*
  Helping method which saves whole array of comparedProducts to session
   */
  private storeComparedProductsToSession() {
    sessionStorage.setItem(this.COMPARED_PRODUCTS, JSON.stringify(this.comparedProducts));
  }

  /*
  Common method to get information if specific product is present in the compared products session
   */
  public isProductInComparison(seoUrl: string) {
    const comparedProducts: ComparedProduct[] = this.getComparisonList();
    if (comparedProducts !== undefined && comparedProducts !== null && comparedProducts.length > 0) {
      for (const comparedProduct of comparedProducts) {
        if (seoUrl === comparedProduct.seoUrl) {
          return true;
        }
      }
    }
    return false;
  }
}
