<form class="form" [formGroup]="formUserDetails">
  <app-customer-form
    #customerForm
    [checkoutMode]="true"
    [isVerifiedCustomerRequired]="isVerifiedCustomerRequired"
    [parentGroup]="formUserDetails"
    [account]="account"
    [patchCustomer]="customer"
    [checkFormVisibility]="simpleCustomer"
    [isCustomerSelected]="customerLocalStorageService.getCurrentCustomer() !== null"
    [simpleCustomer]="simpleCustomer"
    (formGeneratedEmitter)="formGenerated()" />

  <app-customer-account-form
    #customerAccountForm
    [parentGroup]="formUserDetails"
    [visible]="!simpleCustomer"
    (formGeneratedEmitter)="formGenerated()" />
</form>
