import { Component, Input } from '@angular/core';
import { DmsFileDto, PictureTypeDto } from '@btl/admin-bff';

@Component({
  selector: 'app-files-add-modal',
  templateUrl: './files-add-modal.component.html',
  styleUrls: ['./files-add-modal.component.scss'],
})
export class FilesAddModalComponent {
  wrongFileFormat;

  @Input()
  dialogRef;

  @Input()
  uploadStep = true;

  @Input()
  pictureTypes = [];

  @Input()
  files = [];

  @Input()
  fileExtensions = [];

  @Input()
  uploadedHandler = (newFile: DmsFileDto) => {};

  uploadedImage;

  selectedType;

  multiple = true;

  select(pictureType: PictureTypeDto) {
    this.selectedType = pictureType;
    this.uploadStep = false;
  }

  fileBrowseHandler(files) {
    for (const fileToUpload of files) {
      if (this.isCorrectFileFormat(fileToUpload.name)) {
        for (const item of files) {
          item.progress = 0;
          this.files.push(item);
        }
        this.uploadFilesSimulator(0);
        this.wrongFileFormat = false;
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.uploadedImage = new Image();
          this.uploadedImage.src = reader.result as string;
          this.uploadedImage.onload = () => {};

          const base64Content = event.target.result.split(',');
          const mimeType = base64Content[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

          const newFile: DmsFileDto = {
            content: event.target.result.split('base64,')[1],
            contentHref: this.uploadedImage.src,
            name: fileToUpload.name,
            mediaType: mimeType,
            size: fileToUpload.size / 1024,
            created: new Date(),
          };

          this.uploadedHandler(newFile);
        };
        reader.readAsDataURL(fileToUpload);
      } else {
        this.wrongFileFormat = true;
      }
    }
  }

  isCorrectFileFormat(fileName: string) {
    if (this.fileExtensions.length > 0) {
      const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (this.fileExtensions.includes(fileFormat)) {
        return true;
      }
      return false;
    }
    return true;
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
}
