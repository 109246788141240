<div class="sidebox-wrapper">
  <ng-container *ngIf="simpleRows && simpleRows.length; else externalContent">
    <div *ngFor="let simpleRow of simpleRows" class="sidebox-simple-row">
      <ng-container *ngIf="simpleRow.link; else plainText">
        <a href="{{ simpleRow.link }}">{{ simpleRow.text }}</a>
      </ng-container>
      <ng-template #plainText>
        {{ simpleRow.text }}
      </ng-template>
    </div>
  </ng-container>
  <ng-template #externalContent>
    <ng-content></ng-content>
  </ng-template>
</div>
