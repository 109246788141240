/* eslint-disable @angular-eslint/prefer-standalone-component */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AttributeTypeEnum, GuiElementTypeEnum } from '../entity-generated-form.component';
import { ValidationFrontendService, ValidationResultDto } from '@btl/order-bff';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-entity-generated-form-input, [app-entity-generated-form-input]',
  templateUrl: './entity-generated-form-input.component.html',
})
export class EntityGeneratedFormInputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() validate: boolean = true;
  @Input() name: string;
  @Input() formIdPrefix: string;
  @Input() attributeOnChangeEvent = null;
  @Input() externalValues = null;
  @Input() externalDefaultValue = null;
  @Input() defaultValue = null;
  @Input() additionalValidators = null;
  @Input() additionalAsyncValidators = null;
  @Input() applyDefaultValues: boolean = true;
  @Input() checkFormVisibility = true;
  @Input() hide = false;
  @Input() form: FormGroup;
  @Input() validationRegex: string;
  @Input() getAssignmentStateFromMinDate: () => Date;
  @Input() getAssignmentStateFromMaxDate: () => Date;
  @Input() typeDetail: string;
  @Input() type: string;
  @Input() sourceName: string;
  @Input() metaParameters: { [key: string]: any };
  @Input() guiStyleClasses: string;

  formValueBeforeOnFocus = {value: null};
  guiElementTypeEnum = GuiElementTypeEnum;
  attributeTypeEnum = AttributeTypeEnum;
  control;

  constructor(private validationFrontendService: ValidationFrontendService) {
  }

  ngOnInit() {
    this.control = this.form.get(this.name);
    if (this.metaParameters['guiVisibility']) {
      this.metaParameters['guiVisible'] = this.metaParameters['guiVisibility'];
    }
    this.createOrUpdateControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    const validateChanges = changes['validate'];
    if (
      validateChanges &&
      validateChanges.currentValue !== validateChanges.previousValue &&
      !validateChanges.firstChange
    ) {
      this.setValidators();
    }
  }

  createOrUpdateControl() {
    if (!this.control) {
      this.control = new FormControl(null, this.getValidators(), this.getAsyncValidators());
      this.form.addControl(this.name, this.control);
    } else {
      this.control.setValidators(this.getValidators());
      this.control.setAsyncValidators(this.getAsyncValidators());
    }
    if (!this.control.value) {
      this.control.patchValue(this.getDefaultValue());
    }
    this.enableDisableAttrControl();
  }

  getDefaultValue() {
    let defaultValue = this.externalDefaultValue;

    if (this.applyDefaultValues && !defaultValue && this.defaultValue) {
      if (this.type === 'INTEGER' || this.type === 'FLOAT') {
        defaultValue = Number(this.defaultValue);
      } else if (this.type === 'BOOLEAN') {
        defaultValue = this.defaultValue === 'true';
      } else if (this.type === 'DATE') {
        defaultValue = new Date(this.defaultValue);
      } else if (this.type === 'ENUM') {
        defaultValue = this.getEnumValues(this.typeDetail).find(enumValue => enumValue === this.defaultValue);
      } else {
        defaultValue = this.defaultValue;
      }
    }
    if (!defaultValue && this.externalValues && this.externalValues) {
      defaultValue = this.externalValues[0]?.value;
    }

    return defaultValue;
  }

  getEnumValues(enumValues: string): string[] {
    return enumValues.split(',');
  }

  enableDisableAttrControl() {
    if (this.metaParameters['isEditable'] !== 'true') {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  setValidators() {
    this.control.setValidators(this.getValidators());
    this.control.setAsyncValidators(this.getAsyncValidators());
    this.control.updateValueAndValidity();
  }

  getValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (
      this.validate &&
      !this.hide &&
      (this.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)
    ) {
      if (this.metaParameters['isMandatory'] === 'true') {
        validators.push(Validators.required);
      }
      if (this.validationRegex) {
        validators.push(Validators.pattern(this.validationRegex));
      }

      if (this.additionalValidators) {
        this.additionalValidators.forEach(validator => validators.push(validator));
      }
    }

    return validators;
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    const validators: AsyncValidatorFn[] = [];

    if (
      this.validate &&
      !this.hide &&
      (this.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)
    ) {
      if (this.additionalAsyncValidators) {
        this.additionalAsyncValidators.forEach(validator => validators.push(validator));
      }
      if (this.metaParameters['validationUrl'] != null) {
        validators.push(
          metaValidator(
            this.validationFrontendService,
            this.metaParameters['validationUrl'],
            this.formValueBeforeOnFocus
          )
        );
      }
    }

    return validators;
  }

  fieldValueChanged() {
    if (this.formValueBeforeOnFocus.value === null) {
      this.formValueBeforeOnFocus.value = this.control.value;
    }
  }
}

export function metaValidator(
  validationFrontendService: ValidationFrontendService,
  validationUrl: string,
  formValueBeforeOnFocus
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> | null => {
    const value = control.value;
    const orginalValue = formValueBeforeOnFocus.value;
    if (value && value != orginalValue && formValueBeforeOnFocus.value != null) {
      const validationUrlSplit = validationUrl.split('/');
      return validationFrontendService.validate(validationUrlSplit[0], validationUrlSplit[1], { value: value }).pipe(
        map((response: ValidationResultDto) => {
          const error = {};
          error[response.message] = true;
          return !response.valid ? error : null;
        })
      );
    }
    return of(null);
  };
}
