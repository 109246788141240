<div class="section" xmlns="http://www.w3.org/1999/html" [style.display]="visible ? '' : visibleStyle">
  <div class="inner" [formGroup]="customerAccountForm">
    <input id="001_customer_account_form" formControlName="extId" type="hidden" />
    <div class="form-group-header">
      <h4 *ngIf="showHeader" id="003_customer_form">{{ 'wc.shopping.customerAccount.heading' | translate }}</h4>

      <div *ngIf="!hideExistingCaList" class="input select">
        <select
          *ngIf="isCustomerAccountsDropdownVisible()"
          id="003_customer_account_form"
          class="select"
          formControlName="id"
          (ngModelChange)="customerAccountPartyChanged($event)">
          <option *ngFor="let customerAccount of getAllCustomerAccounts()" [ngValue]="customerAccount.id">
            {{ customerAccount.id + (customerAccount.displayName ? ' - ' + customerAccount.displayName : '') }}
          </option>
          <option [ngValue]="null">-- create new –-</option>
        </select>
      </div>
    </div>

    <app-section-header
      *ngIf="showHeader"
      id="collapseHeader"
      [title]="'wc.shopping.customerAccount.details.label' | translate"
      [isCollapsed]="refEl.activeIds.length > 0"
      (click)="acc.toggle('toggleSubjectForm')">
    </app-section-header>
    <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleSubjectForm" [destroyOnHide]="false">
      <ngb-panel id="toggleSubjectForm">
        <ng-template ngbPanelContent>
          <ng-container *ngIf="billingCycles && defaultDueDate && entityGenerateForm && paymentTypes">
            <app-entity-generated-form
              formIdPrefix="customerAccount"
              sourceName="crm"
              entityType="CustomerAccount"
              [form]="customerAccountForm"
              [formGroup]="customerAccountForm"
              [resourceIdentification]="{
                paymentType: getCurrentPaymentType(),
                paymentMethod: getCurrentPaymentMethod()
              }"
              [externalValues]="{
                billCycle: billCyclesExternalValues,
                paymentType: [
                  { value: 'POSTPAID', labelKey: 'wc.shopping.customerAccount.paymentType.postpaid' },
                  { value: 'PREPAID', labelKey: 'wc.shopping.customerAccount.paymentType.prepaid' }
                ],
                paymentMethod: getPaymentMethodsExternalValues(),
                billMedium: billMediasExternalValues
              }"
              [defaultValues]="{
                defaultDueData: defaultDueDate
              }"
              [context]="{}"
              [columnsCount]="2"
              (formGeneratedEmitter)="customerAccountFormGenerated()">
            </app-entity-generated-form>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

    <app-address-form
      #invoicingAddressForm
      class="full"
      style="width: 100%"
      formIdPrefix="customerAccount"
      [checkFormVisibility]="false"
      [form]="getFormGroup('address')"
      [type]="AddressTypeEnum.INVOICING.toString()"
      [entityGenerateForm]="true"
      [parentGroup]="customerAccountForm">
    </app-address-form>

    <app-contact-form
      #invoicingContactForm
      class="full"
      style="width: 100%"
      formIdPrefix="customerAccount"
      [checkFormVisibility]="false"
      [type]="ContactTypeEnum.INVOICING.toString()"
      [showAllowContact]="true"
      [form]="getFormGroup('invoicingContact')"
      [entityGenerateForm]="true"
      [parentGroup]="customerAccountForm"
      (allowContactChanged)="allowContactChanged($event, ContactTypeEnum.INVOICING)">
    </app-contact-form>

    <app-contact-form
      #billingContactForm
      class="full"
      style="width: 100%"
      formIdPrefix="customerAccount"
      [checkFormVisibility]="false"
      [type]="ContactTypeEnum.BILLING.toString()"
      [showAllowContact]="true"
      [entityGenerateForm]="true"
      [form]="getFormGroup('billingContact')"
      [parentGroup]="customerAccountForm"
      (allowContactChanged)="allowContactChanged($event, ContactTypeEnum.BILLING)">
    </app-contact-form>
  </div>
</div>
