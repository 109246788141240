<div class="ecare-account-settings -sub-section">
  <div class="headline">
    {{ 'wc.ecare.accountSettings.payments' | translate }}
  </div>

  <div class="section">
    <div class="section-row">
      <ng-container *ngIf="paymentCards">
        <div *ngFor="let paymentCard of paymentCards" class="editable-box">
          <div class="payment-card">
            <div class="data">
              <div class="card-number" [innerHTML]="formatPaymentCardNumber(paymentCard.cardNumber) | safeHtml"></div>
              <div class="expiry-date">{{ paymentCard.expiryDate | date: 'MM / YY' }}</div>
            </div>
            <div class="card-company-logo">
              <img [src]="'/assets/img/ecare/payment-card-' + paymentCard.type + '.png'" />
            </div>
          </div>

          <div class="buttons">
            <button class="btn-link bg-transparent btn-remove">
              <i [inlineSVG]="'/assets/svg/delete.svg'"></i>
              {{ 'wc.ecare.accountSettings.remove' | translate }}
            </button>

            <button class="btn-confirm">
              <i [inlineSVG]="'/assets/svg/edit.svg'"></i>
              {{ 'wc.ecare.accountSettings.editPaymentCard' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="section-row">
      <button class="btn-new btn-add-another">
        <i [inlineSVG]="'/assets/svg/add.svg'"></i>
        {{ 'wc.ecare.accountSettings.addPaymentCard' | translate }}
      </button>
    </div>
  </div>

  <hr class="ecare-separator" />

  <div class="headline">
    {{ 'wc.ecare.accountSettings.shippingAddress' | translate }}
  </div>

  <div class="section">
    <div class="section-row">
      <ng-container *ngIf="shippingAddresses">
        <div *ngFor="let address of shippingAddresses" class="editable-box">
          <div class="shipping-address">
            <div class="data">
              <div class="-value semi-bold">{{ address.firstName }} {{ address.lastName }}</div>
              <div class="-value">{{ address.street }}</div>
              <div class="-value">{{ address.city }}, {{ address.zipCode }}</div>
              <div class="-value">{{ address.state }}</div>
            </div>
          </div>

          <div class="buttons">
            <button class="btn-link bg-transparent btn-remove">
              <i [inlineSVG]="'/assets/svg/delete.svg'"></i>
              {{ 'wc.ecare.accountSettings.remove' | translate }}
            </button>

            <button class="btn-confirm">
              <i [inlineSVG]="'/assets/svg/edit.svg'"></i>
              {{ 'wc.ecare.accountSettings.editShippingAddress' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="section-row">
      <button class="btn-new btn-add-another">
        <i [inlineSVG]="'/assets/svg/add.svg'"></i>
        {{ 'wc.ecare.accountSettings.addShippingAddress' | translate }}
      </button>
    </div>
  </div>
</div>
