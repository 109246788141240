<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ heading | translate }}
  </h4>
  <div *ngIf="showCloseCrossIcon" class="modal-close" (click)="dialogReference.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <ng-container *ngIf="messagesTextKey?.length">
    <div *ngFor="let messageTextKey of messagesTextKey" class="ecare_modal-text">
      {{ messageTextKey | translate }}
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <app-button
    variant="secondary"
    *ngIf="!hideButtonNo"
    (click)="handleNo()"
    label="{{ buttonNoTextKey | translate }}"
    [iconPath]="buttonNoIconPath">
  </app-button>
  <app-button
    variant="primary"
    *ngIf="!hideButtonYes"
    (click)="handleYes()"
    [iconPath]="buttonYesIconPath"
    label="{{ buttonYesTextKey | translate }}"
    >
  </app-button>
</div>

