<div class="modal-header">
  <h5>
    {{ 'wc.ecare.notifications.addRecipient' | translate }}
  </h5>
  <button type="button" class="close" (click)="dialogRef.dismiss()">x</button>
</div>
<div class="modal-body">
  <div class="recipient-modal">
    <form class="recipient-modal-search" [formGroup]="customersSearchFormGroup">
      <input
        class="form-control search-input"
        type="text"
        formControlName="customerSearch"
        placeholder="{{ 'wc.ecare.search.customer.title' | translate }}" />
      <app-button
        variant="primary"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="customerSearch()">
      </app-button>
    </form>
    <div *ngIf="this.getFormArray().length > 0" class="recipient-modal-select">
      <div class="recipient-modal-labels">selected</div>
      <div class="recipient-modal-select">
        <div class="recipients">
          <div *ngFor="let recipient of this.getFormArray().controls" class="recipient border">
            {{ recipient?.value?.recipientName }} <{{ recipient.value.address }}>
            <button type="button" class="btn-link" (click)="remove(recipient)">X</button>
          </div>
        </div>
      </div>
    </div>
    <div class="recipient-modal-results">
      <div class="recipient-modal-labels">search result</div>
      <div class="recipient-modal-results-table">
        <ng-container *ngIf="recipients.length > 0; else recipients_not_found">
          <table class="ecare_listing-table add-recipient-table table">
            <tbody>
              <tr
                *ngFor="let recipient of recipients; let i = index"
                class="ecare_listing-table-row"
                [ngClass]="{ selected: isSelected(recipient) }">
                <td class="add-recipient-table-data">
                  <div class="add-recipient-table-checkbox">
                    <input
                      type="checkbox"
                      [id]="'customer-' + i"
                      [checked]="isSelected(recipient)"
                      (change)="handleCheckbox(recipient)" />
                    <label [for]="'customer-' + i"></label>
                  </div>
                </td>
                <td colspan="2" class="add-recipient-table-data">
                  <div class="data-name">
                    {{ recipient?.recipientName }}
                  </div>
                  <div class="data-address">
                    {{ recipient.address }}
                  </div>
                </td>
                <td class="add-recipient-table-data">
                  {{ getAdditionalInfo(recipient, 'segment') }}
                </td>
                <td class="add-recipient-table-data">
                  {{ getAdditionalInfo(recipient, 'subjectType') }}
                </td>
                <td class="add-recipient-table-data">
                  {{ getAdditionalInfo(recipient, 'identityNumber') }}
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #recipients_not_found>
          <div class="customers-not-found">
            {{ 'wc.ecare.search.noCustomers.text' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="recipient-modal-new-recipient">
      <app-button
        variant="secondary"
        label="{{ 'wc.ecare.notifications.newRecipient' | translate }}"
        iconPath="{{ addNewRecipient ? 'assets/img/ecare/feather-minus.svg' : 'assets/img/ecare/feather-plus.svg' }}"
        (click)="addNewRecipient = !addNewRecipient">
      </app-button>
    </div>

    <ng-template [ngIf]="addNewRecipient">
      <form class="recipient-modal-new-recipient-form" [formGroup]="form">
        <div class="generated-form">
          <div class="generated-input-container">
            <app-input-row
              class="input full"
              fieldName="recipientName"
              translationPrefix="wc.ecare.notifications.name"
              [parentGroup]="form">
            </app-input-row>
            <app-input-row
              class="input full"
              fieldName="address"
              translationPrefix="wc.ecare.notifications.address"
              [parentGroup]="form">
            </app-input-row>
          </div>
        </div>

        <div class="recipient-modal-new-recipient-form-buttons">
          <app-button variant="primary" label="{{ 'wc.ecare.notifications.add' | translate }}" (click)="addRecipient()">
          </app-button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <div>
    <app-button
      variant="whiteWithBorder"
      label="{{ 'wc.common.cancel.button' | translate }}"
      (click)="dialogRef.dismiss()">
    </app-button>
    <app-button variant="primary" label="{{ 'wc.common.add.button' | translate }}" (click)="add()"> </app-button>
  </div>
</div>
