import {
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
  Filter
} from '@btl/btl-fe-wc-common';
import { Component, HostListener, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import moment from 'moment';
import { DynamicEnumService, EnumEntryDto, PagedBusinessEventTypesDto } from '@btl/order-bff';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-events-filter',
  templateUrl: './events-filter.component.html',
})
export class EventsFilterComponent extends ComplexFilter {
  protected advancedFilterFields = [
    'stateDateFrom',
    'stateDateTo',
    'entityType',
    'entityId',
    'eventType',
  ];
  readonly EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.event.enums.BusinessEventRefEntityType';
  readonly SOURCE_NAME = 'event';

  entityTypes: EnumEntryDto[];

  @Input()
  eventTypes: PagedBusinessEventTypesDto;

  filterForm = this.formBuilder.group({
    id: [],
    extId: [],
    stateDateFrom: [],
    stateDateTo: [],
    entityType: [],
    entityId: [],
    eventType: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.extId, 'extId'),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityType, 'refs', 'entityType', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityId, 'refs', 'entityId', null),
    new ControlFilterMiner(this.filterForm.controls.eventType, 'type.code', CompareTypeDtoEnum.IN),

    new ControlFilterMiner(this.filterForm.controls.stateDateFrom, 'happened', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.stateDateTo, 'happened', CompareTypeDtoEnum.SMALLEREQ),
  ];

  @Input()
  constantFilter: Array<Filter> = [];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  constructor(private formBuilder: FormBuilder, private dynamicEnumService: DynamicEnumService) {
    super();

    this.dynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.EXTERNAL_ENTITY_TYPE_ENUM_NAME).subscribe(result => {
      this.entityTypes = result.data;
    });
  }

  protected getDefaultFilterValues() {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getUTCDate() - 30);
    dateFrom.setHours(0, 0, 0, 0);

    return {
      id: null,
      extId: null,
      stateDateTo: null,
      eventType: null,
      stateDateFrom: moment(dateFrom).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
    };
  }
}
