import { Component } from '@angular/core';
import { CmsWidgetBannerComponent } from 'app/components/cms-templates/widgets/banner/cms-widget-banner.component';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';

SwiperCore.use([Pagination, EffectFade, Autoplay]);

@Component({
  selector: 'app-hero-banner, [app-hero-banner]',
  templateUrl: './hero-banner.component.html',
})
export class HeroBannerComponent extends CmsWidgetBannerComponent {}
