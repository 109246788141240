import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  OrderDto,
  ProductDetailDto,
  ProductInfoDto,
  ProductParamMetadataDto,
  ProductSortAttributeDto,
} from "@btl/order-bff";
import {
  BlockTemplateComponent,
  CurrentLocaleService,
  ProductService,
  StickyMessageService
} from "@btl/btl-fe-wc-common";
import { WcOrderingService } from "@service/wc-ordering.service";
import { ProductInShoppingCart } from "../../../models/product-in-shopping-cart";
import { OneTimePriceComponent } from "app/components/page/price/one-time-price.component";
import { ShoppingCartService } from "app/services/shopping-cart.service";
import {
  IccidAttributeConfigurationComponent,
} from "../iccid-attribute-configuration/iccid-attribute-configuration.component";
import { PrecalculatedShoppingCart } from "../../../models/precalculated-shopping-cart";
import { CategoryTypeEnum, ProductFilter } from "../../../models/product-filter";
import _ from "lodash";
import { ProductUtils } from "../../../helpers/product-utils";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { OrderingWizardService } from "@service/ordering-wizard-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductConfigurationComponent } from "../product-configuration/product-configuration.component";
import { ProductCustomService } from "@service/product-custom.service";
import { GroupDto } from "@btl/order-bff/model/groupDto";
import { ProductAddingToCart, ProductListingService } from "@service/product-listing.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { WishListPopupComponent } from "app/components/wizard/wish-list/wish-list-popup/wish-list-popup.component";

/**
 * {@code ShoppingCartItemComponent} is a component responsible for displaying one product in {@link
 * ShoppingCartComponent shopping cart}.
 *
 * Clients of shopping cart item can subscribe changes via {@link onChange}.
 */
@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
})
export class ShoppingCartItemComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  blockTemplate = BlockTemplateComponent;

  @ViewChild('priceOc', { static: false }) priceOcComponent: OneTimePriceComponent;
  @ViewChild('priceRc', { static: false }) priceRcComponent: OneTimePriceComponent;

  //region Data:

  /**
   * The product loaded for {@link productInShoppingCart}.
   */
  product: ProductDetailDto;

  /**
   * A flag specifying if quantity is shown or not.
   */
  canDisplayQuantity = true;

  delete;

  modify;

  /**
   * A flag specifying if special offer for the shopping cart item is collapsed.
   */
  //specialOfferCollapsed = true;

  //region IO:

  /**
   * The precalculated shopping cart.
   */
  @Input()
  precalculatedShoppingCart: PrecalculatedShoppingCart;

  /**
   * The product in the shopping cart to display.
   */
  @Input()
  productInShoppingCart: ProductInShoppingCart;

  @Input()
  mainProductInShoppingCart: ProductInShoppingCart;

  @Input()
  addon: ProductInfoDto;

  /**
   * A flag specifying if quantity change and special offer part is shown or not.
   */
  @Input()
  showQuantityChange = true;

  /**
   * A flag specifying if remove all same product button is shown or not.
   */
  @Input()
  showRemoveBtn = true;

  /**
   * A flag specifying if configuration button is shown or not.
   */
  @Input()
  showConfigurationBtn = true;

  /**
   * Display mode.
   */
  @Input()
  displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';

  /**
   * A flag specifying if special offer can be shown or not.
   */
  @Input()
  showSpecialOffer = false;

  /**
   * A flag specifying if details (product attributes, etc.) can be shown or not.
   */
  @Input()
  showDetails: boolean = false;

  //region Children:

  @Input()
  showImg = false;

  @Input()
  showQuantity: boolean;

  @Input()
  showAddons = false;

  @Input()
  readOnlyOrder: boolean = false;

  showVat: boolean = true;

  activeAddon: boolean = false;

  childProducts: ProductInShoppingCart[] = [];

  discountOCPrice;

  addonsInGroups: Map<GroupDto, Array<ProductInfoDto>>;

  totalOcPriceForActiveAddons = 0;
  totalOcPriceTaxForActiveAddons = 0;

  totalRcPriceForActiveAddons = 0;
  totalRcPriceTaxForActiveAddons = 0;

  @ViewChild(IccidAttributeConfigurationComponent, {static: false})
  private iccidAttributeConfigurationComponent: IccidAttributeConfigurationComponent;

  dynamicProductParameters: Array<ProductParamMetadataDto>;

  private get blockUIElementName(): string {
    return this.productInShoppingCart.productDetail.id;
  }

  constructor(
    private productService: ProductService,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private productCustomService: ProductCustomService,
    private currentLocaleService: CurrentLocaleService,
    public productListingService: ProductListingService,
    private router: Router,
    private ngbModal: NgbModal,
    private stickyMessageService: StickyMessageService,
    private orderingWizardService: OrderingWizardService
  ) {
    this.orderingWizardService.showVat.pipe(takeUntil(this.onDestroy$)).subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });
  }

  getAddons() {
    const assetIds = [this.productInShoppingCart, ...this.productInShoppingCart.children]
      .filter(product =>
        ProductUtils.isOfAnyCategories(product.productDetail, [
          CategoryTypeEnum.PRODC_SU_TARIFF,
          CategoryTypeEnum.PRODC_SU_TP,
          CategoryTypeEnum.PRODC_SU_CORE,
        ])
      )
      .map(product => product.orderItems[0].id);
    if (!assetIds.length) {
      return;
    }

    const filter: ProductFilter = {
      attributes: { categoryId: 'PROD' },
      sorting: { column: ProductSortAttributeDto.NAME, sortOrder: 'desc' },
      paging: { page: 1, pageSize: undefined },
    };
    this.productService
      .getProductsByFilter(filter, assetIds, null, null, 'GUI_ADDON_CATEGORY')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        responseAddons => {
          this.addonsInGroups = new Map<GroupDto, Array<ProductInfoDto>>();

          responseAddons.data.forEach(product => {
            product.groups.forEach(group => {
              let groupProducts = null;
              for (const entry of this.addonsInGroups) {
                if (entry[0].id === group.id) {
                  groupProducts = entry[1];
                }
              }

              if (groupProducts) {
                groupProducts.push(product);
              } else {
                this.addonsInGroups.set(group, [product]);
              }
            });
          });
        },
        errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            this.router.navigate([this.router.url]);
            this.stickyMessageService.addStickyWarningMessage(
              errorResponse.error.failures.pop().key,
              null,
              errorResponse
            );
          }
        }
      );
  }

  ngOnInit() {
    if (!this.productInShoppingCart && this.addon) {
      this.productInShoppingCart = new ProductInShoppingCart(this.addon);
    }
    this.loadProduct();
    if (this.showAddons && this.productInShoppingCart.orderItems && this.productInShoppingCart.orderItems.length > 0) {
      this.getAddons();
    }
    this.getActiveAddons();
  }

  private loadProduct(): void {
    this.delete = this.productInShoppingCart.deleteAction;
    this.modify = this.productInShoppingCart.modifyAction;
    this.product = this.productInShoppingCart.productDetail;
    if (this.productInShoppingCart.children.length > 0) {
      this.findProductToDisplay(this.productInShoppingCart.children);
      this.getActiveAddonsPrices(this.productInShoppingCart.children);
    }
    this.canDisplayQuantity = this.displayQuantity();

    this.productCustomService
      .getVisibleProductParameterMetadata(this.product.categoryId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: Array<ProductParamMetadataDto>) => {
        this.dynamicProductParameters = result
      });
  }

  getActiveAddons() {
    const shoppingCartContainer = document.querySelector('#shoppingCart-container');
    const shoppingCartActiveAddons = document.querySelector('.active-addons_container');
    shoppingCartContainer === null ? shoppingCartActiveAddons?.remove() : undefined;
  }

  getActiveAddonsPrices(childrenProduct) {
    if (childrenProduct) {
      childrenProduct.forEach(child => {
        child.children.forEach(item => {
          if (ProductUtils.isOfCategory(item.productDetail, 'PRODC_GE')) {
            this.totalOcPriceForActiveAddons += item.ocPrice;
            this.totalOcPriceTaxForActiveAddons += item.ocPriceTax;
            this.totalRcPriceForActiveAddons += item.rcPrice;
            this.totalRcPriceTaxForActiveAddons += item.rcPriceTax;
          }
        });
      });
    }
  }

  //region Event handling:

  /**
   * Increase quantity of the product in the shoping cart by 1.
   */
  increaseQuantity(): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(currentOrder, this.product as ProductAddingToCart)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }

  displayQuantity(): boolean {
    let canDisplay: boolean = true;
    for (const categoryId of PrecalculatedShoppingCart.categoriesNotGroupedByQuantity) {
      if (ProductUtils.isOfCategory(this.product, categoryId)) {
        canDisplay = false;
        break;
      }
    }
    return canDisplay;
  }

  /**
   * Decrease quantity of the product in the shoping cart by 1.
   */
  decreaseQuantity(): void {
    const removeOrderItem = (currentOrder: OrderDto) => {
      const orderItemIndex = this.productInShoppingCart.orderItems.length - 1;
      const orderItem = this.productInShoppingCart.orderItems[orderItemIndex];

      this.orderingService.removeOrderItem(currentOrder, orderItem).pipe(takeUntil(this.onDestroy$)).subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItem);
  }

  /**
   * Remove the order item from the cart.
   */
  remove(product?): void {
    if (!product) {
      product = this.productInShoppingCart;
    }
    const removeOrderItems = (currentOrder: OrderDto) => {
      this.orderingService
        .removeProductInShoppingCartFromCart(currentOrder, product)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(null, errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            errorResponse.error.failures.forEach(failure => {
              if (failure.code === 15150) {
                this.router.navigate([this.router.url]);
                this.stickyMessageService.addStickyWarningMessage(failure.key, null, errorResponse);
              }
            });
          }
        });
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItems);
  }

  /**
   * Perform validate() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    if (this.iccidAttributeConfigurationComponent) {
      return this.iccidAttributeConfigurationComponent.validate();
    } else {
      return true;
    }
  }

  /**
   * Perform save() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    if (this.iccidAttributeConfigurationComponent) {
      this.iccidAttributeConfigurationComponent.save(order);
    }
  }

  canDisplayProduct(product): boolean {
    return (
      !_.isNil(product) &&
      PrecalculatedShoppingCart.canDisplayProductInShoppingCart(product) &&
      (!this.readOnlyOrder || !ProductUtils.isOfCategory(product, CategoryTypeEnum.PRODC_GE_PAYMENT))
    );
  }

  checkPrices(productInShoppingCart) {
    if (productInShoppingCart.ocPrice > 0 && productInShoppingCart.rcPrice > 0) {
      return 'multi';
    } else {
      return 'single';
    }
  }

  findProductToDisplay(childrenProduct) {
    if (childrenProduct) {
      childrenProduct.forEach(product => {
        if (this.canDisplayProduct(product.productDetail)) {
          if (product.productDetail.name) {
            this.childProducts.push(product);
          }
        } else {
          this.findProductToDisplay(product.children);
        }
      });
    }
  }

  hasSIMAttribute(product) {
    if (ProductUtils.isOfCategory(product.productDetail, 'PRODB_TP_GSM_SIM')) {
      return true;
    }
  }

  canDisplayCommitmentInfo(): boolean {
    return !_.isNil(this.getChildProductWithCommitmentCategory()) && this.showDetails === true;
  }

  getChildProductWithCommitmentCategory(): ProductDetailDto {
    return ProductUtils.getProductInCartByCategoryId(this.productInShoppingCart, CategoryTypeEnum.PRODC_SU_COMMIT);
  }

  hasLinkToDetail(): boolean {
    return ProductUtils.hasLinkToDetail(this.product);
  }

  isHw(): boolean {
    return PrecalculatedShoppingCart.isProductWithShipment(this.productInShoppingCart);
  }

  hasProductPrice(product) {
    return product.rcPrice != null || product.ocPrice != null;
  }

  isDiscount(product) {
    if (product.productDetail?.parametersStatic['isDiscount'] === 'true') {
      const discountPrice = product.orderItems[0].prices[0];
      if (discountPrice.priceAppType === 'REL') {
        this.discountOCPrice = (this.productInShoppingCart.ocPrice / 100) * discountPrice.priceWithTax;
      } else if (discountPrice.priceAppType === 'ABS') {
        this.discountOCPrice = discountPrice.priceWithTax;
      }
      return true;
    } else {
      return false;
    }
  }

  getPriceType(product) {
    return product.orderItems[0].prices[0].priceAppType;
  }

  configure() {
    this.orderingService
      .getCurrentOrder()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        const modalRef = this.ngbModal.open(ProductConfigurationComponent, {
          size: 'sm',
          backdrop: 'static',
          windowClass: 'dialog dialog-input',
        });

        const productConfigurationComponent = <ProductConfigurationComponent>modalRef.componentInstance;
        productConfigurationComponent.dialogRef = modalRef;
        productConfigurationComponent.order = order;
        productConfigurationComponent.orderItem = this.productInShoppingCart.orderItems[0];
        productConfigurationComponent.product = this.product;
        productConfigurationComponent.dynamicProductParameters = this.dynamicProductParameters;
      });
  }

  childOfRootProduct() {
    return this.mainProductInShoppingCart?.children.find(child => child.productDetail.id === this.addon.id)
      ? true
      : false;
  }

  getAddonParent(productInShoppingCart): ProductInShoppingCart {
    if (productInShoppingCart.orderItems[0].id === this.addon.parentInstanceId) {
      return productInShoppingCart;
    } else if (productInShoppingCart.children) {
      let ret: ProductInShoppingCart = null;
      productInShoppingCart.children.forEach(child => {
        ret = this.getAddonParent(child);
      });

      return ret;
    }
    return null;
  }

  getParentText() {
    const parent = this.getAddonParent(this.mainProductInShoppingCart);
    if (parent) {
      let name = null;
      const customName = parent.productDetail.parametersStatic['customName'];
      if (customName) {
        name = customName;
      } else {
        name = parent.productDetail.name;
      }
      return name;
    }
    return '';
  }

  selectAddon() {
    if (!this.activeAddon) {
      const activeAddon = (currentOrder: OrderDto) => {
        this.orderingService
          .addProductToShoppingCartWithParent(
            currentOrder,
            this.product as ProductAddingToCart,
            null,
            this.addon.parentInstanceId,
            null,
            null,
            this.isHw() ? true : false,
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {});
      };
      this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(activeAddon);
      this.activeAddon = true;
    } else {
      this.activeAddon = false;
    }
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = this.product.id;
  }
}
