import { AfterViewChecked, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderDto } from '@btl/order-bff';
import { AuthorizationService } from '@btl/btl-fe-wc-common';
import { OrderSortingObject, ThumbnailMap } from '../../../../models/orders-dtos';
import { OrderUtils } from 'app/helpers/order-utils';

@Component({
  selector: 'app-ecare-order-table',
  templateUrl: './ecare-order-table.component.html',
})
export class EcareOrderTableComponent implements AfterViewChecked {
  constructor(public authorizationService: AuthorizationService) {}

  @Input()
  displayMode: 'default' | 'lastOderWidget' = 'default';

  COLUMN_NAME_CREATED = 'created';
  COLUMN_NAME_CREATOR = 'creator';
  COLUMN_NAME_STATE_HAPPENED = 'stateHappened';
  COLUMN_NAME_STATE_TYPE = 'orderStateType';
  COLUMN_NAME_ORDER_ID = 'extId';

  SORT_ORDER_ASC_CSS_CLASS = 'sort_asc';
  SORT_ORDER_DESC_CSS_CLASS = 'sort_desc';

  hasRCPrice: boolean = false;
  hasOCPrice: boolean = false;

  @Input() maxThumbnailsToShow: number;
  @Input() canDisplayNoOrdersText: boolean;
  @Input() orders: OrderDto[] = [];
  @Input() opportunity: OrderDto[] = [];
  @Input() selectMode = false;
  @Input() thumbnailMap: ThumbnailMap[];
  @Input() isModuleBffCrmEnabled = true;
  @Input() currentSortOrder: OrderSortingObject[];

  @Output() selectedOrder = new EventEmitter<OrderDto>();
  @Output() selectCustomer: EventEmitter<string> = new EventEmitter();
  @Output() sortByColumn = new EventEmitter<any>();

  ngAfterViewChecked(): void {
    if (this.orders) {
      this.orders.forEach(order => {
        const totalPriceRC = JSON.parse(OrderUtils.getOrderAttributeValue(order, 'totalPriceRC'));
        const totalPriceOC = JSON.parse(OrderUtils.getOrderAttributeValue(order, 'totalPriceOC'));
        if (totalPriceRC !== 0 && totalPriceRC !== null) this.hasRCPrice = true;
        if (totalPriceOC !== 0 && totalPriceOC !== null) this.hasOCPrice = true;
      });
    }
  }

  selectCustomerInTable(customerId: string) {
    this.selectCustomer.emit(customerId);
  }

  itemSelected(orderDto: OrderDto): void {
    this.selectedOrder.emit(orderDto);
  }

  sortTableByColumn(sorting: OrderSortingObject) {
    if (sorting.sortOrder === null) {
      sorting.column === this.COLUMN_NAME_CREATED ? (sorting.sortOrder = 'desc') : (sorting.sortOrder = 'desc');
    }
    this.sortByColumn.emit(sorting);
  }

  getSortOrderCssClass(column: string) {
    const currentSorting = this.getCurrentSorting(column);
    if (currentSorting) {
      return currentSorting === 'asc' ? this.SORT_ORDER_ASC_CSS_CLASS : this.SORT_ORDER_DESC_CSS_CLASS;
    }
    return '';
  }

  getCurrentSorting(column: string, toggle?: boolean) {
    if (this.currentSortOrder) {
      const sortingIndex = this.currentSortOrder.findIndex(sortOrderObj => sortOrderObj.column === column);
      if (sortingIndex !== -1) {
        const currentSorting = this.currentSortOrder[sortingIndex].sortOrder;
        if (toggle) {
          return currentSorting === 'asc' ? 'desc' : 'asc';
        }
        return currentSorting;
      }
    }
    return null;
  }
}
