import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShopSelectionPopupComponent } from './shop-selection-popup.component';
import { PartyElse } from '../../../models/party-holder';
import { addressTypes, DisplayAddressPipe } from '../../../pipes/display-address.pipe';

@Component({
  selector: 'app-form-shop-selection-input-with-modal',
  templateUrl: './form-shop-selection-input-with-modal.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormShopSelectionInputWithModalComponent),
      multi: true,
    },
  ],
})
export class FormShopSelectionInputWithModalComponent implements ControlValueAccessor, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  parentGroup: FormGroup;

  @Input()
  controlName;

  @Input()
  label: string;

  @Input()
  checkAvailability = false;

  value: PartyElse;

  constructor(public ngbModal: NgbModal, public displayAddress: DisplayAddressPipe) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = JSON.parse(value);
  }

  getValue() {
    return JSON.stringify(this.value);
  }

  getDisplayValue(value) {
    const shop: PartyElse = JSON.parse(value);
    return shop
      ? `${shop.displayName} - ${this.displayAddress.transform(shop.address, addressTypes.DASHBOARD_CA_DETAIL)}`
      : '';
  }

  select() {
    const modalRef = this.ngbModal.open(ShopSelectionPopupComponent, { windowClass: 'select-modal' });
    const shopSelectionPopupComponent = <ShopSelectionPopupComponent>modalRef.componentInstance;
    shopSelectionPopupComponent.dialogRef = modalRef;
    shopSelectionPopupComponent.checkAvailability = this.checkAvailability;
    shopSelectionPopupComponent.value = this.value;
    shopSelectionPopupComponent.handler = shop => {
      this.value = shop._source;
      this.parentGroup.controls[this.controlName].patchValue(JSON.stringify(this.value));
      modalRef.close();
    };
  }
}
