import { Injectable } from '@angular/core';
/*import {
  HWStoreMaterialManagementFrontendService,
  MaterialAvailabilityResponseDto,
  ReserveMaterialResponseDto
} from "../o2hw-bff";*/
import { Observable } from 'rxjs';

/**
 * Service for HW store management
 */
@Injectable({
  providedIn: 'root',
})
export class HwStoreMaterialManagementService {
  /*
  constructor(private hwStoreMaterialManagementFrontendService : HWStoreMaterialManagementFrontendService) {  }

  public materialAvailability(productCode: string, bscsSiteCode?: string, quantity?: number, acceptLanguage?: string): Observable<MaterialAvailabilityResponseDto> {
    return this.hwStoreMaterialManagementFrontendService.materialAvailability(productCode, bscsSiteCode, quantity, acceptLanguage);
  }

  public reserveMaterial(bscsSiteCode: string, productCode: string, phoneNumber: string, quantity?: number): Observable<ReserveMaterialResponseDto> {
    return this.hwStoreMaterialManagementFrontendService.reserveMaterial(bscsSiteCode, productCode, phoneNumber, quantity);
  }*/
}
