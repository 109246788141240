<app-ecare-page-layout
  *ngIf="ticket && ticketTypes && dataTypeEnums"
  columnTemplate="2fr 1fr"
  [showBackButton]="cancelCallback ? false : true"
  title="{{ (newTicket ? 'wc.ecare.ticket.newInteraction' : 'wc.ecare.ticket.editInteraction') | translate }} {{
    !newTicket ? '(' + ticket.subject + ')' : ''
  }}">
  <ng-container page-actions>
    <app-button
      variant="primary"
      iconPath="/assets/svg/save.svg"
      label="{{ 'wc.ecare.opportunity.saveChanges' | translate }}"
      (click)="saveChanges()" />
    <app-button variant="link" label="{{ 'wc.common.cancel.button' | translate }}" (click)="cancel()" />
    <app-acl-internal-evaluate-component>
      <app-button
        *ngIf="!relatedTicketId && ticket.recordVersion"
        variant="secondary"
        iconPath="assets/img/ecare/copy_icon.svg"
        label="{{ 'wc.common.duplicate.button' | translate }}"
        (click)="duplicate()" />
    </app-acl-internal-evaluate-component>
    <app-button
      *ngIf="!relatedTicketId && ticket.recordVersion"
      variant="secondary"
      iconPath="/assets/svg/delete.svg"
      label="{{ 'wc.common.delete.button' | translate }}"
      (click)="deleteTicket()" />
  </ng-container>
  <div *blockUI="blockUIElement.name; template: blockTemplate" left-side>
    <!--Ticket overview-->
    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.overview.title' | translate }}">
      <form class="generated-form" [formGroup]="form">
        <div class="generated-input-container col3">
          <label for="ticket-edit-refNo">
            {{ 'wc.ecare.ticket.refNo' | translate }}
          </label>
          <div class="input full">
            <input id="ticket-edit-refNo" disabled [value]="ticket.id" [type]="'text'" />
          </div>
        </div>
        <div class="generated-input-container col3">
          <label for="ticket-edit-area">
            {{ 'wc.ecare.ticket.area' | translate }}
          </label>
          <div class="input full">
            <input id="ticket-edit-area" type="text" disabled [value]="ticket.type?.areaType" />
          </div>
        </div>
        <div class="generated-input-container col3">
          <label for="ticket-edit-type">
            {{ 'wc.ecare.ticket.type' | translate }}
          </label>
          <div class="input full">
            <input id="ticket-edit-type" disabled [value]="ticket.type?.code" [type]="'text'" />
          </div>
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            [parentGroup]="form"
            [controlName]="'subject'"
            [inputType]="'text'"
            [translationPrefix]="'wc.ecare.ticket'" />
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            controlName="description"
            inputType="textarea"
            [parentGroup]="form"
            [translationPrefix]="'wc.ecare.ticket'" />
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            controlName="solution"
            inputType="textarea"
            [parentGroup]="form"
            [translationPrefix]="'wc.ecare.ticket'" />
        </div>
      </form>
    </app-ecare-section>
    <!--Ticket notes-->
    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.notes' | translate }}">
      <app-ticket-notes-form [ticket]="ticket" [showTicketType]="true" />
    </app-ecare-section>

    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.attachments' | translate }}">
      <div [formGroup]="form">
        <div *ngIf="!getAttachmentsFormArray()?.value?.length" [ngTemplateOutlet]="noDataMessage"></div>
        <app-form-attachments
          extIdPrefix="TICKET_"
          dmsType="TICKET_GENERAL"
          formControlName="attachments"
          [control]="form.get('attachments')"
          [types]="['GENERAL']" />
      </div>
    </app-ecare-section>

    <app-acl-internal-evaluate-component class="no-margin">
      <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.steps' | translate }}">
        <ng-container *ngIf="!getStepsFormArray()?.value?.length" [ngTemplateOutlet]="noDataMessage" />
        <div *ngFor="let stepForm of this.getStepsFormArray().controls; let i = index" class="section-widget">
          <form class="generated-form" [formGroup]="stepForm">
            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-name'">{{ 'wc.ecare.ticket.step.name' | translate }}</label>
              <div class="input full">
                <input [id]="i + '_ticket-edit-step-name'" [type]="'text'" [value]="stepForm.value.config.code" />
              </div>
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-description'">
                {{ 'wc.ecare.ticket.step.description' | translate }}
              </label>
              <div class="input full">
                <textarea
                  type="text"
                  rows="4"
                  [id]="i + '_ticket-edit-step-description'"
                  [value]="stepForm.value.config.description"></textarea>
              </div>
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-remark'">
                {{ 'wc.ecare.ticket.step.remark' | translate }}
              </label>
              <div class="input full">
                <textarea
                  type="text"
                  rows="4"
                  formControlName="remark"
                  [id]="i + '_ticket-edit-step-remark'"></textarea>
              </div>
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-workStartDate'">
                {{ 'wc.ecare.ticket.step.workStartDate' | translate }}
              </label>
              <app-date-picker
                class="full date-picker"
                id="ticket-edit-workStartDate"
                formControlName="workStartDate"
                [id]="i + '_ticket-edit-step-workStartDate'"
                [showTime]="true" />
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-workEndDate'">
                {{ 'wc.ecare.ticket.step.workEndDate' | translate }}
              </label>
              <app-date-picker
                class="full date-picker"
                id="ticket-edit-workEndDate"
                formControlName="workEndDate"
                [id]="i + '_ticket-edit-step-workEndDate'"
                [showTime]="true" />
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-expectedDelay'">
                {{ 'wc.ecare.ticket.step.expectedDelay' | translate }}
              </label>
              <app-date-picker
                class="full date-picker"
                id="ticket-edit-expectedDelay"
                formControlName="expectedDelay"
                [id]="i + '_ticket-edit-step-expectedDelay'"
                [showTime]="true" />
            </div>

            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-step-modifiedBy'">{{
                'wc.ecare.ticket.step.modifiedBy' | translate
              }}</label>
              <div class="input full">
                <input [id]="i + '_ticket-edit-step-modifiedBy'" [type]="'text'" [value]="stepForm.value.modifiedBy" />
              </div>
            </div>
          </form>
        </div>
      </app-ecare-section>
    </app-acl-internal-evaluate-component>
    <app-acl-internal-evaluate-component class="no-margin">
      <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.references' | translate }}">
        <ng-container *ngIf="!getReferencesFormArray()?.value?.length" [ngTemplateOutlet]="noDataMessage" />
        <div
          *ngFor="let referenceForm of this.getReferencesFormArray().controls; let i = index"
          class="section-widget"
          [formGroup]="referenceForm">
          <form class="generated-form" [formGroup]="referenceForm">
            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-reference-referenceType'">
                {{ 'wc.ecare.ticket.reference.referenceType' | translate }}
              </label>
              <select
                class="full"
                id="ticket-edit-referenceType"
                formControlName="refType"
                [id]="i + '_ticket-edit-reference-referenceType'">
                <option *ngFor="let refType of ticketReferenceType" [value]="refType">
                  {{ refType }}
                </option>
              </select>
            </div>
            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-reference-entityType'">
                {{ 'wc.ecare.ticket.reference.entityType' | translate }}
              </label>
              <select
                class="full"
                id="ticket-edit-entityType"
                formControlName="entityType"
                [id]="i + '_ticket-edit-reference-entityType'">
                <option *ngFor="let entityType of ticketEntityType" [value]="entityType">
                  {{ entityType }}
                </option>
              </select>
            </div>
            <div class="generated-input-container col12">
              <label [for]="i + '_ticket-edit-reference-entityId'">
                {{ 'wc.ecare.ticket.reference.entityId' | translate }}
              </label>
              <ng-container [ngSwitch]="referenceForm?.controls.entityType.value">
                <app-form-field-modal-picker
                  *ngSwitchCase="'com.emeldi.ecc.be.crm.dto.CustomerAccount'"
                  valueField="id"
                  labelField="displayName"
                  controlName="entityId"
                  [parentGroup]="referenceForm"
                  [pickerType]="pickerInputType.CUSTOMER_ACCOUNT" />
                <app-form-field-modal-picker
                  *ngSwitchCase="'com.emeldi.ecc.be.crm.dto.Customer'"
                  valueField="id"
                  labelField="displayName"
                  controlName="entityId"
                  [parentGroup]="referenceForm"
                  [pickerType]="pickerInputType.CUSTOMER" />
                <app-form-field-modal-picker
                  *ngSwitchCase="'com.emeldi.ecc.be.order.dto.Order'"
                  valueField="id"
                  labelField="id"
                  controlName="entityId"
                  [parentGroup]="referenceForm"
                  [pickerType]="pickerInputType.ORDER" />
                <input *ngSwitchDefault formControlName="entityId" [id]="i + '_ticket-edit-reference-entityId'" />
              </ng-container>
            </div>
            <app-button
              variant="secondary"
              iconPath="/assets/svg/delete.svg"
              label="{{ 'wc.ecare.ticket.references.delete' | translate }}"
              (click)="remove(referenceForm)" />
          </form>
        </div>
        <div class="buttons">
          <app-button
            variant="secondary"
            iconPath="/assets/svg/add.svg"
            label="{{ 'wc.ecare.ticket.references.new' | translate }}"
            (click)="addReference()" />
        </div>
      </app-ecare-section>
    </app-acl-internal-evaluate-component>
  </div>
  <div right-side>
    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.coreAttributes' | translate }}">
      <form class="generated-form" [formGroup]="form">
        <div class="generated-input-container col12">
          <app-input-select-form-field
            class="full-width"
            formControlName="businessState"
            translationPrefix="wc.ecare.ticket"
            [options]="ticketBusinessState"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12" app-acl-internal-evaluate-component>
          <app-input-select-form-field
            class="full-width"
            formControlName="assignmentState"
            translationPrefix="wc.ecare.ticket"
            [options]="ticketAssignmentStates"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12" app-acl-internal-evaluate-component>
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'assignmentStateFrom'"
            [inputType]="'datetime'"
            [labelOnTheLeft]="true"
            [disabled]="true" />
        </div>

        <div class="generated-input-container col12" app-acl-internal-evaluate-component>
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'assignedTo'"
            [inputType]="'text'"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12">
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'owner'"
            [inputType]="'text'"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12">
          <app-input-select-form-field
            class="full-width"
            labelOption="label"
            valueOption="value"
            formControlName="priority"
            translationPrefix="wc.ecare.ticket"
            [options]="priorities"
            [translate]="false"
            [labelOnTheLeft]="true" />
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'extId'"
            [inputType]="'text'"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12">
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'followUpDate'"
            [inputType]="'datetime'"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12">
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'slaDueDate'"
            [inputType]="'datetime'"
            [labelOnTheLeft]="true" />
        </div>

        <div class="generated-input-container col12" app-acl-internal-evaluate-component>
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [parentGroup]="form"
            [controlName]="'startFrom'"
            [inputType]="'datetime'"
            [labelOnTheLeft]="true" />
        </div>
      </form>
      <app-sidebox-row
        *ngIf="ticket.recordVersion"
        class="right-form"
        label="{{ 'wc.ecare.ticket.created' | translate }}"
        >{{ ticket.createdBy }}
        <span class="-ticket-date">({{ ticket.created | date : 'dd.MM.yyyy HH:mm' }})</span>
      </app-sidebox-row>
      <app-sidebox-row
        *ngIf="ticket.recordVersion"
        class="right-form"
        label="{{ 'wc.ecare.ticket.modified' | translate }}"
        >{{ ticket.modifiedBy }}
        <span class="-ticket-date">({{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }})</span>
      </app-sidebox-row>
    </app-ecare-section>

    <ng-container *ngIf="getParamsFormArrayControls()?.length">
      <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.parameters' | translate }}">
        <form class="generated-form" [formGroup]="form">
          <ng-container formArrayName="parameters">
            <ng-container
              *ngFor="let paramForm of getParamsFormArrayControls()"
              [formGroup]="paramForm"
              [hidden]="!hideParameter(paramForm.value.name)">
              <ng-container *ngIf="ticketParamConfigByName.get(paramForm.value.name)">
                <div class="generated-input-container col12">
                  <div class="form-field-container">
                    <div class="label">
                      {{
                        ticketParamConfigByName.get(paramForm.value.name).localizedNames[
                          currentLocaleService.getCurrentLanguage()
                        ]
                      }}
                    </div>
                    <ng-container [ngSwitch]="ticketParamConfigByName.get(paramForm.value.name).dataType">
                      <app-date-picker
                        *ngSwitchCase="'DATE'"
                        class="date-picker"
                        formControlName="value"
                        [showTime]="false" />

                      <app-date-picker
                        *ngSwitchCase="'DATE_TIME'"
                        class="date-picker"
                        formControlName="value"
                        [showTime]="true" />

                      <app-input-select-form-field
                        *ngSwitchCase="'CODEBOOK'"
                        inputType="codebook"
                        [codebookType]="ticketParamConfigByName.get(paramForm.value.name).dataTypeDetail"
                        formControlName="value"
                        [showEmptyOption]=true
                        [noLabel]="true" />

                      <app-input-select-form-field
                        *ngSwitchCase="'ENUM'"
                        inputType="enum"
                        formControlName="value"
                        sourceName="ticket"
                        [enumName]="ticketParamConfigByName.get(paramForm.value.name).dataTypeDetail"
                        [showEmptyOption]=true
                        [noLabel]="true" />

                      <input
                        *ngSwitchDefault
                        formControlName="value"
                        [type]="
                          ticketParamConfigByName.get(paramForm.value.name).dataType === 'DECIMAL' ? 'number' : 'text'
                        "
                        placeholder="{{ 'wc.ecare.contactHistoryFilter.paramValue.placeholder' | translate }}" />
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </form>
      </app-ecare-section>
    </ng-container>
  </div>
  <ng-template #noDataMessage>
    <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
  </ng-template>
</app-ecare-page-layout>
