import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { fromEvent, of, Subject } from 'rxjs';
import { AccountService, AppBlockerService, FormUtils } from '@btl/btl-fe-wc-common';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, takeUntil } from 'rxjs/operators';
import { Constants } from '../../constants';
import { AccountDto } from '@btl/order-bff';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-account-input',
  templateUrl: './form-account-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAccountInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormAccountInputComponent),
    },
  ],
})
export class FormAccountInputComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy, OnChanges, AfterViewInit
{
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value;

  @Input()
  disabled = false;

  @Input()
  control: AbstractControl;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean = true;

  form: FormGroup = this.formBuilder.group({
    accountId: [null],
    login: [null, Validators.required],
  });

  @ViewChild('accountSearchInput') accountSearchInput: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private accountService: AccountService,
    protected router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled = changes['disabled'].currentValue;
    }
  }

  ngAfterViewInit() {
    this.initAccountSearch();
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  private initAccountSearch() {
    if (this.accountSearchInput) {
      fromEvent(this.accountSearchInput?.nativeElement, 'keyup')
        .pipe(
          map((event: any) => {
            if (event?.target?.value === '') {
              this.onChange.emit(null);
            }
            return event?.target?.value;
          }),
          filter(res => res?.length > 2),
          debounceTime(Constants.DEBOUNCE_TIME),
          distinctUntilChanged()
        )
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe((text: string) => {
          this.searchAccountByLogin(text);
        });
    }
  }

  searchAccountByLogin(login: string) {
    this.appBlockerService.block();
    this.accountService
      .getAccountByLogin(login, true)
      .pipe(catchError(err => of(null)))
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe((account: AccountDto) => {
        if (account) {
          this.form.get('accountId').patchValue(account.id);
          this.getValue();
          this.onChange.emit(account);
        } else {
          this.form.get('login').setErrors({ notExists: true });
          this.form.get('login').markAsDirty();
          this.onChange.emit(null);
        }
      });
  }

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  private selectAccount(account) {
    if (account && account.login) {
      this.searchAccountByLogin(account.login);
    }
  }

  valueChanged() {
    this.selectAccount(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const ret = this.form.getRawValue();
      ret.login = null;
      return ret;
    }
    return null;
  }
}
