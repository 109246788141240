<div class="modal-header">
  <h4 class="modal-title">
    {{ 'wc.ecare.homepage.createCustomer' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <app-ecare-settings-component [createCustomer]="true" [showBreadcrumbs]="false" (backEvent)="(false)" />
</div>

<div class="modal-footer">
  <div class="button-wrapper">
    <app-button variant="secondary" [label]="'wc.common.close.button' | translate" (click)="closePopup()" />
    <app-button
      variant="primary"
      iconPath="/assets/img/ecare/feather-plus.svg"
      [label]="'wc.ecare.homepage.createCustomer' | translate"
      (click)="saveNewCustomer()" />
  </div>
</div>
