<div class="container newDesing">
  <div *ngIf="product" class="product__detail">
    <div
      class="product__detail-content"
      [class.orientation-right]="orientation && orientation.toUpperCase() === 'RIGHT'">
      <div class="product__detail-gallery">
        <img class="product__detail-galleryImage" alt="{{ product.name }}" src="{{ getPictureHref() }}" />
      </div>

      <div class="product__detail-summary">
        <h1 id="002_product_detail" class="product__detail-title">{{ product.name }}</h1>

        <!-- Product stickers -->
        <div class="product__labels">
          <div *ngIf="product?.stickers" class="product__labels">
            <app-sticker *ngFor="let sticker of sortStickersByPriority(product.stickers)" variant="{{ sticker.type | stickerTypeClass }}">{{ getStickerText(sticker.texts) }}</app-sticker>
          </div>
        </div>

        <div class="product__detail-shortDescription">
          <div [innerHTML]="product.shortDescription | safeHtml"></div>
        </div>

        <!-- Product color component -->
        <div class="product__detail-colors">
          <fieldset *ngIf="productColorVariants" class="color-select">
            <ng-container *ngFor="let productColorVariant of productColorVariants">
              <!-- Color -->
              <div
                class="colour-variant"
                [ngClass]="{ active: productColorVariant.productCode === product.productCode }"
                [routerLink]="['/eshop/product-detail/' + productColorVariant.seoUrl]">
                <div class="colour" [style.background-color]="'rgb(' + productColorVariant.rgb + ')'"></div>
                <div class="text">{{ productColorVariant.name }}</div>
              </div>
            </ng-container>
          </fieldset>
        </div>

        <div class="o2-indent o2-indent--standalone">
          <div *ngIf="stockCentral$ | async as stock" class="o2-indent">
            <div *ngIf="stock.realStock > 0; else noStock">
              <span [innerHTML]="stock.text | safeHtml"></span
              ><span *ngIf="deliveryDate"
                >, {{ 'wc.shopping.gui_productDetail.delivery.deliveryDate' | translate }}
                {{ deliveryDate | deliveryDate }}</span
              >
            </div>
            <ng-template #noStock>
              <span [innerHTML]="stock.text | safeHtml"></span>
              <div *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'">
                <a
                  id="004_product_detail"
                  class="o2-typo--link"
                  href="javascript:void(0)"
                  (click)="openWatchdogSubscriptionModal()">
                  {{ 'wc.shopping.gui_productDetail.delivery.noStockAvailabilityInfo' | translate }}
                </a>
              </div>
            </ng-template>
          </div>
        </div>

        <!-- Product watch dog & comparison -->
        <div class="product__detail-subActions">
          <a *ngIf="product.parametersStatic['wishlistEnabled'] === 'true'" class="link" (click)="wishListPopup()">
            <i class="fa fa-heart" aria-hidden="true"></i>
          </a>

          <a
            *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'"
            id="005_product_detail"
            href="javascript:void(0)"
            class="link"
            title="{{ 'wc.shopping.gui_productDetail.watchdog.formButton' | translate }}"
            (click)="openWatchdogSubscriptionModal()"
            ><i id="006_product_detail" class="fa fa-eye" aria-hidden="true"></i
          ></a>

          <span *ngIf="compareAvailable">
            <a
              *ngIf="!isProductInComparison(product); else showRemove"
              id="007_product_detail"
              class="link"
              title="{{ 'wc.shopping.gui_productDetail.productComparison.addLink' | translate }}"
              (click)="addProductToComparison(product, maxProductsInComparison)">
              <i id="008_product_detail" aria-hidden="true" class="fa fa-clone"></i>
            </a>
            <ng-template #showRemove>
              <a
                id="009_product_detail"
                href="javascript:void(0)"
                class="link"
                (click)="removeProductFromComparison(product)"
                >{{ 'wc.shopping.gui_productDetail.productComparison.removeLink' | translate }}</a
              >
            </ng-template>
          </span>
        </div>

        <!-- Product pricing -->
        <div class="product__detail-pricing" id="hw-price">
          <div class="product__detail-price">
            <div class="product__detail-prices">
              <ng-container *ngIf="!testHwLeasing()">
                <div></div>
                <div *ngIf="product?.prices['OC']" class="product__detail-price">
                  <div class="product__detail-streetPrice">
                    <one-time-price [price]="product.prices['OC'].price"></one-time-price>
                  </div>

                  <div
                    class="product__detail-noTax"
                    [innerHTML]="
                      (product.prices['OC'].price
                        | price: 1 + (product.prices['OC'].tax ? product.prices['OC'].tax : 21) / 100) +
                      ' ' +
                      ('wc.shopping.gui_productDetail.general.withoutVat.label' | translate)
                    "></div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="product__detail-pricingStock">
            <ng-container *ngIf="stockCentral$ | async as stock">
              <a
                *ngIf="stock.realStock > 0; else noStock"
                id="010_cms-widget-cik-cak-phone"
                class="btn btn-default btn-sm btn-buy"
                (click)="addAllToBasket()">
                {{ 'wc.common.buyNow.button' | translate }}
              </a>
            </ng-container>

            <ng-template #noStock>
              <a
                *ngIf="product.parametersStatic['watchdogEnabled'] === 'true'"
                id="011_cms-widget-cik-cak-phone"
                class="btn btn-sm btn-buy -watch"
                (click)="openWatchdogSubscriptionModal()">
                {{ 'wc.shopping.gui_productDetail.watchdog.formButton' | translate }}
              </a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
