import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductDetailDto } from '@btl/order-bff';
import { Observable } from 'rxjs/internal/Observable';
import { StockState } from '@service/stock-custom.service';
import { PictureUtils } from '../../../../../../helpers/picture-utils';
import { PictureTypeEnum } from '../../../../../../models/PictureTypeEnum';
import { ProductUtils } from '../../../../../../helpers/product-utils';
import { Constants } from '../../../../../../constants';
import { StickyBarName, StickyBarService } from '@service/sticky-bar.service';

@Component({
  selector: 'app-sticky-price-bar',
  templateUrl: './sticky-price-bar.component.html',
})
export class StickyPriceBarComponent implements AfterViewChecked {
  @Output() readonly addToBasketAction = new EventEmitter<any>();
  @Output() readonly goToTechnologyCheckAction = new EventEmitter<any>();

  @Input()
  product: ProductDetailDto;
  @Input()
  tariffHwProduct: ProductDetailDto;
  @Input()
  checkScanRequired: boolean;
  @Input()
  stockCentral$: Observable<StockState>;
  @Input()
  selectedTariffId: string;
  @Input()
  priceDiscount: number;

  @ViewChild('stickyBarContent') stickyBarContent: ElementRef;

  priceBoxElemReference: HTMLElement;

  priceBoxElementHeight: number;
  CSS_CLASS_VISIBLE_NAME: string = 'visible';
  stickyPriceBoxCssClass: string = '';

  constructor(private stickyBarService: StickyBarService) {}

  getThumbnailUrl(): string {
    if (ProductUtils.isTariff(this.product)) {
      return '/assets/img/products/tariff_default_product_image.png';
    }
    return PictureUtils.getPictureHrefByType(
      this.product,
      PictureTypeEnum.PRODUCT_GENERAL_THUMBNAIL,
      'assets/img/products/phone_example.png'
    );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.priceBoxElemReference && this.priceBoxElementHeight) {
      const boundsTop = this.priceBoxElemReference.getBoundingClientRect().top;
      if (boundsTop < this.priceBoxElementHeight / 2) {
        this.stickyPriceBoxCssClass = this.CSS_CLASS_VISIBLE_NAME;
        this.stickyBarService.setStickyBarHeight(
          StickyBarName.PRODUCT_DETAIL_PRICE_BAR,
          this.stickyBarContent.nativeElement.offsetHeight
        );
      } else {
        this.stickyPriceBoxCssClass = '';
        this.stickyBarService.setStickyBarHeight(StickyBarName.PRODUCT_DETAIL_PRICE_BAR, 0);
      }
    }
  }

  callAddToBasket() {
    this.addToBasketAction.emit();
  }

  callGoToTechnologyCheck() {
    this.goToTechnologyCheckAction.emit();
  }

  ngAfterViewChecked() {
    if (!this.priceBoxElementHeight) {
      if (!this.priceBoxElemReference) {
        const elem = document.getElementById(Constants.PRICE_BOX_ELEMENT_ID);
        if (elem) {
          this.priceBoxElemReference = elem;
        }
      } else {
        if (this.priceBoxElemReference.offsetHeight && this.priceBoxElemReference.offsetHeight > 0) {
          this.priceBoxElementHeight = this.priceBoxElemReference.offsetHeight;
        }
      }
    }
  }
}
