<div class="product-filter">
  <!-- C001.E009 -->
  <div class="filter__sort-by">{{ 'wc.shopping.productFilter.sortBy.label' | translate }}</div>
  <div class="filter__sort-values">
    <ul class="filter__sort-list">
      <li>
        <!--(click)="handleSortingChange(ProductSortAttributeDto.RATING, SortOrderDtoEnum.DESC)"-->
        <!--[ngClass]="{'active': ProductSortAttributeDto.RATING === filter.sorting.attribute}">-->
        {{ 'wc.shopping.productFilter.sortByRecommended.label' | translate }}
      </li>
      <li
        [ngClass]="{ active: ProductSortAttributeDto.NAME === filter.sorting.column }"
        (click)="handleSortingChange(ProductSortAttributeDto.NAME, SortOrderDtoEnum.Asc)">
        {{ 'wc.shopping.productFilter.sortByProductName.label' | translate }}
      </li>
      <li
        [ngClass]="{
          active:
            ProductSortAttributeDto.PRICE === filter.sorting.column && SortOrderDtoEnum.Asc === filter.sorting.sortOrder
        }"
        (click)="handleSortingChange(ProductSortAttributeDto.PRICE, SortOrderDtoEnum.Asc)">
        {{ 'wc.shopping.productFilter.sortByLowestPrice.label' | translate }}
      </li>
      <li
        [ngClass]="{
          active:
            ProductSortAttributeDto.PRICE === filter.sorting.column &&
            SortOrderDtoEnum.Desc === filter.sorting.sortOrder
        }"
        (click)="handleSortingChange(ProductSortAttributeDto.PRICE, SortOrderDtoEnum.Desc)">
        {{ 'wc.shopping.productFilter.sortByHighestPrice.label' | translate }}
      </li>
    </ul>

    <select class="filter__sort-select" id="sortSelect">
      <option value="">{{ 'wc.shopping.productFilter.sortByRecommended.label' | translate }}</option>
      <option value="">{{ 'wc.shopping.productFilter.sortByProductName.label' | translate }}</option>
      <option value="">{{ 'wc.shopping.productFilter.sortByLowestPrice.label' | translate }}</option>
      <option value="">{{ 'wc.shopping.productFilter.sortByHighestPrice.label' | translate }}</option>
    </select>
  </div>
  <div class="filter__open-filter">
    <a
      id="001_product_filter"
      class="link arrow-down"
      aria-controls="extendedFilter"
      [attr.aria-expanded]="!isCollapsed"
      (click)="isCollapsed = !isCollapsed">
      {{ 'wc.shopping.productFilter.advancedFilter.button' | translate }}
    </a>
  </div>

  <div id="extendedFilter" class="filter__extend-filter" [ngbCollapse]="!isCollapsed">
    <div class="inner">
      <div class="filter-col">
        <div *ngIf="pagedProductInfosDto" class="filter-item">
          <!-- C001.E001 -->
          <label for="myrange">{{ 'wc.shopping.productFilter.priceRange.label' | translate }}</label>
          <div class="price-selector">
            <ng-container>
              <span class="rangeValue -min" id="js_minPrice">
                <one-time-price [price]="pagedProductInfosDto.priceRange.min"></one-time-price>
              </span>
              <div class="rangeInput">
                <nouislider
                  [config]="pricerangeConfig"
                  [max]="pagedProductInfosDto.priceRange.max"
                  [min]="pagedProductInfosDto.priceRange.min"
                  (change)="priceRangeHandler($event)"></nouislider>
              </div>
              <span class="rangeValue -max" id="js_maxPrice">
                <one-time-price [price]="pagedProductInfosDto.priceRange.max"></one-time-price>
              </span>
            </ng-container>
          </div>
        </div>
        <!-- C001.E003 -->
        <div class="filter-item">
          <label>{{ 'wc.shopping.productFilter.searchButton' | translate }} </label>
          <div class="serach-input">
            <input
              id="002_product_filter"
              type="text"
              name="filterSearch"
              placeholder="{{ 'wc.common.search.placeholder' | translate }}"
              [(ngModel)]="filter.attributes.text" />
            <input
              id="003_product_filter"
              type="submit"
              class="btn btn-simple"
              value="{{ 'wc.common.search.button' | translate }}"
              (click)="handleChange()" />
          </div>
        </div>
      </div>
      <div class="filter-col">
        <div class="split">
          <div class="split-item">
            <!-- C001.E004 -->
            <label for="">{{ 'wc.shopping.productFilter.brand.label' | translate }}</label>
            <dynamic-selectbox
              *ngIf="productParameters.map['brandName']"
              ngDefaultControl
              [items]="productParameters.map['brandName']"
              [translate]="false"
              [(ngModel)]="filter.attributes.parametersStatic['brandName']"
              (onChange)="handleBrandNameChange($event)">
            </dynamic-selectbox>

            <!-- C001.E006 -->
            <!--Todo Add when rating will be supported
            <label for="">{{'wc.shopping.productFilter.customerRating.label' | translate}}</label>
            <product-rating rating="{{filter.attributes.ratingReview}}" [editable]="true"
                            (onChange)="handleProductRatingChange($event)"></product-rating>-->
          </div>
          <div class="split-item">
            <!-- C001.E005 -->
            <label for="">{{ 'wc.shopping.productFilter.os.label' | translate }}</label>
            <dynamic-selectbox
              *ngIf="productParameters.map['operatingSystem']"
              ngDefaultControl
              [items]="productParameters.map['operatingSystem']"
              [translate]="false"
              [(ngModel)]="filter.attributes.parametersStatic['operatingSystem']"
              (onChange)="handleOperatingSystemChange($event)">
            </dynamic-selectbox>
            <!-- C001.E007 -->
            <!--<label for="">{{'wc.shopping.productFilter.technicalParameters.label' | translate}}</label>
            <dynamic-selectbox *ngIf="productParameters.map['technicalParameters']"
                               [items]="productParameters.map['technicalParameters']"
                               [(ngModel)]="filter.attributes.parametersStatic['technicalParameters']"
                               (onChange)="handleChange()"
                               [translate]="false"
                               ngDefaultControl>
            </dynamic-selectbox>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
