<div class="ecare login_window">
  <div class="login_window-header">Application login</div>
  <div class="login_window-note">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ante elit. Fusce in ante in tellus semper
    bibendum.
  </div>
  <div class="login_window-form" [ngClass]="loginScreenClass">
    <form class="login_form-login">
      <div class="form-group">
        <label for="001_ecare_login">username</label>
        <input id="001_ecare_login" type="text" class="form-control" />
      </div>

      <div class="form-group">
        <label for="002_ecare_login">password</label>
        <input id="002_ecare_login" type="password" class="form-control" placeholder="********" />
      </div>

      <button id="003_ecare_login" type="submit" class="btn btn-default btn-block">Log me in</button>

      <div class="login_window-forgotPwd">
        <a id="004_ecare_login" (click)="toggleLoginClass()">Unable to login?</a>
      </div>
    </form>

    <form class="login_form-recovery">
      <div class="form-group">
        <label for="005_ecare_login">username</label>
        <input id="005_ecare_login" type="text" class="form-control" />
      </div>
      <button id="006_ecare_login" type="submit" class="btn btn-default btn-block">Reset password</button>

      <div class="login_window-forgotPwd">
        <a id="007_ecare_login" (click)="toggleLoginClass()">Back to login</a>
      </div>
    </form>
  </div>
</div>
