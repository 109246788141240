<div class="breadcrumb-container">
  <a *ngIf="!showBacklink" id="001_breadcrumb_home" [routerLink]="'/'">
    <i class="breadcrumb-home-icon" [inlineSVG]="'/assets/svg/breadcrumb-home.svg'"></i>
    {{ 'wc.common.navigation.home.button'| translate}}
  </a>
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let i = index">
    <div class="breadcrumb-item">
      <i class="breadcrumb-slash" [inlineSVG]="'/assets/svg/breadcrumb-slash.svg'"></i>
      <a
        class="breadcrumb-item-link"
        [routerLink]="breadcrumb.seoUrl"
        [ngClass]="{ lastItem: last }"
        >{{ breadcrumb.name | translate }}</a
      >
    </div>
  </ng-container>
  <div *ngIf="showBacklink" class="breadcrumb-item">
    <a class="breadcrumb-item-link" (click)="goBack()">
      <i class="breadcrumb-back-icon" [inlineSVG]="'/assets/svg/breadcrumb-back.svg'"></i>
      {{ 'wc.ecare.common.back' | translate }}
    </a>
  </div>
</div>
