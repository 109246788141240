import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractPageComponent,
  AccountService,
  AuthFactoryService,
  AuthService,
  CurrentLocaleService,
  EnableDynamicLoading,
  UserInfo
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ecare-user-account-details',
  templateUrl: './ecare-user-account-details.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountDetailsComponent.PAGE_ID })
export class EcareUserAccountDetailsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountDetailsComponent';

  @Input()
  dialogRef;

  userInfo: UserInfo;

  maxDate;

  response: 'ok' | 'error' = null;

  personalDataForm: FormGroup = this.formBuilder.group({
    email: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    birthDate: [null, Validators.required],
  });

  pageId(): string {
    return EcareUserAccountDetailsComponent.PAGE_ID;
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private currentLocaleService: CurrentLocaleService,
    private formBuilder: FormBuilder,
    private keycloakService: KeycloakService,
    private authService: AuthService,
    private authFactoryService: AuthFactoryService,
    private accountService: AccountService,
  ) {
    super(router, route);
    this.authService = this.authFactoryService.getAuthService();
    this.personalDataForm.controls.email.disable();
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  postPersonalData() {
    this.response = null;
    if (this.personalDataForm.valid) {
      this.userInfo.email = this.personalDataForm.get(['email']).value;
      this.userInfo.firstName = this.personalDataForm.get(['firstName']).value;
      this.userInfo.lastName = this.personalDataForm.get(['lastName']).value;
      this.userInfo.attributes['birthdate'] = this.personalDataForm.get(['birthDate']).value;
      from(this.authService.updatePersonalData(this.userInfo))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          this.response = response !== 'error' ? 'ok' : 'error';
          if (response !== 'error') {
            from(this.keycloakService.updateToken(-1))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.accountService
                  .getAccountByLogin(this.userInfo.username)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(result => {
                    this.authService.setAccount(result);
                    this.fillPersonaDataForm();
                  });
              });
          }
        });
    }
  }

  ngOnInit(): void {
    const today = new Date();
    this.maxDate = { year: today.getFullYear(), month: today.getMonth(), day: today.getDate() };
    this.fillPersonaDataForm();
  }

  fillPersonaDataForm() {
    from(this.authService.getPersonalData())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: UserInfo) => {
        this.userInfo = data;
        this.personalDataForm.setValue({
          email: this.userInfo.email,
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          birthDate: this.userInfo.attributes['birthdate'],
        });
      });
  }
}
