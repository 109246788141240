export enum AvailabilityEnum {
  none = 'none',
  stockAvailability = 'stockAvailability',
  storeAvailability = 'storeAvailability',
}

export namespace AvailabilityEnum {
  export function getTranslationKey(availability: AvailabilityEnum): string {
    return `wc.shopping.gui_productListing.filtering.${availability}`;
  }

  export function getOnStockTypes(): Array<string> {
    return [AvailabilityEnum.stockAvailability, AvailabilityEnum.storeAvailability];
  }
}
