<div class="customer_account_card" (click)="selectCustomer()">
  <div class="customer_account_card-header">
    <div class="badge-container">
      <div class="badge">{{ customer.id }}</div>
    </div>
    <h3 class="customer_account_label">
      {{ getCustomerName() }}
    </h3>
    <div>
      {{ customer.address | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
    </div>
  </div>
</div>
