<div class="thankyou-page">
  <div class="wrapper">
    <app-payment-response
      *ngIf="paymentParams && orderDto"
      [orderDto]="orderDto"
      [paymentParams]="paymentParams"
      [customerFirstName]="customerFirstName"
      [customerEmail]="customerEmail"
      (reloadOrderEvent)="ngOnInit()">
    </app-payment-response>

    <div class="cart">
      <div class="heading">
        <div class="order-info">
          <h4>
            {{ 'wc.shopping.thankYou.orderNumber.text' | translate }} <strong>{{ orderId }}</strong>
          </h4>
          <div class="inner">
            <ng-template [ngIf]="timeSlots && timeSlots.length > 0">
              <div class="order-info-item">
                <h5 id="001_thank_you_page">
                  {{ 'wc.shopping.confirmation.deliveryInformation.heading' | translate }}
                </h5>
                <div *ngFor="let timeSlot of timeSlots">
                  <p>
                    {{ timeSlot.timeFrom | date : 'dd.MM.yyyy' }}
                    {{ timeSlot.timeFrom | date : 'HH:mm' }}
                    -
                    {{ timeSlot.timeTo | date : 'HH:mm' }}
                  </p>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="deliveryMethodProduct">
              <div class="order-info-item">
                <h5 id="001_thank_you_page">{{ 'wc.shopping.confirmation.deliveryInfo.method' | translate }}</h5>
                <p>
                  {{ deliveryMethodProduct.name + ' - ' + deliveryMethodProduct.shortDescription }}
                </p>
              </div>
            </ng-template>

            <ng-template [ngIf]="deliveryAddress">
              <div class="order-info-item">
                <h5 id="001_thank_you_page">{{ 'wc.shopping.confirmation.deliveryInfo.address' | translate }}</h5>
                <div>
                  {{ deliveryAddress | displayAddress : addressTypes.SUMMARY_PAGE }}
                </div>
              </div>
            </ng-template>

            <div class="order-info-item">
              <h5 id="002_thank_you_page">{{ 'wc.shopping.confirmation.paymentInformation.heading' | translate }}</h5>
              <div *ngIf="paymentMethodProduct" class="payment-status">
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.NOT_APPLICABLE">
                  <img
                    height="20"
                    alt="{{ paymentMethodProduct.name }}"
                    src="{{
                      paymentMethodProduct.picturePreview
                        ? paymentMethodProduct.picturePreview.href
                        : '../assets/img/payment-methods/' + paymentMethodProduct.productCode + '-logo.png'
                    }}" />
                  <p>{{ paymentMethodProduct.name }}</p>
                  <p>{{ paymentMethodProduct.shortDescription }}</p>
                </ng-template>
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.CREATED && !checkPaymentStatusManually">
                  <div *blockUI="paymentBlockUiElementName; template: blockTemplate">
                    {{ 'wc.shopping.payment.checking.status' | translate }}...
                    <p>{{ 'wc.shopping.thankYou.paymentMayTakeSomeTime' | translate }}</p>
                  </div>
                  <app-button
                    variant="secondary"
                    label="{{ 'wc.shopping.payAgain.button' | translate }}"
                    (click)="payOrder()">
                  </app-button>
                </ng-template>
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.CREATED && checkPaymentStatusManually">
                  <app-button
                    id="003_thank_you_page"
                    variant="primary"
                    label="{{ 'wc.shopping.payment.check.status' | translate }}"
                    (click)="checkStatusManually()">
                  </app-button>
                </ng-template>
                <ng-template
                  [ngIf]="
                    paymentStatus === paymentStatusEnum.COMPLETED || paymentStatus === paymentStatusEnum.APPROVED
                  ">
                  <img
                    height="20"
                    alt="{{ paymentMethodProduct.name }}"
                    src="{{
                      paymentMethodProduct.picturePreview
                        ? paymentMethodProduct.picturePreview.href
                        : '../assets/img/payment-methods/' + paymentMethodProduct.productCode + '-logo.png'
                    }}" />
                  <p>{{ paymentMethodProduct.shortDescription }}</p>
                  <div class="payment-tag sucessfully-paid">
                    {{ 'wc.shopping.PAYMENT_TYPE.' + paymentMethodProduct.productCode + '.info' | translate }}
                  </div>
                </ng-template>
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.FAILED">
                  <div class="payment-tag failure-paid">
                    {{ 'wc.shopping.payment.failed' | translate }}
                  </div>
                </ng-template>
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.CANCELED">
                  <div class="payment-tag failure-paid">
                    {{ 'wc.shopping.payment.canceled' | translate }}
                  </div>
                </ng-template>
                <ng-template [ngIf]="paymentStatus === paymentStatusEnum.TIMEOUTED">
                  <div class="payment-tag time-outed">
                    {{ 'wc.shopping.payment.timeouted' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <app-section-header [title]="'wc.shopping.thankYou.oderDetails.heading' | translate"></app-section-header>
        <app-shopping-cart-items
          #shoppingCartItems
          [readOnlyOrder]="true"
          [notFromShoppingCart]="true"
          [showQuantityChange]="false"
          [showRemoveBtn]="false"
          [showConfigurationBtn]="false"
          [showDetails]="false"
          [displayMode]="'ROLLOUT'">
        </app-shopping-cart-items>
      </div>

      <div *ngIf="invoiceExistence" class="buttons clearfix">
        <a id="005_thank_you_page" class="btn btn-default pull-right" (click)="getInvoice()">
          {{ 'wc.shopping.wizard.generateInvoice.button' | translate }}
        </a>
      </div>

      <div class="thankYou_footer">
        <div
          class="thankYou_footer-button d-print-none"
          app-button
          [label]="'wc.shopping.thankYou.printOrSave' | translate"
          [variant]="'secondary'"
          [iconPath]="'/assets/svg/feather-printer.svg'"
          (click)="printPage()"></div>
        <div
          #orderingWizardActions
          class="thankYou_footer-price_container"
          app-ordering-wizard-actions
          [showPrice]="true"
          [displayMode]="'ROLLOUT'"></div>
      </div>
    </div>
    <div class="return-policy d-print-none">
      <i [inlineSVG]="'assets/svg/feather-package.svg'"></i>
      <p>
        {{ 'wc.shopping.thankYou.satisfied' | translate }}? <a>{{ 'wc.shopping.thankYou.policy.label' | translate }}</a>
      </p>
    </div>
  </div>
</div>
