import { Injectable } from '@angular/core';
import { map, share, switchMap } from 'rxjs/operators';
import { CodebookService, Search, ServiceUtils, StockService } from '@btl/btl-fe-wc-common';
import { CodebookDto, PagedStockDto } from '@btl/order-bff';
import { forkJoin, Observable } from 'rxjs';
import { PropertyAccessorLocalService } from './property-accessor-local.service';
import { PartyElse } from '../models/party-holder';

/**
 * Service for retrieving stock information
 */
@Injectable({
  providedIn: 'root',
})
export class StockCustomService {
  public static readonly CODEBOOK_HW_CENTRAL_STOCK_AVAILABILITY = 'HW_CENTRAL_STOCK_AVAILABILITY';

  constructor(
    private codebookService: CodebookService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private stockService: StockService
  ) {}

  /**
   * Returns object containing physical and virtual stock amount for given product on central store (eshop)
   * Physical stock amount is also translated to text through codebook
   * @param productCode Product to search for
   */
  public getStockCentral(productCode: string): Observable<StockState> {
    const centralStockAvailabilityCodebooks$ = this.codebookService
      .getCodebooks(StockCustomService.CODEBOOK_HW_CENTRAL_STOCK_AVAILABILITY)
      .pipe(share());

    const stockCentral$ = this.propertyAccessorLocalService.getCentralStockCode().pipe(
      switchMap(centralStockName => {
        const search: Search = {
          filtering: [
            {
              column: 'productCode',
              compareType: 'EQUAL',
              value: productCode,
            },
            {
              column: 'store.extId',
              compareType: 'EQUAL',
              value: centralStockName,
            },
          ],
          sorting: [{ column: 'productCode', sortOrder: 'asc' }],
          paging: { pageSize: -1, page: 1 },
        };
        return this.stockService.getStocksByFilter(search);
      })
    );
    return forkJoin(centralStockAvailabilityCodebooks$, stockCentral$).pipe(
      map(([centralStockAvailabilityCodebooks, stock]: [CodebookDto[], PagedStockDto]) => {
        //sum the real and virtual stock amounts
        const realStockCentral = stock.data
          .filter(s => s.type === 'PHYSICAL') // filter PHYSICAL
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts

        const virtualStockCentral = stock.data
          .filter(s => s.type === 'VIRTUAL') // filter VIRTUAL
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts

        const matchingCodebook = StockCustomService.getMatchingCodebook(
          centralStockAvailabilityCodebooks,
          realStockCentral,
          null
        );
        return {
          text: this.codebookService
            .getCodebookText(matchingCodebook)
            .replace('{Stock.realStock}', realStockCentral.toString()),
          realStock: realStockCentral,
          virtualStock: virtualStockCentral,
        };
      }),
      share()
    );
  }

  /**
   * Returns number of physical and virtual stock amount outside of eshop (normal shops)
   * @param productCode Product to search for
   */
  public getStockNonCentral(productCode: string): Observable<StockState> {
    return this.propertyAccessorLocalService.getCentralStockCode().pipe(
      switchMap(centralStockName => {
        const search: Search = {
          filtering: [
            {
              column: 'productCode',
              compareType: 'EQUAL',
              value: productCode,
            },
            {
              column: 'store.extId',
              compareType: 'DIFFERENT',
              value: centralStockName,
            },
          ],
          sorting: [{ column: 'productCode', sortOrder: 'asc' }],
          paging: { pageSize: -1, page: 1 },
        };
        return this.stockService.getStocksByFilter(search);
      }),
      map((stocks: PagedStockDto) => {
        const realStockNonCentral = stocks.data
          .filter(s => s.type === 'PHYSICAL')
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts
        const virtualStockNonCentral = stocks.data
          .filter(s => s.type === 'VIRTUAL')
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts
        return {
          realStock: realStockNonCentral,
          virtualStock: virtualStockNonCentral,
        };
      }),
      share()
    );
  }

  public getPreferredStock(productCode: string, shop: PartyElse): Observable<StockState> {
    const centralStockAvailabilityCodebooks$ = this.codebookService
      .getCodebooks(StockCustomService.CODEBOOK_HW_CENTRAL_STOCK_AVAILABILITY)
      .pipe(share());

    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering = [
      {
        column: 'productCode',
        compareType: 'EQUAL',
        value: productCode,
      },
      {
        column: 'store.ouRefNo',
        compareType: 'IN',
        value: [shop.code],
      },
    ];
    const stockPreferred$ = this.stockService.getStocksByFilter(search);

    return forkJoin([centralStockAvailabilityCodebooks$, stockPreferred$]).pipe(
      map(([centralStockAvailabilityCodebooks, stock]: [CodebookDto[], PagedStockDto]) => {
        //sum the real and virtual stock amounts
        const realStockCentral = stock.data
          .filter(s => s.type === 'PHYSICAL') // filter PHYSICAL
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts

        const virtualStockCentral = stock.data
          .filter(s => s.type === 'VIRTUAL') // filter VIRTUAL
          .map(item => item.amount)
          .reduce((prev, next) => prev + next, 0); // sum amounts

        const matchingCodebook = StockCustomService.getMatchingCodebook(
          centralStockAvailabilityCodebooks,
          realStockCentral,
          'POS'
        );
        return {
          text: this.codebookService
            .getCodebookText(matchingCodebook)
            ?.replace('{OrgUnits.Name}', shop.displayName)
            .replace('{Stock.realStock}', realStockCentral.toString()),
          realStock: realStockCentral,
          virtualStock: virtualStockCentral,
        };
      }),
      share()
    );
  }

  public static getMatchingCodebook(stockAvailabilityCodebooks, stockAmount, type) {
    return stockAvailabilityCodebooks.find(codebook => {
      const stockFrom = parseInt(codebook.parameters.find(param => param.name === 'stockFrom').value);
      const stockTo = parseInt(codebook.parameters.find(param => param.name === 'stockTo').value);
      const storeType = codebook.parameters.find(param => param.name === 'storeType')?.value;
      return (!type || storeType === type) && stockAmount >= stockFrom && (isNaN(stockTo) || stockAmount <= stockTo);
    });
  }
}

export interface StockState {
  text?: string;
  realStock: number;
  virtualStock: number;
}
