import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as olProj from 'ol/proj';
import OLMap from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Select } from 'ol/interaction';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import { Vector } from 'ol/layer';
import { Vector as VectorS } from 'ol/source';
import Style from 'ol/style/Style';
import { Icon } from 'ol/style';
import * as sphere from 'ol/sphere';
import { FormPickUpPointInputComponent, LocationDto, PointDto } from '../form-pick-up-point-input.component';

@Component({
  selector: 'app-open-layer-map',
  template: '<div class="map" id="map"></div>',
})
export class OpenLayerMapComponent implements OnInit, OnChanges {
  @Input()
  points: Array<PointDto>;

  @Input()
  currentLocation: LocationDto;

  @Input()
  lng: number;

  @Input()
  lat: number;

  @Input()
  zoom: number;

  @Input()
  hoveredPoint: PointDto;

  @Input()
  selectedPoint: PointDto;

  @Output()
  readonly pointHoverEmitter = new EventEmitter<PointDto>();

  @Output()
  readonly pointSelectedEmitter = new EventEmitter<PointDto>();

  @Output()
  readonly distancesCalculatedEmitter = new EventEmitter<boolean>();

  map: OLMap;
  olMapPointFeature = new Map<string, Feature>();
  currentLocationOl: Feature;
  selectedFeature: Feature;
  hoveredFeature: Feature;
  selecting: boolean;

  ngOnInit(): void {
    if (!this.map) {
      this.map = new OLMap({
        view: new View({
          center: olProj.fromLonLat([this.lng, this.lat]),
          zoom: this.zoom,
        }),
        target: document.getElementById('map'),
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
      });
    }

    this.points.forEach(point => {
      const pointFeature = this.getPointFeature(point, false);
      this.olMapPointFeature.set(`${point['adrLocation'].lon}-${point['adrLocation'].lat}`, pointFeature);
    });

    const select = new Select({
      style: this.getMarkerStyle(null, false),
    });
    select.on('select', e => {
      this.selectFeature(e.target.getFeatures().array_[0]);
      if (this.selectedPoint) {
        this.pointSelectedEmitter.emit(this.selectedPoint);
      }
    });
    this.map.addInteraction(select);

    this.map.on('pointermove', e => {
      const features = this.map.getFeaturesAtPixel(e.pixel);
      this.hoverFeature(features[0]);
      this.pointHoverEmitter.emit(this.hoveredPoint);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes['lng'] || changes['lat'] || changes['zoom']) {
        this.center();
      }
      if (changes['hoveredPoint']) {
        this.hoverPoint(this.hoveredPoint);
      }
      if (changes['selectedPoint']) {
        this.selectPoint(this.selectedPoint);
      }
      if (changes['currentLocation']) {
        this.goToCurrentLocation();
      }
    }
  }

  selectPoint(point) {
    const feature = point
      ? this.olMapPointFeature.get(`${point['adrLocation'].lon}-${point['adrLocation'].lat}`)
      : null;
    this.selectFeature(feature);
  }

  selectFeature(feature) {
    this.selecting = true;
    if (this.selectedFeature && this.selectedFeature !== feature) {
      this.clearSelectPoint();
    }
    this.hoveredPoint = null;
    this.hoveredFeature = null;
    if (feature) {
      const flatCoordinates = olProj.toLonLat(feature.values_.geometry.flatCoordinates);
      const point = this.points.find(
        findPoint =>
          findPoint.adrLocation.lon === Number(flatCoordinates[0].toFixed(10)) &&
          findPoint.adrLocation.lat === Number(flatCoordinates[1].toFixed(10))
      );
      this.selectedPoint = point;
      if (this.selectedFeature && this.selectedFeature !== feature) {
        this.clearSelectPoint();
        this.selectedPoint = point;
      }
      this.selectedFeature = feature;
      if (this.selectedFeature) {
        this.selectedFeature.setStyle(this.getMarkerStyle(point, false));
      }
    }

    this.selecting = false;
  }

  goToCurrentLocation() {
    if (!this.currentLocationOl) {
      this.currentLocationOl = this.getPointFeature(
        {
          adrLocation: {
            lon: this.lng,
            lat: this.lat,
          },
        },
        true
      );
    } else {
      this.currentLocationOl.setGeometry(new Point(olProj.fromLonLat([this.lng, this.lat])));
    }
    this.map.getView().setCenter(olProj.fromLonLat([this.lng, this.lat]));
    this.map.getView().setZoom(this.zoom);
    this.points.forEach(point => {
      point['distance'] = this.getDistanceToCurrentLocation(point);
    });
    this.distancesCalculatedEmitter.emit(true);
  }

  center() {
    this.map.getView().setCenter(olProj.fromLonLat([this.lng, this.lat]));
    this.map.getView().setZoom(this.zoom);
  }

  getPointFeature(point, currentPosition?) {
    const feature = new Feature({
      geometry: new Point(olProj.fromLonLat([point['adrLocation'].lon, point['adrLocation'].lat])),
    });
    feature.setStyle(this.getMarkerStyle(point, currentPosition));
    const layer = new Vector({
      source: new VectorS({
        features: [feature],
      }),
    });

    this.map.addLayer(layer);

    return feature;
  }

  getMarkerStyle(point?, currentPosition?) {
    let icon = FormPickUpPointInputComponent.getPointIcon(this.selectedPoint, this.hoveredPoint, point);
    if (currentPosition) {
      icon = '/assets/img/location.png';
    }
    return new Style({
      image: new Icon({
        src: icon,
      }),
    });
  }

  hoverPoint(point) {
    const feature = point
      ? this.olMapPointFeature.get(`${point['adrLocation'].lon}-${point['adrLocation'].lat}`)
      : null;
    this.hoverFeature(feature);
  }

  hoverFeature(feature) {
    if (this.selecting || feature === this.selectedFeature) {
      return;
    }
    if (!feature && this.hoveredFeature) {
      this.clearCurrentHover();
    } else if (feature) {
      if (this.hoveredFeature && this.hoveredFeature !== feature) {
        this.clearCurrentHover();
      }
      if (!this.hoveredFeature) {
        this.hoveredFeature = feature;
        const flatCoordinates = olProj.toLonLat(feature.values_.geometry.flatCoordinates);
        const point = this.points.find(
          findPoint =>
            findPoint.adrLocation.lon === Number(flatCoordinates[0].toFixed(10)) &&
            findPoint.adrLocation.lat === Number(flatCoordinates[1].toFixed(10))
        );
        if (this.selectedPoint !== point) {
          this.hoveredPoint = point;
          feature.setStyle(this.getMarkerStyle(point, false));
        } else {
          this.hoveredPoint = null;
          this.hoveredFeature = null;
        }
      }
    }
  }

  clearCurrentHover() {
    const point = this.hoveredPoint;
    this.hoveredPoint = null;
    if (this.hoveredFeature) {
      this.hoveredFeature.setStyle(this.getMarkerStyle(point, false));
      this.hoveredFeature = null;
    }
  }

  clearSelectPoint() {
    const point = this.selectedPoint;
    this.selectedPoint = null;
    if (this.selectedFeature) {
      this.selectedFeature.setStyle(this.getMarkerStyle(point, false));
      this.selectedFeature = null;
    }
  }

  getDistanceToCurrentLocation(point) {
    const line = new LineString(
      [olProj.fromLonLat([this.lng, this.lat]), olProj.fromLonLat([point.adrLocation.lon, point.adrLocation.lat])],
      'XY'
    );
    let length = Math.round(sphere.getLength(line) * 100) / 100;
    length = Math.round((length / 1000) * 100) / 100;

    return length;
  }
}
