import { Component, Input, OnInit } from '@angular/core';
import { OrderDto, OrderItemDto, ProductDetailDto } from '@btl/order-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryTypeEnum } from '../../../models/product-filter';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { ProductService } from '@btl/btl-fe-wc-common';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductUtils } from '../../../helpers/product-utils';

/**
 * IccidAttributeConfigurationComponent is a component responsible for displaying configuration of ICCID attributes of a product.
 */
@Component({
  selector: 'app-iccid-attribute-configuration',
  templateUrl: './iccid-attribute-configuration.component.html',
})
export class IccidAttributeConfigurationComponent implements OnInit {
  categoryTypeEnum = CategoryTypeEnum;
  // Data:

  /**
   * A flag specifying if fake attributes should be shown.
   */
  // TODO: Remove (with the real implementation).
  showFakeAttributes: boolean = false;

  /**
   * The form group for product attributes.
   */
  // TODO: Add product's attributes (with the real implementation).
  attributesFormGroup: FormGroup = this.formBuilder.group({
    iccId: [null, [Validators.required]],
  });

  // IO:

  /**
   * The order item to configure the attributes for.
   */
  @Input()
  orderItem: OrderItemDto;

  /**
   * The product for the order item.
   */
  @Input()
  product: ProductDetailDto;

  /**
   * A flag specifying if the component is supposed to be writable form (false), or read-only (true).
   */
  @Input()
  readOnly: boolean = false;

  /**
   * The precalculated shopping cart.
   */
  @Input()
  precalculatedShoppingCart: PrecalculatedShoppingCart;

  @Input()
  showLabel: boolean = true;

  @Input()
  placeholder: string;

  @Input()
  childProduct;

  @Input()
  isSIMcard?: boolean;

  @Input()
  mask: string;

  @Input()
  isDisabled?: boolean = false;

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private orderingService: WcOrderingService
  ) {}

  //region Life-cycle:

  ngOnInit(): void {
    this.validateInputs();
    if (ProductUtils.isOfCategory(this.childProduct.productDetail, CategoryTypeEnum.PRODB_TP_GSM_SIM)) {
      const technologicalPoint = this.precalculatedShoppingCart.order.orderItems.find(
        orderItem => orderItem.id === this.childProduct.orderItems[0].parentOrderItemId
      );
      if (technologicalPoint && technologicalPoint.action !== 'NO_CHANGE') {
        this.showFakeAttributes = true;
        this.orderingService.loadAttribute('iccId', technologicalPoint, this.attributesFormGroup);
      }
    }
  }

  //region Helpers:

  /**
   * Validate form inputs of all attributes.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    if (!this.showFakeAttributes || this.readOnly) {
      return true;
    }
    Object.keys(this.attributesFormGroup.controls).forEach(fieldName => {
      const attributesFormGroupControl = this.attributesFormGroup.get(fieldName);
      attributesFormGroupControl.markAsTouched({ onlySelf: true });
    });
    return this.attributesFormGroup.valid;
  }

  /**
   * Save values in the form to the given order.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    if (!this.showFakeAttributes || this.readOnly) {
      return;
    }

    // The following attributes are hard-coded here as they are displayed for another product than they are saved for.
    const tariff = order.orderItems.find(this.getOrderItemByIdPredicate(this.orderItem.id));
    if (tariff) {
      let technologicalPoint = this.getOrderItemByParentAndCategory(
        tariff.id,
        CategoryTypeEnum.PRODC_SU_TP_GSM,
        this.precalculatedShoppingCart.order.orderItems
      );
      technologicalPoint = order.orderItems.find(orderItem => orderItem.id === technologicalPoint.id);
      if (technologicalPoint) {
        this.orderingService.saveAttribute('iccId', technologicalPoint, this.attributesFormGroup);
      }
    }
  }

  //region Helpers:

  /**
   * Validate component inputs.
   */
  private validateInputs(): void {
    if (!this.readOnly && !this.orderItem) {
      throw new Error('The order item parameter must have non-null value in writable mode.');
    }
    if (!this.readOnly && !this.precalculatedShoppingCart) {
      throw new Error('The precalculated shopping cart parameter must have non-null value in writable mode.');
    }
    if (!this.product) {
      throw new Error('The product parameter must have non-null value.');
    }
  }

  /**
   * Get a predicate for getting an order item by the given ID.
   *
   * @param orderItemId The ID of the searched order item.
   */
  private getOrderItemByIdPredicate(orderItemId): (OrderItemDto) => boolean {
    return (orderItem: OrderItemDto) => {
      return orderItem.id === orderItemId;
    };
  }

  /**
   * Returns product of given category under given parent (recursivelly
   *
   * @param parentId The parent ID of the searched order item.
   * @param categoryId The category ID of the searched order item.
   */
  private getOrderItemByParentAndCategory(
    parentId: string,
    categoryId: CategoryTypeEnum,
    orderItems: Array<OrderItemDto>
  ): OrderItemDto {
    const parentOrderItems = orderItems.filter(orderItem => {
      return orderItem.parentOrderItemId === parentId;
    });
    for (const parentOrderItem of parentOrderItems) {
      const productDetail = this.productService.getProductFromCache(parentOrderItem.productId);
      if (productDetail.categoryId.startsWith(categoryId)) {
        return parentOrderItem;
      } else {
        const childOfCategory = this.getOrderItemByParentAndCategory(parentOrderItem.id, categoryId, orderItems);
        if (childOfCategory) {
          return childOfCategory;
        }
      }
    }
    return null;
  }

  getAttributeOrderItem() {
    if (ProductUtils.isOfCategory(this.childProduct.productDetail, CategoryTypeEnum.PRODB_TP_GSM_SIM)) {
      return this.precalculatedShoppingCart.order.orderItems.find(
        orderItem => orderItem.id === this.childProduct.orderItems[0].parentOrderItemId
      );
    } else {
      return this.orderItem;
    }
  }

  valueChanged($event: any) {
    this.orderingService.addNotSavedAttribute('iccId', this.getAttributeOrderItem(), $event);
  }
}
