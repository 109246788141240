import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CodebookFrontendService, CodebookParamDto } from '@btl/order-bff';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';

/**
 * Service transforming sap color code to RGB color
 */
@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private static readonly iconRgbParamName = 'iconRGB';
  private static readonly colorsStorageName = 'colors';
  private static readonly colorsCodebookType = 'HW_COLOR_VARIANT';

  private colorsSubject: ReplaySubject<Map<string, { name: string; rgb: string }>> = new ReplaySubject<
    Map<string, { name: string; rgb: string }>
  >();
  private isSubscribed: boolean = false;

  constructor(
    private codebookFrontendService: CodebookFrontendService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  public getColorsFromCodebook() {
    const storageName = `${ColorService.colorsStorageName}.${this.currentLocaleService.getCurrentLanguage()}`;
    if (localStorage.getItem(storageName)) {
      this.colorsSubject.next(new Map(JSON.parse(localStorage.getItem(storageName))));
    } else {
      const colors$ = this.codebookFrontendService.getCodebook(ColorService.colorsCodebookType).pipe(
        map(codebookDtos => {
          const map = new Map();
          codebookDtos.forEach(codebookDto => {
            const iconRgbParams: CodebookParamDto[] = codebookDto.parameters.filter(
              param => param.name === ColorService.iconRgbParamName
            );
            if (iconRgbParams.length !== 0) {
              map.set(codebookDto.code, {
                name: this.codebookService.getCodebookText(codebookDto),
                rgb: iconRgbParams[0].value,
              });
            }
          });
          return map;
        }),
        tap(colorsMap => {
          localStorage.setItem(storageName, JSON.stringify(Array.from(colorsMap)));
        })
      );
      if (!this.isSubscribed) {
        this.isSubscribed = true;
        colors$.subscribe(colorsMap => this.colorsSubject.next(colorsMap));
      }
    }
  }

  public getColors(): Observable<Map<string, { name: string; rgb: string }>> {
    return this.colorsSubject;
  }
}

export interface ColorVariant {
  productCode: string;
  colorId: string;
  name: string;
  rgb: string;
  seoUrl: string;
  fontColor: string;
}
