<div [class.product__search]="!isSmartCityOffer">
  <div app-breadcrumb [groupBasedBreadcrumbs]="true"></div>

  <ng-container *ngIf="!isSmartCityOffer && !isTariffListing">
    <div #filters class="product__search-panel" app-filters [enableTariffFilter]="!isTariffListing"></div>
  </ng-container>

  <!-- 005 product-search content -->
  <div
    class="product__search-content"
    app-listing
    [class.tariff_listing]="isTariffListing"
    [tariffListing]="isTariffListing"
    [currentGroup]="currentProductGroup"
    [enablePaging]="true"
    [enableSorting]="!isSmartCityOffer && !isTariffListing"
    [enableHeader]="false"
    [isSmartCityOffer]="isSmartCityOffer"></div>
</div>
