<div
  class="app-input-text-container"
  [ngClass]="{
    rectangle: shape === 'rectangle',
    round: shape === 'round',
    semiRound: shape === 'semi-round',
    withIcon: iconPath !== undefined,
    small: size === 'small',
    medium: size === 'medium',
    large: size === 'large',
    fullWidth: size === 'fullWidth'
  }">
  <label [for]="inputId">{{ label }}<span *ngIf="required" class="required">*</span></label>
  <input
    type="text"
    aria-describedby="Use aria labels when no actual label is in use"
    [formControlName]="controlName"
    [id]="inputId"
    [required]="required"
    [placeholder]="placeholder ? placeholder : ''" />
  <i
    *ngIf="iconPath !== undefined"
    [inlineSVG]="iconPath"
    [ngClass]="{
      noLabel: !label
    }"></i>
  <i *ngIf="iconPathAfter !== undefined" class="iconAfter" [inlineSVG]="iconPathAfter"></i>
  <app-input-errors
    [control]="parentGroup.controls[controlName]"
    [translationPrefix]="'wc.shopping.form.' + controlName">
  </app-input-errors>
</div>
