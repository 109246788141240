<div *ngIf="form" [class.section]="sectionView">
  <div [formGroup]="form" [class.inner]="sectionView">
    <input id="001_contact_form" formControlName="id" type="hidden" />
    <app-section-header
      *ngIf="showHeader"
      id="collapseHeader"
      [title]="'wc.shopping.contact.' + type + '.heading' | translate"
      [isCollapsed]="refEl.activeIds.length > 0"
      rightText="{{
        inputMode
          ? null
          : ((sameAsTranslationKey ? sameAsTranslationKey : 'wc.shopping.contact.sameAsPrimary.label') | translate)
      }}"
      (click)="acc.toggle('toggleCategories')">
    </app-section-header>
    <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleCategories" [destroyOnHide]="false">
      <ngb-panel id="toggleCategories">
        <ng-template ngbPanelContent>
          <div *ngIf="showAllowContact" class="checkbox">
            <input
              type="checkbox"
              [checked]="allowContact === true"
              [disabled]="disabled"
              id="allow_contact_{{ type }}"
              (change)="allowContactChange($event)" />
            <label for="allow_contact_{{ type }}">
              {{ 'wc.shopping.contact.allowContact.label' | translate }}
            </label>
          </div>
          <ng-template [ngIf]="main === false">
            <div class="checkbox">
              <input
                type="checkbox"
                [checked]="inputMode === false"
                [disabled]="disabled"
                id="check_contact_{{ type }}"
                (change)="setSameAs($event.target.checked)" />
              <label for="check_contact_{{ type }}">
                {{
                  (sameAsTranslationKey ? sameAsTranslationKey : 'wc.shopping.contact.sameAsPrimary.label') | translate
                }}
              </label>
            </div>
          </ng-template>
          <div
            *ngIf="entityGenerateForm && externalValues"
            [ngClass]="{ 'customer_section -subsection': sectionView }"
            [hidden]="inputMode !== true && !sameAsObject">
            <app-entity-generated-form
              #contactEntityGeneratedForm
              entityType="Contact"
              [formName]="formName"
              [sourceName]="sourceName"
              [customer]="customer"
              [form]="form"
              [formGroup]="form"
              [formIdPrefix]="formIdPrefix + '.contact.' + type"
              [showCustomerData]="type === 'PRIMARY' && showCustomerData"
              [resourceIdentification]="{
                contactType: type,
                preferredChannel: getPreferredChannel()
              }"
              [defaultValues]="{
                type: type,
                language: defaultContactLanguage
              }"
              [externalValues]="externalValues"
              [context]="{}"
              [validate]="(validate && inputMode && this.allowContact) || main"
              [checkFormVisibility]="checkFormVisibility"
              [columnsCount]="columnsCount"
              (formGeneratedEmitter)="contactFormGenerated()">
            </app-entity-generated-form>
          </div>
          <ng-content></ng-content>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

    <ng-container *ngIf="showHeader && withAddress">
      <app-section-header
        id="collapseHeader"
        [title]="'wc.shopping.address.' + type + '.heading' | translate"
        [isCollapsed]="refEli.activeIds.length > 0"
        (click)="acci.toggle('lastAccordion')">
      </app-section-header>
      <ngb-accordion #acci="ngbAccordion" #refEli activeIds="lastAccordion" [destroyOnHide]="false">
        <ngb-panel id="lastAccordion">
          <ng-template ngbPanelContent>
            <app-entity-generated-form
              #addressEntityGeneratedForm
              entityType="Address"
              [formName]="formName"
              [sourceName]="sourceName"
              [formIdPrefix]="formIdPrefix + '.contact.' + type + '.address'"
              [form]="form.get('address')"
              [resourceIdentification]="{ addressType: type }"
              [context]="{}"
              [columnsCount]="3"
              (formGeneratedEmitter)="contactFormGenerated()">
            </app-entity-generated-form>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
  </div>
</div>
