<div app-cms-widget-banner [bannerTargeter]="'HP_HERO'"></div>

<!-- <div app-cms-widget-banner [bannerTargeter]="'HP_ICON_LIST_FIRST'"></div> -->

<div class="homepage-section homepage-devices">
  <div class="container hp-container">
    <div
      app-homepage-listing
      [swiperId]="1"
      [header]="'wc.ecare.homepage.checkOutFeaturedProducts' | translate"
      [count]="10"
      [availability]="['stockAvailability']"
      [groupCode]="'HP_FEATURED'"
      [sorting]="sorting.RECOMMENDED"
      [withCTA]="true"></div>
  </div>
</div>

<div app-cms-widget-banner [bannerTargeter]="'HP_ICON_LIST_FIRST'"></div>

<div class="homepage-section">
  <div class="container hp-container">
    <div
      app-homepage-listing
      [swiperId]="2"
      [header]="'wc.ecare.homepage.checkOutLatestProducts' | translate"
      [count]="10"
      [availability]="['stockAvailability']"
      [groupCode]="'HP_LATEST'"
      [sorting]="sorting.RECOMMENDED"></div>
  </div>
</div>

<div class="homepage-section">
  <div class="container hp-container" app-half-image-banner [bannerTargeter]="'HP_HALF_IMAGES'"></div>
</div>

<div class="homepage-section" app-cms-widget-banner [bannerTargeter]="'HP_ICON_LIST_SECOND'"></div>

<div class="homepage-section">
  <div class="container hp-container">
    <div
      app-homepage-listing
      [swiperId]="3"
      [header]="'wc.ecare.homepage.mostViewedItems' | translate"
      [count]="10"
      [availability]="['stockAvailability']"
      [groupCode]="'HP_MOST_VIEWED'"
      [sorting]="sorting.RECOMMENDED"></div>
  </div>
</div>

<!--
<div class="container hp-container homepage-section homepage-tariff-title">
  <h2>{{'wc.ecare.homepage.customizeOurOffer' | translate}}</h2>
  <h5>{{'wc.ecare.homepage.chooseCombinationThatSuits' | translate}}</h5>
  <div app-tariff-listing [showAllTariffsButton]="true" [highlightBoxWithStickers]="true" [products]="fakeTariffProductsOffer"></div>
</div>
 -->

<!-- //TODO: New swiperjs parameter - https://swiperjs.com/swiper-api#grid -->
<!-- <div class="homepage-section brand-listing">
  <div app-brand-listing></div>
  <div app-brand-listing [isSecond]="true"></div>
</div> -->

<!--
<div
  class="homepage-section brand-listing"
  app-cms-widget-banner
  [bannerTargeter]="'HP_BRAND_LIST'"
></div>
 -->
