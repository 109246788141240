import { Component } from '@angular/core';

/**
 * It is needed only when optimization: true is used as DynamicComponentRegistry will not be able to found components
 * that are not used in stating routing
 */
@Component({
  selector: 'app-dynamic-component-registry-init',
  templateUrl: './dynamic-component-registry-init.component.html',
})
export class DynamicComponentRegistryInitComponent {

}
