import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TicketTypeParamDto } from '@btl/order-bff';

@Component({
  selector: 'app-configurable-form',
  templateUrl: './configurable-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfigurableFormComponent),
      multi: true,
    },
  ],
})
export class ConfigurableFormComponent implements OnInit {
  @Input() parameter: TicketTypeParamDto;

  form: FormGroup;
  configuration: FormConfiguration;

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private isDisabled: boolean = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.configuration = JSON.parse(this.parameter.paramMetas.find(meta => meta.name === 'formConfiguration')?.value);
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      rows: this.fb.array([]),
    });

    this.configuration.data.forEach(rowData => {
      this.addRow(rowData);
    });

    this.form.valueChanges.subscribe(() => {
      this.propagateChange();
    });
  }

  get rows() {
    return this.form.get('rows') as FormArray;
  }

  addRow(rowData: any) {
    const row = this.fb.group({});

    this.configuration.columns.forEach(column => {
      const value = rowData[column.name];
      const control = this.fb.control({
        value: value,
        disabled: this.isDisabled || column.calculation,
      });

      row.addControl(column.name, control);
    });

    row.valueChanges.subscribe(() => {
      this.updateCalculatedFields(row);
    });

    this.rows.push(row);
    this.updateCalculatedFields(row);
  }

  updateCalculatedFields(row: FormGroup) {
    this.configuration.columns.forEach(column => {
      if (column.calculation) {
        const result = this.calculateFormula(column.calculation, row.getRawValue());
        row.get(column.name)?.setValue(result, { emitEvent: false });
      }
    });
  }

  // Function to evaluate the calculation formula dynamically
  calculateFormula(formula: string, row: any): number {
    try {
      // Sort keys by length (descending) to replace longer names first
      const keys = Object.keys(row).sort((a, b) => b.length - a.length);

      // Replace each key in the formula with the corresponding value from the row using word boundaries
      keys.forEach(key => {
        const regex = new RegExp(`\\b${key}\\b`, 'g');
        formula = formula.replace(regex, row[key]);
      });

      // Use JavaScript's eval to evaluate the formula
      return eval(formula);
    } catch (error) {
      console.error('Error calculating formula:', formula, error);
      return 0;
    }
  }

  writeValue(value: any): void {
    if (value) {
      value = JSON.parse(value);
    }

    if (!value || value.length === 0) {
      value = this.configuration.data;
    }

    this.rows.controls.length = 0;
    value.forEach((rowData: any) => this.addRow(rowData));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    this.rows.controls.forEach(row => {
      this.configuration.columns.forEach(column => {
        if (this.isDisabled || column.calculation) {
          row.get(column.name).disable();
        } else {
          row.get(column.name).enable();
        }
      });
    });
  }

  // Method to get the current value of the form
  getValue(): any {
    return this.form.value.rows;
  }

  // Custom method to propagate changes
  private propagateChange(): void {
    if (this.onChange) {
      this.onChange(this.getValue());
    }
  }
}

interface FormConfiguration {
  columns: {
    name: string;
    type: string; // 'text', 'number', 'date', etc.
    label: string;
    labelKey: string;
    classes: string;
    options?: string[]; // For select elements
    calculation?: string;
  }[];
  data?: any[]; // Initial data for the form
}
