<div
  class="{{ mainCssClass }}"
  [formGroup]="parentGroup"
  [ngClass]="{ 'sim-card': isSIMcard, 'isDisabled': isDisabled }">
  <label *ngIf="showLabel" for="001_input_row-{{ fieldName }}">
    {{ (labelKey ? labelKey : translationPrefix + '.' + fieldName + '.label') | translate : labelParameters }}
  </label>
  <label *ngIf="mandatory" class="mandatory">*</label>
  <div class="input full">
    <div style="position: relative">
      <input
        *ngIf="!codebookType && !selectItems && !datePicker && !checkbox"
        type="{{ isPassword ? 'password' : 'text' }}"
        id="001_input_row-{{ fieldName }}"
        class="{{ prefixInputIcon ? 'contains_prefix_icon' : '' }}"
        [placeholder]="placeholder ? placeholder : ''"
        [formControlName]="fieldName"
        [mask]="mask"
        (change)="onChange.emit(parentGroup.get(fieldName).value)" />
      <input
        *ngIf="checkbox"
        type="checkbox"
        id="001_input_row_checkbox-{{ fieldName }}"
        class="checkbox {{ prefixInputIcon ? 'contains_prefix_icon' : '' }}"
        [placeholder]="placeholder ? placeholder : ''"
        [formControlName]="fieldName"
        (change)="onChange.emit(parentGroup.get(fieldName).value)" />
      <i *ngIf="prefixInputIcon" class="prefix_icon" aria-hidden="true" [inlineSVG]="prefixInputIcon"></i>
      <label *ngIf="checkbox" class="checkbox_label" for="001_input_row_checkbox-{{ fieldName }}">
        {{ checkboxLabelKey | translate }}
      </label>
    </div>

    <div *ngIf="datePicker" class="form-datepicker">
      <input
        #datePickerView="ngbDatepicker"
        id="021_customer_form"
        ngbDatepicker
        placeholder="yyyy-mm-dd"
        type="text"
        [formControlName]="fieldName"
        [minDate]="{ year: 1920, month: 1, day: 1 }" />
      <div class="input-group-append">
        <button id="022_customer_form" class="btn btn-default" type="button" (click)="datePickerView.toggle()">
          <i id="023_customer_form" aria-hidden="true" inlineSVG="/assets/img/ecare/calendar.svg"></i>
        </button>
      </div>
    </div>

    <app-codebook-selection
      *ngIf="codebookType"
      id="002_input_row"
      [showEmptyOption]="false"
      [formControlName]="fieldName"
      [type]="codebookType"
      (onChange)="onChange.emit($event)" />

    <select
      *ngIf="selectItems && !selectItemsAsRadio"
      id="003_input_row"
      [formControlName]="fieldName"
      (change)="onChange.emit($event)">
      <option *ngFor="let item of selectItems" [ngValue]="item">
        <i *ngIf="itemIconsPath" aria-hidden="true" [inlineSVG]="itemIconsPath + item + '.svg'"></i>
        {{
          (itemsTranslationPrefix ? itemsTranslationPrefix : translationPrefix) +
            '.' +
            (itemsTranslationType ? itemsTranslationType : fieldName) +
            '.' +
            item +
            (addItemsTranslationLabel ? '.label' : '') | translate
        }}
      </option>
    </select>

    <div *ngIf="selectItems && selectItemsAsRadio" class="form-radio">
      <div *ngFor="let item of selectItems" class="single-radio">
        <input
          type="radio"
          [value]="item"
          [formControlName]="fieldName"
          id="{{ 'item.' + item }}"/>
        <label class="radio_label" for="{{ 'item.' + item }}">
          <span> </span>
          {{
            (itemsTranslationPrefix ? itemsTranslationPrefix : translationPrefix) +
              '.' +
              (itemsTranslationType ? itemsTranslationType : fieldName) +
              '.' +
              item +
              (addItemsTranslationLabel ? '.label' : '') | translate
          }}
        </label>
      </div>
    </div>
  </div>
</div>
<app-input-errors
  [control]="parentGroup.controls[fieldName]"
  [translationPrefix]="translationPrefix + '.' + fieldName" />
