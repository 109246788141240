import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormUtils } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-multi-ip-control',
  templateUrl: './multi-ip-control.component.html',
  styleUrls: ['./multi-ip-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiIpControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MultiIpControlComponent),
    },
  ],
})
export class MultiIpControlComponent implements OnInit, ControlValueAccessor, Validator {
  @Input()
  pattern: string;

  @Input()
  value: string;

  @Input()
  private control: AbstractControl;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  addresses: Array<string> = [];

  form: FormGroup = this.formBuilder.group({
    addresses: this.formBuilder.array([]),
  });

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal) {}

  ngOnInit(): void {
    const self = this;
    const origFunc = this.control.markAsTouched;
    this.control.markAsTouched = function () {
      FormUtils.validateAllFormFields(self.form);
      if (!self.form.valid) {
        self.control.setErrors(self.form.errors);
        self.discloseValidatorChange();
      } else {
        self.control.setErrors(null);
      }
      origFunc.apply(this, arguments);
    };
    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.form.valid) {
      return this.value;
    }
    return null;
  }

  valueChanged() {
    this.getFormArray().clear();

    this.addresses = this.value ? JSON.parse(this.value) : [];
    if (this.addresses.length === 0) {
      this.addresses.push(null);
    }
    this.addresses.forEach(address => {
      this.addAddressToFormArray(address);
    });
  }

  addAddressToFormArray(address) {
    this.add(address);
  }

  remove(parameterForm: AbstractControl) {
    let index = 1;
    let foundIndex = null;
    this.getFormArray().controls.forEach(control => {
      if (control === parameterForm) {
        foundIndex = index;
      }
      index++;
    });

    if (foundIndex) {
      this.getFormArray().removeAt(foundIndex - 1);
    }
  }

  getFormArray() {
    return this.form.get('addresses') as FormArray;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  edit(content) {
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'xlModal',
    });
  }

  save(c: any) {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const addresses = this.form.getRawValue();
      this.value = JSON.stringify(addresses.addresses);
      this.valueChanged();
      c('');
    }
  }

  add(ipAddress?: string) {
    this.getFormArray().push(
      this.formBuilder.control(ipAddress, [Validators.required, Validators.pattern(this.pattern)])
    );
  }
}
