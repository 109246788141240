import { Component, Input } from '@angular/core';
import { AddonsGroupHolder } from '../../../models/addons-group-holder';

@Component({
  selector: 'app-addon-activation-groups',
  templateUrl: './addon-activation-groups.component.html',
  styleUrls: ['./addon-activation-groups.component.scss'],
})
export class AddonActivationGroupsComponent {
  isCollapsed: boolean = true;

  @Input()
  addonsGroup: AddonsGroupHolder;

  @Input()
  partyId;

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
