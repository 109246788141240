import { Component, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Keyboard, Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Keyboard, Scrollbar, Navigation, Pagination]);

@Component({
  selector: 'app-services-swiper',
  templateUrl: './ecare-services-swiper.html',
  encapsulation: ViewEncapsulation.None,
})
export class EcareServicesSwiperComponent {}
