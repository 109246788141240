<div
  class="container"
  [ngSwitch]="size?.toLowerCase()"
  [attr.id]="urlAnchor"
  [style.color]="color"
  [style.background]="backgroundColor"
  [ngStyle]="
    (backgroundImage && { background: 'url(' + backgroundImage + ') no-repeat' }) ||
    (backgroundImage && { background: backgroundImage })
  ">
  <h1 *ngSwitchCase="'h1'">
    <ng-container *ngTemplateOutlet="headlineTitleTemplate"></ng-container>
  </h1>
  <h2 *ngSwitchCase="'h2'">
    <ng-container *ngTemplateOutlet="headlineTitleTemplate"></ng-container>
  </h2>
  <h3 *ngSwitchCase="'h3'">
    <ng-container *ngTemplateOutlet="headlineTitleTemplate"></ng-container>
  </h3>
</div>

<ng-template #headlineTitleTemplate>
  {{ title | selectLocaleProperty }}
</ng-template>
