import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductsViewedService {
  public static readonly VIEWED_PRODUCTS_SESSION = 'viewedProducts';
  public static readonly VIEWED_PRODUCT_LOCAL_STORAGE = 'viewedProduct';

  constructor() {
    this.clearViewedProducts();

    window.addEventListener('storage', event => {
      if (event.key === ProductsViewedService.VIEWED_PRODUCT_LOCAL_STORAGE) {
        if (event.newValue) {
          this.addViewedProduct(event.newValue);
        } else {
          sessionStorage.removeItem(ProductsViewedService.VIEWED_PRODUCTS_SESSION);
        }
      }
    });
  }

  public addViewedProduct(productCode: string) {
    const products = this.getViewedProducts();
    if (products.some(productCodeLoop => productCodeLoop === productCode)) {
      //Salesrec currently won't allow two same viewed products for one bought product
      return;
    }
    products.push(productCode);
    sessionStorage.setItem(ProductsViewedService.VIEWED_PRODUCTS_SESSION, JSON.stringify(products));
    localStorage.setItem(ProductsViewedService.VIEWED_PRODUCT_LOCAL_STORAGE, productCode);
  }

  clearViewedProducts() {
    sessionStorage.removeItem(ProductsViewedService.VIEWED_PRODUCTS_SESSION);
    localStorage.removeItem(ProductsViewedService.VIEWED_PRODUCT_LOCAL_STORAGE);
  }

  public getViewedProducts(): Array<string> {
    let products = JSON.parse(sessionStorage.getItem(ProductsViewedService.VIEWED_PRODUCTS_SESSION));
    if (!products) {
      products = [];
    }
    return products;
  }
}
