/**
 * Contains product filtering information from GUI
 */
export interface ProductElasticFilter {
  attributes: ProductFilterAttributes;
  sorting: SortTypeEnum;
  paging: FilterPaging;
}

export interface FilterPaging {
  page: number;
  pageSize: number;
  firstPageSize: number;
}

export interface ProductFilterAttributes {
  productGroup?: string;
  text?: string;
  availability?: Array<any>;
  price?: Array<any>;
  brand?: Array<any>;
  screenSize?: Array<any>;
  operatingSystem?: Array<any>;
  ram?: Array<any>;
  totalMemory?: Array<any>;
  color?: Array<any>;
  groupId?: string;
  groupCode?: string;
  compatibleAccessoryOf?: Array<string>;
}

export enum SortTypeEnum {
  RECOMMENDED = 'RECOMMENDED',
  CHEAPEST = 'CHEAPEST',
  MOST_EXPENSIVE = 'MOST_EXPENSIVE',
  NEWEST = 'NEWEST',
  MOST_BOUGHT = 'MOST_BOUGHT',
}
