<div class="shoppingCart-items">
  <div *ngIf="!hasOrderItems()" class="productListing__noProduct">
    <p>{{ 'wc.shopping.cart.noOrderItems.text' | translate }}</p>

    <a
      *ngIf="showGoToProductListingLink"
      id="001_shopping_cart_items"
      routerLink="/eshop/product-listing"
      href=""
      class="link arrow-back catalogue">
      {{ 'wc.common.backToCatalogue.button' | translate }}</a
    >
  </div>

  <ng-container *ngIf="hasOrderItems()">
    <ng-container
      *ngFor="
        let productGroup of precalculatedShoppingCart.products
          | keyvalue: precalculatedShoppingCart.mainProductsIsHwAscOrder;
        trackBy: precalculatedShoppingCart.trackEntryByKey
      ">
      <ng-container *ngFor="let product of productGroup.value">
        <app-shopping-cart-item
          [productInShoppingCart]="product"
          [showQuantityChange]="showQuantityChange"
          [showQuantity]="showQuantity"
          [showRemoveBtn]="showRemoveBtn"
          [showConfigurationBtn]="showConfigurationBtn"
          [displayMode]="displayMode"
          [showSpecialOffer]="showSpecialOffer"
          [showDetails]="showDetails"
          [showImg]="showImg"
          [showAddons]="showAddons"
          [precalculatedShoppingCart]="precalculatedShoppingCart"
          [readOnlyOrder]="readOnlyOrder">
        </app-shopping-cart-item>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
