<app-ecare-page-layout
  columnTemplate="1fr"
  title="{{ 'wc.ecare.dashboard.welcomeTo' | translate }} {{
    customerLocalStorageService.getCurrentCustomer().subject.firstName
  }}">
<!--  <app-button-->
<!--    *ngIf="!editingWidgets; else editMode"-->
<!--    label="Edit widgets"-->
<!--    variant="secondary"-->
<!--    iconPath="/assets/svg/feather-edit.svg"-->
<!--    (click)="editingWidgets = true"></app-button>-->
<!--  <ng-template #editMode>-->
<!--    <app-button label="Discard all" variant="secondary" (click)="editingWidgets = false"></app-button>-->
<!--    <app-button label="Save changes" variant="primary" iconPath="/assets/svg/save.svg"></app-button>-->
<!--  </ng-template>-->
  <div class="widget-container" [ngClass]="{ editMode: editingWidgets }">
    <ng-container *ngIf="subscriptionsUsagesLoaded">
      <ng-container *ngFor="let widget of widgets | keyvalue">
        <ng-container [ngSwitch]="widget.key.groupParams['widget_Type']">
          <div
            *ngSwitchCase="'CUSTOMER_ACCOUNT'"
            class="widget wide account-uptodate"
            app-ecare-customer-account-widget></div>
          <div
            *ngSwitchCase="'FIX_INTERNET'"
            app-ecare-fix-internet-tariff-widget
            class="widget {{ widget.value.length > 0 ? '' : 'banner' }}"
            (subscriptionChanged)="refreshContextCustomer()"
            [group]="widget.key"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'TV'"
            app-ecare-tv-tariff-widget
            class="widget {{ widget.value.length > 0 ? '' : 'banner' }}"
            (subscriptionChanged)="refreshContextCustomer()"
            [group]="widget.key"
            [widgetElementsGroups]="widgetElementsGroups"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'MOBILE_TARIFF'"
            app-mobile-tariff-widget
            class="widget wide {{ widget.value.length > 0 ? '' : 'banner' }}"
            (subscriptionChanged)="refreshContextCustomer()"
            [subscriptionsUsages]="subscriptionsUsages"
            [widgetElementsGroups]="widgetElementsGroups"
            [group]="widget.key"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'MOBILE_TARIFF_USAGE'"
            class="widget wide"
            app-ecare-mobile-usage-tariff-widget
            [subscriptionsUsages]="subscriptionsUsages"
            [widgetElementsGroups]="widgetElementsGroups"
            [group]="widget.key"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'MOBILE_DATA'"
            app-ecare-mobile-internet-tariff-widget
            class="widget wide {{ widget.value.length > 0 ? '' : 'banner' }}"
            (subscriptionChanged)="refreshContextCustomer()"
            [subscriptionsUsages]="subscriptionsUsages"
            [widgetElementsGroups]="widgetElementsGroups"
            [group]="widget.key"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'FIX_VOICE'"
            app-ecare-fix-voice-tariff-widget
            class="widget wide {{ widget.value.length > 0 ? '' : 'banner' }}"
            (subscriptionChanged)="refreshContextCustomer()"
            [subscriptionsUsages]="subscriptionsUsages"
            [widgetElementsGroups]="widgetElementsGroups"
            [group]="widget.key"
            [subscriptions]="widget.value"></div>
          <div
            *ngSwitchCase="'USAGE_ALERTS'"
            class="widget primary-color"
            app-ecare-usage-alerts-widget
            [group]="widget.key"
            [subscriptionsUsages]="subscriptionsUsages"
            [widgetElementsGroups]="widgetElementsGroups"
            [widgets]="widgets"></div>

          <div *ngSwitchCase="'SMALL_BANNER'" class="widget banner" app-ecare-banner-widget [group]="widget.key"></div>

          <div *ngSwitchCase="'BIG_BANNER'" class="widget wide banner" app-ecare-banner-widget [group]="widget.key"></div>

          <div *ngSwitchCase="'LAST_TICKETS'" class="widget" app-last-tickets-widget></div>

          <div
            *ngSwitchCase="'ORDERS_IN_DELIVERY'"
            class="widget"
            app-last-orders-widget
            componentType="ordersInDelivery"
            [orders]="lastInDeliveryOrders"></div>

          <div *ngSwitchCase="'LAST_INVOICES'" class="widget" app-last-invoices-widget [invoices]="invoices"></div>

          <div
            *ngSwitchCase="'LAST_ORDERS'"
            class="widget wide"
            app-last-orders-widget
            componentType="lastOrders"
            [orders]="lastOrders"></div>

          <div
            *ngSwitchCase="'LAST_PAYMENT'"
            class="widget primary-color"
            app-ecare-last-payment-widget
            [lastInvoice]="oldestNotPayedInvoice"></div>

          <div
            *ngSwitchCase="'HELP'"
            class="widget help"
            app-help-and-support-widget
            placement="bottom"
            [routerLink]="'/help'"
            [ngbTooltip]="'wc.ecare.widgets.help.getHelp' | translate"></div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <app-button
    *ngIf="editingWidgets"
    class="customer_account-newWidget"
    label="Add new widget"
    iconPath="/assets/img/ecare/feather-plus.svg"
    variant="secondary">
  </app-button>

</app-ecare-page-layout>

