import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  CurrentLocaleService,
  EnumsService
} from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { takeUntil } from 'rxjs/operators';
import { EnumValueInfoDto, GatewayDto, NotificationTypeDto, NotificationTypeFrontendService } from '@btl/order-bff';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notifications-filter',
  templateUrl: './notifications-filter.component.html',
})
export class NotificationsFilterComponent extends ComplexFilter {
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  @Input()
  gateways: GatewayDto[];

  notificationTypes: NotificationTypeDto[];

  //ToDo Get it from from enumValues when possible
  channelTypes: EnumValueInfoDto[] = [{ name: 'SMS' }, { name: 'EMAIL' }, { name: 'PUSH_FIREBASE' }];
  states: EnumValueInfoDto[] = [
    { name: 'INITIAL' },
    { name: 'INPROGRESS' },
    { name: 'FAILED' },
    { name: 'COMPLETED' },
    { name: 'CANCELLED' },
  ];

  protected advancedFilterFields = [
    'notificationType',
    'gateway',
    'channelType',
    'state',
    'sentFrom',
    'sentTo',
  ];

  filterForm = this.formBuilder.group({
    recipient: [],
    notificationType: [],
    gateway: [],
    channelType: [],
    state: [],
    sentTo: [],
    sentFrom: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.recipient, 'rcptAddress'),
    new ControlFilterMiner(this.filterForm.controls.notificationType, 'notificationType'),
    new ControlFilterMiner(this.filterForm.controls.gateway, 'gateway'),
    new ControlFilterMiner(this.filterForm.controls.channelType, 'channelTypeId'),
    new ControlFilterMiner(this.filterForm.controls.state, 'state'),

    new ControlFilterMiner(this.filterForm.controls.sentFrom, 'sent', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.sentTo, 'sent', CompareTypeDtoEnum.SMALLEREQ),
  ];

  constructor(
    private formBuilder: FormBuilder,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private enumsService: EnumsService,
    private notificationTypeFrontendService: NotificationTypeFrontendService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super();
    notificationTypeFrontendService
      .filterNotificationTypes(null, currentLocaleService.getCurrentLanguage(), null, null, 1, -1)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => (this.notificationTypes = result.data));

    // enumsService.getEnumValues('notification', 'com.emeldi.ecc.be.notification.channelType')
    //   .pipe(takeUntil(this.onDestroy$)).subscribe(result => this.channelTypes = result);
    //
    // enumsService.getEnumValues('notification', 'com.emeldi.ecc.be.notification.notifState')
    //   .pipe(takeUntil(this.onDestroy$)).subscribe(result => this.states = result);
  }

  protected getDefaultFilterValues() {
    return {
      notificationType: null,
      gateway: null,
      channelType: null,
      state: null,
      sentTo: null,
      sentFrom: null,
    };
  }
}
