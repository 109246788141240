<tr class="ecare_listing-table-row clickable" (click)="onRowClick()">
  <td>
    <i id="001_ecare_order_row" class="arrow-triangle" [ngClass]="{ down: collapsed, up: !collapsed }"></i>
  </td>
  <td class="no-wrap">
    {{ event.id }}
  </td>
  <td class="no-wrap content-sm">
    {{ event.type.code }}
  </td>
  <td class="no-wrap content-sm order-created-datetime">
    {{ event.happened | date: 'dd. MM. yyyy' }} <span class="-time">{{ event.happened | date: 'HH:mm' }}</span>
  </td>
</tr>
<tr *ngIf="collapsed" class="notification-window">
  <td colspan="4">
    <div>
      {{ event.description }}
    </div>
    <div *ngIf="event.params" class="document-params">
      <div *ngFor="let attr of eventType.metaDescription.attributes" class="document-param">
        <ng-container *ngIf="attr.metaParameters['guiVisible'] === 'true'">
          <label>{{ getAttributeLabel(attr) }}</label>
          <ng-container [ngSwitch]="attr.type">
            <app-codebook-selection
              *ngSwitchCase="attributeTypeEnum.CODEBOOK"
              class="full"
              [disable]="true"
              [value]="event.params[attr.name]"
              [type]="attr.typeDetail">
            </app-codebook-selection>

            <app-enum-select
              *ngSwitchCase="attributeTypeEnum.ENUM"
              sourceName="event"
              [disable]="true"
              [value]="event.params[attr.name]"
              [enumName]="attr.typeDetail">
            </app-enum-select>

            <input
              *ngSwitchCase="attributeTypeEnum.DATE"
              type="text"
              disabled
              value="{{ event.params[attr.name] ? (event.params[attr.name] | date: 'dd.MM.yyyy') : '' }}" />

            <input
              *ngSwitchDefault
              type="text"
              disabled
              [value]="event.params[attr.name] ? event.params[attr.name] : ''" />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </td>
</tr>
