import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppBlockerService } from '@btl/btl-fe-wc-common';
import { finalize } from 'rxjs/operators';
import { WcOrderingService } from '@service/wc-ordering.service';

@Injectable()
export class OrderBlockUiInterceptor implements HttpInterceptor {
  constructor(private appBlockerService: AppBlockerService, private orderingService: WcOrderingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/orders') === -1) {
      return next.handle(request);
    }
    this.appBlockerService.block();
    return next.handle(request).pipe(
      finalize(() => {
        if (!this.orderingService.doNotUnBlockOneCall) {
          this.appBlockerService.unblock();
        } else {
          this.orderingService.doNotUnBlockOneCall = false;
        }
      })
    );
  }
}
