<div
  *ngIf="!readOnlyOrder|| canDisplayProduct(product)"
  class="shoppingCart-container"
  [ngClass]="{
    'shoppingCart-parent-item': canDisplayProduct(product),
    'addon': addon,
    'checkoutPage': displayMode === 'COMPACT',
    'thankYouPage': displayMode === 'ROLLOUT'
  }">
  <div
    *ngIf="
      canDisplayProduct(product) &&
      (productInShoppingCart.orderItems?.length === 0 || productInShoppingCart.visibleActions)
    ">
    <div *blockUI="blockUIElementName; template: blockTemplate">
      <app-alert
        *ngIf="displayMode === 'FULL' && (modify || productInShoppingCart.replacementFor)"
        alertStyle="warning"
        iconPath="assets/svg/warning-icon.svg"
        text="{{ 'wc.shopping.cart.modify.replacementFor' | translate }} {{
          productInShoppingCart.replacementFor.productDetail.name
        }}">
        <app-button
          variant="whiteWithBorder"
          label="Restore item"
          iconPath="assets/svg/material-restore.svg"
          size="small"
          (click)="remove()"></app-button>
      </app-alert>
      <app-alert
        *ngIf="displayMode === 'FULL' && delete"
        alertStyle="danger"
        iconPath="assets/svg/danger-icon.svg"
        text="{{ 'wc.shopping.cart.modify.delete' | translate }}">
        <app-button
          variant="whiteWithBorder"
          label="Restore item"
          iconPath="assets/svg/material-restore.svg"
          size="small"
          (click)="remove()"></app-button>
      </app-alert>
      <div
        class="parent"
        [ngClass]="{
          shoppingCart: displayMode === 'FULL',
          withChild: productInShoppingCart.children.length > 0,
          addon: addon
        }"
        (click)="addon && selectAddon()">
        <div class="left-side">
          <i *ngIf="addon" class="before-icon check" [inlineSVG]="'assets/svg/check-FA.svg'"></i>
          <div *ngIf="showImg" class="parent-img-container">
            <div *ngIf="showImg" class="parent-img" [hidden]="addon">
              <ng-container *ngIf="isHw(); else non_hw">
                <img *ngIf="product.picturePreview" src="{{ product.picturePreview.href }}" alt="{{ product.name }}" />
                <img
                  *ngIf="!product.picturePreview"
                  src="/assets/img/products/default_product_image.png"
                  alt="{{ product.label | selectLocaleProperty }}" />
              </ng-container>
              <ng-template #non_hw>
                <img
                  *ngIf="product.picturePreview"
                  class="default"
                  src="{{ product.picturePreview.href }}"
                  alt="{{ product.name }}" />
                <!-- <img
                    src="/assets/img/products/tariff_default_icon.svg"
                    class="default_icon"
                    alt="{{ product.label | selectLocaleProperty }}"
                    *ngIf="!product.picturePreview"
                  /> -->
                <div *ngIf="!product.picturePreview">
                  <i [inlineSVG]="'/assets/img/products/tariff_default_icon.svg'"></i>
                </div>
              </ng-template>
            </div>
            <div
              *ngIf="canDisplayQuantity && displayMode !== 'FULL' && displayMode !== 'ROLLOUT'"
              class="parent-quantity">
              <p *ngIf="showQuantity">
                {{ productInShoppingCart.visibleOrderItemsQuantity }}
              </p>
            </div>
            <div
              *ngIf="
                (modify || productInShoppingCart.replacementFor) && displayMode !== 'FULL' && displayMode !== 'ROLLOUT'
              ">
              class="modify-tag absolute warning" <i [inlineSVG]="'/assets/svg/replacing.svg'"></i>
            </div>
            <div *ngIf="delete && displayMode !== 'FULL' && displayMode !== 'ROLLOUT'" class="modify-tag absolute danger">
              <i [inlineSVG]="'/assets/svg/remove.svg'"></i>
            </div>
          </div>
          <div class="parent-name">
            <div id="001_shopping_cart_item" class="parent-name-inner">
              <ng-container *ngIf="product.seoUrl; else noSeoUrl">
                <a
                  *ngIf="!addon"
                  id="002_shopping_cart_item"
                  [queryParams]="
                  productInShoppingCart?.orderItems[0]
                    ? { orderItemId: productInShoppingCart.orderItems[0].id }
                    : { productId: product.id }
                "
                  [routerLink]="['/eshop/product-detail/' + product.seoUrl]"
                >{{ product.name }}
                </a>
              </ng-container>
              <ng-template #noSeoUrl>
                <div class="product-name">{{ product.name }}</div>
              </ng-template>
              <ng-template #popContent>
                <div [innerHTML]="product.shortDescription"></div>
              </ng-template>
              <i
                *ngIf="!addon && showDetails"
                class="shortDescription"
                placement="right"
                triggers="mouseenter:mouseleave"
                [inlineSVG]="'assets/svg/awesome-info-circle.svg'"
                [ngbPopover]="popContent">
              </i>
              <ng-container *ngIf="addon">
                <div *ngIf="displayMode === 'FULL' && delete" class="modify-tag danger">
                  <i [inlineSVG]="'/assets/svg/remove.svg'"></i> {{ 'wc.shopping.cart.modify.removing' | translate }}
                </div>
                <div class="product-long-desc">
                  {{ product.longDescription }}
                  <i
                    *ngIf="product.longDescription"
                    class="infoDescription"
                    triggers="mouseenter:mouseleave"
                    [inlineSVG]="'assets/svg/awesome-info-circle.svg'"
                    ngbPopover="{{ product.longDescription }}">
                  </i>
                </div>

                <!-- <div *ngIf="!childOfRootProduct()">
                  {{
                    ("wc.shopping.cart.addToService.text" | translate) +
                      " " +
                      getParentText()
                  }}
                </div> -->
              </ng-container>
              <div app-acl-internal-evaluate-component>
                <i
                  id="003_shopping_cart_item"
                  class="product__code fa fa-chevron-circle-down"
                  tooltipClass="product-code"
                  placement="bottom"
                  ngbTooltip="{{ product.productCode }}"></i>
              </div>
              <ng-container *ngIf="displayMode === 'FULL' && (modify || productInShoppingCart.replacementFor)">
                <div class="modify-tag-container">
                  <div class="modify-tag warning">
                    <i [inlineSVG]="'/assets/svg/replacing.svg'"></i>
                    {{ 'wc.shopping.cart.modify.replacing' | translate }}
                  </div>
                  {{ productInShoppingCart.replacementFor.productDetail.name }}
                </div>
              </ng-container>
            </div>
            <div class="parent-action-container">
              <div *ngIf="showConfigurationBtn && !addon && !isHw() && dynamicProductParameters?.length > 0"
                   class="parent-action">
                <a id="010_shopping_cart_item" (click)="configure()">
                  <i [inlineSVG]="'/assets/svg/account-settings.svg'"></i>
                  {{ 'wc.shopping.cart.product.configuration' | translate }}
                </a>
              </div>
              <div
                *ngIf="
                  product.parametersStatic &&
                  product.parametersStatic['wishlistEnabled'] === 'true' &&
                  showConfigurationBtn
                "
                class="parent-action">
                <a (click)="wishListPopup()">
                  <i [inlineSVG]="'/assets/svg/favorite-heart.svg'"></i>
                  {{ 'wc.shopping.cart.product.favorite' | translate }}
                </a>
              </div>
            </div>
            <!-- <p
              *ngIf="showQuantityChange"
              ellipsis
              [style.max-height.px]="72"
              [innerHTML]="product.shortDescription"
            >
              <ng-container *ngIf="canDisplayCommitmentInfo()">
                <app-product-commitment-info
                  [product]="getChildProductWithCommitmentCategory()"
                  >,
                </app-product-commitment-info>
              </ng-container>
              <a
                id="004_shopping_cart_item"
                *ngIf="!showQuantity && hasLinkToDetail()"
                [queryParams]="
                  productInShoppingCart
                    ? { orderItemId: productInShoppingCart.orderItems[0].id }
                    : { productId: product.id }
                "
                [routerLink]="['/eshop/product-detail/' + product.seoUrl]"
                class="product__name"
              >
                {{ "wc.shopping.confirmation.product.detail.button" | translate }}
              </a>
            </p> -->
          </div>

          <div *ngIf="displayMode === 'ROLLOUT' && canDisplayQuantity" class="summary-quantity d-flex align-items-center">
            {{ productInShoppingCart.visibleOrderItemsQuantity }} Qty
          </div>
        </div>
        <div class="right-side">
          <div class="rightSide-container">
            <div *ngIf="showQuantityChange && canDisplayQuantity" class="inner-quantity">
              <i
                class="quantity__button"
                [inlineSVG]="'/assets/img/ecare/quantity-minus.svg'"
                (click)="decreaseQuantity()"></i>
              <span class="quantity__input">{{ productInShoppingCart.visibleOrderItemsQuantity }}</span>
              <i
                class="quantity__button"
                [inlineSVG]="'/assets/img/ecare/quantity-plus.svg'"
                (click)="increaseQuantity()"></i>
            </div>
          <div *ngIf="readOnlyOrder" class="product-count">
            {{ productInShoppingCart.visibleOrderItemsQuantity }} {{'wc.shopping.confirmation.pieces.label' | translate}}
          </div>
          <div *ngIf="!delete" class="parent-prices">
            <ng-container *ngIf="hasProductPrice(productInShoppingCart)">
              <ng-container *ngIf="showVat; else noVat">
                <div *ngIf="productInShoppingCart.ocPrice != null" class="inner-prices">
                  <div class="price-amount one-time">
                    <one-time-price [price]="productInShoppingCart.ocPrice"></one-time-price>
                  </div>
                </div>
                <div *ngIf="productInShoppingCart.rcPrice != null" class="inner-prices">
                  <div class="price-amount">
                    <div class="price-name">
                      {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                    </div>
                    <app-recurrent-price
                      [disableTexts]="true"
                      [price]="productInShoppingCart.rcPrice"></app-recurrent-price>
                  </div>
                </div>
              </ng-container>
              <ng-template #noVat>
                <div *ngIf="productInShoppingCart.ocPriceTax != null" class="inner-prices">
                  <div class="price-amount one-time">
                    <one-time-price [price]="productInShoppingCart.ocPriceTax"></one-time-price>
                  </div>
                </div>
                <div *ngIf="productInShoppingCart.rcPriceTax != null" class="inner-prices">
                  <div class="price-amount">
                    <div class="price-name">
                      {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                    </div>
                    <app-recurrent-price
                      [disableTexts]="true"
                      [price]="productInShoppingCart.rcPriceTax"></app-recurrent-price>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <div *ngIf="showRemoveBtn" class="parent-action">
            <a id="008_shopping_cart_item" (click)="remove()">
              <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
            </a>
          </div>
          <!-- <div class="parent-action" *ngIf="showConfigurationBtn">
            <a
              id="010_shopping_cart_item"
              (click)="configure()"
              *ngIf="!isHw()"
            >
              <i id="011_shopping_cart_item" class="fa fa-cogs"></i>
            </a>
          </div>
          <div
            class="parent-action"
            *ngIf="productInShoppingCart.orderItems.length === 0"
          >
            <a
              id="0010_shopping_cart_item"
              (click)="activate()"
              class="btn-link"
            >
              {{ "wc.shopping.cart.activate.text" | translate }}
            </a>
          </div> -->
        </div>
        </div>
      </div>

      <ng-container *ngIf="productInShoppingCart.children.length > 0">
        <div *ngIf="showConfigurationBtn || displayMode === 'ROLLOUT'" class="shoppingCart-child-container">
          <ng-container *ngFor="let childProduct of childProducts">
            <div *ngIf="!childProduct.productDetail.categoryId.startsWith('PRODC_GE')" class="shoppingCart-child">
              <div class="shoppingCart-child-inner">
                <div class="shoppingCart-child-name">
                  <i *ngIf="hasSIMAttribute(childProduct)" [inlineSVG]="'assets/svg/sim-card.svg'"></i>
                  {{ childProduct.productDetail.name }}
                  <span *ngIf="hasSIMAttribute(childProduct) && displayMode !== 'ROLLOUT'" class="asterisk-required"
                    >*</span
                  >
                </div>
                <div *ngIf="hasSIMAttribute(childProduct)" class="shoppingCart-child-attribute">
                  <app-iccid-attribute-configuration
                    [showLabel]="false"
                    [placeholder]="'wc.shopping.cart.enterSim.label' | translate"
                    [product]="product"
                    [childProduct]="childProduct"
                    [orderItem]="productInShoppingCart.orderItems[0]"
                    [precalculatedShoppingCart]="precalculatedShoppingCart"
                    [isSIMcard]="true"
                    [mask]="'0000-0000-0000-0000-0000'"
                    [isDisabled]="displayMode === 'ROLLOUT'">
                  </app-iccid-attribute-configuration>
                </div>
              </div>
              <div class="child-prices" [ngClass]="checkPrices(childProduct)">
                <ng-container *ngIf="hasProductPrice(childProduct); else noPrice">
                  <ng-container *ngIf="showVat; else noVat">
                    <div *ngIf="childProduct.rcPrice" class="inner-prices">
                      <div class="price-name">
                        {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                      </div>
                      <div class="price-amount">
                        <app-recurrent-price [disableTexts]="true" [price]="childProduct.rcPrice"></app-recurrent-price>
                      </div>
                    </div>
                    <div *ngIf="childProduct.ocPrice" class="inner-prices">
                      <ng-container *ngIf="!isDiscount(childProduct); else discountPrice">
                        <div class="price-name">
                          {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                        </div>
                        <div class="price-amount one-time">
                          <one-time-price [price]="childProduct.ocPrice"></one-time-price>
                        </div>
                      </ng-container>
                      <ng-template #discountPrice>
                        <div class="price-amount">
                          <one-time-price [negative]="true" [price]="discountOCPrice"></one-time-price>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>
                  <ng-template #noVat>
                    <div *ngIf="childProduct.rcPriceTax" class="inner-prices">
                      <div class="price-name">
                        {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                      </div>
                      <div class="price-amount">
                        <app-recurrent-price
                          [disableTexts]="true"
                          [price]="childProduct.rcPriceTax"></app-recurrent-price>
                      </div>
                    </div>
                    <div *ngIf="childProduct.ocPriceTax" class="inner-prices">
                      <ng-container *ngIf="!isDiscount(childProduct); else discountPrice">
                        <div class="price-name">
                          {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                        </div>
                        <div class="price-amount one-time">
                          <one-time-price [price]="childProduct.ocPriceTax"></one-time-price>
                        </div>
                      </ng-container>
                      <ng-template #discountPrice>
                        <div class="price-amount">
                          <one-time-price [negative]="true" [price]="discountOCPrice"></one-time-price>
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #noPrice>
                  <div class="noPrice">
                    {{ 'wc.shopping.confirmation.summaryRow.freePrice.label' | translate }}
                  </div>
                </ng-template>
              </div>
              <!--ToDo Probably there should be different condition-->
              <!-- <div
              class="child-action"
              *ngIf="
                childProduct.productDetail.categoryId.startsWith('PRODC_GE')
              "
            >
              <a id="012_shopping_cart_item" (click)="remove(childProduct)">
                <i id="013_shopping_cart_item" class="fa fa-times"></i>
              </a>
            </div> -->
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="showConfigurationBtn || displayMode === 'COMPACT'"
          class="shoppingCart-available-addons active-addons_container">
          <div class="shoppingCart-available-addons-group">
            <app-section-header *ngIf="displayMode !== 'COMPACT'" textAlign="left" [title]="'Active addons'">
              <div class="ml-auto pr-1 rightSide-container">
                <div class="parent-prices">
                  <ng-container *ngIf="showVat; else noVat">
                    <div *ngIf="totalOcPriceForActiveAddons" class="inner-prices">
                      <div class="price-amount one-time">
                        <one-time-price [price]="totalOcPriceForActiveAddons"></one-time-price>
                      </div>
                    </div>
                    <div *ngIf="totalRcPriceForActiveAddons" class="inner-prices">
                      <div class="price-amount">
                        <div class="price-name">
                          {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                        </div>
                        <app-recurrent-price
                          [disableTexts]="true"
                          [price]="totalRcPriceForActiveAddons"></app-recurrent-price>
                      </div>
                    </div>
                  </ng-container>

                  <ng-template #noVat>
                    <div *ngIf="totalOcPriceTaxForActiveAddons" class="inner-prices">
                      <div class="price-amount one-time">
                        <one-time-price [price]="totalOcPriceTaxForActiveAddons"></one-time-price>
                      </div>
                    </div>
                    <div *ngIf="totalRcPriceTaxForActiveAddons" class="inner-prices">
                      <div class="price-amount">
                        <div class="price-name">
                          {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                        </div>
                        <app-recurrent-price
                          [disableTexts]="true"
                          [price]="totalRcPriceTaxForActiveAddons"></app-recurrent-price>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </app-section-header>
            <div class="card-body addon-container">
              <ng-container *ngFor="let childProduct of childProducts">
                <div *ngIf="childProduct.productDetail.categoryId.startsWith('PRODC_GE')">
                  <div class="shoppingCart-container shoppingCart-parent-item addon" id="shoppingCart-container">
                    <div
                      class="parent shoppingCart activated addon"
                      (click)="showRemoveBtn ? remove(childProduct) : undefined">
                      <i class="before-icon check" [inlineSVG]="'assets/svg/check-FA.svg'"></i>
                      <i class="before-icon remove" [inlineSVG]="'assets/svg/close-FA.svg'"></i>
                      <div class="parent-name">
                        <div class="parent-name-inner">
                          <div class="product-name">{{ childProduct.productDetail.name }}</div>
                          <div class="product-long-desc">
                            {{ childProduct.productDetail.longDescription }}
                            <i
                              *ngIf="showDetails && childProduct.productDetail.longDescription"
                              class="infoDescription"
                              triggers="mouseenter:mouseleave"
                              [inlineSVG]="'assets/svg/awesome-info-circle.svg'"
                              ngbPopover="{{ childProduct.productDetail.longDescription }}">
                            </i>
                          </div>
                        </div>
                      </div>
                      <div class="rightSide-container">
                        <div class="product-count" *ngIf="displayMode !== 'COMPACT'">{{childProduct.visibleOrderItemsQuantity}} {{'wc.shopping.confirmation.pieces.label' | translate}}</div>
                        <div class="parent-prices">
                          <ng-container *ngIf="hasProductPrice(childProduct); else noPrice">
                            <ng-container *ngIf="showVat; else noVat">
                              <div *ngIf="childProduct.rcPrice" class="inner-prices">
                                <div class="price-name">
                                  {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                                </div>
                                <div class="price-amount">
                                  <app-recurrent-price
                                    [disableTexts]="true"
                                    [price]="childProduct.rcPrice"></app-recurrent-price>
                                </div>
                              </div>
                              <div *ngIf="childProduct.ocPrice" class="inner-prices">
                                <ng-container *ngIf="!isDiscount(childProduct); else discountPrice">
                                  <div class="price-name">
                                    {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                                  </div>
                                  <div class="price-amount one-time">
                                    <one-time-price [price]="childProduct.ocPrice"></one-time-price>
                                  </div>
                                </ng-container>
                                <ng-template #discountPrice>
                                  <div class="price-amount">
                                    <one-time-price [negative]="true" [price]="discountOCPrice"></one-time-price>
                                  </div>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-template #noVat>
                              <div *ngIf="childProduct.rcPriceTax" class="inner-prices">
                                <div class="price-name">
                                  {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                                </div>
                                <div class="price-amount">
                                  <app-recurrent-price
                                    [disableTexts]="true"
                                    [price]="childProduct.rcPriceTax"></app-recurrent-price>
                                </div>
                              </div>
                              <div *ngIf="childProduct.ocPriceTax" class="inner-prices">
                                <ng-container *ngIf="!isDiscount(childProduct); else discountPrice">
                                  <div class="price-name">
                                    {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                                  </div>
                                  <div class="price-amount one-time">
                                    <one-time-price [price]="childProduct.ocPriceTax"></one-time-price>
                                  </div>
                                </ng-container>
                                <ng-template #discountPrice>
                                  <div class="price-amount">
                                    <one-time-price [negative]="true" [price]="discountOCPrice"></one-time-price>
                                  </div>
                                </ng-template>
                              </div>
                            </ng-template>
                          </ng-container>
                          <ng-template #noPrice>
                            <div class="noPrice">
                              {{ 'wc.shopping.confirmation.summaryRow.freePrice.label' | translate }}
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="!addon && showAddons === true && !isHw() && addonsInGroups?.size > 0"
    class="shoppingCart-available-addons">
    <div *ngFor="let entry of addonsInGroups | keyvalue" class="shoppingCart-available-addons-group">
      <ng-container *ngIf="entry.key.type === 'GUI_ADDON_CATEGORY'">
        <app-section-header
          textAlign="left"
          style="cursor: pointer"
          id="collapseHeader"
          [title]="entry.key.name"
          [isCollapsed]="refEl.activeIds.length > 0"
          (click)="acc.toggle('toggleAddons')">
        </app-section-header>
        <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleAddons">
          <ngb-panel id="toggleAddons">
            <ng-template ngbPanelContent>
              <div class="addon-container">
                <app-shopping-cart-item
                  *ngFor="let addon of entry.value"
                  [showImg]="false"
                  [showQuantity]="false"
                  [showQuantityChange]="false"
                  [displayMode]="'FULL'"
                  [showRemoveBtn]="false"
                  [mainProductInShoppingCart]="productInShoppingCart"
                  [addon]="addon">
                </app-shopping-cart-item>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-container>
    </div>
  </div>
</div>
