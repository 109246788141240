<div class="section" [formGroup]="deliveryAddressForm">
  <div class="inner" [formGroup]="deliveryAddressForm">
    <h4 id="001_delivery_address_form">{{ 'wc.shopping.address.' + addressType + '.heading' | translate }}</h4>
    <app-input-row
      id="002_delivery_address_form"
      fieldName="city"
      translationPrefix="wc.shopping.address"
      [parentGroup]="deliveryAddressForm">
    </app-input-row>
    <app-input-row
      id="003_delivery_address_form"
      fieldName="street"
      translationPrefix="wc.shopping.address"
      [parentGroup]="deliveryAddressForm">
    </app-input-row>
    <app-input-row
      id="004_delivery_address_form"
      fieldName="streetNumber"
      translationPrefix="wc.shopping.address"
      [parentGroup]="deliveryAddressForm">
    </app-input-row>
    <app-input-row
      id="005_delivery_address_form"
      fieldName="zipCode"
      translationPrefix="wc.shopping.address"
      [parentGroup]="deliveryAddressForm">
    </app-input-row>
    <app-input-row
      id="006_delivery_address_form"
      fieldName="district"
      translationPrefix="wc.shopping.address"
      [parentGroup]="deliveryAddressForm">
    </app-input-row>
    <div class="form-group-row">
      <label>{{ 'wc.shopping.address.country.label' | translate }}</label>
      <div class="input select">
        <app-codebook-selection
          id="007_delivery_address_form"
          type="COUNTRY"
          formControlName="country">
        </app-codebook-selection>
        <app-input-errors
          [control]="deliveryAddressForm.controls['country']"
          [translationPrefix]="'wc.shopping.address.country'">
        </app-input-errors>
      </div>
    </div>
  </div>
</div>
