<app-section-header
  *ngIf="productsHolders?.length && productHeaderHolder"
  textAlign="center"
  [title]="productHeaderHolder"></app-section-header>

<div class="homepage-devices-carousel">
  <swiper
    [slidesPerView]="1.7"
    [slidesPerGroupSkip]="1"
    [freeMode]="true"
    [grabCursor]="false"
    [allowTouchMove]="true"
    [scrollbar]="false"
    [speed]="250"
    [width]="getSwiperWidth()"
    [navigation]="{
      nextEl: '.carousel-arrow-swiper-next_' + [swiperId],
      prevEl: '.carousel-arrow-swiper-prev_' + [swiperId]
    }"
    [pagination]="{ clickable: true }"
    [breakpoints]="{
      '576': {
        slidesPerView: 2.3
      },
      '769': {
        slidesPerView: 3
      },
      '992': {
        slidesPerView: 4
      },
      '1200': {
        slidesPerView: 5
      }
    }">
    <ng-template swiperSlide *ngFor="let product of productsHolders; let i = index">
      <ng-container>
        <a id="{{ i }}_001_homepage_listing" [routerLink]="['/eshop/product-detail/' + product._source.seoUrl]">
          <img
            *ngIf="product._source.image && product._source.image.medium"
            #productImage
            alt="{{ product._source.name | selectLocaleProperty }}"
            [src]="'/api/bff/order/v1/img/' + product._source.image.medium"/>
          <img
            *ngIf="(!product._source.image || !product._source.image.medium) && !product._source.picture"
            #productImage
            src="/assets/img/products/phone_example-listing.png"
            alt="{{ product._source.name | selectLocaleProperty }}"
            [style.min-height.px]="imageMinHeight" />
        </a>
        <div class="stars-container">
          <app-rating-stars
            class="stars"
            [style.visibility]="product._source.rating ? '' : 'hidden'"
            [rating]="product._source.rating"
            [onlyFull]="false">
          </app-rating-stars>
        </div>
        <a [routerLink]="['/eshop/product-detail/' + product._source.seoUrl]">
          <h4>
            {{ product._source.name | selectLocaleProperty }}
          </h4>
        </a>
        <div class="old-price">
          <!-- //TODO -->
        </div>
        <div class="price">
          <one-time-price [price]="product._source.prices.priceIndex"></one-time-price>
        </div>
        <app-button
          *ngIf="withCTA"
          iconPath="/assets/svg/cart-icon.svg"
          [label]="'wc.ecare.productListing.addToCart' | translate"
          (click)="addToCart(product._source)"></app-button>

        <div *ngFor="let sticker of product._source.stickers" class="product-stickers">
          <div
            *ngIf="sticker.type === 'NEW'"
            [ngClass]="{
              new: sticker.type === 'NEW'
            }">
            New
          </div>
        </div>

        <!-- //TODO -->
        <!-- product labels and parameters -->
        <!-- Product stickers -->
        <!-- <div class="product__labels">
                <div
                  *ngFor="let sticker of product._source.stickers"
                  class="product__label {{ sticker.type | stickerTypeClass }}"
                >
                  {{ sticker.name | selectLocaleProperty }}
                </div>
                <ng-container *ngIf="product._source.stockStatus">
                  <div
                    class="product__label"
                    [ngClass]="
                      product._source.stockStatus === 'outOfStock'
                        ? '-unavailable'
                        : '-sale'
                    "
                  >
                    {{
                      "wc.shopping.gui_productCard.main.itemAvailability." +
                        product._source.stockStatus | translate
                    }}
                  </div>
                </ng-container>
              </div> -->
      </ng-container>
    </ng-template>
  </swiper>
  <div [className]="'carousel-arrow carousel-arrow-swiper-next carousel-arrow-swiper-next_' + swiperId">
    <i [inlineSVG]="'assets/img/ecare/feather-chevron-down.svg'"></i>
  </div>
  <div [className]="'carousel-arrow carousel-arrow-swiper-prev carousel-arrow-swiper-prev_' + swiperId">
    <i [inlineSVG]="'assets/img/ecare/feather-chevron-down.svg'"></i>
  </div>
</div>
