<div class="template-container" [ngStyle]="{ 'top.px': offsetTop }">
  <header>
    <div><app-navigation-menu #navigationMenu></app-navigation-menu></div>
  </header>

  <div class="container page-container mt-4">
    <app-cms-widgets-sequential-renderer [widgets]="widgets"></app-cms-widgets-sequential-renderer>
  </div>

  <div class="sticky-error">
    <ng-container *ngFor="let stickyMessage of stickyMessageService.stickyMessages; let i = index">
      <app-sticky-message *ngIf="stickyMessage" [index]="i" [stickyMessage]="stickyMessage"> </app-sticky-message>
    </ng-container>
  </div>

  <app-footer></app-footer>
</div>
