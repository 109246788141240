import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
  WidgetElementInfo,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-ecare-mobile-internet-tariff-widget, [app-ecare-mobile-internet-tariff-widget]',
  templateUrl: './ecare-mobile-internet-tariff-widget.component.html',
})
export class EcareMobileInternetTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {

  dataWidgetInfo: WidgetElementInfo;

  protected initTariffData() {
    super.initTariffData();

    this.dataWidgetInfo = this.widgetElementsInfos.get('DATA');
  }
}
