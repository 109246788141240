import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductDetailDto } from '@btl/order-bff';
import { StockCustomService, StockState } from '@service/stock-custom.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-compared-product-availability',
  templateUrl: './compared-product-availability.component.html',
  styleUrls: ['./compared-product-availability.component.scss'],
})
export class ComparedProductAvailabilityComponent implements OnInit, OnDestroy {
  @Input() private product: ProductDetailDto;
  public centralStock: StockState;
  public nonCentralStock: StockState;
  private centralSubscription: Subscription;
  private nonCentralSubscription: Subscription;

  constructor(private stockCustomService: StockCustomService) {}

  ngOnInit() {
    this.centralSubscription = this.stockCustomService
      .getStockCentral(this.product.productCode)
      .subscribe(result => (this.centralStock = result));
    this.nonCentralSubscription = this.stockCustomService
      .getStockNonCentral(this.product.productCode)
      .subscribe(result => (this.nonCentralStock = result));
  }

  ngOnDestroy(): void {
    this.centralSubscription.unsubscribe();
    this.nonCentralSubscription.unsubscribe();
  }
}
