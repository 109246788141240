import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { FormUtils } from '@btl/btl-fe-wc-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simple-contact-control',
  templateUrl: './simple-contact-control.component.html',
  styleUrls: ['./simple-contact-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleContactControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => SimpleContactControlComponent),
    },
  ],
})
export class SimpleContactControlComponent implements OnInit, ControlValueAccessor, Validator {
  @Input()
  value: string;

  @Input()
  private control: AbstractControl;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  contact: SimpleContact = {};

  form: FormGroup = this.formBuilder.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phone1: [null, [Validators.required]],
  });

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal) {}

  ngOnInit(): void {
    const self = this;
    const origFunc = this.control.markAsTouched;
    this.control.markAsTouched = function () {
      FormUtils.validateAllFormFields(self.form);
      if (!self.form.valid) {
        self.control.setErrors(self.form.errors);
        self.discloseValidatorChange();
      } else {
        self.control.setErrors(null);
      }
      origFunc.apply(this, arguments);
    };
    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.form.valid) {
      return this.value;
    }
    return null;
  }

  valueChanged() {
    if (this.value) {
      this.contact = JSON.parse(this.value);
      this.form.patchValue(this.contact);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  edit(content) {
    this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });
  }

  save(c: any) {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const contact = this.form.getRawValue();
      this.value = JSON.stringify(contact);
      this.valueChanged();
      c('');
    }
  }

  getContactString() {
    return this.contact.firstName
      ? `${this.contact.firstName} ${this.contact.lastName} (${this.contact.phone1}, ${this.contact.email})`
      : '';
  }
}

export interface SimpleContact {
  firstName?: string;
  lastName?: string;
  phone1?: string;
  email?: string;
}
