<div class="section" [formGroup]="deliveryContactForm">
  <ng-container *ngIf="!simpleContact; else simpleContactForm" [formGroup]="deliveryContactForm">
    <div class="inner">
      <h4 id="001_delivery_contact_form">
        {{ 'wc.shopping.contact.heading' | translate }} {{ contactId ? this.contactId : '' }}
      </h4>
      <div class="form-group-row">
        <label>{{ 'wc.shopping.contact.preferredChannel.label' | translate }}</label>
        <div class="input select">
          <select id="002_delivery_contact_form" class="select" formControlName="preferredChannel">
            <option *ngFor="let preferredChannel of preferredChannels" [value]="preferredChannel">
              <ng-container *ngIf="codebookService.getCodebookTexts('CONTACT_PREFERRED_CHANNEL') | async as codebook">
                {{ codebook[preferredChannel] }}
              </ng-container>
            </option>
          </select>
        </div>
      </div>
      <app-input-row
        id="003_delivery_contact_form"
        fieldName="firstName"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
      <app-input-row
        id="004_delivery_contact_form"
        fieldName="middleName"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
      <app-input-row
        id="005_delivery_contact_form"
        fieldName="lastName"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
      <app-input-row
        id="006_delivery_contact_form"
        fieldName="phone1"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
      <app-input-row
        id="007_delivery_contact_form"
        fieldName="email"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
    </div>
  </ng-container>
  <ng-template #simpleContactForm>
    <div class="inner">
      <h4 id="008_delivery_contact_form">
        {{ 'wc.shopping.contact.heading' | translate }} {{ contactId ? this.contactId : '' }}
      </h4>
      <app-input-row
        id="009_delivery_contact_form"
        fieldName="fullName"
        translationPrefix="wc.shopping.contact"
        [parentGroup]="deliveryContactForm">
      </app-input-row>
    </div>
  </ng-template>
</div>
