<div class="ecare dashboard dashboard-homepage">
  <div class="dashboard_wrapper">
    <div class="dashboard_search">
      <div class="tiles_wrapper">
        <div *ngIf="customers && customers.length > 0" class="tile -tile12">
          <div class="ecare-header_headline">
            {{ 'wc.ecare.homepage.customer.headline' | translate }}
          </div>
          <div class="tile_inner dashboard_tile">
            <section>
              <app-ecare-customer-card *ngFor="let customer of customers" [customer]="customer">
              </app-ecare-customer-card>
            </section>
          </div>
        </div>
      </div>
      <div *ngIf="account" class="tiles_wrapper">
        <div class="tile -tile12">
          <app-ecare-orders [constantFilter]="constantOrderFilter"> </app-ecare-orders>
        </div>
      </div>

      <div *ngIf="isModuleBffCrmEnabled && account" class="tiles_wrapper">
        <div class="tile -tile12">
          <app-ecare-tasks [backUrl]="router.url" [externalSearch]="ticketSearch"> </app-ecare-tasks>
        </div>
      </div>
    </div>
  </div>
</div>
