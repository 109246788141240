import { Component, Input } from '@angular/core';
import { CookiePolicyService, PageModuleService } from '@btl/btl-fe-wc-common';
import { CookieAgreementDto } from '@btl/order-bff';
import { ModalFactoryService } from '@service/modal-factory.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cookie-policy-bar',
  templateUrl: './cookie-policy-bar.component.html',
})
export class CookiePolicyBarComponent {
  @Input()
  availableCookieAgreements: CookieAgreementDto[];

  constructor(
    private cookiePolicyService: CookiePolicyService,
    private modalFactory: ModalFactoryService,
    private activatedRoute: ActivatedRoute
  ) {}

  acceptAllCookieAgreements() {
    const moduleId = PageModuleService.getActiveModuleId(this.activatedRoute);
    this.availableCookieAgreements.forEach(agreement =>
      this.cookiePolicyService.addCookieAgreement(moduleId, agreement)
    );
  }

  openCookiePolicySettings() {
    const moduleId = PageModuleService.getActiveModuleId(this.activatedRoute);
    this.modalFactory.cookiePolicySettingsModal(this.availableCookieAgreements, moduleId);
  }
}
