import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
})
export class MessageDialogComponent {
  @Input()
  enableClose = true;

  @Input()
  messageTranslationEnable = true;

  @Input()
  headerTranslationKey;

  @Input()
  messageTranslationKey;

  @Input()
  messageTranslationParameters = {};

  @Input()
  dialogRef;

  @Input()
  closeButtonHandler;

  canBeClosed() {
    return this.enableClose;
  }
}
