import { Component } from '@angular/core';
import { CmsWidgetVideoListBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-video-list]',
  templateUrl: './cms-widget-video-list.component.html',
  styleUrls: ['./cms-widget-video-list.component.scss'],
})
@EnableDynamicLoading({ customName: 'VIDEO_LIST' })
export class CmsWidgetVideoListComponent extends CmsWidgetVideoListBaseComponent {}
