import { Injectable } from '@angular/core';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { DynamicComponentRegistry, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { EntityRef } from '@btl/btl-fe-wc-common/lib/models/entity-ref';
import { ContextEntityRefResolver } from '@btl/btl-fe-wc-common/lib/helpers/context-entity-ref-resolver';

@Injectable()
@EnableDynamicLoading({ customName: DynamicComponentRegistry.CONTEXT_ENTITY_REF_RESOLVER })
export class ContextEntityRefResolverImpl implements ContextEntityRefResolver {
  constructor(private customerLocalStorageService: CustomerLocalStorageService) {}

  /**
   * E.g. used for contextual page access evaluation in app-auth-guard.
   */
  getContextEntityRef(): EntityRef {
    const entity =
      this.customerLocalStorageService.getCurrentCustomerAccount() ||
      this.customerLocalStorageService.getCurrentCustomer() ||
      undefined;

    return entity
      ? {
          entityType: entity.role,
          entityId: entity.id,
        }
      : undefined;
  }
}
