<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.common.suspend.button' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopupAndFlushOrder()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <form *ngIf="!formSubmitted && !loggedUser" class="form" [formGroup]="form">
    <div class="generated-form">
      <div class="generated-input-container col12">
        <app-input-row
          id="003_suspend_order"
          fieldName="email"
          labelKey="wc.shopping.suspend.enterEmail"
          translationPrefix="wc.shopping.form"
          [placeholder]="'wc.shopping.gui_productDetail.watchdog.contactEmail' | translate"
          [parentGroup]="form" />
      </div>
    </div>
  </form>
  <div>
    <div *ngIf="!formSubmitted && loggedUser">
      <p>{{ 'wc.shopping.suspend.confirm' | translate }}?</p>
    </div>
    <div *ngIf="formSubmitted && loggedUser && suspendedOrder">
      <p>{{ 'wc.shopping.suspend.availableInResume' | translate }}.</p>
      <p class="font-weight-bold-lighter">{{ 'wc.shopping.suspend.searchId' | translate }}:</p>
      <app-copy-to-clipboard [value]="suspendedOrder.id" />
    </div>
    <p *ngIf="formSubmitted && !loggedUser">
      {{ 'wc.shopping.suspend.linkSent' | translate }}
    </p>
  </div>
</div>

<div class="modal-footer">
  <div *ngIf="!formSubmitted; else close" class="button-wrapper">
    <app-button variant="link" [label]="'wc.common.close.button' | translate" (click)="closePopup()" />
    <app-button
      *ngIf="!trySubmit"
      variant="primary"
      [label]="'wc.common.suspend.button' | translate"
      (click)="suspend()" />
  </div>
  <ng-template #close>
    <app-button
      *ngIf="loggedUser && authService.account && !authService.account.external"
      variant="secondary"
      label="Go to dashboard"
      [routerLink]="['/ecare/homepage']"
      (click)="closePopup()" />
    <app-button variant="primary" ngbAutofocus [label]="'wc.common.close.button' | translate" (click)="closePopup()" />
  </ng-template>
</div>
