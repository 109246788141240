import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { AgreementService, FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgreementDto } from '@btl/order-bff';

/**
 * Form control handing list of agreements. If entityType and entityId are pre-field
 * AgreementService.getAgreements is called to load agreements list
 */
@Component({
  selector: 'app-form-agreements',
  templateUrl: './form-agreements.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAgreementsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormAgreementsComponent),
    },
  ],
})
export class FormAgreementsComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value: Array<AgreementDto>;

  @Input()
  control: AbstractControl;

  @Input()
  entityType: string;

  @Input()
  entityId: string;

  @Input()
  displayMode: string = "ALL";

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  formMode = true;

  agreementsLoaded: boolean = false;

  form: FormGroup = this.formBuilder.group({
    agreements: this.formBuilder.array([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private agreementService: AgreementService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        self.new = false;
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    } else {
      this.formMode = false;
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    return null;
  }

  valueChanged() {
    this.getFormArray().clear();
    if (!this.value) {
      this.value = [];
    }
    if (this.entityType && this.entityId) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({ column: 'entityType', compareType: 'EQUAL', value: this.entityType });
      search.filtering.push({ column: 'entityId', compareType: 'EQUAL', value: this.entityId });
      this.agreementService
        .getAgreements(this.entityType, this.entityId, null, true)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            result.forEach(agreement => {
              this.addToFormArray(agreement);
            });
          if (this.form.value.agreements.length != 0)
          this.agreementsLoaded = true;
          }
        });
    } else {
      this.value.forEach(agreement => {
        this.addToFormArray(agreement);
        this.agreementsLoaded = true;
      });
    }
  }

  public static getSubFormControlConfig(formBuilder) {
    return {
      entityType: [null],
      entityId: [null],
      agreed: [null],
      channel: [null],
      when: [null],
      type: formBuilder.group({
        id: [],
        code: [],
        mandatory: [],
        name: [],
        validFor: [],
        references: [],
        params: [],
        texts: [],
      }),
      recordVersion: [],
    };
  }

  addToFormArray(value) {
    this.new = true;
    const newDataFormGroup = this.formBuilder.group(FormAgreementsComponent.getSubFormControlConfig(this.formBuilder));
    if (value.type.mandatory) {
      newDataFormGroup.get('agreed').setValidators(Validators.requiredTrue);
    }
    value.channel = 'WEB';
    newDataFormGroup.patchValue(value);
    this.getFormArray().push(newDataFormGroup);
  }

  getFormArray() {
    return this.form.get('agreements') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.form.valid) {
      return this.form.get('agreements')?.value;
    }
    return null;
  }
}
