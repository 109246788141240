import { Component, Input, OnInit } from '@angular/core';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { Router } from '@angular/router';
import { CustomerAccountDto, CustomerDto, PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-business-customer-account',
  templateUrl: './ecare-business-customer-account.component.html',
})
export class EcareBusinessCustomerAccountComponent implements OnInit {
  @Input() customerAccount: CustomerAccountDto;
  @Input() customer: CustomerDto;

  customerAccounts: CustomerAccountDto[];
  tariffs: PartyDto[];
  collapsed = true;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.customerAccounts = CustomerPartyUtil.getChildCustomerAccounts(this.customerAccount.childParties);
    this.tariffs = CustomerPartyUtil.getChildTariffs(this.customerAccount.childParties);
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }
}
