import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartyElse } from '../../../models/party-holder';
import { addressTypes } from 'app/pipes/display-address.pipe';
import { PickerInputType } from 'app/components/form-field-modal-picker/form-field-modal-picker.component';

@Component({
  selector: 'app-ecare-search-table-mobile-row',
  templateUrl: './ecare-search-table-mobile-row.component.html',
})
export class EcareSearchTableMobileRowComponent {
  addressTypes = addressTypes;

  @Input()
  pickerInputType: PickerInputType;

  @Input()
  rowText;

  @Input()
  stickerText;

  @Input()
  parameterText;

  @Input()
  lastLineText;

  @Input()
  row: any;

  @Input()
  index: number;

  @Output()
  readonly selectedRow = new EventEmitter<any>();

  getParameterByName(name: string): string {
    let value = null;
    if (this.row) {
      value = this.row.parameters?.find(param => param.name === name)?.value;
    }
    return value;
  }
}
