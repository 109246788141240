<div class='widget--header'>
  <div class='widget--header_title'>
    <i [inlineSVG]="'/assets/svg/dashboard-widgets/last-tickets.svg'"></i>
    <h6>{{'wc.ecare.widgets.last-tickets.header' | translate }}</h6>
  </div>
</div>
<div class='widget--content shrinked-gap'>
  <ng-container *ngFor='let ticket of tickets.slice(0, 3)'>
    <div class="tickets">
      <div class='icon-container'>
        <i class="menu-icon task-icon" [inlineSVG]="'/assets/img/ecare/' + (ticket.parentId ? 'sub' : '') + 'task.svg'"></i></div>
      <div class='ticket-title' [ngbTooltip]='ticket.subject'>{{ ticket.subject }}</div>
      <div class='ticket-priority'>
        <i *ngIf="ticket.type.areaType === 'TASK'; else textPriority" [ngbTooltip]='String(ticket.priority)'
          [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'">
        </i>
        <ng-template #textPriority>
          <span>
            {{ ticket.type.areaType === 'TASK' ? ('wc.ecare.ticket.task.priorities.' + ticket.priority | translate) : ticket.priority }}
          </span>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
<app-button
  class='remove-widget'
  iconPath='/assets/img/ecare/feather-minus.svg'
  variant='whiteWithBorder'
  placement='top'
  ngbTooltip='Remove widget'>
</app-button>

