<div class="tile_inner">
  <div class="tile_headline consumption">
    {{ bundleDto.name }}
  </div>
  <div class="tile_content">
    <div class="tariff_chart">
      <div class="tariff_info">
        {{
          bundleDto.type === bundleTypeEnum.SMS ? (bundleDto.amountRemaining | number: 0) : bundleDto.amountRemaining
        }}
        <small
          >{{ 'wc.ecare.tariffSpaceConsumptionInfo.unit.' + bundleDto.type + '.label' | translate }}
          {{ 'wc.ecare.tariffSpaceConsumptionInfo.remaining.label' | translate }}</small
        >
      </div>
      <div class="chartVisual">
        <div class="chartVisual-fill" [style.width.%]="getRemainingPercent()"></div>
      </div>
      <div class="tariff_info-of">
        <small>{{ 'wc.ecare.tariffSpaceConsumptionInfo.of.label' | translate }}</small>
        {{ bundleDto.amountTotal }}
        {{ 'wc.ecare.tariffSpaceConsumptionInfo.unit.' + bundleDto.type + '.label' | translate }}
      </div>
    </div>
  </div>
  <div class="tile_footer">
    <span>{{ 'wc.ecare.tariffSpaceConsumptionInfo.dayRemaining.label' | translate }}: {{ getDayRemaining() }}</span>
    <a id="001_tariff_space_consumption_info" class="btn btn-default" href="#">{{
      'wc.ecare.tariffSpaceConsumptionInfo.buyMore.label' | translate
    }}</a>
  </div>
</div>
