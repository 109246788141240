<app-ecare-page-layout
  *ngIf="accountEntityRoleDto"
  columnTemplate="1fr"
  showBackButton="true"
  title="{{
    !editMode
      ? ('wc.ecare.accountEntity.headline' | translate)
      : ((newAccountEntity ? 'wc.ecare.accountEntity.newInteraction' : 'wc.ecare.accountEntity.editInteraction')
        | translate)
  }} {{ !editMode ? '(' + accountEntityRoleDto.id + ')' : '' }}">
  <ng-container page-actions>
    <ng-container *ngIf="!editMode; else editActions">
      <app-button
        variant="primary"
        iconPath="/assets/svg/edit.svg"
        label="{{ 'wc.ecare.accountEntity.edit' | translate }}"
        (click)="edit()"></app-button>
      <app-button
        *ngIf="
          accountEntityRoleDto.account.login &&
          accountEntityRoleDto.account.login === authFactoryService.getAuthService().getUsername() &&
          accountEntityRoleDto.canDelegate
        "
        variant="secondary"
        label="{{ 'wc.ecare.accountEntity.delegate.button' | translate }}"
        (click)="delegate()"></app-button>
      <app-button
        variant="secondary"
        iconPath="/assets/svg/delete.svg"
        label="{{ 'wc.ecare.accountEntity.delete' | translate }}"
        (click)="deleteAccountEntity()"></app-button>
    </ng-container>
    <ng-template #editActions>
      <app-button
        *ngIf="newAccountEntity"
        variant="secondary"
        label="{{ 'wc.common.cancel.button' | translate }}"
        (click)="cancel()"></app-button>
      <app-button
        *ngIf="!newAccountEntity"
        variant="secondary"
        iconPath="/assets/svg/delete.svg"
        label="{{ 'wc.ecare.accountEntity.discardChanges' | translate }}"
        (click)="discardChanges()"></app-button>
      <app-button
        variant="primary"
        iconPath="/assets/svg/save.svg"
        label="{{ 'wc.ecare.accountEntity.saveChanges' | translate }}"
        (click)="saveChanges()"></app-button>
    </ng-template>
  </ng-container>
  <div>
    <div
      *blockUI="blockUIElement.name; template: blockTemplate"
      class="dashboard-wrapper ecare-detail-page"
      [formGroup]="form"
      [ngClass]="{ '-read-only': !editMode }">
      <div class="left-panel">
        <div class="section-wrapper inline-form">
          <div class="input" formGroupName="account">
            <label>{{ 'wc.ecare.accountEntity.accountId' | translate }}</label>
            <app-form-field-modal-picker
              valueField="login"
              labelField="login"
              controlName="accountId"
              [parentGroup]="form.get('account')"
              [translationPrefix]="'wc.ecare.accountEntity'"
              [pickerType]="pickerInputType.USER"
              [selectPopUpOptions]="{
                includeExternal: true
              }"
              (selectionChanged)="accountChanged($event)">
            </app-form-field-modal-picker>
          </div>
          <div class="input">
            <label for="009_account_entity_detail">{{ 'wc.ecare.account.lastName' | translate }}</label>
            <input
              type="text"
              id="009_account_entity_detail"
              [disabled]="true"
              [value]="getAccountParamByName('lastName')" />
          </div>
          <div class="input">
            <label for="010_account_entity_detail">{{ 'wc.ecare.account.employeeId' | translate }}</label>
            <input
              type="text"
              id="010_account_entity_detail"
              [disabled]="true"
              [value]="getAccountParamByName('employeeId')" />
          </div>
          <div class="input">
            <label for="011_account_entity_detail">{{ 'wc.ecare.account.state' | translate }}</label>
            <input type="text" id="011_account_entity_detail" [disabled]="true" [value]="account?.state" />
          </div>
        </div>
        <div class="section-wrapper inline-form">
          <div class="checkbox">
            <input
              id="012_account_entity_detail"
              type="checkbox"
              [disabled]="true"
              [checked]="account && !account.external" />
            <label for="012_account_entity_detail">{{ 'wc.ecare.account.external' | translate }}</label>
          </div>
        </div>

        <div class="section-wrapper -full-width">
          <div class="ecare-detail-page-subheader">
            <div id="025_account_entity_detail">{{ 'wc.ecare.accountEntity.entity' | translate }}</div>
          </div>
          <div class="section-wrapper inline-form">
            <div class="input">
              <label [for]="'013_account_entity_detail'">{{ 'wc.ecare.accountEntity.entityType' | translate }}</label>
              <app-form-select-input
                selectClass="ecare-dropdown"
                controlName="entityType"
                translationPrefix="wc.ecare.accountEntity.entityTypes"
                [parentGroup]="form"
                [options]="entityTypes"
                [disableOptionsTranslate]="true"
                [labelOption]="'name'"
                [valueOption]="'name'"
                [id]="'013_account_entity_detail'">
              </app-form-select-input>
            </div>
            <div class="input">
              <label for="014_account_entity_detail">{{ 'wc.ecare.accountEntity.entityId' | translate }}</label>
              <input type="text" formControlName="entityId" id="014_account_entity_detail" />
              <app-input-errors
                [control]="form.get('entityId')"
                [translationPrefix]="'wc.ecare.accountEntity.entityId'">
              </app-input-errors>
            </div>
            <div class="input">
              <label [for]="'015_account_entity_detail'">{{ 'wc.ecare.accountEntity.roleType' | translate }}</label>
              <app-form-select-input
                selectClass="ecare-dropdown"
                controlName="roleType"
                translationPrefix="wc.ecare.accountEntity.roleType"
                [parentGroup]="form"
                [options]="rolesToSelect"
                [labelOption]="'id'"
                [valueOption]="'id'"
                [id]="'015_account_entity_detail'">
              </app-form-select-input>
            </div>
          </div>
        </div>

        <div class="section-wrapper -full-width">
          <div class="ecare-detail-page-subheader">
            <div id="016_account_entity_detail">{{ 'wc.ecare.accountEntity.entityRoleDetail' | translate }}</div>
          </div>
          <div>
            <div class="section-wrapper inline-form">
              <div>
                <label [for]="'017_account_entity_detail'">{{
                  'wc.ecare.accountEntity.startDateTime' | translate
                }}</label>
                <app-date-picker
                  class="input__group"
                  style="display: flex"
                  [formControlName]="'startDateTime'"
                  [disabled]="!editMode"
                  [id]="'017_account_entity_detail'"
                  [showTime]="false">
                </app-date-picker>
                <app-input-errors
                  [control]="form.get('startDateTime')"
                  [translationPrefix]="'wc.ecare.accountEntity.startDateTime'">
                </app-input-errors>
              </div>
              <div>
                <label [for]="'018_account_entity_detail'">{{
                  'wc.ecare.accountEntity.endDateTime' | translate
                }}</label>
                <app-date-picker
                  class="input__group"
                  style="display: flex"
                  [formControlName]="'endDateTime'"
                  [disabled]="!editMode"
                  [id]="'018_account_entity_detail'"
                  [showTime]="false">
                </app-date-picker>
                <app-input-errors
                  [control]="form.get('endDateTime')"
                  [translationPrefix]="'wc.ecare.accountEntity.startDateTime'">
                </app-input-errors>
              </div>
            </div>
            <div>
              <div class="checkbox">
                <input type="checkbox" formControlName="canDelegate" id="019_account_entity_detail" />
                <label for="019_account_entity_detail">{{ 'wc.ecare.accountEntity.canDelegate' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="accountEntityRoleDto.recordVersion || !grantMode" class="section-wrapper -full-width">
          <div class="ecare-detail-page-subheader">
            <div id="020_account_entity_detail">{{ 'wc.ecare.accountEntity.delegatedFrom' | translate }}</div>
          </div>
          <div>
            <div class="section-wrapper inline-form">
              <div class="input" formGroupName="delegatedByAccount">
                <label for="021_account_entity_detail">{{ 'wc.ecare.accountEntity.login' | translate }}</label>
                <input type="text" formControlName="login" id="021_account_entity_detail" [disabled]="true" />
              </div>
              <div class="input">
                <label for="022_account_entity_detail">{{ 'wc.ecare.accountEntity.delegated' | translate }}</label>
                <input
                  type="text"
                  id="022_account_entity_detail"
                  [disabled]="true"
                  [value]="delegatedFrom?.created | date: 'dd.MM.yyyy'" />
              </div>
              <div class="input">
                <label for="023_account_entity_detail">{{ 'wc.ecare.accountEntity.entityType' | translate }}</label>
                <input
                  type="text"
                  id="023_account_entity_detail"
                  [disabled]="true"
                  [value]="delegatedFrom?.entityType" />
              </div>
              <div class="input">
                <label for="024_account_entity_detail">{{ 'wc.ecare.accountEntity.entityId' | translate }}</label>
                <input type="text" id="024_account_entity_detail" [disabled]="true" [value]="delegatedFrom?.entityId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
