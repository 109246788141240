import { Component, Input, OnInit } from '@angular/core';
import { AbstractPageComponent } from "@btl/btl-fe-wc-common";
import {
  EcareFinancialDocumentsComponent
} from "../wizard/ecare-financial-documents/ecare-financial-documents.component";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoicePayComponent } from "../page/invoice-pay/invoice-pay.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerBillDto } from '@btl/order-bff';

@Component({
  selector: 'app-last-invoices-widget, [app-last-invoices-widget]',
  templateUrl: './last-invoices-widget.component.html',
})
export class LastInvoicesWidgetComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'LastInvoicesWidgetComponent';

  pageId(): string {
    return LastInvoicesWidgetComponent.PAGE_ID;
  }

  @Input() invoices: CustomerBillDto[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public modal: NgbModal,
  ) {
    super(router, route);
  }

  ngOnInit(): void {
  }

  goToEcareFinancialDocuments = () => {
    this.navigateSibling(EcareFinancialDocumentsComponent.PAGE_ID)
  }

  existsRemainingAmount(financialDocument: CustomerBillDto): boolean {
    return financialDocument.remainingAmount && financialDocument.remainingAmount.value > 0;
  }

  onPay(invoiceDto: CustomerBillDto): void {
    const modalRef = this.modal.open(InvoicePayComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'invoice-pay-modal',
    });
    const invoicePayComponent = <InvoicePayComponent>modalRef.componentInstance;
    invoicePayComponent.dialogRef = modalRef;
    invoicePayComponent.setInvoice(invoiceDto);
  }
}
