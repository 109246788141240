import { Component, Input, OnInit } from '@angular/core';
import { PrivilegeEvaluationDto } from '@btl/order-bff';
import { AclService, AuthFactoryService } from '@btl/btl-fe-wc-common';
import { from } from 'rxjs';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';

@Component({
  selector: 'app-acl-internal-evaluate-component, [app-acl-internal-evaluate-component]',
  templateUrl: './acl-internal-evaluate-component.component.html',
  host: {
    '[class.internal]': 'isInternalElementsVisible',
    '[class.hide]': '!isInternalElementsVisible',
  },
})
export class AclInternalEvaluateComponentComponent implements OnInit {
  @Input()
  privilege: string;

  evaluationResult: PrivilegeEvaluationDto;

  isUserLoggedIn: boolean;

  showInternalElements: boolean;

  constructor(
    private aclService: AclService,
    private authFactoryService: AuthFactoryService,
    private customerLocalStorageService: CustomerLocalStorageService
  ) {}

  ngOnInit() {
    from(this.authFactoryService.getAuthService().isLoggedIn()).subscribe(value => {
      if (value) {
        this.isUserLoggedIn = true;
      }
      if (this.privilege) {
        this.aclService.evaluatePrivilege(this.privilege).subscribe(evaluationResult => {
          this.evaluationResult = evaluationResult;
        });
      }
    });

    this.showInternalElements = this.customerLocalStorageService.showInternalElements();

    this.customerLocalStorageService.currentInternalVisible.subscribe(value => {
      this.showInternalElements = value;
    });
  }

  get isInternalElementsVisible(): boolean {
    const account = this.authFactoryService.getAuthService().account;
    return (
      this.isUserLoggedIn &&
      (account && !account.external) &&
      this.showInternalElements &&
      (!this.evaluationResult || (this.evaluationResult && this.evaluationResult.canAccessResource))
    );
  }
}
