import { Component, Input, OnInit } from '@angular/core';
import { AddonsGroupHolder } from '../../../models/addons-group-holder';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-tile',
  templateUrl: './customer-tile.component.html',
})
export class CustomerTileComponent implements OnInit {
  @Input()
  customer: CustomerDto;

  @Input()
  addonsGroups: Array<AddonsGroupHolder>;

  addressTypes = addressTypes;

  isBusiness: boolean;
  isCollapsed: boolean = true;

  constructor(public codebookService: CodebookService) {
  }

  ngOnInit(): void {
    this.isBusiness = this.customer.segment !== 'R';
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
