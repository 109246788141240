import { Component, Input, OnInit } from '@angular/core';
import { NavigationTypeEnum } from "../navigation-menu.component";
import { NavigationMenuService } from "@service/navigation-menu.service";

@Component({
  selector: 'app-shopping-cart-side-menu',
  templateUrl: './shopping-cart-side-menu.component.html',
  styleUrls: ['./shopping-cart-side-menu.component.scss']
})
export class ShoppingCartSideMenuComponent implements OnInit {
  readonly NavigationTypeEnum = NavigationTypeEnum;

  isCollapsed: boolean = false;

  @Input()
  preCalculatedShoppingCart;

  popupTimeout;
  showPopup: 'ShoppingCart' | 'WishList' | '';

  @Input()
  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;

  constructor(private navigationMenuService: NavigationMenuService,) {
  }

  ngOnInit(): void {
    this.navigationMenuService.openShoppingCartPopup.subscribe(() => {
      this.isCollapsed = true;
      clearTimeout(this.popupTimeout);
      this.showPopup = 'ShoppingCart';
    });
  }

  closeCollapse(): void {
    this.isCollapsed = false;
    this.popupTimeout = setTimeout(() => {
      this.showPopup = '';
    }, 170);
  }

}
