import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BlockTemplateComponent,
  CodebookService,
  ElasticsearchService,
  ServiceUtils,
  StockService,
} from '@btl/btl-fe-wc-common';
import { PartyHolder } from '../../../models/party-holder';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { WcOrderingService } from '@service/wc-ordering.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockCustomService } from '@service/stock-custom.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-shop-selection-popup',
  templateUrl: './shop-selection-popup.component.html',
})
export class ShopSelectionPopupComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input()
  dialogRef;

  @Input()
  handler = shop => {};

  @Input()
  productCode;

  @Input()
  value;

  @Input()
  enrichAvailability = false;

  @Input()
  checkAvailability = false;

  shops: Array<PartyHolder>;

  public showPopup: boolean = false;

  page = 1;

  pageSize = 5;

  total = 0;

  canDisplayNoCustomersText = false;

  shopDetail: PartyHolder;

  filterForm = this.formBuilder.group({
    filter: [],
  });

  constructor(
    private elasticsearchService: ElasticsearchService,
    private stockService: StockService,
    private formBuilder: FormBuilder,
    private wcOrderingService: WcOrderingService,
    private ngbModal: NgbModal,
    private codebookService: CodebookService
  ) {}

  public searchByText() {
    const properties: Map<string, any> = new Map<string, any>();
    properties.set('partyRole', 'OrgUnit');
    properties.set('parameters.ouType', 'SHOP_UNIT');

    this.elasticsearchService
      .query(
        ElasticsearchService.PARTIES_INDEX,
        null,
        this.filterForm.value.filter,
        properties,
        null,
        this.page,
        this.pageSize
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(oph => {
        const shops = oph.hits.hits;
        if (shops && this.enrichAvailability && this.productCode) {
          const shopsIds = shops.map(shop => shop._source.code);
          const search = ServiceUtils.getUnlimitedSearch();
          search.filtering = [
            {
              column: 'productCode',
              compareType: 'EQUAL',
              value: this.productCode,
            },
            {
              column: 'store.ouRefNo',
              compareType: 'IN',
              value: shopsIds,
            },
          ];
          this.codebookService
            .getCodebooks(StockCustomService.CODEBOOK_HW_CENTRAL_STOCK_AVAILABILITY)
            .subscribe(centralStockAvailabilityCodebooks => {
              this.stockService
                .getStocksByFilter(search)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(pageStocks => {
                  shops.forEach(shop => {
                    const stock = pageStocks.data.find(stock => stock.store.ouRefNo === shop._source.code);
                    const stockAmount = stock ? stock.amount : 0;
                    const stockAvailabilityCodebook = StockCustomService.getMatchingCodebook(
                      centralStockAvailabilityCodebooks,
                      stockAmount,
                      'POS'
                    );
                    shop['amount'] = this.codebookService
                      .getCodebookText(stockAvailabilityCodebook)
                      .replace('{OrgUnits.Name}:', '')
                      .replace('{Stock.realStock}', stockAmount.toString());
                  });
                });
            });
        }
        this.shops = shops;
        this.total = oph.hits.total.value;
      });
  }

  ngOnInit(): void {
    if (this.enrichAvailability && !this.productCode) {
      console.log('productCode must be configured if enrichAvailability is true');
    }
    this.searchByText();
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  shopSelected(shop) {
    if (this.checkAvailability) {
      this.wcOrderingService
        .getCurrentOrderHwAvailability(shop._source.code)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          if (response.stockAvailability) {
            this.handler(shop);
            this.dialogRef.dismiss();
          } else {
            const dialogReference = this.ngbModal.open(ConfirmationDialogComponent, {
              windowClass: 'dialog dialog-confirmation',
            });

            const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
            confirmationDialogComponent.texts.push('wc.ecare.shop.productsAtStockNotAvailable');
            confirmationDialogComponent.cancellationVisible = false;
            confirmationDialogComponent.confirmationLocalizedKey = 'wc.common.ok.button';
            confirmationDialogComponent.dialogReference = dialogReference;
            confirmationDialogComponent.confirmationHandler = () => {
              confirmationDialogComponent.dialogReference.dismiss();
            };
          }
        });
    } else {
      this.handler(shop);
      this.dialogRef.dismiss();
    }
  }

  onPrevPage() {
    this.page--;
    this.searchByText();
  }

  onNextPage() {
    this.page++;
    this.searchByText();
  }

  onPageSizeChanged(pageSize: number) {
    this.page = 1;
    this.pageSize = pageSize;
    this.searchByText();
  }

  onSpecificPage(pageNo: number) {
    this.page = pageNo;
    this.searchByText();
  }
}
