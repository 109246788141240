import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  AssetDto,
  CustomerAccountDto,
  CustomerDto,
  PriceTypeDto,
  ProductInfoDto,
  ProductSortAttributeDto,
  SortDto, TariffSpaceDto
} from '@btl/order-bff';
import { CategoryTypeEnum, ProductFilter } from 'app/models/product-filter';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { CustomerService } from 'app/services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimChangeComponent } from '../sim-change/sim-change.component';
import { SubscriptionUtils } from 'app/helpers/subscription-utils';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductGroupService, ProductService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { Router } from '@angular/router';
import { AddonsGroupHolder } from '../../../models/addons-group-holder';
import { ProductCustomService } from '@service/product-custom.service';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ProductUtils } from '../../../helpers/product-utils';
import { TariffRestrictionComponent } from 'app/components/page/tariff-restriction/tariff-restriction.component';
import { TariffDeactivationComponent } from 'app/components/page/tariff-deactivation/tariff-deactivation.component';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { DocumentsPopupComponent } from '../../wizard/ecare-documents/documents-popup/documents-popup.component';
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
})
export class SubscriptionDetailComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DocumentsPopupComponent) documentsPopup;

  @Input()
  customer: CustomerDto;

  @Input()
  customerAccount: CustomerAccountDto;

  @Input()
  tariffSpace: TariffSpaceDto;

  asset;

  tariffProduct;

  simProduct;

  commitProduct;

  coreProduct;

  prodcSuTpFixProduct;

  checkCreditResultDto;

  isCollapsed: boolean = true;

  addOnsFee = 0;

  discounts = 0;

  tariffProductPrice: number;

  private onDestroy$: Subject<void> = new Subject<void>();

  private readonly customerContextChangeSubscription: Subscription;

  tariffAddonsInGroups: Array<AddonsGroupHolder>;
  private addonProducts: Map<string, ProductInfoDto> = new Map();
  private addonsByTariffs: Map<string, Array<AddonsGroupHolder>> = new Map();

  filter: ProductFilter = {
    attributes: { categoryId: 'PROD' },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: 4,
    },
  };

  private static readonly ADDONS_CATEGORIES = ['PRODC_SU_CORE_VAS_VOICE_VM', CategoryTypeEnum.PRODC_GE_FUOM];

  private static readonly ADDONS_PARENTS_CATEGORIES = [
    CategoryTypeEnum.PRODC_SU_TARIFF,
    CategoryTypeEnum.PRODC_SU_TP,
    CategoryTypeEnum.PRODC_SU_CORE,
  ];

  constructor(
    private customerService: CustomerService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private orderingService: WcOrderingService,
    private productService: ProductService,
    private productGroupService: ProductGroupService,
    private productCustomService: ProductCustomService,
    private router: Router,
    private ngbModal: NgbModal,
    private stickyMessageService: StickyMessageService
  ) {
    const getProductsByCategories: Array<Observable<ProductInfoDto[]>> = [];
    SubscriptionDetailComponent.ADDONS_CATEGORIES.forEach(category =>
      getProductsByCategories.push(this.productService.getProductsByCategory(category))
    );
    forkJoin(getProductsByCategories)
      .pipe(takeUntil(this.onDestroy$))
      // todo: come up with better solution, how to get product groups for product (due to assigning existing addon asset to the right category)
      .subscribe(results => {
        results.forEach(products => products.forEach(product => this.addonProducts.set(product.id, product)));
      });

    this.customerContextChangeSubscription = this.customerLocalStorageService.contextChanged.subscribe(
      (freshCustomer: CustomerDto) => {
        if (freshCustomer) {
          this.customer = freshCustomer;
          this.customerAccount = CustomerPartyUtil.getChildCustomerAccounts(freshCustomer.childParties).find(
            ca => ca.id === this.customerAccount.id
          );
          this.tariffSpace = CustomerPartyUtil.getChildTariffs(this.customerAccount?.childParties).find(
            ts => ts.id === this.tariffSpace.id
          );

          this.addonsByTariffs.delete(this.tariffSpace?.id);
          this.initSubscriptionDetail(this.tariffSpace);
        }
      }
    );
  }

  ngOnInit(): void {
    this.addonsByTariffs.clear();
    this.initSubscriptionDetail(this.tariffSpace);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    if (this.customerContextChangeSubscription) {
      this.customerContextChangeSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tariffSpace'] && !changes['tariffSpace'].isFirstChange()) {
      this.initSubscriptionDetail(changes.tariffSpace.currentValue);
    }
  }

  private initSubscriptionDetail(tariffSpace: TariffSpaceDto): void {
    if (!tariffSpace) {
      return;
    }

    this.asset = tariffSpace.assets[0];
    this.tariffProduct = this.asset['product'];
    const simCategoryId = this.isPostpaid()
      ? CategoryTypeEnum.PRODC_SU_TP_SIM_POP
      : CategoryTypeEnum.PRODC_SU_TP_SIM_PRP;
    this.simProduct = CustomerPartyUtil.getChildAssetByCategoryId(this.asset, simCategoryId);
    this.commitProduct = CustomerPartyUtil.getChildAssetByCategoryId(this.asset, CategoryTypeEnum.PRODC_SU_COMMIT);
    this.coreProduct = CustomerPartyUtil.getChildAssetByCategoryId(this.asset, CategoryTypeEnum.PRODC_SU_CORE);
    this.prodcSuTpFixProduct = CustomerPartyUtil.getChildAssetByCategoryId(
      this.asset,
      CategoryTypeEnum.PRODC_SU_TP_FIX
    );
    this.tariffProductPrice = CustomerPartyUtil.getPrice(this.tariffProduct, this.coreProduct);

    if (!this.isPostpaid()) {
      this.customerService.checkCredit(this.getMsisdn()).subscribe(checkCredit => {
        this.checkCreditResultDto = checkCredit;
      });
    }

    this.loadAddons();
  }

  getLocationId() {
    if (this.prodcSuTpFixProduct) {
      return CustomerPartyUtil.getAssetProductCharacteristicValue(this.prodcSuTpFixProduct, 'locRefNo');
    }

    return null;
  }

  getSocketId() {
    if (this.prodcSuTpFixProduct) {
      return CustomerPartyUtil.getAssetProductCharacteristicValue(this.prodcSuTpFixProduct, 'socketRefNo');
    }

    return null;
  }

  isPostpaid(): boolean {
    return SubscriptionUtils.isPostpaid(this.tariffProduct);
  }

  getAssetProductCharacteristicValue(asset, name: string): string {
    return CustomerPartyUtil.getAssetProductCharacteristicValue(asset, name);
  }

  getStartDate() {
    return CustomerPartyUtil.getAssetProductCharacteristicValue(this.commitProduct, 'startDate');
  }

  getEndDate() {
    return CustomerPartyUtil.getAssetProductCharacteristicValue(this.commitProduct, 'endDate');
  }

  getSimIcc() {
    return CustomerPartyUtil.getAssetProductCharacteristicValue(this.simProduct, 'simIcc');
  }

  getMsisdn() {
    return SubscriptionUtils.getMsisdn(this.coreProduct);
  }

  getPayerRefNo(): string {
    if (this.customerAccount.parameters['paymentResponsible']) {
      return this.customerAccount.id;
    }
    return null;
  }

  changeSim(): void {
    const modalRef = this.ngbModal.open(SimChangeComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });

    const simChangeComponent = <SimChangeComponent>modalRef.componentInstance;
    simChangeComponent.dialogRef = modalRef;
    simChangeComponent.tariff = this.asset;
    simChangeComponent.customer = this.customer;
    simChangeComponent.customerAccount = this.customerAccount;
    simChangeComponent.tariffSpace = this.tariffSpace;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  private loadAddons() {
    if (this.addonsByTariffs.has(this.tariffSpace.id)) {
      // use already loaded data
      this.tariffAddonsInGroups = [...this.addonsByTariffs.get(this.tariffSpace.id)];
      return;
    }

    const tariffAddonsGroups = new Array<AddonsGroupHolder>();

    const handleAddonProductGroup = (product: ProductInfoDto, asset?: AssetDto) =>
      product.groups
        .filter(group => group.type === 'GUI_ADDON_CATEGORY')
        .forEach(group => {
          const handledAddonsGroup = tariffAddonsGroups.find(
            addonGroupHolder => addonGroupHolder.group.id === group.id
          );
          const addon = asset ? asset : product;
          if (handledAddonsGroup) {
            handledAddonsGroup.addons.push(addon);
          } else {
            tariffAddonsGroups.push({
              group: group,
              addons: [addon],
            });
          }
        });

    // load fresh data
    if (this.coreProduct && this.coreProduct['childAssets']) {
      this.coreProduct['childAssets'].forEach(asset => {
        if (ProductUtils.isOfAnyCategories(asset['product'], SubscriptionDetailComponent.ADDONS_CATEGORIES)) {
          if (this.addonProducts.get(asset.productId)) {
            this.addOnsFee = this.addOnsFee + asset.prices.RC.price;
            handleAddonProductGroup(this.addonProducts.get(asset.productId), asset);
          }
        }
      });
    }

    const assetIds = CustomerPartyUtil.getChildAssetsByCategoryIds(
      this.asset,
      SubscriptionDetailComponent.ADDONS_PARENTS_CATEGORIES
    );
    this.productService
      .getProductsByFilter(this.filter, assetIds, null, this.tariffSpace.id, 'GUI_ADDON_CATEGORY', false)
      .subscribe(
        responseAddons => {
          responseAddons.data.forEach(product => handleAddonProductGroup(product));

          tariffAddonsGroups.sort((o1, o2) => o1.group.priority - o2.group.priority);
          this.addonsByTariffs.set(this.tariffSpace.id, tariffAddonsGroups);
          this.tariffAddonsInGroups = [...tariffAddonsGroups];
        },
        errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            this.router.navigate([this.router.url]);
            this.stickyMessageService.addStickyWarningMessage(
              errorResponse.error.failures.pop().key,
              null,
              errorResponse
            );
          }
        }
      );
  }

  /**
   * Open a dialog for tariff restriction.
   */
  restrictTariff(): void {
    const dialogReference = this.ngbModal.open(TariffRestrictionComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <TariffRestrictionComponent>dialogReference.componentInstance;
    confirmationDialogComponent.customer = this.customer;
    confirmationDialogComponent.customerAccount = this.customerAccount;
    confirmationDialogComponent.tariffSpace = this.tariffSpace;
    confirmationDialogComponent.tariff = this.tariffSpace.assets[0];
    confirmationDialogComponent.localizationParameters = {
      tariffName: this.tariffSpace.assets[0].product.name,
    };
    confirmationDialogComponent.dialogReference = dialogReference;
  }

  /**
   * Open a dialog for tariff deactivation.
   */
  deactivateTariff(): void {
    const dialogReference = this.ngbModal.open(TariffDeactivationComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <TariffDeactivationComponent>dialogReference.componentInstance;
    confirmationDialogComponent.customer = this.customer;
    confirmationDialogComponent.customerAccount = this.customerAccount;
    confirmationDialogComponent.tariffSpace = this.tariffSpace;
    confirmationDialogComponent.tariff = this.tariffSpace.assets[0];
    confirmationDialogComponent.localizationParameters = {
      tariffName: this.tariffSpace.assets[0].product.name,
    };
    confirmationDialogComponent.dialogReference = dialogReference;
  }

  /**
   * todo: load real tariff services instead of fake
   */
  get fakeTariffService(): FakeTariffService {
    if (this.coreProduct?.product?.categoryId === 'PRODC_SU_CORE_VOICE') {
      return {
        commercialName: 'Voice service',
        customName: "Olivia's phone number",
        identifier: '+1 306 569 3510',
        isMobileTariff: true,
      };
    }

    return {
      commercialName: 'Wireline Internet service',
      customName: 'My Internet at home',
      identifier: 'Park Street 35, Regina, Saskatchewan, S4N 7K7, CAN',
      isMobileTariff: false,
    };
  }

  get fakeTariffServices(): FakeTariffService[] {
    return [this.fakeTariffService];
  }

  openDocumentPopUp() {
    this.documentsPopup.showPopUp();
  }
}

export interface FakeTariffService {
  commercialName: string;
  customName: string;
  identifier: string;
  isMobileTariff: boolean;
}
