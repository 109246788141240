import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductService } from '@btl/btl-fe-wc-common';
import { CategoryTypeEnum, ProductFilter } from '../../../models/product-filter';
import { PaginationComponent } from 'app/components/page/pagination/pagination.component';
import { PagedProductInfosDto, ProductParamConfigsDataDto, ProductSortAttributeDto, SortDto } from '@btl/order-bff';
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

/**
 * {@code ProductFilterComponent} is a component responsible for managing criteria of product listing filter.
 *
 * Clients of this component can subscribe changes via {@link onChange}.
 */
@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
})
export class ProductFilterComponent implements OnInit, OnChanges {
  //region Data:

  isCollapsed: boolean = false;

  /**
   * The default category type.
   */
  static DEFAULT_CATEGORY_TYPE = CategoryTypeEnum.PRODC_FREE_HW;

  /**
   * Pricerange default config
   */
  pricerangeConfig: any = {
    connect: true,
    disabled: false,
    start: [0, 1000000000],
    tooltips: true,
  };

  /**
   * The filter limiting the products.
   */
  filter: ProductFilter = {
    attributes: {
      categoryId: ProductFilterComponent.DEFAULT_CATEGORY_TYPE,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Asc,
    },
    paging: {
      page: 1,
      pageSize: PaginationComponent.pageSizeOptions[0],
    },
  };

  /**
   * The parameters of products of the HW category.
   */
  productParameters: ProductParamConfigsDataDto = {
    map: {
      brandName: [],
      operatingSystem: [],
      technicalParameters: [],
    },
  };

  sliders: any;

  //region IO:

  /**
   * The product search results by the current filter.
   */
  @Input()
  pagedProductInfosDto: PagedProductInfosDto;

  /**
   * An event emitter activated when a change in the filter occurs.
   */
  @Output()
  readonly onChange: EventEmitter<ProductFilter> = new EventEmitter<ProductFilter>();

  //region Exported types:

  readonly SortOrderDtoEnum = SortOrderDtoEnum;

  readonly ProductSortAttributeDto = ProductSortAttributeDto;

  constructor(private productService: ProductService) {}

  //region Life-cycle:

  ngOnInit() {
    this.configurePriceRange();
    this.loadAvailableOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configurePriceRange();
  }

  //region Event handling:

  public priceRangeHandler(changes: any): void {
    this.filter.attributes.priceRange.max = changes[1];
    this.filter.attributes.priceRange.min = changes[0];

    this.handleChange();
  }

  /**
   * Handle change in product rating: set the value to the filter and invoke {@link handleChange()}.
   *
   * @param {string} rating The new rating.
   */
  //Todo Add when rating will be supported
  /*public handleProductRatingChange(rating: string): void {
    this.filter.attributes.ratingReview = rating;
    this.handleChange();
  }*/

  /**
   * Handle change in sorting: set the sorting to the filter and invoke {@link handleChange()}.
   *
   * @param {ProductSortAttributeDto } attribute The attribute of sorting.
   * @param {SortOrderDtoEnum} direction The sorting direction.
   */
  public handleSortingChange(attribute: ProductSortAttributeDto, direction: SortOrderDtoEnum) {
    const sorting: SortDto = {
      column: attribute,
      sortOrder: direction,
    };
    this.filter.sorting = sorting;

    this.handleChange();
  }

  /**
   * Handle any change on any field in the filter. When a change happens, an event is emitted (see {@link onChange}).
   */
  public handleChange(): void {
    this.onChange.emit(this.filter);
  }

  /**
   * Handle changes on brand name. When a change happens, an event is emitted (see {@link onChange}).
   */
  public handleBrandNameChange($event): void {
    this.filter.attributes.parametersStatic['brandName'] = $event.toString();
    this.onChange.emit(this.filter);
  }

  /**
   * Handle changes on operating system. When a change happens, an event is emitted (see {@link onChange}).
   */
  public handleOperatingSystemChange($event): void {
    this.filter.attributes.parametersStatic['operatingSystem'] = $event.toString();
    this.onChange.emit(this.filter);
  }

  //region Helpers:

  /**
   * Configure price range.
   * TODO: find solution how to set default values to slider
   */
  private configurePriceRange() {
    if (this.sliders) {
      this.sliders = document.querySelectorAll('.rangeInput input');
      this.sliders[0].value = this.filter.attributes.priceRange.min;
    }
  }

  /**
   * Load parameters of products of the HW category.
   */
  private loadAvailableOptions() {
    const handleProductParameters = (productParameters: ProductParamConfigsDataDto) => {
      for (const parameterName in productParameters.map) {
        this.productParameters.map[parameterName] = productParameters.map[parameterName];
        this.filter.attributes.parametersStatic[parameterName] = '';
      }
    };

    const categoryType: string = this.filter.attributes.categoryId;

    this.productService.getProductParameters(categoryType).subscribe(handleProductParameters);
  }
}
