import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

export enum PaymentCardType {
  VISA = 'VISA',
  MASTERCARD = 'MasterCard',
}

export interface PaymentCardData {
  cardNumber: string;
  expiryDate: Date;
  type: PaymentCardType;
}

export interface ShippingAddress {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  zipCode: string;
  state: string;
}

@Component({
  selector: 'app-ecare-user-account-payments',
  templateUrl: './ecare-user-account-payments.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountPaymentsComponent.PAGE_ID })
export class EcareUserAccountPaymentsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountPaymentsComponent';

  pageId(): string {
    return EcareUserAccountPaymentsComponent.PAGE_ID;
  }

  private static readonly PAYMENT_CARD_NO_PROTECTION_PART = {
    startIndex: 6,
    endIndex: 12,
  };

  paymentCards: PaymentCardData[];
  shippingAddresses: ShippingAddress[];

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }

  ngOnInit() {
    this.initPaymentCards();
    this.initShippingAddress();
  }

  private initPaymentCards() {
    this.paymentCards = [
      {
        cardNumber: '4511616244884959',
        expiryDate: new Date(2024, 8),
        type: PaymentCardType.VISA,
      },
      {
        cardNumber: '4511616266334452',
        expiryDate: new Date(2023, 7),
        type: PaymentCardType.VISA,
      },
    ];
  }

  private initShippingAddress() {
    this.shippingAddresses = [
      {
        firstName: 'Juraj',
        lastName: 'Janosik',
        street: '1001 Janosik Street',
        city: 'Janosik Towne',
        zipCode: 'TX 75 001',
        state: 'United States',
      },
      {
        firstName: 'Jindra',
        lastName: 'Janosikova',
        street: '1001 Janosik Street',
        city: 'Janosik Towne',
        zipCode: 'TX 75 001',
        state: 'United States',
      },
      {
        firstName: 'Ondra',
        lastName: 'Janosik',
        street: '1001 Janosik Street',
        city: 'Janosik Towne',
        zipCode: 'TX 75 001',
        state: 'United States',
      },
    ];
  }

  formatPaymentCardNumber(cardNumber: string): string {
    const protectionCharsCount =
      EcareUserAccountPaymentsComponent.PAYMENT_CARD_NO_PROTECTION_PART.endIndex -
      EcareUserAccountPaymentsComponent.PAYMENT_CARD_NO_PROTECTION_PART.startIndex;
    const protectedCardNumber =
      cardNumber.substring(0, EcareUserAccountPaymentsComponent.PAYMENT_CARD_NO_PROTECTION_PART.startIndex) +
      '*'.repeat(protectionCharsCount) +
      cardNumber.substring(EcareUserAccountPaymentsComponent.PAYMENT_CARD_NO_PROTECTION_PART.endIndex);
    return protectedCardNumber.replace(/(.{4})/g, '$1 ').replace(/[*]/g, '<span class="protect-symbol"></span>');
  }
}
