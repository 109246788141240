import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-switch, [app-toggle-switch]',
  templateUrl: './toggle-switch.component.html',
})
export class ToggleSwitchComponent {
  @Input() checked: boolean;
  @Input() disabled?: boolean;
}
