<div [formGroup]="parentGroup">
  <div class="form-field-picker generated-input-container col12">
    <input class="form__input" hidden="true" type="text" [formControlName]="controlName" />
    <input
      disabled
      type="text"
      class="form__input form-field-picker-input {{ disabled ? 'disabled' : '' }}"
      [value]="showValue" />
    <i
      *ngIf="showValue && !disabled"
      class="cursor-pointer remove-button"
      inlineSVG="/assets/svg/delete.svg"
      (click)="remove()">
    </i>
    <app-button
      class="picker-button"
      iconPath="/assets/svg/search-icon.svg"
      variant="primary"
      [disabled]="disabled"
      (click)="select()" />
  </div>
  <app-input-errors
    [control]="parentGroup.controls[controlName]"
    [translationPrefix]="translationPrefix + '.' + controlName" />
</div>
