<button
  type="button"
  class="close"
  [attr.aria-label]="'wc.common.close' | translate"
  (click)="dialogReference.dismiss()">
  <span aria-hidden="true">&times;</span>
</button>

<div class="ecare_modal-title" id="modal-basic-title">
  {{ 'wc.ecare.accountSettings.delete.title' | translate }}
</div>

<div class="ecare_modal-text">
  {{ 'wc.ecare.accountSettings.delete.message' | translate }}
</div>

<input
  class="ecare_modal-input"
  type="text"
  placeholder="{{ 'wc.ecare.accountSettings.delete.inputPlaceholder' | translate }}" />

<div class="buttons">
  <button class="btn btn-link btn-no" type="button" (click)="handleNo()">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button class="btn btn-default btn-yes btn-delete" type="button" (click)="handleYes()">
    <i [inlineSVG]="'/assets/svg/delete.svg'"></i>
    {{ 'wc.ecare.accountSettings.delete.confirm' | translate }}
  </button>
</div>
