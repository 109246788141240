import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShoppingCartItemsComponent } from '../../page/shopping-cart-items/shopping-cart-items.component';
import { ActivatedRoute, Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderDto, ProductDetailDto } from '@btl/order-bff';
import { AuthFactoryService, ProductService, TicketingService } from '@btl/btl-fe-wc-common';
import { OrderingWizardActionsComponent } from '../../page/ordering-wizard-actions/ordering-wizard-actions.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { ScenarioTypeEnum } from '../../../helpers/order-utils';
import { TicketService } from '@service/ticket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuspendOrderComponent } from '../checkout-page/suspend-order/suspend-order.component';
import { CancelOrderComponent } from 'app/components/page/cancel-order/cancel-order.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAddingToCart } from '@service/product-listing.service';

/**
 * {@code ShoppingCartComponent} is a component responsible for displaying editable shopping cart.
 */
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('shoppingCartItems', { static: true }) shoppingCartItemsComponent: ShoppingCartItemsComponent;
  @ViewChild('orderingWizardActions', { static: true }) orderingWizardActions: OrderingWizardActionsComponent;

  PRODUCT_ID_URL_PARAM: string = 'productId';
  paramProductId: string;

  product: ProductDetailDto;

  scenarioTypeEnum = ScenarioTypeEnum;

  suspendOrder: boolean = false;

  extUserAuthMode: string = null;

  userLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public wcOrderingService: WcOrderingService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private authFactoryService: AuthFactoryService,
    private productService: ProductService,
    private ticketingService: TicketingService,
    private ngbModal: NgbModal,
  ) {}

  ngOnInit() {
    this.fillProductIdUrlParams();
    this.initShoppingCart();

    const handleGetCurrentOrder = () => {
      if (!this.wcOrderingService.currentOrder) {
        throw new Error('There is no current order!');
      }
    };

    // get authentication mode
    this.propertyAccessorLocalService.getExtUserAuthMode().subscribe(result => {
      this.extUserAuthMode = result;
      this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(handleGetCurrentOrder);
    });

    // check if user is logged in
    this.authFactoryService
      .getAuthService()
      .isLoggedIn()
      .then(isLoggedIn => {
        this.userLoggedIn = isLoggedIn;
      })
      .catch(reason => {
        throw new Error(`Cannot check login status: ${reason}`);
      });
  }

  initShoppingCart() {
    if (this.paramProductId && this.paramProductId !== '') {
      this.productService
        .getProductById(this.paramProductId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(product => {
          const addOrderItem = (currentOrder: OrderDto) => {
            this.wcOrderingService
              .addProductToShoppingCartWithParent(currentOrder, product as ProductAddingToCart)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((order: OrderDto) => {});
          };
          this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
        });
    }
  }

  private fillProductIdUrlParams() {
    const productId = this.route.snapshot.queryParamMap.get(this.PRODUCT_ID_URL_PARAM);
    if (productId && productId !== '') {
      this.paramProductId = productId;
      this.router.navigate([], {
        queryParams: {
          productId: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Continue to the next page if child forms are valid. Stay on the same page otherwise.
   */
  continue() {
    if (this.shoppingCartItemsComponent.validate() === true) {
      const performSaving = (currentOrder: OrderDto) => {
        this.shoppingCartItemsComponent.save(currentOrder);

        this.wcOrderingService
          .updateOrderItems(currentOrder, currentOrder.orderItems)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((orderWithUpdatedItemAttributes: OrderDto) => {
            if (orderWithUpdatedItemAttributes) {
              if (this.wcOrderingService.currentOrder?.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT) {
                const opportunity = TicketService.addReference(
                  TicketService.getLocalOpportunity(),
                  'com.emeldi.ecc.be.order.dto.Order',
                  this.wcOrderingService.currentOrder.id,
                );

                const id = opportunity.id;
                opportunity.id = null;
                TicketService.clearFieldsBeforeUpdate(opportunity);
                this.ticketingService
                  .updateTicket(id, opportunity)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(response => {
                    TicketService.removeLocalOpportunity();
                    this.wcOrderingService.removeCurrentOrderFromContext();
                    this.router.navigate(['/ecare/opportunities/', response.id]);
                  });
              } else {
                this.orderingWizardActions.continueToNextStep();
              }
            }
          });
      };

      this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(performSaving);
    }
  }

  suspendOrderPopup() {
    const modalRef = this.ngbModal.open(SuspendOrderComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });
    const suspendOrderComponent = <SuspendOrderComponent>modalRef.componentInstance;
    suspendOrderComponent.dialogRef = modalRef;
  }

  cancelOrderPopup() {
    const modalRef = this.ngbModal.open(CancelOrderComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <CancelOrderComponent>modalRef.componentInstance;
    confirmationDialogComponent.dialogRef = modalRef;
  }
}
