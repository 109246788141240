import { EventEmitter, Injectable, Output } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailComparisonCommonService {
  @Output()
  readonly loadProduct: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly loadDiscount: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly showBuyButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly showPreorderButton: EventEmitter<any> = new EventEmitter<any>();

  invokeAddToBasketEmitter: EventEmitter<any> = new EventEmitter();

  public transformValueByType(value: string, type: string, unit: string, locale: string = 'cs'): string {
    let transformedValue: string = value;
    //for STRING the value stays the same
    //for STRING, BOOLEAN is already translated
    switch (type) {
      case 'DATE':
        const date = new Date(value);
        let timeFormat = '';
        if (date.getHours() !== 0 && date.getMinutes() !== 0 && date.getSeconds() !== 0) {
          timeFormat = ' HH:MM:SS';
        }
        if (locale === 'cs') {
          transformedValue = formatDate(value, `DD. MM. YYYY${timeFormat}`, locale);
        } else {
          transformedValue = formatDate(value, `DD MMM YYYY${timeFormat}`, locale);
        }
        break;
      case 'FLOAT':
        transformedValue = parseFloat(value).toLocaleString(locale);
        break;
      case 'INTEGER':
        transformedValue = parseInt(value).toLocaleString(locale);
        break;
    }
    return transformedValue + (unit ? ` ${unit}` : '');
  }

  public callAddToBasket(preorder: boolean = false, fast: boolean = false) {
    this.invokeAddToBasketEmitter.emit({ preorder: preorder, fast: fast });
  }
}
