import { Component, Input } from '@angular/core';
import { OrderDto } from '@btl/order-bff';

@Component({
  selector: 'app-last-orders-widget, [app-last-orders-widget]',
  templateUrl: './last-orders-widget.component.html'
})
export class LastOrdersWidgetComponent {
  @Input() orders: OrderDto[] = [];
  @Input() componentType: 'lastOrders' | 'ordersInDelivery';
}
