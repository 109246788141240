<ng-container *ngIf="!isHomepage; else homepage">
  <div class="no-results">
    <div class="-common-heading-text">
      {{ headingTextKey | translate }}
    </div>

    <div class="-img">
      <i [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
    </div>

    <div class="-entity-label-text">{{ labelTextKey | translate }}</div>
  </div>
</ng-container>
<ng-template #homepage>
  <div class="no-results">

    <div class="-img">
      <i [inlineSVG]="'/assets/svg/undraw_No_data_re_kwbl.svg'"></i>
    </div>

    <div class="-entity-label-text">{{ labelTextKey | translate }}</div>
    <div class="breaker"></div>
    <div class="-entity-label-text only-text-low-opacity">
      <span>{{ 'wc.ecare.homepage.search.empty.text' | translate }}</span>
      &nbsp;<a (click)="clearSearchField()">{{ 'wc.ecare.homepage.search.empty.text.action' | translate }}</a>
    </div>

  </div>
</ng-template>

