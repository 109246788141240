import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { CustomerDto, PagedDmsFilesDto } from '@btl/order-bff';
import { AbstractPageComponent, BlockTemplateComponent, EnableDynamicLoading, Search } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@service/customer.service';

@Component({
  selector: 'app-ecare-customers',
  templateUrl: './ecare-customers.component.html',
})
@EnableDynamicLoading({ customName: EcareCustomersComponent.PAGE_ID })
export class EcareCustomersComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareCustomersComponent';

  pageId(): string {
    return EcareCustomersComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() pageSize: number = 20;
  @Output() readonly selectedCustomer = new EventEmitter<CustomerDto>();

  customers: CustomerDto[] = [];
  pagedDto: PagedDmsFilesDto;
  canDisplayNoCustomersText = false;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    this.search.paging.pageSize = this.pageSize;
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadCustomers();
  }

  private loadCustomers() {
    this.blockUIElement.start();
    this.customerService
      .getCustomers(this.search)
      .pipe(
        finalize(() => {
          this.canDisplayNoCustomersText = _.isEmpty(this.customers);
          this.blockUIElement.stop();
        })
      )
      .subscribe(pagedCustomers => {
        this.pagedDto = pagedCustomers;
        this.customers = pagedCustomers.data;
        this.blockUIElement.stop();
      });
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadCustomers();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadCustomers();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadCustomers();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadCustomers();
  }

  customerSelected(customer: CustomerDto): void {
    this.selectedCustomer.emit(customer);
  }
}
