<div class="info-banner-container">
  <div *ngIf="iconsListMeta" class="container hp-container">
    <ng-container *ngFor="let iconMeta of iconsListMeta">
      <div class="info-banner-item">
        <i [inlineSVG]="iconMeta.iconImage"></i>
        <div>
          <h6>{{ iconMeta.title | selectLocaleProperty }}</h6>
          <p *ngIf="iconMeta.content">
            <span app-cms-widget-rich-content [content]="iconMeta.content | selectLocaleProperty"></span>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
