<div class="error-left-side">
  <div class="error-content">
    <div *ngIf="causedByMessage; else shortMessage">
      <div class="error-code">{{ 'wc.common.error' | translate }} {{ errorCode }}</div>
      <div class="error-name">
        {{ 'wc.common.errorCode.' + errorCode + '.header' | translate }}
      </div>
      <div class="error-description-wrapper">
        <div class="error-description">
          {{ 'wc.common.errorCode.' + errorCode | translate }}
          <a class="cursor-pointer" app-acl-internal-evaluate-component (click)="openErrorPageModal()">
            {{ 'wc.common.error.detail.button' | translate }}
          </a>
        </div>
      </div>

      <ng-contaioner *ngTemplateOutlet="errorButtons"></ng-contaioner>
    </div>

    <ng-template #shortMessage>
      <div class="error-code">{{ 'wc.common.error' | translate }} {{ errorCode }}</div>
        <div class="error-name">
          {{ 'wc.common.errorCode.' + errorCode + '.header' | translate }}
        </div>
        <div class="error-description">
          {{ 'wc.common.errorCode.' + errorCode | translate }}
        </div>
      <ng-contaioner *ngTemplateOutlet="errorButtons"></ng-contaioner>
    </ng-template>
  </div>
</div>
<div class="error-right-side">
  <i [inlineSVG]="'/assets/svg/error-' + errorCode + '.svg'"></i>
</div>

<ng-template #errorButtons>
  <div *ngIf="!errorOn" class="errorButtons">
    <div
      app-button
      variant="secondary"
      iconPath="/assets/svg/arrow-dropleft-icon.svg"
      label="{{ 'wc.common.back.button' | translate }}"
      (click)="goBack()"></div>
    <div
      app-button
      variant="primary"
      iconPath="/assets/svg/home-page.svg"
      label="{{ 'wc.common.goToHomepage.button' | translate }}"
      (click)="goHomepage()"></div>
  </div>
  <div
    *ngIf="errorOn === errorOnEnum.ORDER_CONFIRMATION.toString()"
    app-button
    variant="primary"
    iconPath="/assets/svg/home-page.svg"
    label="{{ 'wc.common.goToSummary.button' | translate }}"
    [routerLink]="'/eshop/summary-page'"></div>
</ng-template>
