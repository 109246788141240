<div>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
      <div class="-filter-fields -filter-fields-group">
        <input
          id="002_contact_history_filter"
          type="text"
          formControlName="id"
          class="filter-input-id"
          placeholder="{{
            'wc.ecare.contactHistoryFilter.id.placeholder' | translate
          }}"
        />
        <app-advanced-filter
          [form]="filterForm"
          [isAdvancedFilterOpen]="isAdvancedFilterOpen"
          (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
          [changedFilterFieldsCount]="changedFilterFieldsCount"
          clearHistoryTextLocalizedKey="wc.ecare.contactHistory.clearFilters"
          (resetFieldsEmitter)="resetFilterFields()"
        >
          <app-advanced-filter-field
            [fieldType]="'input'"
            [hidden]="customerLocalStorageService.getCurrentCustomer()"
            fullWidth="true"
            labelLocalizedKey="wc.ecare.contactHistoryFilter.relatedTo"
            controlName="relatedTo"
            idAttribute="008_contact_history_filter"
            placeholderLocalizedKey="wc.ecare.contactHistoryFilter.relatedTo.placeholder"
            (changeFilterFieldAction)="
              changeFilterField($event.controlName, $event.value)
            "
          >
          </app-advanced-filter-field>
          <app-advanced-filter-field
            [fieldType]="'date'"
            labelLocalizedKey="wc.ecare.contactHistory.createdDate"
            controlName="createdDateFrom"
            startOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
            (changeFilterFieldAction)="
              changeFilterField($event.controlName, $event)
            "
          >
          </app-advanced-filter-field>
          <app-advanced-filter-field
            [fieldType]="'date'"
            labelLocalizedKey="wc.ecare.contactHistory.createdDate"
            controlName="createdDateTo"
            endOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.contactHistory.createdDateTo.placeholder"
            (changeFilterFieldAction)="
              changeFilterField($event.controlName, $event)
            "
          >
          </app-advanced-filter-field>
          <app-advanced-filter-field
            [fieldType]="'input'"
            labelLocalizedKey="wc.ecare.contactHistoryFilter.paramName"
            controlName="paramName"
            idAttribute="005_contact_history_filter"
            placeholderLocalizedKey="wc.ecare.contactHistoryFilter.paramName.placeholder"
            (changeFilterFieldAction)="
              changeFilterField($event.controlName, $event.value)
            "
          >
          </app-advanced-filter-field>
          <app-advanced-filter-field
            [fieldType]="'input'"
            labelLocalizedKey="wc.ecare.contactHistoryFilter.paramValue"
            controlName="paramValue"
            idAttribute="006_contact_history_filter"
            placeholderLocalizedKey="wc.ecare.contactHistoryFilter.paramValue.placeholder"
            (changeFilterFieldAction)="
              changeFilterField($event.controlName, $event.value)
            "
          >
          </app-advanced-filter-field>
        </app-advanced-filter>
      </div>
      <app-button
        variant="primary"
        id="004_contact_history_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </form>
  </div>
</div>
