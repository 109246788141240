import {Component, ElementRef, Input, QueryList, Renderer2, ViewChildren} from '@angular/core';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { FormBuilder } from '@angular/forms';
import { OrderDto, TicketDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  ServiceUtils,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PartyElse } from '../../../models/party-holder';
import {
  EcareSearchFilterComponent,
  SearchEntityDataType,
  SearchEntityType
} from './ecare-search-filter/ecare-search-filter.component';
import { addressTypes } from 'app/pipes/display-address.pipe';
import { finalize } from 'rxjs/operators';
import { OpportunityDetailsComponent } from '../ecare-opportunities/opportunity-details/opportunity-details.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ecare-search',
  templateUrl: './ecare-search.component.html',
})
@EnableDynamicLoading({ customName: EcareSearchComponent.PAGE_ID })
export class EcareSearchComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareSearchComponent';

  HOMEPAGE_RECORDS_COUNT: number = 10;

  @Input() isHomepage: boolean = false;

  isMore: boolean = false;

  pageId(): string {
    return EcareSearchComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  addressTypes = addressTypes;
  searchEntityType: SearchEntityType;
  lastSearch: SearchEntityDataType;

  /** A list of searched customers. null means no search is active, empty list means no results were found.
   *
   */
  customers: PartyElse[] = null;

  /**
   * A list of found orders. null means no search is active, empty list means no results were found.
   */
  foundOrders: OrderDto[] = null;

  /**
   * A list of found opportunities. null means no search is active, empty list means no results were found.
   */
  foundOpportunities: TicketDto[] = null;

  isModuleBffCrmEnabled = false;

  @ViewChildren('dropdownElement') dropdownElements: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2,
    protected router: Router,
    protected route: ActivatedRoute,
    private customerLocalStorageService: CustomerLocalStorageService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private orderingService: WcOrderingService,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private fb: FormBuilder,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.isModuleBffCrmEnabled = bffCrmEnabled));
  }

  customerSearchByValue(customerSearch: string) {
    this.isMore = false;
    this.blockUIElement.start();
    this.customerService.searchByText(customerSearch, 33).subscribe(customerHolders => {
      const foundCustomers = customerHolders.map(customerHolder => customerHolder._source);
      if (foundCustomers?.length === 1) {
        this.selectCustomer(foundCustomers[0].partyRefNo);
      } else {
        this.customers = [...foundCustomers];
        if (this.isHomepage) {
          if (this.customers.length > this.HOMEPAGE_RECORDS_COUNT) {
            this.isMore = true;
            this.customers = this.customers.slice(0, this.HOMEPAGE_RECORDS_COUNT);
          }
        }
      }
      this.blockUIElement.stop();
    });
  }

  selectCustomer(customerId: string, targetUrl?: string) {
    this.customerLocalStorageService.selectSearchedCustomer(customerId, targetUrl);
  }

  selectCustomerFromRow(customerId: string, event: any) {
    let isDropdownClick = false;
    if (this.dropdownElements && this.dropdownElements.length) {
      this.dropdownElements.forEach(dropdownElement => {
        if (!isDropdownClick && dropdownElement.nativeElement?.contains(event.target)) {
          isDropdownClick = true;
        }
      });
    }
    if (!isDropdownClick) {
      this.selectCustomer(customerId);
    }
  }

  /**
   * Search orders.
   */
  private searchOrders(orderId: string): void {
    this.isMore = false;
    this.blockUIElement.start();
    this.orderingService.searchOrders(orderId).subscribe((order: OrderDto) => {
      this.foundOrders = [];
      if (order) {
        this.foundOrders.push(order);
      }
      if (this.isHomepage) {
        if (this.foundOrders.length > this.HOMEPAGE_RECORDS_COUNT) {
          this.isMore = true;
          this.foundOrders = this.foundOrders.slice(0, this.HOMEPAGE_RECORDS_COUNT);
        }
      }
      this.blockUIElement.stop();
    });
  }

  private searchOpportunities(opportunityId: string): void {
    this.isMore = false;
    this.blockUIElement.start();
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push(
      {
        column: 'type',
        compareType: null,
        value: {
          code: OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE,
          areaType: 'CUSTOMER',
        },
      },
      {
        column: 'subject',
        compareType: CompareTypeDtoEnum.LIKE,
        value: `*${opportunityId}*`,
      }
    );
    this.ticketingService.getTicketsByFilter(search).subscribe(result => {
      this.foundOpportunities = [];
      if (result) {
        this.foundOpportunities = result.data;
      }
      if (this.isHomepage) {
        if (this.foundOpportunities.length > this.HOMEPAGE_RECORDS_COUNT) {
          this.isMore = true;
          this.foundOpportunities = this.foundOpportunities.slice(0, this.HOMEPAGE_RECORDS_COUNT);
        }
      }
      this.blockUIElement.stop();
    });
  }

  handleSearch(searchData: SearchEntityDataType) {
    this.lastSearch = searchData;
    switch (searchData.searchEntity) {
      case SearchEntityType.CUSTOMER:
        this.customerSearchByValue(searchData.searchValue);
        break;
      case SearchEntityType.ORDER:
        this.searchOrders(searchData.searchValue);
        break;
      case SearchEntityType.OPPORTUNITIES:
        this.searchOpportunities(searchData.searchValue);
        break;
    }
  }

  changeSearchEntity(searchEntity: SearchEntityType) {
    this.searchEntityType = searchEntity;

    this.foundOrders = null;
    this.customers = null;
    this.foundOpportunities = null;
  }

  isSearchPerformed(): boolean {
    switch (this.searchEntityType) {
      case SearchEntityType.CUSTOMER:
        return !!this.customers;
      case SearchEntityType.ORDER:
        return !!this.foundOrders;
      case SearchEntityType.OPPORTUNITIES:
        return !!this.foundOpportunities;
    }
  }

  isSearchOrders(): boolean {
    return this.searchEntityType === SearchEntityType.ORDER;
  }

  isSearchCustomers(): boolean {
    return this.searchEntityType === SearchEntityType.CUSTOMER;
  }

  isSearchOpportunities(): boolean {
    return this.searchEntityType === SearchEntityType.OPPORTUNITIES;
  }

  get noResultsTextKey(): string {
    if (this.isSearchPerformed()) {
      return `wc.ecare.search.noResults.${this.searchEntityType}`;
    }
    return `wc.ecare.search.notSearched.${this.searchEntityType}`;
  }
}
