import { Component } from '@angular/core';
import { CmsWidgetButtonBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-button]',
  templateUrl: './cms-widget-button.component.html',
  styleUrls: ['./cms-widget-button.component.scss'],
})
@EnableDynamicLoading({ customName: 'BUTTON' })
export class CmsWidgetButtonComponent extends CmsWidgetButtonBaseComponent {}
