import { Injectable } from '@angular/core';
import { CodebookService, CurrentLocaleService, ExternalScriptsService } from '@btl/btl-fe-wc-common';

@Injectable({
  providedIn: 'root',
})
export class SocialNetworksService {
  public static readonly FACEBOOK_SDK_SOURCE_URL = 'https://connect.facebook.net/{locale}/sdk.js#xfbml=1&version=v15.0';
  private static readonly TWITTER_SDK_SOURCE_URL = 'https://platform.twitter.com/widgets.js';

  constructor(
    private externalScriptsService: ExternalScriptsService,
    private currentLocaleService: CurrentLocaleService,
    private codebookService: CodebookService
  ) {}

  activateFacebook() {
    this.loadFbScript();
    this.currentLocaleService.currentLocaleChange.subscribe(() => {
      this.loadFbScript();
    });
  }

  activateTwitter() {
    this.externalScriptsService.loadScript(SocialNetworksService.TWITTER_SDK_SOURCE_URL).then();
  }

  loadFbScript() {
    let fbLocale = this.currentLocaleService.getCurrentLanguage();
    this.codebookService.getCodebooks('COUNTRY').subscribe(result => {
      const localeCB = result.find(
        cb => cb.parameters.find(param => param.name === 'languages' && param.value).value.indexOf(fbLocale) > -1
      );
      fbLocale += `_${localeCB.parameters.find(param => param.name === 'iso_alpha2').value}`;
      const fbScriptUrl = SocialNetworksService.FACEBOOK_SDK_SOURCE_URL.replace('{locale}', fbLocale);
      this.externalScriptsService.loadScript(fbScriptUrl).then();
    });
  }
}
