<div [formGroup]="form">
  <div *ngFor="let type of types; let i = index" class="form-recipients">
    <ng-container *ngIf="i === 0 || showAll">
      <div class="form-label">
        {{ type }}
      </div>
      <div class="type-recipients border">
        <div class="recipients">
          <div *ngFor="let recipient of this.getFormArray(type).controls" class="recipient border mb-0">
            <ng-container *ngIf="recipient?.value?.recipientName; else splitName">
              {{ recipient?.value?.recipientName }} <{{ recipient.value.address }}>
            </ng-container>
            <ng-template #splitName>
              {{ recipient?.value?.firstName }} {{ recipient?.value?.middleName }} {{ recipient?.value?.lastName }} <{{
                customerFormComponent.getRecipientEmail(recipient.value)
              }}>
            </ng-template>
            <button type="button" class="btn-link" (click)="remove(recipient, type)">X</button>
          </div>
        </div>

        <app-button
          variant="link"
          label="{{ 'wc.ecare.notifications.addRecipient' | translate }}"
          iconPath="/assets/img/notifications/find_recipient.svg"
          (click)="add(type, this.getFormArray(type).value)">
        </app-button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!showAll">
    <app-button
      variant="secondary"
      label="{{ 'wc.ecare.notifications.addCcBcc' | translate }}"
      iconPath="/assets/img/notifications/add_cc-bcc.svg"
      (click)="showAll = true">
    </app-button>
  </div>
</div>
