<ng-container *ngIf="customer">
  <ng-template #caNodeTemplate let-data>
    <ng-container *ngIf="data.role === 'CustomerAccount' && !omittedResults.includes(data.id)">
      <div
        class="ca-element"
        [ngClass]="{ selected: selectedAccount.id === data.id, selectedParent: selectedParents.includes(data.id) }"
        (click)="loadCustomerAccount(data, $event)">
        <div class="ca-element-text">
          {{ data.displayName }} {{ getCACount(data) ? '(' + getCACount(data) + ')' : '' }}
        </div>
        <div *ngIf="getCACount(data)" class="ca-element-opener" (click)="toggle(wrapper, arrow)">
          <div #arrow class="{{ ARROW_DOWN_CSS_CLASS }}">
            <i inlineSVG="/assets/svg/arrow-dropleft-icon.svg"></i>
          </div>
        </div>
      </div>
      <div #wrapper>
        <ng-container *ngIf="data.childParties">
          <div class="ca-element-child-group">
            <ng-container
              *ngFor="let child of data.childParties"
              [ngTemplateOutlet]="caNodeTemplate"
              [ngTemplateOutletContext]="{ $implicit: child }" />
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <div class="customer-account-box">
    <div #mainControl class="ng-select-container" (click)="rollingPartOpen = !rollingPartOpen">
      <div class="contact-details-dropdown_leftSide">
        <div class="icon-container fullHeight">
          <i inlineSVG="/assets/svg/account-overview.svg"></i>
        </div>
        <div class="contact-details-dropdown_wrapper">
          <div class="fullName">
            {{ customer.subject?.firstName }} {{ customer.subject?.lastName }} {{ customer.extId }}
          </div>
          <div class="additional-info">
            {{ customerLocalStorageService.getCurrentCustomerAccount()?.displayName }}
          </div>
        </div>
      </div>
      <div class="contact-details-dropdown_arrowSide {{ rollingPartOpen ? 'open' : '' }}">
        <span></span>
        <i [inlineSVG]="'/assets/svg/section-arrow.svg'"></i>
      </div>
    </div>
    <div #rollingPart class="customer-account-box-rolling {{ rollingPartOpen ? 'open' : '' }}">
      <div class="home-link-wrapper ca-element" (click)="goHome()">
        <i class="go-home-icon" [inlineSVG]="'/assets/svg/home-page.svg'"></i>
        <div class="ca-element-text">{{ 'wc.ecare.goHome' | translate }}</div>
      </div>
      <div class="home-link-wrapper ca-element" (click)="goCustomer()">
        <i class="go-home-icon" [inlineSVG]="'/assets/svg/account-overview.svg'"></i>
        <div class="ca-element-text">
          {{ customer.subject?.firstName }} {{ customer.subject?.lastName }} {{ customer.extId }}
        </div>
      </div>
      <form>
        <div class="search-input">
          <input
            #searchAccount
            id="002_customers_filter"
            type="text"
            placeholder="Search"
            (input)="searchCustomerAccount(searchAccount.value)" />
          <i inlineSVG="/assets/svg/search-icon.svg"></i>
        </div>
      </form>
      <div class="customer-accounts-list">
        <ng-container
          *ngFor="let caNode of allRootParties"
          [ngTemplateOutlet]="caNodeTemplate"
          [ngTemplateOutletContext]="{ $implicit: caNode }" />
      </div>
    </div>
  </div>
</ng-container>
