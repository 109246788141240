<div *blockUI="dialogId; template: blockTemplate">
  <div *ngIf="heading" class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ heading | translate: localizationParameters }}
    </h4>
    <div
      *ngIf="cancellationVisible"
      class="modal-close"
      [disabled]="cancellationDisabled"
      (click)="dialogReference.dismiss()">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>

  <div class="modal-body">
    <ng-content> </ng-content>
  </div>

  <div class="modal-footer">
    <div>
      <app-button
        *ngIf="cancellationVisible"
        variant="whiteWithBorder"
        [disabled]="cancellationDisabled"
        label="{{ (cancelLocalizedKey ? cancelLocalizedKey : 'wc.common.no.button') | translate }}"
        (click)="handleNo()">
      </app-button>

      <app-button
        variant="primary"
        [disabled]="confirmationDisabled"
        [iconPath]="confirmationIconPath"
        label="{{ (confirmationLocalizedKey ? confirmationLocalizedKey : 'wc.common.yes.button') | translate }}"
        (click)="handleYes()">
      </app-button>
    </div>
  </div>
</div>
