import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-mobile-tariff-widget, [app-mobile-tariff-widget]',
  templateUrl: './mobile-tariff-widget.component.html',
})
export class MobileTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {

}
