import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Product } from '../../../models/product';
import { GroupTreeNode } from '@btl/btl-fe-wc-common';
import { ProductDetailDto } from '@btl/order-bff';

/**
 * ProductListingItemComponent is a component responsible for displaying one product inside ProductListingComponent.
 */
@Component({
  selector: 'app-product-listing-item, [app-product-listing-item]',
  templateUrl: './product-listing-item.component.html',
})
export class ProductListingItemComponent implements OnDestroy, OnInit {
  //region Data:
  /**
   * A flag specifying if product detail is visible. This is only related to grid type of product listing.
   */
  detailVisibility: boolean = false;

  //region IO:

  /**
   * The displayed product.
   */
  @Input()
  product: Product;

  @Input()
  parentProductId: string;

  @Input()
  parentInstanceIds: Array<string>;

  @Input()
  partyId: string;

  @Input()
  tariffListing: boolean;

  @Input()
  loading: boolean;

  @Input()
  currentProductGroup: GroupTreeNode;

  /**
   * The ID of the party of the original product. This is only relevant for tariff change.
   */
  @Input()
  originalProductPartyId: string;

  @Input()
  originalProductLocationId: string;

  @Input()
  originalProductSocketId: string;

  /**
   * The original product. This is only relevant for tariff change.
   */
  @Input()
  originalProduct: ProductDetailDto;

  @Input()
  public compareAvailable: boolean = false;

  @Input()
  parentId = '';

  public checkScanRequired: boolean = false;

  private static allProductTiles: Array<ProductListingItemComponent> = new Array<ProductListingItemComponent>();
  private allSubscriptions: Subscription[] = new Array<Subscription>();

  //region Life-cycle:

  ngOnInit() {
    this.validateInputs();
    this.checkScanRequired = this.isCheckIfScanRequired(this.currentProductGroup, this.originalProduct);
  }

  ngOnDestroy() {
    for (const subscription of this.allSubscriptions) {
      subscription.unsubscribe();
    }
    const index = ProductListingItemComponent.allProductTiles.findIndex(tile => tile === this);
    ProductListingItemComponent.allProductTiles.splice(index, 1);
  }

  isCheckIfScanRequired(productGroup: GroupTreeNode, product: ProductDetailDto): boolean {
    // identify scan-check from the original product attribute (like in product detail)
    if (!_.isNil(product)) {
      if (product.parametersStatic['checkScanRequired'] === 'true') {
        return true;
      }
    }

    // identify from the product group recursively
    if (productGroup) {
      let isRequired = false;
      if (productGroup.group.groupParams['checkScanRequired'] === 'true') {
        this.product.groups.forEach(groups => {
          if (groups.id === productGroup.group.id) {
            isRequired = true;
          }
        });
      }
      if (!isRequired && productGroup.children) {
        for (const child of productGroup.children) {
          isRequired = isRequired || this.isCheckIfScanRequired(child, product);
        }
      }
      return isRequired;
    }
    return false;
  }

  /**
   * Handle change of visibility of product detail.
   */
  handleDetailVisibilityChange(): void {
    this.detailVisibility = !this.detailVisibility;
  }

  private validateInputs() {
    if (!this.product) {
      throw new Error('The product parameter must have non-null value.');
    }
  }
}
