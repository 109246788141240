import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { HomepageComponent } from './components/wizard/homepage/homepage.component';
import { ProductListingComponent } from './components/wizard/product-listing/product-listing.component';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { TariffChangeComponent } from './components/wizard/tariff-change/tariff-change.component';
import { ProductDetailComponent } from './components/wizard/detail/product-detail/product-detail.component';
import { ShoppingCartComponent } from './components/wizard/shopping-cart/shopping-cart.component';
import { LookForCustomerComponent } from './components/wizard/look-for-customer/look-for-customer.component';
import { CheckoutPageComponent } from './components/wizard/checkout-page/checkout-page.component';
import { CreateCustomerComponent } from './components/wizard/create-customer/create-customer.component';
import { ThankYouPageComponent } from './components/wizard/thank-you-page/thank-you-page.component';
import { SummaryPageComponent } from './components/wizard/summary-page/summary-page.component';
import { PaymentComponent } from './components/wizard/payment/payment.component';
import { AppAuthGuard, CmsPageDraftRenderComponent } from '@btl/btl-fe-wc-common';
import { ProductComparisonComponent } from './components/page/product-comparison/product-comparison.component';
import { ServiceDetailComponent } from './components/wizard/detail/service-detail/service-detail.component';

import { DeliveryComponent } from './components/wizard/delivery/delivery.component';
import { CheckoutScoringComponent } from './components/wizard/checkout-scoring/checkout-scoring.component';
import { TechnologyCheckComponent } from './components/wizard/technology-check/technology-check.component';
import { WishListDetailComponent } from './components/wizard/wish-list/wish-list-detail/wish-list-detail.component';
import { AppPageTemplateComponent } from './components/page/app-page-template/app-page-template.component';
import { CmsPageTemplateBlankComponent } from './components/cms-templates/page/blank/cms-page-template-blank.component';
import { WishListsComponent } from './components/wizard/wish-list/wish-lists/wish-lists.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';


const ProductListingCanDeactivate: CanDeactivateFn<ProductListingComponent> = (
  component: ProductListingComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState?: RouterStateSnapshot
) => {
  component.clearCurrentProductGroup();
  return true;
};
/**
 * For routes should be always defined 'component: AppPageTemplateComponent' for the 'root' route
 * and the component that contains required logic or HTML template should be as a child route, e.g.:
 * ```
 * [
 *  {
 *    path: 'eshop',
      component: AppPageTemplateComponent,
      children: [
        {
          path: 'product-listing',
          component: ProductListingComponent,
          canActivate: [OrderingWizardService]
        },
        ...
      ]
 *  }
 * ]
 * ```
 *
 * Otherwise, the page layout may not be displayed correctly.
 */
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: AppPageTemplateComponent,
    data: {
      moduleId: 'eshop',
    },
    children: [
      {
        path: '',
        component: HomepageComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'eshop',
    redirectTo: 'eshop/product-listing',
    pathMatch: 'full',
    data: {
      moduleId: 'eshop',
    },
  },
  {
    path: 'eshop',
    component: AppPageTemplateComponent,
    data: {
      moduleId: 'eshop',
    },
    children: [
      {
        path: 'product-listing',
        component: ProductListingComponent,
        canActivate: [OrderingWizardService],
        canDeactivate:[ProductListingCanDeactivate]
      },
      {
        path: 'product-listing/:seoUrl',
        component: ProductListingComponent,
        canActivate: [OrderingWizardService],
        canDeactivate:[ProductListingCanDeactivate]
      },
      {
        path: 'search/:text',
        component: ProductListingComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'tariff-change',
        component: TariffChangeComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'technology-check',
        component: TechnologyCheckComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'product-detail/:seoUrl',
        component: ProductDetailComponent,
      },
      {
        path: 'service-detail',
        component: ServiceDetailComponent,
      },
      {
        path: 'shopping-cart',
        component: ShoppingCartComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'shopping-cart/:authCode',
        component: ShoppingCartComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'shopping-cart/id/:orderRefNo',
        component: ShoppingCartComponent,
        canActivate: [OrderingWizardService, AppAuthGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'checkout-page/look-for-customer',
        component: LookForCustomerComponent,
      },
      {
        path: 'checkout-page',
        component: CheckoutPageComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'delivery',
        component: DeliveryComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'checkout-page/create-customer',
        component: CreateCustomerComponent,
      },
      {
        path: 'checkout-scoring',
        component: CheckoutScoringComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'thank-you-page',
        component: ThankYouPageComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'summary-page',
        component: SummaryPageComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [OrderingWizardService],
      },
      {
        path: 'wish-list-detail',
        component: WishListDetailComponent,
      },
      {
        path: 'wish-list-detail/:authCode',
        component: WishListDetailComponent,
      },
      {
        path: 'wish-lists',
        data: {
          breadcrumb: 'WishLists',
        },
        children: [
          {
            path: '',
            component: WishListsComponent,
          },
          {
            path: ':breadcrumb',
            component: WishListDetailComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'compare',
    component: AppPageTemplateComponent,
    data: {
      moduleId: 'eshop',
    },
    children: [
      {
        path: '',
        component: ProductComparisonComponent,
        pathMatch: 'full',
      },
      {
        path: ':seoUrl1',
        component: ProductComparisonComponent,
      },
      {
        path: ':seoUrl1/:seoUrl2',
        component: ProductComparisonComponent,
      },
      {
        path: ':seoUrl1/:seoUrl2/:seoUrl3',
        component: ProductComparisonComponent,
      },
    ],
  },

  // *****************************************************************************
  // ** dynamic routes are loaded here (e.g. for CMS pages, eCare modules etc.) **
  // ** see app-init.ts                                                         **
  // *****************************************************************************

  {
    path: 'preview/cms-content/:verHash',
    component: CmsPageDraftRenderComponent,
  },
  {
    // example of static route to CMS content
    path: 'cms-content/:seoUrl',
    component: CmsPageTemplateBlankComponent,
    data: {
      loadPageConfig: true,
      isStaticRoute: true,
      moduleId: 'eshop',
    },
  },
  {
    path: '**',
    component: AppPageTemplateComponent,
    children: [
      {
        path: '',
        component: ErrorPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      initialNavigation: 'disabled', // routes will be updated and navigation initialized in app-init.ts
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
