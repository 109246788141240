import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { FormUtils } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddRecipientModalComponent } from './add-recipient-modal/add-recipient-modal.component';
import { CustomerFormComponent } from '../page/customer-form/customer-form.component';
import { NotificationRecipientDto } from '@btl/order-bff';

@Component({
  selector: 'app-form-recipients',
  templateUrl: './form-recipients.component.html',
  styleUrls: ['./form-recipients.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRecipientsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormRecipientsComponent),
    },
  ],
})
export class FormRecipientsComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value: Array<NotificationRecipientDto>;

  @Input()
  collapsed = false;

  @Input()
  control: AbstractControl;

  @Input()
  types: Array<string>;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  showAll: boolean = false;

  form: FormGroup = this.formBuilder.group({});

  customerFormComponent = CustomerFormComponent;

  constructor(private formBuilder: FormBuilder, private ngbModal: NgbModal) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }

    this.types.forEach(type => {
      if (this.form.get(type)) {
        (this.form.get(type) as FormArray).clear();
      } else {
        this.form.addControl(type, this.formBuilder.array([]));
      }

      this.value
        .filter(recipient => recipient.recipientType === type)
        .forEach(recipient => this.addToFormArray(recipient, type));
    });
  }

  public static getSubFormControlConfig() {
    return {
      id: [null],
      address: [null, Validators.required],
      recipientName: [null],
      sent: [false],
      recipientState: ['READY'],
      partyRefNo: [],
      recipientType: [],
    };
  }

  addToFormArray(value: NotificationRecipientDto, type: string) {
    const newDataFormGroup = this.formBuilder.group(FormRecipientsComponent.getSubFormControlConfig());
    // @ts-ignore
    newDataFormGroup.patchValue(value);
    this.getFormArray(type).push(newDataFormGroup);
    this.new = false;
  }

  getFormArray(type: string) {
    return this.form.get(type) as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      let recipients = [];
      Object.keys(this.form.controls).forEach(group => {
        recipients = recipients.concat((this.form.get(group) as FormArray).getRawValue());
      });
      return recipients;
    }
    return null;
  }

  add(type: string, recipients: any) {
    const modalRef = this.ngbModal.open(AddRecipientModalComponent, { windowClass: 'recipient-modal' });
    const addRecipientModalComponent = <AddRecipientModalComponent>modalRef.componentInstance;
    addRecipientModalComponent.dialogRef = modalRef;
    addRecipientModalComponent.type = type;
    addRecipientModalComponent.recipients = recipients;
    addRecipientModalComponent.addHandler = (recipientArray: FormArray) => {
      this.getFormArray(type).clear();
      recipientArray.controls.forEach(control => {
        this.getFormArray(type).push(control);
      });
      modalRef.close();
    };
  }

  remove(formToRemove: AbstractControl, type) {
    let index = 1;
    let foundIndex = null;
    this.getFormArray(type).controls.forEach(control => {
      if (control === formToRemove) {
        foundIndex = index;
      }
      index++;
    });

    if (foundIndex) {
      this.getFormArray(type).removeAt(foundIndex - 1);
    }
  }
}
