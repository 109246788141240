import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { CustomerService } from 'app/services/customer.service';
import { BlockUIService } from 'ng-block-ui';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-ecare-dashboard-subscription',
  templateUrl: './ecare-dashboard-subscription.component.html',
})
export class EcareDashBoardSubscriptionComponent {
  blockTemplate = BlockTemplateComponent;

  constructor(
    private readonly route: ActivatedRoute,
    private customerLocalStorageService: CustomerLocalStorageService,
    private customerService: CustomerService,
    private blockUIService: BlockUIService
  ) {}

  asset;

  customer;

  customerAccount;

  tariffSpace;

  tariffSpaceConsumptionInfos = [];

  get subscriptionBlockUiElementName() {
    return 'subscription';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const tariffSpaceId = queryParams.tariffSpaceId;
      const customerAccountId = queryParams.customerAccountId;
      const tariffId = queryParams.tariffId;

      if (tariffSpaceId) {
        this.customer = this.customerLocalStorageService.getCurrentCustomer();
        if (this.customer) {
          this.customerAccount = this.customerLocalStorageService.getPartyByIdRecursive(
            this.customer.childParties,
            customerAccountId
          );
          if (this.customerAccount) {
            this.tariffSpace = this.customerLocalStorageService.getItemById(
              this.customerAccount.childParties,
              tariffSpaceId
            );
            if (this.tariffSpace) {
              this.asset = this.customerLocalStorageService.getItemById(this.tariffSpace.assets, tariffId);
            }
          }
        }
      }
    });

    this.blockUIService.start(this.subscriptionBlockUiElementName);
    /*const isPrepaid = !SubscriptionUtils.isPostpaid(this.asset);
    const msisdn = SubscriptionUtils.getMsisdn(CustomerPartyUtil.getChildAssetByCategoryId(this.asset, CategoryTypeEnum.PRODC_SU_CORE_GSM));
    this.customerService.getTariffSpaceConsumptionInfo(msisdn, isPrepaid).subscribe(tariffSpaceConsumptionInfoDto => {
      if (tariffSpaceConsumptionInfoDto) {
        if (tariffSpaceConsumptionInfoDto.dataBundles) {
          tariffSpaceConsumptionInfoDto.dataBundles.forEach(bundle => {
            bundle.type = BundleTypeEnum.DATA;
            this.tariffSpaceConsumptionInfos.push(bundle);
          });
        }
        if (tariffSpaceConsumptionInfoDto.voiceBundles) {
          tariffSpaceConsumptionInfoDto.voiceBundles.forEach(bundle => {
            bundle.type = BundleTypeEnum.VOICE;
            this.tariffSpaceConsumptionInfos.push(bundle);
          });
        }
        if (tariffSpaceConsumptionInfoDto.smsmmsBundles) {
          tariffSpaceConsumptionInfoDto.smsmmsBundles.forEach(bundle => {
            bundle.type = BundleTypeEnum.SMS;
            this.tariffSpaceConsumptionInfos.push(bundle);
          });
        }
      }
      this.blockUIService.stop(this.subscriptionBlockUiElementName);
    });*/
  }
}
