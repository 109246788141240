<div *ngIf="iconsListMeta" class="homepage-categories">
  <ng-container *ngFor="let iconMeta of iconsListMeta">
    <div class="category" [routerLink]="iconMeta.linkUrl">
      <div class="icon-container">
        <i [inlineSVG]="iconMeta.iconImage"></i>
      </div>
      <p>{{ iconMeta.title | selectLocaleProperty }}</p>
    </div>
  </ng-container>
</div>
