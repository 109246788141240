import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class ScoringTimeoutInterceptor implements HttpInterceptor {
  static readonly SCORING_TIMEOUT = 200;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.scoringServiceEndPoint) === true) {
      return next.handle(request).pipe(timeout(ScoringTimeoutInterceptor.SCORING_TIMEOUT));
    }

    return next.handle(request);
  }
}
