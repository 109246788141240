import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AddressHolder } from '../../../../models/address-holder';
import { ProductElasticFilter } from '../../../../models/product-elastic-filter';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FiltersComponent } from '../../../wizard/product-listing/filters/filters.component';
import { addressTypes, DisplayAddressPipe } from '../../../../pipes/display-address.pipe';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { AddressService } from '@service/address.service';
import { Constants } from '../../../../constants';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-address-search-control',
  templateUrl: './address-search-control.component.html',
  styleUrls: ['./address-search-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSearchControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AddressSearchControlComponent),
    },
  ],
})
export class AddressSearchControlComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
  @Input()
  value: string;

  @Input()
  private control: AbstractControl;

  @Input()
  addressType: addressTypes;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addressSearch', { static: false }) private searchInput: ElementRef;
  private searchTerms = new Subject<string>();
  public autocompleteProducts: Observable<AddressHolder[]>;
  private maxAutocompleteResults: number;
  private maxAutoCompleteResultsSubscription: Subscription;
  public filter: ProductElasticFilter;

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  constructor(
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private addressService: AddressService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    this.initAddressAutoComplete();
  }

  ngAfterViewInit(): void {
    this.valueChanged();
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  valueChanged() {
    if (this.value && this.searchInput) {
      const addressPipe = new DisplayAddressPipe(this.codebookService, this.currentLocaleService);
      this.searchInput.nativeElement.value = addressPipe.transform(JSON.parse(this.value), this.addressType);
    }
  }

  getValue() {
    return this.value;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  private initAddressAutoComplete() {
    this.maxAutoCompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .subscribe(maxAutocompleteResults => {
        this.maxAutocompleteResults = maxAutocompleteResults;
        this.valueChanged();
      });
    this.autocompleteProducts = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),
      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<AddressHolder[]>([]);
        } else {
          return this.addressService.searchByText(term, this.maxAutocompleteResults).pipe(source => {
            return source;
          });
        }
      })
    );
  }

  public searchChange(text: string) {
    this.searchTerms.next(text);
  }

  public autocompleteFocusOut(autocomplete: HTMLElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  private selectAddress(address) {
    this.value = JSON.stringify(address._source);
    this.valueChanged();
    this.propagateChange(this.getValue());
  }
}
