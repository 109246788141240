import { Component, OnInit } from '@angular/core';
import { OrderDto, PaymentDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { PaymentService } from '@service/payment.service';
import { getCurrentHost } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * PaymentComponent is a component responsible for redirecting to NTS Checkout.
 */
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit {
  //region URL query parameters:

  /**
   * The URL query parameter containing ID of the NTS order that was subject of the payment.
   */
  public static readonly NTS_ORDER_ID_URL_QUERY_PARAMETER = 'cartid';

  /**
   * The URL query parameter containing ID of the NTS transaction identifying the payment.
   */
  public static readonly NTS_TRANSACTION_ID_URL_QUERY_PARAMETER = 'rtxid';

  /**
   * The URL query parameter containing ID of order.
   */
  public static readonly NTS_INTERNAL_ORDER_ID_URL_QUERY_PARAMETER = 'orderId';

  /**
   * The URL query parameter containing a flag specifying if payment was unsuccessful (from business reasons).
   */
  public static readonly PAYMENT_FAIL_URL_QUERY_PARAMETER = 'paymentFailure';

  /**
   * A flag specifying if this component is displayed after unsuccessful (from technical reasons) payment (true), or otherwise (false).
   */
  public static readonly PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER = 'paymentTechnicalFailure';

  public static readonly PAYMENT_CALLBACK_URL_BASE = 'callbackUrlBase';

  public static readonly PAYMENT_SUCCESS_URL = 'successUrl';

  public static readonly PAYMENT_BACK_PARAMS = 'backParams';

  public static readonly PAYMENT_ERROR_URL = 'errorUrl';

  callbackUrlBase;
  successUrl;
  errorUrl;
  backParams;

  constructor(
    private orderingService: WcOrderingService,
    private paymentService: PaymentService,
    private router: Router,
    private readonly route: ActivatedRoute
  ) {}

  //region Life-cycle:

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.callbackUrlBase = queryParams[PaymentComponent.PAYMENT_CALLBACK_URL_BASE];
      this.successUrl = queryParams[PaymentComponent.PAYMENT_SUCCESS_URL];
      this.backParams = queryParams[PaymentComponent.PAYMENT_BACK_PARAMS];
      this.errorUrl = queryParams[PaymentComponent.PAYMENT_ERROR_URL];

      if (!this.callbackUrlBase) {
        this.callbackUrlBase = `${getCurrentHost()}/eshop/`;
      }
      if (!this.successUrl) {
        this.successUrl = 'thank-you-page';
      }
      if (!this.errorUrl) {
        this.errorUrl = 'summary-page';
      }
    });
    this.pay();
  }

  //region Helpers:

  /**
   * Prepare payment for the current order. Once it is prepared, redirect to NTS Checkout.
   */
  private pay(): void {
    const processPayment = (order: OrderDto) => {
      const orderId = order.id;

      let successCallbackUrl = `${this.callbackUrlBase}${this.successUrl}?orderId=${orderId}`;
      let errorCallbackUrl = `${this.callbackUrlBase}${this.errorUrl}?orderId=${orderId}&${PaymentComponent.PAYMENT_FAIL_URL_QUERY_PARAMETER}=true`;
      let redirectBackUrl = `${this.callbackUrlBase}${this.errorUrl}?orderId=${orderId}&${PaymentComponent.PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER}=true`;

      if (this.backParams) {
        successCallbackUrl += `&${this.backParams}`;
        errorCallbackUrl += `&${this.backParams}`;
        redirectBackUrl += `&${this.backParams}`;
      }

      const redirectToPayment = (payment: PaymentDto) => {
        if (payment) {
          window.location.href = payment.redirectUrl;
        }
      };

      const redirectBack = () => {
        window.location.href = redirectBackUrl;
        return of(null);
      };
      this.paymentService
        .payOrder(orderId, successCallbackUrl, errorCallbackUrl)
        .pipe(catchError(redirectBack))
        .subscribe(redirectToPayment);
    };
    this.orderingService.getCurrentOrder().subscribe(processPayment);
  }
}
