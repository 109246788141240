import { Component, Input } from '@angular/core';
import { EcareOrderTableComponent } from './ecare-order-table.component';

@Component({
  selector: 'app-ecare-order-table-mobile',
  templateUrl: './ecare-order-table-mobile.component.html'
})
export class EcareOrderTableMobileComponent extends EcareOrderTableComponent {
  @Input() isWidget: boolean = false;
  @Input() maxThumbnailsToShow: number;
}
