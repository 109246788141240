import { Injectable } from '@angular/core';
import { ProductElasticFilter } from '../models/product-elastic-filter';
import { CodebookService, CurrentLocaleService, ElasticsearchService, ProductService } from '@btl/btl-fe-wc-common';
import { CodebookDto, ProductDetailDto, ProductParamMetadataDto, SocketService } from '@btl/order-bff';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ProductsHolder } from '../models/products-holder';
import { Product } from '../models/product';
import { ColorService, ColorVariant } from './color.service';
import { ProductDetailComparisonCommonService } from './product-detail-comparison-common.service';
import { AvailabilityEnum } from '../models/availability.enum';
import { OrderErrorHandler } from 'app/services/errors/order-error-handler';
import { FilterService } from './filter.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';

/**
 * Service for retrieving product list
 */
@Injectable({
  providedIn: 'root',
})
export class ProductCustomService {
  private PHONE_BRAND_CATEGORIES = ['prd_l_hw_mpho', 'prd_l_hw_tblt'];
  private parameterListGroup$: Observable<CodebookDto[]>;

  constructor(
    private elasticSearchService: ElasticsearchService,
    private currentLocaleService: CurrentLocaleService,
    private colorService: ColorService,
    private productService: ProductService,
    private productDetailAndComparisonCommonService: ProductDetailComparisonCommonService,
    private codebookService: CodebookService,
    private orderErrorHandler: OrderErrorHandler,
    private translateService: TranslateService,
    private socketService: SocketService
  ) {}

  isScanRequired(productCode): Observable<any> {
    return new Observable<any>(observer => {
      this.socketService
        .getProductChildren(productCode)
        .subscribe(result => {
          let scanRequired = false;
          result.forEach(product => {
            if (product?.product?.parametersStatic && product.product.parametersStatic['checkScanRequired'] === 'true') {
              scanRequired = true;
            }
          });
          observer.next({ value: scanRequired });
        });
    });
  }


  /**
   * Get a list of products by the given filter. If no product matches the filter, an empty list is returned instead.
   *
   * @param filter The product filter.
   * @param aggregationsJson JSON containing elasticsearch aggregations
   * @param onlyHw Only hardware
   * @returns {Observable<any>} A list of hits containing products matching the filter.
   */
  public getProductsByFilter(
    filter: ProductElasticFilter,
    aggregationsJson: object = null,
    onlyHw?: boolean
  ): Observable<any> {
    if (!filter) {
      throw new Error('Filter must be configured.');
    }
    const freeText: string = filter.attributes.text;
    const properties = this.getElasticsearchProperties(filter);
    return this.elasticSearchService.query(
      FilterService.PRODUCTS_INDEX,
      this.currentLocaleService.getCurrentLanguage(),
      freeText,
      properties,
      filter.sorting,
      filter.paging.page,
      filter.paging.pageSize,
      filter.paging.firstPageSize,
      aggregationsJson,
      onlyHw
    );
  }

  /**
   * Returns crossed/since price for product comparison
   * @param productDetail Product deatil DTO
   */
  public getCrossedAndSincePrice(productDetail: ProductDetailDto): { crossedPrice: number; sincePrice: number } {
    let crossedPrice: number = null;
    let sincePrice: number = null;

    if (productDetail.parametersStatic['crossedPriceEnabled'] === 'true') {
      const hardwarePrice = productDetail.prices['OC'].price;
      const priceBestOffer = productDetail.parametersStatic['priceBestOffer'];
      const priceSuperficial = productDetail.parametersStatic['priceSuperficial'];

      let priceBestOfferNum;
      let priceSuperficialNum;
      if (priceBestOffer && (priceBestOfferNum = parseInt(priceBestOffer)) && priceBestOfferNum < hardwarePrice) {
        sincePrice = priceBestOfferNum;
      } else if (
        priceSuperficial &&
        (priceSuperficialNum = parseInt(priceSuperficial)) &&
        priceSuperficialNum > hardwarePrice
      ) {
        crossedPrice = priceSuperficialNum;
      }
    }
    return {
      crossedPrice: crossedPrice,
      sincePrice: sincePrice,
    };
  }

  /**
   * Returns crossed price for product detail
   * @param productDetail Product deatil DTO
   */
  public getCrossedPrice(productDetail: ProductDetailDto): number | null {
    let crossedPrice: number = null;

    if (productDetail.parametersStatic['crossedPriceEnabled'] === 'true') {
      const hardwarePrice = productDetail.prices['OC'].price;
      const priceSuperficial = productDetail.parametersStatic['priceSuperficial'];

      let priceSuperficialNum;
      if (
        priceSuperficial &&
        (priceSuperficialNum = parseInt(priceSuperficial)) &&
        priceSuperficialNum > hardwarePrice
      ) {
        crossedPrice = priceSuperficialNum;
      }
    }
    return crossedPrice;
  }

  private getElasticsearchProperties(productElasticFilter: ProductElasticFilter) {
    const properties: Map<string, any> = new Map<string, any>();

    if (productElasticFilter.attributes.groupId) {
      properties.set('groups.id', productElasticFilter.attributes.groupId);
    }
    if (productElasticFilter.attributes.groupCode) {
      properties.set('groups.code', productElasticFilter.attributes.groupCode);
    }
    if (productElasticFilter.attributes.availability && productElasticFilter.attributes.availability.length > 0) {
      properties.set('stockAvailability', productElasticFilter.attributes.availability);
    }
    if (productElasticFilter.attributes.color && productElasticFilter.attributes.color.length > 0) {
      properties.set('colorVariants.colorCode', productElasticFilter.attributes.color);
    }
    if (productElasticFilter.attributes.price && productElasticFilter.attributes.price.length > 0) {
      properties.set('prices.priceIndex', productElasticFilter.attributes.price);
    }
    if (productElasticFilter.attributes.screenSize && productElasticFilter.attributes.screenSize.length > 0) {
      properties.set('screenSize', productElasticFilter.attributes.screenSize);
    }
    if (productElasticFilter.attributes.brand && productElasticFilter.attributes.brand.length > 0) {
      properties.set('brand', productElasticFilter.attributes.brand);
    }
    if (productElasticFilter.attributes.operatingSystem && productElasticFilter.attributes.operatingSystem.length > 0) {
      properties.set('operatingSystem', productElasticFilter.attributes.operatingSystem);
    }
    if (productElasticFilter.attributes.ram && productElasticFilter.attributes.ram.length > 0) {
      properties.set('ram', productElasticFilter.attributes.ram);
    }
    if (productElasticFilter.attributes.totalMemory && productElasticFilter.attributes.totalMemory.length > 0) {
      properties.set('totalMemory', productElasticFilter.attributes.totalMemory);
    }
    if (
      productElasticFilter.attributes.compatibleAccessoryOf &&
      productElasticFilter.attributes.compatibleAccessoryOf.length > 0
    ) {
      properties.set('compatibleAccessoryOf', productElasticFilter.attributes.compatibleAccessoryOf);
    }

    return properties;
  }

  /**
   * Search elasticsearch for products by given text
   * @param term Text to search by
   */
  public searchByText(term: string, maxHits: number): Observable<ProductsHolder[]> {
    return this.elasticSearchService
      .query(FilterService.PRODUCTS_INDEX, this.currentLocaleService.getCurrentLanguage(), term, null, null, 1, maxHits)
      .pipe(map(oph => oph.hits.hits));
  }

  public getProductGroupsForProduct(productCodes: string[]): Observable<Product> {
    const properties = new Map<string, any>();
    properties.set('id', productCodes);
    return this.elasticSearchService
      .query(FilterService.PRODUCTS_INDEX, null, null, properties, null, 1, 1)
      .pipe(map(oph => (oph.hits.hits[0] ? oph.hits.hits[0]._source : null)));
  }

  /**
   * Returns all color variants of the given product
   * @param productCode Product
   */
  public getColorVariants(productCode: string): Observable<Array<ColorVariant>> {
    this.colorService.getColorsFromCodebook();
    const groupProducts$ = this.productService.getGroupProducts(null, 'GUI_HW_COLOR_VARIANT', productCode);
    const colorSubject$ = this.colorService.getColors();
    return combineLatest([groupProducts$, colorSubject$]).pipe(
      map(([groupProducts, colors]: [Array<ProductDetailDto>, Map<string, { name: string; rgb: string }>]) => {
        const colorVariants: Array<ColorVariant> = [];
        for (const groupProduct of groupProducts) {
          let color = colors.get(groupProduct.parametersStatic['colorId']);
          if (!color) {
            console.log(`ColorId parameter is not set for product color variant: ${groupProduct.id}`);
            //Get first color as default
            color = colors.values().next().value;
          }
          if (color && color.rgb) {
            const colorsSum: number = 0;
            const [red, green, blue] = color.rgb.split(',').map(rgbPart => parseInt(rgbPart));
            const colorVariant: ColorVariant = {
              productCode: groupProduct.productCode,
              colorId: groupProduct.parametersStatic['colorId'],
              name: color.name,
              rgb: color.rgb,
              seoUrl: groupProduct.seoUrl,
              fontColor: red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? 'black' : 'white',
            };
            colorVariants.push(colorVariant);
          }
        }
        return colorVariants;
      }),
      share()
    );
  }

  public getPhoneBrands(): Observable<Array<string>> {
    const prefixes = new Map<string, any>();
    prefixes.set('categoryId', this.PHONE_BRAND_CATEGORIES);
    return this.elasticSearchService.getBucketList(FilterService.PRODUCTS_INDEX, ['brand'], null, null, prefixes).pipe(
      map(aggregations => {
        const brands: Array<string> = new Array<string>();
        for (const aggr of aggregations.brand.buckets) {
          brands.push(aggr.key);
        }
        brands.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        return brands;
      }),
      share()
    );
  }

  public getPhoneModelsForBrand(phoneBrand: string): Observable<Array<string>> {
    const prefixes = new Map<string, any>();
    prefixes.set('categoryId', this.PHONE_BRAND_CATEGORIES);
    const properties = new Map<string, any>();
    properties.set('brand', phoneBrand);
    return this.elasticSearchService
      .getBucketList(FilterService.PRODUCTS_INDEX, ['model'], null, properties, prefixes)
      .pipe(
        map(aggregations => {
          const models: Array<string> = new Array<string>();
          for (const aggr of aggregations.model.buckets) {
            models.push(aggr.key);
          }
          models.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
          return models;
        })
      );
  }

  public getProductCodesForBrandAndModel(phoneBrand: string, phoneModel?: string): Observable<Array<string>> {
    const prefixes = new Map<string, any>();
    prefixes.set('categoryId', this.PHONE_BRAND_CATEGORIES);
    const properties = new Map<string, any>();
    properties.set('brand', phoneBrand);
    if (phoneModel) {
      properties.set('model', phoneModel);
    }
    return this.elasticSearchService
      .getBucketList(FilterService.PRODUCTS_INDEX, ['id'], null, properties, prefixes)
      .pipe(
        map(aggregations => {
          const productCodes: Array<string> = new Array<string>();
          for (const aggr of aggregations.id.buckets) {
            productCodes.push(aggr.key);
          }
          return productCodes;
        })
      );
  }

  /**
   * Returns observable with array of key features
   * @param product THe product the fetch key features for
   */
  public getKeyFeatures(
    product: ProductDetailDto,
    metadata$: Observable<{} | ProductParamMetadataDto[]> = null
  ): Observable<Array<KeyFeature>> {
    if (metadata$ === null) {
      metadata$ = this.productService.getProductParameterMetadata(product.categoryId).pipe(share());
    }
    return metadata$.pipe(
      map((productParameterMetadata: ProductParamMetadataDto[]) => {
        const sections: Array<ParameterSection> = new Array<ParameterSection>();
        const allKeyFeatureParams = productParameterMetadata
          .filter(param => param.metadata['guiKeyFeaturePriority'])
          .sort(
            (param1, param2) =>
              parseInt(param1.metadata['guiKeyFeaturePriority']) - parseInt(param2.metadata['guiKeyFeaturePriority'])
          );

        const keyFeatureParamsMap: Map<string, KeyFeature> = new Map();
        allKeyFeatureParams.forEach(param => {
          const icon = param.metadata['guiKeyFeatureIconId'];
          let guiParam: KeyFeature = keyFeatureParamsMap.get(icon);
          const paramValue = !product.parametersStatic[param.name]
            ? null
            : this.productDetailAndComparisonCommonService.transformValueByType(
                product.parametersStatic[param.name],
                param.type,
                null
              );
          if (paramValue) {
            if (guiParam) {
              guiParam.value = `${guiParam.value}x${paramValue}`;
              guiParam.label = `${guiParam.label.replace(`(${param.metadata['unit']})`, '')}x ${
                param.label ? param.label.replace(`(${param.metadata['unit']})`, '') : param.name
              }`;
            } else {
              guiParam = this.getGuiParam(param, paramValue, guiParam, icon);
              if (guiParam) {
                keyFeatureParamsMap.set(icon, guiParam);
              }
            }
          }
        });
        return Array.from(keyFeatureParamsMap.values());
      })
    );
  }

  private getGuiParam(param: ProductParamMetadataDto, paramValue: string, guiParam: KeyFeature, icon: string) {
    if (param.type === 'BOOLEAN') {
      if (paramValue === 'true') {
        guiParam = {
          label: '',
          value: param.label ? param.label : param.name,
          unit: '',
          icon: icon.split('.')[0],
        };
      } else {
        guiParam = null;
      }
    } else {
      guiParam = {
        label: param.label ? param.label : param.name,
        value: paramValue,
        unit: param.metadata['unit'] ? ` ${param.metadata['unit']}` : '',
        icon: icon.split('.')[0],
      };
    }
    return guiParam;
  }

  public getParameterSections(
    product: ProductDetailDto,
    metadata$: Observable<{} | ProductParamMetadataDto[]> = null
  ): Observable<Array<ParameterSection>> {
    if (metadata$ === null) {
      metadata$ = this.productService.getProductParameterMetadata(product.categoryId).pipe(share());
    }
    if (!this.parameterListGroup$) {
      this.parameterListGroup$ = this.codebookService.getCodebooks('HW_PARAMETER_LIST_GROUP').pipe(share());
    }
    const sections$ = forkJoin(metadata$, this.parameterListGroup$).pipe(
      map(([productParameterMetadata, parameterListGroup]: [ProductParamMetadataDto[], CodebookDto[]]) => {
        const sections: Array<ParameterSection> = new Array<ParameterSection>();

        for (const codebook of parameterListGroup) {
          const filtered = productParameterMetadata
            .filter(param => param.metadata['guiParameterListGroup'] === codebook.code)
            .sort(
              (param1, param2) =>
                parseInt(param1.metadata['guiParameterListPriority']) -
                parseInt(param2.metadata['guiParameterListPriority'])
            )
            .map(param => {
              return {
                label: param.label ? param.label : param.name,
                value: !product.parametersStatic[param.name]
                  ? null
                  : this.productDetailAndComparisonCommonService.transformValueByType(
                      product.parametersStatic[param.name],
                      param.type,
                      param.metadata['unit']
                    ),
                type: param.type,
              };
            })
            .filter(param => !!param.value);
          const section: ParameterSection = {
            sectionName: this.codebookService.getCodebookText(codebook),
            sectionParameters: filtered,
          };
          sections.push(section);
        }
        return sections.filter(section => section.sectionParameters.length > 0);
      }),
      share()
    );
    return sections$;
  }

  public filterOutUnavailableProducts(products: Array<ProductDetailDto>): Observable<Array<ProductDetailDto>> {
    if (!products || !products.length) {
      return of(products);
    }
    const prodCodes = products.map(product => product.productCode);
    const properties = new Map<string, any>();
    properties.set('id', prodCodes);
    properties.set('stockAvailability', [
      AvailabilityEnum.stockAvailability.toString(),
      AvailabilityEnum.storeAvailability.toString(),
    ]);
    return this.elasticSearchService.getBucketList(FilterService.PRODUCTS_INDEX, ['id'], null, properties).pipe(
      map(aggregations => {
        const availableProductCodes = aggregations.id.buckets
          .filter(bucket => bucket.doc_count > 0)
          .map(bucket => bucket.key);
        return products.filter(product => availableProductCodes.includes(product.productCode));
      })
    );
  }

  public getProductsFromElasticByProductCodes(
    prodCodes: Array<string>,
    onlyAvailable: boolean = false
  ): Observable<Array<Product>> {
    const properties = new Map<string, any>();
    properties.set('colorVariants.id', prodCodes);
    if (onlyAvailable) {
      properties.set('stockAvailability', [
        AvailabilityEnum.stockAvailability.toString(),
        AvailabilityEnum.storeAvailability.toString(),
      ]);
    }
    return this.elasticSearchService.query(FilterService.PRODUCTS_INDEX, null, null, properties, null, 1, -1).pipe(
      map(result => {
        const products: Array<Product> = [];
        prodCodes.forEach(productCode => {
          const filteredProducts = result.hits.hits.filter(hitProduct => hitProduct._source.id === productCode);
          if (filteredProducts.length > 0) {
            products.push(filteredProducts[0]._source);
          }
        });

        return products;
      })
    );
  }
  /**
   * Formating the price includintg FractionDigits and Currency symbol
   */
  public formatPrice(value: number, currentLanguage: string, currency: string): string {
    if (!value) {
      value = 0;
    }
    const formattedPrice: string = new CurrencyPipe(currentLanguage).transform(
      value,
      currency,
      '',
      '1.2-2',
      currentLanguage
    );
    //removing last 3 characters from price
    const intNumber = formattedPrice.substring(0, formattedPrice.length - 3);
    //appending last 3 characters as fractionDigits
    const fractionDigits = formattedPrice.substring(formattedPrice.length - 3);
    //formating fraction digits
    const fractionDigitFormat = `${intNumber}<span class="fraction-digits">${fractionDigits}</span>`;

    const parameters = {
      currencySymbol: currency,
      price: fractionDigitFormat,
    };

    //metoda getTranslation
    let formattedPriceWithSymbol: string = this.translateService.instant('wc.common.currencyFormat', parameters);
    if (!formattedPriceWithSymbol) {
      //if currencyFormat in LT empty, default formatting used
      formattedPriceWithSymbol = new CurrencyPipe(currentLanguage).transform(
        value,
        currency,
        'symbol',
        '1.2-2',
        currentLanguage
      );
    }
    return formattedPriceWithSymbol;
  }

  /**
   * Get guiVisibility and not resource product parameter metadata by product categoryId
   */
  getVisibleProductParameterMetadata(categoryId): Observable<Array<ProductParamMetadataDto>> {
    return this.productService
      .getProductParameterMetadata(categoryId)
      .pipe(map((result: Array<ProductParamMetadataDto>) => {
        //ToDo add acl validation
        return result.filter(
          param =>
            !param.isStatic &&
            param.metadata['guiVisibility'] &&
            param.metadata['guiVisibility'] === 'true' &&
            (!param.metadata['isResource'] || param.metadata['isResource'] === 'false')
        );
      }));
  }
}

export interface KeyFeature {
  label: string;
  value: string;
  unit?: string;
  icon: string;
}

export interface ParameterSection {
  sectionName: string;
  sectionParameters: Array<{ label: string; value: string; type: string }>;
}
