<div *ngIf="shops" class="modal-body">
  <div class="select-modal">
    <div class="close" (click)="closePopup()">
      <img src="/assets/img/map-close.svg" />
    </div>
    <div class="content">
      <div class="ecare dashboard">
        <div class="ecare-header_container">
          <div class="ecare-header_headline">
            {{ 'wc.ecare.shops.headline' | translate }}
          </div>
        </div>

        <div class="ecare-numberOforders">
          {{ 'wc.ecare.orders.showing.label' | translate }} {{ shops.length }}
          {{ 'wc.ecare.shops.shops.label' | translate }}
        </div>
        <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
          <div class="tile -tile12">
            <div class="">
              <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
                <div class="-filter-fields -filter-fields-group">
                  <input id="002_shops_filter" type="text" formControlName="filter" class="filter-input-id" />
                  <app-button
                    variant="primary"
                    id="004_shops_filter"
                    iconPath="/assets/svg/search-icon.svg"
                    label="{{ 'wc.common.search.button' | translate }}"
                    (click)="searchByText()">
                  </app-button>
                </div>
              </form>
            </div>

            <div class="dashboard_search ecare-ordersSearch">
              <table *ngIf="shops.length > 0" class="ecare_listing-table orders-table table">
                <thead class="content-sm">
                  <tr>
                    <th>{{ 'wc.ecare.shop.table.name.header' | translate }}</th>
                    <th>{{ 'wc.ecare.shop.table.zip.header' | translate }}</th>
                    <th>{{ 'wc.ecare.shop.table.city.header' | translate }}</th>
                    <th>{{ 'wc.ecare.shop.table.street.header' | translate }}</th>
                    <th *ngIf="enrichAvailability">{{ 'wc.ecare.shop.table.amount.header' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <app-shop-selection-row
                    *ngFor="let shop of shops"
                    [shop]="shop"
                    [checkAvailability]="checkAvailability"
                    [enrichAvailability]="enrichAvailability"
                    [productCode]="productCode"
                    (shopSelected)="shopSelected($event)">
                  </app-shop-selection-row>
                </tbody>
              </table>

              <ng-container *ngIf="canDisplayNoCustomersText">
                <app-ecare-listing-no-results></app-ecare-listing-no-results>
              </ng-container>
            </div>
            <div>
              <app-ecare-pagination
                [page]="page"
                [pageSize]="pageSize"
                [totalCount]="total"
                [pagesToShow]="total / pageSize"
                (goPrev)="onPrevPage()"
                (goNext)="onNextPage()"
                (goPage)="onSpecificPage($event)"
                (pageSizeChanged)="onPageSizeChanged($event)">
              </app-ecare-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button class="btn wish-list-button wish-list-done-button" (click)="closePopup()">
        {{ 'wc.common.close' | translate }}
      </button>
    </div>
  </div>
</div>
