<!-- 001 Categories listing -->
<div
  *ngIf="productGroupsTree && productGroupsTree.length > 0"
  class="product__search-panelInside product__search-categories">
  <app-section-header
    *ngIf="currentProductGroup"
    textAlign="left"
    style="cursor: pointer"
    id="collapseHeader"
    size="small"
    title="{{ getGroupName(currentProductGroup.group) }}"
    [isCollapsed]="refEl.activeIds.length > 0"
    (click)="acc.toggle('toggleCategories')"></app-section-header>
  <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleCategories">
    <ngb-panel id="toggleCategories">
      <ng-template ngbPanelContent>
        <ng-container *ngFor="let productGroupNode of productGroupsTree; let i = index">
          <a
            class="product__categoriesList-title -mobile"
            id="{{ i }}_001_filter"
            [class.-active]="
              currentProductGroup &&
              (currentProductGroup.group.id === productGroupNode.group.id ||
                currentProductGroup.group.parentId === productGroupNode.group.id)
            "
            [routerLink]="'/eshop/product-listing/' + productGroupNode.group.seoUrl"
            >{{ getGroupName(productGroupNode.group) }}</a
          >
          <!-- 004 sub groups list-->
          <div
            *ngIf="subGroups$ && currentProductGroup && currentProductGroup.group.id === productGroupNode.group.id"
            class="product__categoriesList-subCategories">
            <ng-container *ngFor="let groupChild of subGroups$ | async; let j = index">
              <a
                id="{{ j }}_{{ i }}002_filter"
                [class.-active]="currentProductGroup.group.id === groupChild.group.id"
                [routerLink]="'/eshop/product-listing/' + groupChild.group.seoUrl"
                >{{ getGroupName(groupChild.group) }}</a
              >
            </ng-container>
          </div>
          <!-- /004 -->
        </ng-container>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<!-- /001 -->

<div *ngIf="enableTariffFilter" class="product__search-panelInside -white">
  <!-- 006 filter items list (aggregations) -->
  <div *ngIf="allFilterData$ | async as allFilterData" class="product__search-menu">
    <ng-container *ngFor="let filteringGroup of allFilterData; let lastFilteringGroup = last; let i = index">
      <ng-container
        *ngIf="
          filteringGroup.codebook && codebookService.getCodebookTexts(filteringGroup.codebook.codebookType)
            | async as codebook
        ">
        <app-section-header
          textAlign="left"
          style="cursor: pointer"
          id="collapseHeader"
          size="small"
          title="{{ codebook[filteringGroup.code] }}"
          [isCollapsed]="i < 2 && refEl.activeIds.length > 0"
          (click)="acc.toggle('toggleAddons-' + i)"></app-section-header>
      </ng-container>
      <ng-container *ngIf="filteringGroup.translateKey">
        <app-section-header
          textAlign="left"
          style="cursor: pointer"
          id="collapseHeader"
          size="small"
          title="{{ filteringGroup.translateKey | translate }}"
          [isCollapsed]="i < 2 && refEl.activeIds.length > 0"
          (click)="acc.toggle('toggleAddons-' + i)"></app-section-header>
      </ng-container>
      <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleAddons-0, toggleAddons-1">
        <ngb-panel id="toggleAddons-{{ i }}">
          <ng-template ngbPanelContent>
            <div #filterGroupWrapper>
              <!-- <ul class="product__search-items"> -->
              <div
                *ngFor="let generalFilter of filteringGroup.filters; index as index"
                #checkboxDiv
                class="product__search-item checkbox"
                [class.disabled]="
                  getProductsAggregationsNumber(filteringGroup.code, generalFilter.code) === '0' && !hwCheck.checked
                ">
                <input
                  #hwCheck
                  type="checkbox"
                  [id]="'hw-' + filteringGroup.code + '-' + (index + 1)"
                  [disabled]="
                    getProductsAggregationsNumber(filteringGroup.code, generalFilter.code) === '0' && !hwCheck.checked
                  "
                  [checked]="isChecked(filteringGroup.code, generalFilter.code)"
                  (click)="checkFilter(filteringGroup, generalFilter, hwCheck)" />

                <label [for]="'hw-' + filteringGroup.code + '-' + (index + 1)">
                  <ng-container
                    *ngIf="
                      generalFilter.codebook &&
                        codebookService.getCodebookTexts(generalFilter.codebook.codebookType) | async as codebook
                    ">
                    {{ codebook[generalFilter.code] }}
                  </ng-container>
                  <ng-container *ngIf="generalFilter.translateKey || generalFilter.name">
                    {{ generalFilter.translateKey ? (generalFilter.translateKey | translate) : generalFilter.name }}
                  </ng-container>
                  <div class="filter-number">
                    ({{ getProductsAggregationsNumber(filteringGroup.code, generalFilter.code) }})
                  </div>
                </label>
              </div>
              <!-- </ul> -->
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
  </div>
  <!-- /006 -->
  <!-- 007 filter buttons: search, reset -->
  <a id="006_filter" href="javascript:void(0)" class="resetFilters" (click)="cancelAllCheckFilters()">{{
    'wc.shopping.gui_productListing.button.deleteAllFilters' | translate
  }}</a>
  <!-- /007 -->
</div>
