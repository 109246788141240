<app-ecare-page-layout
  class="ecare-financialDocuments"
  columnTemplate="1fr"
  title="{{ 'wc.ecare.financialDocuments.headline' | translate }}">
  <div>
    <div *blockUI="blockUIElement.name; template: blockTemplate">
      <div *ngIf="paymentParams && paymentParams['orderId']">
        <app-payment-response [orderDto]="orderDto" [paymentParams]="paymentParams"></app-payment-response>
      </div>
      <div>
        <app-financial-document-filter
          [filtering]="search.filtering"
          (filterChanged)="handleFilterChange()">
        </app-financial-document-filter>
        <ng-container *ngIf="financialDocuments.length > 0">
          <table class="ecare_listing-table">
            <thead>
              <tr>
                <th>
                  {{ 'wc.ecare.financialDocument.id.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.category.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.billingPeriod.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.issueDate.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.state.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.amountDue.header' | translate }}
                </th>
                <th>
                  {{ 'wc.ecare.financialDocument.remainingAmount.header' | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let financialDocument of financialDocuments" class="ecare_listing-table-row">
                <td class="font-highlight">
                  <span>{{ financialDocument.id }}</span>
                </td>
                <td class="font-grayed">
                  <span>{{ financialDocument.category }}</span>
                </td>
                <td>
                  <span *ngIf="financialDocument.billingPeriod"
                    >{{ financialDocument.billingPeriod.startDateTime | date }}
                    -
                    {{ financialDocument.billingPeriod.endDateTime | date }}</span
                  >
                </td>
                <td class="font-highlight">
                  <div
                    class="ecare-finDocsTable-issue-date"
                    ngbTooltip="{{ financialDocument.billDate | date : 'dd.MM.yyyy' }}">
                    {{ financialDocument.billDate | date }}
                  </div>
                </td>
                <td>
                  <app-sticker [variant]="getStickerType(financialDocument.state)">{{
                    financialDocument.state
                  }}</app-sticker>
                </td>
                <td>
                  <one-time-price
                    *ngIf="financialDocument.amountDue"
                    [price]="financialDocument.amountDue.value"></one-time-price>
                </td>
                <td>
                  <one-time-price
                    *ngIf="existsRemainingAmount(financialDocument)"
                    [price]="financialDocument.remainingAmount.value"></one-time-price>
                </td>
                <td>
                  <app-button
                    *ngIf="existsRemainingAmount(financialDocument)"
                    id="001_ecare_financial_documents"
                    variant="primary"
                    size="fullWidth"
                    iconPathAfter="/assets/svg/arrow-dropright-icon.svg"
                    label="{{ 'wc.ecare.invoices.pay' | translate }}"
                    (click)="onPay(financialDocument)">
                  </app-button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-mobile">
            <div *ngFor="let financialDocument of financialDocuments" class="row-wrapper mobile-table-row">
              <div class="row-top">
                <div class="left-part">
                  <div class="id-part">
                    <div class="order-id-wrapper">
                      <div>{{ financialDocument.id }}</div>
                    </div>
                    <app-sticker [variant]="getStickerType(financialDocument.state)">{{
                      financialDocument.state
                    }}</app-sticker>
                  </div>
                  <div *ngIf="financialDocument.billingPeriod">
                    {{ financialDocument.billingPeriod.startDateTime | date }} -
                    {{ financialDocument.billingPeriod.endDateTime | date }}
                  </div>
                </div>
                <div class="right-part">
                  <one-time-price
                    *ngIf="financialDocument.amountDue"
                    [price]="financialDocument.amountDue.amount"></one-time-price>
                </div>
              </div>
              <div class="row-bottom">
                <div class="left-part">
                  {{ 'wc.ecare.financialDocument.issueDate.header' | translate }}
                  {{ financialDocument.billDate | date }}
                </div>
                <div *ngIf="existsRemainingAmount(financialDocument)" class="right-part">
                  {{ 'wc.ecare.financialDocument.remainingAmount.header' | translate }}
                  <one-time-price [price]="financialDocument.remainingAmount.value"></one-time-price>
                  <app-button
                    *ngIf="existsRemainingAmount(financialDocument)"
                    id="001_ecare_financial_documents"
                    variant="primary"
                    size="fullWidth"
                    iconPathAfter="/assets/svg/arrow-dropright-icon.svg"
                    label="{{ 'wc.ecare.invoices.pay' | translate }}"
                    (click)="onPay(financialDocument)">
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="moreRecordsExists">
          {{ 'wc.ecare.financialDocuments.scrollDownForMoreRecords.text' | translate }}
        </div>
        <div *ngIf="canDisplayNoFinancialDocumentsText" class="no-invoices-text">
          {{ 'wc.ecare.financialDocuments.noFinancialDocuments.text' | translate }}
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
