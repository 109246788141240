import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AbstractPageComponent,
  AccountEntityRoleService,
  AppBlockerService,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountEntityRoleDto,
  BulkDeleteDto,
  BulkOperationsRequestDto,
  PagedAccountEntityRolesDto
} from '@btl/order-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DelegateModalComponent } from './delegate-modal/delegate-modal.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-ecare-account-entity',
  templateUrl: './ecare-account-entity.component.html',
  styleUrls: ['./ecare-account-entity.component.css'],
})
@EnableDynamicLoading({ customName: EcareAccountEntityComponent.PAGE_ID })
export class EcareAccountEntityComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareAccountEntityComponent';

  pageId(): string {
    return EcareAccountEntityComponent.PAGE_ID;
  }

  entityRoles: AccountEntityRoleDto[];

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  moreRecordsExists = false;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'modified', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };
  pagedDto: PagedAccountEntityRolesDto;

  cuRefNo = null;
  caRefNo = null;
  queryParams = null;

  selectForm: FormGroup = this.formBuilder.group({
    selected: new FormArray([]),
  });

  constructor(
    private readonly appBlockerService: AppBlockerService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    private accountEntityRoleService: AccountEntityRoleService,
    private formBuilder: FormBuilder,
    private ngModal: NgbModal,
    private stickyMessageService: StickyMessageService
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;

      // if (this.cuRefNo || this.caRefNo) {
      //   this.constantFilter = [{
      //     column: 'entities',
      //     compareType: CompareTypeDtoEnum.EQUAL,
      //     value: [
      //       {
      //         entityType: 'com.emeldi.ecc.be.crm.dto.Party',
      //         entityId: this.caRefNo ? this.caRefNo : this.cuRefNo,
      //       },
      //     ],
      //   }];
      // } else {
      //   this.constantFilter = [];
      // }
    });
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadAccountEntityRolesBySearch(this.search);
  }

  new() {
    const urlTree = this.router.createUrlTree(['newAccountEntity'], { relativeTo: this.route });
    this.router.navigateByUrl(urlTree);
  }

  private loadAccountEntityRolesBySearch(search: Search) {
    this.appBlockerService.block();
    this.accountEntityRoleService
      .filterAccountEntityRoles(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.pagedDto = result;
          this.entityRoles = result.data;
          this.selectedFormArray.clear();
          this.entityRoles.forEach(() => this.selectedFormArray.push(new FormControl()));
          if (result.data.length > 0 && result.data.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  edit(entityRole: AccountEntityRoleDto) {
    const urlTree = this.router.createUrlTree([entityRole.id], { relativeTo: this.route });
    this.router.navigateByUrl(urlTree);
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadAccountEntityRolesBySearch(this.search);
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadAccountEntityRolesBySearch(this.search);
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadAccountEntityRolesBySearch(this.search);
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadAccountEntityRolesBySearch(this.search);
  }

  delete(entityRole: AccountEntityRoleDto) {
    this.accountEntityRoleService
      .deleteAccountEntityRole(entityRole.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.accountEntity.accountEntityRoleDeleted');
        this.loadAccountEntityRolesBySearch(this.search);
      });
  }

  get selectedFormArray() {
    return this.selectForm.get('selected') as FormArray;
  }

  getSelectedEntityRoles() {
    const selected = [];
    for (let i = 0; i < this.selectedFormArray.length; i++) {
      if (this.selectedFormArray.getRawValue()[i]) {
        selected.push(this.entityRoles[i]);
      }
    }
    return selected;
  }

  deleteEntityRoles() {
    const bulkDelete: BulkDeleteDto[] = [];

    this.getSelectedEntityRoles().forEach(entityRole =>
      bulkDelete.push({
        id: entityRole.id,
      })
    );

    const bulkRequest: BulkOperationsRequestDto = {
      bulkDeleteList: bulkDelete,
    };
    if (bulkDelete.length > 0) {
      const dialogReference = this.ngModal.open(ConfirmationDialogComponent, {
        windowClass: 'dialog dialog-confirmation',
      });

      const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
      confirmationDialogComponent.heading = 'wc.ecare.accountEntity.delete';
      confirmationDialogComponent.texts.push('wc.ecare.accountEntity.delete.confirm');
      confirmationDialogComponent.dialogReference = dialogReference;
      confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
        dialogReference.dismiss();

        this.accountEntityRoleService
          .bulkOperationsAccountEntityRoles(bulkRequest)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.loadAccountEntityRolesBySearch(this.search);
            this.stickyMessageService.addStickySuccessMessage('wc.ecare.accountEntityRole.delete.success');
          });
      };
    }
  }

  delegate(accountEntity: AccountEntityRoleDto) {
    const modalRef = this.ngModal.open(DelegateModalComponent);
    const delegateModalComponent = <DelegateModalComponent>modalRef.componentInstance;
    delegateModalComponent.dialogRef = modalRef;
    delegateModalComponent.selectedAccountEntityRoleDto = accountEntity;
    delegateModalComponent.grantMode = false;
  }
}
