import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticsearchService } from '@btl/btl-fe-wc-common';
import { AddressHolder } from '../models/address-holder';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  testLocation = [
    {
      locRefNo: '15',
      name: 'test1',
      block: 'test1',
      blockNo: 'test1',
      site: 'test1',
      adminCentre: 'test1',
      entrance: 'test1',
      floor: 'test1',
      apartment: 'test1',
      doorTitle: 'test1',
      extId: 'test',
      municipality: 'test',
      longitude: 50,
      adrRefNo: 'test',
      latitude: 60,
      note: 'test',
    },
    {
      locRefNo: '30',
      name: 'test',
      block: 'test',
      blockNo: 'test',
      site: 'test',
      adminCentre: 'test',
      entrance: 'test',
      floor: 'test',
      apartment: 'test',
      doorTitle: 'test',
      extId: 'test',
      municipality: 'test',
      longitude: 500,
      adrRefNo: 'test',
      latitude: 600,
      note: 'test',
    },
  ];

  constructor(private http: HttpClient, private elasticSearchService: ElasticsearchService) {}

  /**
   * Search elasticsearch for products by given text
   * @param term Text to search by
   */
  public searchByText(term: string, maxHits: number): Observable<AddressHolder[]> {
    return this.elasticSearchService
      .query(ElasticsearchService.ADDRESS_INDEX, null, term, null, null, 1, maxHits)
      .pipe(map(oph => oph.hits.hits));
  }

  // remove when done
  public getLocation(locRefNo) {
    return this.testLocation.find(t => t.locRefNo === locRefNo);
  }
}
