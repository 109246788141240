<div class="customer_account_card">
  <div class="customer_account_card-header">
    <div class="badge-container">
      <div class="badge">{{ customerAccount.id }}</div>
      <div>
        <ng-container *ngIf="customerAccount.billingContact">
          <i
            *ngIf="
              customerAccount.billingContact.preferredChannel === 'EMAIL' ||
              customerAccount.billingContact.preferredChannel === 'ALL'
            "
            [inlineSVG]="'assets/svg/caParameter-mail.svg'"
            ngbTooltip="{{ customerAccount.billingContact.email }}"></i>
          <i
            *ngIf="
              customerAccount.billingContact.preferredChannel === 'SMS' ||
              customerAccount.billingContact.preferredChannel === 'ALL'
            "
            [inlineSVG]="'assets/svg/caParameter-phone.svg'"
            ngbTooltip="{{ customerAccount.billingContact.phone1 | phoneNumber }}"></i>
        </ng-container>
        <i
          *ngIf="hasSubscribtion"
          [inlineSVG]="'assets/svg/caParameter-subscription.svg'"
          ngbTooltip="{{ 'wc.ecare.dashboard.customerAccount.subscription' | translate }}"></i>
      </div>
    </div>
    <h3 class="customer_account_label">
      {{
        customerAccount.displayName
          ? customerAccount.displayName
          : ('wc.ecare.dashboard.customerAccount.label' | translate)
      }}
    </h3>
    <div class="child-container">
      <ng-container *ngFor="let child of customerAccount.childParties.slice(0, 3)">
        <button
          *ngIf="child.displayName !== undefined"
          class="badge badge-child"
          (click)="loadSelectedCustomerAccount(child, $event)">
          <span>{{ child.displayName }}</span>
        </button>
      </ng-container>
      <div *ngIf="customerAccount.childParties && customerAccount.childParties.length > 3" class="child-count">
        +{{ customerAccount.childParties.length - 3 }}
      </div>
    </div>
  </div>
  <div
    *ngIf="customerAccount.parameters['paymentResponsible']"
    class="customer_account_card-footer"
    openDelay="300"
    ngbTooltip="{{ 'wc.ecare.dashboard.financialDocument.button.label' | translate }}"
    (click)="goToFinancialDocuments()">
    <div>
      <i [inlineSVG]="'assets/svg/actualBalance.svg'"></i>
      <one-time-price *ngIf="openBalanceResult" [price]="openBalanceResult.openAmount"> </one-time-price>
    </div>
    <div>
      <i [inlineSVG]="'assets/svg/overdueAmount.svg'"></i>
      <one-time-price *ngIf="openBalanceResult" [price]="openBalanceResult.dueAmount"> </one-time-price>
    </div>
  </div>
</div>
