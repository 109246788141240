<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.shopping.gui_wishlist.shareWishList.shareViber.title' | translate }}
  </h4>
  <div class="modal-close" (click)="cancel()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  {{ 'wc.shopping.gui_wishlist.shareWishList.shareViber.body' | translate }}
  <app-button
    class="viber-download-button"
    variant="whiteWithBorder"
    label="{{ 'wc.shopping.gui_wishlist.shareWishList.shareViber.button' | translate }}"
    link="https://www.viber.com/en/download/"
    [buttonNewTab]="true"
    iconPath="/assets/svg/viber_icon.svg"
  ></app-button>
</div>

<div class="modal-footer">
  <app-button
    variant="link"
    label="{{ 'wc.common.cancel.button' | translate }}"
    (click)="cancel()"
  ></app-button>
  <app-button
    variant="primary"
    label="{{ 'wc.shopping.gui_wishlist.shareWishList.shareViber.confirm' | translate }}"
    [link]="'viber://forward?text=' + url | safeUrl"
  ></app-button>
</div>
