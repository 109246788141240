import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'displayAddress',
  pure: false
})
export class DisplayAddressPipe implements PipeTransform, OnDestroy {
  private lastValue;

  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  constructor(
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {
  }

  transform(address, type?: addressTypes): string {
    if (this.lastValue) {
      return this.lastValue;
    }
    this.lastValue = '';

    if (address) {
      if (this.codebookService && address.city) {
        this.currentLocaleService.currentLocaleChange
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.lastValue = null;
            this.transform(address, type);
          });

        forkJoin([this.codebookService.getCodebooks('CITY'), this.codebookService.getCodebooks('COUNTRY')])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(([cityCb, countryCb]) => {
            address['cityName'] = address.city;
            address['countryName'] = address.country;
            let codebookAddressCity = cityCb
              .find(cd => cd.code === address.city)
              ?.localizedTexts?.find(text => text.locale === this.currentLocaleService.getCurrentLanguage())?.text;
            if (codebookAddressCity) {
              address.cityName = codebookAddressCity;
            }
            let codebookAddressCountry = countryCb
              .find(cd => cd.code === address.country)
              ?.localizedTexts?.find(text => text.locale === this.currentLocaleService.getCurrentLanguage())?.text;
            if (codebookAddressCountry) {
              address.countryName = codebookAddressCountry;
            }
            let value = this.getAddressByType(address, type);
            if (value != this.lastValue) {
              this.lastValue = value;
              this.transform(null);
            }
          });
      }
      this.lastValue = this.getAddressByType(address, type);
    }

    return this.lastValue;
  }

  private getAddressByType(address, type?: addressTypes): string {
    const emptyField = '';
    const comma = ', ';
    const slash = '/';

    if (type === addressTypes.PICK_UP_POINT) {
      return `${address.adrStreet ? address.adrStreet : emptyField} ${
        address.adrStreetNo ? address.adrStreetNo + comma : emptyField
      }${address.adrCity ? address.adrCity + comma : emptyField}${
        address.adrCityPart ? address.adrCityPart + comma : emptyField
      }${address.adrZip ? address.adrZip : emptyField}`;
    }
    if (type === addressTypes.TECHNOLOGY_CHECK || type === addressTypes.DELIVERY || !addressTypes || !type) {
      return `${address.street ? address.street : emptyField} ${
        address.streetNo ? address.streetNo + slash : emptyField
      }${address.buildingNo ? address.buildingNo + comma : emptyField}${
        address.cityName ? address.cityName + comma : emptyField
      }${address.zipCode ? address.zipCode + comma : emptyField}${
        address.countryName ? address.countryName : emptyField
      }`;
    }
    if (type === addressTypes.SUMMARY_PAGE) {
      return `${address.cityName ? address.cityName + comma : emptyField}${address.street ? address.street : emptyField} ${
        address.streetNumber ? address.streetNumber + comma : emptyField
      } ${
        address.countryName
          ? address.countryName + comma
          : emptyField
      }${address.zipCode ? address.zipCode : emptyField}`;
    }
    if (type === addressTypes.DASHBOARD_CA_DETAIL) {
      return `${address.street ? address.street : emptyField} ${
        address.streetNumber ? address.streetNumber + comma : emptyField
      }${address.district ? address.district + comma : emptyField}${
        address.cityName ? address.cityName + comma : emptyField
      }${address.zipCode ? address.zipCode + comma : emptyField}${
        address.countryName ? address.countryName : emptyField
      }`;
    }
  }
}

export enum addressTypes {
  TECHNOLOGY_CHECK = 'TechCheck',
  SUMMARY_PAGE = 'SumPage',
  DELIVERY = 'Delivery',
  DASHBOARD_CA_DETAIL = 'DashboardCustomerAccountDetail',
  PICK_UP_POINT = 'PickUpPoint',
}
