<div class="modal-header">
  <h4 class="modal-title">{{ 'wc.shopping.gui_wishlist.shareWishList.heading' | translate }} {{ wishList.name }}</h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="wish-list-popup share-wish-list-popup" [class.has-error]="shareWishListForm && formSubmitted">
    <div class="wish-list-content">
      <div class="wish-list-social-media-wrapper">
        <div class="wish-list-social-media-icon">
          <a class="facebook"></a>
          {{ 'wc.shopping.gui_wishlist.shareWishList.facebook.title' | translate }}
        </div>
        <div class="wish-list-social-media-icon">
          <a class="twitter"></a>
          {{ 'wc.shopping.gui_wishlist.shareWishList.twitter.title' | translate }}
        </div>
        <div class="wish-list-social-media-icon">
          <a class="reddit"></a>
          {{ 'wc.shopping.gui_wishlist.shareWishList.reddit.title' | translate }}
        </div>
        <div class="wish-list-social-media-icon">
          <a class="email"></a>
          {{ 'wc.shopping.gui_wishlist.shareWishList.email.title' | translate }}
        </div>
      </div>

      <form #shareWishListForm="ngForm" (ngSubmit)="shareWishList(shareWishListForm)">
        <div class="wish-list-input-part">
          <label for="sender">{{ 'wc.shopping.gui_wishlist.shareWishList.senderEmail.label' | translate }}</label>
          <input
            #senderInput="ngModel"
            class="wish-list-add-input"
            type="text"
            id="sender"
            required
            [name]="SENDER_FIELD"
            [pattern]="emailPattern"
            [class.is-invalid]="senderInput.errors && formSubmitted"
            [(ngModel)]="userEmail" />
          <div *ngIf="senderInput.errors && formSubmitted" class="alert alert-danger">
            {{ 'wc.shopping.gui_wishlist.shareWishList.validation.sender' | translate }}
          </div>
        </div>
        <div class="wish-list-input-part">
          <label for="receiver">{{ 'wc.shopping.gui_wishlist.shareWishList.receiverEmail.label' | translate }}</label>
          <input
            #receiverInput="ngModel"
            class="wish-list-add-input"
            type="text"
            id="receiver"
            ngModel
            required
            [name]="RECEIVER_FIELD"
            [pattern]="emailPattern"
            [class.is-invalid]="receiverInput.errors && formSubmitted" />
          <div *ngIf="receiverInput.errors && formSubmitted" class="alert alert-danger">
            {{ 'wc.shopping.gui_wishlist.shareWishList.validation.receiver' | translate }}
          </div>
        </div>
        <div class="wish-list-input-part">
          <label for="receiver-message">{{
            'wc.shopping.gui_wishlist.shareWishList.messageText.label' | translate
          }}</label>
          <textarea
            #receiverMessageInput="ngModel"
            class="wish-list-add-input"
            rows="3"
            id="receiver-message"
            ngModel
            required
            [name]="RECEIVER_MESSAGE_FIELD"
            [class.is-invalid]="receiverMessageInput.errors && formSubmitted"></textarea>
          <div *ngIf="receiverMessageInput.errors && formSubmitted" class="alert alert-danger">
            {{ 'wc.shopping.gui_wishlist.shareWishList.validation.message' | translate }}
          </div>
        </div>
        <div class="wish-list-form-row">
          <label for="can-manage" class="wish-list-item-container">
            {{ 'wc.shopping.gui_wishlist.shareWishList.canManageList.label' | translate }}
            <input
              #canManageCheckbox="ngModel"
              type="checkbox"
              id="can-manage"
              ngModel
              [checked]="wishList.isManageable"
              [name]="CAN_MANAGE_CHECKBOX" />
            <span class="wish-list-checkmark"></span>
          </label>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-button
    variant="link"
    label="{{ 'wc.shopping.gui_wishlist.shareWishList.shareList.cancel' | translate }}"
    (click)="closePopup()"></app-button>
  <app-button
    variant="primary"
    form="ngForm"
    label="{{ 'wc.shopping.gui_wishlist.shareWishList.shareList.button' | translate }}"
    (click)="shareWishListForm.ngSubmit.emit()"></app-button>
</div>
