import { AbstractService, CurrentLocaleService, OrderingService } from '@btl/btl-fe-wc-common';
import { OrderDto, OrderPaymentFrontendService, PaymentDto } from '@btl/order-bff';
import { NGXLogger } from 'ngx-logger';
import { OrderErrorHandler } from './errors/order-error-handler';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrderUtils } from '../helpers/order-utils';
import { catchError, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ReservationErrorModalComponent
} from '../components/page/payment-response/reservation-error-modal/reservation-error-modal.component';

@Injectable()
export class PaymentService extends AbstractService {
  constructor(
    private currentLocaleService: CurrentLocaleService,
    private bffPaymentService: OrderPaymentFrontendService,
    private orderingService: OrderingService,
    public ngbModal: NgbModal,
    protected logger: NGXLogger,
    protected errorHandler: OrderErrorHandler
  ) {
    super(errorHandler);
  }

  //region Operations:

  /**
   * Prepare payment for the order identified by the given ID and get the result.
   *
   * @param orderId An ID of the order to pay.
   * @param successCallbackUrl The success URL callback (query parameter for the redirect URL).
   * @param errorCallbackUrl The error URL callback (query parameter for the redirect URL).
   */
  payOrder(orderId: string, successCallbackUrl: string, errorCallbackUrl: string): Observable<PaymentDto> {
    if (!orderId) {
      throw new Error('Order ID must be passed.');
    }
    if (!successCallbackUrl) {
      throw new Error('Success URL callback must be passed.');
    }
    if (!errorCallbackUrl) {
      throw new Error('Error URL callback must be passed.');
    }

    const locale = this.currentLocaleService.getCurrentLanguage();

    return this.bffPaymentService.payOrder(orderId, locale, successCallbackUrl, errorCallbackUrl, this.orderingService.getOrderAuthCode());
  }

  updateOrderPaymentStatus(orderId: string, status: string): Observable<OrderDto> {
    if (!orderId) {
      throw new Error('Order ID must be passed.');
    }
    if (!status) {
      throw new Error('Status must be passed.');
    }
    const errorMessage = `Update order payment status by orderRefNo '${orderId}' failed.`;
    return this.bffPaymentService.updateOrderPaymentStatus(orderId, status, this.orderingService.getOrderAuthCode())
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  private postPaymentGateway(paymentGatewayUrl: string, paymentGatewayPostParameters) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = paymentGatewayUrl;
    form.style.display = 'none';

    Object.keys(paymentGatewayPostParameters).forEach(key => {
      const input = document.createElement('input');
      input.value = paymentGatewayPostParameters[key];
      input.name = key;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  }

  public gotToPaymentGatewayUrl(orderDto) {
    const paymentGatewayUrl = OrderUtils.getOrderAttributeValue(orderDto, 'paymentGatewayUrl');
    const paymentGatewayPostParameters = OrderUtils.getOrderAttributeValue(orderDto, 'paymentParamGwPostParams');
    if (paymentGatewayPostParameters) {
      this.postPaymentGateway(paymentGatewayUrl, JSON.parse(paymentGatewayPostParameters));
    } else {

      window.location.href = paymentGatewayUrl;
    }
  }

  /**
   * Handles order reprocess: it does reservation of order items requiring to be paid needed before reprocessing of order payment (or prolongation of existing reservation)
   *
   * @param id Order id to be processed, in further operations references as well as orderRefNo
   * @return Order with updated order items.
   */
  public reprocessOrderPayment(orderDto: OrderDto): Observable<OrderDto> {
    const errorMessage = `Reprocess order by orderRefNo '${orderDto.id}' failed.`;
    return this.bffPaymentService.updateOrderPaymentStatus(orderDto.id, 'REQUIRED', this.orderingService.getOrderAuthCode())
      .pipe(switchMap(order => this.createOrderPayment(order.id)))
      .pipe(switchMap(order => this.bffPaymentService.reprocessOrderPayment(order.id)))
      .pipe(catchError(this.reservationErrorHandling))
      .pipe(catchError(this.handleError(errorMessage, null)))
      .pipe(map(result => {
        if (result) {
          this.gotToPaymentGatewayUrl(result);
          return result;
        }
      }));
  }

  public reservationErrorHandling = (err) => {
    let reserveFailure = err?.error?.failures?.find(failure => failure?.contextData['errorCode'] === 'err.shopping.handleResources.reserveResource002');
    if (reserveFailure) {
      const modalRef = this.ngbModal.open(ReservationErrorModalComponent, {
        size: 'sm',
        windowClass: 'dialog',
      });
      const addRecipientModalComponent = <ReservationErrorModalComponent>modalRef.componentInstance;
      addRecipientModalComponent.dialogRef = modalRef;
      addRecipientModalComponent.failure = reserveFailure;
      return of(null)
    } else {
      return throwError(err);
    }
  }

  /**
   * Creates Payment for Order
   * @param id ID of the order to be payed.
   */
  public createOrderPayment(id: string): Observable<OrderDto> {
    return this.bffPaymentService.createOrderPayment(id, this.orderingService.getOrderAuthCode()).pipe(catchError(this.handleError('', null)));
  }
}
