import { Component, OnInit } from '@angular/core';
import {
  AuthFactoryService,
  AuthService,
  ContentService,
  CookiePolicyService,
  CurrentLocaleService,
  ErrorService,
  GroupTreeNode,
  PageModule,
  PageModuleService,
  ProductGroupService,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { ContentDto, CookieAgreementDto } from '@btl/order-bff';
import { ModalFactoryService } from '@service/modal-factory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StickyBarService } from '@service/sticky-bar.service';
import { NavigationMenuService, UserMenuItems } from '@service/navigation-menu.service';

export interface FooterLink {
  title: string;
  targetUrl: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  private static readonly FOOTER_LINKS_MAX_COUNT_PER_COLUMN = 4;

  userNavigationMenuItems: FooterLink[];
  eshopMenuItems: FooterLink[];
  footerContents: Array<ContentDto>;
  availableCookieAgreements: CookieAgreementDto[] | null;

  private _footerOffsetBottom = 0;
  private authService: AuthService;

  constructor(
    private contentService: ContentService,
    private currentLocaleService: CurrentLocaleService,
    private modalFactory: ModalFactoryService,
    private cookiePolicyService: CookiePolicyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stickyBarService: StickyBarService,
    private navigationMenuService: NavigationMenuService,
    private authFactoryService: AuthFactoryService,
    private pageModuleService: PageModuleService,
    private productGroupService: ProductGroupService,
    private errorService: ErrorService,
  ) {
    this.authService = this.authFactoryService.getAuthService();
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push(
      {
        column: 'contentTemplateType',
        compareType: 'EQUAL',
        value: 'BLANK_PAGE',
      },
      {
        column: 'parameters',
        compareType: null,
        value: [{ name: 'type', value: 'footerPage' }],
      },
    );
    if (!this.errorService.footerContentsError) {
      contentService.filterContents(search).subscribe(
        result => {
          this.footerContents = result.data;
        },
        error => (this.errorService.footerContentsError = true),
      );
    }
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        this.navigationMenuService
          .getUserMenuItems()
          .subscribe(menuItems => (this.userNavigationMenuItems = this.getFooterUserNavigationLinks(menuItems)));
      }
    });

    this.eshopMenuItems = this.getFooterEshopLinks();
    this.navigationMenuService.eshopMenuItemsChanged.subscribe(
      () => (this.eshopMenuItems = this.getFooterEshopLinks())
    );

    const moduleId = PageModuleService.getActiveModuleId(this.activatedRoute);
    if (moduleId) {
      this.cookiePolicyService
        .getCookieAgreements(moduleId)
        .subscribe(agreements => (this.availableCookieAgreements = agreements));
    }

    this._footerOffsetBottom = this.stickyBarService.getStickyBarMaxHeight();
    this.stickyBarService.stickyBarMaxHeightChanged.subscribe(
      () => (this._footerOffsetBottom = this.stickyBarService.getStickyBarMaxHeight())
    );
  }

  get footerOffsetBottom(): number {
    return this._footerOffsetBottom;
  }

  getContentSeoUrl(content: ContentDto) {
    return content.contentMaster.keys.find(key => key.keyId === 'seoUrl')?.keyValue;
  }

  getContentHrefText(content: ContentDto) {
    return content.contentMaster.localizedParameters.find(param => param.name === 'hrefText')?.localizedValues[
      this.currentLocaleService.getCurrentLanguage()
    ];
  }

  showCookieBar(): boolean {
    const moduleId = PageModuleService.getActiveModuleId(this.activatedRoute);
    return (
      moduleId && this.availableCookieAgreements?.length && !this.cookiePolicyService.cookiePolicyCookieExists(moduleId)
    );
  }

  openCookiePolicySettings() {
    const moduleId = PageModuleService.getActiveModuleId(this.activatedRoute);
    this.modalFactory.cookiePolicySettingsModal(this.availableCookieAgreements, moduleId);
  }

  openSocialLink(url: string) {
    window.open(url, '_blank');
  }

  navigateToLink(url: string) {
    this.router.navigate([url]);
  }

  private getFooterUserNavigationLinks(userNavigationMenuItems: UserMenuItems): FooterLink[] {
    if (!userNavigationMenuItems) {
      return [];
    }

    const menuItems: PageModule[] = this.authService.account?.external
      ? [
          ...userNavigationMenuItems.externalUserMenuItems,
          ...userNavigationMenuItems.externalUserMenuMiddleItems,
          ...userNavigationMenuItems.externalUserMenuBottomItems,
        ]
      : [
          ...userNavigationMenuItems.internalUserMenuItems,
          ...userNavigationMenuItems.internalUserMenuMiddleItems,
          ...userNavigationMenuItems.internalUserMenuBottomItems,
        ];
    return menuItems
      .map((pageModule: PageModule): FooterLink => {
        return {
          title: this.pageModuleService.getModuleText(pageModule.code) as string,
          targetUrl: `${pageModule.parentUrlPattern}${pageModule.urlPattern}`,
        };
      })
      .slice(0, FooterComponent.FOOTER_LINKS_MAX_COUNT_PER_COLUMN);
  }

  private getFooterEshopLinks(): FooterLink[] {
    const eshopMenuItems: GroupTreeNode[] = this.navigationMenuService.getEshopMenuItems();
    if (!eshopMenuItems?.length) {
      return [];
    }

    const eshopLinks: FooterLink[] = [];
    eshopMenuItems?.forEach((groupTreeNode: GroupTreeNode) => {
      if (groupTreeNode.children?.length) {
        const footerLinks = groupTreeNode.children.map((groupTreeNode: GroupTreeNode): FooterLink => {
          return {
            title: this.productGroupService.getGroupName(groupTreeNode.group),
            targetUrl: `/eshop/product-listing/${groupTreeNode.group.seoUrl}`,
          };
        });
        eshopLinks.push(...footerLinks);
      } else {
        eshopLinks.push({
          title: this.productGroupService.getGroupName(groupTreeNode.group),
          targetUrl: this.navigationMenuService.getProductGroupNavigationUrl(groupTreeNode),
        });
      }
    });
    return eshopLinks.slice(0, FooterComponent.FOOTER_LINKS_MAX_COUNT_PER_COLUMN);
  }
}
