<div class="modal-body">
  <div class="select-modal">
    <div class="close" (click)="closePopup()">
      <img src="/assets/img/map-close.svg" />
    </div>

    <div class="content">
      <app-ecare-orders [party]="party" [selectMode]="true" (selectedOrder)="rowSelected($event)"> </app-ecare-orders>
    </div>

    <div class="buttons">
      <button class="btn wish-list-button wish-list-done-button" (click)="closePopup()">
        {{ 'wc.common.close' | translate }}
      </button>
    </div>
  </div>
</div>
