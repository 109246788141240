<div [formGroup]="form">
  <ng-container
    *ngTemplateOutlet="deliveryMethods.length > 1 ? multiDeliveryMethods : singleDeliveryMethod"></ng-container>
  <!-- Multi delivery method -->
  <ng-template #multiDeliveryMethods>
    <app-section-header
      id="collapseHeader"
      [title]="'wc.shopping.customerCreation.deliveryMethod.heading' | translate"
      [isCollapsed]="refEl.activeIds.length > 0"
      (click)="acc.toggle('toggleDeliveryMethods')">
    </app-section-header>
    <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleDeliveryMethods" [destroyOnHide]="false">
      <ngb-panel id="toggleDeliveryMethods">
        <ng-template ngbPanelContent>
          <div class="form">
            <ng-container *ngFor="let dm of deliveryMethods">
              <input
                class="deliveryMethod-input"
                type="radio"
                formControlName="deliveryMethod"
                id="{{ 'deliveryMethod.' + dm.productCode }}"
                [value]="dm.productCode"
                (change)="changeDeliveryProduct(dm)" />
              <label
                class="single-method deliveryMethod halfColumn"
                for="{{ 'deliveryMethod.' + dm.productCode }}"
                title="{{ dm.shortDescription }}">
                <div class="single-method_leftSide">
                  <div class="icon-container">
                    <img
                      alt="{{ dm.name }}"
                      src="{{
                        dm.picturePreview
                          ? dm.picturePreview.href
                          : '/assets/img/delivery-methods/' + dm.productCode + '.png'
                      }}" />
                  </div>
                  {{ dm.name }}
                </div>
                <div class="single-method_rightSide">
                  <div
                    class="single-method_priceSide"
                    [innerHTML]="
                      dm.prices['OC'] && dm.prices['OC'].price > 0 ? (dm.prices['OC'].price | price | safeHtml) : (0 | price | safeHtml)
                    "></div>
                  <span class="checkmark"></span>
                </div>
              </label>
            </ng-container>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-template>
  <!-- Single delivery method -->
  <ng-template #singleDeliveryMethod>
    <div
      app-section-header
      class="single-method"
      [title]="'wc.shopping.customerCreation.deliveryMethod.heading' | translate">
      <div class="single-method_leftSide">
        <div class="icon-container white">
          <img
            alt="{{ deliveryMethods[0].name }}"
            src="{{
              deliveryMethods[0].picturePreview
                ? deliveryMethods[0].picturePreview.href
                : '/assets/img/delivery-methods/' + deliveryMethods[0].productCode + '.png'
            }}" />
        </div>
        {{ deliveryMethods[0].name }}
      </div>
      <div
        class="single-method_priceSide"
        [innerHTML]="
          deliveryMethods[0].prices['OC']
            ? (deliveryMethods[0].prices['OC'].price | price | safeHtml)
            : (0 | price | safeHtml)
        "></div>
    </div>
  </ng-template>
</div>
