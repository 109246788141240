import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactDto, CustomerDto, OrderDto, SortDto, TicketDto } from '@btl/order-bff';
import { CompareType, EnableDynamicLoading, Search, ServiceUtils } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { OpportunityDetailsComponent } from './opportunity-details/opportunity-details.component';
import { OpportunityFilterComponent } from './opportunity-filter/opportunity-filter.component';
import { TicketService } from '@service/ticket.service';
import { ScenarioTypeEnum } from '../../../helpers/order-utils';
import { AbstractTicketTableComponent } from '../tickets/abstract-ticket-table.component';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

export declare type OpportunityProbabilityType = 'hot' | 'warm' | 'cold';

@Component({
  selector: 'app-opportunities-component',
  templateUrl: './ecare-opportunities.component.html',
})
@EnableDynamicLoading({ customName: EcareOpportunitiesComponent.PAGE_ID })
export class EcareOpportunitiesComponent extends AbstractTicketTableComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareOpportunitiesComponent';

  pageId(): string {
    return EcareOpportunitiesComponent.PAGE_ID;
  }

  @Input() tickets: TicketDto[] = [];
  @Input() canDisplayNoTicketsText: boolean;
  @Input() externalSearchMode = false;

  @ViewChild('opportunityFilterComponent') opportunityFilterComponent: OpportunityFilterComponent;
  defaultSortOrdering: SortDto = { column: 'startFrom', sortOrder: 'desc' };

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;
      this.initSearch(OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE, 'CUSTOMER');
      if (this.authService.account?.login) {
        this.search.filtering.push({
          column: 'assignedTo',
          compareType: 'EQUAL',
          value: this.authService.account.login,
        });
        this.loadTicketsBySearch(this.search);
      }

      this.authService.accountChange.subscribe(() => {
        if (this.opportunityFilterComponent) {
          this.sectionTabsChange(this.account.login);
        } else {
          this.search.filtering.push({
            column: 'assignedTo',
            compareType: 'EQUAL',
            value: this.authService.account.login,
          });
          this.loadTicketsBySearch(this.search);
        }
      });
    });
  }

  loadTicketsBySearch(search: Search) {
    this.appBlockerService.block();
    this.ticketingService
      .getTicketsByFilter(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          const tickets = [];
          const customers: string[] = [];
          const orders: string[] = [];

          if (result) {
            result.data.forEach(ticket => {
              const formControl = new FormControl();
              formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                this.allTicketsSelected = this.areAllSelected();
                this.anyTicketsSelected = this.isAnySelected();
              });
              this.selectedFormArray.push(formControl);

              const tasks: string[] = [];
              ticket['expectedRevenue'] = TicketService.getParamValue(
                ticket,
                TicketService.EXPECTED_REVENUE_PARAM_NAME
              );
              if (ticket.slaDueDate) {
                ticket['percentageToDueDate'] = this.calculatePercentage(new Date(ticket.created), new Date(ticket.slaDueDate));
              }

              ticket['salesTeam'] = TicketService.getParamValue(ticket, TicketService.TEAM_ASSIGNMENT_PARAM_NAME);
              ticket['state'] = TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_STATE_PARAM_NAME);
              ticket['type'] = TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_TYPE_PARAM_NAME);
              ticket['probability'] = TicketService.getParamValue(
                ticket,
                TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME
              );
              ticket['currentOperator'] = TicketService.getParamValue(
                ticket,
                TicketService.CURRENT_OPERATOR_PARAM_NAME
              );
              ticket['preferredContact'] = JSON.parse(
                TicketService.getParamValue(ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME)
              );
              ticket['customer'] = TicketService.getReferenceValue(ticket, 'com.emeldi.ecc.be.crm.dto.Customer');
              ticket['customerAccount'] = TicketService.getReferenceValue(
                ticket,
                'com.emeldi.ecc.be.crm.dto.CustomerAccount'
              );
              if (ticket['customer']) {
                customers.push(ticket['customer']);
              } else {
                let customer: CustomerDto = JSON.parse(
                  TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME)
                );

                let customerNames = [];

                if (customer) {
                  if (customer.subject.firstName) {
                    customerNames.push(customer.subject.firstName);
                  }
                  if (customer.subject.lastName) {
                    customerNames.push(customer.subject.lastName);
                  }
                } else {
                  let contact: ContactDto = JSON.parse(
                    TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_CONTACT_PARAM_NAME)
                  );
                  if (contact) {
                    if (contact.firstName) {
                      customerNames.push(contact.firstName);
                    }
                    if (contact.lastName) {
                      customerNames.push(contact.lastName);
                    }
                  }
                }
                if (customerNames.length > 0) {
                  ticket['customer'] = customerNames.join(' ');
                }
              }

              ticket.references.forEach(reference => {
                if (reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket') {
                  tasks.push(reference.entityId);
                }
                if (reference.entityType === 'com.emeldi.ecc.be.order.dto.Order') {
                  orders.push(reference.entityId);
                }
              });
              ticket['tasksIds'] = tasks;
              ticket['tasks'] = [];
              tickets.push(ticket);
            });

            if (customers.length > 0) {
              const search = ServiceUtils.getUnlimitedSearch();
              search.filtering.push({
                column: 'id',
                compareType: CompareType.CompareTypeDtoEnum.IN,
                value: customers,
              });
              this.customerService
                .getCustomers(search)
                .pipe(finalize(this.appBlockerService.unblock))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(customers => {
                  tickets.forEach(ticket => {
                    if (ticket['customer']) {
                      const customer = customers.data.find(loopCustomer => loopCustomer.id === ticket['customer']);
                      if (customer) {
                        ticket['customer'] = customer.displayName;
                        if (ticket['customerAccount']) {
                          const ca = this.customerLocalStorageService.getPartyByIdRecursive(
                            customer.childParties,
                            ticket['customerAccount']
                          );
                          if (ca) {
                            ticket['customerAccount'] = ca.displayName;
                          }
                        }
                      }
                    }
                  });
                });
            }

            if (orders.length > 0) {
              const search = ServiceUtils.getUnlimitedSearch();
              search.filtering.push({
                column: 'id',
                compareType: CompareType.CompareTypeDtoEnum.IN,
                value: orders,
              });
              this.orderingService
                .filterOrders(search, null)
                .pipe(finalize(this.appBlockerService.unblock))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(pagedOrders => {
                  tickets.forEach(ticket => {
                    const orders: OrderDto[] = [];
                    const quotes: OrderDto[] = [];
                    ticket.references.forEach(ref => {
                      if (ref.entityType === 'com.emeldi.ecc.be.order.dto.Order') {
                        const order = pagedOrders.data.find(loopCustomer => loopCustomer.id === ref.entityId);
                        if (order.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT) {
                          orders.push(order);
                        } else {
                          quotes.push(order);
                        }
                      }
                    });
                    ticket['orders'] = orders;
                    ticket['quotes'] = quotes;
                  });
                });
            }

            tickets.forEach(ticket => this.tickets.push(ticket));
            if (result.data.length > 0 && result.data.length === this.search.paging.pageSize) {
              this.moreRecordsExists = true;
            } else {
              this.moreRecordsExists = false;
            }
          }
        }
      });
  }

  showDetails(ticketDto: TicketDto, inNewTab: boolean = false) {
    if (inNewTab) {
      window.open(
        this.router.serializeUrl(this.siblingUrlTree('OpportunityDetailsComponent', { id: ticketDto.id })),
        '_blank',
      );
    } else {
      this.navigateSibling('OpportunityDetailsComponent', { id: ticketDto.id });
    }
  }

  new() {
    this.navigateSibling('OpportunityDetailsComponent', { id: 'newTicket' });
  }

  delete(ticket: TicketDto) {
    this.modalFactory.deleteEntityModal((dialogReference: NgbModalRef) => {
      this.ticketingService
        .deleteTicket(ticket.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.opportunityDeleted');
          this.tickets.length = 0;
          this.loadTicketsBySearch(this.search);
          dialogReference.dismiss();
        });
    });
  }

  get appCurrency(): string {
    return environment.currency;
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadTicketsBySearch(this.search);
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadTicketsBySearch(this.search);
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadTicketsBySearch(this.search);
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadTicketsBySearch(this.search);
  }

  getOpportunityProbabilityType(probability: number): OpportunityProbabilityType {
    if (probability <= 30) {
      return 'cold';
    }
    if (probability <= 70) {
      return 'warm';
    }
    return 'hot';
  }

  sectionTabsChange(event: any) {
    this.opportunityFilterComponent.assignedToChanged(event);
    this.opportunityFilterComponent.handleChange();
    this.allTicketsSelected = true;
    this.selectDeselectAll();
  }

  calculatePercentage(startDate: Date, endDate: Date): number {
    if (startDate && endDate) {
      // Get the current date
      const currentDate: Date = new Date();
      // Calculate the difference in milliseconds between the current date and the start date
      const timeDifferenceStart = currentDate.getTime() - startDate.getTime();

      // Calculate the difference in milliseconds between the end date and the start date
      const timeDifferenceTotal = endDate.getTime() - startDate.getTime();

      // Calculate the percentage
      let percentage = (timeDifferenceStart / timeDifferenceTotal) * 100;

      if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    }
    return null;
  }

  duplicate(ticket: TicketDto) {
    this.ticketService.getDuplicateParameters(ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSibling('OpportunityDetailsComponent', duplicateParamters);
        }
      });
  }
}
