import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { GroupDto } from '@btl/order-bff';
import { GroupTreeNode, ProductGroupService } from '@btl/btl-fe-wc-common';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-third-menu, [app-navigation-third-menu]',
  templateUrl: './navigation-third-menu.component.html',
  styleUrls: ['./navigation-third-menu.component.scss'],
})
export class NavigationThirdMenuComponent implements OnInit, OnDestroy, OnChanges {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  public navigationGroupsTree: Array<GroupTreeNode>;
  private productGroupSubscription: Subscription;

  @Input()
  private allProductGroups: Array<GroupTreeNode>;

  @Input()
  private currentProductGroup: GroupTreeNode;

  private currentProductGroupParent: GroupTreeNode;

  constructor(private productGroupService: ProductGroupService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allProductGroups']) {
      this.navigationGroupsTree = NavigationThirdMenuComponent.getProductGroup(
        this.currentProductGroup,
        this.allProductGroups
      ).children;
    }
  }

  ngOnInit() {
    this.navigationGroupsTree = NavigationThirdMenuComponent.getProductGroup(
      this.currentProductGroup,
      this.allProductGroups
    ).children;
    this.currentProductGroupParent = this.getCurrentGroupParent();
  }

  public static getProductGroup(currentProductGroup, allProductGroups) {
    let productGroup = currentProductGroup;
    const parents = [];
    while (productGroup.group.parentId) {
      productGroup = NavigationThirdMenuComponent.getProductGroupByGroupId(
        productGroup.group.parentId,
        allProductGroups
      );
      parents.push(productGroup);
    }

    if (currentProductGroup.children.length > 0 || parents.length === 1) {
      productGroup = currentProductGroup;
    } else if (parents[0]) {
      productGroup = parents[0];
    }

    return productGroup;
  }

  public getCurrentGroupParent() {
    return NavigationThirdMenuComponent.getProductGroupByGroupId(
      this.currentProductGroup.group.parentId,
      this.allProductGroups
    );
  }

  public static getProductGroupByGroupId(id, productGroups) {
    let retProductGroup = undefined;
    if (productGroups && productGroups.length > 0) {
      for (const productGroup of productGroups) {
        let found;
        if (productGroup.group.id === id) {
          found = productGroup;
        } else {
          found = NavigationThirdMenuComponent.getProductGroupByGroupId(id, productGroup.children);
        }

        if (found) {
          retProductGroup = found;
        }
      }
    }

    return retProductGroup;
  }

  public getGroupName(group: GroupDto): string {
    return this.productGroupService.getGroupName(group);
  }
}
