<app-ecare-page-layout
  class="ecare-ordersSearch"
  columnTemplate="1fr"
  [showBreadcrumbs]="!ecareSearch"
  title="{{
    !ecareSearch
      ? (caRefNo
          ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
            ' ' +
            ('wc.ecare.dashboard.account.header' | translate) +
            ' '
          : (cuRefNo ? customerLocalStorageService.getCurrentCustomer().displayName + ' ' : '') + ' ') +
        ('wc.ecare.orders.headline' | translate)
      : ''
  }}">
  <div>
    <div *blockUI="blockUIElement.name; template: blockTemplate">
      <div>
        <div class="ecare-filtering-wrapper">
          <app-section-tabs *ngIf="showStatusBar && ordersByState && ordersByState.length > 0">
            <li *ngFor="let orderByState of ordersByState">
              <a
                class="nav-link"
                [class.active]="selectedOrderState === orderByState.orderState"
                (click)="changeSelectedOrderState(orderByState.orderState)">
                {{
                (orderByState.orderState === 'ALL'
                    ? 'wc.ecare.ordersFilter.status.defaultFilter'
                    : '' + 'wc.common.orderState.' + orderByState.orderState
                ) | translate
                }}
                <span>({{ orderByState.count }})</span>
              </a>
            </li>
          </app-section-tabs>
          <div *ngIf="!ecareSearch">
            <app-orders-filter
              #filterComponent
              [selectMode]="selectMode"
              [constantFilter]="constantFilter"
              [filtering]="search.filtering"
              [party]="party"
              (filterChanged)="handleFilterChange()" />
          </div>
        </div>
        <app-ecare-order-table
          [canDisplayNoOrdersText]="canDisplayNoOrdersText"
          [selectMode]="selectMode"
          [orders]="orders"
          [currentSortOrder]="currentSortOrder"
          [thumbnailMap]="thumbnailMap"
          [maxThumbnailsToShow]="maxThumbnailsToShow"
          [isModuleBffCrmEnabled]="isModuleBffCrmEnabled"
          (selectedOrder)="itemSelected($event)"
          (sortByColumn)="orderByColumn($event)" />
        <app-ecare-order-table-mobile
          [canDisplayNoOrdersText]="canDisplayNoOrdersText"
          [selectMode]="selectMode"
          [orders]="orders"
          [currentSortOrder]="currentSortOrder"
          [thumbnailMap]="thumbnailMap"
          (selectedOrder)="itemSelected($event)"
          (sortByColumn)="orderByColumn($event)" />
      </div>
      <div>
        <app-ecare-pagination
          *ngIf="selectMode || ecareSearch"
          [page]="search.paging.page"
          [pageSize]="search.paging.pageSize"
          [totalCount]="pagedDto?.totalItems"
          [pagesToShow]="pagedDto?.totalPages"
          (goPrev)="onPrevPage()"
          (goNext)="onNextPage()"
          (goPage)="onSpecificPage($event)"
          (pageSizeChanged)="onPageSizeChanged($event)" />
      </div>
    </div>
  </div>
</app-ecare-page-layout>
