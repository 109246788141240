<ng-container *ngIf="product">
  <div class="section">
    <div class="inner">
      <form class="form">
        <div class="form-group-row">
          <label for="depositCustomerName">{{ 'wc.shopping.gui_checkoutScoring.customer' | translate }}</label>
          <div class="input">
            <input type="text" class="form-control" id="depositCustomerName" disabled value="{{ product.name }}" />
          </div>
        </div>
        <!-- deposit amount -->
        <div *ngIf="product.prices['OC']" class="form-group-row">
          <label for="depositAmount">{{ 'wc.shopping.gui_checkoutScoring.deposit' | translate }}</label>
          <div class="input">
            <input
              type="text"
              id="depositAmount"
              class="form-control"
              disabled
              value="{{ product.prices['OC'].price }}" />
            <div app-acl-internal-evaluate-component>
              <a
                id="001_checkout_deposit_item"
                class="removeInput"
                title="{{ (scoringDeposit ? 'wc.shopping.gui_checkoutScoring.zeroScoringDeposit.label' : 'wc.shopping.gui_checkoutScoring.removeDeposit.label') | translate }}"
                (click)="this.resultFlag === 'ORANGE' ? remove() : false">
                <i id="002_checkout_deposit_item" class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
        <!--scoring type-->
        <div *ngIf="scoringReason" class="form-group-row" app-acl-internal-evaluate-component>
          <label for="scoringType">{{ 'wc.shopping.gui_checkoutScoring.type' | translate }}</label>
          <div class="input">
            <input type="text" class="form-control" id="scoringType" disabled value="{{ scoringReason.scoringType }}" />
          </div>
        </div>
        <!--scoring result-->
        <div *ngIf="scoringReason" class="form-group-row" app-acl-internal-evaluate-component>
          <label for="scoringResult">{{ 'wc.shopping.gui_checkoutScoring.result' | translate }}</label>
          <div class="input">
            <input
              type="text"
              class="form-control"
              id="scoringResult"
              disabled
              value="{{ scoringReason.scoringTypeResult }}" />
          </div>
        </div>
        <!-- Deposit change -->
        <div app-acl-internal-evaluate-component [formGroup]="form">
          <div class="form-group-row">
            <label for="depositNewValue">
              {{ 'wc.shopping.gui_checkoutScoring.deposit.value' | translate }}
            </label>
            <div class="input">
              <input
                type="number"
                id="depositNewValue"
                formControlName="value"
                class="form-control"
                placeholder="{{ 'wc.shopping.gui_checkoutScoring.deposit.value.placeholder' | translate }}"
                (ngModelChange)="valueChanged()" />
              <app-input-errors
                translationPrefix="wc.shopping.gui_checkoutScoring.deposit.value"
                [control]="form.controls['value']">
              </app-input-errors>
            </div>
          </div>

          <div class="form-group-row">
            <label for="depositChangeNote">
              {{ 'wc.shopping.gui_checkoutScoring.deposit.note' | translate }}
            </label>
            <div class="input">
              <textarea
                type="text"
                id="depositChangeNote"
                formControlName="note"
                class="form-control"
                placeholder="{{ 'wc.shopping.gui_checkoutScoring.deposit.changeNote.placeholder' | translate }}">
              </textarea>
              <app-input-errors
                translationPrefix="wc.shopping.gui_checkoutScoring.deposit.note"
                [control]="form.controls['note']">
              </app-input-errors>
            </div>
          </div>
          <div class="form-group-row">
            <div class="formGroupButtons">
              <button id="003_checkout_deposit_item" class="btn btn-default btn-sm" (click)="changeDeposit()">
                {{ 'wc.shopping.gui_checkoutScoring.changeDeposit.label' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
