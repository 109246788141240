import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-row, [app-input-row]',
  templateUrl: './input-row.component.html',
})
export class InputRowComponent {
  @Input() prefixInputIcon: string;
  @Input() parentGroup: FormGroup;
  @Input() fieldName: string;
  @Input() labelKey: string;
  @Input() translationPrefix: string;
  @Input() itemsTranslationPrefix: string;
  @Input() itemsTranslationType: string;
  @Input() addItemsTranslationLabel = true;
  @Input() labelParameters: any;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string;
  @Input() mandatory = false;
  @Input() codebookType;
  @Input() selectItems;
  @Input() checkbox = false;
  @Input() datePicker = false;
  @Input() checkboxLabelKey: string;
  @Input() selectItemsAsRadio;
  @Input() itemIconsPath: string;
  @Input() mainCssClass = 'form-group-row';
  @Output() readonly onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSIMcard: boolean;
  @Input() mask: string;
  @Input() isPassword: boolean;
  @Input() isDisabled: boolean;
}
