<div class="widget--header primary-color">
  <div class="widget--header_title">
    <i [inlineSVG]="'/assets/svg/dashboard-widgets/upcoming-payment.svg'"></i>
    <h6>{{ 'wc.ecare.widgets.lastPayment.header' | translate }}</h6>
  </div>
</div>

<div class="widget--content" app-ecare-widget-main-info
     labelAdditionalStyle="inherit_color"
     [price]="lastInvoice?.remainingAmount.amount"
     [infoText]="('wc.ecare.widgets.accountOverview.maturity' | translate) +' '+ (lastInvoice?.billDate | date: 'dd.MM.yyyy') ">
</div>
<div class="widget--cta" *ngIf="existsRemainingAmount(lastInvoice)">
  <app-button label="Make payment" (click)="onPay(lastInvoice)" variant="whiteWithBorder" size="fullWidth"></app-button>
</div>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
