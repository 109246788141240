import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockTemplateComponent, NotificationService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { NotificationDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../../../../../helpers/form-utils';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { BlockUIService } from 'ng-block-ui';

@Component({
  selector: 'app-email-content-sharing-modal',
  templateUrl: './email-content-sharing-modal.component.html',
})
export class EmailContentSharingModalComponent implements OnDestroy {
  @Input()
  dialogRef;

  @Input()
  contentSeoUrl;

  form = this.formBuilder.group({
    emailTo: [null, Validators.required],
    emailFrom: [],
    info: [],
  });

  blockTemplate = BlockTemplateComponent;

  get emailContentSharingBlockUiElementName() {
    return 'emailContentSharing';
  }

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private stickyMessageService: StickyMessageService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private blockUIService: BlockUIService
  ) {

  }

  cancel() {
    this.dialogRef.dismiss();
  }

  send() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.blockUIService.start(this.emailContentSharingBlockUiElementName);
      this.propertyAccessorLocalService
        .getBaseUrl()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          const formData = this.form.getRawValue();
          const notification: NotificationDto = {
            notificationType: 'CONTENT_SHARE',
            contextMap: {
              contentSeoUrl: this.contentSeoUrl,
              contentSender: formData.emailFrom,
              userMessage: formData.info,
              globalBaseUrl: result,
            },
            recipients: [
              {
                address: formData.emailTo,
                recipientType: 'TO',
              },
            ],
          };
          this.notificationService
            .createNotification(notification)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(finalize(() => {
              this.blockUIService.stop(this.emailContentSharingBlockUiElementName)
            }))
            .subscribe(resp => {
              this.stickyMessageService.addStickySuccessMessage('wc.shopping.contentShare.sent');
              this.dialogRef.dismiss();
            });
        }, () => {
          this.blockUIService.stop(this.emailContentSharingBlockUiElementName)
        });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
