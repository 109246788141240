import {Component, Input, OnInit} from '@angular/core';
import {Placement} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-thumbnail-container',
  templateUrl: './thumbnail-container.component.html',
})
export class ThumbnailContainerComponent {

  @Input()
  thumbnailsForRow: { thumbnail: string; name: string; count: number; omit: boolean }[] = [];

  @Input()
  unshowedThumbnails: { count: number; names: string };

  @Input()
  tooltipPosition: Placement;

}
