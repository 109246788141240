import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { CustomerService } from '@service/customer.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { AttachmentType } from '../../form-attachments/form-attachments.component';
import {
  GatewayDto,
  NotificationDto,
  NotificationFrontendService,
  NotificationGatewayFrontendService,
  NotificationRecipientDto
} from '@btl/order-bff';
import { UrlParamsService } from '@service/url-params.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notifications-component',
  templateUrl: './ecare-notifications.component.html',
})
@EnableDynamicLoading({ customName: EcareNotificationsComponent.PAGE_ID })
export class EcareNotificationsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareNotificationsComponent';

  pageId(): string {
    return EcareNotificationsComponent.PAGE_ID;
  }

  private static readonly RESEND_ALLOWED_STATES = ['COMPLETED', 'CANCELED', 'FAILED'];

  notifications: NotificationDto[] = [];

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  moreRecordsExists = false;
  canDisplayNoFinancialDocumentsText = false;

  cuRefNo = null;
  caRefNo = null;
  queryParams = null;
  selected;
  gateways: GatewayDto[];
  attachmentType = AttachmentType.ATTACH_FILES;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'created', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    private readonly fb: FormBuilder,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private readonly orderingService: WcOrderingService,
    private readonly appBlockerService: AppBlockerService,
    private readonly propertyAccessorLocalService: PropertyAccessorLocalService,
    private readonly notificationFrontendService: NotificationFrontendService,
    public modal: NgbModal,
    private stickyMessageService: StickyMessageService,
    private notificationGatewayFrontendService: NotificationGatewayFrontendService
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
    notificationGatewayFrontendService
      .filterGateways(null, null, null, 1, -1)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => (this.gateways = result.data));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;

      if (
        this.customerLocalStorageService.getCurrentCustomer() ||
        this.customerLocalStorageService.getCurrentCustomerAccount()
      ) {
        this.search.filtering.push({
          column: 'partyRefNo',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: this.caRefNo ? this.caRefNo : this.cuRefNo,
        });
      }
    });
  }

  loadContactHistoryBySearch(search: Search) {
    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    this.notificationFrontendService
      .filterNotifications(
        null,
        serializedFilter,
        serializedSorting,
        search.paging.page,
        search.paging.pageSize,
        search.fields
      )
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          result.data.forEach(notification => this.notifications.push(notification));
          if (result.data.length > 0 && result.data.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  public handleFilterChange(): void {
    this.notifications.length = 0;
    this.search.paging.page = 1;
    this.loadContactHistoryBySearch(this.search);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.moreRecordsExists) {
      this.search.paging.page++;
      this.loadContactHistoryBySearch(this.search);
    }
  }

  duplicate(notificationDto: NotificationDto) {
    const params = UrlParamsService.mergeParams(this.queryParams, {
      id: 'newNotification',
      duplicationId: notificationDto.id,
    });
    this.navigateSibling('NotificationDetailComponent', params);
  }

  newNotification() {
    const params = UrlParamsService.mergeParams(this.queryParams, {
      id: 'newNotification',
    });
    this.navigateSibling('NotificationDetailComponent', params);
  }

  getRecipientsNames(recipients: Array<NotificationRecipientDto>) {
    return recipients.filter(recipient => recipient.recipientName)
      .map(recipient => recipient.recipientName).join(', ');
  }

  getRecipientsAddresses(recipients: Array<NotificationRecipientDto>, type) {
    return recipients
      .filter(recipient => recipient.recipientType === type)
      .map(recipient => recipient.address)
      .join(', ');
  }

  delete(notification: NotificationDto) {
    this.notificationFrontendService
      .deleteNotification(notification.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.notifications.notificationDeleted');
        this.loadContactHistoryBySearch(this.search);
      });
  }

  resent(notification: NotificationDto) {
    if (!this.isResendAllowed(notification)) {
      this.stickyMessageService.addStickyErrorMessage('wc.ecare.notifications.notificationCannotBeResent');
    } else {
      this.notificationFrontendService
        .patchNotification(notification.id, { state: 'INITIAL' })
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.notifications.notificationResend');
          this.loadContactHistoryBySearch(this.search);
        });
    }
  }

  getGatewayChannelType(gatewayCode) {
    const gatewayDto = this.gateways.find(gateway => gateway.code === gatewayCode);
    return gatewayDto ? gatewayDto.channelType.toLowerCase() : null;
  }

  isResendAllowed(notification: NotificationDto): boolean {
    return EcareNotificationsComponent.RESEND_ALLOWED_STATES.includes(notification?.state);
  }

  getNotificationBody(notification: NotificationDto): string {
    if (notification.body && !notification.bodyAlternative) {
      return notification.body;
    }
    return notification.bodyAlternative;
  }
}
