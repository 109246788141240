import { ProductUtils } from '../../../../helpers/product-utils';
import { OrderDto, ProductBundleDto, ProductDetailDto, StickerDto, StoreCountDto, TextDto } from '@btl/order-bff';
import { ColorVariant } from '@service/color.service';
import { ProductCustomService } from '@service/product-custom.service';
import { of, Subject, Subscription } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { StockCustomService, StockState } from '@service/stock-custom.service';
import { Observable } from 'rxjs/internal/Observable';
import {
  Breadcrumb,
  CurrentLocaleService,
  GroupTreeNode,
  ProductGroupService,
  StoreService
} from '@btl/btl-fe-wc-common';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { DateService } from '@service/date.service';
import { Product } from '../../../../models/product';
import { PictureUtils } from '../../../../helpers/picture-utils';
import { PictureTypeEnum } from '../../../../models/PictureTypeEnum';
import { ProductComparisonService } from '@service/product-comparison.service';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import { Component, OnDestroy } from '@angular/core';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { Router } from '@angular/router';
import { NavigationMenuService } from '@service/navigation-menu.service';
import { OrderUtils } from '../../../../helpers/order-utils';
import { PartyElse } from '../../../../models/party-holder';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({ template: '' })
export abstract class BaseProductDetailComponent implements OnDestroy {
  protected readonly PREFERRED_SHOP_ATTR = 'preferredShop';

  product: ProductDetailDto;
  selectedTariffBundle: ProductBundleDto;
  selectedGiftBundle: ProductBundleDto;
  checkScanRequired: boolean;
  selectedColor: string;
  productColorVariants: Array<ColorVariant>;

  realStockCentral: number;
  virtualStockCentral: number;
  realStockNonCentral: number;
  deliveryDate: Date;

  compareAvailable: boolean = false;
  maxProductsInComparison: number;

  breadcrumbs: Array<Breadcrumb>;

  stockCentral$: Observable<StockState>;
  storeCount$: Observable<StoreCountDto>;
  preferredStock$: Observable<StockState>;

  currentOrder: OrderDto;
  preferredShop: PartyElse;

  currentGroupTreeNode;
  treeGroupPath;
  protected onDestroy$: Subject<void> = new Subject<void>();
  protected allSubscriptions: Array<Subscription> = new Array<Subscription>();

  protected constructor(
    protected router: Router,
    protected productCustomService: ProductCustomService,
    protected stockCustomService: StockCustomService,
    protected currentLocaleService: CurrentLocaleService,
    protected propertyAccessorLocalService: PropertyAccessorLocalService,
    protected dateService: DateService,
    protected productGroupService: ProductGroupService,
    protected productComparisonService: ProductComparisonService,
    protected orderingService: WcOrderingService,
    protected shoppingCartService: ShoppingCartService,
    protected navigationMenuService: NavigationMenuService,
    protected storeService: StoreService
  ) {
    this.initMaxProductsInComparison();
  }

  abstract ngOnDestroy(): void;

  isTariff(): boolean {
    return ProductUtils.isTariff(this.product);
  }

  initColorVariants(): void {
    const subscr = this.productCustomService.getColorVariants(this.product.productCode).subscribe(colors => {
      if (colors && JSON.stringify(colors) !== JSON.stringify(this.productColorVariants)) {
        this.productColorVariants = colors;
        this.productColorVariants.forEach(item => {
          if (item.productCode === this.product.productCode) {
            this.selectedColor = item.name;
            this.check3dModel();
          }
        });
        this.initBreadcrumbs(this.productColorVariants.map(c => c.productCode));
      }
    });
    this.allSubscriptions.push(subscr);
  }

  // this method should be overridden if 3D model should be loaded for product detail
  check3dModel() {}

  public initBreadcrumbs(productIds: string[]) {
    productIds = productIds && productIds.length ? productIds : [this.product.productCode];
    const treeGroupPath$ = this.productCustomService.getProductGroupsForProduct(productIds).pipe(
      switchMap(product => {
        let groups = [];
        if (product) {
          groups = product.groups
            .filter(group => group.seq || group.seq === 0)
            .sort((a, b) => {
              return a.id.localeCompare(b.id.toString());
            });
        }
        if (!groups.length) {
          return of(null);
        }
        const groupIds = groups.map(group => group.id.toString());
        // search all top level groups of the product for param 'isCompareAvailable' === true
        this.initCompareAvailable(groupIds);
        return this.productGroupService.getTreePathToGroup(groupIds);
      })
    );
    this.setBreadcrumbsByGroups(treeGroupPath$);
  }

  private initCompareAvailable(groupIds: string[]) {
    const groupsSubscription = this.productGroupService.getProductGroups().subscribe(groups => {
      this.compareAvailable = false;
      for (const groupId of groupIds) {
        const productGroupById = this.productGroupService.getProductGroupById(groups, groupId);
        if (
          productGroupById &&
          productGroupById.group.groupParams &&
          productGroupById.group.groupParams['isCompareAvailable'] === 'true'
        ) {
          this.compareAvailable = true;
          break;
        }
      }
    });
    this.allSubscriptions.push(groupsSubscription);
  }

  private setBreadcrumbsByGroups(treeGroupPath$: Observable<Array<GroupTreeNode>>) {
    const subscription = this.productGroupService
      .getCurrentProductGroup()
      .pipe(
        switchMap(currentGroupTreeNode => {
          this.currentGroupTreeNode = currentGroupTreeNode;
          return treeGroupPath$;
        })
      )
      .subscribe(treeGroupPath => {
        this.treeGroupPath = treeGroupPath;
      });
    this.allSubscriptions.push(subscription);
  }

  initStocks() {
    this.initStockCentral();
    this.initStockNonCentral();
    this.storeCount$ = this.storeService.getStoreCount(this.product.productCode, 'POS');
  }

  private initStockCentral() {
    this.stockCentral$ = this.stockCustomService.getStockCentral(this.product.productCode).pipe(
      tap(result => {
        this.realStockCentral = result.realStock;
        this.virtualStockCentral = result.virtualStock;
      })
    );
  }

  private initStockNonCentral() {
    const stockNonCentral$ = this.stockCustomService.getStockNonCentral(this.product.productCode).subscribe(result => {
      this.realStockNonCentral = result.realStock;
    });
    this.allSubscriptions.push(stockNonCentral$);
  }

  sortStickersByPriority(stickers: Array<StickerDto>): Array<StickerDto> {
    return stickers.sort((a, b) => a.priority - b.priority);
  }

  getStickerText(texts: Array<TextDto>): string {
    const text = texts.find(
      t => t.textType === 'STICKER_NAME' && t.locale === this.currentLocaleService.getCurrentLanguage()
    );
    if (text) {
      return text.message;
    }
    return '';
  }

  protected getGlobalDeliveryDaySubscription() {
    this.dateService.getHolidaysFromCodebook();
    const globalDeliveryDelaySubscription = this.propertyAccessorLocalService
      .getGlobalDeliveryDelay()
      .subscribe(globalDeliveryDelay => {
        if (globalDeliveryDelay) {
          const subscription = this.dateService.getHolidaysSubject().subscribe(result => {
            this.deliveryDate = this.dateService.getWorkingDay(
              this.dateService.addDays(new Date(), globalDeliveryDelay),
              result
            );
          });
          this.allSubscriptions.push(subscription);
        }
      });
    this.allSubscriptions.push(globalDeliveryDelaySubscription);
  }

  isProductInComparison(product: ProductDetailDto) {
    return this.productComparisonService.isProductInComparison(product.seoUrl);
  }

  addProductToComparison(product: ProductDetailDto, maxProductsInComparison: number) {
    const productElastic: Product = new (class implements Product {})();
    productElastic.seoUrl = product.seoUrl;
    // todo solve english and czech name when known
    productElastic.name = { en: product.name, cs: product.name };

    const pictureThumbnail = PictureUtils.getPictureByType(product, PictureTypeEnum.PRODUCT_GENERAL_THUMBNAIL);
    const pictureMiddle = PictureUtils.getPictureByType(product, PictureTypeEnum.PRODUCT_GENERAL_LISTING);
    productElastic.image = {
      thumbnail: pictureThumbnail ? pictureThumbnail.extId : '',
      medium: pictureMiddle ? pictureMiddle.extId : '',
    };
    this.productComparisonService.addToComparison(productElastic, maxProductsInComparison);
  }

  removeProductFromComparison(product: ProductDetailDto) {
    this.productComparisonService.removeFromComparison(product.seoUrl);
  }

  testHwLeasing(): boolean {
    return ProductUtils.isOfCategory(this.product, CategoryTypeEnum.PRODC_SU_HW_LEASING);
  }

  addAllToBasket(isAddToCart?: boolean) {
    let giftProduct: ProductDetailDto = null;
    let discountProduct: ProductDetailDto = null;
    let tariffProduct: ProductDetailDto = null;

    if (this.selectedGiftBundle) {
      giftProduct = this.getBundleGiftProduct(this.selectedGiftBundle);
      discountProduct = this.getBundleDiscountProduct(this.selectedGiftBundle);
    }

    if (this.selectedTariffBundle) {
      tariffProduct = this.getBundleTariffProduct(this.selectedTariffBundle);
      discountProduct = this.getBundleDiscountProduct(this.selectedTariffBundle);
    }

    if (tariffProduct) {
      this.testIfScanRequired(tariffProduct.productCode)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(first())
        .subscribe(scanRequired => {
          if (scanRequired) {
            this.router.navigate(['/eshop/technology-check'], {
              queryParams: {
                productId: tariffProduct?.id,
                giftProductId: giftProduct?.id,
                discountProductId: discountProduct?.id,
                hwProductId: this.product.id,
                backUrl: isAddToCart ? this.router.url : '',
              },
            });
          } else {
            this.orderingService
              .addAllToBasket(this.product, giftProduct, discountProduct, tariffProduct)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(order => {
                isAddToCart
                  ? this.navigationMenuService.openShoppingCartPopup.emit()
                  : this.router.navigate(['/eshop/shopping-cart']);
              });
          }
        });
    } else {
      this.orderingService
        .addAllToBasket(this.product, giftProduct, discountProduct, tariffProduct)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => {
          isAddToCart
            ? this.navigationMenuService.openShoppingCartPopup.emit()
            : this.router.navigate(['/eshop/shopping-cart']);
        });
    }
  }

  testIfScanRequired(productCode): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.productCustomService
        .getProductGroupsForProduct(productCode)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(value => {
          let groups = [];
          if (productCode && value) {
            groups = value.groups
              .filter(group => group.seq || group.seq === 0)
              .sort((a, b) => {
                return a.id.localeCompare(b.id.toString());
              });
            const groupIds = groups.map(group => group.id.toString());
            this.productGroupService.getProductGroups().subscribe(groups => {
              for (const groupId of groupIds) {
                const productGroupById = this.productGroupService.getProductGroupById(groups, groupId);
                if (
                  productGroupById &&
                  productGroupById.group.groupParams &&
                  productGroupById.group.groupParams['checkScanRequired'] === 'true'
                ) {
                  observer.next(true);
                }
              }
              observer.next(false);
            });
          }
        });
    });
  }

  goToTechnologyCheck(productId, selectedTariffId) {
    this.router.navigate(['/eshop/technology-check'], {
      queryParams: {
        productId: productId,
        parentId: selectedTariffId,
      },
    });
  }

  getBundleTariffProduct(productBundleDto: ProductBundleDto): ProductDetailDto {
    return productBundleDto.products.find(product =>
      ProductUtils.isOfCategory(product, CategoryTypeEnum.PRODC_SU_TARIFF)
    );
  }

  getBundleDiscountProduct(productBundleDto: ProductBundleDto): ProductDetailDto {
    return productBundleDto.products.find(product => product.parametersStatic['isDiscount']);
  }

  getBundleGiftProduct(productBundleDto: ProductBundleDto): ProductDetailDto {
    return productBundleDto.products.find(
      product => ProductUtils.isOfCategory(product, CategoryTypeEnum.PRODC) && !product.parametersStatic['isDiscount']
    );
  }

  private initMaxProductsInComparison() {
    const maxProductsSubsription = this.propertyAccessorLocalService
      .getMaxComparisonHwCount()
      .subscribe(maxComparisonHwCount => (this.maxProductsInComparison = maxComparisonHwCount));
    this.allSubscriptions.push(maxProductsSubsription);
  }

  handleOrder(order: OrderDto) {
    this.currentOrder = order;
    this.preferredShop = JSON.parse(OrderUtils.getOrderAttributeValue(order, this.PREFERRED_SHOP_ATTR));
    if (this.preferredShop) {
      this.preferredStock$ = this.stockCustomService.getPreferredStock(this.product.productCode, this.preferredShop);
    }
  }
}
