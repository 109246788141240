<div class="technologyCheck__search">
  <div class="technologyCheck__wrapper">
    <i
      id="019_navigation_menu"
      class="primary-menu__icon primary-menu__icon-search"
      [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
    <input
      #addressSearch
      type="text"
      autocomplete="off"
      placeholder="{{ 'wc.admin.productConfiguration.address.placeholder' | translate }}"
      [value]="filter?.attributes?.text ? filter?.attributes?.text : ''"
      (input)="searchChange(addressSearch.value)"
      (keyup.enter)="autocomplete.hidden = true"
      (focusout)="autocompleteFocusOut(autocomplete)"
      (focusin)="autocomplete.hidden = false" />
    <div class="col-sm-2"></div>
    <div #autocomplete class="technologyCheck__autocomplete">
      <div *ngFor="let address of autocompleteProducts | async; let i = index" class="autocomplete-items">
        <a href="javascript:void(0)" (click)="selectAddress(address)">
          <span [innerHTML]="address._source | displayAddress : addressType | highlight : addressSearch.value"></span>
        </a>
      </div>
    </div>
  </div>
</div>
