<div class="widget--header">
  <div class="widget--header_title">
    <i [inlineSVG]="'/assets/svg/dashboard-widgets/support.svg'"></i>
    <h6>{{ 'wc.ecare.widgets.help.support' | translate }}</h6>
  </div>
</div>
<div class="widget--content content-in-row help">
  <div class="mainInfo">
    <h6 class="banner-title">{{ 'wc.ecare.widgets.help.needHelp' | translate }}</h6>
    <p class="banner-description">{{ 'wc.ecare.widgets.help.contactTeam' | translate }}</p>
  </div>
</div>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
