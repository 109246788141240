<ng-container *ngIf="customerAccount; else customer">
  <div *ngIf="customerAccount" class="widget--header">
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/account-overview.svg'"></i>
      <h6>{{ 'wc.ecare.widgets.accountOverview.header' | translate }}</h6>
    </div>
    <div class="widget--header_account">
      <a link="#">{{ 'wc.ecare.orders.showDetails.button' | translate }}</a>
    </div>
  </div>
  <div class="widget--content">
    <app-ecare-widget-main-info
      [label1]="
      customerAccount.parameters['customName'] ? customerAccount.parameters['customName'] : customerAccount.displayName
    "
      [price]="sumPrice"
      [period]="'wc.shopping.confirmation.price.RC.label' | translate"
      [hasSuccessIcon]="upToDate"
      [infoText]="
      (upToDate
        ? 'wc.ecare.widgets.accountOverview.yourAccountIsUpToDate'
        : 'wc.ecare.widgets.accountOverview.paymentIsRequired'
      ) | translate
    ">
    </app-ecare-widget-main-info>
    <div class="details-wraper">
      <div class="detail-item">
        <div class="detail-item--label">
          <i [inlineSVG]="'/assets/svg/dashboard-widgets/payment-method.svg'"></i
          >{{ 'wc.ecare.dashboard.paymentMethod.label' | translate }}
        </div>
        <div class="detail-item--value">
          {{
          'wc.shopping.customerAccount.paymentMethod.' + customerAccount.parameters['paymentMethod'] + '.label'
            | translate
          }}
          {{paymentMethodAdditionalInfo ? '• ' + paymentMethodAdditionalInfo : ''}}
        </div>
      </div>
      <div class="detail-item" ng *ngIf="billCycleSpecification">
        <div class="detail-item--label">
          <i [inlineSVG]="'/assets/svg/dashboard-widgets/billing-period.svg'"></i
          >{{ 'wc.shopping.customerAccount.billingCycle.BC.label' | translate }}
        </div>
        <div class="detail-item--value">
          {{ billCycleSpecification?.frequency }} • {{ 'wc.ecare.widgets.accountOverview.maturity' | translate }}
          {{ maturity | date : 'dd.MM' }}
        </div>
      </div>
      <div class="detail-item wide">
        <div class="detail-item--label">
          <i [inlineSVG]="'/assets/svg/dashboard-widgets/invoice-address.svg'"></i
          >{{ 'wc.ecare.dashboard.invoiceAddress.label' | translate }}
        </div>
        <div *ngIf="this.address" class="detail-item--value">
          {{ this.address | displayAddress : addressTypes.DASHBOARD_CA_DETAIL }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #customer>
  <div class="widget--header">
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/account-overview.svg'"></i>
      <h6>{{ 'wc.ecare.customerDetails.headline' | translate }}</h6>
    </div>
    <div class="widget--header_account">
      <a [routerLink]="['/ecare/settings']"
         [queryParams]="{cuRefNo: customerLocalStorageService.getCurrentCustomer().id}">
        {{ 'wc.ecare.orders.showDetails.button' | translate }}</a>
    </div>
  </div>
  <div class="widget--content">
    <app-ecare-widget-main-info
      [priceFormat]="false"
      [price]="customerLocalStorageService.getCurrentCustomer().displayName">
    </app-ecare-widget-main-info>
    <div class="details-wraper">
      <div class="detail-item wide">
        <div class="detail-item--label">
          <i [inlineSVG]="'/assets/svg/dashboard-widgets/invoice-address.svg'"></i
          >{{ 'wc.ecare.dashboard.primaryContact.label' | translate }}
        </div>
        <div *ngIf="customerLocalStorageService.getCurrentCustomer().contact" class="detail-item--value">
          {{ customerLocalStorageService.getCurrentCustomer().contact | displayContact }}
        </div>
      </div>
      <div class="detail-item wide">
        <div class="detail-item--label">
          <i [inlineSVG]="'/assets/svg/dashboard-widgets/invoice-address.svg'"></i
          >{{ 'wc.ecare.dashboard.registrationAddress.label' | translate }}
        </div>
        <div *ngIf="customerLocalStorageService.getCurrentCustomer().address" class="detail-item--value">
          {{ customerLocalStorageService.getCurrentCustomer().address | displayAddress : addressTypes.DASHBOARD_CA_DETAIL }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-button
  *ngIf="editMode"
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
