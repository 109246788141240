<div *ngIf="showMobileMenu" class="mobile-menu-wrapper d-print-none">
  <div class="mobile-menu">
    <div
      class="mobile-menu-bottom-part"
      [ngClass]="{'with-left-panel': authFactoryService.getAuthService()?.account?.external === false}">
      <ng-container *ngTemplateOutlet="topMenuRightSide"></ng-container>
    </div>
    <div class="mobile-menu-top-part">
      <div class="mobile-menu-control-wrapper">
        <ng-container *ngIf="authFactoryService.getAuthService()?.account?.external === false">
          <a class="mobile-menu-close"
             [routerLink]="'/ecare/homepage'"
             ngbTooltip="{{ 'wc.common.navigation.homepage.button' | translate }}">
            <i [inlineSVG]="'/assets/svg/top-menu-icons/ph_house.svg'"></i>
          </a>

          <a class="mobile-menu-close"
             [routerLink]="'/ecare/search'"
             ngbTooltip="{{ 'wc.common.search.button' | translate }}">
            <i [inlineSVG]="'/assets/svg/top-menu-icons/ph_magnifying-glass.svg'"></i>
          </a>
          <!-- Todo - change icon when there is unread notification -->
          <a
            class="mobile-menu-close"
            [routerLink]="'/ecare/notifications'"
            ngbTooltip="{{ 'wc.common.userNavigation.notifications.button' | translate }}">
            <i [inlineSVG]="'/assets/svg/top-menu-icons/ph_bell.svg'"></i>
          </a>

          <a class="mobile-menu-close"
             icon="ph_question"
             [routerLink]="'/help'"
             ngbTooltip="{{ 'wc.common.navigation.help.button' | translate }}">
            <i [inlineSVG]="'/assets/svg/top-menu-icons/ph_question.svg'"></i>
          </a>
        </ng-container>

        <a id="023_navigation_menu" class="mobile-menu-close" aria-hidden="true" (click)="toggleShowMenu()"
        ><i [inlineSVG]="'/assets/svg/close-icon.svg'"></i
        ></a>
      </div>
      <ng-container *ngTemplateOutlet="topMenuLeftSide"></ng-container>
      <div *ngIf="navigationType === NavigationTypeEnum.eshop" class="mobile-nav-part main-navigation">
        <ng-container *ngTemplateOutlet="mainNavigation"></ng-container>
      </div>
      <div *ngIf="navigationType !== NavigationTypeEnum.eshop" class="mobile-nav-part main-navigation">
        <ng-container *ngTemplateOutlet="navigation"></ng-container>
      </div>

      <div class="mobile-nav-part">
        <a
          id="038_navigation_menu"
          class="primary-menu__item primary-menu__item-icon primary-menu__item-cart"
          (click)="goToShoppingCart()">
          <i
            class="primary-menu__icon primary-menu__icon-cart primary-menu__icon-cart-solid"
            [inlineSVG]="'/assets/svg/shopping-cart-icon.svg'"></i>
          {{ 'wc.common.navigation.shopping_cart.button' | translate }}
        </a>
        <a
          id="039_navigation_menu"
          class="primary-menu__item primary-menu__item-icon primary-menu__item-wish-list"
          [routerLink]="['/eshop/wish-lists']">
          <i
            class="primary-menu__icon primary-menu__icon-wish-list"
            [inlineSVG]="'/assets/svg/red-heart-outlined.svg'"></i>
          {{ 'wc.common.navigation.wishLists' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
