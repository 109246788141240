import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  AddressDto, AccountContactDto,
  CustomerDto,
  OrderDto,
  OrderParamDto,
  PagedAccountContactsDto
} from '@btl/order-bff';
import {
  CustomerAccountFormComponent
} from 'app/components/page/customer-account-form/customer-account-form.component';
import { CustomerFormComponent } from 'app/components/page/customer-form/customer-form.component';
import { WcOrderingService } from '@service/wc-ordering.service';
import moment_ from 'moment';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { OrderUtils } from 'app/helpers/order-utils';
import { FormUtils } from 'app/helpers/form-utils';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import _ from 'lodash';
import { TicketService } from '@service/ticket.service';
import { first, takeUntil } from 'rxjs/operators';
import { AccountContactsService, AuthFactoryService, MetaDescriptionService } from '@btl/btl-fe-wc-common';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { DeliveryComponent } from '../delivery/delivery.component';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
})
export class CreateCustomerComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public readonly CUSTOMER = 'customer';
  public readonly CUSTOMER_ACCOUNT = 'customerAccount';

  @ViewChild('customerForm', { static: true }) customerFormComponent: CustomerFormComponent;
  @ViewChild('customerAccountForm', { static: true }) customerAccountFormComponent: CustomerAccountFormComponent;

  orderAttributes: Array<OrderParamDto>;

  formUserDetails: FormGroup;

  mainProductCategory;

  orderChanged = false;

  hwInvoicingContactDtos: Array<AccountContactDto>;
  deliveryContactDtos: Array<AccountContactDto>;

  currentOrder: OrderDto = null;

  customer: CustomerDto;
  registrationAddressPresent = true;

  @Input() isVerifiedCustomerRequired = false;
  @Input() simpleCustomer = false;
  @Input() account;
  @Input() accountPrimaryContact;
  @Input() contactType;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  validateForm() {
    FormUtils.validateAllFormFields(this.formUserDetails);
  }

  updateOrderDtoAttrWithCustomerData(orderAttributes: Array<OrderParamDto>) {
    this.updateOrderDtoAttr(orderAttributes, this.formUserDetails);
  }

  @Input() checkSameAs = false;

  sameAs = {
    hwInvoicingContactSameAsPrimary: false,
    hwInvoicingAddressSameAsRegistration: false,
    deliveryContactSameAsPrimary: false,
    deliveryAddressSameAsRegistration: false,
  };

  constructor(
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private fb: FormBuilder,
    private accountContactsService: AccountContactsService,
    private metaDescriptionService: MetaDescriptionService,
    private authFactoryService: AuthFactoryService
  ) {}

  ngOnInit() {
    this.formUserDetails = this.fb.group({});
    this.formUserDetails.addControl(this.CUSTOMER, this.customerFormComponent.getCustomerGroupControl());
    this.formUserDetails.addControl(
      this.CUSTOMER_ACCOUNT,
      this.customerAccountFormComponent.getCustomerAccountGroupControl()
    );

    const registrationAddressPresentCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Address', {
      resourceIdentification: { addressType: AddressDto.TypeDtoEnum.REGISTRATION },
    });

    this.authFactoryService
      .getAuthService()
      .accountChange.pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.account = result;
        this.fillFormWithOrderAttributes(this.currentOrder.orderAttributes);
      });

    let deliveryContactDtosCall: Observable<PagedAccountContactsDto> = of(null);
    if (this.account) {
      deliveryContactDtosCall = this.accountContactsService.getAccountContactsByType('DELIVERY', this.account.id);
    }

    forkJoin([registrationAddressPresentCall, deliveryContactDtosCall])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([registrationAddressPresent, deliveryContactDtos]) => {
        this.registrationAddressPresent = registrationAddressPresent.metaParameters['guiVisible'] === 'true';

        const handleGetCurrentOrder = (orderDto: OrderDto) => {
          this.currentOrder = orderDto;
          this.mainProductCategory = this.shoppingCartService.preCalculatedShoppingCart.getMainOderCategoryId();

          if (!(Object.getOwnPropertyNames(orderDto).length === 0)) {
            this.deliveryContactDtos = deliveryContactDtos?.data;
            this.fillFormWithOrderAttributes(orderDto.orderAttributes);
          } else {
            throw new Error('There is no current order!');
          }
        };
        this.orderingService.getCurrentOrder().subscribe(handleGetCurrentOrder);

        this.orderingService.orderChanged.subscribe(order => {
          this.currentOrder = order;
          if (
            OrderUtils.formOrderAttributesChanged(this.formUserDetails, this.orderAttributes, order.orderAttributes)
          ) {
            this.orderChanged = true;
            this.fillFormWithOrderAttributes(order.orderAttributes);
          }
        });
      });
  }

  setSameAsField(sameAsFieldName, orderAttrName, sameAsObject) {
    const object = JSON.parse(OrderUtils.getOrderAttributeValue(this.currentOrder, orderAttrName));

    if (object && CustomerPartyUtil.equalsWithExclude(sameAsObject, object, 'type', 'id', 'parameters', 'address')) {
      this.sameAs[sameAsFieldName] = true;
    }
  }

  fillFormWithOrderAttributes(orderAttributes: Array<OrderParamDto>) {
    let customerAccount;
    let customerFromAttr = false;
    this.orderAttributes = orderAttributes;
    this.orderAttributes.forEach(attribute => {
      const attr = attribute.attr;
      const value = attribute.value;
      const control = this.formUserDetails.get(attr);
      if (control) {
        if (control instanceof FormGroup) {
          if (attr === this.CUSTOMER) {
            this.customer = JSON.parse(value);
            customerFromAttr = true;
            if (!this.customer.address) {
              this.customer.address = CustomerPartyUtil.getDefaultAddress(AddressDto.TypeDtoEnum.REGISTRATION);
            }
          }
          if (attr === this.CUSTOMER_ACCOUNT) {
            customerAccount = JSON.parse(value);
            CustomerPartyUtil.updateCustomerAccountContacts(customerAccount);
          }
        } else if (attr === 'agreeCondition1' || attr === 'agreeCondition2' || attr === 'agreeCondition3') {
          control.setValue(value === 'true');
        } else {
          control.patchValue(value);
        }
      }
    });

    if (this.customer && this.checkSameAs) {
      this.setSameAsField(
        'hwInvoicingContactSameAsPrimary',
        DeliveryComponent.HW_INVOICING_CONTACT,
        this.customer.contact
      );
      this.setSameAsField(
        'hwInvoicingAddressSameAsRegistration',
        DeliveryComponent.HW_INVOICING_ADDRESS,
        this.customer.address
      );
      this.setSameAsField('deliveryContactSameAsPrimary', DeliveryComponent.DELIVERY_CONTACT, this.customer.contact);
      this.setSameAsField(
        'deliveryAddressSameAsRegistration',
        DeliveryComponent.DELIVERY_ADDRESS,
        this.customer.address
      );
    }

    if (!this.customer) {
      this.customer = CustomerPartyUtil.getDefaultCustomer();
    }

    if (!customerFromAttr) {
      //if AUTH_AUTO_REGISTRATION user login and page refresh
      this.fillCustomerContactFromAccount(this.customer);
    }

    if (this.orderChanged) {
      this.patchCustomer(this.customer);
    } else {
      this.customerFormComponent
        .generateForm(this.customer)
        .pipe(first())
        .subscribe(() => {
          this.patchCustomer(this.customer);
          this.disableContactAccountFields();

          if (!customerAccount) {
            if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
              customerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();
            } else {
              const customerAccounts = this.customerLocalStorageService.getActiveCustomerAccounts();
              if (customerAccounts.length > 0) {
                customerAccount = customerAccounts[customerAccounts.length - 1];
              }
            }
          }

          if (!customerAccount && this.customer) {
            customerAccount = CustomerPartyUtil.getDefaultCustomerAccount();
          }

          this.customerAccountFormComponent.initContactsAndAddreses(customerAccount, this.customer);
          if (customerAccount) {
            if (this.orderChanged) {
              this.patchCustomerAccount(customerAccount);
            } else {
              this.customerAccountFormComponent
                .generateForm(customerAccount)
                .pipe(first())
                .subscribe(() => {
                  this.patchCustomerAccount(customerAccount);
                });
            }
          }
        });
    }

    if (!this.customer?.subject?.subjectType) {
      this.customerFormComponent
        .generateForm(this.customer)
        .pipe(first())
        .subscribe(() => {
          this.patchCustomer(this.customer);
        });
    }
    if ((!this.isVerifiedCustomerRequired || this.customer) && !TicketService.getLocalOpportunity()) {
      // TP2-537 (last Richard's comment), TP2-593
      const customerAccountKnown = customerAccount && customerAccount.id;
      const customerFormDisabled =
        (this.customer.id && customerAccountKnown) ||
        (this.customer.id && !customerAccountKnown && !this.isVerifiedCustomerRequired);
      this.customerFormComponent.disable(customerFormDisabled, []);
      this.disableContactAccountFields();
    }

    if (this.simpleCustomer && !TicketService.getLocalOpportunity()) {
      this.customerFormComponent.disable(this.customer.id ? true : false, []);
    }
  }

  patchCustomer(customer) {
    if (!customer.subject) {
      customer.subject = {};
    }
    this.customerFormComponent.customerForm.patchValue(customer);
    this.customerFormComponent.disable(customer.id ? true : false, ['id']);
  }

  patchCustomerAccount(customerAccount) {
    this.customerAccountFormComponent.customerAccountForm.patchValue(
      CustomerPartyUtil.getFormCustomerAccount(customerAccount)
    );
    this.customerAccountFormComponent.customerAccountForm
      .get('allowBillingContact')
      .setValue(this.customerAccountFormComponent.billingContactFormComponent.allowContact);
    this.customerAccountFormComponent.customerAccountForm
      .get('allowInvoicingContact')
      .setValue(this.customerAccountFormComponent.invoicingContactFormComponent.allowContact);
    this.customerAccountFormComponent.disable(customerAccount.id ? true : false, ['id']);
  }

  updateOrderDtoAttr(orderAttributes: Array<OrderParamDto>, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.value) {
        const value = control.value;
        OrderUtils.updateOrderAttr(orderAttributes, field, value);
      } else if (control instanceof FormGroup) {
        let value;
        if (field === this.CUSTOMER) {
          value = this.customerFormComponent.customerForm.getRawValue();
          if (value?.subject?.subjectType) {
            if (!this.isVerifiedCustomerRequired && value.address && _.isEmpty(value.address.city)) {
              value.address = null;
            }
          } else if (value && !value?.subject?.subjectType) {
            value.subject = null;
            value.address = null;
          }

          if (value?.contact?.id && !this.customerLocalStorageService.getCurrentCustomer() && !this.simpleCustomer) {
            value.contact.id = null;
          }

          if (value?.contact && this.sameAs.hwInvoicingContactSameAsPrimary) {
            const contact = {};
            CustomerPartyUtil.copyWithExclude(value?.contact, contact, 'type', 'id');
            OrderUtils.updateOrderAttr(
              orderAttributes,
              DeliveryComponent.HW_INVOICING_CONTACT,
              JSON.stringify(contact)
            );
          }
          if (value?.contact && this.sameAs.hwInvoicingAddressSameAsRegistration) {
            const address = {};
            CustomerPartyUtil.copyWithExclude(value?.address, address, 'type', 'id');
            OrderUtils.updateOrderAttr(
              orderAttributes,
              DeliveryComponent.HW_INVOICING_ADDRESS,
              JSON.stringify(address)
            );
          }
          if (value?.contact && this.sameAs.deliveryContactSameAsPrimary) {
            const contact = {};
            CustomerPartyUtil.copyWithExclude(value?.contact, contact, 'type', 'id');
            OrderUtils.updateOrderAttr(orderAttributes, DeliveryComponent.DELIVERY_CONTACT, JSON.stringify(contact));
          }
          if (value?.contact && this.sameAs.deliveryAddressSameAsRegistration) {
            const address = {};
            CustomerPartyUtil.copyWithExclude(value?.address, address, 'type', 'id');
            OrderUtils.updateOrderAttr(orderAttributes, DeliveryComponent.DELIVERY_ADDRESS, JSON.stringify(address));
          }
        }
        if (field === this.CUSTOMER_ACCOUNT && !this.simpleCustomer) {
          value = this.customerAccountFormComponent.getFormCustomerAccount(this.customerFormComponent.customerForm.getRawValue());
        }
        OrderUtils.updateOrderAttr(orderAttributes, field, value === null ? null : JSON.stringify(value));
      }
    });
  }

  disableContactAccountFields() {
    if (
      this.customerFormComponent.contactFormComponent?.form &&
      this.account &&
      this.account.external &&
      !this.customerFormComponent.customerForm?.get('id').value
    ) {
      if (this.accountPrimaryContact) {
        this.customerFormComponent.contactFormComponent.disableForm();
      } else {
        this.customerFormComponent.contactFormComponent.form.get('firstName')?.disable();
        this.customerFormComponent.contactFormComponent.form.get('lastName')?.disable();
        this.customerFormComponent.contactFormComponent.form.get('language')?.disable();
        this.customerFormComponent.contactFormComponent.form.get('email')?.disable();
      }
    }
  }

  private fillCustomerContactFromAccount(customer) {
    if (this.accountPrimaryContact) {
      customer.contact = this.accountPrimaryContact;
      if (!this.simpleCustomer) {
        customer.contact.id = null;
      }
    } else if (this.account && this.account.external) {
      customer.contact.firstName = this.account.parameters.find(param => param.name === 'firstName')?.value;
      customer.contact.lastName = this.account.parameters.find(param => param.name === 'lastName')?.value;
      customer.contact.language = this.account.locale;
      customer.contact.email = this.account.login;
      if (
        this.hwInvoicingContactDtos &&
        this.hwInvoicingContactDtos.length > 0 &&
        this.hwInvoicingContactDtos[this.hwInvoicingContactDtos.length - 1].phone1
      ) {
        customer.contact.phone1 = this.hwInvoicingContactDtos[this.hwInvoicingContactDtos.length - 1].phone1;
      } else if (this.deliveryContactDtos && this.deliveryContactDtos.length > 0) {
        customer.contact.phone1 = this.deliveryContactDtos[this.deliveryContactDtos.length - 1].phone1;
      }
    }
  }

  public static ngbDateStructAsString(date: NgbDateStruct): String {
    if (date === null) {
      return null;
    }
    const d = moment_({
      year: date.year,
      month: date.month - 1,
      date: date.day,
    });
    return d.isValid() ? d.format('YYYY-MM-DD') : null;
  }

  public static parseToNgbDateStruct(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const d = moment_(value, moment_.ISO_8601);
    return d.isValid()
      ? {
          year: d.year(),
          month: d.month() + 1,
          day: d.date(),
        }
      : null;
  }

  hwScenario() {
    return this.shoppingCartService.preCalculatedShoppingCart.checkShipment();
  }

  formGenerated() {
    if (this.customerFormComponent.entityGenerateForm && this.customerAccountFormComponent.entityGenerateForm) {
      this.formGeneratedEmitter.emit();
    }
  }
}
