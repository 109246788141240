import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { UrlParamsService } from '@service/url-params.service';
import { CustomerService } from '@service/customer.service';
import { EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { CustomerDto } from '@btl/order-bff';

@Injectable()
@EnableDynamicLoading({ customName: EcareCustomerGuard.PAGE_ID })
export class EcareCustomerGuard  {
  public static readonly PAGE_ID = 'EcareCustomerGuard';

  pageId(): string {
    return EcareCustomerGuard.PAGE_ID;
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private urlParamsService: UrlParamsService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private customerService: CustomerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // check if customer identification present in URL - if yes, try to load the customer into the context
    const cuRefNo = this.urlParamsService.getCuRefNo(route.queryParams);

    if (cuRefNo) {
      let customerDto = this.customerLocalStorageService.getCurrentCustomer();
      if (!customerDto || cuRefNo != customerDto.id) {
        customerDto = await this.selectCustomer(cuRefNo);
      }
      if (customerDto) {
        const caRefNo = route.queryParams['caRefNo'];
        this.customerLocalStorageService.setCurrentCustomerWithLast(customerDto);
        if (caRefNo) {
          const customerAccount = this.customerLocalStorageService.getPartyByIdRecursive(customerDto.childParties, caRefNo);
          if (customerAccount) {
            this.customerLocalStorageService.setCurrentCustomerAccount(customerAccount);
          } else {
            console.warn(caRefNo + ' customer account was not found for customer ' + cuRefNo);
            this.router.navigate(['/ecare/search']);
          }
        }
        return true;
      } else {
        console.warn(cuRefNo + ' customer was not found');
        this.router.navigate(['/ecare/search']);
        return false;
      }
    }
    return true;
  }

  async selectCustomer(cuRefNo: string): Promise<CustomerDto> {
    return await this.customerService.getCustomer(cuRefNo, true).toPromise();
  }
}
