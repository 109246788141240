import { Component, Input, OnInit } from "@angular/core";
import { PriceTypeDto } from "@btl/order-bff";
import { PrecalculatedShoppingCart } from "../../../models/precalculated-shopping-cart";
import _ from "lodash";
import { ProductInShoppingCart } from "../../../models/product-in-shopping-cart";

@Component({
  selector: 'app-summary-product-row, [app-summary-product-row]',
  templateUrl: './summary-product-row.component.html',
})
export class SummaryProductRowComponent implements OnInit {
  PriceTypeDto = PriceTypeDto;

  /**
   * The product in the shopping cart to display.
   */
  @Input()
  productInShoppingCart: ProductInShoppingCart;

  @Input()
  showVat;

  priceType = '';

  delete;

  modify;

  product;

  childProducts: ProductInShoppingCart[] = [];

  ngOnInit() {
    this.loadProduct();
  }

  canDisplayProduct(product): boolean {
    return !_.isNil(product) && PrecalculatedShoppingCart.canDisplayProductInShoppingCart(product);
  }

  private loadProduct(): void {
    this.product = this.productInShoppingCart.productDetail;
    if (this.productInShoppingCart.children.length > 0) {
      this.findProductToDisplay(this.productInShoppingCart.children);
    }
    if (this.productInShoppingCart.rcPrice > 0) {
      this.priceType = PriceTypeDto.RC;
    } else {
      this.priceType = PriceTypeDto.OC;
    }
    this.delete = this.productInShoppingCart.deleteAction;
    this.modify = this.productInShoppingCart.modifyAction;
  }

  isHw(): boolean {
    return PrecalculatedShoppingCart.isProductWithShipment(this.productInShoppingCart);
  }

  findProductToDisplay(childrenProduct) {
    if (childrenProduct) {
      childrenProduct.forEach(product => {
        if (this.canDisplayProduct(product.productDetail)) {
          this.childProducts.push(product);
        } else {
          this.findProductToDisplay(product.children);
        }
      });
    }
  }

  hasProductPrice(product) {
    return product.rcPrice != null || product.ocPrice != null;
  }
}
