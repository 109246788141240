import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { OrderStateTypeEnum } from "../../../../models/orderStateTypeEnum";
import { NavigationTypeEnum } from "../navigation-menu.component";
import {
  CurrentLocaleService,
  ErrorService,
  GroupTreeNode,
  PageModule,
  PageModuleService,
  ProductGroupService,
  WishListService
} from "@btl/btl-fe-wc-common";
import { NavigationThirdMenuComponent } from "../../navigation-third-menu/navigation-third-menu.component";
import { NavigationMenuService } from "@service/navigation-menu.service";
import { CustomerLocalStorageService } from "@service/customer-local-storage.service";
import { CustomerAccountDto, CustomerDto, GroupDto, WishListDto } from '@btl/order-bff';
import { FiltersComponent } from "../../../wizard/product-listing/filters/filters.component";
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { FilterService } from "@service/filter.service";
import { Observable, of, Subject, Subscription } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProductElasticFilter } from "../../../../models/product-elastic-filter";
import { Constants } from "../../../../constants";
import { ProductsHolder } from "../../../../models/products-holder";
import { PropertyAccessorLocalService } from "@service/property-accessor-local.service";
import { ProductCustomService } from "@service/product-custom.service";

@Component({
  selector: 'app-customer-menu',
  templateUrl: './customer-menu.component.html',
})
export class CustomerMenuComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  private defaultGroupSubscription: Subscription;
  protected readonly NavigationTypeEnum = NavigationTypeEnum;
  private productGroupsTree: Array<GroupTreeNode>;
  public currentProductGroup: GroupTreeNode;
  public navigationGroupsTree: Array<GroupTreeNode>;
  private searchTerms = new Subject<string>();
  public autocompleteProducts: Observable<ProductsHolder[]>;
  public filter: ProductElasticFilter;
  customerMenuItems: Array<PageModule> = [];
  customersSearchFormGroup: FormGroup;
  private maxAutocompleteResultsSubscription: Subscription;
  private maxAutocompleteResults: number;

  orderStateTypeEnum = OrderStateTypeEnum;
  isSearchActive: boolean = false;
  toggleOthersMenu: boolean = false;

  @Input() showMobileMenu: boolean = false;

  @Input()
  preCalculatedShoppingCart;

  @Input()
  isContextLoaded: boolean;

  @Input()
  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;

  @Output() topMenuGroups = new EventEmitter<Array<GroupTreeNode>>();
  @Output() showMobileMenuChanged = new EventEmitter<boolean>();

  @ViewChild('mainNavigation') mainNavigation: TemplateRef<any>;
  @ViewChild('navigation') navigation: TemplateRef<any>;

  popupTimeout;
  showPopup: 'ShoppingCart' | 'WishList' | '';

  wishLists: Array<WishListDto>;

  currentLocale: string = this.currentLocaleService.getCurrentLanguage();
  selectedParty: CustomerDto | CustomerAccountDto;
  customer = null;
  showCustomerMenu = true;

  constructor(
    private navigationMenuService: NavigationMenuService,
    public customerLocalStorageService: CustomerLocalStorageService,
    private productGroupService: ProductGroupService,
    public router: Router,
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private productService: ProductCustomService,
    public pageModules: PageModuleService,
    private wishListService: WishListService,
    private currentLocaleService: CurrentLocaleService,
    private route: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
      this.navigationGroupsTree = null;
      this.productGroupsTree = null;
      this.loadGroups();
    });
  }

  ngOnInit(): void {
    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    this.navigationMenuService.getUserMenuItems().subscribe(menuItems => {
      this.customerMenuItems = menuItems.customerMenuItems;
    });

    this.customersSearchFormGroup = this.formBuilder.group({
      customerSearch: [''],
    });
    this.filter = this.filterService.getProductFilter();
    this.initProductsAutocomplete();
    this.loadGroups();

    this.wishListService.wishListChange.pipe(takeUntil(this.onDestroy$)).subscribe(wishList => {
      this.loadWishLists();
    });

    this.route?.firstChild.params.subscribe(params => {
      this.selectedParty = params['caRefNo']
        ? this.customerLocalStorageService.getPartyByIdRecursive(this.customer.childParties, params['caRefNo'])
        : this.customer;
    });

    if (this.customerLocalStorageService.getCurrentCustomer()) {
      this.showCustomerMenu = true;
    } else {
      this.showCustomerMenu = false;
    }

    this.fillSelectedParty();
    this.customerLocalStorageService.contextChangedCustomerAccount.subscribe(() => {
      this.fillSelectedParty();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private fillSelectedParty() {
    this.route.firstChild.params.subscribe(params => {
      const caParty = params['caRefNo']
        ? this.customerLocalStorageService.getPartyByIdRecursive(this.customer.childParties, params['caRefNo'])
        : this.customerLocalStorageService.getCurrentCustomerAccount();
      this.selectedParty = caParty ? caParty : this.customer;
    });
  }

  private loadGroups() {
    if (!this.errorService.loadingGroupsError) {
      this.productGroupService
        .getProductGroups()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          value => {
            if (this.productGroupsTree) {
              this.productGroupsTree.length = 0;
              value.forEach(p => this.productGroupsTree.push(p));
            } else {
              this.productGroupsTree = value;
            }

            this.topMenuGroups.emit(this.productGroupsTree);

            this.loadNavigation();
          },
          error => {
            console.error('Unable to load product groups', error);
            this.errorService.loadingGroupsError = true;
          }
        );
    }
  }

  private loadNavigation() {
    this.productGroupService
      .getCurrentProductGroup()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(groupTreeNode => {
        this.currentProductGroup = groupTreeNode;
        if (groupTreeNode) {
          while (groupTreeNode.group.parentId) {
            groupTreeNode = NavigationThirdMenuComponent.getProductGroupByGroupId(
              groupTreeNode.group.parentId,
              this.productGroupsTree
            );
          }
          if (this.navigationGroupsTree) {
            this.navigationGroupsTree.length = 0;
            if (groupTreeNode.children.length === 0) {
              groupTreeNode = NavigationThirdMenuComponent.getProductGroupByGroupId(
                groupTreeNode.group.id,
                this.productGroupsTree
              );
            }
            groupTreeNode.children.forEach(p => this.navigationGroupsTree.push(p));
          } else {
            this.navigationGroupsTree = groupTreeNode.children;
          }
          this.navigationMenuService.setEshopMenuItems(this.navigationGroupsTree);
        }
      });
  }

  isCurrentGroupOrParent(groupId) {
    if (!groupId || !this.currentProductGroup || !this.productGroupsTree) {
      return false;
    }
    if (this.currentProductGroup?.group.id === groupId || this.currentProductGroup?.group.parentId === groupId) {
      return true;
    }
    let productGroup = this.currentProductGroup;
    while (productGroup.group.parentId) {
      productGroup = NavigationThirdMenuComponent.getProductGroupByGroupId(
        productGroup.group.parentId,
        this.productGroupsTree
      );
      if (productGroup?.group.id === groupId || productGroup?.group.parentId === groupId) {
        return true;
      }
    }

    return false;
  }

  getCustomerMainMenuItems() {
    let menuItems = this.customerMenuItems.slice(0, 3);
    if (this.customerLocalStorageService.getCurrentCustomer().childParties.length === 0) {
      const settingsPage = this.customerMenuItems.find(pageModule => pageModule.code === 'SETTINGS');
      menuItems = menuItems.map(obj => {
        if (obj.code !== 'DASHBOARD') {
          return obj;
        } else {
          return settingsPage;
        }
      });
    }
    return menuItems;
  }

  getCustomerOtherMenuItems() {
    let menuItems = this.customerMenuItems.slice(3, this.customerMenuItems.length);
    const tasksPage = menuItems.find(pageModule => pageModule.code === 'TASKS');
    if (this.customerLocalStorageService.getCurrentCustomer().childParties.length === 0) {
      menuItems = menuItems.filter(pageModule => pageModule.code !== 'SETTINGS');
    }
    return menuItems;
  }

  openShoppingCartPopup() {
    this.navigationMenuService.openShoppingCartPopup.emit();
  }

  openWishListPopup() {
    clearTimeout(this.popupTimeout);
    this.showPopup = 'WishList';
  }

  getProductGroupNavigationUrl(navigationGroupNode: GroupTreeNode): string {
    return this.navigationMenuService.getProductGroupNavigationUrl(navigationGroupNode);
  }

  public getGroupName(group: GroupDto): string {
    return this.productGroupService.getGroupName(group);
  }

  /**
   * Called from GUI when search input changes
   * @param text
   */
  public searchChange(text: string) {
    this.searchTerms.next(text);
  }

  /**
   * Sets text filter in filtering service
   * @param text Text to set
   */
  public setFilteringText(text: string) {
    if (text && text.length >= FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH) {
      //this.cancelAllCheckFilters();
      this.router.navigate(['eshop/search', text]);
    }
    if (!text) {
      this.defaultGroupSubscription = this.productGroupService
        .getDefaultGroup()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(defaultGroup => {
          this.filterService.setText(null);
          this.router.navigate(['eshop/product-listing', defaultGroup.group.seoUrl]);
        });
    }
  }

  showSearchForm(searchForm: HTMLFormElement, searchInput: HTMLInputElement) {
    searchForm.hidden = false;
    searchInput.focus();
    this.isSearchActive = true;
    document.body.style.overflow = 'hidden';
  }

  closeSearchForm(searchForm: HTMLFormElement) {
    searchForm.hidden = true;
    this.isSearchActive = false;
    document.body.style.overflow = 'visible';
  }

  private initProductsAutocomplete() {
    this.maxAutocompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(maxAutocompleteResults => (this.maxAutocompleteResults = maxAutocompleteResults));
    this.autocompleteProducts = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<ProductsHolder[]>([]);
        } else {
          return this.productService.searchByText(term, this.maxAutocompleteResults);
        }
      })
    );
  }

  public autocompleteFocusOut(autocomplete: HTMLFormElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  closePopup() {
    this.popupTimeout = setTimeout(() => {
      this.showPopup = '';
    }, 350);
  }

  toggleShowMenu() {
    this.showMobileMenu = true;
    this.showMobileMenuChanged.emit(this.showMobileMenu);
  }

  loadWishLists() {
    this.wishListService
      .getWishLists(false)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishLists => (this.wishLists = wishLists));
  }

  getUrlParams() {
    const params = {};
    params['cuRefNo'] = this.customerLocalStorageService.getCurrentCustomer().id;
    if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
      params['caRefNo'] = this.customerLocalStorageService.getCurrentCustomerAccount().id;
    }

    return params;
  }
}
