import { Component, HostBinding } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuspendOrderComponent } from '../checkout-page/suspend-order/suspend-order.component';

@Component({
  selector: 'app-checkout-shopping-cart-secondary-actions',
  templateUrl: './checkout-shopping-cart-secondary-actions.component.html',
})
export class CheckoutShoppingCartSecondaryActionsComponent {
  @HostBinding('class') class = 'cart-secondary-actions';

  constructor(private ngbModal: NgbModal) {}
  suspendOrderPopup() {
    const modalRef = this.ngbModal.open(SuspendOrderComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });
    const suspendOrderComponent = <SuspendOrderComponent>modalRef.componentInstance;
    suspendOrderComponent.dialogRef = modalRef;
  }
}
