import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { GroupTreeNode, ProductGroupService } from '@btl/btl-fe-wc-common';

import { ProductCustomService } from '@service/product-custom.service';
import { FilterService } from '@service/filter.service';

@Component({
  selector: 'app-phone-model-select, [app-phone-model-select]',
  templateUrl: './phone-model-select.component.html',
  styleUrls: ['./phone-model-select.component.scss'],
})
export class PhoneModelSelectComponent implements OnInit, OnDestroy {
  public isAccessoryGroup: boolean;
  private groupSubscription: Subscription;
  private phoneBrands: Array<string>;
  private selectedPhoneBrand: string = null;
  private phoneBrandsSubscription: Subscription;
  private phoneModels: Array<string>;
  private phoneModelsSubscription: Subscription;
  private selectedPhoneModel: string;
  private productCodesForBrandAndModelSubscription: Subscription;

  constructor(
    private productGroupService: ProductGroupService,
    private productCustomService: ProductCustomService,
    private filterService: FilterService
  ) {}

  ngOnInit() {
    this.groupSubscription = combineLatest([
      this.productGroupService.getCurrentProductGroup(),
      this.productGroupService.getProductGroups(),
    ]).subscribe(([currentGroup, allProductGroups]: [GroupTreeNode, Array<GroupTreeNode>]) => {
      this.isAccessoryGroup = this.getIsAccessoryGroup(currentGroup, allProductGroups);
      if (!this.isAccessoryGroup) {
        this.selectedPhoneModel = undefined;
        this.selectedPhoneBrand = undefined;
        this.filterService.setIsAccessoryOf([]);
      }
      if (this.isAccessoryGroup && !this.phoneBrands) {
        this.phoneBrandsSubscription = this.productCustomService
          .getPhoneBrands()
          .subscribe(phoneBrands => (this.phoneBrands = phoneBrands));
      }
    });
  }

  ngOnDestroy(): void {
    this.groupSubscription.unsubscribe();
    if (this.phoneBrandsSubscription) {
      this.phoneBrandsSubscription.unsubscribe();
    }
    if (this.phoneModelsSubscription) {
      this.phoneModelsSubscription.unsubscribe();
    }
    if (this.productCodesForBrandAndModelSubscription) {
      this.productCodesForBrandAndModelSubscription.unsubscribe();
    }
  }

  private getIsAccessoryGroup(currentGroup: GroupTreeNode, allProductGroups: Array<GroupTreeNode>) {
    let isAccessoryGroup = false;
    if (currentGroup && currentGroup.group.groupParams['isAccessories']) {
      isAccessoryGroup = currentGroup.group.groupParams['isAccessories'] === 'true';
    } else if (currentGroup && currentGroup.group.parentId) {
      const productGroupById = this.productGroupService.getProductGroupById(
        allProductGroups,
        currentGroup.group.parentId
      );
      return this.getIsAccessoryGroup(productGroupById, allProductGroups);
    }
    return isAccessoryGroup;
  }

  private selectPhoneBrand(phoneBrand: string) {
    if (this.selectedPhoneBrand !== phoneBrand) {
      this.selectedPhoneBrand = phoneBrand;
      this.selectedPhoneModel = undefined;
      this.filterAccessories();
    }
    if (phoneBrand) {
      this.loadModels(phoneBrand);
    } else {
      this.phoneModels = null;
    }
  }

  private loadModels(phoneBrand: string) {
    if (this.phoneModelsSubscription) {
      this.phoneModelsSubscription.unsubscribe();
    }
    this.phoneModelsSubscription = this.productCustomService
      .getPhoneModelsForBrand(phoneBrand)
      .subscribe(models => (this.phoneModels = models));
  }

  private selectPhoneModel(phoneModel: string) {
    this.selectedPhoneModel = phoneModel;
    this.filterAccessories();
  }

  private filterAccessories() {
    if (this.productCodesForBrandAndModelSubscription) {
      this.productCodesForBrandAndModelSubscription.unsubscribe();
    }
    this.productCodesForBrandAndModelSubscription = this.productCustomService
      .getProductCodesForBrandAndModel(this.selectedPhoneBrand, this.selectedPhoneModel)
      .subscribe(productCodes => this.filterService.setIsAccessoryOf(productCodes));
  }
}
