import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AccountService,
  AuthFactoryService,
  CompareType,
  EnableDynamicLoading,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { CustomerService } from '@service/customer.service';
import { AccountDto, CustomerDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ecare-ext-homepage',
  templateUrl: './ecare-ext-homepage.component.html',
  styleUrls: ['./ecare-ext-homepage.component.scss'],
})
@EnableDynamicLoading({ customName: EcareExtHomepageComponent.PAGE_ID })
export class EcareExtHomepageComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareExtHomepageComponent';

  pageId(): string {
    return EcareExtHomepageComponent.PAGE_ID;
  }

  isModuleBffCrmEnabled = false;
  createCustomer = false;
  account: AccountDto;
  customers: Array<CustomerDto>;
  constantOrderFilter = [];
  ticketSearch = null;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private accountService: AccountService,
    private authFactoryService: AuthFactoryService,
    private customerService: CustomerService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
  }

  ngOnInit() {
    if (this.authFactoryService.getAuthService().account) {
      this.getCustomers(this.authFactoryService.getAuthService().account);
    }
    this.authFactoryService
      .getAuthService()
      .accountChange.pipe(takeUntil(this.onDestroy$))
      .subscribe(account => {
        this.getCustomers(account);
      });
  }

  getCustomers(account: AccountDto) {
    this.account = account;
    if (this.account) {
      this.ticketSearch = {
        filtering: [
          {
            column: 'userTickets',
            value: this.account.login,
          }
        ],
        sorting: [{ column: 'startFrom', sortOrder: 'desc' }],
        paging: {
          page: 1,
          pageSize: 20,
        }
      };
      this.constantOrderFilter = [
        {
          column: 'orderType',
          compareType: 'EQUAL',
          value: 'SALES',
        },
        {
          column: 'orderCalcStateType',
        compareType: CompareTypeDtoEnum.DIFFERENT,
        value: 'EMPTY',
      },
      {
        column: 'creator',
          compareType: 'EQUAL',
          value: this.account.login,
        },
      ];
    }
    this.propertyAccessorLocalService.isModuleBffCrmEnabled().subscribe(isModuleBffCrmEnabled => {
      this.isModuleBffCrmEnabled = isModuleBffCrmEnabled;
      if (isModuleBffCrmEnabled) {
        this.customerService.getCustomers(ServiceUtils.getUnlimitedSearch()).subscribe(pagedCustomers => {
          if (pagedCustomers.data.length > 0) {
            this.customers = [];
            pagedCustomers.data.forEach(customer => {
              this.customers.push(customer);
            });
          }
        });
      }
    });
  }
}
