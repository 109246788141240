<div class="checkout-page -technologyCheck">
  <!-- Generate content for mobile -->
  <div class="d-lg-none">
    <app-checkout-shopping-cart-secondary-actions />
    <app-ordering-wizard />
  </div>
  <!-- END Generate content for mobile -->
  <div class="checkout-page_form technologyCheck__page">
    <!-- section -->
    <div class="section">
      <div class="inner">
        <h4 id="001_technology_check">
          {{ 'wc.shopping.gui_technologyScan.address.header' | translate }}
        </h4>

        <form *blockUI="blockUIElement.name; template: blockTemplate" class="form" [formGroup]="technologyForm">
          <ng-container *ngIf="fillInputs; else technologyResult">
            <div class="technologyCheck__search">
              <!-- TODO - missing GD -->
              <div class="technologyCheck__wrapper">
                <i
                  id="019_navigation_menu"
                  class="primary-menu__icon primary-menu__icon-search"
                  [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
                <input
                  #addressSearch
                  type="text"
                  id="searchMode"
                  autocomplete="off"
                  formControlName="address"
                  [placeholder]="'Address...'"
                  [value]="filter?.attributes?.text ? filter?.attributes?.text : ''"
                  (input)="searchChange(addressSearch.value)"
                  (keyup.enter)="autocomplete.hidden = true"
                  (focusout)="autocompleteFocusOut(autocomplete)"
                  (focusin)="autocomplete.hidden = false" />
                <div class="col-sm-2"></div>
                <div #autocomplete class="technologyCheck__autocomplete">
                  <div *ngFor="let address of autocompleteProducts | async; let i = index" class="autocomplete-items">
                    <a href="javascript:void(0)" id="{{ i }}_005_delivery" (click)="selectAddress(address)">
                      <span
                        [innerHTML]="
                          address._source
                            | displayAddress : addressTypes.TECHNOLOGY_CHECK
                            | highlight : addressSearch.value
                        "></span>
                    </a>
                  </div>
                </div>
                <app-input-errors
                  [control]="technologyForm.controls['address']"
                  [translationPrefix]="'wc.shopping.technologyCheck.address.wrong'" />
              </div>
            </div>

            <!-- Technology check item -->
            <div class="technologyCheck__page-item">
              <input type="checkbox" id="locationLabel" formControlName="locationCheckBox" (change)="noLocation()" />
              <label for="locationLabel">
                {{ 'wc.shopping.gui_technologyScan.locations.label' | translate }}
              </label>

              <!-- location input -->
              <div class="form-group-row" [hidden]="!location">
                <div class="input select">
                  <select
                    name="location"
                    id="location"
                    class="form-control select"
                    formControlName="location"
                    (change)="getSelectedLocation()">
                    <option *ngFor="let location of locationsOptions" [value]="location">
                      {{ location?.floor }}
                    </option>
                  </select>
                  <app-input-errors
                    [control]="technologyForm.controls['location']"
                    [translationPrefix]="'wc.shopping.technologyCheck.select.required'" />
                </div>
              </div>
            </div>

            <!-- Technology check item -->
            <div class="technologyCheck__page-item">
              <input type="checkbox" id="socketLabel" formControlName="socketCheckBox" (change)="noSocket()" />
              <label for="socketLabel">
                {{ 'wc.shopping.gui_technologyScan.sockets.label' | translate }}
              </label>

              <!-- Socket -->
              <div class="form-group-row" [hidden]="!socket">
                <div class="input select">
                  <select name="socket" id="socket" class="form-control select" formControlName="socket">
                    <option *ngFor="let socket of socketOptions" [value]="socket">
                      {{ socket.socketRefNo }}
                    </option>
                  </select>
                  <app-input-errors
                    [control]="technologyForm.controls['socket']"
                    [translationPrefix]="'wc.shopping.technologyCheck.select.required'" />
                </div>
              </div>
            </div>

            <div app-acl-internal-evaluate-component>
              <div class="technologyCheck__page-item">
                <input type="checkbox" id="cacheLabel" />
                <label for="cacheLabel">
                  {{ 'wc.shopping.gui_technologyScan.useCache.label' | translate }}
                </label>
              </div>
            </div>
          </ng-container>

          <ng-template #technologyResult>
            <!-- list review -->
            <div *ngIf="serviceNotAvailable" class="technologyCheck-failed">
              <!-- Technology check failed -->

              <!-- Fail message -->
              <div class="alert alert-danger">
                {{ 'wc.shopping.gui_technologyScan.serviceNotAvavilable.label' | translate }}
              </div>

              <div>
                <!-- -->
                <div class="form-group-row">
                  <label for="address" title="address">
                    {{ 'wc.shopping.gui_technologyScan.selectedAddress.label' | translate }}
                  </label>
                  <div class="input">
                    <input
                      disabled
                      class="form-control"
                      type="text"
                      id="address"
                      [value]="
                        selectedAddress
                          ? selectedAddress.street + ' ' + selectedAddress.streetNo + ', ' + selectedAddress.city
                          : ('wc.common.choose.nothing' | translate)
                      " />
                  </div>
                </div>

                <!-- Selected location -->
                <div class="form-group-row">
                  <label for="location" title="location">
                    {{ 'wc.shopping.gui_technologyScan.selectedLocation.label' | translate }}
                  </label>
                  <div class="input">
                    <input
                      disabled
                      class="form-control"
                      type="text"
                      id="locationInput"
                      [value]="selectedLocation ? selectedLocation.floor : ('wc.common.choose.nothing' | translate)" />
                  </div>
                </div>

                <!-- selected socket -->
                <div class="form-group-row">
                  <label for="socketInput" title="socketInput">
                    {{ 'wc.shopping.gui_technologyScan.selectedSocket.label' | translate }}
                  </label>
                  <div class="input">
                    <input
                      disabled
                      class="form-control"
                      type="text"
                      id="socketInput"
                      [value]="
                        selectedSocket ? selectedSocket.socketRefNo : ('wc.common.choose.nothing' | translate)
                      " />
                  </div>
                </div>
              </div>
              <div app-acl-internal-evaluate-component>
                <div *ngIf="scanResult" class="form-group-row">
                  <label title="socketInput">
                    {{ 'wc.shopping.gui_technologyScan.availableTechnologies.label' | translate }}
                  </label>
                  <div class="input">
                    <!--TODO: make it like LT ?-->
                    <select id="availableTechnologies" class="form-control" disabled multiple>
                      <ng-container *ngFor="let technologiesResponse of scanResult.availableTechnologies">
                        <option
                          [innerHTML]="
                            technologiesResponse.technology +
                            ' ' +
                            ('wc.shopping.gui_technologyScan.speedInfo.text'
                              | translate
                                : {
                                    '0': technologiesResponse.maxSpeed,
                                    '1': technologiesResponse.maxSpeedUp
                                  }) +
                            ' ' +
                            (technologiesResponse.availableFrom
                              ? ('wc.shopping.gui_technologyScan.plannedAvailability.text'
                                | translate
                                  : {
                                      '0': technologiesResponse.availableFrom
                                    })
                              : '')
                          "></option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <div class="buttons clearfix">
                <app-button
                  class="pull-right"
                  variant="secondary"
                  [label]="'wc.common.changeAddress.button' | translate"
                  (click)="back()" />
              </div>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
  <!-- Right side of checkout - shopping cart -->
  <app-checkout-shopping-cart [alwaysShowNavigationButtons]="true" [checkFormAction]="continue.bind(this)" />
</div>
