<div *ngIf="extUserAuthMode" class="checkout-page {{!childMode ? '': 'no-cart'}}">
  <!-- Generate content for mobile -->
  <div class="d-lg-none" *ngIf="!childMode">
    <app-checkout-shopping-cart-secondary-actions></app-checkout-shopping-cart-secondary-actions>
    <app-ordering-wizard></app-ordering-wizard>
  </div>
  <!-- END Generate content for mobile -->

  <!-- Left side of checkout - form -->
  <div
    [ngClass]="
      wcOrderingService.currentOrder?.scenarioType === scenarioTypeEnum.PRP_REGISTRATION
        ? 'checkout-page_registration'
        : 'checkout-page_form'
    ">
    <ng-container *ngIf="extUserAuthMode && !(account === undefined) && wcOrderingService.currentOrder">
      <!-- remove unnecessary registration checkbox 👇 -->
      <div
        *ngIf="
          !isVerifiedCustomerRequired &&
          !isCustomerSelected() &&
          ((account && !account.external) ||
            extUserAuthMode === 'AUTH_ANONYMOUS' ||
            (extUserAuthMode === 'AUTH_REQUIRED' && account))
        "
        class="checkbox"
        [hidden]="extUserAuthMode === 'AUTH_REQUIRED' && account && account.external">
        <div class="form-radio">
          <input id="simpleCustomer" type="checkbox" [checked]="simpleCustomer" [(ngModel)]="simpleCustomer" />
          <label for="simpleCustomer">
            {{ 'wc.shopping.checkout.simple.registration.label' | translate }}
          </label>
        </div>
      </div>
      <div *ngIf="extUserAuthMode === 'AUTH_REQUIRED' && !account">
        <app-checkout-account-selection></app-checkout-account-selection>
      </div>
      <hr *ngIf="extUserAuthMode === 'AUTH_REQUIRED' && !account && showCreateCustomer()" class="checkout-separator" />
      <!--<app-look-for-customer></app-look-for-customer>-->
      <app-create-customer
        *ngIf="showCreateCustomer()"
        #createCustomer
        [checkSameAs]="!childMode"
        [simpleCustomer]="simpleCustomer"
        [accountPrimaryContact]="accountPrimaryContact"
        (formGeneratedEmitter)="formGeneratedEmitter.emit()"
        [account]="account">
      </app-create-customer>
    </ng-container>
  </div>

  <!-- Right side of checkout - shopping cart -->
  <app-checkout-shopping-cart
    *ngIf="!childMode"
    [checkFormAction]="confirmCustomerData.bind(this)"
    [currentOrderDto]="wcOrderingService.currentOrder"
    [disableContinueButton]="extUserAuthMode === 'AUTH_REQUIRED' && !account"></app-checkout-shopping-cart>
</div>
