import { PriceDto, PriceTypeDto } from '@btl/order-bff';
import { AbstractPriceComponent } from './abstract-price.component';
import { Component } from '@angular/core';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { TranslateService } from '@ngx-translate/core';
import { ProductCustomService } from '@service/product-custom.service';

/**
 * {@code OneTimePriceComponent} is a component responsible displaying one-time price. The source of the value can be
 * a product or a price itself.
 */
@Component({
  selector: 'one-time-price',
  templateUrl: './one-time-price.component.html',
})
export class OneTimePriceComponent extends AbstractPriceComponent {
  constructor(
    protected currentLocaleService: CurrentLocaleService,
    protected translateService: TranslateService,
    protected productCustomService: ProductCustomService
  ) {
    super(currentLocaleService, translateService, productCustomService);
  }

  //region Helpers:

  protected getPriceWrapper(): PriceDto {
    return this._product.prices[PriceTypeDto.OC];
  }
}
