import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupTreeNode, ProductGroupService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-product-listing, [app-product-listing]',
  templateUrl: './product-listing.component.html',
})
export class ProductListingComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  public currentProductGroup: GroupTreeNode;
  public isTariffListing: boolean = false;

  get isSmartCityOffer(): boolean {
    return (
      this.currentProductGroup && this.currentProductGroup.group.groupParams.guiDisplayType.startsWith('OFFER_SCITY')
    );
  }

  constructor(
    private productGroupService: ProductGroupService,
  ) {}

  ngOnInit() {
    this.productGroupService
      .getCurrentProductGroup()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(currentGroupTreeNode => {
        this.setListingType(currentGroupTreeNode);
        this.currentProductGroup = currentGroupTreeNode;
      });
  }

  clearCurrentProductGroup(){
    this.productGroupService.setCurrentProductGroup(null);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
  setListingType(currentGroup: GroupTreeNode) {
    this.isTariffListing = currentGroup && currentGroup.group.groupParams.guiDisplayType.startsWith('OFFER');
  }
}

interface SubgroupHeightInfo {
  id: string;
  height: number;
  parentCenter: number;
}
