import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { addressTypes, DisplayAddressPipe } from '../../pipes/display-address.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormPickUpPointModalComponent } from './form-pick-up-point-modal/form-pick-up-point-modal.component';

@Component({
  selector: 'app-form-pick-up-point-input-with-modal',
  templateUrl: './form-pick-up-point-input-with-modal.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPickUpPointInputWithModalComponent),
      multi: true,
    },
  ],
})
export class FormPickUpPointInputWithModalComponent implements ControlValueAccessor, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  addressTypes = addressTypes;

  @Input()
  parentGroup: FormGroup;

  @Input()
  controlName;

  @Input()
  label: string;

  @Input()
  provider;

  value;

  constructor(public ngbModal: NgbModal, public displayAddress: DisplayAddressPipe) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  getValue() {
    return this.value;
  }

  select() {
    const modalRef = this.ngbModal.open(FormPickUpPointModalComponent, { windowClass: 'pick-up-point-modal' });
    const addRecipientModalComponent = <FormPickUpPointModalComponent>modalRef.componentInstance;
    addRecipientModalComponent.dialogRef = modalRef;
    addRecipientModalComponent.provider = this.provider;
    addRecipientModalComponent.value = this.getValue();
    addRecipientModalComponent.selectHandler = pickupPoint => {
      this.value = this.displayAddress.transform(pickupPoint, addressTypes.PICK_UP_POINT);
      this.parentGroup.controls[this.controlName].patchValue(this.value);
      modalRef.close();
    };
  }
}
