import { EventEmitter, Injectable } from '@angular/core';

export enum StickyBarName {
  PRODUCT_COMPARISON = 'product-comparison',
  PRODUCT_DETAIL_PRICE_BAR = 'product-detail-price-bar',
}

@Injectable({
  providedIn: 'root',
})
export class StickyBarService {
  private stickyBarsHeights: Map<StickyBarName, number> = new Map<StickyBarName, number>();

  readonly stickyBarMaxHeightChanged: EventEmitter<void> = new EventEmitter<void>();

  public setStickyBarHeight(stickyBarName: StickyBarName, stickyBarHeight: number) {
    if (stickyBarHeight <= 0) {
      this.stickyBarsHeights.delete(stickyBarName);
    } else {
      this.stickyBarsHeights.set(stickyBarName, stickyBarHeight);
    }
    this.stickyBarMaxHeightChanged.emit();
  }

  public getStickyBarMaxHeight(): number {
    if (!this.stickyBarsHeights.size) {
      return 0;
    }
    return Math.max(...this.stickyBarsHeights.values());
  }
}
