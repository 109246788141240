import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ComparedProduct } from '../../../../models/compared-product';
import { ProductComparisonService } from '@service/product-comparison.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StickyBarName, StickyBarService } from '@service/sticky-bar.service';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'app-compared-products-bar',
  templateUrl: './compared-products-bar.component.html',
})
export class ComparedProductsBarComponent implements OnInit {
  comparedProducts: ComparedProduct[];

  @ViewChild('stickyBarContent') stickyBarContent: ElementRef;

  constructor(
    private productComparisonService: ProductComparisonService,
    private _DomSanitizer: DomSanitizer,
    private router: Router,
    private stickyBarService: StickyBarService
  ) {}

  ngOnInit() {
    this.comparedProducts = this.productComparisonService.getComparisonList();
    this.handleStickyBarHeight();

    this.productComparisonService.comparisonChange.subscribe(() => {
      this.comparedProducts = this.productComparisonService.getComparisonList();
      this.handleStickyBarHeight();
    });
  }

  removeAllFromComparison() {
    this.productComparisonService.removeAllFromComparison();
    this.ngOnInit();
  }

  removeFromComparison(comparedProductSeoUrl: string) {
    this.productComparisonService.removeFromComparison(comparedProductSeoUrl);
    this.ngOnInit();
  }

  getComparisonLink(comparedProducts: ComparedProduct[]) {
    const seoUrls: string[] = [this.productComparisonService.COMPARISON_PAGE_BASE_PATH];
    for (const comparedProduct of comparedProducts) {
      seoUrls.push(comparedProduct.seoUrl);
    }
    return this.router.createUrlTree(seoUrls).toString();
  }

  imgStateChange(state: StateChange) {
    if (state.reason === 'finally') {
      this.handleStickyBarHeight();
    }
  }

  private handleStickyBarHeight() {
    let stickyBarHeight = 0;
    if (this.comparedProducts?.length && this.stickyBarContent) {
      stickyBarHeight = this.stickyBarContent.nativeElement.offsetHeight;
    }
    this.stickyBarService.setStickyBarHeight(StickyBarName.PRODUCT_COMPARISON, stickyBarHeight);
  }
}
