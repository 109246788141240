import { Component } from '@angular/core';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ecare-user-account-skills',
  templateUrl: './ecare-user-account-skills.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountSkillsComponent.PAGE_ID })
export class EcareUserAccountSkillsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareUserAccountSkillsComponent';

  pageId(): string {
    return EcareUserAccountSkillsComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }
}
