<div class="template-container" [class.checkout]="checkout"  class="{{ shrinkCssClass }}" [ngStyle]="{ 'top.px': offsetTop }">
  <app-navigation-menu #navigationMenu></app-navigation-menu>
  <div [ngClass]="containerClass"><router-outlet></router-outlet></div>

  <div class="sticky-error">
    <ng-container *ngFor="let stickyMessage of stickyMessageService.stickyMessages; let i = index">
      <app-sticky-message *ngIf="stickyMessage" [index]="i" [stickyMessage]="stickyMessage"> </app-sticky-message>
    </ng-container>
  </div>

  <div
    *ngIf="eshop && cmbEnabled"
    class="fixed-callMeBack d-print-none"
    [ngClass]="{ moveUp: isMluvii() }"
    [ngStyle]="{ bottom: activeCompareBar ? '90px' : '0' }">
    <div class="callMeButton">
      <div class="callMeButtonBubble">
        <div class="call-me-button">
          <ng-container *ngIf="!ngbModal.hasOpenModals(); else closeModal">
            <i class="call-me-button-full-opacity" [inlineSVG]="'/assets/svg/callPhone.svg'" (click)="callMeBack()"></i>
          </ng-container>
          <ng-template #closeModal>
            <i
              class="call-me-button-full-opacity"
              [inlineSVG]="'/assets/svg/closeModal.svg'"
              (click)="ngbModal.dismissAll()"></i>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div app-sticky-bar class="sticky-content sticky-content--bottom" [compareBarOpen]="compareBarOpen"></div>
  <app-footer class="mt-auto"></app-footer>
</div>
