import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-to-clipboard, [app-copy-to-clipboard]',
  templateUrl: './copy-to-clipboard.component.html',
})
export class CopyToClipboardComponent {
  isCopiedOrderId: boolean = false;
  @Input() value: string;

  copyOrderId(): void {
    this.isCopiedOrderId = true;
  }
}
