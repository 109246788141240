import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DynamicEnumService, TicketDto, TicketNoteDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { TicketEditComponent } from '../edit/ticket-edit.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ticket-notes-form',
  templateUrl: './ticket-notes-form.component.html'
})
export class TicketNotesFormComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() ticket: TicketDto;
  @Input() showTicketType = false;
  @Output() readonly notesChanged = new EventEmitter();

  ticketNoteTypes = [];
  noteEmptyError: boolean;
  editedNote: TicketNoteDto;

  constructor(private dynamicEnumService: DynamicEnumService) {
  }

  ngOnInit() {
    if (this.showTicketType) {
      this.dynamicEnumService
        .getEnumEntries(TicketEditComponent.SOURCE_NAME, TicketEditComponent.TICKET_NOTE_TYPE_ENUM_NAME)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.ticketNoteTypes = result.data.map(value => value.name).sort();
        });
    }
  }

  editNote(note: TicketNoteDto) {
    this.editedNote = note;
  }

  deleteNote(note: TicketNoteDto) {
    this.ticket.notes = this.ticket.notes.filter(filterNote => filterNote !== note);
    this.notesChanged.emit();
  }

  confirmNote(note: TicketNoteDto, noteEdit: string, noteType: string) {
    if (!noteEdit || noteEdit === '') {
      this.noteEmptyError = true;
    } else {
      this.noteEmptyError = false;
      this.ticket.notes.forEach(loopNote => {
        if (loopNote === note) {
          delete loopNote['new'];
          loopNote.note = noteEdit;
          if (this.showTicketType) {
            loopNote.noteType = noteType;
          }

          if (!loopNote.modified) {
            loopNote.modified = new Date();
          }
        }
      });
      this.notesChanged.emit();
      this.editedNote = null;
    }
  }

  cancelEditNote(note: TicketNoteDto, noteField, typeField) {
    if (note['new']) {
      this.ticket.notes = this.ticket.notes.filter(filterNote => filterNote !== note);
    } else {
      noteField.value = note.note;
      typeField.value = note?.noteType;
    }

    this.editedNote = null;
    this.noteEmptyError = false;
  }

  addNote() {
    if (!this.editedNote) {
      this.editedNote = {
        note: '',
        noteType: 'GENERAL'
      };

      this.editedNote['new'] = true;
      this.ticket.notes.push(this.editedNote);
    }
  }

  checkNotEmptyNote(value: any) {
    this.noteEmptyError = !value || value === '';
  }
}
