<ng-container *ngIf="activeSearchEntity !== 'orderSearch'; else notOrderSearch">
  <div *blockUI="blockUIElement.name; template: blockTemplate">
    <ng-container *ngTemplateOutlet="ecareSearchFilter" />
  </div>
</ng-container>
<ng-template #notOrderSearch>
  <ng-container *ngTemplateOutlet="ecareSearchFilter" />
</ng-template>
<ng-template #ecareSearchFilter>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter -search-entity" [formGroup]="entitySearchForm">
      <div class="-filter-fields -filter-fields-group -search-entity">
        <ng-container>
          <ng-container *ngIf="searchEntityTypes.length > 1; else singleOption">
            <ng-select
              id="001a_ecare_search"
              class="ecare-dropdown -search-entity"
              formControlName="searchEntity"
              [readonly]="searchEntityTypes.length === 1"
              [clearable]="false"
              [clearOnBackspace]="false"
              [searchable]="false"
              (change)="changeSearchEntity($event)"
              (open)="toggleEntitySelection = true"
              (close)="toggleEntitySelection = false">
              <ng-option *ngFor="let searchEntity of searchEntityTypes" [value]="searchEntity">
                <i
                  class="search-entity-icon"
                  [inlineSVG]="'/assets/svg/' + getSearchEntityIcon(searchEntity) + '.svg'"></i>
                {{ 'wc.ecare.search.' + searchEntity + '.title' | translate }}
              </ng-option>
            </ng-select></ng-container
          >
        </ng-container>
        <ng-template #singleOption>
          <input
            class="ecare-dropdown -search-entity single"
            formControlName="searchEntity"
            readonly
            [name]="searchEntityTypes[0]"
            value="{{ 'wc.ecare.search.' + searchEntityTypes[0] + '.title' | translate }}" />
        </ng-template>
        <div class="search-input-field-wrapper">
          <div class="breaker"></div>
          <div class="search-input-field-wrapper">
            <div class="breaker"></div>
            <input
              id="001b_ecare_search"
              class="filter-input-id"
              formControlName="searchValue"
              type="text"
              autocomplete="off"
              placeholder="{{
                (activeSearchEntity === 'orderSearch'
                  ? 'wc.ecare.ordersFilter.id.placeholder'
                  : 'wc.ecare.search.' + activeSearchEntity + '.placeholder'
                ) | translate
              }}" />
          </div>
        </div>
      </div>
      <app-orders-filter
        *ngIf="activeSearchEntity === 'orderSearch'"
        [ecareSearch]="true"
        [filtering]="orderSearch.filtering"
        [filterOrderIdChangedEmitter]="filterOrderIdChangedEmitter"
        (filterChanged)="orderFilterChanged()" />
      <app-button
        *ngIf="!(activeSearchEntity === 'orderSearch')"
        iconPath="/assets/svg/search-icon.svg"
        variant="primary"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="doSearch()" />
    </form>
    <div *ngIf="activeSearchEntity === 'customerSearch'" class="searchLast">
      <span class="-label">{{ 'wc.ecare.search.lastVisitedCustomers.label' | translate }}:</span>
      <div
        *ngFor="let storageCustomerDto of lastVisitedCustomers; let i = index"
        class="dashboard_chip-item -chip-with-hover searchLast-item">
        <div
          *ngIf="storageCustomerDto"
          class="searchLast-item-content"
          id="{{ i }}_004_ecare_search"
          (click)="selectCustomer(storageCustomerDto.id)">
          {{
            (storageCustomerDto.firstName ? storageCustomerDto.firstName + ' ' : '') + storageCustomerDto.lastName
              ? storageCustomerDto.lastName
              : ''
          }}
        </div>
      </div>
    </div>
    <div class="overflow-y-auto">
      <app-ecare-orders
        *ngIf="activeSearchEntity === 'orderSearch'"
        [isModuleBffCrmEnabled]="isModuleBffCrmEnabled"
        [maxThumbnailsToShow]="isHomepage ? 2 : 4"
        [searchChangedEmitter]="searchChangedEmitter"
        [cacheFilterKey]="orderCacheFilterKey"
        [showStatusBar]="authFactoryService.getAuthService().account?.external"
        [ecareSearch]="true" />
    </div>
  </div>
</ng-template>
