import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtmlPipe } from '@btl/btl-fe-wc-common';

/**
 * Pipe that highlight parameter in given text
 */
@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private safeHtml: SafeHtmlPipe) {}

  /**
   * Find {@code highlight} in @{code text} and return the text with {@code highlight} amrked
   * @param text Text to search
   * @param highlight Text to find and highlight
   */
  transform(text: string, highlight: string): any {
    const re = new RegExp(highlight, 'gi');
    const match = text.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return text;
    }

    const replacedValue = text.replace(re, `<span class="searched-value">${match[0]}</span>`);
    return this.safeHtml.transform(replacedValue);
  }
}
