import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountContactsService, CompareType } from '@btl/btl-fe-wc-common';
import { AccountContactDto, AccountDto, ContactDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/internal/Subject';
import { ContactEditPopupComponent } from '../contact-edit-popup/contact-edit-popup.component';

@Component({
  selector: 'app-user-contacts',
  templateUrl: './user-contacts.component.html',
})
export class UserContactsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input()
  account: AccountDto;

  @Input()
  contactType;

  contactDtos: AccountContactDto[];

  constructor(private accountContactsService: AccountContactsService, public ngbModal: NgbModal) {}

  ngOnInit() {
    this.getContacts();
  }

  getContacts() {
    if (this.account) {
      this.accountContactsService
        .getAccountContactsByType(this.contactType, this.account.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => (this.contactDtos = result.data));
    }
  }

  addContact() {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.accountId = this.account.id;
    contactEditPopupComponent.contact = null;
    contactEditPopupComponent.contactType = this.contactType;
    contactEditPopupComponent.handler = contact => {
      this.getContacts();
      modalRef.close();
    };
  }

  remove(contact: ContactDto) {
    this.accountContactsService
      .deleteContact(this.account.id, contact.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => this.getContacts());
  }

  edit(contact: ContactDto) {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.accountId = this.account.id;
    contactEditPopupComponent.contact = contact;
    contactEditPopupComponent.handler = () => {
      this.getContacts();
      modalRef.close();
    };
  }
}
