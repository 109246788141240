import {Component, OnInit} from '@angular/core';
import { AbstractPageComponent, AuthFactoryService, AuthService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ecare-user-account-acl',
  templateUrl: './ecare-user-account-acl.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountAclComponent.PAGE_ID })
export class EcareUserAccountAclComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountAclComponent';

  pageId(): string {
    return EcareUserAccountAclComponent.PAGE_ID;
  }

  authService: AuthService;

  selectedUserRole: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService
  ) {
    super(router, route);
    this.authService = this.authFactoryService.getAuthService();
  }

  ngOnInit(): void {
    const userRoles = this.authService.getUserRoles(true);
    if (userRoles && userRoles.length) {
      for (let userRole of userRoles) {
        if (this.isSelectedRole(userRole)) {
          this.selectedUserRole = userRole;
        }
      }
    }
  }

  get userRoles(): string[] {
    return this.authService.getUserRoles(true);
  }

  isSelectedRole(roleName: string): boolean {
    // todo: fix this fake implementation
    return !!roleName?.match(/^[A-Z\d_]*$/g);
  }
}
