<div class="modal-header">
  <h4 class="modal-title">
    <i [inlineSVG]="'/assets/svg/awesome-cookie-bite.svg'"></i>
    {{ 'wc.shopping.cookiePolicy.cookieBar.settings.header' | translate }}
  </h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="cookie-modal-description">
    {{ 'wc.shopping.cookiePolicy.cookieBar.text' | translate }}
  </div>
  <ng-container *ngIf="availableCookieAgreements">
    <div *ngFor="let agreement of availableCookieAgreements">
      <div class="cookie-modal-option">
        <div class="cookie-modal-option-title">
          {{ getAgreementLabel(agreement) }}
        </div>
        <div class="cookie-modal-option-checkbox">
          <app-toggle-switch
            style="display: inline-flex"
            [checked]="isAgreementAgreed(agreement)"
            [disabled]="agreement.mandatory"
            (change)="switchAgreement(agreement, !isAgreementAgreed(agreement))"></app-toggle-switch>
        </div>
      </div>
      <div class="cookie-modal-description">
        {{ getAgreementDescription(agreement) }}
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <app-button
    size="shrink"
    variant="secondary"
    label="{{ 'wc.shopping.cookiePolicy.cookieBar.button.allowAll' | translate }}"
    (click)="allowAllCookies()"></app-button>
  <app-button
    size="shrink"
    variant="primary"
    label="{{ 'wc.shopping.cookiePolicy.cookieBar.button.confirmChoice' | translate }}"
    (click)="allowAllCookies()"></app-button>
</div>
