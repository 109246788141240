<form *ngIf="formGenerated" class="generated-form" [formGroup]="form">
  <div *ngIf="customer && showCustomerData" class="primaryContact-box">
    <div class="primaryContact-box_leftSide">
      <div class="icon-container">
        <i [inlineSVG]="'assets/svg/login-benefits/manage-account.svg'"></i>
      </div>
      <div class="primaryContact-box_text">
        <div class="fullName">{{ customer.contact.firstName }} {{ customer.contact.lastName }}</div>
        <div>
          {{ customer.contact.email }}
        </div>
      </div>
    </div>
    <i
      placement="bottom"
      ngbTooltip="{{ 'wc.shopping.form.primaryContact.info.tooltip' | translate }}"
      [inlineSVG]="'assets/svg/awesome-info-circle.svg'"></i>
  </div>
  <ng-container *ngFor="let formAttribute of formAttributes">
    <div
      *ngIf="formAttribute.metaParameters['isInternal'] === 'true'"
      app-acl-internal-evaluate-component
      class="generated-input-container"
      [ngClass]="generateColumn(formAttribute.name)"
      [hidden]="formAttribute.metaParameters['guiVisible'] !== 'true' || hide">
      <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>
    </div>

    <div
      *ngIf="formAttribute.metaParameters['isInternal'] !== 'true' && !generatePrimaryContact(formAttribute.name)"
      class="generated-input-container"
      [ngClass]="generateColumn(formAttribute.name)"
      [hidden]="formAttribute.metaParameters['guiVisible'] !== 'true' || hide">
      <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>
    </div>

    <ng-template #inputTemplate>
      <label>{{ getAttributeLabel(formAttribute) }}
        <span *ngIf="formAttribute.metaParameters['isMandatory'] === 'true' && validate" class="required">*</span>
      </label>
      <ng-container *ngIf="formAttribute.nativeAttribute; else nonNative">
        <div class="input full" [ngSwitch]="formAttribute.metaParameters['guiElementType']">
          <ng-container *ngSwitchCase="guiElementTypeEnum.BOOLEAN">
            <input
              class="checkbox"
              type="checkbox"
              [id]="formIdPrefix + '.' + formAttribute.name"
              [formControlName]="formAttribute.name"
              (ngModelChange)="
                attributeOnChangeEvent[formAttribute.name]
                  ? attributeOnChangeEvent[formAttribute.name].emit($event)
                  : ''
              " />
            <label class="-align-center" [for]="formIdPrefix + '.' + formAttribute.name"> </label>
          </ng-container>

          <app-date-picker
            *ngSwitchCase="guiElementTypeEnum.DATE"
            class="full date-picker date-generated-form"
            [id]="formIdPrefix + '.' + formAttribute.name"
            [minDate]="getAssignmentStateFromMinDate()"
            [maxDate]="getAssignmentStateFromMaxDate()"
            [formControlName]="formAttribute.name"
            [showTime]="false"
            (ngModelChange)="
              attributeOnChangeEvent[formAttribute.name] ? attributeOnChangeEvent[formAttribute.name].emit($event) : ''
            " />

          <app-date-picker
            *ngSwitchCase="guiElementTypeEnum.DATE_TIME"
            class="full date-picker date-generated-form"
            [id]="formIdPrefix + '.' + formAttribute.name"
            [minDate]="getAssignmentStateFromMinDate()"
            [maxDate]="getAssignmentStateFromMaxDate()"
            [formControlName]="formAttribute.name"
            [showTime]="true"
            (ngModelChange)="
              attributeOnChangeEvent[formAttribute.name] ? attributeOnChangeEvent[formAttribute.name].emit($event) : ''
            " />

          <ng-container *ngSwitchCase="guiElementTypeEnum.SELECT">
            <ng-container *ngIf="formAttribute.typeDetail !== 'EXTERNAL_VALUE' && !externalValues[formAttribute.name]; else externalValue">
              <app-input-select-form-field
                *ngIf="formAttribute.type === attributeTypeEnum.CODEBOOK"
                class="full-width"
                inputType="codebook"
                [doNotShowInternalErrors]="true"
                [id]="formIdPrefix + '.' + formAttribute.name"
                [formControlName]="formAttribute.name"
                [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                [codebookType]="formAttribute.typeDetail"
                [noLabel]="true"
                (selectionChangeEmit)="
                  attributeOnChangeEvent[formAttribute.name]
                    ? attributeOnChangeEvent[formAttribute.name].emit($event)
                    : ''
                " />

              <app-input-select-form-field
                *ngIf="formAttribute.type === attributeTypeEnum.ENUM"
                class="full"
                inputType="enum"
                [doNotShowInternalErrors]="true"
                [id]="formIdPrefix + '.' + formAttribute.name"
                [formControlName]="formAttribute.name"
                [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                [sourceName]="sourceName"
                [enumName]="formAttribute.typeDetail"
                [noLabel]="true"
                (selectionChangeEmit)="
                  attributeOnChangeEvent[formAttribute.name]
                    ? attributeOnChangeEvent[formAttribute.name].emit($event)
                    : ''
                " />
            </ng-container>

            <ng-template #externalValue>
              <app-input-select-form-field
                class="full-width"
                valueOption="value"
                [labelOption]="externalValues[formAttribute.name][0]['labelKey'] ? 'labelKey' : 'label'"
                [doNotShowInternalErrors]="true"
                [id]="formIdPrefix + '.' + formAttribute.name"
                [formControlName]="formAttribute.name"
                [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                [options]="externalValues[formAttribute.name]"
                [noLabel]="true"
                (selectionChangeEmit)="
                  attributeOnChangeEvent[formAttribute.name]
                    ? attributeOnChangeEvent[formAttribute.name].emit($event)
                    : ''
                " />
            </ng-template>
          </ng-container>

          <app-form-phone-number
            *ngSwitchCase="guiElementTypeEnum.PHONE"
            class="full-width"
            [id]="formIdPrefix + '.' + formAttribute.name"
            [validate]="validate"
            [showLabel]="false"
            [translationPrefix]="'wc.shopping.form'"
            [mandatory]="formAttribute.metaParameters['isMandatory'] === 'true'"
            [fieldName]="formAttribute.name"
            [control]="getFormControlByName(formAttribute.name)"
            [formControlName]="formAttribute.name" />

          <!-- Default input -->
          <input
            *ngSwitchDefault
            [id]="formIdPrefix + '.' + formAttribute.name"
            class="{{ formAttribute.name === guiElementTypeEnum.EMAIL ? 'withIcon' : '' }}"
            [formControlName]="formAttribute.name"
            [type]="
              formAttribute.metaParameters['guiElementType'] === 'integer' ||
              formAttribute.metaParameters['guiElementType'] === 'float'
                ? 'number'
                : 'text'
            "
            (focusin)="this.fieldValueChanged(formAttribute.name)"
            (ngModelChange)="
              attributeOnChangeEvent[formAttribute.name] ? attributeOnChangeEvent[formAttribute.name].emit($event) : ''
            " />
          <!-- We will use this component after demo (bigger refactoring needed) -->
          <!-- <app-input-text
            *ngSwitchDefault
            [parentGroup]="form"
            [controlName]="formAttribute.name"
            [inputId]="formIdPrefix + '.' + formAttribute.name"
            [label]="getAttributeLabel(formAttribute)"
            [required]="formAttribute.metaParameters['isMandatory'] === 'true' && validate"
            [iconPath]="
              formAttribute.name === guiElementTypeEnum.EMAIL ? '/assets/img/ecare/mail.svg' : undefined
            "></app-input-text> -->
          <i
            *ngIf="formAttribute.name === guiElementTypeEnum.EMAIL"
            class="prefix_icon"
            aria-hidden="true"
            inlineSVG="/assets/img/ecare/mail.svg">
          </i>
        </div>
        <app-input-errors
          [control]="getFormControlByName(formAttribute.name)"
          [translationPrefix]="'wc.shopping.form.' + formAttribute.name" />
      </ng-container>

      <ng-template #nonNative>
        <div [formGroup]="getParametersForm()">
          <div class="input full" [ngSwitch]="formAttribute.metaParameters['guiElementType']">
            <ng-container *ngSwitchCase="guiElementTypeEnum.BOOLEAN">
              <input
                class="checkbox"
                type="checkbox"
                [id]="formIdPrefix + '.' + formAttribute.name"
                [formControlName]="formAttribute.name"
                (ngModelChange)="
                  attributeOnChangeEvent[formAttribute.name]
                    ? attributeOnChangeEvent[formAttribute.name].emit($event)
                    : ''
                " />
              <label class="-align-center" [for]="formIdPrefix + '.' + formAttribute.name"> </label>
            </ng-container>

            <app-date-picker
              *ngSwitchCase="guiElementTypeEnum.DATE"
              class="full date-picker date-generated-form"
              style="display: flex"
              [id]="formIdPrefix + '.' + formAttribute.name"
              [minDate]="getAssignmentStateFromMinDate()"
              [maxDate]="getAssignmentStateFromMaxDate()"
              [formControlName]="formAttribute.name"
              [showTime]="false"
              (ngModelChange)="
                attributeOnChangeEvent[formAttribute.name]
                  ? attributeOnChangeEvent[formAttribute.name].emit($event)
                  : ''
              " />

            <app-date-picker
              *ngSwitchCase="guiElementTypeEnum.DATE_TIME"
              class="full date-picker date-generated-form"
              style="display: flex"
              [id]="formIdPrefix + '.' + formAttribute.name"
              [minDate]="getAssignmentStateFromMinDate()"
              [maxDate]="getAssignmentStateFromMaxDate()"
              [formControlName]="formAttribute.name"
              [showTime]="true"
              (ngModelChange)="
                attributeOnChangeEvent[formAttribute.name]
                  ? attributeOnChangeEvent[formAttribute.name].emit($event)
                  : ''
              " />

            <ng-container *ngSwitchCase="guiElementTypeEnum.SELECT">
              <ng-container *ngIf="formAttribute.typeDetail !== 'EXTERNAL_VALUE' && !externalValues[formAttribute.name]; else externalValue">
                <app-input-select-form-field
                  *ngIf="formAttribute.type === attributeTypeEnum.CODEBOOK"
                  class="full-width"
                  inputType="codebook"
                  [doNotShowInternalErrors]="true"
                  [id]="formIdPrefix + '.' + formAttribute.name"
                  [formControlName]="formAttribute.name"
                  [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                  [codebookType]="formAttribute.typeDetail"
                  [noLabel]="true"
                  (selectionChangeEmit)="
                    attributeOnChangeEvent[formAttribute.name]
                      ? attributeOnChangeEvent[formAttribute.name].emit($event)
                      : ''
                  " />

                <app-input-select-form-field
                  *ngIf="formAttribute.type === attributeTypeEnum.ENUM"
                  class="full"
                  inputType="enum"
                  [doNotShowInternalErrors]="true"
                  [id]="formIdPrefix + '.' + formAttribute.name"
                  [formControlName]="formAttribute.name"
                  [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                  [sourceName]="sourceName"
                  [enumName]="formAttribute.typeDetail"
                  [noLabel]="true"
                  (selectionChangeEmit)="
                    attributeOnChangeEvent[formAttribute.name]
                      ? attributeOnChangeEvent[formAttribute.name].emit($event)
                      : ''
                  " />
              </ng-container>

              <ng-template #externalValue>
                <app-input-select-form-field
                  class="full-width"
                  valueOption="value"
                  [labelOption]="externalValues[formAttribute.name][0]['labelKey'] ? 'labelKey' : 'label'"
                  [doNotShowInternalErrors]="true"
                  [id]="formIdPrefix + '.' + formAttribute.name"
                  [formControlName]="formAttribute.name"
                  [showEmptyOption]="!(formAttribute.metaParameters['isMandatory'] === 'true')"
                  [options]="externalValues[formAttribute.name]"
                  [noLabel]="true"
                  (selectionChangeEmit)="
                    attributeOnChangeEvent[formAttribute.name]
                      ? attributeOnChangeEvent[formAttribute.name].emit($event)
                      : ''
                  " />
              </ng-template>
            </ng-container>

            <app-form-phone-number
              *ngSwitchCase="guiElementTypeEnum.PHONE"
              class="full-width"
              [id]="formIdPrefix + '.' + formAttribute.name"
              [showLabel]="false"
              [validate]="validate"
              [mandatory]="formAttribute.metaParameters['isMandatory'] === 'true'"
              [control]="getFormParameterByName(formAttribute.name)"
              [fieldName]="formAttribute.name"
              [formControlName]="formAttribute.name" />

            <input
              *ngSwitchDefault
              class="form__input full"
              [id]="formIdPrefix + '.' + formAttribute.name"
              [formControlName]="formAttribute.name"
              [type]="
                formAttribute.metaParameters['guiElementType'] === 'integer' ||
                formAttribute.metaParameters['guiElementType'] === 'float'
                  ? 'number'
                  : 'text'
              "
              (focusin)="this.fieldValueChanged(formAttribute.name)"
              (ngModelChange)="
                attributeOnChangeEvent[formAttribute.name]
                  ? attributeOnChangeEvent[formAttribute.name].emit($event)
                  : ''
              " />
            <i
              *ngIf="formAttribute.metaParameters['guiElementType'] === guiElementTypeEnum.EMAIL"
              class="prefix_icon"
              aria-hidden="true"
              inlineSVG="/assets/img/ecare/mail.svg">
            </i>
          </div>
        </div>

        <app-input-errors
          [control]="getFormParameterByName(formAttribute.name)"
          [translationPrefix]="'wc.shopping.form.' + formAttribute.name" />
      </ng-template>
    </ng-template>
  </ng-container>
</form>
