<div class="section">
  <div class="inner">
    <h4 id="001_checkout_deposit_items" [class]="resultClass() === 'success' ? 'alert alert-' + resultClass() : 'alert custom-alert ' + resultClass()">
      {{ 'wc.shopping.gui_checkoutScoring.result.' + resultClass() | translate }}
      <br />
      {{ resultFlag !== 'RED' ? '' : ('wc.shopping.gui_checkoutScoring.message.' + resultClass() | translate) }}
    </h4>
  </div>
</div>
<ng-container *ngIf="scoringResult">
  <ng-container *ngFor="let scoringReason of scoringResult.resultReasons">
    <app-checkout-deposit-item
      [order]="order"
      [resultFlag]="resultFlag"
      [scoringReason]="scoringReason"
      (depositChangedEmitter)="reloadDeposits($event)">
    </app-checkout-deposit-item>
  </ng-container>
</ng-container>
<ng-container *ngIf="manualDepositProducts">
  <ng-container *ngFor="let product of manualDepositProducts">
    <app-checkout-deposit-item
      [order]="order"
      [resultFlag]="resultFlag"
      [manualDepositProduct]="product"
      (manualDepositChangedEmitter)="manualDepositChanged($event)"
      (manualDepositRemovedEmitter)="manualDepositRemoved($event)">
    </app-checkout-deposit-item>
  </ng-container>
</ng-container>
