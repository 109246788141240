<div *ngIf="iconsListMeta" class="container cms-widget-icons-list" [attr.id]="urlAnchor">
  <ng-container *ngFor="let iconMeta of iconsListMeta">
    <div class="-box">
      <img class="-box-icon" alt="Icon in 'Icons list' widget" [lazyLoad]="iconMeta.iconImage" />

      <div class="-box-text">
        <div app-cms-widget-rich-content [content]="iconMeta.content"></div>
      </div>
    </div>
  </ng-container>
</div>
