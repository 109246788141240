<div class="full-height" [attr.id]="urlAnchor" [ngSwitch]="bannerTargeter">
  <ng-container *ngSwitchCase="'HP_HERO'">
    <div
      *ngIf="renderingContentConfigs.length > 0"
      app-hero-banner
      [contentConfigsList]="renderingContentConfigs"></div>
  </ng-container>

  <ng-container *ngSwitchCase="'HP_HALF_IMAGES'">
    <div
      *ngIf="renderingContentConfigs.length > 0"
      app-half-image-banner
      [contentConfigsList]="renderingContentConfigs"></div>
  </ng-container>

  <ng-container *ngSwitchCase="'HP_ICON_LIST_FIRST'">
    <div
      *ngIf="renderingContentConfigs.length > 0"
      app-categories-banner
      [contentConfigsList]="renderingContentConfigs"></div>
  </ng-container>

  <ng-container *ngSwitchCase="'HP_ICON_LIST_SECOND'">
    <div
      *ngIf="renderingContentConfigs.length > 0"
      app-info-banner
      [contentConfigsList]="renderingContentConfigs"></div>
  </ng-container>

  <ng-container *ngSwitchCase="'HP_BRAND_LIST'">
    <div
      *ngIf="renderingContentConfigs.length > 0"
      app-brand-listing
      [contentConfigsList]="renderingContentConfigs"></div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="banner-swiper">
      <swiper
        [slidesPerView]="1"
        [centeredSlides]="true"
        [slidesPerGroupSkip]="1"
        [allowTouchMove]="true"
        [grabCursor]="false"
        [pagination]="{ clickable: true }"
        [autoplay]="{
        delay: 6000
      }"
        [effect]="'fade'"
        [breakpoints]="{
        '1200': {
          allowTouchMove: false
        }
      }">
        <ng-template swiperSlide *ngFor="let content of renderingContentConfigs">
          <app-cms-widgets-sequential-renderer [widgets]="content.contentVersion.widgets">
          </app-cms-widgets-sequential-renderer>
        </ng-template>
      </swiper>
    </div>
  </ng-container>
</div>
