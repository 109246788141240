import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AccountService, CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { map, takeUntil } from 'rxjs/operators';
import {
  EntityGeneratedFormComponent,
  ExternalValue
} from '../../entity-generated-form/entity-generated-form.component';
import { CodebookDto, ContactDto } from '@btl/order-bff';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('contactEntityGeneratedForm', { static: false }) contactFormComponent: EntityGeneratedFormComponent;
  @ViewChild('addressEntityGeneratedForm', { static: false }) addressFormComponent: EntityGeneratedFormComponent;

  @Input() parentGroup: FormGroup;
  @Input() form;
  @Input() type: string;
  @Input() translationPrefix: string;
  @Input() main: boolean = false;
  @Input() account;
  @Input() columnsCount: number = 2;
  @Input() validate = true;
  @Input() withAddress = false;
  @Input() showHeader = true;
  @Input() sectionView = true;
  @Input() sameAsForm: FormGroup = null;
  @Input() sameAsObject: ContactDto = null;
  @Input() sameAsTranslationKey;
  @Input() checkEmailUniqueness = true;
  @Input() preferredContactWithoutNone = false;
  @Input() formName: string;
  @Input() showAllowContact = false;
  @Input() entityGenerateForm = false;
  @Input() checkFormVisibility = true;
  @Input() formIdPrefix = '';
  @Input() customer?;
  @Input() showCustomerData = true;
  @Input() sourceName = 'account';

  @Output() readonly allowContactChanged = new EventEmitter<boolean>();
  @Output() readonly formGeneratedEmitter = new EventEmitter<void>();

  inputMode = true;
  allowContact = false;
  defaultContactLanguage = environment.localization.defaultLocale;
  disabled = false;
  formGenerated = false;
  patchContact;
  sameAsSubscription: Subscription;
  preferredChannelOld;
  preferredChannelExternalValues: Array<ExternalValue>;
  externalValues = null;
  preferredChannels: CodebookDto[];

  constructor(
    private accountService: AccountService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    if (this.preferredContactWithoutNone) {
      this.codebookService
        .getCodebooks('CONTACT_PREFERRED_CHANNEL')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.preferredChannels = result;
          this.preferredChannelExternalValues = this.getPreferredChannelExternalValues();
          this.externalValues = this.getExternalValues();
          this.currentLocaleService.currentLocaleChange
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => (this.preferredChannelExternalValues = this.getPreferredChannelExternalValues()));
        });
    } else {
      this.externalValues = {};
    }

    if (this.form) {
      this.form.get('type')?.setValue(this.type);
    }
  }

  getPreferredChannelExternalValues(): Array<ExternalValue> {
    return this.preferredChannels
      .filter(cb => cb.code != 'NONE')
      .map(pm => {
        return {
          value: pm.code,
          label: pm.localizedTexts.find(lt => lt.locale === this.currentLocaleService.getCurrentLanguage())?.text,
        };
      });
  }

  getExternalValues() {
    const externalValues = {};
    if (this.preferredContactWithoutNone) {
      externalValues['preferredChannel'] = this.preferredChannelExternalValues;
    }
    return externalValues;
  }

  resetForm() {
    if (this.contactFormComponent) {
      this.contactFormComponent.resetForm();
      this.form.get('type').setValue(this.type);
    }
    if (this.addressFormComponent) {
      this.addressFormComponent.resetForm();
      this.form.get('address').form.get('type').setValue(this.type);
    }
    this.patchContact = null;
  }

  setSameAs(sameAs: boolean) {
    this.inputMode = !sameAs;

    if (sameAs) {
      if (this.sameAsForm) {
        this.sameAsSubscription = this.sameAsForm.valueChanges.subscribe(value => {
          const contact = this.sameAsForm.getRawValue();
          contact.id = null;
          this.form.patchValue(contact);
          this.form.get('type').setValue(this.type);
        });
        const contact = this.sameAsForm.getRawValue();
        contact.id = null;
        this.form.patchValue(contact);
        this.form.get('type').setValue(this.type);
        this.sameAsObject = null;
      }
      if (this.sameAsObject) {
        this.sameAsObject['id'] = null;
        this.form.disable();
        this.form.patchValue(this.sameAsObject);
        this.form.get('type').setValue(this.type);
        this.sameAsForm = null;
      }
    } else {
      this.form.enable();
      this.unsubscribeSameAs();
    }

    if (this.sameAsForm || this.sameAsObject) {
      this.main = false;
    }
  }

  disable(disable: boolean) {
    this.disabled = disable;
  }

  disableForm() {
    if (!this.showCustomerData || this.customer.id) {
      this.form.disable();
    }
  }

  allowContactChange(event) {
    this.allowContact = event.target.checked;
    this.allowContactChanged.emit(this.allowContact);
  }

  public static getBaseFormConfiguration() {
    return {
      id: [null],
      type: [null],
    };
  }

  contactFormGenerated() {
    if (this.contactFormComponent.formGenerated && (!this.withAddress || this.addressFormComponent.formGenerated)) {
      if (this.patchContact) {
        this.form.patchValue(this.patchContact);
      }
      this.formGenerated = true;
      this.patchContact = null;
      this.formGeneratedEmitter.emit();
    }
  }

  getPreferredChannel() {
    let contact = this.patchContact;
    if (!contact) {
      contact = this.form.getRawValue();
    }

    if (!contact) {
      return this.preferredChannelOld;
    }

    this.preferredChannelOld = contact.preferredChannel;
    return this.preferredChannelOld;
  }

  public generateForm(contact) {
    this.patchContact = contact;
    this.entityGenerateForm = true;
    return this.formGeneratedEmitter;
  }

  ngOnDestroy(): void {
    this.unsubscribeSameAs();
  }

  unsubscribeSameAs() {
    this.onDestroy$.next();

    if (this.sameAsSubscription) {
      this.sameAsSubscription.unsubscribe();
    }
  }
}
