<app-ecare-page-layout
  *ngIf="ticket"
  showBackButton="true"
  columnTemplate="{{ '2fr 1fr' }}"
  title="{{
    !editMode
      ? ('wc.ecare.interactions.heading.detail.text' | translate)
      : ((newTicket ? 'wc.ecare.interactions.heading.edit.text' : 'wc.ecare.interactions.heading.edit.text')
        | translate)
  }}">
  <ng-container page-actions>
    <ng-container *ngIf="!editMode; else editActions">
      <app-button
        variant="secondary"
        iconPath="/assets/svg/edit.svg"
        label="{{ 'wc.common.edit' | translate }}"
        (click)="editInteraction()"></app-button>
      <app-button
        iconPath="/assets/svg/add.svg"
        label="{{ 'wc.ecare.contactHistory.addRelatedTask' | translate }}"
        (click)="addRelatedTask()"></app-button>
    </ng-container>
    <ng-template #editActions>
      <app-button
        iconPath="/assets/svg/save.svg"
        label="{{ 'wc.ecare.accountSettings.saveChanges' | translate }}"
        (click)="saveChanges()"></app-button>
      <app-button
        variant="secondary"
        label="{{ 'wc.common.cancel.button' | translate }}"
        (click)="cancel()"></app-button>
      <app-button
        *ngIf="!newTicket"
        variant="secondary"
        label="{{ 'wc.ecare.contactHistory.discardChanges' | translate }}"
        (click)="discardChanges()"></app-button>
    </ng-template>
  </ng-container>
  <div *blockUI="blockUIElement.name; template: blockTemplate" left-side>
    <app-ecare-section sectionTitle="{{ 'wc.ecare.interactions.heading.interactionInfo.text' | translate }}">
      <div class="left-panel generated-form">
        <div class="generated-input-container col12">
          <app-custom-input
            [parentGroup]="form"
            [controlName]="'subject'"
            [inputType]="'textarea'"
            [disabled]="!editMode"
            (valueChanged)="fieldChanged('subject', $event.target.value)"
            [translationPrefix]="'wc.ecare.ticket'">
          </app-custom-input>
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            [parentGroup]="form"
            [controlName]="'description'"
            [inputType]="'textarea'"
            [disabled]="!editMode"
            (valueChanged)="fieldChanged('description', $event.target.value)"
            [translationPrefix]="'wc.ecare.ticket'">
          </app-custom-input>
        </div>
      </div>
    </app-ecare-section>

    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.notes' | translate }}">
      <app-ticket-notes-form [ticket]="ticket" (notesChanged)="update()"></app-ticket-notes-form>
    </app-ecare-section>

    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.attachments' | translate }}">
      <div *ngIf="!form.get('attachments').value?.length" [ngTemplateOutlet]="noDataMessage"></div>
      <ng-container [formGroup]="form">
        <app-form-attachments
          extIdPrefix="TICKET_"
          dmsType="TICKET_GENERAL"
          formControlName="attachments"
          [control]="form.get('attachments')"
          [types]="['GENERAL']"
          (onChange)="fieldChanged('attachments', $event)">
        </app-form-attachments>
      </ng-container>
    </app-ecare-section>

    <ng-container *ngIf="!newTicket">
      <app-ecare-section sectionTitle="{{ 'wc.ecare.contactHistory.relatedTasks' | translate }}">
        <div *ngIf="relatedTasks?.length; else noDataMessage">
          <app-ecare-tasks [backUrl]="router.url" [tickets]="relatedTasks" (tasksDeletedEmitter)="tasksDeleted()">
          </app-ecare-tasks>
        </div>
        <div class="buttons">
          <app-button
            variant="secondary"
            iconPath="/assets/svg/add.svg"
            label="{{ 'wc.ecare.contactHistory.addRelatedTask' | translate }}"
            (click)="addRelatedTask()">
          </app-button>
        </div>
      </app-ecare-section>
    </ng-container>
  </div>
  <div right-side>
    <app-ecare-section sectionTitle="{{ 'wc.ecare.interactions.interactionDetails.label' | translate }}">
      <div *ngIf="contactTypes">
        <app-sidebox-row label="{{ 'wc.ecare.ticket.channel' | translate }}">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_CHANNEL).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              *ngIf="channels"
              class="full"
              id="ticket-edit-history-channel"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_CHANNEL).value"
              (ngModelChange)="fieldChanged(contactParamNames.CONTACT_CHANNEL, $event)">
              <option *ngFor="let channel of channels" [value]="channel.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook">
                  {{ codebook[channel.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.ticket.direction' | translate }}">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_DIRECTION') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_DIRECTION).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              *ngIf="directions"
              class="full"
              id="ticket-edit-history-direction"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_DIRECTION).value"
              (ngModelChange)="fieldChanged(contactParamNames.CONTACT_DIRECTION, $event)">
              <option *ngFor="let direction of directions" [value]="direction.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_DIRECTION') | async as codebook">
                  {{ codebook[direction.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.ticket.contactType' | translate }}">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_TYPE).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              class="full"
              id="ticket-edit-history-contactType"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_TYPE).value"
              (ngModelChange)="
              fieldChanged(contactParamNames.CONTACT_TYPE, $event);
              contactTypeChanged($event, areaSelect2, subAreaSelect2)
            ">
              <option *ngFor="let contactType of contactTypes" [value]="contactType.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[contactType.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.ticket.area' | translate }}">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{ getParam(ticket.parameters, contactParamNames.TYPE_AREA).value ? codebook[getParam(ticket.parameters, contactParamNames.TYPE_AREA).value] : '-'}}
          </div>
          <div [hidden]="!editMode">
            <select
              #areaSelect2
              class="full"
              id="ticket-edit-history-area"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.TYPE_AREA).value"
              (ngModelChange)="fieldChanged(contactParamNames.TYPE_AREA, $event); areaChanged($event, subAreaSelect2)">
              <option *ngFor="let area of areas" [value]="area.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[area.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>

        <app-sidebox-row label="{{ 'wc.ecare.ticket.subArea' | translate }}">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{ getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value ? codebook[getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value] : '-' }}
          </div>
          <div [hidden]="!editMode">
            <select
              #subAreaSelect2
              class="full"
              id="ticket-edit-history-subArea"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value"
              (ngModelChange)="fieldChanged(contactParamNames.TYPE_SUBAREA, $event)">
              <option *ngFor="let subArea of subAreas" [value]="subArea.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[subArea.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row *ngIf="ticket.recordVersion" label="{{ 'wc.ecare.ticket.created' | translate }}"
        >{{ ticket.createdBy }}
          <span class="-ticket-date">({{ ticket.created | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
        <app-sidebox-row *ngIf="ticket.recordVersion" label="{{ 'wc.ecare.ticket.modified' | translate }}"
        >{{ ticket.modifiedBy }}
          <span class="-ticket-date">({{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
      </div>
    </app-ecare-section>

    <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.customer' | translate }}">
      <div class="generated-form" [formGroup]="customerForm">
        <div class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.ticket.customer' | translate }}
            <span class="required">*</span>
          </label>
          <!--                  <app-ecare-filter-autocomplete-box-->
          <!--                    #customerSearch-->
          <!--                    [searchedEntity]="'Customer'"-->
          <!--                    (handleChangeEvent)="fieldChanged('customer', $event)">-->
          <!--                  </app-ecare-filter-autocomplete-box>-->
          <app-form-field-modal-picker
            labelField="displayName"
            controlName="cuRefNo"
            [disabled]="!editMode || (newTicket && customerLocalStorageService.getCurrentCustomer())"
            [parentGroup]="customerForm"
            [translationPrefix]="'wc.ecare.ticket'"
            [pickerType]="pickerInputType.CUSTOMER"
            (selectionChanged)="fieldChanged('Customer', $event)">
          </app-form-field-modal-picker>
        </div>
        <div *ngIf="customerForm.controls.cuRefNo.value" class="generated-input-container col4">
          <label>
            {{ 'wc.ecare.ticket.id' | translate }}
          </label>
          <input disabled [value]="customerForm.controls.cuRefNo.value?.id" />
        </div>
        <div *ngIf="customerForm.controls.cuRefNo.value" class="generated-input-container col8">
          <label>
            {{ 'wc.ecare.dashboard.subjectType.label' | translate }}
          </label>
          <input disabled [value]="customerForm.controls.cuRefNo.value?.subject.subjectType" />
        </div>
        <div *ngIf="customerForm.controls.cuRefNo.value" class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.dashboard.primaryContact.label' | translate }}
          </label>
          <input disabled [value]="customerForm.controls.cuRefNo.value?.contact | displayContact" />
        </div>
        <div *ngIf="customerForm.controls.cuRefNo.value" class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.dashboard.customerAccount.header' | translate }}
          </label>
          <app-form-field-modal-picker
            labelField="displayName"
            valueField="id"
            controlName="caRefNo"
            [disabled]="!editMode"
            [parentGroup]="customerForm"
            [customerId]="customerForm.controls.cuRefNo.value?.id"
            [pickerType]="pickerInputType.CUSTOMER_ACCOUNT"
            (selectionChanged)="fieldChanged('CustomerAccount', $event)">
          </app-form-field-modal-picker>
        </div>
      </div>
    </app-ecare-section>
  </div>
</app-ecare-page-layout>

<ng-template #noDataMessage>
  <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
</ng-template>
