<div #stickyBarContent class="product__detail-sticky_price_bar {{ stickyPriceBoxCssClass }}">
  <div class="container">
    <div class="sticky_price_bar-product">
      <div class="sticky_price_bar-product-thumbnail">
        <img src="{{ getThumbnailUrl() }}" alt="{{ product.name }}" />
      </div>
      <div class="sticky_price_bar-product-label">{{ product.name }}</div>
      <div class="sticky_price_bar-buy_section-price">
        <div class="sticky_price_bar-buy_section-price-title">
          {{ 'wc.shopping.oneTimePayment.label' | translate }}
        </div>
        <div class="sticky_price_bar-buy_section-price-value">
          <one-time-price
            *ngIf="product?.prices['OC']; else tariffPrice"
            [price]="
              tariffHwProduct ? tariffHwProduct.prices['OC'].price : product.prices['OC'].price - priceDiscount
            "></one-time-price>
          <ng-template #tariffPrice>
            <app-recurrent-price
              [price]="tariffHwProduct ? tariffHwProduct.prices['RC'].price : product.prices['RC'].price">
            </app-recurrent-price>
          </ng-template>
        </div>
      </div>
      <div class="sticky_price_bar-buy_section-button">
        <ng-container *ngIf="!checkScanRequired; else technology_check">
          <ng-container *ngIf="stockCentral$ | async as stock">
            <app-button
              *ngIf="stock.realStock > 0"
              id="product-detail-sticky-price-button-buy"
              variant="primary"
              iconPath="/assets/svg/shopping-cart-icon-small.svg"
              label="{{ 'wc.common.buyNow.button' | translate }}"
              (click)="callAddToBasket()"></app-button>
          </ng-container>
        </ng-container>
        <ng-template #technology_check>
          <app-button
            id="product-detail-sticky-price-button-technology"
            iconPath="/assets/svg/shopping-cart-icon-small.svg"
            variant="primary"
            label="{{ 'wc.common.buyNow.button' | translate }}"
            (click)="callGoToTechnologyCheck()"></app-button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
