import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-sidebox-row',
  templateUrl: './sidebox-row.component.html'
})

/*
    The component to display label and value in simple table form
    The template content inside <app-sidebox-row></app-sidebox-row> tag is visible only with attribute forceSpecialContent on
 */

export class SideboxRowComponent implements OnInit {
  /* Row label */
  @Input() label: string;

  /* Path of the svg icon that is used */
  @Input() iconPath: string;

  @Input() require: boolean = false;

  /* If boolean is true and icon exists, it is shown before the value text and not after it */
  @Input() iconBeforeValue: boolean = false;

  /* Tooltip of the icon if exists */
  @Input() iconTooltip: string;

  /* Tooltip on the value text */
  @Input() generalTooltip: string;

  /* If filled this value is displayed instead of component content and allows it to copy to clipboard */
  @Input() clipboardValue: string;

  /* Row label */
  @Input() onlyForInternal: boolean = false;

  isCopied: boolean = false;

  constructor(public clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  copyToClipboard(value: string) {
    const pending = this.clipboard.beginCopy(value);

    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
        this.isCopied = true;
      }
    };
    attempt();
  }

}
