<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.ticket.duplicate' | translate }}
  </h4>
  <div class="modal-close" (click)="cancel()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  <div [formGroup]="form" class="generated-form">
    <div class="generated-input-container col12">
      <div *ngFor="let control of formArray.controls; let i = index" class="form-row">
        <input type="checkbox" [id]="'forDuplication-' + i" [formControl]="control" />
        <label [for]="'forDuplication-' + i">{{ 'wc.ecare.ticket.' + items[i] | translate }}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-button
    variant="secondary"
    ngbAutofocus
    label="{{ 'wc.common.cancel.button' | translate }}"
    (click)="cancel()">
  </app-button>
  <app-button
    variant="primary"
    label="{{ 'wc.common.continue.button' | translate }}"
    (click)="continue()">
  </app-button>
</div>
