import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { Product } from '../../../../models/product';
import { Subject, Subscription } from 'rxjs';
import { ProductListingService } from '@service/product-listing.service';
import { CurrentLocaleService, WishListService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { WishListPopupComponent } from 'app/components/wizard/wish-list/wish-list-popup/wish-list-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EllipsisDirective } from 'ngx-ellipsis';
import { ColorService } from '@service/color.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { ProductDetailDto } from '@btl/order-bff';

@Component({
  selector: 'app-box-item',
  templateUrl: './box-item.component.html',
  styleUrls: ['./box-item.component.scss'],
})
export class BoxItemComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  /**
   * The displayed product.
   */
  @Input()
  product: Product;

  /**
   * The ID of the party of the original product. This is only relevant for tariff change.
   */
  @Input()
  originalProductPartyId: string;

  /**
   * The original product. This is only relevant for tariff change.
   */
  @Input()
  originalProduct: ProductDetailDto;

  @Input()
  parentProductId: string;

  @Input()
  parentInstanceId: string;

  @Input()
  partyId: string;

  @Input()
  public compareAvailable: boolean = false;

  public colorMap: Map<string, { name: string; rgb: string }>;
  public keyFeaturesCount: number;
  @Input()
  checkScanRequired: boolean = false;

  @Input()
  parentId = '';

  private static allProductTiles: Array<BoxItemComponent> = new Array<BoxItemComponent>();
  @ViewChild('productTile', { static: true }) productTileRef: ElementRef;
  @ViewChild('productImage', { static: false }) public imageRef: ElementRef;
  @ViewChild('name', { static: true }) nameRef: ElementRef;
  @ViewChild('description', { static: true }) descriptionRef: ElementRef;
  @ViewChild('prices', { static: true }) pricesRef: ElementRef;
  @ViewChild(EllipsisDirective) ellipsisReload: EllipsisDirective;
  public tileMinHeight: number = undefined;
  public imageMinHeight: number = undefined;
  public nameMinHeight: number = undefined;
  public descriptionMinHeight: number = undefined;
  public pricesMinHeight: number = undefined;
  private maxProductsSubsription: Subscription;
  private maxProductsInComparison: number;

  inWishList = false;

  constructor(
    public productListingService: ProductListingService,
    public wishListService: WishListService,
    private ngbModal: NgbModal,
    private currentLocaleService: CurrentLocaleService,
    private colorService: ColorService,
    protected propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    this.currentLocaleService.currentLocaleChange.subscribe(result => {
      this.ellipsisReload?.applyEllipsis();
    });
    this.colorService
      .getColors()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => (this.colorMap = result));
    this.initMaxProductsInComparison();
  }

  ngOnInit() {
    this.productListingService.initListing();
    this.keyFeaturesCount = this.productListingService.keyFeaturesCount;
    BoxItemComponent.allProductTiles.push(this);

    this.checkIfAnyWishList();
    this.wishListService.wishListChange.pipe(takeUntil(this.onDestroy$)).subscribe(wishList => {
      this.checkIfAnyWishList();
    });
  }

  private initMaxProductsInComparison() {
    this.propertyAccessorLocalService
      .getMaxComparisonHwCount()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(maxComparisonHwCount => (this.maxProductsInComparison = maxComparisonHwCount));
  }

  checkIfAnyWishList() {
    this.wishListService
      .getWishLists(false)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishLists => {
        let found = false;
        if (wishLists) {
          wishLists.forEach(wishList => {
            this.wishListService.getWishListItems(wishList.id, false).subscribe(items => {
              items.forEach(item => {
                if (item.productCode === this.product.id) {
                  found = true;
                }
              });
            });
          });
        }
        this.inWishList = found;
      });
  }

  ngOnDestroy() {
    for (const subscription of this.productListingService.allSubscriptions) {
      subscription.unsubscribe();
    }
    const index = BoxItemComponent.allProductTiles.findIndex(tile => tile === this);
    BoxItemComponent.allProductTiles.splice(index, 1);

    this.onDestroy$.next();
  }

  public productTileResized($event: ResizedEvent) {
    const newHeight = $event.newRect.height;
    const tilesWithSameTop = BoxItemComponent.allProductTiles.filter(
      tile =>
        tile.productTileRef.nativeElement.offsetParent &&
        tile.productTileRef.nativeElement.offsetParent.offsetParent ===
          this.productTileRef.nativeElement.offsetParent.offsetParent &&
        tile.productTileRef.nativeElement.offsetParent.offsetTop ===
          this.productTileRef.nativeElement.offsetParent.offsetTop
    );

    const maxImageHeight = this.max(tilesWithSameTop, 'imageRef');
    const maxNameHeight = this.max(tilesWithSameTop, 'nameRef');
    const maxDescriptionHeight = this.max(tilesWithSameTop, 'descriptionRef');
    const maxPricesHeight = this.max(tilesWithSameTop, 'pricesRef');
    for (const tileWithSameTop of tilesWithSameTop) {
      tileWithSameTop.imageMinHeight = this.getNewHeight(tileWithSameTop.imageMinHeight, maxImageHeight);
      tileWithSameTop.nameMinHeight = this.getNewHeight(tileWithSameTop.nameMinHeight, maxNameHeight);
      tileWithSameTop.descriptionMinHeight = this.getNewHeight(
        tileWithSameTop.descriptionMinHeight,
        maxDescriptionHeight
      );
      tileWithSameTop.pricesMinHeight = this.getNewHeight(tileWithSameTop.pricesMinHeight, maxPricesHeight);
      tileWithSameTop.tileMinHeight =
        !tileWithSameTop.tileMinHeight || tileWithSameTop.tileMinHeight < newHeight
          ? newHeight
          : tileWithSameTop.tileMinHeight;
    }
  }

  private getNewHeight(oldHeight, maxImageHeight) {
    const oldHeightInt = parseInt(oldHeight);
    return !oldHeight || oldHeight < maxImageHeight ? maxImageHeight : oldHeight;
  }

  private max(tilesWithSameTop: BoxItemComponent[], field: string): number {
    let max: number = 0;
    for (const tile of tilesWithSameTop) {
      if (
        tile[field] &&
        tile[field].nativeElement &&
        tile[field].nativeElement.offsetHeight !== undefined &&
        parseInt(tile[field].nativeElement.offsetHeight) > max
      ) {
        max = parseInt(tile[field].nativeElement.offsetHeight);
      }
    }
    return max;
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = this.product.id;
  }

  buyProduct(product: Product) {
    this.productListingService.handleProductAddingToCart(
      ProductListingService.asProductAddingToCart(product),
      this.originalProductPartyId,
      this.partyId,
      this.parentInstanceId,
      this.parentProductId,
      null,
      true
    );
  }

  getStickerType(value) {
    if (value === 'outOfStock' && 'unavailable') {
      return 'neutral';
    } else {
      return 'success';
    }
  }
}
