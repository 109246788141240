<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ headerTranslationKey | translate }}
  </h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  <p>
    {{ messageTranslationEnable ? (messageTranslationKey | translate) : messageTranslationKey }}
  </p>
</div>
<div class="modal-footer modal-dialog-centered">
  <div class="ml-auto"></div>
  <div class="mr-auto">
    <app-button
      variant="primary"
      label="{{ 'wc.common.close.button' | translate }}"
      (click)="dialogRef.dismiss()"></app-button>
  </div>
</div>
