import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppBlockerService, BlockTemplateComponent, CurrentLocaleService, IconService } from '@btl/btl-fe-wc-common';
import { ActivatedRoute } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderDto } from '@btl/order-bff';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { ThemeService } from '@service/theme.service';
import { Subject } from 'rxjs/internal/Subject';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceSessionService } from '@service/auth-service-session.service';
import { Icons } from './helpers/icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  title = 'app';
  instance: String = 'eshop';
  mainBlockUIInstanceName = AppBlockerService.mainBlockUIInstanceName;
  blockTemplate = BlockTemplateComponent;
  themeCssClass: string;

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    public ngbModal: NgbModal,
    private renderer: Renderer2,
    private iconService: IconService,
    private authServiceSessionService: AuthServiceSessionService, //It is needed for auto logout
  ) {
    this.themeService.getThemeColor().subscribe(theme => (this.themeCssClass = theme));
  }

  ngOnInit() {
    this.iconService.registerIcons(Object.values(Icons));
    let locale = this.route.snapshot.queryParams['locale'];
    if (!locale) {
      locale = this.currentLocaleService.getCurrentLanguage();
    }
    this.currentLocaleService.setCurrentLanguage(locale);
    this.renderer.addClass(document.body, this.themeCssClass);

    // Load order for later usage.
    this.orderingService.getCurrentOrder().subscribe((order: OrderDto) => {
      this.shoppingCartService.handleOrderChange(order).subscribe();
    });
  }
}
