<div *ngIf="!tariffListing" app-most-sold [group]="currentProductGroup"></div>

<!-- 002 Listing heading title -->
<div *ngIf="enableHeader === true">
  <h3 *ngIf="currentProductGroup; else searchTitle" id="001_listing" class="productList__heading">
    {{ getGroupName(currentProductGroup.group) }}
  </h3>
  <ng-template #searchTitle>
    <h3 *ngIf="this.filterService.getProductFilter()?.attributes?.text" id="002_listing" class="productList__heading">
      {{ 'wc.shopping.gui_productListing.header.searchResult' | translate }}
      {{ this.filterService.getProductFilter()?.attributes?.text }}
    </h3>
  </ng-template>
</div>
<!-- /002 -->

<div *ngIf="loading" class="loading-element">
  <div *blockUI="blockUIElement.name; template: blockTemplate"></div>
</div>

<div class="listing-bottom-part">
  <div *ngIf="enableSorting === true && !tariffListing" app-sorting [totalResults]="totalValue"></div>

  <div *ngIf="productsLoadingError">
    {{ 'wc.shopping.gui_productListing.listing.error' | translate }}
    <a
      id="003_listing"
      class="o2-btn o2-btn--primary o2-btn--full-width"
      [class.o2-btn--disabled]="disableReload"
      (click)="reloadProducts()"
      >{{ disableReload ? 'Reloading...' : 'Reload' }}</a
    >
  </div>

  <ng-container *ngIf="isSmartCityOffer || tariffListing">
    <div class="product-listing-headline">
      <div class="product-listing-headline_container" [ngClass]="getGroupClass()">
        <!-- Add after import of images are done -->
        <!-- <div class="-bg-img" [style.background-image]="'url(' + getProductGroup(currentProductGroup).group.pictures[0]?.href + ')'"></div> -->
        <div class="-bg-img"></div>

        <div *ngIf="currentProductGroup" class="headline-container">
          <div class="-title">{{ getGroupName(getProductGroup(currentProductGroup).group) }}</div>
          <div class="-description">{{ getGroupDescription(getProductGroup(currentProductGroup).group) }}</div>
        </div>
      </div>
    </div>

    <div class="product-listing">
      <div
        *ngIf="products && products.length > 0 && !productsLoadingError && !loading"
        app-tariff-listing
        [products]="products"
        [currentProductGroup]="currentProductGroup"
        (btnBuyClick)="buySmartCity($event)"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isSmartCityOffer && !tariffListing">
    <!-- If you want to show tarrifs as LIST, use -tariffsList class instead. -->
    <div *ngIf="!productsLoadingError && !loading" class="products__list">
      <!-- 007 list of products-->
      <div
        *ngFor="let productHolder of productsHolders"
        class="product -tariff"
        app-product-listing-item
        [class.-full]="enableSorting !== true"
        [product]="productHolder?._source"
        [parentProductId]="parentProductId"
        [parentInstanceIds]="parentInstanceId"
        [partyId]="partyId"
        [originalProduct]="originalProduct"
        [originalProductPartyId]="originalProductPartyId"
        [compareAvailable]="compareAvailable"
        [currentProductGroup]="currentProductGroup"
        [parentId]="productHolder?._source.id"></div>
      <!-- /007 -->
    </div>
  </ng-container>
  <!-- 011 pagination -->
  <div
    *ngIf="enablePaging && !productsLoadingError && moreProductsAvailable && loadMorePageSize"
    class="productListing__footer">
    <app-button
      variant="secondary"
      label="{{ 'wc.shopping.gui_productListing.button.displayMore' | translate }}"
      (click)="loadNextPage()"></app-button>
  </div>
  <!-- /011 -->
</div>
