<ng-template #innerPart>
  <div class="homepage-search">
    <div class="dashboard">
      <div class="dashboard_wrapper">
        <div
          app-ecare-search-filter
          [isHomepage]="isHomepage"
          (handleSearch)="handleSearch($event)"
          (handleChangeEntity)="changeSearchEntity($event)"></div>
        <div class="dashboard_search">
          <div *ngIf="isSearchCustomers()" class="table-result">
            <ng-container *ngIf="customers && customers.length; else no_results">
              <table class="ecare_listing-table table ecare-search-customers">
                <thead>
                  <tr>
                    <th class="no-wrap">{{ 'wc.ecare.search.segment.header' | translate }}</th>
                    <th>{{ 'wc.ecare.search.identityNumber.header' | translate }}</th>
                    <th>{{ 'wc.ecare.search.name.header' | translate }}</th>
                    <th>{{ 'wc.ecare.homepage.parameterColumn.label' | translate }}</th>
                    <th>{{ 'wc.ecare.search.address.header' | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tr
                  *ngFor="let customer of customers; let i = index"
                  class="ecare_listing-table-row clickable"
                  (click)="selectCustomerFromRow(customer.partyRefNo, $event)">
                  <td class="no-wrap font-highlight">
                    <app-sticker variant="neutral">{{ customer.segment }}</app-sticker>
                  </td>
                  <td class="no-wrap font-highlight">
                    {{ customer.identityNumber }}
                  </td>
                  <td class="no-wrap font-highlight">
                    {{ customer.firstName }} {{ customer.middleName }} {{ customer.lastName }}
                  </td>
                  <td class="no-wrap -customer-params">
                    <span *ngIf="customer.subjectType === 'Person'">
                      <i [inlineSVG]="'/assets/svg/customer-search-parameter-birth.svg'"></i>{{ customer.birthDate }}
                    </span>
                    <span *ngIf="customer.subjectType !== 'Person'">
                      <i [inlineSVG]="'/assets/svg/customer-search-parameter-id.svg'"></i>{{ customer.companyRegNo }}
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span *ngIf="customer.address">
                      {{ customer.address | displayAddress : addressTypes.DASHBOARD_CA_DETAIL }}
                    </span>
                  </td>
                  <td #dropdownElement class="middle dots">
                    <app-ecare-search-select-dropdown [customer]="customer" [index]="i" />
                  </td>
                </tr>
              </table>
              <app-ecare-search-table-mobile
                pickerInputType="customer"
                [rows]="customers"
                (selectedRow)="selectCustomer($event.id)" />
              <a
                routerLink="/ecare/search"
                [state]="{
                  state: { searchData: { searchEntity: lastSearch.searchEntity, searchValue: lastSearch.searchValue } }
                }">
                <app-button
                  *ngIf="isMore"
                  variant="secondary"
                  label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}" />
              </a>
            </ng-container>
          </div>

          <div *ngIf="isSearchOrders()" class="table-result">
            <ng-container *ngIf="foundOrders && foundOrders.length">
              <app-ecare-order-table
                [canDisplayNoOrdersText]="false"
                [orders]="foundOrders"
                (selectCustomer)="selectCustomer($event)" />
              <app-ecare-order-table-mobile
                [orders]="foundOrders"
                [canDisplayNoOrdersText]="false"
                (selectCustomer)="selectCustomer($event)" />
              <app-button
                *ngIf="isMore"
                variant="secondary"
                label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}" />
            </ng-container>
          </div>

          <div *ngIf="isSearchOpportunities()" class="table-result">
            <ng-container *ngIf="foundOpportunities && foundOpportunities.length; else no_results">
              <app-opportunities-component
                [externalSearchMode]="true"
                [canDisplayNoTicketsText]="false"
                [tickets]="foundOpportunities" />
              <app-button
                *ngIf="isMore"
                variant="secondary"
                label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}" />
            </ng-container>
          </div>

          <!-- TODO: Disconnect no result style from dashboard_wrapper & dashboard_search; connect it with theme  -->
          <ng-template #no_results>
            <app-ecare-listing-no-results *ngIf="!isSearchOrders()" isHomepage="true" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="isHomepage; else isPage">
  <ng-container *ngTemplateOutlet="innerPart" />
</ng-container>
<ng-template #isPage>
  <app-ecare-page-layout
    columnTemplate="1fr"
    title="{{
      (isModuleBffCrmEnabled ? 'wc.ecare.homepage.search.header' : 'wc.ecare.homepage.orderSearch.header') | translate
    }}">
    <div class="dashboard">
      <div class="dashboard_wrapper">
        <div class="dashboard_search">
          <ng-container *ngTemplateOutlet="innerPart" />
        </div>
      </div>
    </div>
  </app-ecare-page-layout>
</ng-template>
