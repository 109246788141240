/**
 * Keys just encapsulates various keys used through the application.
 */
export class Keys {
  //region Local storage:

  /**
   * The key of an orderRefNo stored in the local storage.
   */
  static readonly KEY_CURRENT_ORDER_REF_NO: string = 'currentOrderRefNo';

  //region Session storage:

  /**
   * The key of an order stored in the session storage.
   */
  static readonly KEY_CURRENT_ORDER: string = 'currentOrder';

  static readonly KEY_CURRENT_OPPORTUNITY: string = 'currentOpportunity';
}
