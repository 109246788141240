<div *blockUI="emailContentSharingBlockUiElementName; template: blockTemplate">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.shopping.contentShare.email.title' | translate }}
    </h4>
    <div class="modal-close" (click)="cancel()">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>

  <div class="modal-body">
    <div class="form" [formGroup]="form">
      <div class="generated-form">
        <div class="col12">
          <label>{{ 'wc.shopping.contentShare.email.emailFrom' | translate }}</label>
          <div class="input">
            <app-input-row
              fieldName="emailFrom"
              translationPrefix="wc.shopping.contentShare.email"
              [parentGroup]="form"
              [showLabel]="false">
            </app-input-row>
          </div>
        </div>
      </div>

      <div class="generated-form">
        <div class="col12">
          <label>{{ 'wc.shopping.contentShare.email.emailTo' | translate }}</label>
          <div class="input">
            <app-input-row
              fieldName="emailTo"
              translationPrefix="wc.shopping.contentShare.email"
              [parentGroup]="form"
              [showLabel]="false">
            </app-input-row>
          </div>
        </div>
      </div>

      <div class="generated-form">
        <div class="col12">
          <label>{{ 'wc.shopping.contentShare.email.info' | translate }}</label>
          <div class="input">
            <textarea class="w-100" formControlName="info"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <app-button variant="link" label="{{ 'wc.common.cancel.button' | translate }}" (click)="cancel()"></app-button>
    <app-button
      variant="primary"
      label="{{ 'wc.shopping.contentShare.email.send' | translate }}"
      (click)="send()"></app-button>
  </div>
</div>
