import { ProductDetailDto, ProductPictureHrefDto } from '@btl/order-bff';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PictureUtils {
  public static getPictureHrefByType(product: ProductDetailDto, type: string, defaultHref?: string): string {
    const picture = this.getPictureByType(product, type);
    if (picture) {
      return picture.href;
    }

    return defaultHref;
  }

  public static getPictureByType(product: ProductDetailDto, type: string): ProductPictureHrefDto {
    if (product) {
      for (let i = 0; i < product.pictures.length; i++) {
        const productPictureHrefs = product.pictures[i];
        const pictureHrefDto = productPictureHrefs.pictureMap[type];
        if (pictureHrefDto) {
          return pictureHrefDto;
        }
      }
    }

    return null;
  }

  public static createImageFromBlob(image: Blob): Observable<any> {
    if (image) {
      const reader = new FileReader();
      image instanceof Blob ? reader.readAsDataURL(image) : reader.readAsDataURL(new Blob([image]));
      return fromEvent(reader, 'load').pipe(map(() => reader.result));
    }
  }
}
