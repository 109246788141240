import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandler } from '@btl/btl-fe-wc-common/lib/errors/error-handler';

@Injectable()
export class PageAccessErrorHandler implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(message: string, error: any): any {
    console.error(message, error);
    this.router.navigate(['/']);
  }
}
