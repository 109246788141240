import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import moment from 'moment';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-documents-filter',
  templateUrl: './ecare-documents-filter.component.html',
})
export class EcareDocumentsFilterComponent extends ComplexFilter {
  protected advancedFilterFields = ['stateDateFrom', 'stateDateTo'];

  filterForm = this.formBuilder.group({
    code: [],
    stateDateFrom: [],
    stateDateTo: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.code, 'code'),
    new ControlFilterMiner(this.filterForm.controls.stateDateFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.stateDateTo, 'created', CompareTypeDtoEnum.SMALLEREQ),
  ];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected getDefaultFilterValues() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 6);

    const dateTo = new Date();

    return {
      code: '',
      stateDateFrom: moment(dateFrom).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      stateDateTo: moment(dateTo).utc().add(1, 'd').format('YYYY-MM-DDTHH:mm:ssZ'),
    };
  }
}
