import { Component, Input, OnInit } from '@angular/core';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { Router } from '@angular/router';
import { CategoryTypeEnum } from '../../../models/product-filter';
import _ from 'lodash';
import { PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-business-tariff',
  templateUrl: './ecare-business-tariff.component.html',
})
export class EcareBusinessTariffComponent implements OnInit {
  @Input() tariff: PartyDto;
  msisdn: string;
  collapsed = true;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.msisdn = this.getTariffMsisdn();
  }

  getTariffMsisdn(): string {
    const gsmProduct = CustomerPartyUtil.getChildAssetByCategoryId(
      this.tariff.assets[0],
      CategoryTypeEnum.PRODC_SU_CORE_GSM
    );
    return CustomerPartyUtil.getAssetProductCharacteristicValue(gsmProduct, 'phoneNumber');
  }

  getTariffName(): string {
    return _.isNil(this.tariff.assets[0].product) ? '' : this.tariff.assets[0].product.name;
  }

  goToTariffDetail(): void {
    this.router.navigate(['ecare/dashboard/subscription']);
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }
}
