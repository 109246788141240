<div class="ecare dashboard">
  <div class="ecare-header_headline">
    {{ 'wc.ecare.subscriptionHeadline.label' | translate }}
  </div>
  <div class="dashboard_wrapper">
    <div *blockUI="subscriptionBlockUiElementName; template: blockTemplate" class="tiles_wrapper full_tile">
      <app-tariff-space-consumption-info
        *ngFor="let bundle of tariffSpaceConsumptionInfos"
        class="tile -tile4"
        [bundleDto]="bundle">
      </app-tariff-space-consumption-info>

      <app-subscription-tile
        *ngIf="tariffSpace"
        class="tile -tile12"
        [asset]="asset"
        [customerAccount]="customerAccount"
        [customer]="customer"
        [showCollapse]="false"
        [tariffSpace]="tariffSpace">
      </app-subscription-tile>
    </div>
  </div>
</div>
