import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { WcOrderingService, WcOrderingService as OrderingStorageService } from '@service/wc-ordering.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TicketService } from '@service/ticket.service';
import { AuthFactoryService, AuthService } from '@btl/btl-fe-wc-common';
import { OrderDto } from '@btl/order-bff';

@Component({
  selector: 'app-suspend-order',
  templateUrl: './suspend-order.component.html',
})
export class SuspendOrderComponent implements OnInit {
  @Input() suspendOrderPopup: boolean;
  @Input() dialogRef;

  formSubmitted: boolean = false;
  trySubmit: boolean = false;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loggedUser: boolean;
  authService: AuthService;
  suspendedOrder: OrderDto = null;

  form = this.fb.group({
    email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
  });

  constructor(
    private router: Router,
    private authFactoryService: AuthFactoryService,
    public wcOrderingService: WcOrderingService,
    private orderingStorageService: OrderingStorageService,
    private fb: FormBuilder
  ) {
    this.authService = authFactoryService.getAuthService();
  }

  ngOnInit() {
    from(this.checkedIfLogged()).subscribe(value => {
      this.loggedUser = value;
    });
  }

  checkedIfLogged(): Promise<boolean> {
    return this.authService
      .isLoggedIn()
      .then(value => {
        return value;
      })
      .catch(reason => {
        throw new Error(`Cannot check login status: ${reason}`);
      });
  }

  suspend() {
    this.trySubmit = true;
    if (this.form.invalid && !this.loggedUser) {
      this.trySubmit = false;
      return;
    } else {
      const emailField = this.form.value['email'];
      const orderAsMap = {
        recordVersion: this.wcOrderingService.currentOrder.recordVersion,
        orderStateType: this.wcOrderingService.currentOrder.orderStateType,
      };

      this.wcOrderingService.suspendOrder(this.wcOrderingService.currentOrder.id, orderAsMap, emailField).subscribe(
        suspendedOrder => {
          this.suspendedOrder = suspendedOrder;
          if (this.suspendedOrder) {
            this.formSubmitted = true;
          }
          TicketService.getLocalOpportunity();
          this.trySubmit = false;
          this.router.navigate(['/']);
        },
        (error: HttpErrorResponse) => {
          console.error('Error suspendOrder', error);
          this.dialogRef.dismiss();
        }
      );
    }
  }

  closePopup(): void {
    this.dialogRef.dismiss();
  }

  closePopupAndFlushOrder(): void {
    if (this.formSubmitted) {
      this.orderingStorageService.removeCurrentOrderFromContext();
      this.orderingStorageService.getCurrentOrder().subscribe(() => {
        this.router.navigate(['/']);
      });
    }
    this.dialogRef.dismiss();
  }
}
