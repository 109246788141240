import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for parsing url params
 */
export class UrlParamsService {
  private static readonly SEO_URL = 'seoUrl';
  private static readonly TEXT = 'text';
  private static readonly SORT = 'sort';
  private static readonly AUTH_CODE = 'authCode';
  private static readonly ORDER_REF_NO = 'orderRefNo';
  private static readonly CU_REF_NO = 'cuRefNo';
  private static readonly ORDER_ID = 'orderId';

  /**
   * Retrives seoUrl from parameters
   * @param params
   */
  public getSeoUrl(params) {
    if (params[UrlParamsService.SEO_URL]) {
      return params[UrlParamsService.SEO_URL];
    }
  }

  /**
   * Retrives search text from parameters
   * @param params
   */
  public getSearchText(params) {
    if (params[UrlParamsService.TEXT]) {
      return params[UrlParamsService.TEXT];
    }
  }

  /**
   * Retrieves sort from parameters
   * @param params
   */
  public getSort(params): string {
    if (params[UrlParamsService.SORT]) {
      return params[UrlParamsService.SORT];
    }
  }

  /**
   * Retrives orderHash from parameters
   * @param params
   */
  public getOrderAuthCode(params) {
    if (params[UrlParamsService.AUTH_CODE]) {
      return params[UrlParamsService.AUTH_CODE];
    }
  }

  /**
   * Retrives orderRefNo from parameters
   * @param params
   */
  public getOrderRefNo(params) {
    if (params[UrlParamsService.ORDER_REF_NO]) {
      return params[UrlParamsService.ORDER_REF_NO];
    }
  }

  public getOrderId(params) {
    if (params[UrlParamsService.ORDER_ID]) {
      return params[UrlParamsService.ORDER_ID];
    }
  }

  /**
   * Retrives customer reference number from parameters
   * @param params
   */
  public getCuRefNo(params): string {
    if (params[UrlParamsService.CU_REF_NO]) {
      return params[UrlParamsService.CU_REF_NO];
    }
    return undefined;
  }

  public static mergeParams(params1: Record<string, string>, params2: Record<string, string>): Record<string, string> {
    let params: Record<string, string> = {};

    for (const key in params1) {
      params[key] = params1[key];
    }

    for (const key in params2) {
      params[key] = params2[key];
    }

    return params;
  }
}
