import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WishListDetailDto } from '@btl/order-bff';
import { WishListService } from '@btl/btl-fe-wc-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-wish-list-popup',
  templateUrl: './delete-wish-list-popup.component.html',
})
export class DeleteWishListPopupComponent {
  @Input()
  wishList: WishListDetailDto;
  @Input() dialogRef;
  @Input() redirectBack?: boolean = false;

  @Output()
  readonly deleteEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(private wishListService: WishListService, private router: Router) {}

  closePopup() {
    this.dialogRef.dismiss();
  }

  deleteWishList() {
    this.wishListService.deleteWishList(this.wishList.id);
    this.redirectBack ? this.router.navigate(['/eshop/wish-lists']) : false;
    this.dialogRef.dismiss();
  }
}
