<a
  *ngIf="comparedProducts && comparedProducts.length > 0"
  id="001_compared_products_bar"
  style="cursor: pointer"
  class=""
  [routerLink]="getComparisonLink(comparedProducts)">
  <div class="">
    <span class=""></span>
    <div class="">{{ comparedProducts.length }}</div>
  </div>
</a>
<div #stickyBarContent class="sticky-content sticky-content--bottom sticky__compare">
  <div *ngIf="comparedProducts && comparedProducts.length > 0" class="container">
    <div class="comparisonBarLabel">{{ 'wc.shopping.gui_productComparison.bar.comparisoBarLabel' | translate }}</div>
    <div class="sticky-bar">
      <div class="sticky-bar_col item">
        <ng-container *ngFor="let number of [0, 1, 2]; let i = index">
          <div *ngIf="comparedProducts[i] !== undefined" class="sticky-bar_row">
            <!-- Image -->
            <img
              *ngIf="comparedProducts[i].image"
              [lazyLoad]="'/api/bff/order/v1/img/' + comparedProducts[i].image"
              [defaultImage]="'/assets/img/products/loading.gif'"
              (onStateChange)="imgStateChange($event)" />
            <img *ngIf="!comparedProducts[i].image" src="/assets/img/products/phone_example-listing.png" />

            <!-- hardware name -->
            <div>
              {{ comparedProducts[i].nameCs || comparedProducts[i].nameEn }}
            </div>

            <!-- remove icon -->
            <div
              class="delete-icon"
              placement="top"
              ngbTooltip="{{ 'wc.shopping.gui_productDetail.productComparison.removeLink' | translate }}"
              (click)="removeFromComparison(comparedProducts[i].seoUrl)">
              <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
            </div>
          </div>

          <!-- empty/default placeholder -->
          <div *ngIf="comparedProducts[i] === undefined" class="sticky-bar_row empty">
            <div class="image">
              <i class="fa fa-clone empty-image" aria-hidden="true" id="{{ i }}_004_compared_products_bar"></i>
            </div>
            <div class="phoneName empty">
              <span class="">{{ i + 1 }}. {{ 'wc.shopping.gui_productComparison.bar.emptyPosition' | translate }}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- main actions -->
      <div class="sticky-bar_col --buttons">
        <app-button
          label="{{ 'wc.shopping.gui_productComparison.bar.comapareHwButton' | translate }}"
          [routerLink]="getComparisonLink(comparedProducts)"></app-button>
        <app-button
          variant="secondary"
          label="{{ 'wc.shopping.gui_productComparison.bar.removeAllHwLink' | translate }}"
          (click)="removeAllFromComparison()">
        </app-button>
      </div>
    </div>
  </div>
</div>
