<div class="arrow" (click)="prevAlert()" *ngIf="alertSubscriptionsUsages.length > 0">
  <div class="arrow-inner">
    <i class="fa fa-chevron-left"></i>
  </div>
</div>
<div class="arrow right" (click)="nextAlert()" *ngIf="alertSubscriptionsUsages.length > 0">
  <div class="arrow-inner">
    <i class="fa fa-chevron-right"></i>
  </div>
</div>

<div class="widget--header">
  <ng-container *ngIf="alertSubscriptionsUsages.length > 0; else noAlerts">
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/usage-alerts.svg'"></i>
      <h6>{{ 'wc.ecare.widgets.usageAlert.header' | translate }}</h6>
    </div>
    <div class="widget--header_account">{{ subscriptionServiceAlert?.subIdentification }}</div>
  </ng-container>
  <ng-template #noAlerts>
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/usage-alerts.svg'"></i>
      <h6>{{ 'wc.ecare.widgets.usageAlert.noCurrentUsageAlert' | translate }}</h6>
    </div>
  </ng-template>
</div>

<div class="widget--content shrinked-gap arrows" *ngIf="alertSubscriptionsUsages.length > 0">
  <app-ecare-widget-main-info
    period="{{ 'wc.ecare.widgets.ofDataHaveBeenUsed' | translate }}"
    [price]="
      subscriptionServiceAlert
        ? subscriptionServiceAlert.usedPercentage + '% '
        : ('wc.ecare.widgets.usageAlert.noAlerts' | translate)
    "
    [priceFormat]="false">
  </app-ecare-widget-main-info>
  <p *ngIf="subscriptionServiceAlert" class="tip-note">
    {{ 'wc.ecare.widgets.usageAlert.hint01.' + subscriptionServiceAlert.serviceType | translate }}
  </p>
</div>
<div class="widget--cta">
  <div
    *ngIf="subscriptionServiceAlert && isPostpaid(subscriptionServiceAlert.tariffProduct)"
    app-button
    class="change-tariff-btn"
    routerLink="/eshop/tariff-change"
    variant="whiteWithBorder"
    size="fullWidth"
    [queryParams]="{
      tariffSpaceId: subscriptionServiceAlert.subIdentification,
      tariffId: subscriptionServiceAlert.tariffId,
      customerAccountId: customerAccount?.id ? customerAccount.id : subscriptionServiceAlert.subParentId,
      locationId: subscriptionServiceAlert.locationId,
      socketId: subscriptionServiceAlert.socketId
    }"
    [label]="'wc.ecare.dashboard.upgradeData' | translate"></div>
</div>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
