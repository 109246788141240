import { Injectable } from '@angular/core';
import {
  BasePropertyAccessorLocalService,
  CmsWidgetVideoSourceType,
  PropertyAccessorService
} from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { Constants } from '../constants';
import { map } from 'rxjs/operators';

/**
 * Service for accessing properties from property accessor while caching them to localStorage
 */
@Injectable({
  providedIn: 'root',
})
export class PropertyAccessorLocalService extends BasePropertyAccessorLocalService {
  private static readonly MODULE_NAME: string = 'btl-fe-wc';
  private static readonly STORAGE_NAME: string = 'propertyAccessor';
  private static readonly MAX_COMPARISON_HW_COUNT: string = 'mobilePhone.maxComparisonHwCount';
  private static readonly MAX_AUTOCOMPLETE_RESULTS: string = 'gui.productListing.searching.maxAutocompleteResults';
  private static readonly ADD_TO_BASKET_DEEPLINK_URL: string = 'gui.common.link.addToBasket';
  private static readonly GLOBAL_DELIVERY_DELAY: string = 'global.deliveryDelay';
  private static readonly GLOBAL_CENTRAL_STORE_ID = 'global.centralStoreId';
  private static readonly SIMILAR_PRODUCT_COUNT: string = 'gui.productDetail.similarProductCount';
  private static readonly VIEWED_PRODUCT_COUNT: string = 'gui.productDetail.viewedProductCount';
  private static readonly SOLD_PRODUCT_COUNT: string = 'gui.productDetail.soldProductCount';
  private static readonly CUSTOMER_BOUGHT_TTL: string = 'gui.productDetail.customersBoughtTtl';
  private static readonly CUSTOMER_BOUGHT_MIN_COUNT: string = 'gui.productDetail.customersBoughtMinCount';
  private static readonly ACCESSORIES_COUNT: string = 'gui.productDetail.accessoriesCount';
  private static readonly KEY_FEATURES_COUNT: string = 'gui.productCard.keyFeaturesCount';
  private static readonly PAGE_SIZE: string = 'gui.productListing.pageSize.default.largeScreen';
  private static readonly LOAD_MORE_PAGE_SIZE: string = 'gui.productListing.pageSize.loadMore.largeScreen';
  private static readonly TECHNICAL_SUPPORT_URL: string = 'global.technicalSupportUrl';
  private static readonly MORE_REVIEW_AMOUNT: string = 'gui.productDetail.rating.reviewCount';
  private static readonly MIN_RATING_LIMIT: string = 'global.minRatingLimit';
  private static readonly INTERNAL_DASHBOARD_LINK: string = 'gui.common.link.internalDashboard';
  private static readonly INTERNAL_USER_DETAIL_LINK: string = 'gui.common.link.internalUserDeatail';
  private static readonly WEB_CLIENT_CHANNEL: string = 'gui.webClient.channel';
  private static readonly DEFAULT_DUE_DATE: string = 'default.dueDate';
  private static readonly GLOBAL_HOME_COUNTRY: string = 'global.homeCountry';
  private static readonly PHONE_NUMBER_FORMAT_COUNTRY: string = 'global.phoneNumberFormatCountry';
  private static readonly CHAT_ENABLED: string = 'gui.global.chatEnabled';
  private static readonly CHAT_SRC: string = 'gui.global.chatSrc';
  private static readonly CHAT_TOKEN: string = 'gui.global.chatToken';
  private static readonly CHAT_COMPANY: string = 'gui.global.chatCompany';
  private static readonly CMS_WIDGET_VIDEO_SOURCE: string = 'gui.cmsWidget.video.source.';
  private static readonly DASHBOARD_PRODUCT_CATEGORY: string = 'gui.dashboard.productCategory';
  private static readonly QUOTES_DEFAULT_VALIDITY_PERIOD: string = 'global.quotes.defaultValidityPeriod';
  private static readonly EXT_USER_AUTH_MODE: string = 'global.order.extUserAuthMode';
  private static readonly PICK_UP_POINT_MAP_PROVIDER: string = 'gui.pickUpPoint.map.provider';
  private static readonly GOOGLE_MAP_API_KEY: string = 'gui.googleMap.apiKey';
  private static readonly BASE_URL: string = 'global.baseUrl';
  private static readonly CMB_ENABLED: string = 'gui.eshop.callMeBack.enabled';
  private static readonly REVIEW_VIEW_TYPE: string = 'gui.productDetail.review.viewType';
  private static readonly MODULE_BFF_CRM_ENABLED: string = 'modules.bff-crm.enabled';
  private static readonly STOCK_AVALIBILITY_ENABLED: string = 'gui.eshop.stockAvalibility.enabled';
  private static readonly SWITCH_VAT_BUTTON_VISIBLE: string = 'gui.eshop.switchVatButton.visible';
  private static readonly WIDGET_SWITCH_INTERNAL_SECONDS: string = 'gui.dashboard.widget.switchInternalSeconds';
  private static readonly WIDGET_USAGE_ALERTS_TRIGGER_PERCENTAGE: string = 'gui.dashboard.widget.usageAlert.triggerPercentage';

  constructor(protected propertyAccessorService: PropertyAccessorService) {
    super(
      PropertyAccessorLocalService.MODULE_NAME,
      PropertyAccessorLocalService.STORAGE_NAME,
      Constants.PROPERTIES_TTL,
      propertyAccessorService,
    );
  }

  public getWidgetSwitchInternalSeconds(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.WIDGET_SWITCH_INTERNAL_SECONDS);
  }

  public getWidgetUsageAlertTriggerPercentage(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.WIDGET_USAGE_ALERTS_TRIGGER_PERCENTAGE);
  }

  public getMaxComparisonHwCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.MAX_COMPARISON_HW_COUNT);
  }

  public getMaxAutocompleteResults(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.MAX_AUTOCOMPLETE_RESULTS);
  }

  public getAddToBasketDeeplinkUrl(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.ADD_TO_BASKET_DEEPLINK_URL);
  }

  public getGlobalDeliveryDelay(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.GLOBAL_DELIVERY_DELAY);
  }

  public getCentralStockCode(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GLOBAL_CENTRAL_STORE_ID);
  }

  public getViewedProductCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.VIEWED_PRODUCT_COUNT);
  }

  public getSoldProductCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.SOLD_PRODUCT_COUNT);
  }

  public getCustomersBoughtTtl(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.CUSTOMER_BOUGHT_TTL);
  }

  public getCustomersBoughtMinCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.CUSTOMER_BOUGHT_MIN_COUNT);
  }

  public getSimilarProductCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.SIMILAR_PRODUCT_COUNT);
  }

  public getAccessoriesCount(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.ACCESSORIES_COUNT);
  }

  public getKeyFeaturesCount() {
    return this.getNumberProperty(PropertyAccessorLocalService.KEY_FEATURES_COUNT);
  }

  public getPageSize(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.PAGE_SIZE);
  }

  public getLoadMorePageSize(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.LOAD_MORE_PAGE_SIZE);
  }

  public getMoreReviewAmount(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.MORE_REVIEW_AMOUNT);
  }

  public getMinRatingLimit(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.MIN_RATING_LIMIT);
  }

  /**
   * Returns url address with technical support
   */
  public getTechnicalSupportUrl(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.TECHNICAL_SUPPORT_URL);
  }

  public getInternalDashboardLink(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.INTERNAL_DASHBOARD_LINK);
  }

  public getInternalUserDetailLink(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.INTERNAL_USER_DETAIL_LINK);
  }

  public getWebClientChannel(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.WEB_CLIENT_CHANNEL);
  }

  public getDefaultDueDate(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.DEFAULT_DUE_DATE);
  }

  public getGlobalHomeCountry(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GLOBAL_HOME_COUNTRY);
  }

  public getPhoneNumberFormatCountry(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.PHONE_NUMBER_FORMAT_COUNTRY);
  }

  public getChatEnabled(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CHAT_ENABLED);
  }

  public getChatSrc(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CHAT_SRC);
  }

  public getChatToken(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CHAT_TOKEN);
  }

  public getChatCompany(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CHAT_COMPANY);
  }

  public getCmsWidgetVideoBaseSourceLink(sourceType: CmsWidgetVideoSourceType): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CMS_WIDGET_VIDEO_SOURCE + sourceType);
  }

  public getDashboardProductCategory(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.DASHBOARD_PRODUCT_CATEGORY);
  }

  public getQuotesDefaultValidityPeriod(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.QUOTES_DEFAULT_VALIDITY_PERIOD);
  }

  public getExtUserAuthMode(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.EXT_USER_AUTH_MODE);
  }

  public getPickUpPointMapProvider(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.PICK_UP_POINT_MAP_PROVIDER);
  }

  public getGoogleMapApiKey(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GOOGLE_MAP_API_KEY);
  }

  public getBaseUrl(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.BASE_URL);
  }

  public getCallMeBackBoolean(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CMB_ENABLED);
  }

  public getReviewViewType(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.REVIEW_VIEW_TYPE);
  }

  public isModuleBffCrmEnabled(): Observable<boolean> {
    return this.getStringProperty(PropertyAccessorLocalService.MODULE_BFF_CRM_ENABLED).pipe(
      map(value => !value || value.toLowerCase() === 'true')
    );
  }

  public getStockAvalibilityBoolean(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.STOCK_AVALIBILITY_ENABLED);
  }

  public getSwitchVatButtonVisibleBoolean(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.SWITCH_VAT_BUTTON_VISIBLE);
  }
}
