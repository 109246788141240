import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ProductPictureHrefsDto } from '@btl/order-bff';
import { PictureTypeEnum } from 'app/models/PictureTypeEnum';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition, YoutubeItem } from 'ng-gallery';

@Component({
  selector: 'app-product-gallery, [app-product-gallery]',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.scss'],
})
export class ProductGalleryComponent implements OnInit {
  @Input()
  pictures: Array<ProductPictureHrefsDto>;

  @Input()
  videoUrl: string;

  @Input()
  name;

  @Input()
  color;

  images: GalleryItem[];

  imagesData: any = [];

  constructor(private gallery: Gallery) {
  }

  ngOnInit() {
    this.initPictureAndVideoGallery(this.pictures, this.videoUrl);
  }

  initPictureAndVideoGallery(pictures: Array<ProductPictureHrefsDto>, videoUrl) {
    this.imagesData = [];
    this.images = [];
    this.videoUrl = videoUrl;
    this.gallery.resetAll();
    if (pictures) {
      pictures.forEach(productPictureHrefs => {
        const midSizePicture = productPictureHrefs.pictureMap[PictureTypeEnum.PRODUCT_GENERAL];
        const thumbPicture = productPictureHrefs.pictureMap[PictureTypeEnum.PRODUCT_GENERAL_THUMBNAIL];
        if (midSizePicture && thumbPicture) {
          this.imagesData.push({
            srcUrl: midSizePicture.href,
            thumbUrl: thumbPicture.href,
          });
        }
      });
    }

    if (this.imagesData.length < 1) {
      this.imagesData.push({
        srcUrl: 'assets/img/products/phone_example.png',
        thumbUrl: 'assets/img/products/phone_example-small.png',
      });
    }

    this.images = this.imagesData.map(image => new ImageItem({ src: image.srcUrl, thumb: image.thumbUrl }));
    if (this.videoUrl) {
      this.images = this.images.concat([new YoutubeItem({ src: this.videoUrl })]);
    }

    this.customGalleryModal();
  }

  customGalleryModal() {
    const galleryModal = this.gallery.ref('productGalleryModal');
    galleryModal.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Left,
      thumbAutosize: true,
      thumbHeight: 90,
      thumbWidth: 60,
      counter: false,
      thumb: window.innerWidth > 600,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.customGalleryModal();
    this.nameAndColor();
  }

  nameAndColor() {
    // Create new divs to lightbox

    // main div
    if (window.innerWidth > 600) {
      const divMain = document.createElement('div');
      divMain.className = 'productName';

      // div for name
      const divProductName = document.createElement('div');
      divProductName.className = 'productGallery-modal';

      const productName = document.createElement('h3');
      productName.className = 'productGallery-modal-heading';
      productName.innerText = this.name;

      divProductName.appendChild(productName);

      // div for color

      const divProductColor = document.createElement('div');
      divProductColor.className = 'productGallery-modal';

      const productColor = document.createElement('h3');
      productColor.className = 'productGallery-modal-small';

      //productColor.innerText = "Barva: " + this.color;

      divProductColor.appendChild(productColor);

      //add name&color to main div

      divMain.appendChild(divProductName);
      divMain.appendChild(divProductColor);

      // add div to lightbox

      const galleryCoreDiv = document.getElementsByTagName('gallery-core')[1];
      if (galleryCoreDiv) {
        galleryCoreDiv.appendChild(divMain);
      }
    }
  }
}
