<div [formGroup]="form">
  <ng-container
    *ngTemplateOutlet="onetimePaymentMethods.length > 1 ? multiPaymentMethods : singlePaymentMethod"></ng-container>
  <!-- Multi payment method -->
  <ng-template #multiPaymentMethods>
    <app-section-header
      id="collapseHeader"
      [title]="'wc.shopping.customerCreation.paymentMethod.heading' | translate"
      [isCollapsed]="refEl.activeIds.length > 0"
      (click)="acc.toggle('togglePaymentMethods')">
    </app-section-header>
    <ngb-accordion #acc="ngbAccordion" #refEl activeIds="togglePaymentMethods" [destroyOnHide]="false">
      <ngb-panel id="togglePaymentMethods">
        <ng-template ngbPanelContent>
          <div class="form">
            <ng-container *ngFor="let opt of onetimePaymentMethods">
              <input
                class="paymentMethod-input"
                type="radio"
                formControlName="onetimePaymentMethod"
                id="{{ 'paymentMethod.' + opt.productCode }}"
                [value]="opt.productCode"
                (change)="changePaymentProduct(opt)"/>
              <label
                class="single-method paymentMethod halfColumn"
                for="{{ 'paymentMethod.' + opt.productCode }}"
                title="{{ opt.name }}">
                <div class="single-method_leftSide">
                  <div class="icon-container">
                    <img
                      alt="{{ opt.name }}"
                      src="{{
                        opt.picturePreview
                          ? opt.picturePreview.href
                          : '/assets/img/payment-methods/' + opt.productCode + '-logo.png'
                      }}" />
                  </div>
                  {{ opt.name }}
                </div>
                <div class="single-method_rightSide">
                  <div
                    class="single-method_priceSide"
                    [innerHTML]="
                      opt.prices['OC'] && opt.prices['OC'].price > 0 ? (opt.prices['OC'].price | price | safeHtml) : (0 | price | safeHtml)
                    "></div>
                  <span class="checkmark"></span>
                </div>
              </label>
            </ng-container>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-template>
  <!-- Single payment method -->
  <ng-template #singlePaymentMethod>
    <div
      app-section-header
      class="single-method"
      [title]="'wc.shopping.customerCreation.paymentMethod.heading' | translate">
      <div class="single-method_leftSide">
        <div class="icon-container white">
          <img
            src="{{ onetimePaymentMethods[0].picturePreview
                      ? onetimePaymentMethods[0].picturePreview.href
                      : '/assets/img/payment-methods/' + onetimePaymentMethods[0].productCode + '-logo.png' }}"
            alt="{{ onetimePaymentMethods[0].name }}" />
        </div>
        {{ onetimePaymentMethods[0].name }}
      </div>
      <div
        class="single-method_priceSide"
        [innerHTML]="
          onetimePaymentMethods[0].prices['OC']
            ? (onetimePaymentMethods[0].prices['OC'].price | price | safeHtml)
            : (0 | price | safeHtml)
        "></div>
    </div>
  </ng-template>
</div>
