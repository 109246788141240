import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductDetailDto } from '@btl/order-bff';

@Component({
  selector: 'app-payment-method, [app-payment-method]',
  templateUrl: './payment-method.component.html',
})
export class PaymentMethodComponent {
  @Input() onetimePaymentMethods: Array<ProductDetailDto>;
  @Input() selectedPaymentProduct: ProductDetailDto;
  @Input() changePaymentProduct: Function;
  @Input() public form: FormGroup;
}
