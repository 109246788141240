<app-ecare-mobile-row-general>
  <div class="order-row-wrapper" (click)="buttonInRowClick($event, order)">
    <div class="order-row-top">
      <div class="left-part">
        <div class="id-part">
          <div class="order-id-wrapper">
            <div>Order {{ order.id }}</div>
            <i *ngIf="isBlocked" [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
            <i *ngIf="isProcessingError" class="error" [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
          </div>
          <app-sticker [variant]="getStickerType()">
            {{ 'wc.common.orderState.' + order.orderCalcStateType | translate }}
          </app-sticker>
        </div>
        <div class="created-part">
          {{ order.created | date : 'dd.MM.yyyy HH:mm' }}
        </div>
      </div>
      <ng-container *ngIf="displayMode === 'lastOderWidget'">
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
      </ng-container>
      <div class="right-part">
        <one-time-price *ngIf="totalPriceOC" [price]="totalPriceOC"></one-time-price>
        <app-recurrent-price
          *ngIf="totalPriceRC"
          cmsLabelKey="wc.shopping.confirmation.price.RC.label"
          [price]="totalPriceRC"></app-recurrent-price>
      </div>
      <ng-container *ngIf="displayMode === 'lastOderWidget'">
        <ng-container *ngTemplateOutlet="resumeButton"></ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="displayMode === 'default'">
      <div class="order-row-bottom">
        <div class="left-part">
          <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
        </div>
        <div class="right-part">
          <ng-container *ngTemplateOutlet="resumeButton"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</app-ecare-mobile-row-general>

<ng-template #thumbnail>
  <app-thumbnail-container
    [thumbnailsForRow]="thumbnailsForRow"
    [unshowedThumbnails]="unshowedThumbnails"
    [tooltipPosition]="'right'">
  </app-thumbnail-container>
</ng-template>

<ng-template #resumeButton>
  <app-button
    *ngIf="order.orderStateType === orderStateTypeEnum.CREATED && order.orderItems.length > 0"
    id="resume-order-button"
    variant="whiteWithBorder"
    size="medium"
    label="{{ 'wc.ecare.orders.resume.button' | translate }}"
    (click)="resumeOrder(order, $event)">
  </app-button>
</ng-template>
