import { Pipe, PipeTransform } from "@angular/core";
import { PhoneNumberPipe } from "@btl/btl-fe-wc-common";

@Pipe({
  name: 'displayContact',
})
export class DisplayContactPipe implements PipeTransform {
  constructor(private phoneNumberPipe: PhoneNumberPipe) {}

  transform(contact, preferredChannel?): string {
    return `${contact.firstName ? contact.firstName : ""} ${contact.lastName ? contact.lastName : ""}
    ${contact.firstName || contact.lastName ? ", " : ""} ${
      contact.phone1
        ? preferredChannel === ContactPreferredChannels.SMS || preferredChannel === ContactPreferredChannels.ALL
          ? `<b>${this.formatPhoneNumber(contact.phone1)}</b>`
          : this.formatPhoneNumber(contact.phone1)
        : ""
    }${contact.phone1 && contact.email ? ", " : ""} ${
      contact.email
        ? preferredChannel === ContactPreferredChannels.EMAIL || preferredChannel === ContactPreferredChannels.ALL
          ? `<b>${contact.email}</b>`
          : contact.email
        : ""
    }`;
  }

  private formatPhoneNumber(phoneNumber: string): string {
    try {
      return this.phoneNumberPipe.transform(phoneNumber);
    } catch (Error: any) {
      return phoneNumber;
    }
  }
}

export enum ContactPreferredChannels {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  ALL = 'ALL',
  NONE = 'NONE',
}
