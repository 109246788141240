import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShoppingCartItemsComponent } from '../shopping-cart-items/shopping-cart-items.component';
import { BlockTemplateComponent, GroupTreeNode } from '@btl/btl-fe-wc-common';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { BlockUIService } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderingWizardService, StepTypeInfo } from '@service/ordering-wizard-service';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { CustomerMenuComponent } from './customer-menu/customer-menu.component';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('iconShoppingCartItems', { static: false }) shoppingCartItemsComponent: ShoppingCartItemsComponent;
  @ViewChild('headerElement') headerElement: ElementRef;
  @ViewChild('customerMenu') customerMenu: CustomerMenuComponent;
  @ViewChild('topMenu') topMenu: TopMenuComponent;

  blockTemplate = BlockTemplateComponent;

  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;
  doNotRedirectAfterLogin: boolean = false;
  checkout: boolean = false;

  SHRINK_CSS_CLASS_NAME: string = 'header-shrink';
  shrinkCssClass: string = '';
  headerElementHeight: number;

  public currentProductGroup: GroupTreeNode;
  isContextLoaded: boolean = false;
  customer = null;
  contextHealthScore: string;
  stepTypesInfos: Array<StepTypeInfo>;

  topMenuGroups: Array<GroupTreeNode>;
  showMobileMenu: boolean = false;

  get preCalculatedShoppingCart(): PrecalculatedShoppingCart {
    return this.shoppingCartService.preCalculatedShoppingCart;
  }

  constructor(
    private shoppingCartService: ShoppingCartService,
    public router: Router,
    private route: ActivatedRoute,
    public customerLocalStorageService: CustomerLocalStorageService,
    private blockUIService: BlockUIService,
    private orderingWizardService: OrderingWizardService
  ) {
    this.customerLocalStorageService.contextChanged.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.blockUIService.stop('contextCustomer');
      this.loadContext();
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.route.url.pipe(takeUntil(this.onDestroy$)).subscribe(urlParts => {
      if (urlParts?.map(segment => segment.path).includes('ecare')) {
        this.navigationType = NavigationTypeEnum.ecare;
      } else {
        this.navigationType = NavigationTypeEnum.eshop;
      }

      if (urlParts?.map(segment => segment.path).includes('eshop')) {
        this.doNotRedirectAfterLogin = true;
      } else {
        this.doNotRedirectAfterLogin = false;
      }
    });
    this.checkCheckoutProcess();
    this.loadContext();
  }

  checkCheckoutProcess() {
    this.stepTypesInfos = Array.from(this.orderingWizardService.stepTypesInfos.values());
    this.stepTypesInfos.find(step => {
      if (step.url === this.router.url) {
        this.checkout = true;
        this.navigationType = NavigationTypeEnum.checkout;
      }
    });
  }

  loadContext(): void {
    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    this.isContextLoaded = !!this.customer;
    if (this.isContextLoaded) {
      this.contextHealthScore = this.customer.healthScore; /*CUSTOMER_HEALTH codebook:{GREEN, AMBER, RED} */
    }
  }

  ngAfterViewInit(): void {
    this.headerElementHeight = this.headerElement.nativeElement.offsetHeight;
  }

  getTopMenuGroups(topMenuGroups) {
    this.topMenuGroups = topMenuGroups;
  }

  onShowMenuChanged(showMenu: boolean) {
    this.showMobileMenu = showMenu;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTopPosition = window.document.documentElement.scrollTop;
    this.shrinkCssClass = scrollTopPosition > this.headerElementHeight ? this.SHRINK_CSS_CLASS_NAME : '';
  }
}

export enum NavigationTypeEnum {
  ecare = 'ecare',
  eshop = 'eshop',
  checkout = 'checkout',
}
