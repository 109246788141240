import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductParameter } from '../product-comparison.component';

@Component({
  selector: '[app-compared-product-parameter]',
  templateUrl: './compared-product-parameter.component.html',
  styleUrls: ['./compared-product-parameter.component.scss'],
})
export class ComparedProductParameterComponent implements OnInit {
  @Input() parameter: ProductParameter;
  @Input() paramHeights: Map<string, Array<ComparedProductParameterComponent>>;
  @Input() blockLength: number;
  @Input() index: number;
  @Input() isLastColumn: boolean;
  public maxHeight: number;
  @ViewChild('paramBlock', { static: true }) public paramBlock: ElementRef;

  ngOnInit() {
    let paramComponent = this.paramHeights.get(this.parameter.name);
    if (!paramComponent) {
      paramComponent = [this];
      this.paramHeights.set(this.parameter.name, paramComponent);
    } else {
      paramComponent.push(this);
    }
  }

  public countHeights() {
    for (const paramComponent of this.paramHeights.get(this.parameter.name)) {
      if (!this.maxHeight || this.maxHeight < parseInt(paramComponent.paramBlock.nativeElement.offsetHeight)) {
        this.maxHeight = parseInt(paramComponent.paramBlock.nativeElement.offsetHeight);
      }
    }
    for (const paramComponent of this.paramHeights.get(this.parameter.name)) {
      if (paramComponent.maxHeight < this.maxHeight) {
        paramComponent.maxHeight = this.maxHeight;
      }
    }
  }
}
