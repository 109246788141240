import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isWithBreadcrumb?: boolean;

  displayInRow: boolean = true;

  constructor(private responsive: BreakpointObserver) {}

  ngOnInit() {
    this.responsive.observe([Breakpoints.XSmall]).subscribe(result => {
      result.matches ? (this.displayInRow = false) : (this.displayInRow = true);
    });
  }
}
