<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<ng-container>
  <div *ngIf="showBreadcrumbs" class="breadcrumb" app-ecare-breadcrumb></div>
  <app-page-header *ngIf="title" [isWithBreadcrumb]="showBreadcrumbs" [title]="title">
    <ng-content select="[page-actions]"></ng-content>
  </app-page-header>
  <div class="ecare-layout" [ngStyle]="{ 'grid-template-columns': columnTemplate }">
    <ng-content></ng-content>
  </div>
</ng-container>
