import { EventEmitter, Injectable } from '@angular/core';
import {
  AbstractService,
  AccountService,
  AuthFactoryService,
  ErrorOnEnum,
  OrderingService,
  ProductService,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { OrderErrorHandler } from 'app/services/errors/order-error-handler';
import {
  CustomerDto,
  OrderAsMapDto,
  OrderAvailabilityFrontendService,
  OrderDto,
  OrderFrontendService,
  OrderitemAttributeDto,
  OrderItemDto, PartyDto,
  ProductDetailDto,
  ShoppingCartFrontendService
} from '@btl/order-bff';
import { ShoppingCartService } from './shopping-cart.service';
import { Keys } from './keys';
import { Router } from '@angular/router';
import { CategoryTypeEnum } from '../models/product-filter';
import { CustomerLocalStorageService } from './customer-local-storage.service';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { ProductInShoppingCart } from 'app/models/product-in-shopping-cart';
import { CustomerService } from 'app/services/customer.service';
import { OrderStateTypeEnum } from '../models/orderStateTypeEnum';
import { FormGroup } from '@angular/forms';
import { CheckAvailabilityDto } from '@btl/order-bff/model/checkAvailabilityDto';
import { StockAvailabilityDto } from '@btl/order-bff/model/stockAvailabilityDto';
import { StockCustomService } from '@service/stock-custom.service';
import { ProductAddingToCart } from '@service/product-listing.service';
import { ProductUtils } from '../helpers/product-utils';
import { PaymentService } from '@service/payment.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { OrderParamDto } from '@btl/order-bff';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';

/**
 * {@code OrderingService} is a service encapsulating operations related to ordering (operations on orders and their
 * items.
 */
@Injectable()
export class WcOrderingService extends AbstractService {
  private static customerOrderAttribute = 'customer';
  private static customerAccountOrderAttribute = 'customerAccount';

  doNotUnBlockOneCall = false;

  readonly allowedCategoriesForScenarios = new Map([
    [
      ScenarioTypeEnum.PRODUCT_MANAGEMENT,
      [
        { category: CategoryTypeEnum.PRD_L_HW, maximum: undefined },
        { category: CategoryTypeEnum.PRODC, maximum: undefined },
        { category: CategoryTypeEnum.SCITYC, maximum: undefined },
      ],
    ],
    [
      ScenarioTypeEnum.QUOTE_MANAGEMENT,
      [
        { category: CategoryTypeEnum.PRD_L_HW, maximum: undefined },
        { category: CategoryTypeEnum.PRODC, maximum: undefined },
      ],
    ],
    [ScenarioTypeEnum.PRP_REGISTRATION, []],
    [ScenarioTypeEnum.PRP_RECHARGE, null],
    [ScenarioTypeEnum.CHANGE_SIM, null],
    [ScenarioTypeEnum.INVOICE_PAYMENT, null],
  ]);

  orderChanged = new EventEmitter<OrderDto>();
  currentOrder: OrderDto;

  constructor(
    private orderingService: OrderingService,
    private shoppingCartService: ShoppingCartService,
    private productService: ProductService,
    private bffOrderingService: OrderFrontendService,
    private bffShoppingCartService: ShoppingCartFrontendService,
    private orderAvailabilityFrontendService: OrderAvailabilityFrontendService,
    protected logger: NGXLogger,
    protected errorHandler: OrderErrorHandler,
    private router: Router,
    private customerLocalStorageService: CustomerLocalStorageService,
    private authFactoryService: AuthFactoryService,
    private customerService: CustomerService,
    private accountService: AccountService,
    private stockCustomService: StockCustomService,
    private stickyMessageService: StickyMessageService,
    private paymentService: PaymentService,
    public ngbModal: NgbModal
  ) {
    super(errorHandler);
    errorHandler.orderingService = this;
    customerLocalStorageService.contextChanged.subscribe(this.handleContextChanged);
  }

  //region Operations:

  /**
   * Get an order from BFF by its orderRefNo.
   *
   * TODO: Security is missing. An unauthenticated user must get just anonymized order if he uses a different session.
   * An authenticated user will get full order only if he is the one who created the order or if he has some specific
   * privileges (back office operator).
   *
   * @param {string} orderRefNo The orderRefNo identifying the order.
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public getOrderByRefNo(orderRefNo: string, preventHandling: boolean = false): Observable<OrderDto> {
    if (!orderRefNo) {
      throw new Error('OrderRefNo must be configured.');
    }

    const errorMessage = `Getting order by orderRefNo '${orderRefNo}' failed.`;
    if (preventHandling) {
      return this.bffOrderingService
        .getOrderById(orderRefNo, this.orderingService.getOrderAuthCode())
        .pipe(catchError(this.handleError(errorMessage, null)));
    }
    return this.bffOrderingService
      .getOrderById(orderRefNo, this.orderingService.getOrderAuthCode())
      .pipe(
        mergeMap(v => {
          if (v.accountId && !this.authFactoryService.getAuthService().account) {
            const newOrderDto: OrderDto = OrderUtils.getInitOrder(
              ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
              ScenarioTypeEnum.PRODUCT_MANAGEMENT
            );
            return new Observable<OrderDto>(observer => {
              this.authFactoryService
                .getAuthService()
                .isLoggedIn()
                .then(loggedin => {
                  if (loggedin) {
                    observer.next(v);
                  } else {
                    this.createOrder(newOrderDto, true).subscribe(orderDto => observer.next(orderDto));
                  }
                });
            });
          } else {
            return of(v);
          }
        })
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(orderRefNo))
      )
      .pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
  }

  protected orderAccessDeniedAndNotFoundErrorHandler(orderRefNo: string): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const notFoundError = error.status === 404;
      const foundFailure = error.error?.failures?.find(failure => failure.code === 403);

      if (foundFailure || notFoundError) {
        if (foundFailure) {
          this.logger.error(`Access denied to order with orderRefNo '${orderRefNo}', new order will be created.`);
        } else {
          this.logger.error(`Order with orderRefNo '${orderRefNo}' no longer exists, new order will be created.`);
        }
        return new Observable<OrderDto>(observable => {
          const newOrderDto: OrderDto = OrderUtils.getInitOrder(
            ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
            ScenarioTypeEnum.PRODUCT_MANAGEMENT
          );
          this.createOrder(newOrderDto, true).subscribe(order => {
            observable.next(order);
          });
        });
      }
      throw error;
    };
  }

  /**
   * Search orders by ID.
   *
   * @param orderId The ID of the searched order.
   * @return Found order or null if there is no such order
   */
  public searchOrders(orderId: string): Observable<OrderDto> {
    if (!orderId) {
      return of(null);
    }

    return this.bffOrderingService.getOrderById(orderId, this.orderingService.getOrderAuthCode()).pipe(
      catchError((error: any) => {
        if (error && error.status && error.status === 404) {
          return of(null);
        }

        const errorMessage = `Searching order by orderId '${orderId}' failed.`;
        return this.handleError(errorMessage, null)(error);
      })
    );
  }

  /**
   * Suspends order by given id, patches the order with {@code orderAsMap} and remove order from context.
   * Map must contain orderStateType. Order must be in state {@code CREATED}
   * @param id The id of the order
   * @param orderAsMap Order to save, must contain field orderStateType
   * @param email Email to send order info to
   */
  public suspendOrder(id: string, orderAsMap: OrderAsMapDto, email?: string): Observable<OrderDto> {
    const errorMessage = `Suspending order '${id}' failed.`;
    return this.bffOrderingService
      .suspendOrder(id, orderAsMap, null, this.orderingService.getOrderAuthCode(), email)
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.suspendOrderOptLockErrorHandler(id, orderAsMap, email))
      )
      .pipe(
        mergeMap(order => {
          this.removeCurrentOrderFromContext();
          return of(order);
        }),
        catchError(this.handleError(errorMessage, null))
      );
  }

  /**
   * Handles 409 optimistic locking error when calling suspendOrder with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   */
  protected suspendOrderOptLockErrorHandler(orderRefNo, orderAsMap, email): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderRefNo, true).subscribe(order => {
            orderAsMap['recordVersion'] = order.recordVersion;
            this.bffOrderingService
              .suspendOrder(orderRefNo, orderAsMap, null, this.orderingService.getOrderAuthCode(), email)
              .subscribe(order => {
                observable.next(order);
              });
          });
        });
      }
      throw error;
    };
  }

  public getCurrentOrderFromSession(): OrderDto {
    if (!this.currentOrder) {
      this.currentOrder = JSON.parse(window.sessionStorage.getItem(Keys.KEY_CURRENT_ORDER));
    }
    return this.currentOrder;
  }

  /**
   * Get the current order from BFF. The current order is an order having its orderRefNo stored in the local storage.
   * If no orderRefNo is stored or if it doesn't exist on BFF, a new order is created.
   *
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public getCurrentOrder(): Observable<OrderDto> {
    const orderRefNo: string = window.localStorage.getItem(Keys.KEY_CURRENT_ORDER_REF_NO);
    if (!orderRefNo) {
      this.removeCurrentOrderFromContext();
    }
    const orderDto: OrderDto = this.getCurrentOrderFromSession();
    const newOrderDto: OrderDto = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
      ScenarioTypeEnum.PRODUCT_MANAGEMENT
    );

    if (orderDto) {
      return of(orderDto);
    } else {
      if (orderRefNo) {
        return this.getOrderByRefNo(orderRefNo);
      } else {
        return this.createOrder(newOrderDto);
      }
    }
  }

  /**
   * Add authCode to session storage for new order.
   */
  getNewOrderAuthCodeHandler = (order: OrderDto): Observable<OrderDto> => {
    if (order) {
      this.orderingService.setOrderAuthCode(order.authCode);
    }

    return of(order);
  };

  /**
   * Get a handler of storing the order locally.
   *
   * @returns {(order: OrderDto) => Observable<OrderDto>} The handler.
   */
  getOrderStoringHandler = (order: OrderDto): Observable<OrderDto> => {
    if (
      !(order.orderStateType === OrderStateTypeEnum.CONFIRMED || order.orderStateType === OrderStateTypeEnum.PENDING)
    ) {
      this.setCurrentOrderContext(order);
      this.checkOrderCustomer(<CustomerDto>JSON.parse(OrderUtils.getOrderAttributeValue(order, 'customer')));
    }
    return this.shoppingCartService.handleOrderChange(order).pipe(map(() => order));
  };

  public createOrderObservable;
  /**
   * Create the given order and get it updated by BFF.
   *
   * @param {OrderDto} order The order to create.
   * @returns {Observable<OrderDto>} The updated order's observable.
   */
  public createOrder(order: OrderDto, preventHandling: boolean = false): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }

    const errorMessage = `Order creation failed.`;
    if (preventHandling) {
      return this.bffOrderingService.createNewOrder(order).pipe(mergeMap(this.getNewOrderAuthCodeHandler));
    } else {
      if (this.createOrderObservable) {
        return this.createOrderObservable;
      } else {
        const customer = this.customerLocalStorageService.getCurrentCustomer();
        if (customer) {
          OrderUtils.addCustomerAttributeToOrder(order, customer);
        }

        if (
          this.authFactoryService.getAuthService().account &&
          this.authFactoryService.getAuthService().account.external
        ) {
          order.accountId = this.authFactoryService.getAuthService().account.id;
        }

        this.createOrderObservable = this.bffOrderingService
          .createNewOrder(order)
          .pipe(share())
          .pipe(mergeMap(this.getNewOrderAuthCodeHandler))
          .pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
        return this.createOrderObservable;
      }
    }
  }

  /**
   * Create new fast order and get it updated and confirmed by BFF.
   *
   * @param {OrderItemDto[]} orderItems The order items to Order.
   * @returns {Observable<OrderDto>} The confirmed fast order's observable.
   */
  public createFastOrder(orderItems: OrderItemDto[]): Observable<OrderDto> {
    if (!orderItems || orderItems.length < 1) {
      throw new Error('Order items missing.');
    }

    const fastOrder = OrderUtils.getInitOrder();
    const customer = this.customerLocalStorageService.getCurrentCustomer();
    if (customer) {
      OrderUtils.addCustomerAttributeToOrder(fastOrder, customer);
    }

    fastOrder.orderType = 'FAST';
    fastOrder.orderItems = orderItems;

    return this.bffOrderingService
      .createNewOrder(fastOrder)
      .pipe(mergeMap(this.getNewOrderAuthCodeHandler))
      .pipe(catchError(this.handleError('Fast Order error.', null)));
  }

  /*
   * Remove current order reference number from windows local storage and session storage
   */
  public removeCurrentOrderFromContext(removeOrderAuthCode = true) {
    this.currentOrder = null;
    window.localStorage.removeItem(Keys.KEY_CURRENT_ORDER_REF_NO);
    window.sessionStorage.removeItem(Keys.KEY_CURRENT_ORDER);
    if (removeOrderAuthCode) {
      this.orderingService.removeOrderAuthCode();
    }

    this.createOrderObservable = null;
    this.shoppingCartService.handleOrderChange(null).subscribe();
  }

  /*
   * Add current order reference number to windows local storage and session storage
   */
  public setCurrentOrderContext(orderDto: OrderDto) {
    const currentOrder = this.getCurrentOrderFromSession();
    window.localStorage.setItem(Keys.KEY_CURRENT_ORDER_REF_NO, orderDto.id);
    window.sessionStorage.setItem(Keys.KEY_CURRENT_ORDER, JSON.stringify(orderDto));

    if (!currentOrder || currentOrder.recordVersion != orderDto.recordVersion) {
      this.currentOrder = orderDto;
      this.orderChanged.emit(this.currentOrder);
    }
  }

  /**
   * Patch order from BFF by its orderRefNo and map of order fields and they values.
   *
   * @param {string} orderRefNo The orderRefNo identifying the order.
   * * @param {any} orderAsMap Map of order fields and they values.
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public patchOrder(orderRefNo: string, orderAsMap: any, preventHandling: boolean = false): Observable<OrderDto> {
    if (!orderRefNo) {
      throw new Error('OrderRefNo must be configured.');
    }

    const errorMessage = `Patching order by orderRefNo '${orderRefNo}' failed.`;
    const request = this.bffOrderingService
      .patchOrder(orderRefNo, orderAsMap, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(orderRefNo))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.patchOptLockErrorHandler(orderRefNo, orderAsMap, errorMessage))
      );
    if (preventHandling) {
      return request;
    } else {
      return request.pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
    }
  }

  /**
   * Handles 409 optimistic locking error when calling patchOrder with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   * If patch orderAsMap contains orderAttributes or orderItems refresh page dialog is shown
   * as this would require some complicated merging strategy.
   */
  protected patchOptLockErrorHandler(orderRefNo, orderAsMap, errorMessage): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderRefNo, true).subscribe(order => {
            if (orderAsMap['orderAttributes'] || orderAsMap['orderItems']) {
              this.showRefreshPageDialog(order);
            } else {
              orderAsMap['recordVersion'] = order.recordVersion;
              this.bffOrderingService
                .patchOrder(orderRefNo, orderAsMap, this.orderingService.getOrderAuthCode())
                .pipe(catchError(this.handleError(errorMessage, null)))
                .subscribe(order => {
                  observable.next(order);
                });
            }
          });
        });
      }
      throw error;
    };
  }

  /**
   * Confirm order from BFF
   *
   * @param {OrderDto} order to confirm
   * @param A flag specifying if result handling (error spreading, storing results) should be performed.
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public confirmOrder(order: OrderDto, preventHandling: boolean = false): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }

    const orderRecordVersionAsMap = {
      recordVersion: order.recordVersion,
    };

    const errorMessage = `Confirming order by orderRefNo '${order.id}' failed.`;
    const request = this.bffOrderingService
      .confirmOrder(order.id, orderRecordVersionAsMap, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.confirmOrderOptLockErrorHandler(order))
      );
    if (order.scenarioType === ScenarioTypeEnum.PRODUCT_MANAGEMENT) {
      return request
        .pipe(catchError(this.paymentService.reservationErrorHandling))
        .pipe(catchError(this.handleError(errorMessage, null, ErrorOnEnum.ORDER_CONFIRMATION)));
    }
    if (preventHandling) {
      return request.pipe(catchError(this.handleError(errorMessage, null, ErrorOnEnum.ORDER_CONFIRMATION)));
    } else {
      const cleanOrder = () => {
        this.removeCurrentOrderFromContext();
      };

      return request.pipe(
        tap(cleanOrder),
        catchError(this.handleError(errorMessage, null, ErrorOnEnum.ORDER_CONFIRMATION))
      );
    }
  }

  /**
   * Handles 409 optimistic locking error when calling confirmOrder with wrong recordVersion
   * (for example if order was changed on different tab) by showing refresh page dialog
   * as user needs to see latest order data before confirming.
   */
  protected confirmOrderOptLockErrorHandler(order): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(order.id, true).subscribe(order => {
            this.showRefreshPageDialog(order);
          });
        });
      }
      throw error;
    };
  }

  private showRefreshPageDialog(order) {
    const dialogReference = this.ngbModal.open(ConfirmationDialogComponent, {
      windowClass: 'dialog dialog-confirmation',
    });

    const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
    confirmationDialogComponent.heading = 'wc.shopping.confirm.refreshPage.heading';
    confirmationDialogComponent.texts.push('wc.shopping.confirm.refreshPage.test');
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.cancellationVisible = false;
    confirmationDialogComponent.confirmationLocalizedKey = 'wc.common.ok.button';
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.getOrderStoringHandler(order).subscribe(() => {
        dialogReference.dismiss();
        this.router.navigate([this.router.url]);
      });
    };
  }

  /**
   * Cancel the given order.
   *
   * @param order The order to cancel.
   */
  public cancelOrder(order: OrderDto): Observable<OrderDto> {
    if (!order) {
      throw new Error('order must be configured.');
    }

    const removeOrderFromLocalStorage = (patchedOrder: OrderDto) => {
      if (patchedOrder.id === window.localStorage.getItem(Keys.KEY_CURRENT_ORDER_REF_NO)) {
        this.removeCurrentOrderFromContext();
        this.createOrderObservable = null;
        return of(patchedOrder);
      } else {
        return of(null);
      }
    };

    const orderAsMap = {
      recordVersion: order.recordVersion,
      orderStateType: 'CANCELED',
    };
    return this.bffOrderingService
      .patchOrder(order.id, orderAsMap, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.patchOptLockErrorHandler(order.id, orderAsMap, null))
      )
      .pipe(mergeMap(removeOrderFromLocalStorage));
  }

  //endregion

  //region: OrderItem Operations

  private identifyOrderForProduct(order: OrderDto, productCategory: string): Observable<OrderDto> {
    const scenarioType = order.scenarioType;

    // check category maximum
    let categoryConfiguration = undefined;
    this.allowedCategoriesForScenarios.forEach((value, key: ScenarioTypeEnum) => {
      if (value && value.length > 0 && value.find(item => productCategory.startsWith(item.category))) {
        categoryConfiguration = value.find(item => productCategory.startsWith(item.category));
      }
    });

    if (categoryConfiguration !== undefined && categoryConfiguration.maximum !== undefined) {
      const categoryMaximumResult = this.ensureCategoryMaximum(
        order,
        categoryConfiguration.maximum,
        categoryConfiguration.category
      );
      if (categoryMaximumResult !== undefined) {
        return categoryMaximumResult;
      }
    }

    if (this.allowedCategoriesForScenarios.has(ScenarioTypeEnum[order.scenarioType])) {
      const categoryConfiguration = this.allowedCategoriesForScenarios
        .get(ScenarioTypeEnum[order.scenarioType])
        .find(item => productCategory.startsWith(item.category));
      if (categoryConfiguration) {
        // current scenario is just fine for given category
        return of(order);
      }
    }

    // current scenario is not ok - check order content
    let targetScenario: ScenarioTypeEnum = null;
    if (order.orderItems.length > 0) {
      // do not allow to add requested product
      const errorMessage = `Product of category "${productCategory}" is not compatible with scenario ${order.scenarioType}`;
      const errDetails = {
        status: '',
        error: {
          failures: [{ code: 'Business Rule', detail: 'Compatibility Exception', localizedDescription: errorMessage }],
        },
      };
      return this.handleError(errorMessage, null)(errDetails);
    } else {
      this.allowedCategoriesForScenarios.forEach((value, key: ScenarioTypeEnum) => {
        if (value && value.length > 0 && value.find(item => productCategory.startsWith(item.category))) {
          targetScenario = key;
        }
      });
    }
    // change order scenario
    if (targetScenario && order.scenarioType !== ScenarioTypeEnum.QUOTE_MANAGEMENT) {
      order.scenarioType = targetScenario;
      order.id = null;
      order.recordVersion = null;
      return this.createOrder(order);
    }

    return of(order);
  }

  /**
   * Ensure that the count of product of the same category in order and under the context customer will not overflow the category maximum.
   *
   * @param order The context order.
   * @param maximum The maximum of products of the same category.
   * @param category The category.
   */
  private ensureCategoryMaximum(
    order: OrderDto,
    maximum: number,
    category: CategoryTypeEnum
  ): Observable<any> | undefined {
    let actualNumberInCart = 0;
    order.orderItems.forEach(orderItem => {
      const productDetail = this.productService.getProductFromCache(orderItem.productId);
      if (productDetail && CategoryTypeEnum[productDetail.categoryId] === category) {
        actualNumberInCart++;
      }
    });

    let actualNumberUnderCustomer = 0;
    const tariffSpaces = this.getContextTariffSpaces();
    if (tariffSpaces && tariffSpaces.length > 0) {
      tariffSpaces.forEach(tariffSpace => {
        tariffSpace.assets.forEach(asset => {
          if (CategoryTypeEnum[asset.product.categoryId] === category) {
            actualNumberUnderCustomer++;
          }
        });
      });
    }

    if (maximum < actualNumberInCart + actualNumberUnderCustomer + 1) {
      const errorMessage = `There can be only ${maximum} products of category ${category} under one customer.`;
      const errDetails = {
        status: '',
        error: {
          failures: [{ code: 'Business Rule', detail: 'Compatibility Exception', localizedDescription: errorMessage }],
        },
      };
      return this.handleError(errorMessage, null)(errDetails);
    }
  }

  /**
   * Get tariff spaces for the context customer or null if context customer or customer account don't exist.
   */
  private getContextTariffSpaces(): PartyDto[] {
    const customer = this.customerLocalStorageService.getCurrentCustomer();
    if (customer) {
      const customerAccounts = customer.childParties;
      if (customerAccounts && customerAccounts.length > 0) {
        const tariffSpaces: Array<PartyDto> = new Array<PartyDto>();
        customerAccounts.forEach(customerAccount => {
          customerAccount.childParties.forEach(tariffSpace => {
            tariffSpaces.push(tariffSpace);
          });
        });
        return tariffSpaces;
      }
    }
    return null;
  }

  /**
   * Add product to shopping cart. If parentProductId is not null and parent product is not in shopping cart it will add first parent product then product
   * with parentOrderItemId as parent product orderItem.id, if parent is in shopping it will add its orderItem.id as parentOrderItemId .
   * @param productId
   * @param parentProductId
   * @param parentInstanceId
   * @param partyId
   * @param action
   */
  public addProductToShoppingCartWithParent(
    order: OrderDto,
    product: ProductAddingToCart,
    parentProductId?: string,
    parentInstanceId?: string,
    partyId?: string,
    action?: string,
    checkStockAvailability: boolean = true
  ): Observable<OrderDto> {
    const handleProductToShoppingCartWithParent = (order: OrderDto) => {
      const addOrderItem: OrderItemDto = {
        productId: product.id,
        parentOrderItemId: parentInstanceId,
        partyRefNo: partyId,
        action: action ? action : 'ADD',
      };
      const parentOrderItem: OrderItemDto = {
        productId: parentProductId,
        partyRefNo: partyId,
        action: 'ADD',
      };

      if (order) {
        if (parentProductId !== undefined && parentProductId) {
          let parentOrderItemInShoppingCart = undefined;
          if (this.shoppingCartService.preCalculatedShoppingCart) {
            parentOrderItemInShoppingCart =
              this.shoppingCartService.preCalculatedShoppingCart.getOrderItemByProductId(parentProductId);

            if (product.categoryId.startsWith(CategoryTypeEnum.PRODC_SU_VAS_CORE)) {
              // for vas product we have to find core in the cart
              const corePorductInShoppingCart =
                this.shoppingCartService.preCalculatedShoppingCart.getTariffProductInCartByCategoryId(
                  parentProductId,
                  CategoryTypeEnum.PRODC_SU_CORE_GSM
                );
              parentOrderItemInShoppingCart = corePorductInShoppingCart.orderItems[0];
            }
          }
          if (parentOrderItemInShoppingCart) {
            addOrderItem['parentOrderItemId'] = parentOrderItemInShoppingCart.id;
            return this.addOrderItem(order, addOrderItem);
          } else {
            parentOrderItem['id'] = '111222333';
            addOrderItem['parentOrderItemId'] = parentOrderItem['id'];
            return this.updateOrderItems(order, [parentOrderItem, addOrderItem]);
          }
        } else {
          return this.addOrderItem(order, addOrderItem);
        }
      }
    };

    if (ProductUtils.isTariff(product) || !checkStockAvailability) {
      return this.identifyOrderForProduct(order, product.categoryId).pipe(
        mergeMap(handleProductToShoppingCartWithParent)
      );
    } else {
      return this.stockCustomService.getStockCentral(product.productCode).pipe(
        mergeMap(result => {
          const productOrderItemInShoppingCart = this.shoppingCartService.preCalculatedShoppingCart.products.get(
            product.id
          );
          let checkQuantity = 1;
          if (productOrderItemInShoppingCart && productOrderItemInShoppingCart.length > 0) {
            checkQuantity += productOrderItemInShoppingCart[0].visibleOrderItemsQuantity;
          }
          if (result.realStock >= checkQuantity) {
            return this.identifyOrderForProduct(order, product.categoryId).pipe(
              mergeMap(handleProductToShoppingCartWithParent)
            );
          } else {
            this.stickyMessageService.addStickyWarningMessage(
              'wc.shopping.stickyMessage.error.notEnoughProductsInStock'
            );
            return of(null);
          }
        })
      );
    }
  }

  private getAddOrderItem(product: ProductDetailDto, parentProduct?: ProductDetailDto): OrderItemDto {
    return {
      productId: product.id,
      parentOrderItemId: parentProduct
        ? this.shoppingCartService.preCalculatedShoppingCart.getOrderItemByProductId(parentProduct.id, true).id
        : null,
      action: 'ADD',
    };
  }

  addAllToBasket(
    addProduct: ProductDetailDto,
    giftProduct?: ProductDetailDto,
    discountProduct?: ProductDetailDto,
    tariffProduct?: ProductDetailDto
  ) : Observable<OrderDto> {
    return new Observable<OrderDto>(observer => {
      const addOrderItem = (currentOrder: OrderDto) => {
        this.addProductToShoppingCartWithParent(currentOrder, addProduct as ProductAddingToCart).subscribe(order => {
          if (order) {
            if (tariffProduct) {
              order.orderItems.push(this.getAddOrderItem(tariffProduct));
            }

            if (giftProduct) {
              order.orderItems.push(this.getAddOrderItem(giftProduct));
            }

            if (tariffProduct || giftProduct) {
              this.updateOrderItems(order, order.orderItems).subscribe((order: OrderDto) => {
                if (order) {
                  if (tariffProduct) {
                    order.orderItems.push(this.getAddOrderItem(discountProduct, addProduct));
                  }
                  if (giftProduct) {
                    order.orderItems.push(this.getAddOrderItem(discountProduct, giftProduct));
                  }
                  if (discountProduct) {
                    this.updateOrderItems(order, order.orderItems).subscribe((order: OrderDto) => {
                      observer.next(order);
                    });
                  }
                }
                observer.next(order);
              });
            }
          }
          observer.next(order);
        });
      };
      this.getCurrentOrder().subscribe(addOrderItem);
    });
  }

  /**
   * Add the given order item to the given order.
   *
   * @param {OrderDto} order The target order
   * @param {OrderItemDto} orderItem The order item to add.
   * @returns {Observable<OrderDto>} The updated order.
   */
  private addOrderItem(order: OrderDto, orderItem: OrderItemDto): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }
    if (!orderItem) {
      throw new Error('Order item must be configured.');
    }

    orderItem.operation = OrderItemDto.OperationDtoEnum.ADDORUPDATE;

    // TODO: In case of error, throw an exception.
    // TODO: Is it necessary to create a shopping cart manually?

    const errorMessage = `Adding an item to order '${order.id}' failed.`;
    return this.bffShoppingCartService
      .addOrderItem(order.id, orderItem, order.recordVersion, null, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.addOrderItemOptLockErrorHandler(order, orderItem, errorMessage))
      )
      .pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Handles 409 optimistic locking error when calling addOrderItem with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   */
  protected addOrderItemOptLockErrorHandler(orderDto, orderItem, errorMessage): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderDto.id, true).subscribe(order => {
            this.bffShoppingCartService
              .addOrderItem(order.id, orderItem, order.recordVersion, null, this.orderingService.getOrderAuthCode())
              .pipe(catchError(this.handleError(errorMessage, null)))
              .subscribe(order => {
                observable.next(order);
              });
          });
        });
      }
      throw error;
    };
  }

  /**
   * Update parameters of given order items
   *
   * @param {OrderDto} order The target order.
   * @param {Array<OrderItemDto>)} orderItems The array of order items to be updated
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public updateOrderItems(
    order: OrderDto,
    orderItems: Array<OrderItemDto>,
    preventHandling: boolean = false
  ): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }
    if (!orderItems || orderItems.length === 0) {
      throw new Error('At least one orderItem must be present');
    }

    orderItems.forEach(orderItem => (orderItem.operation = OrderItemDto.OperationDtoEnum.ADDORUPDATE));
    const errorMessage = `Updating order items of the '${orderItems}' orderItems from order '${order.id}' failed.`;
    const request: Observable<OrderDto> = this.bffShoppingCartService
      .updateOrderItems(order.id, orderItems, order.recordVersion, null, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.updateOrderItemsOrderOptLockErrorHandler(order, orderItems, errorMessage))
      );
    if (preventHandling) {
      return request;
    } else {
      return request.pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
    }
  }

  /**
   * Handles 409 optimistic locking error when calling updateOrderItems with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   */
  protected updateOrderItemsOrderOptLockErrorHandler(
    orderDto,
    orderItem,
    errorMessage
  ): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderDto.id, true).subscribe(order => {
            this.bffShoppingCartService
              .updateOrderItems(order.id, orderItem, order.recordVersion, null, this.orderingService.getOrderAuthCode())
              .pipe(catchError(this.handleError(errorMessage, null)))
              .subscribe(order => {
                observable.next(order);
              });
          });
        });
      }
      throw error;
    };
  }

  /**
   * Remove all order items of the given productInShoppingCart from the given order.
   *
   * @param {OrderDto} order The target order.
   * @param {ProductInShoppingCart} productInShoppingCart to be removed.
   * @returns {Observable<OrderDto>} The order's observable.mergeMap
   */
  public removeProductInShoppingCartFromCart(
    order: OrderDto,
    productInShoppingCart: ProductInShoppingCart
  ): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }
    if (!productInShoppingCart || !productInShoppingCart.productDetail) {
      throw new Error('ProductInShoppingCart must be configured.');
    }

    //remove all ordered items with given product instance id from the cart
    const orderItemsToRemove = productInShoppingCart.orderItems;
    orderItemsToRemove.forEach(orderItem => (orderItem.operation = OrderItemDto.OperationDtoEnum.REMOVE));
    const errorMessage = `Removing order items of the '${productInShoppingCart.productDetail.id}' productId from order '${order.id}' failed.`;
    return this.bffShoppingCartService
      .updateOrderItems(
        order.id,
        orderItemsToRemove,
        order.recordVersion,
        null,
        this.orderingService.getOrderAuthCode()
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.removeProductInShoppingCartFromCartOptLockErrorHandler(order, orderItemsToRemove, errorMessage))
      )
      .pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));

    /*OLD VERSION:
    const orderItemsToStay = order.orderItems.filter(orderItem => orderItem.productId !== productId);
    const errorMessage = `Removing order items of the '${productId}' productId from order '${order.id}' failed.`;
    return this.bffShoppingCartService.updateOrderItems(order.id, order.recordVersion, orderItemsToStay).pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));*/
  }

  /**
   * Handles 409 optimistic locking error when calling removeProductInShoppingCartFromCart with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   * If current order already do not have product retry is not done.
   */
  protected removeProductInShoppingCartFromCartOptLockErrorHandler(
    orderDto,
    orderItems,
    errorMessage
  ): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderDto.id, true).subscribe(order => {
            const nextOrderItemsToRemove = [];
            orderItems.forEach(removeOrderItem => {
              if (order.orderItems.find(orderItem => orderItem.id === removeOrderItem.id)) {
                nextOrderItemsToRemove.push(removeOrderItem);
              }
            });
            if (nextOrderItemsToRemove) {
              this.bffShoppingCartService
                .updateOrderItems(
                  order.id,
                  nextOrderItemsToRemove,
                  order.recordVersion,
                  null,
                  this.orderingService.getOrderAuthCode()
                )
                .pipe(catchError(this.handleError(errorMessage, null)))
                .subscribe(nextOrder => {
                  observable.next(nextOrder);
                });
            } else {
              observable.next(order);
            }
          });
        });
      }
      throw error;
    };
  }

  /**
   * Remove the given order item from the given order.
   *
   * @param {OrderDto} order The target order.
   * @param {OrderItemDto} orderItem The order item to remove.
   * @returns {Observable<OrderDto>} The order's observable.
   */
  public removeOrderItem(order: OrderDto, orderItem: OrderItemDto): Observable<OrderDto> {
    if (!order) {
      throw new Error('Order must be configured.');
    }
    if (!orderItem) {
      throw new Error('Order item must be configured.');
    }

    const errorMessage = `Removing order item '${orderItem.id}' from order '${order.id}' failed.`;
    return this.bffShoppingCartService
      .removeOrderItem(order.id, orderItem.id, order.recordVersion, null, this.orderingService.getOrderAuthCode())
      .pipe(
        switchMap(v => of(v)),
        catchError(this.orderAccessDeniedAndNotFoundErrorHandler(order.id))
      )
      .pipe(
        switchMap(v => of(v)),
        catchError(this.removeOrderItemOptLockErrorHandler(order, orderItem, errorMessage))
      )
      .pipe(mergeMap(this.getOrderStoringHandler), catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Handles 409 optimistic locking error when calling removeOrderItem with wrong recordVersion
   * (for example if order was changed on different tab) by loading current order version
   * and using its recordVersion in re-try call.
   * If current order already do not have order item retry is not done.
   */
  protected removeOrderItemOptLockErrorHandler(
    orderDto,
    orderItem,
    errorMessage
  ): (error: any) => Observable<OrderDto> {
    return (error: any): Observable<OrderDto> => {
      const opFailure = error.error.failures.find(failure => failure.code === 409 || failure.code === 4);
      if (opFailure) {
        return new Observable<OrderDto>(observable => {
          this.getOrderByRefNo(orderDto.id, true).subscribe(order => {
            if (order.orderItems.find(findItem => findItem.id === orderItem.id)) {
              this.bffShoppingCartService
                .removeOrderItem(
                  order.id,
                  orderItem.id,
                  order.recordVersion,
                  null,
                  this.orderingService.getOrderAuthCode()
                )
                .pipe(catchError(this.handleError(errorMessage, null)))
                .subscribe(nextOrder => {
                  observable.next(nextOrder);
                });
            } else {
              observable.next(order);
            }
          });
        });
      }
      throw error;
    };
  }

  //endregion

  //region Helpers:

  private checkOrderCustomer(customer: CustomerDto) {
    const currentCustomer = this.customerLocalStorageService.getCurrentCustomer();
    if (customer && customer.id) {
      if (!currentCustomer || customer.id !== currentCustomer.id) {
        this.customerService.getCustomer(customer.id).subscribe(requestCustomer => {
          this.customerLocalStorageService.setCurrentCustomer(requestCustomer, false);
        });
      }
    }
  }

  handleContextChangedPendding = false;

  private handleContextChanged = (customerDto: CustomerDto): void => {
    if (!this.handleContextChangedPendding) {
      this.handleContextChangedPendding = true;
      const prevCustomerContext = this.customerLocalStorageService.prevCustomerContext;
      if (!customerDto || (prevCustomerContext && prevCustomerContext.id !== customerDto.id)) {
        this.createOrderObservable = null;
        this.removeCurrentOrderFromContext();
        this.getCurrentOrder();
      } else {
        this.getCurrentOrder().subscribe(order => {
          const orderParamsDto: Array<OrderParamDto> = [];
          if (order && customerDto) {
            OrderUtils.updateOrderAttr(orderParamsDto, 'customer', JSON.stringify(CustomerPartyUtil.getFormCustomer(customerDto)));
          }

          const orderAsMap = {
            orderAttributes: orderParamsDto,
            recordVersion: order.recordVersion,
          };
          const orderCreator = order.creator;
          this.addCreator(order, orderAsMap);
          this.addAccountId(order, orderAsMap);
          this.patchOrder(order.id, orderAsMap).subscribe(patchedOrder => {
            if (orderCreator != patchedOrder.creator) {
              this.orderingService.removeOrderAuthCode();
            }
            this.setCurrentOrderContext(patchedOrder);
            this.orderChanged.emit(patchedOrder);
            this.handleContextChangedPendding = false;
          });
        });
      }
    }
  };

  getOrderByAuthCode(authCode: string): Observable<OrderDto[]> {
    this.orderingService.setOrderAuthCode(authCode);
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'authCode',
      compareType: 'EQUAL',
      value: authCode,
    });
    return this.orderingService.filterOrders(search, null).pipe(map(pageOrders => pageOrders.data));
  }

  addAccountId(order: OrderDto, orderAsMap) {
    if (
      !order.accountId &&
      this.authFactoryService.getAuthService().account &&
      this.authFactoryService.getAuthService().account.external
    ) {
      orderAsMap['accountId'] = this.authFactoryService.getAuthService().account.id;
    }
  }

  addCreator(order: OrderDto, orderAsMap) {
    if (!order.creator && this.authFactoryService.getAuthService().getUsername()) {
      orderAsMap['creator'] = this.authFactoryService.getAuthService().getUsername();
    }
  }

  private orderItemNotSavedAttributes = new Map<string, any>();

  addNotSavedAttribute(attributeName: string, sourceOrderItem: OrderItemDto, value: any) {
    this.orderItemNotSavedAttributes.set(`${sourceOrderItem.id}-${attributeName}`, value);
  }

  /**
   * Load attribute identified by the name value from the given source order item to the form.
   *
   * @param attributeName The name of the attribute to load.
   * @param sourceOrderItem The source order item.
   * @param attributesFormGroup attribute value.
   * @param readOnly If attribute is read-only.
   */
  loadAttribute(
    attributeName: string,
    sourceOrderItem: OrderItemDto,
    attributesFormGroup: FormGroup,
    readOnly?: boolean
  ): void {
    if (!sourceOrderItem) {
      return;
    }

    if (!sourceOrderItem.attributes) {
      return;
    }
    const attribute = sourceOrderItem.attributes.find(this.getAttributeByNamePredicate(attributeName));
    if (!attribute) {
      return;
    }

    const attributesFormGroupControl = attributesFormGroup.get(attributeName);
    attributesFormGroupControl.setValue(attribute.value);
    const orderItemNotSavedAttributeValue = this.orderItemNotSavedAttributes.get(
      `${sourceOrderItem.id}-${attributeName}`
    );
    if (orderItemNotSavedAttributeValue) {
      attributesFormGroupControl.setValue(orderItemNotSavedAttributeValue);
    }
    if (readOnly) {
      attributesFormGroupControl.disable();
    }
  }

  /**
   * Save attribute value identified by the name from the form to the given target order item.
   *
   * @param attributeName The name of the attribute to save.
   * @param targetOrderItem The target order item.
   * @param attribute attribute value.
   */
  saveAttribute(attributeName: string, targetOrderItem: OrderItemDto, attribute: any): void {
    let newAttribute;
    let attributesFormGroupControl;
    if (!targetOrderItem.attributes) {
      targetOrderItem.attributes = [];
    }
    if (attribute instanceof FormGroup) {
      attributesFormGroupControl = attribute.get(attributeName);
      newAttribute = targetOrderItem.attributes.find(this.getAttributeByNamePredicate(attributeName));
    }
    if (!newAttribute) {
      newAttribute = {
        name: attributeName,
      };
      targetOrderItem.attributes.push(newAttribute);
    }
    if (attributesFormGroupControl) {
      newAttribute.value = attributesFormGroupControl.value;
    } else {
      newAttribute.value = attribute;
    }
  }

  /**
   * Get a predicate for getting an order item attribute by the given name.
   *
   * @param attributeName The name of the searched order item attribute.
   */
  getAttributeByNamePredicate(attributeName: string): (OrderitemAttributeDto) => boolean {
    return (attribute: OrderitemAttributeDto) => {
      return attribute.name === attributeName;
    };
  }

  public getCurrentOrderHwAvailability(ouRefNo): Observable<StockAvailabilityDto> {
    if (!ouRefNo) {
      throw new Error('ouRefNo must be configured.');
    }
    if (this.shoppingCartService.preCalculatedShoppingCart.products.size > 0) {
      const checkAvailabilityDto: CheckAvailabilityDto = {
        stockType: 'PHYSICAL',
        ouRefNo: ouRefNo,
        productCodes: this.shoppingCartService.preCalculatedShoppingCart.getHwProductCodes(),
      };

      const errorMessage = `OrderHwAvailability failed.`;
      return this.orderAvailabilityFrontendService
        .getOrderHwAvailability(this.shoppingCartService.currentOrder.id, checkAvailabilityDto)
        .pipe(catchError(this.handleError(errorMessage, null)));
    } else {
      return of({
        stockAvailability: true,
      });
    }
  }
}
