<label *ngIf="showLabel" for="001_input_row-{{ fieldName }}">
  {{ translationPrefix + '.' + fieldName + '.label' | translate }}
</label>
<label *ngIf="mandatory && showLabel" class="mandatory">*</label>
<div class="input" [formGroup]="form">
  <div class="phone_number">
    <select class="directional" formControlName="directional">
      <option *ngFor="let directional of directionals" [ngValue]="directional">
        {{ directional }}
      </option>
    </select>
    <input formControlName="phone" id="002_input_row-{{ fieldName }}" [type]="'text'" />
  </div>
</div>
