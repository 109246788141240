import { Inject, Injectable } from '@angular/core';
import { WcOrderingService } from './wc-ordering.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscriber } from 'rxjs';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { KeycloakService } from 'keycloak-angular';
import { BASE_PATH, OrderDto, OrderParamDto } from '@btl/order-bff';
import { ProductInShoppingCart } from '../models/product-in-shopping-cart';
import { Keys } from './keys';
import { OrderUtils, ScenarioStepTypeEnum } from 'app/helpers/order-utils';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import {
  AuthFactoryService,
  DefaultErrorHandler,
  OrderingService,
  ProductGroupService,
  ProductService,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ProductCustomService } from './product-custom.service';
import { OrderStateTypeEnum } from '../models/orderStateTypeEnum';
import { UrlParamsService } from './url-params.service';
import { catchError, map } from 'rxjs/operators';
import { PaymentService } from '@service/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OrderCollisionPopupComponent
} from '../components/page/ordering-wizard/order-collision-popup/order-collision-popup.component';
import { ProductsViewedService } from '@service/products-viewed.service';

/**
 * Service that is handling ordering wizard step changes using canActivate interface. When step is change service change order scenarioStepType
 * and if scenarioStepType is CHECKOUT_THANKYOU then it call orderingService.confirmOrder.
 */
@Injectable()
export class OrderingWizardService  {
  public static readonly PAYMENT_STATUS_ATTR = 'paymentStatus';
  public static readonly BLOCKING_REASON_ATTR = 'blockingReason';
  public static readonly IS_BLOCKED_ATTR = 'isBlocked';
  public static readonly PAYMENT_ID_ATTR = 'paymentId';
  public static readonly TARIFF_CHANGE_PATH = 'tariff-change';
  public static readonly CUSTOMER_ACCOUNT_ID_QUERY_PARAMETER: string = 'customerAccountId';

  // TODO: URLs are duplicated. Whole system of step infos is a serious refactoring candidate!
  stepTypesInfos = new Map<string, StepTypeInfo>([
    [
      'technology-check',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.SALES_TECHNOLOGY_CHECK,
        translation: 'technologyCheck',
        visibility: true,
        clickable_when_done: false,
        url: '/eshop/technology-check',
      },
    ],
    [
      'shopping-cart',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.SALES_SHOPPING_CART,
        translation: 'shoppingCart',
        visibility: false,
        clickable_when_done: true,
        url: '/eshop/shopping-cart/',
      },
    ],
    [
      'checkout-page',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_DETAILS,
        translation: 'checkout',
        visibility: true,
        clickable_when_done: true,
        url: '/eshop/checkout-page',
      },
    ],
    [
      'checkout-scoring',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_SCORING,
        translation: 'scoring',
        visibility: true,
        clickable_when_done: true,
        url: '/eshop/checkout-scoring',
      },
    ],
    [
      'delivery',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_DELIVERY,
        translation: 'delivery',
        visibility: true,
        clickable_when_done: true,
        url: '/eshop/delivery',
      },
    ],
    [
      'summary-page',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_SUMMARY,
        translation: 'summary',
        visibility: true,
        clickable_when_done: true,
        url: '/eshop/summary-page',
      },
    ],
    [
      'payment',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_PAYMENT,
        translation: 'payment',
        visibility: true,
        clickable_when_done: false,
        url: '/eshop/payment',
      },
    ],
    [
      'thank-you-page',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.CHECKOUT_THANKYOU,
        translation: 'finish',
        visibility: false,
        clickable_when_done: false,
        url: '/eshop/thank-you-page',
      },
    ],
    [
      'tariff-change',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
        translation: 'tariffChange',
        visibility: false,
        clickable_when_done: true,
        url: `/eshop/${OrderingWizardService.TARIFF_CHANGE_PATH}`,
      },
    ],
    [
      'product-listing',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
        translation: 'productListing',
        visibility: false,
        clickable_when_done: true,
        url: '/eshop/product-listing',
      },
    ],
    [
      'search',
      {
        scenarioStepTypeEnum: ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
        translation: 'productListing',
        visibility: false,
        clickable_when_done: false,
        url: '/eshop/search',
      },
    ],
  ]);

  orderingWizardStepStatuses: Array<StepStatusEnum> = new Array<StepStatusEnum>();
  collisionOrder: boolean;

  showVat: BehaviorSubject<any> = new BehaviorSubject('');

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private wcOrderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private keycloakService: KeycloakService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private defaultErrorHandler: DefaultErrorHandler,
    private productCustomService: ProductCustomService,
    private productGroupService: ProductGroupService,
    private productService: ProductService,
    private urlParamsService: UrlParamsService,
    public stickyMessageService: StickyMessageService,
    private authFactoryService: AuthFactoryService,
    private paymentService: PaymentService,
    private orderingService: OrderingService,
    private ngbModal: NgbModal,
    private productsViewedService: ProductsViewedService,
    @Inject(BASE_PATH) public basePath,
  ) {
    this.loadStepStatuses();
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    this.collisionOrder = false;
    if (route.routeConfig.path) {
      const urlParts = route.routeConfig.path.split('/');
      if (urlParts.length >= 1) {
        let orderRefNo = window.localStorage.getItem(Keys.KEY_CURRENT_ORDER_REF_NO);
        const currentOrder: OrderDto = this.wcOrderingService.getCurrentOrderFromSession();
        const stepTypeInfo = this.stepTypesInfos.get(urlParts[0]);
        let paramOrderRefNo = this.urlParamsService.getOrderRefNo(route.params);
        if (!paramOrderRefNo) {
          paramOrderRefNo = this.urlParamsService.getOrderId(state.root.queryParams);
        }
        const authCode = this.urlParamsService.getOrderAuthCode(route.params);
        const customerAccountId = route.queryParams[OrderingWizardService.CUSTOMER_ACCOUNT_ID_QUERY_PARAMETER];

        if (authCode || paramOrderRefNo) {
          return new Observable<boolean>(observer => {
            return this.getRedirectOrder(authCode, paramOrderRefNo).subscribe(
              (order: OrderDto) => {
                if (order && currentOrder && currentOrder.orderItems.length > 0 && orderRefNo !== order.id) {
                  this.showCollisionPopUp(state.url);
                  this.collisionOrder = true;
                } else {
                  orderRefNo = order.id;
                  if (authCode) {
                    order.authCode = authCode;
                  }
                  this.wcOrderingService.setCurrentOrderContext(order);
                }
                this.nextCanActivateObserver(observer, orderRefNo, stepTypeInfo, customerAccountId, paramOrderRefNo);
              },
              error => {
                if (authCode) {
                  this.stickyMessageService.addStickyWarningMessage(
                    'wc.shopping.stickyMessage.error.noOrderByAuthCode'
                  );
                } else {
                  this.stickyMessageService.addStickyWarningMessage('wc.shopping.stickyMessage.error.noOrderByRefNo');
                }
              }
            );
          });
        } else {
          const isTherePossibleTariffChangeCollision: boolean =
            orderRefNo && urlParts[1] === OrderingWizardService.TARIFF_CHANGE_PATH;
          if (!orderRefNo || isTherePossibleTariffChangeCollision) {
            return new Observable<boolean>(observer => {
              return this.wcOrderingService.getCurrentOrder().subscribe((actOrder: OrderDto) => {
                if (isTherePossibleTariffChangeCollision && actOrder.orderItems.length > 0) {
                  this.showCollisionPopUp(state.url);
                  this.collisionOrder = true;
                }
                this.nextCanActivateObserver(observer, orderRefNo, stepTypeInfo, customerAccountId, paramOrderRefNo);
              });
            });
          }
        }
        if (this.collisionOrder) {
          return false;
        }
        return this.updateStepTypeInfos(orderRefNo, stepTypeInfo, customerAccountId, paramOrderRefNo);
      }
    }
    return true;
  }

  private nextCanActivateObserver(
    observer: Subscriber<boolean>,
    orderRefNo: string,
    stepTypeInfo: StepTypeInfo,
    customerAccountId,
    paramOrderRefNo: string
  ) {
    if (this.collisionOrder) {
      observer.next(false);
    }

    const stepTypeInfoCanActivate = this.updateStepTypeInfos(
      orderRefNo,
      stepTypeInfo,
      customerAccountId,
      paramOrderRefNo
    );
    if (stepTypeInfoCanActivate instanceof Observable) {
      stepTypeInfoCanActivate.subscribe(canActivate => {
        observer.next(canActivate);
      });
    } else {
      observer.next(stepTypeInfoCanActivate);
    }
  }

  private getRedirectOrder(authCode: string, paramOrderRefNo: string): Observable<OrderDto> {
    if (authCode) {
      return this.wcOrderingService.getOrderByAuthCode(authCode).pipe(map(result => result[0]));
    }
    if (paramOrderRefNo) {
      return this.wcOrderingService.searchOrders(paramOrderRefNo);
    }
  }

  private loadStepStatuses() {
    this.orderingWizardStepStatuses = new Array<StepStatusEnum>();
    Array.from(this.stepTypesInfos.values()).forEach((loopStepTypeInfo, index) => {
      this.orderingWizardStepStatuses.push(StepStatusEnum.NOT_DONE);
    });
  }

  private updateOrderStepType(
    orderRefNo: string,
    stepType: string,
    customerAccountId: string,
    paramOrderRefNo: string
  ): boolean | Observable<boolean> {
    if (orderRefNo) {
      return new Observable<boolean>(observer => {
        const handelOrder = (orderDto: OrderDto): void => {
          const orderAsMap = {
            scenarioStepType: stepType,
            recordVersion: orderDto.recordVersion,
          };

          if (customerAccountId) {
            const orderCustomerAccount = JSON.parse(OrderUtils.getOrderAttributeValue(orderDto, 'customerAccount'));
            const customerAccount = this.customerLocalStorageService.getContextCustomerCAById(customerAccountId);
            if (!orderCustomerAccount || orderCustomerAccount.id === customerAccountId) {
              if (customerAccount) {
                OrderUtils.addCustomerAccountAttributeToOrder(orderDto, customerAccount);
              }
            } else {
              const errorMessage = `You need to first finish order for ${orderCustomerAccount.id} customer account to be able to work with ${customerAccountId} customer account`;
              const errDetails = {
                status: '',
                error: {
                  failures: [
                    {
                      code: 'Customer account conflict',
                      detail: 'Compatibility Exception',
                      localizedDescription: errorMessage,
                    },
                  ],
                },
              };
              return this.defaultErrorHandler.handleError(errorMessage, errDetails);
            }
          }

          if (
            orderDto.orderStateType === OrderStateTypeEnum.CONFIRMED ||
            orderDto.orderStateType === OrderStateTypeEnum.FINISHED
          ) {
            const paymentStatus = OrderUtils.getOrderAttributeValue(
              orderDto,
              OrderingWizardService.PAYMENT_STATUS_ATTR
            );

            if (paymentStatus && paymentStatus === PaymentStatusEnum.REQUIRED) {
              this.paymentService
                .createOrderPayment(orderDto.id)
                .pipe(
                  catchError(() => {
                    return of(null);
                  })
                )
                .subscribe(paymentOrderDto => {
                  if (orderDto && paymentOrderDto) {
                    this.paymentService.gotToPaymentGatewayUrl(orderDto);
                  } else {
                    observer.next(false);
                  }
                });
            } else {
              this.wcOrderingService.removeCurrentOrderFromContext(false);
              observer.next(true);
            }
          } else {
            if (
              stepType === ScenarioStepTypeEnum.CHECKOUT_THANKYOU &&
              orderDto.orderStateType === OrderStateTypeEnum.CREATED
            ) {
              const orderParamsDto: Array<OrderParamDto> = [];
              OrderUtils.updateOrderAttr(
                orderParamsDto,
                OrderUtils.VIEWED_PRODUCTS_ATTR,
                JSON.stringify(this.productsViewedService.getViewedProducts())
              );
              this.productsViewedService.clearViewedProducts();
              orderAsMap['orderAttributes'] = orderParamsDto;
            }

            const authCode = orderDto.authCode;
            this.wcOrderingService.patchOrder(orderRefNo, orderAsMap).subscribe(orderDto => {
              if (authCode) {
                this.orderingService.setOrderAuthCode(authCode);
              }
              this.shoppingCartService.handleOrderChange(orderDto);
              if (stepType === ScenarioStepTypeEnum.CHECKOUT_THANKYOU) {
                if (orderDto.orderStateType === OrderStateTypeEnum.CREATED) {
                  this.wcOrderingService
                    .confirmOrder(orderDto, true)
                    .pipe(
                      catchError(() => {
                        return of(null);
                      })
                    )
                    .subscribe(orderDto => {
                      if (orderDto) {
                        const paymentStatus = OrderUtils.getOrderAttributeValue(
                          orderDto,
                          OrderingWizardService.PAYMENT_STATUS_ATTR
                        );

                        if (paymentStatus && paymentStatus === PaymentStatusEnum.REQUIRED) {
                          this.paymentService.createOrderPayment(orderDto.id).subscribe(paymentOrderDto => {
                            if (paymentOrderDto) {
                              this.wcOrderingService.setCurrentOrderContext(paymentOrderDto);
                              this.paymentService.gotToPaymentGatewayUrl(paymentOrderDto);
                            } else {
                              observer.next(false);
                            }
                          });
                        } else {
                          this.wcOrderingService.removeCurrentOrderFromContext(false);
                          observer.next(true);
                        }
                      } else {
                        observer.next(false);
                      }
                    });
                }
              } else {
                observer.next(true);
              }
            });
          }
        };
        //Use checkIfUserOrderAfterRedirect only for CHECKOUT_THANKYOU as only there we are redirected back to
        //application and this.keycloakService.getUsername() have issue with this
        if (paramOrderRefNo && paramOrderRefNo !== orderRefNo) {
          return this.wcOrderingService.getOrderByRefNo(paramOrderRefNo).subscribe(handelOrder);
        } else {
          return this.wcOrderingService
            .getCurrentOrder()
            .subscribe(handelOrder);
        }
      });
    } else {
      if (stepType === 'SALES_PRODUCT_LISTING') {
        return true;
      } else {
        return false;
      }
    }
  }

  private updateStepTypeInfos(
    orderRefNo: string,
    stepTypeInfo: StepTypeInfo,
    customerAccountId: string,
    paramOrderRefNo: string
  ): boolean | Observable<boolean> {
    const stepUpdated = this.updateOrderStepType(
      orderRefNo,
      stepTypeInfo.scenarioStepTypeEnum,
      customerAccountId,
      paramOrderRefNo
    );

    if (stepUpdated) {
      let currentStepTypeInfoIndex = this.stepTypesInfos.size;

      Array.from(this.stepTypesInfos.values()).forEach((loopStepTypeInfo, index) => {
        if (loopStepTypeInfo.scenarioStepTypeEnum === stepTypeInfo.scenarioStepTypeEnum) {
          currentStepTypeInfoIndex = index;
        }

        if (index < currentStepTypeInfoIndex) {
          if (loopStepTypeInfo.clickable_when_done) {
            this.orderingWizardStepStatuses[index] = StepStatusEnum.DONE;
          } else {
            this.orderingWizardStepStatuses[index] = StepStatusEnum.DONE_NOT_CLICKABLE;
          }
        } else if (index > currentStepTypeInfoIndex) {
          this.orderingWizardStepStatuses[index] = StepStatusEnum.NOT_DONE;
        } else {
          this.orderingWizardStepStatuses[index] = StepStatusEnum.ACTIVE;
        }
      });
    }

    return stepUpdated;
  }

  /**
   * Check if the given wizard step is visible for the current order (true) or not (false).
   *
   * @param {boolean} step The wizard step to check visibility for.
   * @return true if the step is visible, false otherwise.
   */
  // TODO: This is hard-coded right now. Evaluation service would be nice.
  checkStepVisibility(step: ScenarioStepTypeEnum): boolean {
    if (step === ScenarioStepTypeEnum.CHECKOUT_DETAILS) {
      return this.checkCustomerStepVisibility();
    } else if (step === ScenarioStepTypeEnum.CHECKOUT_PAYMENT) {
      return this.checkPaymentStepVisibility();
    } else if (step === ScenarioStepTypeEnum.SALES_TECHNOLOGY_CHECK) {
      return this.checkTechnologyCheckStepVisibility();
    } else if (step === ScenarioStepTypeEnum.CHECKOUT_SCORING) {
      return this.checkScoringStepVisibility();
    } else if (step === ScenarioStepTypeEnum.CHECKOUT_DELIVERY) {
      return this.checkDeliveryStepVisibility();
    } else {
      return true;
    }
  }

  testIfScanRequired(product: ProductInShoppingCart) {
    if (product.productDetail.parametersStatic['checkScanRequired'] === 'true') {
      return true;
    }
    if (product.children) {
      for (const child of product.children) {
        if (this.testIfScanRequired(child)) {
          return true;
        }
      }
    }
  }

  /**
   * Check if the Payment step is visible for the current order (true) or not (false). It is visible if: OC price is greater than 0, user
   * has the SHOP_ASSISTANT role, there is a product with the ADD operation, and the order has positive upfrontPayment attribute.
   *
   * @return true if the step is visible, false otherwise.
   */
  // TODO: This is hard-coded right now. Evaluation service would be nice.
  private checkPaymentStepVisibility(): boolean {
    const currentOrder: OrderDto = this.wcOrderingService.getCurrentOrderFromSession();
    if (!currentOrder || !this.shoppingCartService.preCalculatedShoppingCart) {
      return false;
    }

    // TODO: ACL should be checked in BFF. This is a temporary solution.
    // TODO: For now, we consider a user to be logged in if he has at least one role.
    const loggedUser: boolean = this.keycloakService.getUserRoles().length > 0;
    if (!loggedUser) {
      return false;
    }

    const ocPrice: number = this.shoppingCartService.preCalculatedShoppingCart.totalOcPrice;
    if (ocPrice <= 0) {
      return false;
    }

    let upfrontPayment = false;
    this.shoppingCartService.preCalculatedShoppingCart.products.forEach(
      (productInShoppingCartGroup: ProductInShoppingCart[]) => {
        for (const productInShoppingCart of productInShoppingCartGroup) {
          if (!upfrontPayment) {
            if (this.testUpfrontPayment(productInShoppingCart)) {
              upfrontPayment = true;
            }
          }
        }
      }
    );

    return upfrontPayment;
  }

  private checkCustomerStepVisibility(): boolean {
    const currentOrder: OrderDto = this.wcOrderingService.getCurrentOrderFromSession();
    if (!currentOrder || !this.shoppingCartService.preCalculatedShoppingCart) {
      return false;
    }
    return this.shoppingCartService.preCalculatedShoppingCart.containsService();
  }

  /**
   * Check if the TechnologyCheck step is visible for the current order (true) or not (false)
   * @return true if the step is visible, false otherwise.
   */
  private checkTechnologyCheckStepVisibility(): boolean {
    let requiredScan = false;
    this.shoppingCartService.preCalculatedShoppingCart?.products?.forEach(
      (productInShoppingCartGroup: ProductInShoppingCart[]) => {
        for (const product of productInShoppingCartGroup) {
          if (!requiredScan) {
            if (this.testIfScanRequired(product)) {
              requiredScan = true;
              //this.router.navigate(['/eshop/technology-check']);
            }
          }
        }
      }
    );
    return requiredScan;
  }

  /**
   * Test if upfront payment exists on the given product in shopping cart or its items.
   *
   * @param productInShoppingCart The product in shopping cart.
   * @return true if upfront payment exists, false otherwise.
   */
  private testUpfrontPayment(productInShoppingCart: ProductInShoppingCart): boolean {
    if (productInShoppingCart.productDetail.parametersStatic['upfrontPayment'] === 'true') {
      for (const orderItem of productInShoppingCart.orderItems) {
        if (orderItem.action === 'ADD') {
          return true;
        }
      }
    }

    if (productInShoppingCart.children) {
      for (const productInShoppingCartChild of productInShoppingCart.children) {
        if (this.testUpfrontPayment(productInShoppingCartChild)) {
          return true;
        }
      }
    }

    return false;
  }

  private checkScoringStepVisibility(): boolean {
    const currentOrder: OrderDto = this.wcOrderingService.getCurrentOrderFromSession();
    if (!currentOrder || !this.shoppingCartService.preCalculatedShoppingCart) {
      return false;
    }

    let scoring = false;
    this.shoppingCartService.preCalculatedShoppingCart.products.forEach(
      (productInShoppingCartGroup: ProductInShoppingCart[]) => {
        for (const productInShoppingCart of productInShoppingCartGroup) {
          if (!scoring) {
            if (this.testScoring(productInShoppingCart)) {
              scoring = true;
            }
          }
        }
      }
    );
    return scoring;
  }

  public testScoring(productInShoppingCart: ProductInShoppingCart) {
    if (productInShoppingCart === undefined) {
      return false;
    }
    if (productInShoppingCart.productDetail.parametersStatic['scoringCategory']) {
      return true;
    }
    if (productInShoppingCart.children) {
      for (const child of productInShoppingCart.children) {
        if (this.testScoring(child)) {
          return true;
        }
      }
    }
  }

  private checkDeliveryStepVisibility() {
    if (this.shoppingCartService.preCalculatedShoppingCart?.isDeliveryOrUpFrontPaymentNeeded()) {
      return true;
    }
    return false;
  }

  private showCollisionPopUp(urlToContinue) {
    const modalRef = this.ngbModal.open(OrderCollisionPopupComponent, {
      size: 'sm',
      backdrop: false,
      keyboard: false,
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <OrderCollisionPopupComponent>modalRef.componentInstance;
    confirmationDialogComponent.dialogRef = modalRef;
    confirmationDialogComponent.urlToContinue = urlToContinue;
  }
}

export class StepTypeInfo {
  scenarioStepTypeEnum: ScenarioStepTypeEnum;
  translation: string;
  visibility: boolean;
  clickable_when_done: boolean;
  url: string;
}

export enum StepStatusEnum {
  NOT_DONE = '',
  ACTIVE = 'active',
  DONE = 'done',
  DONE_NOT_CLICKABLE = 'done disable',
}

export enum PaymentStatusEnum {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CREATED = 'CREATED',
  REQUIRED = 'REQUIRED',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  TIMEOUTED = 'TIMEOUTED',
  FAILED = 'FAILED',
}
