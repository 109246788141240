import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AccountContactDto, AccountDto, ContactDto, OrderDto, OrderParamDto } from '@btl/order-bff';
import { CreateCustomerComponent } from '../create-customer/create-customer.component';
import { WcOrderingService } from '@service/wc-ordering.service';
import { Router } from '@angular/router';
import { ScoringService } from 'app/services/scoring.service';
import { OrderUtils, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { CategoryTypeEnum } from 'app/models/product-filter';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { TicketService } from '@service/ticket.service';
import { AccountContactsService, AccountService, AuthFactoryService, AuthService } from '@btl/btl-fe-wc-common';
import { Constants } from 'app/constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CheckoutShoppingCartComponent } from '../checkout-shopping-cart/checkout-shopping-cart.component';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('createCustomer', { static: false }) createCustomerComponent: CreateCustomerComponent;
  @ViewChild(CheckoutShoppingCartComponent) checkoutShoppingCart: CheckoutShoppingCartComponent;

  scenarioTypeEnum = ScenarioTypeEnum;
  categoryTypeEnum = CategoryTypeEnum;

  isVerifiedCustomerRequired = false;
  simpleCustomer = false;

  extUserAuthMode = null;
  authService: AuthService;
  account: AccountDto = null;
  accountPrimaryContact: AccountContactDto;

  @Input() childMode = false;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  constructor(
    public wcOrderingService: WcOrderingService,
    private router: Router,
    private renderer: Renderer2,
    private scoringService: ScoringService,
    private shoppingCartService: ShoppingCartService,
    private orderingWizardService: OrderingWizardService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private authFactoryService: AuthFactoryService,
    private accountService: AccountService,
    private accountContactsService: AccountContactsService,
  ) {
    this.authService = authFactoryService.getAuthService();

    this.shoppingCartService.onChange.subscribe((precalculatedShoppingCart: PrecalculatedShoppingCart) => {
      this.isVerifiedCustomerRequired = this.shoppingCartService.preCalculatedShoppingCart.isVerifiedCustomerRequired();
    });
  }

  ngOnInit() {
    this.isVerifiedCustomerRequired = this.shoppingCartService.preCalculatedShoppingCart.isVerifiedCustomerRequired();
    this.authService.accountChange.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.setAccount(result);
    });

    if (this.authService.account) {
      this.setAccount(this.authService.account);
    } else {
      this.account = null;
      this.loadCurrentOrder();
    }

    this.wcOrderingService.orderChanged.subscribe(() => this.loadCurrentOrder());
  }

  setAccount(account) {
    this.account = account;
    this.accountContactsService
      .getAccountContactsByType('PRIMARY', this.account.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.accountPrimaryContact = result.data[0];
        }
        this.loadCurrentOrder();
      });
  }

  loadCurrentOrder() {
    const handleGetCurrentOrder = () => {
      if (!this.wcOrderingService.currentOrder) {
        throw new Error('There is no current order!');
      }

      if (!this.isVerifiedCustomerRequired && OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customer')) {
        this.simpleCustomer = true;
      }
    };
    this.propertyAccessorLocalService.getExtUserAuthMode().subscribe(result => {
      this.extUserAuthMode = result;
      if (!this.account || this.account.external) {
        if (this.extUserAuthMode === 'AUTH_REQUIRED' || this.extUserAuthMode === 'AUTH_AUTO_REGISTRATION') {
          if (!this.isVerifiedCustomerRequired) {
            this.simpleCustomer = true;
          }
        }
      }

      this.wcOrderingService.getCurrentOrder().subscribe(handleGetCurrentOrder);
    });
  }

  isCustomerSelected() {
    if (this.customerLocalStorageService.getCurrentCustomer()) {
      return true;
    } else {
      return false;
    }
  }

  getOrderToUpdate() {
    let orderParamsDto: Array<OrderParamDto> = [];
    if (
      this.showCreateCustomer() &&
      (this.isVerifiedCustomerRequired || this.simpleCustomer || this.extUserAuthMode === 'AUTH_REQUIRED')
    ) {
      if (this.simpleCustomer) {
        this.createCustomerComponent.formUserDetails.get('customerAccount').disable();
      }
      this.createCustomerComponent.validateForm();
      if (this.createCustomerComponent.formUserDetails.valid) {
        this.createCustomerComponent.updateOrderDtoAttrWithCustomerData(orderParamsDto);
        TicketService.removeLocalOpportunity();
      } else if (this.createCustomerComponent.formUserDetails.status !== 'DISABLED') {
        setTimeout(() => {
          const errorElements = this.renderer.selectRootElement('app-input-errors div', true);
          errorElements.style.scrollMarginTop = Constants.NAVIGATION_HEIGHT;
          errorElements.scrollIntoView({ behavior: 'smooth' });
        });
        return orderParamsDto;
      }
    }

    if (!this.isVerifiedCustomerRequired && !this.simpleCustomer && this.extUserAuthMode !== 'AUTH_REQUIRED') {
      OrderUtils.updateOrderAttr(orderParamsDto, 'customer', null);
      OrderUtils.updateOrderAttr(orderParamsDto, 'customerAccount', null);
    }

    return orderParamsDto;
  }

  confirmCustomerData() {
    const orderParamsDto: Array<OrderParamDto> = this.getOrderToUpdate();
    if (orderParamsDto.length > 0) {
      const handleGetCurrentOrder = (orderDto: OrderDto) => {
        this.wcOrderingService.currentOrder = orderDto;
        this.checkoutShoppingCart.orderingWizardActions.continueToNextStep();
      };
      const orderAsMap = {
        orderAttributes: orderParamsDto,
        recordVersion: this.wcOrderingService.currentOrder.recordVersion,
      };
      this.wcOrderingService.addAccountId(this.wcOrderingService.currentOrder, orderAsMap);
      this.wcOrderingService.patchOrder(this.wcOrderingService.currentOrder.id, orderAsMap).subscribe(handleGetCurrentOrder);
    }
  }

  checkScoring(): boolean {
    let scoring = false;
    this.shoppingCartService.preCalculatedShoppingCart.products.forEach(
      (productInShoppingCartGroup: ProductInShoppingCart[]) => {
        for (const productInShoppingCart of productInShoppingCartGroup) {
          if (!scoring) {
            if (this.orderingWizardService.testScoring(productInShoppingCart)) {
              scoring = true;
            }
          }
        }
      }
    );
    return scoring;
  }

  public showCreateCustomer(): boolean {
    const ret = false;

    if (this.account && !this.account.external) {
      if (this.isVerifiedCustomerRequired || this.simpleCustomer) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        (this.isVerifiedCustomerRequired && !(this.extUserAuthMode === 'AUTH_REQUIRED' && !this.account)) ||
        (this.simpleCustomer &&
          ((this.extUserAuthMode === 'AUTH_REQUIRED' && this.account != null) || this.extUserAuthMode === 'AUTH_ANONYMOUS')) ||
        this.extUserAuthMode === 'AUTH_AUTO_REGISTRATION'
      );
    }
  }
}
