<app-ecare-page-layout columnTemplate="1fr" title="{{ 'wc.ecare.reports.headline' | translate }}">
  <div>
    <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
      <app-report-filter
        [filtering]="search.filtering"
        (filterChanged)="handleFilterChange()"
        (onChangeAreaEvent)="filterReportsByArea($event)"
        (onChangeTypeEvent)="filterReportsByType($event)">
      </app-report-filter>
      <div class="dashboard_search">
        <div class="ecare-responsive-table">
          <ng-container *ngIf="reports?.length > 0; else no_results">
            <table class="ecare_listing-table responsive-table table">
              <thead>
                <tr>
                  <th></th>
                  <th>{{ 'wc.ecare.reports.name' | translate }}</th>
                  <th>{{ 'wc.ecare.reports.code' | translate }}</th>
                  <th>{{ 'wc.ecare.reports.owner' | translate }}</th>
                  <th>{{ 'wc.ecare.reports.type' | translate }}</th>
                  <th>{{ 'wc.ecare.reports.action' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of reports" class="ecare_listing-table-row pointer">
                  <td class="images">
                    <div class="images">
                      <div
                        class="type"
                        tooltipClass="ecare-settings-tooltip"
                        placement="left"
                        ngbTooltip="{{ report.type.area }}">
                        <i
                          class="listing-icon"
                          [inlineSVG]="'/assets/img/reports/' + getReportTypeArea(report.type.area) + '.svg'"></i>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="information">{{ report.name }}</span>
                  </td>
                  <td>
                    <app-sticker variant="neutral">{{ report.type.id }}</app-sticker>
                  </td>
                  <td>
                    <span class="highlight">{{ report.owner }}</span>
                  </td>
                  <td>
                    <span class="row-border-background">{{ report.type.type }}</span>
                  </td>
                  <td class="no-wrap mobile-right">
                    <ng-container *ngIf="report?.urlDetail && report?.urlDownload; else singleUrl">
                      <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
                        <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                          <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                        </span>

                        <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ report.id }}_popover-content">
                          <div>
                            <button
                              class="btn btn-link action-list-more-actions-button"
                              type="button"
                              (click)="openUrl(report.urlDetail)">
                              {{ 'wc.ecare.reports.showDetails.button' | translate }}
                            </button>
                            <button
                              class="btn btn-link action-list-more-actions-button"
                              type="button"
                              (click)="openUrl(report.urlDownload)">
                              {{ 'wc.ecare.reports.download.button' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #singleUrl>
                      <ng-container *ngIf="report.urlDownload">
                        <i class="download-icon" [inlineSVG]="'/assets/svg/download.svg'"></i>
                        <a class="table-link bold" (click)="openUrl(report.urlDownload)">
                          {{ 'wc.ecare.reports.download.button' | translate }}
                        </a>
                      </ng-container>
                      <ng-container *ngIf="report.urlDetail">
                        <a class="table-link bold" (click)="openUrl(report.urlDetail)">
                          {{ 'wc.ecare.reports.showDetails.button' | translate }}
                        </a>
                      </ng-container>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-ecare-reports-mobile class="ecare-responsive-mobile" [reports]="reports"></app-ecare-reports-mobile>

            <div>
              <app-ecare-pagination
                [page]="search.paging.page"
                [pageSize]="search.paging.pageSize"
                [totalCount]="pagedDto?.totalItems"
                [pagesToShow]="pagedDto?.totalPages"
                (goPrev)="onPrevPage()"
                (goNext)="onNextPage()"
                (goPage)="onSpecificPage($event)"
                (pageSizeChanged)="onPageSizeChanged($event)">
              </app-ecare-pagination>
            </div>
          </ng-container>
        </div>
        <ng-template #no_results>
          <app-ecare-listing-no-results></app-ecare-listing-no-results>
        </ng-template>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
