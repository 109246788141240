import { Component } from '@angular/core';
import { CmsWidgetVideoBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: '[app-cms-widget-video]',
  templateUrl: './cms-widget-video.component.html',
  styleUrls: ['./cms-widget-video.component.scss'],
})
@EnableDynamicLoading({ customName: 'VIDEO' })
export class CmsWidgetVideoComponent extends CmsWidgetVideoBaseComponent {
  constructor(private propertyAccessorLocalService: PropertyAccessorLocalService) {
    super();
  }

  protected getCmsWidgetVideoBaseSourceLink(): Observable<string> {
    return this.propertyAccessorLocalService.getCmsWidgetVideoBaseSourceLink(this.sourceType);
  }
}
