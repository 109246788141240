<div class="search-input input">
  <input
    #searchInput
    (input)="searchEntity(searchInput.value, searchedEntity)"
    (focus)="focusOn = true"
    type="text"
    placeholder="Search" />
  <i inlineSVG="/assets/svg/search-icon.svg"></i>
  <div class="ecare-filter-autocomplete" *ngIf="focusOn && searchResultHolders && searchResultHolders.length">
    <ng-container *ngFor="let holder of searchResultHolders">
      <div class="item" (click)="handleSearch(holder._source.partyRefNo, searchedEntity)" #autocompleteItem>
        {{holder._source.displayName}}
      </div>
    </ng-container>
  </div>
</div>
