<app-confirmation-dialog-border
  [dialogId]="confirmationDialogBlockUiElementName"
  [dialogReference]="dialogReference"
  [heading]="heading"
  [localizationParameters]="localizationParameters"
  [confirmationHandler]="confirmationHandler"
  [cancellationHandler]="cancellationHandler"
  [confirmationDisabled]="confirmationDisabled || failure"
  [cancellationDisabled]="cancellationDisabled">
  <div *ngIf="failure" class="alert alert-danger">
    <div>
      {{ 'wc.ecare.dashboard.tariffDeactivation.confirmation.generalFailure.text' | translate: localizationParameters }}
    </div>
  </div>

  <ng-container *ngIf="!failure">
    <p>
      {{ 'wc.ecare.dashboard.tariffDeactivation.confirmation.text' | translate: localizationParameters }}
    </p>

    <form class="form" *ngIf="reasons" [formGroup]="tariffDeactivationForm">
      <div class="form-group-row label-center">
        <label>
          {{ 'wc.ecare.dashboard.tariffDeactivation.confirmation.reason.label' | translate }}
        </label>
        <div class="select">
          <app-input-select-form-field
            [noLabel] = true
            [options]="reasons"
            [doNotShowInternalErrors]="true"
            valueOption="value"
            labelOption="label"
            [showEmptyOption] = false
            translationPrefix="wc.ecare.dashboard.tariffDeactivation.confirmation.reason"
            formControlName="reason">
          </app-input-select-form-field>
        </div>
      </div>
      <app-input-errors
        [control]="tariffDeactivationForm.controls.reason"
        translationPrefix="wc.ecare.dashboard.tariffDeactivation.confirmation.reason" />
    </form>
  </ng-container>
</app-confirmation-dialog-border>
