import {
  Component,
  Input,
} from '@angular/core';
import {
  AbstractPageComponent,
  AccountService,
  CurrentLocaleService,
  EnableDynamicLoading,
  getCurrentHost,
} from '@btl/btl-fe-wc-common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

export interface ThirdPartyAuthorityConfig {
  name: string;
  iconPath: string;
}

@Component({
  selector: 'app-ecare-user-account-security',
  templateUrl: './ecare-user-account-security.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountSecurityComponent.PAGE_ID })
export class EcareUserAccountSecurityComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareUserAccountSecurityComponent';

  @Input()
  dialogRef;

  passwordDataForm: FormGroup = this.formBuilder.group({
    currentPassword: [null, Validators.required],
    newPassword: [null, Validators.required],
    confirmPassword: [null, Validators.required]
  });

  notTheSame: boolean = false;
  noMatchPolicy: boolean = false;
  response: 'ok' | 'error' = null;

  pageId(): string {
    return EcareUserAccountSecurityComponent.PAGE_ID;
  }

  constructor(
    private currentLocaleService: CurrentLocaleService,
    protected router: Router,
    protected route: ActivatedRoute,
    private accountService: AccountService,
    private formBuilder: FormBuilder
  ) {
    super(router, route);
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  validatePasswords(newPassword: string, confirmPassword: string) {
    if (newPassword !== confirmPassword) {
      this.notTheSame = true;
      return false;
    }
    if ((newPassword.length < 8 || !/\d/.test(newPassword))) {
      this.noMatchPolicy = true;
      return false;
    }
    return true;
  }

  submitForm() {
    this.noMatchPolicy = false;
    this.notTheSame = false;
    this.response = null;

    const newPassword = this.passwordDataForm.value.newPassword;
    const currentPassword = this.passwordDataForm.value.currentPassword;
    const confirmPassword = this.passwordDataForm.value.confirmPassword;

    if (this.passwordDataForm.valid && this.validatePasswords(newPassword, confirmPassword)) {
      this.accountService.updateAccountPassword(currentPassword, newPassword)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          this.response = (response && response.statusCode !== '200' ? 'error' : 'ok');
        })
    }
  }
}
