import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ShoppingCartItemComponent } from '../shopping-cart-item/shopping-cart-item.component';
import { ScoringResult } from '../../wizard/checkout-scoring/checkout-scoring.component';
import { OrderDto, ProductDetailDto } from '@btl/order-bff';

@Component({
  selector: 'app-checkout-deposit-items',
  templateUrl: './checkout-deposit-items.component.html',
  styleUrls: ['./checkout-deposit-items.component.scss'],
})
export class CheckoutDepositItemsComponent {
  @Input()
  scoringResult: ScoringResult;

  @Input()
  order: OrderDto;

  @Input()
  manualDepositProducts: Array<ProductDetailDto>;

  @Input()
  resultFlag: string;

  @Output()
  readonly depositChangedEmitter = new EventEmitter<OrderDto>();

  @Output()
  readonly manualDepositChangedEmitter = new EventEmitter<ProductDetailDto>();

  @Output()
  readonly manualDepositRemovedEmitter = new EventEmitter<ProductDetailDto>();

  @ViewChildren(ShoppingCartItemComponent)
  private shoppingCartItemComponents: QueryList<ShoppingCartItemComponent>;

  resultClass() {
    const result = this.resultFlag;
    if (result === 'ORANGE') {
      return 'warning';
    } else if (result === 'RED') {
      return 'danger';
    } else {
      return 'success';
    }
  }

  reloadDeposits(order) {
    this.depositChangedEmitter.emit(order);
  }

  manualDepositChanged(manualDeposit: ProductDetailDto) {
    this.manualDepositChangedEmitter.emit(manualDeposit);
  }

  manualDepositRemoved(manualDeposit: ProductDetailDto) {
    this.manualDepositRemovedEmitter.emit(manualDeposit);
  }
}
