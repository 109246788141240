import { Component } from '@angular/core';
import { CmsWidgetTipImagesListBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-tip-images-list]',
  templateUrl: './cms-widget-tip-images-list.component.html',
  styleUrls: ['./cms-widget-tip-images-list.component.scss'],
})
@EnableDynamicLoading({ customName: 'TIP_IMAGE' })
export class CmsWidgetTipImagesListComponent extends CmsWidgetTipImagesListBaseComponent {}
