import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationTypeEnum } from '../navigation-menu.component';
import { AuthFactoryService, BlockTemplateComponent } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
})
export class MobileMenuComponent {
  readonly NavigationTypeEnum = NavigationTypeEnum;

  @Input()
  showMobileMenu: boolean = false;
  isLoggedIn: boolean = false;

  @Input()
  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;

  @Input()
  mainNavigation: TemplateRef<any>;

  @Input()
  navigation: TemplateRef<any>;

  @Input()
  topMenuRightSide: TemplateRef<any>;

  @Input()
  topMenuLeftSide: TemplateRef<any>;

  @Output() showMobileMenuChanged = new EventEmitter<boolean>();

  constructor(
    public router: Router,
    public authFactoryService: AuthFactoryService,
  ) {
  }

  goToShoppingCart() {
    this.router.navigate(['/eshop/shopping-cart']);
  }

  toggleShowMenu() {
    this.showMobileMenu = false;
    this.showMobileMenuChanged.emit(this.showMobileMenu);
  }

  protected readonly BlockTemplateComponent = BlockTemplateComponent;
}
