import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-advanced-filter-field',
  templateUrl: './advanced-filter-field.component.html',
  host: {'class': '-filter-field'}
})
export class AdvancedFilterFieldComponent implements OnInit {

  @HostBinding('class.full-line') fullLine: boolean = false;
  form: FormGroup;

  @Output() changeFilterFieldAction = new EventEmitter<{controlName: string, value: any}>();

  ngOnInit() {
    if (this.fullWidth) {
      this.fullLine = true;
    }
  }

  /*
  -------------------
  required attributes
   */

  @Input()
  labelLocalizedKey: string;

  /* required if ng-content is not present */

  @Input()
  fieldType: 'date' | 'select' | 'input' | 'number' | 'checkbox' | undefined;

  @Input()
  controlName: string;

  /* select input specific values */

  /* select box required attributes */

  @Input()
  selectOptions: any[] = [];

  @Input()
  optionValue: string;

  @Input()
  optionLabel: string;

  /* select box voluntary attributes */

  @Input()
  translationPrefix: string;

  @Input()
  isCodebook: boolean = false;

  @Input()
  codebookType;

  @Input()
  disableOptionsTranslate = false;

  @Input()
  showEmptyOption: boolean = false;

  @Input()
  emptyLocalizedKey;

  @Input()
  emptyValue = null;

  @Input()
  multipleSelect = false;

  /*
  datepicker specific values
   */

  @Input()
  showTime: boolean = false;

  @Input()
  startOfTheDay;

  @Input()
  endOfTheDay;

  /*
  --------------------
  general voluntary attributes
   */

  /*
    additional css for form-field element
   */
  @Input()
  additionalCss: string;

  @Input()
  idAttribute: string;

  @Input()
  placeholderLocalizedKey: string;

  @Input()
  isInternal: boolean = false;

  /*
    by default the form element takes 50% of box width; if fullWidth is true then the element takes 100%
   */
  @Input()
  fullWidth: boolean;

  callChangeFilterField(formControl: string, value: any): void {
    this.changeFilterFieldAction.next({controlName: formControl, value: value});
  }
}
