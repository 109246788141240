import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecare-mobile-row-general',
  templateUrl: './ecare-mobile-row-general.component.html'
})
export class EcareMobileRowGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
