import { Component, Input, OnDestroy } from '@angular/core';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormUtils } from '../../../helpers/form-utils';
import { BlockUIService } from 'ng-block-ui';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from '../../../helpers/order-utils';
import {
  AssetDto,
  CodebookParamDto,
  CustomerAccountDto,
  CustomerDto,
  OrderDto,
  OrderItemDto,
  OrderParamDto, TariffSpaceDto
} from '@btl/order-bff';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-tariff-deactivation',
  templateUrl: './tariff-deactivation.component.html',
})
export class TariffDeactivationComponent extends ConfirmationDialogComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  //region Data:

  /**
   * A list of available reasons to deactivate a tariff.
   */
  reasons = null;

  /**
   * The tariff deactivation form.
   */
  tariffDeactivationForm: FormGroup = this.formBuilder.group({
    reason: [null, [Validators.required]],
  });

  /**
   * A flag specifying if the tariff deactivation failed (from any reason).
   */
  failure: boolean;

  /**
   * The order.
   */
  order: OrderDto;

  //region IO:

  /**
   * The customer of the deactivated tariff.
   */
  @Input()
  customer: CustomerDto;

  /**
   * The customer account of the deactivated tariff.
   */
  @Input()
  customerAccount: CustomerAccountDto;

  /**
   * The tariff space of the deactivated tariff.
   */
  @Input()
  tariffSpace: TariffSpaceDto;

  /**
   * The deactivated tariff.
   */
  @Input()
  tariff: AssetDto;

  private finalConfirmationHandler = null;

  private isSubscribed: boolean = false;

  constructor(
    private orderingService: WcOrderingService,
    protected blockUIService: BlockUIService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super(blockUIService);
  }

  //region Life-cycle:
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.heading = 'wc.ecare.dashboard.tariffDeactivation.confirmation.heading';
    if (this.confirmationHandler) {
      this.finalConfirmationHandler = this.confirmationHandler;
    }
    this.confirmationHandler = this.tariffDeactivationConfirmationHandler;
    this.startProgress();
    this.loadReasonsFromCodebook();

    super.ngOnInit();
  }

  createDeactivationOrder() {
    const newOrder = OrderUtils.getInitOrder(ScenarioStepTypeEnum.CHECKOUT_THANKYOU, ScenarioTypeEnum.DEACTIVATION);
    OrderUtils.addCustomerAttributeToOrder(newOrder, this.customer);
    OrderUtils.addCustomerAccountAttributeToOrder(newOrder, this.customerAccount);
    OrderUtils.addAttributeToOrder(newOrder, 'reason', this.tariffDeactivationForm.controls.reason.value);

    this.orderingService
      .createOrder(newOrder, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order1: OrderDto) => {
        const orderItems: OrderItemDto[] = [];

        const tariffOrderItem: OrderItemDto = {
          productId: this.tariff.product.id,
          asRefNo: this.tariff.id,
          action: 'DELETE',
          partyRefNo: this.tariffSpace.id,
        };
        orderItems.push(tariffOrderItem);

        this.orderingService
          .updateOrderItems(order1, orderItems, true)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((order2: OrderDto) => {
            this.order = order2;
            this.stopProgress();
          }, this.setFailure);
      }, this.setFailure);
  }

  //region Helpers:

  /**
   * The yes handler.
   *
   * @param dialogReference The dialog reference.
   */
  private tariffDeactivationConfirmationHandler = (dialogReference: NgbModalRef) => {
    FormUtils.validateAllFormFields(this.tariffDeactivationForm);
    if (this.tariffDeactivationForm.invalid) {
      return;
    }

    this.startProgress();
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'reason', this.tariffDeactivationForm.value.reason);

    const quoteAsMap = {
      orderAttributes: orderParamsDto,
      recordVersion: this.order.recordVersion,
    };

    this.orderingService
      .patchOrder(this.order.id, quoteAsMap, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.order = order;
        this.orderingService
          .confirmOrder(this.order, true)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((order: OrderDto) => {
            this.stopProgress();
            dialogReference.dismiss();
            this.finalConfirmationHandler(this.dialogReference);
            const confirmationMessageRef = this.modal.open(MessageDialogComponent, { size: 'sm', backdrop: 'static' });
            const confirmationMessageComponent = <MessageDialogComponent>confirmationMessageRef.componentInstance;
            confirmationMessageComponent.dialogRef = confirmationMessageRef;
            confirmationMessageComponent.headerTranslationKey =
              'wc.ecare.dashboard.tariffDeactivation.confirmation.success.heading';
            confirmationMessageComponent.messageTranslationKey =
              'wc.ecare.dashboard.tariffDeactivation.confirmation.success.text';
            confirmationMessageComponent.enableClose = true;
            confirmationMessageComponent.closeButtonHandler = () => {};
          }, this.setFailure);
      });
  };

  protected validateInputs(): void {
    super.validateInputs();

    if (!this.customer) {
      throw new Error('The customer parameter must have non-null value.');
      this.startProgress();
    }
    if (!this.customerAccount) {
      throw new Error('The customerAccount parameter must have non-null value.');
      this.startProgress();
    }
    if (!this.tariff) {
      throw new Error('The tariff parameter must have non-null value.');
      this.startProgress();
    }
  }

  /**
   * Set tariff deactivation general failure.
   *
   * @param error The error.
   */
  private setFailure = (error: any) => {
    this.failure = true;
    if (error.error && error.error.failures[0]) {
      this.localizationParameters['failureReason'] = error.error.failures[0].localizedDescription
        ? error.error.failures[0].localizedDescription
        : error.error.failures[0].detail;
    } else {
      this.localizationParameters['failureReason'] = '';
    }

    this.stopProgress();
  };

  loadReasonsFromCodebook() {
    this.codebookService
      .getCodebooks('REASONSTATUS')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(codebookDtos => {
        let reasons = [];
        codebookDtos.forEach(codebookDto => {
          const reasonStatusParams: CodebookParamDto[] = codebookDto.parameters;
          if (
            reasonStatusParams.length !== 0 &&
            reasonStatusParams.find(param => param.name === 'reasonStatus' && param.value === 'DEACTIVATION')
          ) {
            reasons.push({
              value: codebookDto.code,
              label: codebookDto.localizedTexts.find(lt => lt.locale === this.currentLocaleService.getCurrentLanguage())
                .text,
            });
          }
        });
        this.reasons = [...reasons];
        if (this.reasons.length > 0) {
          this.tariffDeactivationForm.controls.reason.patchValue(this.reasons[0].value);
          this.createDeactivationOrder();
        } else {
          this.stopProgress();
        }
      });
  }
}
