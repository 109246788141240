import { Component, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Keyboard, Navigation, Pagination, Scrollbar } from 'swiper';
import { ServiceUnitsData } from './ecare-tariff-shared-units-tile/ecare-tariff-shared-units-tile.component';

SwiperCore.use([Keyboard, Scrollbar, Navigation, Pagination]);

@Component({
  selector: 'app-tariff-shared-units-swiper',
  templateUrl: './ecare-tariff-shared-units-swiper.html',
  encapsulation: ViewEncapsulation.None,
})
export class EcareTariffSharedUnitsSwiperComponent {
  sharedUnits: ServiceUnitsData[] = [
    {
      packageName: 'Mobile Internet',
      usedAmount: 1.72,
      remainingAmount: 0.28,
      units: 'GB',
      iconType: 'internet_icon',
    },
    {
      packageName: 'SMS to all networks',
      usedAmount: 273,
      remainingAmount: -1,
      units: 'SMS',
      iconType: 'sms_icon',
    },
    {
      packageName: 'Minutes for country home',
      usedAmount: 42,
      remainingAmount: -1,
      units: 'min',
      iconType: 'minutes_icon',
    },
    {
      packageName: 'Roaming World data',
      usedAmount: 200,
      remainingAmount: 200,
      units: 'MB',
      iconType: 'internet_icon',
    },
  ];
}
