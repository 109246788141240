import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-subscription-select-widget',
  templateUrl: './subscription-select-widget.component.html',
})
export class SubscriptionSelectWidgetComponent {

  @Input() subscriptionsToSelect = new Map<string, PartyDto>();
  @Input() subscription: PartyDto;
  @Input() tariffIdentification: string;
  @Output() readonly subscriptionChanged = new EventEmitter<PartyDto>();

}
