import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private static readonly TITLE_MAX_LENGTH: number = 69;
  private static readonly DESCRIPTION_MAX_LENGTH: number = 150;
  private static readonly KEYWORDS_MAX_LENGTH: number = 150;
  public static readonly STATIC_KEYWORDS = 'eshop, goods, btl, btl demo, emeldi, ecommerce, crm';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private doc,
    private router: Router
  ) {}

  public setMeta(title: string, description?: string, keywords?: string, url?: string, image?: string) {
    const cleanTitle = this.getCleanMeta(title, SeoService.TITLE_MAX_LENGTH);
    this.setTitle(cleanTitle);
    this.setMetaTag('name', 'title', cleanTitle);
    this.setMetaTag('property', 'og:title', cleanTitle);
    if (description) {
      const cleanDescription = this.getCleanMeta(description, SeoService.DESCRIPTION_MAX_LENGTH);
      this.setMetaTag('name', 'description', cleanDescription);
      this.setMetaTag('property', 'og:description', cleanDescription);
    }
    if (keywords) {
      keywords = this.getCleanMeta(keywords, SeoService.KEYWORDS_MAX_LENGTH);
      this.setMetaTag('name', 'keywords', keywords);
    }
    if (url) {
      this.setMetaTag('property', 'og:url', url);
      this.setCanonicalLinkRel(url);
    }
    if (image) {
      this.setMetaTag('property', 'og:image', image);
    }
  }

  private getCleanMeta(value: string, maxLength: number) {
    value.replace(/<[^>]*>/g, '');
    const metaText = value.substr(0, maxLength);
    return metaText;
  }

  private setMetaTag(selector: string, selectorValue: string, content: string) {
    if (this.metaService.getTag(`${selector}='${selectorValue}'`)) {
      this.metaService.updateTag({ [selector]: selectorValue, content: content }, `${selector}='${selectorValue}'`);
    } else {
      this.metaService.addTag({ [selector]: selectorValue, content: content });
    }
  }

  private setCanonicalLinkRel(canonicalUrl?: string) {
    if (this.doc) {
      const head = this.doc.head;
      if (head) {
        const canonicalLink = this.doc.querySelector("link[rel='canonical']");
        if (canonicalLink) {
          if (!canonicalUrl) {
            //remove node when canonical url is not set
            canonicalLink.parentNode.removeChild(canonicalLink);
            return;
          }
          canonicalLink.setAttribute('href', canonicalUrl);
        } else if (canonicalUrl) {
          const link: HTMLLinkElement = this.doc.createElement('link');
          link.setAttribute('rel', 'canonical');
          this.doc.head.appendChild(link);
          link.setAttribute('href', canonicalUrl);
        }
      }
    }
  }

  private setTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
