import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WishListDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { WishListService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wish-list-item-popup',
  templateUrl: './wish-list-item-popup.component.html',
  styleUrls: ['./wish-list-item-popup.component.css'],
})
export class WishListItemPopupComponent implements OnInit, OnDestroy {
  @Input()
  wishList: WishListDto;

  @Input()
  id;

  @Input()
  productCode;

  wishListItem = null;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private wishListService: WishListService) {}

  ngOnInit(): void {
    this.checkIfProductInWishList();

    this.wishListService.wishListChange.pipe(takeUntil(this.onDestroy$)).subscribe(wishList => {
      if (wishList && this.wishList.id === wishList.id) {
        this.wishList = wishList;
        this.checkIfProductInWishList();
      }
    });
  }

  checkIfProductInWishList() {
    this.wishListService
      .getWishListItems(this.wishList.id, false)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(wishListItems => {
        const product = wishListItems.find(
          wishListItem => wishListItem.productCode && wishListItem.productCode === this.productCode
        );
        this.wishListItem = product;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  toggleItemInWishList(wishListId: string, checkbox: any) {
    const isAdd: boolean = checkbox.currentTarget.checked;
    if (wishListId) {
      if (isAdd) {
        this.wishListService.addWishlistItem(wishListId, this.productCode);
      } else {
        this.wishListService.deleteWishListItem(wishListId, this.wishListItem.id);
      }
    }
  }
}
