<tr class="ecare_listing-table-row clickable" (click)="shopDetail = !shopDetail">
  <td class="no-wrap">
    {{ shop._source.displayName }}
  </td>
  <td class="no-wrap">
    {{ shop._source.address?.zip }}
  </td>
  <td class="no-wrap">
    {{ shop._source.address?.city }}
  </td>
  <td class="no-wrap">
    {{
      shop._source.address?.street +
        ' ' +
        shop._source.address?.streetNo +
        (shop._source.address?.buildingNo ? '/' + shop._source.address?.buildingNo : '')
    }}
  </td>
  <td *ngIf="enrichAvailability">{{ shop['amount'] }}</td>
</tr>
<tr *ngIf="shopDetail && shopWorkingHours">
  <td colspan="4" class="working-hours">
    <table>
      <tr *ngFor="let day of shopWorkingHours">
        <td>{{ 'wc.common.days.' + day.day | translate }}</td>
        <td>{{ day.hours }}</td>
      </tr>
    </table>
    <button class="btn btn-default" (click)="shopSelected.emit(shop)">
      {{ 'wc.ecare.pickUpPoints.choose' | translate }}
    </button>
  </td>
</tr>
