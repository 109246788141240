<div class="product__search">
  <div class="product__detail-header">
    <ng-container *ngIf="label"
      ><app-section-header textAlign="center" [title]="label"></app-section-header
    ></ng-container>
    <p *ngIf="description" class="product__detail-headerDescription">
      {{ description }}
    </p>
  </div>
  <div *ngIf="transformedProductDetails" class="products__list">
    <div
      *ngFor="let product of getPaginatedProducts(); let index = index"
      class="product"
      app-product-listing-item
      [product]="product"></div>
  </div>
</div>
