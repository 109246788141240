<div *ngIf="showPopup === 'ShoppingCart' && navigationType !== NavigationTypeEnum.checkout" class="drawer-background">
  <div class="container">
    <div class="drawer-content" [ngClass]="{ hidePopup: !isCollapsed }">
      <div class="drawer-header">
        <h6>{{ 'wc.shopping.cart.label' | translate }}</h6>
        <i [inlineSVG]="'/assets/svg/hamburger_icon.svg'" (click)="closeCollapse()"></i>
      </div>
      <div
        *ngIf="
              showPopup === 'ShoppingCart' &&
                preCalculatedShoppingCart.quantityOfItemsInShoppingCart &&
                preCalculatedShoppingCart.order.orderStateType !== 'CONFIRMED' &&
                preCalculatedShoppingCart.order.orderStateType !== 'PENDING';
              else emptyCart
            "
        class="items-section"
        app-shopping-cart-items
        [showQuantityChange]="false"
        [showConfigurationBtn]="false"
        [showDetails]="false"></div>
      <div class="summary-section" app-ordering-wizard-actions [showPrice]="true"></div>
      <ng-template #emptyCart>
        <div class="empty_cart">
          <i [inlineSVG]="'/assets/svg/empty_cart.svg'"></i>
          <p>{{ 'wc.shopping.cart.empty' | translate }}.</p>
          <app-button
            size="shrink"
            label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
            [routerLink]="['/']"></app-button>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="drawer-overlay" (click)="closeCollapse()"></div>
</div>
