import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-ecare-section',
  templateUrl: './ecare-section.component.html'
})
export class EcareSectionComponent implements OnInit {

  @Input()
  sectionTitle;

  constructor() { }

  ngOnInit(): void {
  }

}
