<div
  *ngIf="subscriptionsToSelect.size > 1; else oneSubscription"
  ngbDropdown
  class="widget--header_account"
  placement="bottom-right">
  <app-button
    variant="whiteWithBorder"
    size="shrink"
    iconPathAfter="/assets/svg/menu-down-arrow.svg"
    id="widgetAccountDropdown"
    ngbDropdownToggle
    label="{{ tariffIdentification }}">
  </app-button>
  <div ngbDropdownMenu aria-labelledby="widgetAccountDropdown">
    <ng-container *ngFor="let tariff of subscriptionsToSelect | keyvalue">
      <button
        *ngIf="tariff.value.id != subscription.id"
        ngbDropdownItem
        class="widget--account_dropdownItem"
        (click)="subscriptionChanged.emit(tariff.value)">
        {{ tariff.key }}
      </button>
    </ng-container>
  </div>
</div>
<ng-template #oneSubscription>
  <div class="widget--header_account" placement="bottom-right">
    {{ tariffIdentification }}
  </div>
</ng-template>
