<app-ecare-mobile-row-general
  *ngFor="let ticket of ticketDtos; let i = index"
  class="ecare_listing-table-row"
  [ngClass]="{ '-not-important-row': ticket.businessState === 'CLOSED', 'selected-row': selectFormArray?.at(i).value }">
  <div class="mobile-table-row-wrapper" [for]="'ticket-' + i" (click)="toggleCheckbox(i)">
    <input
      *ngIf="selectFormArray"
      class="checkbox"
      type="checkbox"
      [id]="'ticket-' + i"
      [formControl]="selectFormArray?.at(i)"
      [checked]="selectFormArray?.at(i).value" />
    <div class="mobile-table-row-flex space-between">
      <div class="mobile-table-row-flex">
        <div class="information">{{ ticket.subject }}</div>
        <ng-container *ngIf="ticket.type.areaType === 'TASK'; else priorityNumber">
          <div class="wrapped">
            <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'"></i>
            <div class="highlight">{{ 'wc.ecare.ticket.task.priorities.' + ticket.priority | translate }}</div>
          </div>
        </ng-container>
        <ng-template #priorityNumber>
          <div class="task-priority higlighted">{{ ticket.priority }}</div>
        </ng-template>
      </div>
      <ng-container>
        <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
          <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
            <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
          </span>

          <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ ticket.id }}_popover-content">
            <div>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(ticket)">
                {{ 'wc.ecare.ticket.edit.button' | translate }}
              </button>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(ticket, true)">
                {{ 'wc.ecare.ticket.editInNewTab.button' | translate }}
              </button>
              <button class="btn btn-link action-list-more-actions-button" type="button" (click)="delete(ticket)">
                {{ 'wc.ecare.ticket.delete.button' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="additional-info">
      <div *ngIf="ticket.startFrom">
        <span>{{ 'wc.ecare.ticket.startFrom' | translate }}: {{ ticket.startFrom | date : 'dd.MM.yyyy HH:mm' }}</span>
      </div>
      <div *ngIf="!hideDueDate && ticket.slaDueDate">
        <span
          >{{ 'wc.ecare.dashboard.dueDate.label' | translate }}:
          {{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</span
        >
      </div>
    </div>
    <div class="mobile-table-row-flex">
      <i
        class="menu-icon task-icon"
        [inlineSVG]="'/assets/img/ecare/' + (ticket.parentId ? 'sub' : '') + 'task.svg'"></i>
      <div *ngIf="!hideState">
        <span class="task-state {{ ticket.businessState }}">{{ ticket.businessState }}</span>
      </div>
    </div>

    <div>
      <span app-acl-internal-evaluate-component>{{ ticket.assignedTo }}</span>
    </div>
  </div>
</app-ecare-mobile-row-general>
