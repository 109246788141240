import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderDto, OrderParamDto } from '@btl/order-bff';
import { StickyMessageService } from '@btl/btl-fe-wc-common';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderUtils } from '../../../../../helpers/order-utils';
import { OpportunityDetailsComponent } from '../opportunity-details.component';
import QuoteStateDtoEnum = OrderDto.QuoteStateDtoEnum;

@Component({
  selector: 'app-quote-details-popup',
  templateUrl: './quote-details-popup.component.html',
})
export class QuoteDetailsPopupComponent implements OnInit {
  public static readonly POPUP_WINDOW_CSS_CLASSES = 'quote-details-modal';

  @Input()
  quote: OrderDto;

  @Input()
  dialogReference: NgbModalRef;

  @Input()
  saveChangesHandler = (quote: OrderDto) => {};

  form: FormGroup = this.formBuilder.group({});
  quoteStateDtoEnum = QuoteStateDtoEnum;

  constructor(
    private formBuilder: FormBuilder,
    private orderingService: WcOrderingService,
    private stickyMessageService: StickyMessageService
  ) {}

  ngOnInit(): void {
    if (this.isQuoteStateIn([QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending])) {
      this.form.addControl('quoteName', this.formBuilder.control(this.getQuoteAttributeValue('description')));
      this.form.addControl('category', this.formBuilder.control(this.getQuoteAttributeValue('quoteCategory')));
      this.form.addControl('note', this.formBuilder.control(this.getQuoteAttributeValue('quoteNotes')));
    }
    if (this.isQuoteStateIn([QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved])) {
      this.form.addControl('validTo', this.formBuilder.control(this.getQuoteValidTo()));
    }
  }

  saveChanges() {
    const formValue = this.form.getRawValue();
    const orderParamsDto: Array<OrderParamDto> = [];

    if (this.isQuoteStateIn([QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending])) {
      OrderUtils.updateOrderAttr(orderParamsDto, 'description', formValue.quoteName);
      OrderUtils.updateOrderAttr(orderParamsDto, 'quoteCategory', formValue.category);
      OrderUtils.updateOrderAttr(orderParamsDto, 'quoteNotes', formValue.note);
    }
    if (this.isQuoteStateIn([QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved])) {
      const validForJson = this.getQuoteAttributeValue('quoteValidFor');
      if (validForJson) {
        const validFor = JSON.parse(validForJson);
        validFor.endDateTime = formValue.validTo;
        OrderUtils.updateOrderAttr(orderParamsDto, 'quoteValidFor', JSON.stringify(validFor));
      }
    }

    const quoteAsMap = {
      orderAttributes: orderParamsDto,
      recordVersion: this.quote.recordVersion,
    };
    this.orderingService.patchOrder(this.quote.id, quoteAsMap).subscribe(quote => {
      this.dialogReference.dismiss();
      this.saveChangesHandler(quote);
      this.stickyMessageService.addStickySuccessMessage('wc.ecare.quoteDetails.save.ok');
    });
  }

  getQuoteAttributeValue(attrName: string): string {
    return this.quote.orderAttributes.find(findParam => findParam.attr === attrName)?.value;
  }

  getQuoteValidTo(): Date {
    const validForJson = this.getQuoteAttributeValue('quoteValidFor');
    if (validForJson) {
      const validTo = JSON.parse(validForJson)['endDateTime'];
      if (validTo) {
        return new Date(validTo);
      }
    }
    return null;
  }

  getQuoteCompletedDate(): Date {
    const completedTo = this.getQuoteAttributeValue('qEffectiveQuoteCompletionDate');
    if (completedTo) {
      return new Date(completedTo);
    }
    return null;
  }

  isQuoteStateIn(states: string[]): boolean {
    return states.includes(this.quote.quoteState);
  }

  getQuoteState(quote: OrderDto) {
    const quoteState = quote.quoteState;
    const quoteValidFor = quote.orderAttributes.find(oa => oa.attr === 'quoteValidFor')?.value;
    return OpportunityDetailsComponent.getQuoteState(quoteState, quoteValidFor);
  }
}
