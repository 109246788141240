import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountService, CompareType, Search, ServiceUtils } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CustomerSelectionPopupComponent
} from '../wizard/ecare-customers/customer-selection-popup/customer-selection-popup.component';
import {
  CustomerAccountSelectionPopupComponent
} from '../wizard/ecare-customer-accounts/customer-account-selection-popup/customer-account-selection-popup.component';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';
import { CustomerAccountDto, CustomerDto, OrderFrontendService } from '@btl/order-bff';
import { UserSelectionPopupComponent } from '../wizard/ecare-users/user-selection-popup/user-selection-popup.component';
import {
  OrderSelectionPopupComponent
} from '../wizard/ecare-orders/order-selection-popup/order-selection-popup.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-form-field-modal-picker',
  templateUrl: './form-field-modal-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldModalPicker),
      multi: true,
    },
  ],
})
export class FormFieldModalPicker implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  parentGroup: FormGroup;

  @Input()
  pickerType: PickerInputType;

  @Input()
  controlName;

  @Input()
  translationPrefix = '';

  @Input()
  inputType;

  @Input()
  labelField;

  @Input()
  valueField;

  @Input()
  disabled = false;

  @Input()
  labelFunction = (object: any, value: any) => {
    switch (this.pickerType) {
      case PickerInputType.CUSTOMER:
        const customer: CustomerDto = object;
        if (value) {
          this.showValue = customer.displayName ? customer.displayName : customer.id;
        }
        break;
      case PickerInputType.CUSTOMER_ACCOUNT:
        const customerAccount: CustomerAccountDto = object;
        if (value) {
          this.showValue = customerAccount.displayName ? customerAccount.displayName : customerAccount.id;
        }
        break;
      default:
        this.showValue = value;
        break;
    }
    if (!this.showValue) {
      this.showValue = value;
    }
  };

  @Input() selectPopUpOptions = {};
  @Input() customerId = null;

  value;
  showValue: string = null;

  @Output()
  readonly selectionStarts: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly selectionEnds: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly preSelectionDone: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private ngbModal: NgbModal,
    private readonly customerService: CustomerService,
    private readonly accountService: AccountService,
    private readonly bffOrderingService: OrderFrontendService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.initInputs();
    if (this.parentGroup && this.parentGroup.controls[this.controlName]) {
      this.parentGroup.controls[this.controlName].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
        if (!value) {
          this.showValue = null;
        } else {
          this.initInputs();
        }
      });
    }
  }

  initInputs() {
    const value = this.parentGroup.getRawValue()[this.controlName];

    switch (this.pickerType) {
      case PickerInputType.CUSTOMER:
        if (value && this.valueField === 'id') {
          this.getCustomerById(value);
        } else {
          this.labelFunction(value, value);
        }
        break;
      case PickerInputType.CUSTOMER_ACCOUNT:
        if (value && this.valueField === 'id') {
          this.getCustomerAccountById(value);
        } else {
          this.labelFunction(value, value);
        }
        break;
      case PickerInputType.USER:
        if (value && this.valueField) {
          this.getAccountByColumn(this.valueField, value);
        } else {
          this.labelFunction(value, value);
        }
        break;
      case PickerInputType.ORDER:
        if (value && this.valueField === 'id') {
          this.getOrderById(value);
        } else {
          this.labelFunction(value, value);
        }
        break;
    }
  }

  setShowValue(result, value) {
    if (this.labelField && result && result[this.labelField]) {
      this.showValue = result[this.labelField];
    } else {
      this.labelFunction(result, value);
    }
  }

  select() {
    this.selectionStarts.emit();
    let modalTypeComponent;
    if (this.pickerType) {
      switch (this.pickerType) {
        case PickerInputType.CUSTOMER:
          modalTypeComponent = CustomerSelectionPopupComponent;
          break;
        case PickerInputType.CUSTOMER_ACCOUNT:
          modalTypeComponent = CustomerAccountSelectionPopupComponent;
          break;
        case PickerInputType.USER:
          modalTypeComponent = UserSelectionPopupComponent;
          break;
        case PickerInputType.ORDER:
          modalTypeComponent = OrderSelectionPopupComponent;
          break;
      }
      const modalRef = this.ngbModal.open(modalTypeComponent, { windowClass: 'select-modal' });
      modalRef.dismissed.subscribe(() => {
        this.selectionEnds.emit();
      });
      const modalSelectComponent = modalRef.componentInstance;
      modalSelectComponent.dialogRef = modalRef;
      if (modalTypeComponent === CustomerAccountSelectionPopupComponent) {
        modalSelectComponent.customerId = this.customerId;
        modalSelectComponent.canChangeCustomer = false;
      }

      Object.keys(this.selectPopUpOptions).forEach(key => {
        modalSelectComponent[key] = this.selectPopUpOptions[key];
      });
      modalSelectComponent.handler = result => {
        this.labelFunction(result, this.valueField ? result[this.valueField] : result);
        this.parentGroup.controls[this.controlName].patchValue(this.valueField ? result[this.valueField] : result);
        this.selectionChanged.emit(result);
      };
    }
  }

  remove() {
    this.showValue = null;
    this.parentGroup.controls[this.controlName].patchValue(null);
    this.selectionChanged.emit(null);
  }

  getCustomerById(value: any) {
    this.customerService
      .getCustomer(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
        this.preSelectionDone.emit(result);
      });
  }

  getCustomerAccountById(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'id',
          value: value,
          compareType: CompareTypeDtoEnum.EQUAL,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };
    this.customerService
      .filterCustomerAccounts(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result.data[0], value);
        this.preSelectionDone.emit(result.data[0]);
      });
  }

  getAccountByColumn(column: string, value: any) {
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({ column: column, value: value, compareType: 'EQUAL' });
    this.accountService
      .filterAccounts(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result && result.data.length > 0) {
          this.setShowValue(result.data[0], value);
          this.preSelectionDone.emit(result.data[0]);
        }
      });
  }

  getOrderById(value: any) {
    this.bffOrderingService
      .getOrderById(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
        this.preSelectionDone.emit(result);
      });
  }
}

export enum PickerInputType {
  CUSTOMER = 'customer',
  CUSTOMER_ACCOUNT = 'customerAccount',
  USER = 'user',
  ORDER = 'order',
}
