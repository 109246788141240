import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormUtils } from '../../helpers/form-utils';

@Component({
  selector: 'app-call-me-back-modal',
  templateUrl: './call-me-back-modal.component.html',
})
export class CallMeBackModalComponent {
  @Input()
  dialogRef;

  @Input()
  handler = phoneNumber => {};

  form = this.formBuilder.group({
    phoneNumber: [null, Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {}

  cancel() {
    this.dialogRef.dismiss();
  }

  callMeBack() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.handler(this.form.getRawValue().phoneNumber);
    }
  }
}
