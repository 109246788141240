import { Component, OnInit } from '@angular/core';
import { AbstractPageComponent, AuthFactoryService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-user-account-agreements',
  templateUrl: './ecare-user-account-agreements.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountAgreementsComponent.PAGE_ID })
export class EcareUserAccountAgreementsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountAgreementsComponent';

  pageId(): string {
    return EcareUserAccountAgreementsComponent.PAGE_ID;
  }

  account: AccountDto;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.account = this.authFactoryService.getAuthService().account;
  }
}
