<div class="technologyCheck__search" [formGroup]="form">
  <!-- TODO - missing GD -->
  <div class="technologyCheck__wrapper">
    <i
      id="019_navigation_menu"
      class="primary-menu__icon primary-menu__icon-search"
      [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
    <input type="hidden" formControlName="address" />
    <input
      #addressSearch
      type="text"
      id="searchMode"
      autocomplete="off"
      formControlName="addressSearch"
      [placeholder]="'Address...'"
      [value]="filter?.attributes?.text ? filter?.attributes?.text : ''"
      (input)="searchChange(addressSearch.value)"
      (keyup.enter)="autocomplete.hidden = true"
      (focusout)="autocompleteFocusOut(autocomplete)"
      (focusin)="disabled ? (autocomplete.hidden = true) : (autocomplete.hidden = false)" />
    <div class="col-sm-2"></div>
    <div #autocomplete class="technologyCheck__autocomplete">
      <div *ngFor="let address of autocompleteProducts | async; let i = index" class="autocomplete-items">
        <a href="javascript:void(0)" id="{{ i }}_005_delivery" (click)="selectAddress(address?._source)">
          <span
            [innerHTML]="
              address._source | displayAddress : addressTypes.TECHNOLOGY_CHECK | highlight : addressSearch.value
            "></span>
        </a>
      </div>
    </div>
    <app-input-errors
      [control]="form.controls['address']"
      [translationPrefix]="'wc.shopping.technologyCheck.address.wrong'" />
  </div>
</div>
