<div *ngIf="account" class="ecare-account-settings -sub-section">
  <div class="section-content" *ngIf="deliveryContactPresent">
    <app-user-contacts [contactType]="contactTypeEnum.DELIVERY" [account]="account">
    </app-user-contacts>
  </div>
  <div class="section-content" *ngIf="hwInvoicesContactPresent">
    <app-user-contacts [contactType]="contactTypeEnum.HWINVOICING" [account]="account">
    </app-user-contacts>
  </div>
</div>
