<div class="page-header" [ngClass]="{ withBreadcrumb: isWithBreadcrumb }">
  <h3>{{ title }}</h3>
  <div *ngIf="displayInRow" class="page-header-actions">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </div>
  <div *ngIf="!displayInRow" class="page-header-actions_mobile">
    <div ngbDropdown placement="bottom-right">
      <div app-button ngbDropdownToggle [variant]="'secondary'" [iconPath]="'/assets/svg/three-dots.svg'" *ngIf="dropdownMenu.children.length > 0"></div>
      <div ngbDropdownMenu #dropdownMenu aria-labelledby="shoppingCartDropdown">
        <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
      </div>
    </div>
  </div>
  <ng-template #tempOutlet>
    <ng-content></ng-content>
  </ng-template>
</div>
