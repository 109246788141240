import { ProductInShoppingCart } from '../models/product-in-shopping-cart';
import _ from 'lodash';
import { ProductDetailDto, ProductInfoDto } from '@btl/order-bff';
import { CategoryTypeEnum } from '../models/product-filter';
import { Product } from '../models/product';

export class ProductUtils {
  private static allowedCategoriesForProductDetail = [
    CategoryTypeEnum.PRODC_FREE_HW,
    CategoryTypeEnum.PRODC_GE_HW_PHONE_MOBILE,
    CategoryTypeEnum.PRODC_SU_HW,
    CategoryTypeEnum.PRODC_SU_TARIFF,
  ];

  static getProductInCartByCategoryId(
    productInShoppingCart: ProductInShoppingCart,
    categoryId: CategoryTypeEnum
  ): ProductDetailDto {
    const filteredProductInShoppingCart = _.find(
      productInShoppingCart.children,
      childProductInShoppingCart => childProductInShoppingCart.productDetail.categoryId === categoryId
    );
    return _.isNil(filteredProductInShoppingCart) ? undefined : filteredProductInShoppingCart.productDetail;
  }

  static isTariff(product: ProductDetailDto): boolean {
    return _.isNil(product) || !product.parametersStatic ? false : product.parametersStatic['isMainTariff'] === 'true';
  }

  static hasLinkToDetail(product: ProductDetailDto | Product): boolean {
    return _.includes(ProductUtils.allowedCategoriesForProductDetail, product.categoryId);
  }

  /**
   * Tests if the given product is of the given category (or subcategory). The comparison is suffix-based - subcategory has some suffix to
   * the parent category (e. g. if PRODC_TARIFF is parent, PRODC_TARIFF_POP is its child). This solution is approved as working.
   *
   * @param product The tested product.
   * @param categoryId The tested category ID.
   * @return true if the product is of the category, false otherwise.
   */
  static isOfCategory(product: ProductDetailDto | ProductInfoDto | Product, categoryId: string): boolean {
    if (_.isNil(product) || _.isNil(categoryId)) {
      return false;
    }

    const productCategoryId = product.categoryId;
    if (productCategoryId.startsWith(categoryId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Tests if the given product is of any given categories (or subcategories). The comparison is suffix-based - subcategory has some suffix to
   * the parent category (e. g. if PRODC_TARIFF is parent, PRODC_TARIFF_POP is its child). This solution is approved as working.
   *
   * @param product The tested product.
   * @param categoryIds The tested category ID's.
   * @return true if the product is of any category, false otherwise.
   * @see ProductUtils.isOfCategory
   */
  static isOfAnyCategories(product: ProductDetailDto | ProductInfoDto | Product, categoryIds: string[]): boolean {
    for (const category of categoryIds) {
      if (ProductUtils.isOfCategory(product, category)) {
        return true;
      }
    }
    return false;
  }
}
