import { CustomerAccountDto, CustomerDto, OrderDto, OrderParamDto } from '@btl/order-bff';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { OrderitemAttributeDto } from '@btl/order-bff/model/orderitemAttributeDto';
import { FormGroup } from '@angular/forms';

export enum ScenarioTypeEnum {
  QUOTE_MANAGEMENT = 'QUOTE_MANAGEMENT',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  PRP_REGISTRATION = 'PRP_REGISTRATION',
  PRP_RECHARGE = 'PRP_RECHARGE',
  CHANGE_SIM = 'CHANGE_SIM',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  CHANGE_TARIFF = 'CHANGE_TARIFF',
  DEACTIVATION = 'DEACTIVATION',
}

export enum ScenarioStepTypeEnum {
  SALES_PRODUCT_LISTING = 'SALES_PRODUCT_LISTING',
  SALES_PRODUCT_DETAIL = 'SALES_PRODUCT_DETAIL',
  SALES_TECHNOLOGY_CHECK = 'SALES_TECHNOLOGY_CHECK',
  SALES_SHOPPING_CART = 'SALES_SHOPPING_CART',
  CHECKOUT_DETAILS = 'CHECKOUT_DETAILS',
  CHECKOUT_SCORING = 'CHECKOUT_SCORING',
  CHECKOUT_DELIVERY = 'CHECKOUT_DELIVERY',
  CHECKOUT_SUMMARY = 'CHECKOUT_SUMMARY',
  CHECKOUT_DOCUMENTS = 'CHECKOUT_DOCUMENTS',
  CHECKOUT_PAYMENT = 'CHECKOUT_PAYMENT',
  CHECKOUT_THANKYOU = 'CHECKOUT_THANKYOU',
}

export class OrderUtils {
  public static readonly VIEWED_PRODUCTS_ATTR = 'viewedProducts';

  public static getOrderAttributeValue(orderDto: OrderDto, attr: string): string {
    let value = null;
    orderDto.orderAttributes.forEach(attribute => {
      if (attribute.attr === attr) {
        value = attribute.value;
        return attribute.value;
      }
    });
    return value;
  }

  public static updateOrderAttr(orderAttributes: Array<OrderParamDto>, attr: string, value: any) {
    let attrFound = false;
    let newAttr: OrderParamDto;

    orderAttributes.forEach(attribute => {
      if (attribute.attr === attr) {
        attribute.value = value;
        attrFound = true;
      }
    });

    if (!attrFound) {
      newAttr = {
        attr: attr,
        value: value,
      };

      orderAttributes.push(newAttr);
    }
  }

  public static updateOrderItemAttr(orderItemAttributes: Array<OrderitemAttributeDto>, name: string, value: any) {
    let attrFound = false;
    let newAttr: OrderitemAttributeDto;

    orderItemAttributes.forEach(attribute => {
      if (attribute.name === name) {
        attribute.value = value;
        attrFound = true;
      }
    });

    if (!attrFound) {
      newAttr = {
        name: name,
        value: value,
      };

      orderItemAttributes.push(newAttr);
    }
  }

  public static getInitOrder(scenarioStepType?: ScenarioStepTypeEnum, scenarioType?: ScenarioTypeEnum): OrderDto {
    const orderDto: OrderDto = {
      orderType: 'SALES',
      channelType: 'WEB',
      scenarioType: scenarioType ? scenarioType : ScenarioTypeEnum.PRODUCT_MANAGEMENT,
      scenarioStepType: scenarioStepType ? scenarioStepType : ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
    };

    return orderDto;
  }

  public static addAttributeToOrder(order: OrderDto, attr: string, value: string) {
    if (order) {
      if (!order.orderAttributes) {
        order.orderAttributes = [];
      }
      let orderAttr =  order.orderAttributes.find(orderAttr => orderAttr.attr === attr);
      if (orderAttr) {
        orderAttr.value = value;
      } else {
        order.orderAttributes.push({
          attr: attr,
          value: value,
        });
      }
    }
  }

  public static addCustomerAttributeToOrder(order: OrderDto, customer: CustomerDto) {
    if (order && customer) {
      const orderCustomer = CustomerPartyUtil.getFormCustomer(customer);
      if (!order.orderAttributes) {
        order.orderAttributes = [];
      }
      order.orderAttributes.push({
        attr: 'customer',
        value: JSON.stringify(orderCustomer),
      });
    }
  }

  public static addCustomerAccountAttributeToOrder(order: OrderDto, customerAccount: CustomerAccountDto) {
    if (order && customerAccount) {
      const orderCustomerAccount = CustomerPartyUtil.getFormCustomerAccount(customerAccount);
      if (!order.orderAttributes) {
        order.orderAttributes = [];
      }
      order.orderAttributes.push({
        attr: 'customerAccount',
        value: JSON.stringify(orderCustomerAccount),
      });
    }
  }
  public static getOrderStickerType(order) {
    const orderState = order.orderCalcStateType;
    if (orderState == 'CREATED') {
      return 'warning';
    }
    if (orderState == 'PROCESSING_FAILED' || orderState == 'PROCESSING_ERROR') {
      return 'danger';
    }
    if (orderState == 'PENDING' || orderState == 'PAID' || orderState == 'CONFIRMED') {
      return 'inform';
    }
    if (orderState == 'FINISHED') {
      return 'success';
    }
    if (orderState == 'CANCELED' || orderState == 'BLOCKED') {
      return 'neutral';
    }
    if (orderState == 'WAITING_FOR_PAYMENT') {
      return 'important';
    }
  }

  public static formOrderAttributesChanged(
    form: FormGroup,
    oldAttrs: Array<OrderParamDto>,
    newAttrs: Array<OrderParamDto>,
  ): boolean {
    const oldFormAttributes = oldAttrs.filter(filterAttr => form.get(filterAttr.attr));
    const newFormAttributes = newAttrs.filter(filterAttr => form.get(filterAttr.attr));

    if (newFormAttributes.length === oldFormAttributes.length) {
      newFormAttributes.forEach(newAttr => {
        if (form.get(newAttr.attr)) {
          const oldAttr = oldFormAttributes.find(findAttr => findAttr.attr === newAttr.attr);
          if (!oldAttr || newAttr.value != oldAttr.value) {
            return true;
          }
        }
      });
    } else {
      return true;
    }
    return false;
  }
}
