<form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
  <div class="-filter-fields -filter-fields-group">
    <input
      id="002_customers_filter"
      type="text"
      formControlName="id"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.customers.customerNo.placeholder' | translate }}" />

    <app-advanced-filter
      clearHistoryTextLocalizedKey="wc.ecare.customersFilter.clearFilters"
      [form]="filterForm"
      [isAdvancedFilterOpen]="isAdvancedFilterOpen"
      [changedFilterFieldsCount]="changedFilterFieldsCount"
      (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
      (resetFieldsEmitter)="resetFilterFields()">
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.customers.table.firstName.header"
        controlName="firstName"
        placeholderLocalizedKey="wc.ecare.customers.table.firstName.header"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.customers.table.lastName.header"
        controlName="lastName"
        placeholderLocalizedKey="wc.ecare.customers.table.lastName.header"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.customers.table.identityNumber.header"
        controlName="identityNumber"
        placeholderLocalizedKey="wc.ecare.customers.table.identityNumber.header"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.customers.table.compRegNo.header"
        controlName="registrationNumber"
        placeholderLocalizedKey="wc.ecare.customers.table.compRegNo.header"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.customers.table.extNo.header"
        controlName="extId"
        placeholderLocalizedKey="wc.ecare.customers.table.extNo.header"
        fullWidth="true"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
    </app-advanced-filter>
  </div>

  <app-button
    variant="primary"
    id="004_opportunity_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()">
  </app-button>
</form>
