import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { AccountDto, PagedAccountsDto } from '@btl/order-bff';
import {
  AbstractPageComponent,
  AccountService,
  BlockTemplateComponent,
  EnableDynamicLoading,
  Search,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ecare-users',
  templateUrl: './ecare-users.component.html',
})
@EnableDynamicLoading({ customName: EcareUsersComponent.PAGE_ID })
export class EcareUsersComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUsersComponent';

  pageId(): string {
    return EcareUsersComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() pageSize: number = 20;
  @Input() includeExternal = false;
  @Output() readonly selectedUser = new EventEmitter<AccountDto>();

  users: AccountDto[] = [];
  pagedDto: PagedAccountsDto;
  canDisplayNoUsersText = false;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    private renderer: Renderer2,
    private readonly accountService: AccountService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    this.search.paging.pageSize = this.pageSize;
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadUsers();
  }

  private loadUsers() {
    this.blockUIElement.start();
    this.accountService
      .filterAccounts(this.search)
      .pipe(
        finalize(() => {
          this.canDisplayNoUsersText = _.isEmpty(this.users);
          this.blockUIElement.stop();
        })
      )
      .subscribe(pagedUsers => {
        this.pagedDto = pagedUsers;
        this.users = pagedUsers.data;
        this.blockUIElement.stop();
      });
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadUsers();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadUsers();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadUsers();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadUsers();
  }

  userSelected(user: AccountDto): void {
    this.selectedUser.emit(user);
  }
}
