import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AppBlockerService,
  AuthFactoryService,
  BlockTemplateComponent,
  Search,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { PagedTicketsDto, TicketDto } from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { from } from 'rxjs/internal/observable/from';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';

@Component({
  selector: 'app-last-tickets-widget, [app-last-tickets-widget]',
  templateUrl: './last-tickets-widget.component.html',
})
export class LastTicketsWidgetComponent implements OnInit, OnDestroy {

  tickets: TicketDto[] = [];

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  orderDto;
  search: Search = {
    filtering: [],
    sorting: [{ column: 'startFrom', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  private onDestroy$: Subject<void> = new Subject<void>();
  protected readonly String = String;


  constructor(private readonly ticketingService: TicketingService,
              private customerLocalStorageService: CustomerLocalStorageService,
              private readonly appBlockerService: AppBlockerService,
              private authFactoryService: AuthFactoryService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      from(this.authFactoryService.getAuthService().getUserInfo()).subscribe(userProfile => {
        if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
          if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
            this.search.filtering.push({
              column: 'references',
              compareType: null,
              value: [
                {
                  entityType: 'com.emeldi.ecc.be.crm.dto.CustomerAccount',
                  entityId: this.customerLocalStorageService.getCurrentCustomerAccount().id,
                },
              ],
            });
          } else if (this.customerLocalStorageService.getCurrentCustomer()) {
            this.search.filtering.push({
              column: 'references',
              compareType: null,
              value: [
                {
                  entityType: 'com.emeldi.ecc.be.crm.dto.Customer',
                  entityId: this.customerLocalStorageService.getCurrentCustomer().id,
                },
              ],
            });
          }
        }
        this.ticketingService
          .getTicketsByFilter(this.search)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((result: PagedTicketsDto) => {
            if (result) {
              this.tickets = result.data;
            }
          });
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
