<div *ngIf="!ticket.notes || !ticket.notes.length" [ngTemplateOutlet]="noDataMessage"></div>
<ng-container *ngFor="let note of ticket.notes">
  <form class="generated-form">
    <div class="generated-input-container col12">
      <label>
        {{ 'wc.ecare.ticket.description' | translate }}
      </label>
      <div class="input full">
        <textarea
          #noteEdit
          [value]="note.note"
          [disabled]="editedNote !== note"
          [ngClass]="{ 'read-only-text': editedNote !== note }"
          (input)="checkNotEmptyNote($event.target.value)">
        </textarea>
      </div>
    </div>
  </form>
  <div class="note-info">
    <div *ngIf="note.modified" class="info-time">
      <label *ngIf="editedNote !== note && showTicketType" app-acl-internal-evaluate-component>{{
        note.noteType
        }}</label>
      {{ note.modifiedBy }}
      <img height="auto" width="auto" [src]="'/assets/svg/note-timestamp.svg'" />
      {{ note.modified | date : 'dd.MM.yyyy HH:mm' }}
    </div>
    <div *ngIf="editedNote !== note" class="buttons column-gap-10">
      <app-button
        variant="secondary"
        size="medium"
        iconPath="/assets/svg/delete.svg"
        label="{{ 'wc.ecare.contactHistory.deleteNote' | translate }}"
        (click)="deleteNote(note)">
      </app-button>
      <app-button
        variant="primary"
        size="medium"
        iconPath="/assets/svg/edit.svg"
        label="{{ 'wc.ecare.contactHistory.editNote' | translate }}"
        (click)="editNote(note)">
      </app-button>
    </div>

    <div
      app-acl-internal-evaluate-component
      class="generated-input-container col12"
      [hidden]="editedNote !== note || !showTicketType">
      <label for="ticket-edit-noteType">
        {{ 'wc.ecare.ticket.note.noteType' | translate }}
      </label>
      <select #noteType class="full" id="ticket-edit-noteType">
        <option *ngFor="let noteType of ticketNoteTypes" [value]="noteType">
          {{ noteType }}
        </option>
      </select>
    </div>

    <div *ngIf="editedNote === note" class="buttons column-gap-10">
      <app-button
        variant="secondary"
        label="{{ 'wc.common.cancel.button' | translate }}"
        (click)="cancelEditNote(note, noteEdit, noteType)">
      </app-button>
      <app-button
        variant="primary"
        iconPath="/assets/svg/check.svg"
        label="{{ 'wc.common.save.button' | translate }}"
        (click)="confirmNote(note, noteEdit.value, noteType?.value)">
      </app-button>
    </div>
  </div>

  <div *ngIf="editedNote === note && noteEmptyError" class="alert alert-danger">
    {{ 'wc.ecare.contactHistory.noteEmptyError' | translate }}
  </div>
</ng-container>
<div class="buttons column-gap-10">
  <app-button
    *ngIf="!editedNote"
    variant="secondary"
    iconPath="/assets/svg/add.svg"
    label="{{ 'wc.ecare.contactHistory.addNote' | translate }}"
    (click)="addNote()">
  </app-button>
</div>


<ng-template #noDataMessage>
  <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
</ng-template>
