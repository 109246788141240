import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { SubjectTypeEnum } from '../../../models/subjectTypeEnum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { SegmentEnum } from '../../../models/segmentEnum';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  CodebookService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  MetaDescriptionService,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@service/customer.service';
import { AddressDto, CodebookMapDto, CustomerAccountDto, CustomerDto, MetaEntityAttributeDto } from '@btl/order-bff';
import { ContactFormComponent } from '../../page/contact-form/contact-form.component';
import { AddressFormComponent } from '../../page/address-form/address-form.component';
import { CustomerFormComponent } from '../../page/customer-form/customer-form.component';
import { FormAgreementsComponent } from '../../form-agreements/form-agreements.component';
import { CustomerContactsComponent } from '../customer-contacts/customer-contacts.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AttributeTypeEnum } from '../../entity-generated-form/entity-generated-form.component';
import {
  CustomerAccountFormComponent
} from 'app/components/page/customer-account-form/customer-account-form.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ecare-settings-component',
  templateUrl: './ecare-settings.component.html',
})
@EnableDynamicLoading({ customName: EcareSettingsComponent.PAGE_ID })
export class EcareSettingsComponent extends AbstractPageComponent implements OnInit, AfterViewInit {
  public static readonly PAGE_ID = 'EcareSettingsComponent';

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  pageId(): string {
    return EcareSettingsComponent.PAGE_ID;
  }

  @ViewChild('customerFormComponent', { static: false }) customerFormComponent: CustomerFormComponent;

  @ViewChild('customerAccountFormComponent', { static: false })
  customerAccountFormComponent: CustomerAccountFormComponent;

  @ViewChild(FormAgreementsComponent)
  formAgreementsComponent: FormAgreementsComponent;

  @ViewChild(CustomerContactsComponent)
  customerContactsComponent: CustomerContactsComponent;

  showCustomerContacts: boolean = false;

  @Input() createCustomer = false;
  @Input() showBreadcrumbs: boolean = true;

  @Output() readonly backEvent = new EventEmitter<boolean>();

  customer: CustomerDto;
  patchCustomer: CustomerDto;

  customerAccount: CustomerAccountDto;
  patchCustomerAccount: CustomerAccountDto;

  formGenerated = false;
  isBusinessView: boolean;
  editMode = false;

  maxDate;

  customerForm: FormGroup;
  customerAccountForm: FormGroup;
  globalHomeCountry: string;
  customerHealths = [];

  countryFromCodebook = false;
  cityFromCodebook = false;
  customerAccountAttributes: MetaEntityAttributeDto[];
  subjectAttributesMap = new Map<string, MetaEntityAttributeDto>();
  currentLocale: string;

  constructor(
    private renderer: Renderer2,
    private customerLocalStorageService: CustomerLocalStorageService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private customerService: CustomerService,
    public codebookService: CodebookService,
    private stickyMessageService: StickyMessageService,
    private appBlockerService: AppBlockerService,
    private metaDescriptionService: MetaDescriptionService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super(router, route);
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    });

    this.renderer.addClass(document.body, 'ecare');
    this.addForms();
    const today = new Date();
    this.maxDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };

    this.metaDescriptionService
      .getEntityMetaDescriptions('crm', 'Address', {
        resourceIdentification: { addressType: AddressDto.TypeDtoEnum.REGISTRATION },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(metadata => {
        const countryMeta = metadata.attributes.find(metaAttr => metaAttr.name === 'country');
        const cityMeta = metadata.attributes.find(metaAttr => metaAttr.name === 'city');

        this.countryFromCodebook = countryMeta && countryMeta.type === AttributeTypeEnum.CODEBOOK;
        this.cityFromCodebook = cityMeta && cityMeta.type === AttributeTypeEnum.CODEBOOK;
      });

    this.metaDescriptionService
      .getEntityMetaDescriptions('crm', 'CustomerAccount', {})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(metadata => {
        this.customerAccountAttributes = metadata.attributes
          .filter(attr => attr.metaParameters['guiVisible'] === 'true')
          .sort((a, b) =>
            Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
          );
      });
    this.codebookService
      .getCodebook('CUSTOMER_HEALTH')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((codebook: CodebookMapDto) => {
        this.customerHealths.splice(0, this.customerHealths.length);
        for (const value in codebook) {
          const codebookItem = {
            label: codebook[value],
            value: value,
          };
          this.customerHealths.push(codebookItem);
        }
      });
  }

  getAttributeLabel(attribute: MetaEntityAttributeDto): string {
    const ret = attribute.localizedTexts?.find(text => text.locale === this.currentLocale)?.message;
    return ret ? ret : attribute.name;
  }

  ngOnInit() {
    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    this.customerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();
    if (this.createCustomer) {
      this.customer = CustomerPartyUtil.getDefaultCustomer();
    } else {
      this.metaDescriptionService
        .getEntityMetaDescriptions('crm', 'Subject', {
          resourceIdentification: {
            subjectType: this.customer.subject.subjectType
          }
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(metadata => {
          this.subjectAttributesMap.clear();
          metadata.attributes
            .filter(attr => attr.metaParameters['guiVisible'] === 'true')
            .sort((a, b) =>
              Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
            ).forEach(attr => {
            this.subjectAttributesMap.set(attr.name, attr);
          });
          console.log(this.subjectAttributesMap);
        });
    }
    this.isBusinessView = this.customer?.segment !== 'R';

    this.loadProperties();
  }

  addForms() {
    this.customerForm = this.fb.group({
      subject: this.fb.group({}),
      address: this.fb.group(AddressFormComponent.getBaseFormConfiguration()),
      contact: this.fb.group(ContactFormComponent.getBaseFormConfiguration()),
      id: null,
      extId: null,
      role: 'Customer',
      state: 'CREATED',
      displayName: null,
      parameters: this.fb.group({}),
    });
    this.customerForm.setValidators(this.countryCheck());

    this.customerAccountForm = this.fb.group({
      address: this.fb.group(AddressFormComponent.getBaseFormConfiguration()),
      allowInvoicingContact: false,
      invoicingContact: this.fb.group(ContactFormComponent.getBaseFormConfiguration()),
      allowBillingContact: false,
      billingContact: this.fb.group(ContactFormComponent.getBaseFormConfiguration()),
      id: null,
      displayName: null,
      extId: null,
      parentId: null,
      role: 'CustomerAccount',
      state: 'CREATED',
    });
  }

  customerFormGenerated() {
    if (!this.formGenerated) {
      this.patchCustomer = this.customer;
      this.initParams();
      this.customerFormComponent.customerForm.patchValue(this.patchCustomer);
      this.patchCustomer = null;
      this.formGenerated = true;
    }
  }

  customerAccountFormGenerated() {
    if (!this.formGenerated) {
      this.patchCustomerAccount = this.customerAccount;
      this.initParams();
      this.customerAccountFormComponent.customerAccountForm.patchValue(this.patchCustomerAccount);
      this.customerAccountFormComponent.initContactsAndAddreses(this.patchCustomerAccount, this.customer);
      this.patchCustomerAccount = null;
      this.formGenerated = true;
    }
  }

  initParams() {
    if (this.patchCustomer) {
      if (!this.patchCustomer.parameters) {
        this.patchCustomer.parameters = {};
      }
      if (!this.patchCustomer.subject) {
        this.patchCustomer.subject = {};
      }
      if (!this.patchCustomer.subject?.parameters) {
        this.patchCustomer.subject.parameters = {};
      }
    }
  }

  getHealthScoreFormGroup() {
    return (this.customerForm.get('parameters') as FormGroup).get('healthScore');
  }

  edit() {
    if (this.editMode) {
      this.formGenerated = false;
    }
    this.editMode = !this.editMode;
  }

  saveCustomer(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      FormUtils.validateAllFormFields(this.customerForm);
      if (this.customerForm.valid) {
        const customer = this.customerForm.getRawValue();
        if (!customer.id) {
          this.appBlockerService.block();
          this.customerService
            .createCustomer(customer)
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              this.customer = result;
              this.stickyMessageService.addStickySuccessMessage('wc.ecare.createCustomer.customerCreated');
              this.customerLocalStorageService.setCurrentCustomer(result, true);
              observer.next(true);
            });
        } else {
          const id = customer.id;
          customer.id = null;
          customer.recordVersion = this.customer.recordVersion;
          this.customerService
            .updateCustomer(id, customer)
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              this.stickyMessageService.addStickySuccessMessage('wc.ecare.createCustomer.customerUpdated');
              this.customer = result;
              this.initParams();
              this.customerForm.patchValue(this.customer);
              this.customerLocalStorageService.setCurrentCustomer(this.customer, false);
              this.formGenerated = false;
              this.editMode = false;
              observer.next(true);
            });
        }
      } else {
        setTimeout(() => {
          const errorElements = this.renderer.selectRootElement('app-input-errors div', true);
          errorElements.scrollIntoView({ behavior: 'smooth' });
        });
        observer.next(false);
      }
    });
  }

  saveCustomerAccount(): boolean {
    FormUtils.validateAllFormFields(this.customerAccountForm);
    if (this.customerAccountForm.valid) {
      const customerAccount = this.customerAccountFormComponent.getFormCustomerAccount(this.customer);

      const id = customerAccount.id;
      customerAccount.id = null;
      customerAccount.recordVersion = this.customerAccount.recordVersion;
      this.customerService
        .updateCustomerAccount(id, customerAccount)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.createCustomer.customerAccountUpdated');
          this.customerAccount = result;
          this.initParams();
          this.customerAccountForm.patchValue(this.customerAccount);
          this.customerLocalStorageService.setCurrentCustomerAccount(this.customerAccount);
          this.formGenerated = false;
          this.editMode = false;
        });
      return true;
    } else {
      setTimeout(() => {
        const errorElements = this.renderer.selectRootElement('app-input-errors div', true);
        errorElements.scrollIntoView({ behavior: 'smooth' });
      });
      return false;
    }
  }

  loadProperties() {
    this.propertyAccessorLocalService
      .getGlobalHomeCountry()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(globalHomeCountry => {
        this.globalHomeCountry = globalHomeCountry;
      });
  }

  countryCheck() {
    return group => {
      const subjectType = group.get('subject')?.controls['subjectType'];
      const idAuthority = group.get('subject')?.controls['idIssueAuthority'];
      if (subjectType?.value === SubjectTypeEnum.FOREIGNER) {
        if (idAuthority?.value === this.globalHomeCountry) {
          return idAuthority.setErrors({ equivalent: true });
        }
      }
    };
  }

  isPerson() {
    return this.customerForm?.get('segment')?.value === SegmentEnum.R.toString();
  }

  isBusiness() {
    return this.customerForm?.get('segment')?.value === SegmentEnum.SMB.toString();
  }

  isCORP() {
    return (
      this.customerForm?.get('segment')?.value === SegmentEnum.CORP.toString() ||
      this.customerForm?.get('segment')?.value === SegmentEnum.GOV.toString()
    );
  }

  deactivate() {
    const customerAsMap = {
      state: 'CANCELED',
      recordVersion: this.customerAccount ? this.customerAccount.recordVersion : this.customer.recordVersion,
    };

    if (this.customerAccount) {
      this.customerService.patchCustomerAccount(this.customerAccount.id, customerAsMap).subscribe(result => {
        this.customer = result;
        this.customerForm.patchValue(this.customer);
      });
    } else {
      this.customerService.patchCustomer(this.customer.id, customerAsMap).subscribe(result => {
        this.customer = result;
        this.customerForm.patchValue(this.customer);
      });
    }
  }

  ngAfterViewInit(): void {
    this.showCustomerContacts = this.customerContactsComponent?.contacts?.length > 0;
  }
}
