import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-ecare-login',
  templateUrl: './ecare-login.component.html',
})
export class EcareLoginComponent {
  loginScreenClass: String = 'login';

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'ecare-login');
    this.renderer.addClass(document.body, 'no-partials');
  }

  public toggleLoginClass(): void {
    if (this.loginScreenClass === 'login') {
      this.loginScreenClass = 'recovery';
    } else {
      this.loginScreenClass = 'login';
    }
  }
}
