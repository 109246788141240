import { Component, Input, OnInit } from '@angular/core';
import { AssetDto } from '@btl/order-bff';

@Component({
  selector: 'app-standalone-tile',
  templateUrl: './standalone-tile.component.html',
})
export class StandaloneTileComponent implements OnInit {
  //region Data:

  /**
   * A flag specifying if the tile is collapsed or not.
   */
  isCollapsed: boolean = true;

  //region IO:

  /**
   * The standalone service to display.
   */
  @Input()
  service: AssetDto;

  //region Life-cycle:

  ngOnInit(): void {
    this.validateInputs();
  }

  //region Helpers:

  /**
   * Validate component inputs.
   */
  private validateInputs(): void {
    if (!this.service) {
      throw new Error('The service parameter must have non-null value.');
    }
  }
}
