import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgreementDto, CustomerDto, OrderDto, OrderParamDto, ProductDetailDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OneTimePriceComponent } from '../../page/price/one-time-price.component';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { CurrentLocaleService, GeneratedDocumentService, OrderingService } from '@btl/btl-fe-wc-common';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { OrderUtils, ScenarioStepTypeEnum } from 'app/helpers/order-utils';
import { PaymentComponent } from '../payment/payment.component';
import { NtsPaymentDetails } from '../../../models/nts-payment-details';
import { TranslateService } from '@ngx-translate/core';
import { CustomerAccountFormComponent } from '../../page/customer-account-form/customer-account-form.component';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { CategoryTypeEnum } from '../../../models/product-filter';
import _ from 'lodash';
import { ProductUtils } from '../../../helpers/product-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../../helpers/form-utils';
import { CheckoutShoppingCartComponent } from '../checkout-shopping-cart/checkout-shopping-cart.component';
import { FormAgreementsComponent } from 'app/components/form-agreements/form-agreements.component';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
})
export class SummaryPageComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  public readonly HW_INVOICING_CONTACT = 'hwInvoicingContact';
  public readonly HW_INVOICING_ADDRESS = 'hwInvoicingAddress';
  public readonly DELIVERY_CONTACT = 'deliveryContact';
  public readonly DELIVERY_ADDRESS = 'deliveryAddress';

  ScenarioStepTypeEnum = ScenarioStepTypeEnum;

  @ViewChild('taxOcSum', { static: false }) taxOcSumComponent: OneTimePriceComponent;
  @ViewChild('ocSum', { static: false }) ocSumComponent: OneTimePriceComponent;
  @ViewChild('taxRcSum', { static: false }) taxRcSumComponent: OneTimePriceComponent;
  @ViewChild('rcSum', { static: false }) rcSumComponent: OneTimePriceComponent;
  @ViewChild('todaySum', { static: false }) todaySumComponent: OneTimePriceComponent;

  @ViewChild('customerAccountForm', { static: true }) customerAccountFormComponent: CustomerAccountFormComponent;
  @ViewChild(CheckoutShoppingCartComponent) checkoutShoppingCart: CheckoutShoppingCartComponent;
  @ViewChild('formAgreementsComponent', { static: false }) formAgreementsComponent: FormAgreementsComponent;

  paymentParams;

  document: Blob;

  //TP2-59
  onetimePaymentMethod: string;
  deliveryMethod: string;
  customer: CustomerDto;
  customerAccount;
  loading;
  showVat: boolean;

  /**
   * A flag specifying if summary is displayed because the payment failed from business reasons.
   */
  paymentFailure: boolean;

  /**
   * A flag specifying if summary is displayed because the payment failed from technical reasons.
   */
  paymentTechnicalFailure: boolean;

  paymentDetails: NtsPaymentDetails = null;

  get preCalculatedShoppingCart(): PrecalculatedShoppingCart {
    return this.shoppingCartService.preCalculatedShoppingCart;
  }

  agreementsForm: FormGroup = this.formBuilder.group({
    agreements: [],
  });

  selectedDeliveryMethod: ProductInShoppingCart;
  selectedPaymentMethod: ProductInShoppingCart;

  deliveryContact;
  deliveryAddress;

  invoiceContact;
  invoiceAddress;

  account;

  constructor(
    private orderingService: OrderingService,
    public wcOrderingService: WcOrderingService,
    public shoppingCartService: ShoppingCartService,
    private generatedDocumentService: GeneratedDocumentService,
    private currentLocaleService: CurrentLocaleService,
    public orderingWizardService: OrderingWizardService,
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder
  ) {
    this.orderingWizardService.showVat.pipe(takeUntil(this.onDestroy$)).subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });
  }

  ngOnInit(): void {
    const handleGetCurrentOrder = (orderDto: OrderDto): void => {
      // this.wcOrderingService.orderChanged.subscribe(orderDto => {
      //   this.currentOrderDto = orderDto;
      // });
      if (this.wcOrderingService.currentOrder) {
        if (this.hasOrderItems()) {
          this.updateSummaryInfo();
          this.orderingService
            .getOrderAgreements(this.wcOrderingService.currentOrder.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              const agreements: Array<AgreementDto> = JSON.parse(
                OrderUtils.getOrderAttributeValue(orderDto, this.AGREEMENTS_ORDER_ATTR)
              );
              if (agreements) {
                agreements.forEach(agreement => {
                  const resultAgreement = result.find(findAgreement => findAgreement.type.id === agreement.type.id);
                  if (resultAgreement) {
                    resultAgreement.agreed = agreement.agreed;
                  }
                });
              }
              this.agreementsForm.patchValue({ agreements: result });
            });
        } else {
          this.router.navigate(['/']);
        }
      } else {
        throw new Error('There is no current order!');
      }
    };

    this.showVat = true;
    this.loadProducts();
    if (
      this.activatedRoute.snapshot.queryParamMap.has(PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER) ||
      this.activatedRoute.snapshot.queryParamMap.has(PaymentComponent.PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER)
    ) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
        this.paymentParams = params;
        this.wcOrderingService
          .getOrderByRefNo(params[PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(handleGetCurrentOrder);
      });
    } else {
      this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(handleGetCurrentOrder);
    }
  }

  loadProducts() {
    const deliveryAndPaymentProducts: ProductInShoppingCart[] = [];

    if (this.shoppingCartService.preCalculatedShoppingCart.deliveryProduct) {
      this.selectedDeliveryMethod = this.shoppingCartService.preCalculatedShoppingCart.deliveryProduct;
      deliveryAndPaymentProducts.push(this.selectedDeliveryMethod);
    }

    if (this.shoppingCartService.preCalculatedShoppingCart.paymentProduct) {
      this.selectedPaymentMethod = this.shoppingCartService.preCalculatedShoppingCart.paymentProduct;
      deliveryAndPaymentProducts.push(this.selectedPaymentMethod);
    }

    for (const product of deliveryAndPaymentProducts) {
      if (product.ocPrice !== 0 || product.rcPrice > 0) {
      }
    }
  }

  generateDocument(): void {
    const handleDocumentGenerated = document => {
      this.document = document;
      window.open(URL.createObjectURL(this.document));
    };

    const locale = this.currentLocaleService.getCurrentLanguage();
    const orderId = this.wcOrderingService.currentOrder.id;

    this.generatedDocumentService
      .generateOrderDocument('DOC_ACTIVATION_1', locale, orderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(handleDocumentGenerated);
  }

  updateSummaryInfo(): void {
    this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customer'));
    this.customerAccount = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customerAccount')
    );
    this.invoiceContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.HW_INVOICING_CONTACT)
    );
    this.invoiceAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.HW_INVOICING_ADDRESS)
    );
    this.deliveryContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.DELIVERY_CONTACT)
    );
    this.deliveryAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.DELIVERY_ADDRESS)
    );
  }

  choose(event) {
    this.showVat = event !== 'noVat';
  }

  private readonly AGREEMENTS_ORDER_ATTR = 'agreements';

  continue() {
    FormUtils.validateAllFormFields(this.agreementsForm);
    if (this.agreementsForm.valid) {
      const orderParamsDto: Array<OrderParamDto> = [];
      OrderUtils.updateOrderAttr(
        orderParamsDto,
        this.AGREEMENTS_ORDER_ATTR,
        JSON.stringify(this.agreementsForm.controls.agreements.value ?? [])
      );

      const handleGetCurrentOrder = (orderDto: OrderDto) => {
        this.wcOrderingService.currentOrder = orderDto;
        this.checkoutShoppingCart.orderingWizardActions.finishWizard();
      };
      const orderAsMap = {
        orderAttributes: orderParamsDto,
        recordVersion: this.wcOrderingService.currentOrder.recordVersion,
      };
      this.wcOrderingService.addAccountId(this.wcOrderingService.currentOrder, orderAsMap);
      this.wcOrderingService
        .patchOrder(this.wcOrderingService.currentOrder.id, orderAsMap)
        .subscribe(handleGetCurrentOrder);
    }
  }

  hasOrderItems(): boolean {
    return (
      !_.isNil(this.shoppingCartService.preCalculatedShoppingCart) &&
      !_.isEmpty(this.shoppingCartService.preCalculatedShoppingCart.products)
    );
  }
}
