<div *ngIf="products && productDetails" class="tariff">
  <div class="tariff-container">
    <ng-container *ngFor="let product of products">
      <div
        class="tariff-item"
        [class.with-stickers]="product.stickers?.length"
        [class.highlight-sticker-box]="highlightBoxWithStickers">
        <div class="tariff-item-content">
          <span
            *ngFor="let sticker of getProductStickersSorted(product)"
            class="tariff-tag-sticker {{ sticker.styleClass }}">
            {{ sticker.name | selectLocaleProperty }}
          </span>

          <ng-container *ngIf="product.image?.medium?.includes('.svg'); else imgIcon">
            <i class="tariff-icon" [inlineSVG]="product.image.medium"></i>
          </ng-container>
          <ng-template #imgIcon>
            <img
              class="tariff-icon"
              alt="{{ product.name | selectLocaleProperty }}"
              [defaultImage]="'/assets/img/products/loading.gif'"
              [lazyLoad]="'/api/bff/order/v1/img/' + product.image.medium"/>
          </ng-template>

          <h4>
            <a
              *ngIf="this.productListingService.hasLinkToDetail(product); else no_detail"
              [routerLink]="['/eshop/product-detail/' + product.seoUrl]">
              {{ product.name | selectLocaleProperty }}
            </a>
            <ng-template #no_detail>{{ product.name | selectLocaleProperty }}</ng-template>
          </h4>

          <!--          <h6>{{ product.description | selectLocaleProperty }}</h6>-->
          <p
            *ngIf="getProductDetail(product)?.longDescription; else shortDescriptionFallback"
            class="tariff-description"
            [innerHTML]="getProductDetail(product).longDescription | safeHtml"></p>
          <ng-template #shortDescriptionFallback>
            <p class="tariff-description">
              {{ product.description | selectLocaleProperty }}
            </p>
          </ng-template>
        </div>

        <div class="tariff-item-bottom">
          <div *ngIf="product.prices" class="price">
            <one-time-price [price]="product.prices.priceIndex"></one-time-price>
            <span *ngIf="product.prices.priceValidity[0].priceType === 'RC'" class="price-period">/ month</span>
          </div>
          <div
            *ngIf="showBuyButton"
            app-button
            [variant]="highlightBoxWithStickers && !product.stickers?.length ? 'secondary' : 'primary'"
            label="{{
              (isTariffChange ? 'wc.ecare.dashboard.tariffChange.label' : 'wc.ecare.productListing.addToCart')
                | translate
            }}"
            iconPath="{{ isTariffChange ? '' : '/assets/svg/cart-icon.svg' }}"
            (click)="btnBuyOnClick(product)"></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="showAllTariffsButton"
    app-button
    label="{{ 'wc.ecare.productListing.showAllTariffs' | translate }}"
    [link]="'eshop/product-listing/consumer'"></div>
</div>
