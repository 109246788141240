import { ProductFilterDto, SortDto } from '@btl/order-bff';
import { Paging } from '@btl/btl-fe-wc-common';

/**
 * {@code ProductFilter} is an interface representing an object grouping product filtering attributes with sorting and
 * paging for easier manipulation.
 */
export interface ProductFilter {
  /**
   * The product filter attributes.
   */
  attributes: ProductFilterDto;

  /**
   * The sorting.
   */
  sorting: SortDto;

  /**
   * The paging.
   */
  paging: Paging;
}

export const UNLIMITED_PRODUCT_PAGE_SIZE = 200;

// TODO: This should be generated by BFF
export enum CategoryTypeEnum {
  PRODC_FREE_HW = 'PRODC_FREE_HW',
  PRD_L_HW = 'PRD_L_HW',
  PRD_L = 'PRD_L',
  PRODC_SU_HW = 'PRODC_SU_HW',
  PRODC_GE_FUOM = 'PRODC_GE_FUOM',
  PRODC_SU_TARIFF = 'PRODC_SU_TARIFF',
  PRODC_SU_TP_GSM = 'PRODC_SU_TP_GSM',
  PRODC_SU_TP_SIM_POP = 'PRODC_SU_TP_SIM_POP',
  PRODC_SU_TP_SIM_PRP = 'PRODC_SU_TP_SIM_PRP',
  PRODC_SU_CORE = 'PRODC_SU_CORE',
  PRODC_SU_CORE_GSM = 'PRODC_SU_CORE_GSM',
  PRODC_SU_VAS_CORE_FUP_REC = 'PRODC_SU_VAS_CORE_FUP_REC',
  PRODC_SU_VAS_CORE_FUOMS = 'PRODC_SU_VAS_CORE_FUOMS',
  PRODC_SU_VAS_CORE = 'PRODC_SU_VAS_CORE',
  PRODC_SU_COMMIT = 'PRODC_SU_COMMIT',
  PRODC_CA_VAS_SS = 'PRODC_CA_VAS_SS',
  PRODC_SU_HW_LEASING = 'PRODC_SU_HW_LEASING',
  PRODC_SU_OCC = 'PRODC_SU_OCC',
  PRODC_SU_OCC_LEASING = 'PRODC_SU_OCC_LEASING',
  PRODT_UT = 'PRODT_UT',

  //New categories (old above should be deleted)
  PRODC = 'PRODC',
  PRODC_GE_HW = 'PRODC_GE_HW',
  PRODC_GE_HW_PHONE_MOBILE = 'PRODC_GE_HW_PHONE_MOBILE',
  PRODC_GE_DEPOSIT = 'PRODC_GE_DEPOSIT',
  PRODC_GE_DELIVERY = 'PRODC_GE_DELIVERY',
  PRODC_GE_DELIVERY_INSTALL = 'PRODC_GE_DELIVERY_INSTALL',
  PRODC_SU_TP = 'PRODC_SU_TP',
  PRODC_SU_TP_FIX = 'PRODC_SU_TP_FIX',
  PRODC_SU_TP_FIX_CIRCUIT = 'PRODC_SU_TP_FIX_CIRCUIT',
  PRODC_GE_PAYMENT = 'PRODC_GE_PAYMENT',
  PRODB_TP_GSM_SIM = 'PRODB_TP_GSM_SIM',
  SCITYC = 'SCITYC',
  PRODC_SU_CORE_VOICE = 'PRODC_SU_CORE_VOICE',
}
