import { Component, OnInit } from '@angular/core';
import {
  AbstractPageComponent,
  AuthFactoryService,
  EnableDynamicLoading,
  MetaDescriptionService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountDto, ContactDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-ecare-user-account-contacts',
  templateUrl: './ecare-user-account-contacts.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountContactsComponent.PAGE_ID })
export class EcareUserAccountContactsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareUserAccountContactsComponent';

  pageId(): string {
    return EcareUserAccountContactsComponent.PAGE_ID;
  }

  account: AccountDto;
  deliveryContactPresent: boolean;
  hwInvoicesContactPresent: boolean;

  contactTypeEnum = ContactDto.TypeDtoEnum;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService,
    private metaDescriptionService: MetaDescriptionService,
    public ngbModal: NgbModal,
  ) {
    super(router, route);
    const deliveryContactPresentCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Contact', {
      resourceIdentification: { contactType: this.contactTypeEnum.DELIVERY },
    });
    const hwInvoicesContactPresentCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Contact', {
      resourceIdentification: { contactType: this.contactTypeEnum.HWINVOICING },
    });

    forkJoin([deliveryContactPresentCall, hwInvoicesContactPresentCall])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([deliveryContactPresent, hwInvoicesContactPresent]) => {
        this.deliveryContactPresent = deliveryContactPresent.metaParameters['guiVisible'] === 'true';
        this.hwInvoicesContactPresent = hwInvoicesContactPresent.metaParameters['guiVisible'] === 'true';
      });
  }

  ngOnInit() {
    this.authFactoryService
      .getAuthService()
      .accountChange.pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.account = result;
      });
    this.account = this.authFactoryService.getAuthService().account;
  }
}
