<button
  attr.type="button"
  [ngClass]="{
    selected: variant === 'selected',
    active: variant === 'active'
  }">
  <i *ngIf="icon !== undefined" [inlineSVG]="'/assets/svg/top-menu-icons/' + icon + '.svg'"></i>
  <ng-content select="user-initials"></ng-content>
  <ng-content select="language-flag"></ng-content>
  <span *ngIf="label !== undefined">{{ label }}</span>
  <i *ngIf="isDropdown === true" class="iconAfter" [inlineSVG]="'/assets/svg/menu-down-arrow.svg'"></i>
</button>
