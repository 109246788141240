<div
  *ngIf="
    group &&
    group.group &&
    group.group.groupParams &&
    group.group.groupParams['bestsellingEnabled'] !== 'false' &&
    products
  "
  class="most-sold">
  <h5 class="section-title">
    {{ 'wc.shopping.gui_productListing.sortingType.productFilter.mostBoughtFirst' | translate }}
  </h5>

  <div *ngFor="let product of products; let i = index" class="most-sold-product">
    <a id="{{ i }}_001_most_sold" [routerLink]="['/eshop/product-detail/' + product.seoUrl]">
      <div *ngIf="product.image" class="product-row">
        <div class="product-info">
          <div>
            <img
              *ngIf="product.image.medium"
              [src]="basePath + '/api/bff/order/v1/img/' + product.image.medium"
              [alt]="product.name | selectLocaleProperty" />
            <img
              *ngIf="!product.image.medium"
              [src]="'assets/img/products/phone_example-listing.png'"
              [alt]="product.name | selectLocaleProperty" />
          </div>
          <div>
            <div class="title">{{ product.name | selectLocaleProperty }}</div>
            <div class="description">
              <div
                #ellipsisReload
                ellipsis
                [style.max-height.px]="28"
                [ellipsis-content]="product.description | selectLocaleProperty | stripHtmlTags"></div>
            </div>
            <app-sticker [variant]="getStickerType(product.stockStatus)">
              {{ 'wc.shopping.gui_productCard.main.itemAvailability.' + product.stockStatus | translate }}</app-sticker
            >
          </div>
        </div>
        <div class="price">
          <span [innerHTML]="product.prices?.priceIndex | price"></span>
        </div>
      </div>
    </a>
  </div>
</div>
