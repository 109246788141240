import { Component } from '@angular/core';
import { CmsWidgetBannerComponent } from 'app/components/cms-templates/widgets/banner/cms-widget-banner.component';
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Scrollbar, Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-half-image-banner, [app-half-image-banner]',
  templateUrl: './half-image-banner.component.html',
})
export class HalfImageBannerComponent extends CmsWidgetBannerComponent {}
