<div style="display: flex; flex-direction: row">
  <input type="text" disabled [value]="addresses.join(', ')"/>
  <app-button
    variant="primary"
    iconPath="/assets/svg/edit.svg"
    label="{{'wc.common.edit.button' | translate }}"
    (click)="edit(editIps)">
  </app-button>
</div>

<ng-template #editIps let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.ecare.productConfiguration.multi-ip.heading' | translate }}
    </h4>
    <div class="modal-close" (click)="c('Close click')">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <form class="form" [formGroup]="form">
        <div class="generated-form" formArrayName="addresses">
          <div *ngFor="let addressControl of getFormArray().controls" class="generated-input-container">
            <label class="form__label">{{ 'wc.admin.productConfiguration.serverIp' | translate }}</label>
            <div class="container-relative">
              <input type="text" [formControl]="addressControl"/>
              <i class="cursor-pointer remove-button right-middle-item"
                 inlineSVG="/assets/svg/delete.svg"
                 (click)="remove(addressControl)">
              </i>
            </div>
            <app-input-errors [control]="addressControl"
                              [translationPrefix]="'wc.ecare.productConfiguration.ipAddress'">
            </app-input-errors>
          </div>
        </div>
      </form>
    </div>

    <app-button
      variant="primary"
      label="{{'wc.common.add.button' | translate }}"
      (click)="add()">
    </app-button>

  </div>
  <div class="modal-footer">
    <app-button
      variant="link"
      ngbAutofocus
      label="{{ 'wc.common.cancel.button' | translate }}"
      (click)="c('Close click')">
    </app-button>
    <app-button
      variant="primary"
      iconPath="/assets/svg/save.svg"
      label="{{ 'wc.ecare.productConfiguration.set.button' | translate }}"
      (click)="save(c)">
    </app-button>
  </div>
</ng-template>
