import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'form-pick-up-point-modal',
  templateUrl: './form-pick-up-point-modal.component.html',
})
export class FormPickUpPointModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  disableActions: boolean = false;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input()
  value;

  @Input()
  provider;

  form = this.formBuilder.group({
    pickUpPoint: [null, Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form.controls['pickUpPoint'].patchValue(this.value);
  }

  selectEvent(pickUpPoint) {
    this.selectHandler(pickUpPoint);
    this.dialogRef.close();
  }

  closePopup() {
    this.dialogRef.dismiss();
  }
}
