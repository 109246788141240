import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TicketDto } from "@btl/order-bff";
import { FormArray } from "@angular/forms";

@Component({
  selector: 'app-ecare-tasks-table-mobile',
  templateUrl: './ecare-tasks-table-mobile.component.html',
})
export class EcareTasksTableMobileComponent {

  @Input()
  simpleMode = false;

  @Output()
  readonly editTaskEmitter = new EventEmitter<any>();

  @Output()
  readonly deleteTaskEmitter = new EventEmitter<string>();

  @Input()
  ticketDtos: TicketDto[];

  @Input() cuRefNo;

  @Input() hideDueDate = false;
  @Input() hideState = false;

  @Input() selectFormArray: FormArray;


  edit(ticketDto: TicketDto, inNewTab: boolean = false) {
    this.editTaskEmitter.emit({ id: ticketDto.id, inNewTab: inNewTab });
  }

  delete(ticketDto: TicketDto) {
    this.deleteTaskEmitter.emit(ticketDto.id);
  }

  toggleCheckbox(index: number) {
    const checkbox = this.selectFormArray.at(index);
    checkbox.setValue(!checkbox.value);
  }
}
