<div>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
      <div class="-filter-fields">
        <input
          id="002_orders_filter"
          type="text"
          formControlName="code"
          class="filter-input-id"
          placeholder="{{ 'wc.ecare.documentsFilter.code.placeholder' | translate }}" />

        <app-advanced-filter
          clearHistoryTextLocalizedKey="wc.ecare.customersFilter.clearFilters"
          [form]="filterForm"
          [isAdvancedFilterOpen]="isAdvancedFilterOpen"
          [changedFilterFieldsCount]="changedFilterFieldsCount"
          (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
          (resetFieldsEmitter)="resetFilterFields()">
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.documentsFilter.created"
            controlName="stateDateFrom"
            startOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.documentsFilter.stateDateFrom.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            controlName="stateDateTo"
            endOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.documentsFilter.stateDateTo.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
        </app-advanced-filter>

      </div>

      <app-button
        variant="primary"
        id="004_orders_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </form>
  </div>
</div>
