import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ecare-widget-main-info, [app-ecare-widget-main-info]',
  templateUrl: './ecare-widget-main-info.component.html',
})
export class EcareWidgetMainInfoComponent {
  @Input() label1;
  @Input() label2;
  @Input() price;
  @Input() period;
  @Input() price2;
  @Input() period2;
  @Input() priceOnTop;
  @Input() periodOnTop;
  @Input() infoText;
  @Input() hasSuccessIcon;
  @Input() labelAdditionalStyle;
  @Input() priceFormat = true;
}
