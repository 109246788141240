import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { CodebookMapDto } from '@btl/order-bff';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-select-input, [app-form-select-input]',
  templateUrl: './form-select-input.component.html',
  styleUrls: ['./form-select-input.component.scss'],
})
export class FormSelectInput implements OnInit, OnChanges {
  @Input()
  selectClass: string;

  @Input()
  emptyValue = null;

  @Input()
  emptyLocalizedKey;

  @Input()
  id: string;

  allSelected = false;

  @Input()
  parentGroup: FormGroup;

  @Input()
  controlName;

  @Input()
  label: string;

  @Input()
  disableOptionsTranslate = false;

  @Input()
  suffix;

  @Input()
  labelOption: string;

  @Input()
  valueOption: string;

  @Input()
  inputType;

  @Input()
  codebookType;

  @Input()
  options = [];

  @Input()
  showEmptyOption = false;

  @Input()
  translationPrefix: string;

  @Input()
  multipleSelect: boolean = false;

  @Input()
  disabled;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly selectionChangeEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private codebookService: CodebookService, private currentLocaleService: CurrentLocaleService) {}

  ngOnInit(): void {
    if (this.inputType === 'codebook') {
      this.labelOption = 'label';
      this.valueOption = 'value';
      if (!this.codebookType) {
        throw new Error('Codebook type must be configured.');
      }
      this.loadCodebook();
      this.currentLocaleService.currentLocaleChange.subscribe(() => {
        this.loadCodebook();
      });
    }
    if (this.disabled) {
      this.parentGroup.controls[this.controlName].disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled === null || this.disabled === false) {
      this.parentGroup.controls[this.controlName].enable();
    }
  }

  /**
   * Load the codebook from BFF.
   */
  private loadCodebook(): void {
    const createSelectionItems = (codebook: CodebookMapDto) => {
      this.options.splice(0, this.options.length);
      for (const value in codebook) {
        const codebookItem = {
          label: codebook[value],
          value: value,
        };
        this.options.push(codebookItem);
      }
    };
    this.codebookService.getCodebook(this.codebookType).subscribe(createSelectionItems);
  }

  change(event) {
    this.onChange.emit(event);
  }

  selectionChange(event) {
    if (this.multipleSelect && event.find(option => option['$ngOptionLabel'] === 'Select all')) {
      event.length = 0;
      this.options.forEach(option => event.push(this.valueOption ? option[this.valueOption] : option));
    }
    this.parentGroup.get(this.controlName).patchValue(event);
    this.selectionChangeEmit.emit(event);
  }

  hasErrors() {
    if (this.parentGroup.controls[this.controlName]?.errors) {
      return true;
    }
  }

  handleSelectAll() {}
}
