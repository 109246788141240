<div class="homepage-banner-carousel">
  <swiper
    [slidesPerView]="'auto'"
    [slidesPerGroupSkip]="1"
    [grabCursor]="false"
    [scrollbar]="false"
    [allowTouchMove]="true"
    [navigation]="{
      nextEl: '.carousel-arrow-swiper-next_banner',
      prevEl: '.carousel-arrow-swiper-prev_banner'
    }"
    [pagination]="{ clickable: true }"
    [autoplay]="{
      delay: 3000
    }"
    [breakpoints]="{
      '992': {
        slidesPerView: 2
      }
    }">
    <ng-template swiperSlide *ngFor="let content of renderingContentConfigs">
      <app-cms-widgets-sequential-renderer [widgets]="content.contentVersion.widgets">
      </app-cms-widgets-sequential-renderer>
    </ng-template>
  </swiper>
  <div class="carousel-arrow carousel-arrow-swiper-next carousel-arrow-swiper-next_banner">
    <i [inlineSVG]="'assets/img/ecare/feather-chevron-down.svg'"></i>
  </div>
  <div class="carousel-arrow carousel-arrow-swiper-prev carousel-arrow-swiper-prev_banner">
    <i [inlineSVG]="'assets/img/ecare/feather-chevron-down.svg'"></i>
  </div>
</div>
