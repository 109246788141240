<app-ecare-page-layout columnTemplate="2fr 1fr" title="Dashboard">
  <div left-side>
    <div class="ecare dashboard dashboard-homepage">
      <div class="dashboard_wrapper">
        <div class="dashboard_search">
          <app-section-header
            title="{{
              (isModuleBffCrmEnabled ? 'wc.ecare.homepage.search.header' : 'wc.ecare.homepage.orderSearch.header')
                | translate
            }}">
            <app-button
              variant="whiteWithBorder"
              size="small"
              label="{{ 'wc.ecare.homepage.createCustomer' | translate }}"
              (click)="createCustomerPopup()">
            </app-button>
          </app-section-header>
          <app-ecare-search [isHomepage]="true"></app-ecare-search>
          <div *ngIf="isModuleBffCrmEnabled" class="ecare-header_container create-customer-btn-container">
            <div class="headline-buttons no_margin"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isModuleBffCrmEnabled" right-side>
    <app-ecare-tasks [simpleMode]="true" (showAllTasksEmitter)="showAllTasks($event)"> </app-ecare-tasks>
  </div>
</app-ecare-page-layout>
