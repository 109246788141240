import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-ecare-user-account-notifications',
  templateUrl: './ecare-user-account-notifications.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountNotificationsComponent.PAGE_ID })
export class EcareUserAccountNotificationsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareUserAccountNotificationsComponent';

  pageId(): string {
    return EcareUserAccountNotificationsComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }
}
