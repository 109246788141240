<div class="checkout-page">
    <div class="checkout-page_form">
      <div class="section">
        <div class="inner">
          <h2 id="001_look_for_customer">{{ 'wc.shopping.checkout.customerLookup.heading' | translate }}</h2>

          <form class="form">
            <div class="form-group">
              <label>{{ 'wc.shopping.checkout.companyNumber.label' | translate }}</label>
              <div class="input">
                <input
                  id="002_look_for_customer"
                  type="text"
                  placeholder="{{ 'wc.shopping.checkout.companyNumber.placeholder' | translate }}" />
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'wc.shopping.checkout.personalNumber.label' | translate }}</label>
              <input
                id="003_look_for_customer"
                type="text"
                placeholder="{{ 'wc.shopping.checkout.personalNumber.placeholder' | translate }}" />
            </div>
            <div class="form-group">
              <label>{{ 'wc.shopping.checkout.customerCode.label' | translate }}</label>
              <input
                id="004_look_for_customer"
                type="text"
                placeholder="{{ 'wc.shopping.checkout.customerCode.placeholder' | translate }}" />
            </div>
            <div class="form-group">
              <button id="005_look_for_customer" class="btn btn-small btn-default">
                {{ 'wc.shopping.checkout.customerLookUp.button' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="section">
        <div class="inner">
          <h2 id="006_look_for_customer">{{ 'wc.shopping.checkout.searchResults.heading' | translate }}</h2>
          <div class="search-results">
            <p class="no-found">
              {{ 'wc.common.noSearchResults.text' | translate }}
            </p>
            <a id="007_look_for_customer" routerLink="/eshop/checkout-page/create-customer" class="btn btn-success">
              {{ 'wc.shopping.checkout.newCustomer.button' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-page_cart">
      <div class="stickyCart" id="stickyCart">
        <div class="inner">
          <div class="heading">
            <h2 id="008_look_for_customer">Cart</h2>
            <div class="cart-items">
              <!-- BOF cart item -->
              <div class="cart-item">
                <div class="cart-item-image">
                  <img alt="" src="../assets/img/products/iphone_small.png" />
                </div>
                <div class="cart-item-desc">
                  <h3 id="009_look_for_customer">iPhone 7 Product red 128gb</h3>
                  <p>
                    7 Megapixel Facetime Camera with 1080p HD Video Recording, Retina Flash, f/2.2 aperture, Wide Color
                    Capture for photos and Live Photos
                  </p>
                </div>
                <div class="cart-item-price">
                  <span class="price-regular">399$</span>
                  <span class="price-monthly">+ 8,79$ monthly</span>
                </div>
              </div>
              <!-- BOF cart item -->
              <div class="cart-item">
                <div class="cart-item-image">
                  <img alt="" src="../assets/img/products/iphone_small.png" />
                </div>
                <div class="cart-item-desc">
                  <h3 id="010_look_for_customer">iPhone 7 Product red 128gb</h3>
                  <p>
                    7 Megapixel Facetime Camera with 1080p HD Video Recording, Retina Flash, f/2.2 aperture, Wide Color
                    Capture for photos and Live Photos
                  </p>
                </div>
                <div class="cart-item-price">
                  <span class="price-regular">399$</span>
                  <span class="price-monthly">+ 8,79$ monthly</span>
                </div>
              </div>
              <!-- BOF cart item -->
              <div class="cart-item">
                <div class="cart-item-image">
                  <img alt="" src="../assets/img/products/iphone_small.png" />
                </div>
                <div class="cart-item-desc">
                  <h3 id="011_look_for_customer">iPhone 7 Product red 128gb</h3>
                  <p>
                    7 Megapixel Facetime Camera with 1080p HD Video Recording, Retina Flash, f/2.2 aperture, Wide Color
                    Capture for photos and Live Photos
                  </p>
                </div>
                <div class="cart-item-price">
                  <span class="price-regular">399$</span>
                  <span class="price-monthly">+ 8,79$ monthly</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
