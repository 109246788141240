import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProductListingService } from '@service/product-listing.service';
import { CurrentLocaleService, GroupTreeNode, ProductService } from '@btl/btl-fe-wc-common';
import { ProductDetailDto } from '@btl/order-bff';
import { forkJoin } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { Product, Sticker } from '../../../../models/product';

@Component({
  selector: 'app-tariff-listing, [app-tariff-listing]',
  templateUrl: './tariff-listing.component.html',
})
export class TariffListingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() products: Product[];

  @Input() showAllTariffsButton: boolean;

  @Input() highlightBoxWithStickers: boolean;

  @Input() showBuyButton = true;

  @Input() checkScanRequired: boolean;

  @Input() isTariffChange: boolean;

  @Input()
  currentProductGroup: GroupTreeNode;

  /**
   * The ID of the party of the original product. This is only relevant for tariff change.
   */
  @Input()
  originalProductPartyId: string;

  /**
   * The original product. This is only relevant for tariff change.
   */
  @Input()
  originalProduct: ProductDetailDto;

  @Output()
  readonly btnBuyClick: EventEmitter<{ product: Product; checkScanRequired: boolean }> = new EventEmitter<{
    product: Product;
    checkScanRequired: boolean;
  }>();

  private onDestroy$: Subject<void> = new Subject<void>();

  public productDetails: ProductDetailDto[];

  constructor(
    public productListingService: ProductListingService,
    private currentLocaleService: CurrentLocaleService,
    private productService: ProductService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['products']) {
      this.getProductDetails();
    }
  }

  ngOnInit(): void {
    this.getProductDetails();

    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.getProductDetails();
    });
  }

  getProductDetails() {
    const getProductDetailsByProductCodes = this.products
      ?.filter(product => product.id)
      .map(product => this.productService.getProductByProductCode(product.id));
    forkJoin(getProductDetailsByProductCodes)
      .pipe(takeUntil(this.onDestroy$))
      // todo: come up with better solution, how to get products bz IDs
      .subscribe(productDetails => (this.productDetails = productDetails));
  }

  getProductStickersSorted(product: Product): Sticker[] {
    return product.stickers?.sort((a, b) => a.priority - b.priority) ?? [];
  }

  checkIfScanRequired(product: Product, productGroup: GroupTreeNode) {
    // identify from the product group recursively
    if (productGroup) {
      let isRequired = false;
      if (productGroup.group.groupParams['checkScanRequired'] === 'true') {
        product.groups.forEach(groups => {
          if (groups.id === productGroup.group.id) {
            isRequired = true;
          }
        });
      }
      if (!isRequired && productGroup.children) {
        for (const child of productGroup.children) {
          isRequired = isRequired || this.checkIfScanRequired(product, child);
        }
      }
      return (this.checkScanRequired = isRequired);
    }
    return (this.checkScanRequired = false);
  }

  btnBuyOnClick(product: Product) {
    this.checkIfScanRequired(product, this.currentProductGroup);

    this.btnBuyClick.emit({ product, checkScanRequired: this.checkScanRequired });
  }

  getProductDetail(product: Product): ProductDetailDto {
    const find = this.productDetails?.find(productDetail => productDetail.productCode === product.id);
    return find;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
