import { Component } from '@angular/core';
import { CmsWidgetIconsListBaseComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-cms-widget-icons-list]',
  templateUrl: './cms-widget-icons-list.component.html',
  styleUrls: ['./cms-widget-icons-list.component.scss'],
})
@EnableDynamicLoading({ customName: 'ICON_LIST' })
export class CmsWidgetIconsListComponent extends CmsWidgetIconsListBaseComponent {}
