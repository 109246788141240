import { Component, Input, OnInit } from '@angular/core';
import { FailureDto, ProductDetailDto } from '@btl/order-bff';
import { ShoppingCartService } from '@service/shopping-cart.service';

@Component({
  selector: 'reservation-error-modal',
  templateUrl: './reservation-error-modal.component.html',
})
export class ReservationErrorModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  failure: FailureDto;

  closePopup() {
    this.dialogRef.dismiss();
  }

  errors: string[] = [];

  products: ProductDetailDto[] = [];

  constructor(private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit(): void {
    let errors = this.failure?.contextData['errors']?.split('|');
    let productVerCodes = this.failure?.contextData['productVersionCodes']?.split('|');

    this.shoppingCartService.preCalculatedShoppingCart.products.forEach((value, key) => {
      let index = productVerCodes.indexOf(key);
      if (index > -1 && value.length > 0) {
        this.products.push(value[0].productDetail);
        this.errors.push(errors[index]);
      }
    });
  }
}
