<div #paramBlock class="parameter-item" [style.min-height.px]="maxHeight" (resized)="countHeights()">
  <ng-container *ngIf="parameter.value; else paramEmpty">
    <div class="parameter-label">{{ parameter.label }}</div>
    <div class="parameter-value">
      <ng-container *ngIf="parameter.type === 'BOOLEAN'; else genericParam">
        <i *ngIf="parameter.value === 'true'" class="before-icon check" [inlineSVG]="'assets/svg/check-FA.svg'"></i>
        <i *ngIf="parameter.value === 'false'" class="before-icon remove" [inlineSVG]="'assets/svg/close-FA.svg'"></i>
      </ng-container>
      <ng-template #genericParam>
        {{ parameter.value }}
      </ng-template>
    </div>
  </ng-container>
  <ng-template #paramEmpty>
    <div class="paramEmpty">{{ 'wc.shopping.gui_productComparison.infoNotAvailable' | translate }}</div>
  </ng-template>
</div>
