import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BlockTemplateComponent, FormUtils, ProductService } from '@btl/btl-fe-wc-common';
import { OrderDto, OrderItemDto, PriceDto, ProductDetailDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScoringReason } from '../../wizard/checkout-scoring/checkout-scoring.component';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { OrderUtils } from '../../../helpers/order-utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScoringResponse } from '@service/scoring.service';

@Component({
  selector: 'app-checkout-deposit-item',
  templateUrl: './checkout-deposit-item.component.html',
  styleUrls: ['./checkout-deposit-item.component.scss'],
})
export class CheckoutDepositItemComponent implements OnInit, OnChanges {
  blockTemplate = BlockTemplateComponent;
  product: ProductDetailDto;
  orderItem: OrderItemDto;

  @Input()
  order: OrderDto;

  @Input()
  scoringReason: ScoringReason;

  @Input()
  manualDepositProduct: ProductDetailDto;

  @Input()
  resultFlag: string;

  @Output()
  readonly depositChangedEmitter = new EventEmitter<OrderDto>();

  @Output()
  readonly manualDepositChangedEmitter = new EventEmitter<ProductDetailDto>();

  @Output()
  readonly manualDepositRemovedEmitter = new EventEmitter<ProductDetailDto>();

  form: FormGroup = this.formBuilder.group({
    value: [null],
    note: [null],
  });

  private onDestroy$: Subject<void> = new Subject<void>();

  scoringDeposit = false;

  get precalculatedShoppingCart(): PrecalculatedShoppingCart {
    return this.shoppingCartService.preCalculatedShoppingCart;
  }

  constructor(
    private productService: ProductService,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private formBuilder: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDepositProduct();
  }

  ngOnInit() {
    this.loadDepositProduct();
  }

  loadDepositProduct() {
    if (this.scoringReason && this.scoringReason.requiredProductCode) {
      this.productService.getProductByProductCode(this.scoringReason.requiredProductCode).subscribe(product => {
        this.product = product;
        this.product.prices['OC'].price = this.scoringReason.requiredOC;
        const scoringResult = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'scoringResult'));
        this.scoringDeposit =
          scoringResult.resultReasons.find(reason => reason.requiredProductCode === this.product.productCode) != null;
      });
    } else if (this.manualDepositProduct) {
      this.product = this.manualDepositProduct;
    }

    this.fillFormByOrderItem();
  }

  fillFormByOrderItem() {
    this.orderItem = this.order.orderItems.find(orderItem => orderItem.productId === this.product?.id);
    if (this.orderItem) {
      const priceOC = this.orderItem.prices.find(price => price.priceId === this.product.prices['OC'].id);
      if (priceOC) {
        this.form.get('value').patchValue(priceOC.overridingPrice);
        this.form.get('note').patchValue(priceOC.overridingPriceReason);
      }
    }
  }

  remove(): void {
    this.form.get('note').setValidators(Validators.required);
    this.form.get('note').updateValueAndValidity();
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const productInShoppingCart = this.precalculatedShoppingCart.products
        .get(this.product.id)
        .find(productInShoppingCart => productInShoppingCart.orderItems[0].productId === this.product.id);
      const removeOrderItems = (currentOrder: OrderDto) => {
        if (this.scoringDeposit) {
          this.form.controls.value.patchValue(0);
          this.changeDeposit();
        } else {
          this.orderingService
            .removeProductInShoppingCartFromCart(currentOrder, productInShoppingCart)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(order => {
              if (this.manualDepositProduct) {
                this.manualDepositRemovedEmitter.emit(this.manualDepositProduct);
              } else {
                this.depositChangedEmitter.emit(order);
              }
            });
        }
      };
      this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItems);
    }
  }

  changeDeposit() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const newValue: number = parseFloat(this.form.getRawValue().value);
      const note = this.form.getRawValue().note;
      this.loadDepositProduct();
      const changeDeposit = (currentOrder: OrderDto) => {
        const price: PriceDto = this.product.prices['OC'];
        let priceOverrideDto = this.orderItem.prices.find(or => or.priceId === price.id);
        if (priceOverrideDto) {
          priceOverrideDto.overridingPrice = newValue;
          priceOverrideDto.overridingPriceReason = note;
        } else {
          priceOverrideDto = {
            priceId: price.id,
            overridingPrice: newValue,
            overridingPriceReason: note,
          };
          this.orderItem.prices.push(priceOverrideDto);
        }
        this.orderingService
          .updateOrderItems(currentOrder, [this.orderItem])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {
            this.order = order;
            if (this.manualDepositProduct) {
              this.fillFormByOrderItem();
              this.manualDepositChangedEmitter.emit(this.manualDepositProduct);
            } else {
              this.depositChangedEmitter.emit(order);
            }
          });
      };
      this.orderingService.getCurrentOrder().subscribe(changeDeposit);
    }
  }

  valueChanged() {
    if (this.form.getRawValue().value) {
      this.form.get('note').setValidators(Validators.required);
    } else {
      this.form.get('note').setValidators(null);
    }
    this.form.get('note').updateValueAndValidity();
  }
}
