<div class="thumbnail-container" *ngIf="thumbnailsForRow">
  <ng-container *ngFor="let thumbnail of thumbnailsForRow">
    <div *ngIf="!thumbnail.omit" class="order-item-wrapper" placement="{{tooltipPosition}}" ngbTooltip="{{ thumbnail.name }}">
      <img
        *ngIf="thumbnail.thumbnail;
             else defaultImg"
        class="order-item"
        [defaultImage]="'/assets/img/products/loading.gif'"
        [lazyLoad]="thumbnail.thumbnail" />
      <ng-template #defaultImg>
        <i [inlineSVG]="'/assets/svg/product-placeholder.svg'"></i>
      </ng-template>
      <span *ngIf="thumbnail.count > 1" class="order-item-count">{{ thumbnail.count }}</span>
    </div>
  </ng-container>
  <div *ngIf="unshowedThumbnails" class="unshowed-thumbnails" ngbTooltip="{{ unshowedThumbnails.names }}">
    +{{ unshowedThumbnails.count }}
  </div>
</div>
