<div class="form ecare-filter" [formGroup]="filterForm">
  <div class="-filter-fields">
    <input
      id="002_events_filter"
      type="text"
      formControlName="id"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.eventFilter.id.placeholder' | translate }}" />

    <app-advanced-filter
      clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
      [form]="filterForm"
      [isAdvancedFilterOpen]="isAdvancedFilterOpen"
      [changedFilterFieldsCount]="changedFilterFieldsCount"
      (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
      (resetFieldsEmitter)="resetFilterFields()">
      <app-advanced-filter-field
        fullWidth="true"
        labelLocalizedKey="wc.ecare.eventFilter.extId"
        controlName="extId"
        idAttribute="008_events_filter"
        placeholderLocalizedKey="wc.ecare.eventFilter.extId.placeholder"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        labelLocalizedKey="wc.ecare.eventFilter.stateDatePeriod"
        controlName="stateDateFrom"
        startOfTheDay="true"
        placeholderLocalizedKey="wc.ecare.eventFilter.stateDatePeriod.placeholder.startDate"
        [fieldType]="'date'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        controlName="stateDateTo"
        showTime="false"
        endOfTheDay="true"
        placeholderLocalizedKey="wc.ecare.eventFilter.stateDatePeriod.placeholder.endDate"
        [fieldType]="'date'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        *ngIf="!presentInFilter('refs') && entityTypes"
        labelLocalizedKey="wc.ecare.eventFilter.entityType"
        fieldType="select"
        fullWidth="true">
        <app-form-select-input
          selectClass="ecare-dropdown"
          controlName="entityType"
          id="009_events_filter"
          translationPrefix="wc.ecare.event.entityTypes"
          [parentGroup]="filterForm"
          [options]="entityTypes"
          [disableOptionsTranslate]="true"
          [labelOption]="'name'"
          [valueOption]="'name'">
        </app-form-select-input>
      </app-advanced-filter-field>
      <app-advanced-filter-field
        *ngIf="!presentInFilter('refs')"
        fullWidth="true"
        labelLocalizedKey="wc.ecare.eventFilter.entityId"
        controlName="entityId"
        idAttribute="0010_events_filter"
        placeholderLocalizedKey="wc.ecare.eventFilter.entityId.placeholder"
        [fieldType]="'input'"
        (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
      </app-advanced-filter-field>
      <app-advanced-filter-field
        *ngIf="eventTypes"
        labelLocalizedKey="wc.ecare.eventFilter.eventType"
        fieldType="select"
        fullWidth="true">
        <app-form-select-input
          selectClass="ecare-dropdown"
          controlName="eventType"
          id="012_events_filter"
          translationPrefix="wc.ecare.event.eventType"
          [parentGroup]="filterForm"
          [options]="eventTypes.data"
          [disableOptionsTranslate]="true"
          [labelOption]="'localizedName'"
          [valueOption]="'code'"
          [multipleSelect]="true">
        </app-form-select-input>
      </app-advanced-filter-field>
    </app-advanced-filter>
  </div>

  <app-button
    variant="primary"
    id="004_events_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()">
  </app-button>
</div>
