import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AppBlockerService,
  AuthFactoryService,
  AuthService,
  BlockTemplateComponent,
  CurrentLocaleService,
  DmsService,
  EnableDynamicLoading,
  EnumDataTypeService,
  FormUtils,
  StickyMessageService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ContactDto,
  DataTypeEnumDto,
  DynamicEnumService,
  OrderDto,
  TicketDto,
  TicketTypeDto,
  TicketTypeParamDto
} from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Attachment } from '../../../form-attachments/form-attachments.component';
import { forkJoin } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { environment } from '../../../../../environments/environment';
import { TicketService } from '@service/ticket.service';
import { DataTypeEnumItemDto } from '@btl/order-bff/model/dataTypeEnumItemDto';
import moment_ from 'moment/moment';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
})
@EnableDynamicLoading({ customName: TicketEditComponent.PAGE_ID })
export class TicketEditComponent extends AbstractPageComponent implements OnDestroy {
  moment = moment_;
  public static readonly PAGE_ID = 'TicketEditComponent';

  pageId(): string {
    return TicketEditComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketId = this.params.id;
      const isNewOpportunity = !ticketId || ticketId === 'newTicket';

      this.authService = this.authFactoryService.getAuthService();

      if (isNewOpportunity) {
        this.newTicket = true;
        this.editMode = true;

        if (this.ticketTypeCode) {
          this.getSkeletonByType(this.ticketTypeCode);
        } else if (this.duplicationId) {
          this.ticketService
            .getTicketForDuplication(this.duplicationId, this.forDuplication)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(ticket => this.reloadTicket(ticket, true));
        }
      } else {
        this.loadTicket(ticketId);
      }
    } else {
      this.ticket = undefined;
    }
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  static readonly SOURCE_NAME = 'ticket';
  readonly TICKET_REF_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketRefType';
  static readonly TICKET_NOTE_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketNoteType';
  static readonly TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.ExternalEntityType';

  form = this.formBuilder.group({
    subject: [null, Validators.required],
    solution: [null],
    description: [null, Validators.required],
    businessState: [null, Validators.required],
    assignmentState: [null, Validators.required],
    assignmentStateFrom: [{ value: null, disabled: true }],
    assignedTo: [null],
    owner: [null],
    priority: [null, Validators.required],
    extId: [null],
    created: [{ value: null, disabled: true }],
    followUpDate: [null],
    slaDueDate: [null],
    startFrom: [null],
    attachments: [],
    parameters: this.formBuilder.array([]),
    references: this.formBuilder.array([]),
    steps: this.formBuilder.array([])
  });

  @Input() ticket: TicketDto;
  @Input() ticketTypeCode;
  @Input() relatedTicketId;
  @Input() cuRefNo: string;
  @Input() caRefNo: string;
  @Input() cancelCallback: () => void;
  @Input() saveCallback: (ticketDto: TicketDto) => void;

  ticketTypes: TicketTypeDto[];
  ticketParamConfigByName= new Map<string, TicketTypeParamDto>();
  dataTypeEnums: { [name: string]: DataTypeEnumDto };
  contacts: ContactDto[] = [];
  relatedTasks: Array<TicketDto> = [];
  relatedOrders: Array<OrderDto> = [];
  relatedQuotes: Array<OrderDto> = [];

  ticketBusinessState = [];
  ticketAssignmentStates = [];
  ticketReferenceType = [];
  ticketEntityType = [];
  ticketNoteTypes = [];

  editMode = true;
  newTicket: boolean = false;

  authService: AuthService;
  existingDmsFiles: Set<string> = new Set();
  duplicationId;
  forDuplication;
  createDmsFilesList = [];
  backUrl: string = null;
  pickerInputType = PickerInputType;

  priorities = [];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private ticketService: TicketService,
    private enumDataTypeService: EnumDataTypeService,
    private dmsService: DmsService,
    private authFactoryService: AuthFactoryService,
    private stickyMessageService: StickyMessageService,
    public customerLocalStorageService: CustomerLocalStorageService,
    private dynamicEnumService: DynamicEnumService,
    public currentLocaleService: CurrentLocaleService
  ) {
    super(router, route);
    this.getEnums();

    this.ticketingService.getTicketTypes().subscribe(result => {
      this.ticketTypes = result;
    });

    this.enumDataTypeService.getEnums('ticket').subscribe(result => {
      this.dataTypeEnums = {};
      result.forEach(e => (this.dataTypeEnums[e.name] = e));
    });

    this.route.queryParams.subscribe(queryParams => {
      this.ticketTypeCode = queryParams['code'];
      this.forDuplication = queryParams['forDuplication'];
      this.duplicationId = queryParams['duplicationId'];
      this.relatedTicketId = queryParams['relatedTicketId'];
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.backUrl = queryParams['backUrl'];
    });
  }

  ngOnInit(): void {
    if (this.ticket) {
      this.newTicket = true;
    }
    if (this.ticketTypeCode) {
      this.getSkeletonByType(this.ticketTypeCode);
    }
  }

  getEnums() {
    this.dynamicEnumService
      .getEnumEntries(TicketEditComponent.SOURCE_NAME, this.TICKET_REF_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketReferenceType = result.data.map(value => value.name).sort();
      });

    this.dynamicEnumService
      .getEnumEntries(TicketEditComponent.SOURCE_NAME, TicketEditComponent.TICKET_NOTE_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketNoteTypes = result.data.map(value => value.name).sort();
      });

    this.dynamicEnumService
      .getEnumEntries(TicketEditComponent.SOURCE_NAME, TicketEditComponent.TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketEntityType = result.data.map(value => value.name).sort();
      });
  }

  public getSkeletonByType(type: string) {
    this.ticketingService
      .getTicketSkeletonByTypeCode(type)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticketSkeleton => {
        if (this.relatedTicketId) {
          TicketService.addReference(ticketSkeleton, 'com.emeldi.ecc.be.ticket.dto.Ticket', this.relatedTicketId);
        }
        if (this.cuRefNo) {
          TicketService.addReference(ticketSkeleton, 'com.emeldi.ecc.be.crm.dto.Customer', this.cuRefNo);
        }
        if (this.caRefNo) {
          TicketService.addReference(ticketSkeleton, 'com.emeldi.ecc.be.crm.dto.CustomerAccount', this.caRefNo);
        }
        this.reloadTicket(ticketSkeleton, true);
      });
  }

  loadTicket(ticketId) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadTicket(result);
      });
  }

  reloadTicket(ticketDto: TicketDto, buildForm = false) {
    this.ticket = ticketDto;
    this.priorities.length = 0;
    this.priorities.push(...this.getPriorities(this.ticket.type));
    this.getBusinessStates();
    this.getAssignmentStates();
    if (this.ticket.parameters) {
      this.getParamsFormArray().clear();
      this.ticketParamConfigByName.clear();
      const ticketType = this.ticketTypes?.find(type => type.code === this.ticket.type.code);
      if (ticketType) {
        ticketType.parameters.forEach(paramConfig => this.ticketParamConfigByName.set(paramConfig.name, paramConfig));
      }

      this.ticket.parameters = this.ticket.parameters.sort((a, b) =>
        this.ticketParamConfigByName.get(a.name)?.paramMetas.find(meta => meta.name === 'guiSortOrder')?.value >
        this.ticketParamConfigByName.get(b.name)?.paramMetas.find(meta => meta.name === 'guiSortOrder')?.value
          ? 1
          : -1
      );

      this.ticket.parameters.forEach(param => {
        this.addParamToFormArray(param);
      });
    }

    if (this.ticket.steps) {
      this.getStepsFormArray().clear();
      this.ticket.steps.forEach(param => {
        this.addStepToFormArray(param);
      });
    }

    if (this.ticket.references) {
      this.getReferencesFormArray().clear();
      this.ticket.references.forEach(param => {
        this.addReferenceToFormArray(param);
      });
    }

    // @ts-ignore
    this.form.patchValue(this.ticket);

    this.ticket.attachments?.forEach(attachment => this.existingDmsFiles.add(attachment.extId));

    this.relatedTasks = [];
    this.relatedOrders = [];
    this.relatedQuotes = [];
    this.ticket.attachments?.forEach(attachment => this.existingDmsFiles.add(attachment.extId));
  }

  getParamsFormArray() {
    return this.form.get('parameters') as FormArray;
  }

  getParameterDataTypeEnumItems(parameterName: string): DataTypeEnumItemDto[] {
    return this.dataTypeEnums[this.ticketParamConfigByName.get(parameterName)?.dataTypeDetail]?.items;
  }

  getParamsFormArrayControls(): FormGroup[] {
    return (this.form.get('parameters') as FormArray).controls as FormGroup[];
  }

  getAttachmentsFormArray() {
    return this.form.get('attachments') as FormArray;
  }

  addParamToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group({
      name: [null],
      value: [null],
    });

    newDataFormGroup.patchValue(value);
    if (this.ticketParamConfigByName.get(value.name)?.staticType) {
      newDataFormGroup.disable();
    }

    this.getParamsFormArray().push(newDataFormGroup);
  }

  getParamValue(paramName: string, defaultValue: string = ''): string {
    const opportunityParam = this.ticket?.parameters.find(param => param.name === paramName);
    return opportunityParam ? opportunityParam.value : defaultValue;
  }

  getOrderAttributeValue(order: OrderDto, attrName: string): string {
    return order.orderAttributes.find(findParam => findParam.attr === attrName)?.value;
  }

  edit() {
    this.editMode = true;
  }

  saveChanges(skipFormValidation = false) {
    if (!skipFormValidation) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return;
      }
    }

    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      this.ticket[field] = control.value;
    });

    if (this.ticket.attachments) {
      this.ticket.attachments = this.ticket.attachments.filter(attachment => !attachment['delete']);
      this.ticket.attachments.forEach((attachment: Attachment) => {
        attachment.id = null;
        attachment.dmsFile.contentHref = null;
        attachment.dmsFile.size = null;
        if (!attachment.dmsFile?.id) {
          this.createDmsFilesList.push(attachment.dmsFile);
        }
        delete attachment.dmsFile;
      });
    }

    if (this.ticket.recordVersion) {
      this.appBlockerService.block();
      this.saveTicket();
    } else {
      TicketService.clearFieldsBeforeUpdate(this.ticket, true);
      this.ticketingService
        .createTicket(this.ticket)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => this.saveFiles(result));
    }
  }

  private saveTicket() {
    const id = this.ticket.id;
    this.ticket.id = null;

    TicketService.clearFieldsBeforeUpdate(this.ticket);
    this.ticketingService
      .updateTicket(id, this.ticket)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => this.saveFiles(result));
  }

  private saveFiles(ticket) {
    if (this.createDmsFilesList.length > 0) {
      const calls = [];
      this.createDmsFilesList.forEach(file => calls.push(this.dmsService.createFile(file)));
      forkJoin(calls)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.afterSaveSuccess(ticket);
        });
    } else {
      this.newTicket = false;
      this.editMode = false;
      this.afterSaveSuccess(ticket);
    }
  }

  afterSaveSuccess(ticket) {
    this.reloadTicket(ticket);
    if (this.saveCallback) {
      this.saveCallback(ticket);
    } else if (this.backUrl) {
      let extras = {};
      if (this.relatedTicketId) {
        extras = {
          queryParams: {
            relatedTicketId: this.relatedTicketId,
            relatedTaskId: ticket.id,
          },
        };
      }
      this.router.navigate([this.backUrl], extras);
    } else {
      this.navigateSibling('EcareTasksComponent', { cuRefNo: this.cuRefNo, caRefNo: this.caRefNo });
    }
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback();
    } else if (this.backUrl) {
      this.router.navigate([this.backUrl]);
    } else {
      this.navigateSibling('EcareTasksComponent', { cuRefNo: this.cuRefNo, caRefNo: this.caRefNo });
    }
  }

  deleteTicket() {
    this.ticketingService
      .deleteTicket(this.ticket.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.tickets.ticketDeleted');
        this.navigateSibling('EcareTasksComponent', { cuRefNo: this.cuRefNo, caRefNo: this.caRefNo });
      });
  }

  get appCurrency(): string {
    return environment.currency;
  }

  private setAssignedToUser() {
    from(this.authService.getUserInfo()).subscribe(user => this.form.get('assignedTo').setValue(user.username));
  }

  getBusinessStates() {
    this.ticketingService
      .getBusinessStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(businessStates => {
        const businessTargets = businessStates.states.find(p => p.name === this.ticket.businessState)?.targets;
        this.ticketBusinessState = businessTargets ? businessTargets : [];
        if (this.ticket.businessState) {
          this.ticketBusinessState.push(this.ticket.businessState);
        }
      });
  }

  getAssignmentStates() {
    this.ticketingService
      .getAssignmentStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(assignmentStates => {
        const assignmentTargets = assignmentStates.states.find(p => p.name === this.ticket.assignmentState)?.targets;
        this.ticketAssignmentStates = assignmentTargets ? assignmentTargets : [];
        if (this.ticket.assignmentState) {
          this.ticketAssignmentStates.push(this.ticket.assignmentState);
        }
      });
  }

  getStepsFormArray() {
    return this.form.get('steps') as FormArray;
  }

  addStepToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group({
      remark: [],
      stepSeqNo: [],
      workStartDate: [],
      workEndDate: [],
      expectedDelay: [],
      modifiedBy: [],
      config: this.formBuilder.group({
        code: [],
        description: [],
      }),
    });

    newDataFormGroup.patchValue(value);
    this.getStepsFormArray().push(newDataFormGroup);
  }

  getReferencesFormArray() {
    return this.form.get('references') as FormArray;
  }

  addReferenceToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group({
      entityId: [null, Validators.required],
      entityType: [null, Validators.required],
      refType: [null, Validators.required],
    });

    newDataFormGroup.patchValue(value);
    this.getReferencesFormArray().push(newDataFormGroup);
  }

  remove(referenceForm) {
    let index = 1;
    let foundIndex = null;
    this.getReferencesFormArray().controls.forEach(control => {
      if (control === referenceForm) {
        foundIndex = index;
      }
      index++;
    });

    if (foundIndex) {
      this.getReferencesFormArray().removeAt(foundIndex - 1);
    }
  }

  addReference() {
    this.addReferenceToFormArray({});
  }

  duplicate() {
    this.ticketService.getDuplicateParameters(this.ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSelf(duplicateParamters);
        }
      });
  }

  getPriorities(ticketType: TicketTypeDto) {
    return this.ticketingService.getTicketPriorities(ticketType?.minPriority, ticketType?.maxPriority).map(value => ({
      label: value.toString(),
      value: value
    }));
  }

  hideParameter(name) {
    const param = this.ticketParamConfigByName.get(name);
    if (param) {
      return param.paramMetas.find(meta => meta.name === 'guiVisible')?.value === 'true';
    }
    return false;
  }
}
