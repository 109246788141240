import { EventEmitter, Injectable, Output } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  CodebookService,
  ElasticsearchService,
  ElseAggrRange,
  GroupTreeNode,
  ProductGroupService
} from '@btl/btl-fe-wc-common';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FilteringGroup, GeneralFilter, RangeFilter } from '../models/filtering-group';
import { CodebookDto, CodebookParamDto } from '@btl/order-bff';
import { ProductElasticFilter, SortTypeEnum } from '../models/product-elastic-filter';

/**
 * Stateful service containing current state of the GUI product filter
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private productFilter: ProductElasticFilter;
  private productAggregations: object;
  private lastProductAggregations: object;
  private lastFilteringGroup: string;

  @Output()
  readonly filterChange: EventEmitter<any> = new EventEmitter();

  private DEFAULT_PAGE_SIZE = 21;
  //TODO: HW_FILTERING_TYPE refactor - no such mappings in the code!
  private CODEBOOK_TO_ELSE_MAPPING = {
    // values calculated by elastic
    1: 'stockAvailability',
    3: 'brand',
    6: 'operatingSystem',
    7: 'ram',
    8: 'totalMemory',
  };
  private CODEBOOK_TO_ELSE_NUMBER_ONLY_MAPPING = {
    // values from codebook by number code
    4: 'colorVariants.colorCode',
  };
  private CODEBOOK_TO_ELSE_RANGE_MAPPING = {
    // values are defined as ranges in codebook
    2: 'prices.priceIndex',
    5: 'screenSize',
  };
  private CODEBOOK_CODE_TO_ATTRIBUTE_NAME = {
    //all possible filters
    1: 'availability',
    2: 'price',
    3: 'brand',
    4: 'color',
    5: 'screenSize',
    6: 'operatingSystem',
    7: 'ram',
    8: 'totalMemory',
  };
  private codebookFilters$: Observable<FilteringGroup[]>;

  // Elasticsearch index names
  public static readonly PRODUCTS_INDEX = 'products';
  public static readonly PICK_UP_POINTSS_INDEX = 'pick-up-points';

  constructor(
    private productGroupService: ProductGroupService,
    private codebookService: CodebookService,
    private elasticSearchService: ElasticsearchService
  ) {}

  /**
   * Returns current product filter DTO
   */
  public getProductFilter(): ProductElasticFilter {
    if (!this.productFilter) {
      this.productFilter = this.getDefaultProductFilter();
    }
    return this.productFilter;
  }

  /**
   * Sets aggregation JSON to local variable for usage in left menu filters
   * @param productAggregations Aggregation JSON
   * @param filteringGroup Name of the filtering group attribute that emitted the product reload
   */
  public setProductAggregations(productAggregations, filteringGroup?: string) {
    if (filteringGroup && this.getProductFilter().attributes[filteringGroup].length === 0) {
      filteringGroup = undefined;
    }
    if (this.lastFilteringGroup !== filteringGroup) {
      this.lastProductAggregations = this.productAggregations;
    }
    this.productAggregations = productAggregations;
    this.lastFilteringGroup = filteringGroup;
  }

  public getActiveFilteringGroups() {
    return Object.values(this.CODEBOOK_CODE_TO_ATTRIBUTE_NAME).filter(
      attr => this.getProductFilter().attributes[attr].length > 0
    );
  }

  public getDefaultProductFilter() {
    return {
      attributes: {
        availability: [],
        price: [],
        brand: [],
        screenSize: [],
        operatingSystem: [],
        ram: [],
        totalMemory: [],
        color: [],
        groupId: null,
        groupCode: null,
        productGroup: null,
        text: null,
        compatibleAccessoryOf: [],
      },
      paging: { page: 1, pageSize: this.DEFAULT_PAGE_SIZE, firstPageSize: this.DEFAULT_PAGE_SIZE },
      sorting: SortTypeEnum.RECOMMENDED,
    };
  }

  /**
   * Changes current sorting to the one in parameter. If there is no parameter, only emits filter change event.
   * @param sorting
   */
  public changeSorting(sorting?: SortTypeEnum) {
    if (sorting) {
      this.getProductFilter().sorting = sorting;
    }
    this.filterChange.emit();
  }

  public changeGroupId(groupId: string) {
    this.getProductFilter().attributes.groupId = groupId;
    this.filterChange.emit();
  }

  /**
   * Sets the text filter given value
   * @param text
   */
  public setText(text: string, emitChange: boolean = true) {
    const defaultProductFilter = this.getDefaultProductFilter();
    this.getProductFilter().attributes = defaultProductFilter.attributes;
    this.getProductFilter().sorting = defaultProductFilter.sorting;
    this.getProductFilter().paging = defaultProductFilter.paging;

    this.getProductFilter().attributes.text = text;
    if (emitChange) {
      this.filterChange.emit();
    }
  }

  /**
   * Sets productGroup to use in filtering, group name and subgroups
   * @param productGroupNode The group to use
   */
  public setCurrentProductGroup(productGroupNode: GroupTreeNode) {
    this.productGroupService.setCurrentProductGroup(productGroupNode);
    this.changeGroupId(productGroupNode.group.id);
  }

  /**
   * Adds new product filtering attribute value to filter and emits filter change
   * @param attributeName attribute name
   * @param attributeValue attribute value
   */
  public addProductAttributeValue(attributeName: string, attributeValue: any) {
    this.getProductFilter().attributes[attributeName].push(attributeValue);
    this.filterChange.emit(attributeName);
  }

  /**
   * Removes product filtering attribute value from filter and emits filter change
   * @param attributeName attribute name
   * @param attributeValue attribute value
   */
  public removeProductAttributeValue(attributeName: string, attributeValue: any) {
    this.getProductFilter().attributes[attributeName] = this.getProductFilter().attributes[attributeName].filter(
      attrVal => JSON.stringify(attrVal) !== JSON.stringify(attributeValue)
    );
    this.filterChange.emit(attributeName);
  }

  /**
   * Transforms codebook code to attribute name using varaible CODEBOOK_CODE_TO_ATTRIBUTE_NAME for mapping
   * @param codebookCode The code from codebook
   */
  public getAttributeNameByCodebookCode(codebookCode) {
    return this.CODEBOOK_CODE_TO_ATTRIBUTE_NAME[codebookCode];
  }

  /**
   * Sets productGroup to use in filtering, group name and subgroups by seoUrl
   * @param seoUrl The seoUrl of the group to use
   */
  public setCurrentProductGroupBySeoUrl(seoUrl: string): Observable<GroupTreeNode> {
    const setCurrentGroup = groupTreeNode => {
      if (groupTreeNode) {
        this.setCurrentProductGroup(groupTreeNode);
      }
    };
    if (seoUrl) {
      return this.productGroupService.getProductGroupBySeoUrl(seoUrl).pipe(tap(setCurrentGroup));
    } else {
      return this.productGroupService.getDefaultGroup().pipe(tap(setCurrentGroup));
    }
  }

  /**
   * Returns observable containing filters for left menu checkboxes using data from codebooks and ELSE
   */
  public getCheckboxFilters(groupId: string): Observable<Array<FilteringGroup>> {
    const codebookFilters$ = this.getCodebookFilters().pipe(
      switchMap(codebookFilters => {
        const $elseFilters = this.getElseFilters(codebookFilters, groupId);
        return forkJoin($elseFilters, of(codebookFilters));
      }),
      map(([elseFilters, codebookFilters]: [any, Array<FilteringGroup>]) =>
        this.mapElseIntoFileringGroup(codebookFilters, elseFilters)
      )
    );
    return codebookFilters$;
  }

  /**
   * Returns number of docs in given bucket from variable lastProductAggregations, which is set from product load
   * through method setProductAggregations
   * @param groupCode Codebook item code of the group
   * @param filterCode Codebook item code of the given filter
   */
  public getAggregatedNumber(groupCode: string, filterCode: string): number {
    let number: number = 0;
    let buckets;
    let productAggregations = this.productAggregations;
    if (this.lastFilteringGroup === this.getAttributeNameByCodebookCode(groupCode)) {
      productAggregations = this.lastProductAggregations;
    }
    if (productAggregations) {
      const mapping = { ...this.CODEBOOK_TO_ELSE_MAPPING, ...this.CODEBOOK_TO_ELSE_NUMBER_ONLY_MAPPING };
      if (this.isCodebookCodeMapped(groupCode, productAggregations, mapping)) {
        buckets = this.getBuckets(productAggregations, groupCode, mapping);
      }
      if (this.isCodebookCodeMapped(groupCode, productAggregations, this.CODEBOOK_TO_ELSE_RANGE_MAPPING)) {
        buckets = this.getBuckets(productAggregations, groupCode, this.CODEBOOK_TO_ELSE_RANGE_MAPPING);
      }
      if (buckets) {
        const filterBucket = buckets.find(bucket => bucket.key === filterCode);
        if (filterBucket) {
          number = parseInt(filterBucket.doc_count);
        }
      }
    }
    return number;
  }

  private getCodebookFilters(): Observable<Array<FilteringGroup>> {
    const hwFilteringType$ = this.codebookService.getCodebooks('HW_FILTERING_TYPE');
    const hwFilteringPriceRange$ = this.codebookService.getCodebooks('HW_FILTERING_PRICE_RANGE');
    const hwFilteringScreenSizeRange$ = this.codebookService.getCodebooks('HW_FILTERING_SCREEN_SIZE_RANGE');
    const hwColorVariant$ = this.codebookService.getCodebooks('HW_COLOR_VARIANT');

    if (!this.codebookFilters$) {
      this.codebookFilters$ = forkJoin(
        hwFilteringType$,
        hwFilteringPriceRange$,
        hwFilteringScreenSizeRange$,
        hwColorVariant$
      ).pipe(
        map(result => this.mapCodebookFiltersTogether(result[0], result[1], result[2], result[3])),
        shareReplay(1)
      );
    }
    return this.codebookFilters$;
  }

  private getElseFilters(codebookFilters: Array<FilteringGroup>, groupId: string): Observable<any> {
    const elseFieldNames: Array<string> = this.getElseFieldNames(codebookFilters, this.CODEBOOK_TO_ELSE_MAPPING);
    const elseAggrRanges: Array<ElseAggrRange> = this.getElseAggrRanges(
      codebookFilters,
      this.CODEBOOK_TO_ELSE_RANGE_MAPPING
    );
    const properties: Map<string, any> = new Map<string, any>();
    properties.set('groups.id', groupId);
    return this.elasticSearchService.getBucketList(
      FilterService.PRODUCTS_INDEX,
      elseFieldNames,
      elseAggrRanges,
      properties
    );
  }

  public getAggregationJson(): Observable<any> {
    return this.getCodebookFilters().pipe(
      map(codebookFilters => {
        const elseFieldNames: Array<string> = this.getElseFieldNames(codebookFilters, {
          ...this.CODEBOOK_TO_ELSE_MAPPING,
          ...this.CODEBOOK_TO_ELSE_NUMBER_ONLY_MAPPING,
        });
        const elseAggrRanges: Array<ElseAggrRange> = this.getElseAggrRanges(
          codebookFilters,
          this.CODEBOOK_TO_ELSE_RANGE_MAPPING
        );
        return this.elasticSearchService.getAggregations(elseFieldNames, elseAggrRanges);
      })
    );
  }

  private getElseAggrRanges(codebookFilters: Array<FilteringGroup>, mapping: object) {
    return codebookFilters
      .map(codebookFilter => {
        if (mapping[codebookFilter.code]) {
          const elseAggrRange: ElseAggrRange = {
            field: mapping[codebookFilter.code],
            ranges: codebookFilter.filters.map(filter => {
              const range = { key: filter.code };
              if (filter['min'] || filter['min'] === 0) {
                range['from'] = filter['min'];
              }
              if (filter['max'] && Number(filter['max'])) {
                range['to'] = Number(filter['max']);
              }
              return range;
            }),
          };
          return elseAggrRange;
        } else {
          return null;
        }
      })
      .filter(elseAggrRange => elseAggrRange);
  }

  private getElseFieldNames(codebookFilters: Array<FilteringGroup>, mapping: object) {
    return codebookFilters
      .map(codebookFilter => {
        if (mapping[codebookFilter.code]) {
          return mapping[codebookFilter.code];
        } else {
          return null;
        }
      })
      .filter(elseName => elseName);
  }

  private mapElseIntoFileringGroup(codebookFilters: Array<FilteringGroup>, elseFilters): Array<FilteringGroup> {
    for (const codebookFilter of codebookFilters) {
      if (this.isCodebookCodeMapped(codebookFilter.code, elseFilters, this.CODEBOOK_TO_ELSE_MAPPING)) {
        const buckets = this.getBuckets(elseFilters, codebookFilter.code, this.CODEBOOK_TO_ELSE_MAPPING);
        codebookFilter.filters = buckets
          .map(bucket => {
            const generalFilter: GeneralFilter = {
              value: bucket.key,
              name: bucket['key_as_string'] ? bucket['key_as_string'] : bucket.key,
              code: bucket.key,
              types: codebookFilter.types,
            };
            return generalFilter;
          })
          .sort((n1, n2) => n2.value - n1.value);
      }
    }
    return codebookFilters;
  }

  private getBuckets(elseFilters, codebookFilterCode, mapping) {
    return elseFilters[mapping[codebookFilterCode]].buckets
      ? elseFilters[mapping[codebookFilterCode]].buckets
      : elseFilters[mapping[codebookFilterCode]].subkey.buckets;
  }

  private isCodebookCodeMapped(codebookFilterCode, elseFilters, mapping) {
    return (
      mapping[codebookFilterCode] &&
      elseFilters[mapping[codebookFilterCode]] &&
      (elseFilters[mapping[codebookFilterCode]].buckets || elseFilters[mapping[codebookFilterCode]].subkey.buckets)
    );
  }

  private mapCodebookFiltersTogether(
    hwFilteringTypes: CodebookDto[],
    hwFilteringPriceRanges: CodebookDto[],
    hwFilteringScreenSizeRanges: CodebookDto[],
    hwColorVariant: Array<CodebookDto>
  ): FilteringGroup[] {
    const filteringGroups: FilteringGroup[] = new Array<FilteringGroup>();
    for (const hwFilteringType of hwFilteringTypes) {
      const filteringParameterArr = hwFilteringType.parameters.filter(param => param.name === 'filteringParameter');
      const filteringParameter = filteringParameterArr.length > 0 ? filteringParameterArr[0].value : null;
      const filteringGroup: FilteringGroup = {
        name: null,
        codebook: hwFilteringType,
        filteringParameter: filteringParameter,
        code: hwFilteringType.code,
        types: this.getFilteringTypes(hwFilteringType),
        filters: this.getFilterItems(
          hwFilteringType,
          hwFilteringPriceRanges,
          hwFilteringScreenSizeRanges,
          hwColorVariant
        ),
      };
      filteringGroups.push(filteringGroup);
    }
    return filteringGroups;
  }

  private getFilterItems(
    hwFilteringType: CodebookDto,
    hwFilteringPriceRanges: CodebookDto[],
    hwFilteringScreenSizeRanges: CodebookDto[],
    hwColorVariant: Array<CodebookDto>
  ): Array<GeneralFilter | RangeFilter> {
    //TODO: HW_FILTERING_TYPE refactor
    switch (
      hwFilteringType.code // TODO: refactor this code and CB configuration to be more generic and use only min/max
    ) {
      case '2':
        return this.getRangeFilters(hwFilteringPriceRanges, 'minPrice', 'maxPrice');
        break;
      case '5':
        return this.getRangeFilters(hwFilteringScreenSizeRanges, 'minSize', 'maxSize');
        break;
      case '4':
        return this.getColorFilter(hwFilteringType, hwColorVariant);
    }
  }

  private getRangeFilters(ranges: CodebookDto[], minParamName: string, maxParamName: string) {
    const filters: Array<RangeFilter> = new Array<RangeFilter>();
    for (const range of ranges) {
      const types = this.getFilteringTypes(range);
      const min = this.getNumberParam(range.parameters, minParamName);
      const max = this.getNumberParam(range.parameters, maxParamName);
      const rangeFilter: RangeFilter = {
        name: null,
        codebook: range,
        code: range.code,
        min: min,
        max: max,
        types: types,
      };
      filters.push(rangeFilter);
    }
    return filters;
  }

  private getNumberParam(params: CodebookParamDto[], name: string): string {
    return params
      .filter(param => param.name === name)
      .map(param => param && param.value)
      .pop();
  }

  private getColorFilter(hwFilteringType: CodebookDto, hwColorVariants: Array<CodebookDto>) {
    const filters: Array<GeneralFilter> = new Array<GeneralFilter>();
    for (const colorVariant of hwColorVariants) {
      const types = this.getFilteringTypes(hwFilteringType);
      const priceFilter: GeneralFilter = {
        name: null,
        codebook: colorVariant,
        code: colorVariant.code,
        value: colorVariant.code,
        types: types,
      };
      filters.push(priceFilter);
    }
    return filters;
  }

  private getFilteringTypes(codebookDto: CodebookDto) {
    return codebookDto.parameters
      .filter(param => param.name.startsWith('filteringType') && param.value === 'x')
      .map(param => param && parseInt(param.name.replace(/filteringType(\d)/, '$1')));
  }

  public setIsAccessoryOf(productCodes: Array<string>) {
    this.getProductFilter().attributes.compatibleAccessoryOf = productCodes;
    this.filterChange.emit();
  }
}
