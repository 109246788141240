import { Component } from '@angular/core';
import {
  AuthFactoryService,
  IPageModuleBreadcrumb,
  PageModule,
  PageModuleBreadcrumbComponent,
  PageModuleService
} from '@btl/btl-fe-wc-common';
import { AccountDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { ActivePageModule } from '../../../../../mvn_modules/@btl/btl-fe-wc-common';

@Component({
  selector: 'app-ecare-breadcrumb, [app-ecare-breadcrumb]',
  templateUrl: './ecare-breadcrumb.component.html',
})
export class EcareBreadcrumbComponent extends PageModuleBreadcrumbComponent {

  homePageUrl: string;

  constructor(protected pageModuleService: PageModuleService, private authFactoryService: AuthFactoryService) {
    super(pageModuleService);
    this.setHomePage(authFactoryService.getAuthService()?.account);
    authFactoryService
      .getAuthService()
      .accountChange.pipe(takeUntil(this.onDestroy$))
      .subscribe(account => {
        this.setHomePage(account);
      });
  }

  private setHomePage(account: AccountDto) {
    if (account) {
      if (account.external) {
        this.homePageUrl = '/ecare/ext-homepage';
      } else {
        this.homePageUrl = '/ecare/homepage';
      }
    } else {
      this.homePageUrl = null;
    }
  }

  protected createBreadcrumb(
    active: ActivePageModule,
    module: PageModule,
    urlModule: PageModule
  ): IPageModuleBreadcrumb {
    const params: { [key: string]: string } = {};
    if (active.params['cuRefNo']) {
      params['cuRefNo'] = active.params['cuRefNo'];
    }
    if (active.params['caRefNo']) {
      params['caRefNo'] = active.params['caRefNo'];
    }
    return {
      code: module.code,
      usedParams:
        active.pageModule === module
          ? this.generateUsedParams(urlModule.urlPattern, active.params)
          : this.generateUsedParams(
              (urlModule.parent ? urlModule.parentUrlPattern : '') + urlModule.urlPattern,
              active.params
            ),
      url: this.generateUrl((urlModule.parent ? urlModule.parentUrlPattern : '') + urlModule.urlPattern, active.params),
      params: params,
    };
  }
}
