import { Component } from '@angular/core';
import {
  AbstractPageComponent,
  CurrentLocaleService,
  EnableDynamicLoading,
  getCurrentHost,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

export interface ThirdPartyAuthorityConfig {
  name: string;
  iconPath: string;
}

@Component({
  selector: 'app-ecare-user-account-linked-accounts',
  templateUrl: './ecare-user-account-linked-accounts.component.html',
})
@EnableDynamicLoading({ customName: EcareUserAccountLinkedAccountsComponent.PAGE_ID })
export class EcareUserAccountLinkedAccountsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareUserAccountLinkedAccountsComponent';

  pageId(): string {
    return EcareUserAccountLinkedAccountsComponent.PAGE_ID;
  }

  kcUrl;

  constructor(
    private currentLocaleService: CurrentLocaleService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.setKcUrl();
    currentLocaleService.currentLocaleChange.subscribe(locale => {
      this.setKcUrl();
    });
  }

  setKcUrl() {
    this.kcUrl = `${getCurrentHost()}/auth/realms/btl/account/identity?kc_locale=${this.currentLocaleService.getCurrentLanguage()}`;
  }
}
