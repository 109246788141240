<div class="accordion" [attr.id]="urlAnchor">
  <div *ngFor="let meta of accordionMeta; let i = index" class="accordion-item">
    <h2
      class="accordion-header cursor-pointer"
      [style.color]="colorTitle"
      [style.background]="backgroundColorTitle"
      (click)="itemsCollapsions[i] = !itemsCollapsions[i]">
      <img [src]="getIcon(i)" /> {{ meta.title | selectLocaleProperty }}
    </h2>
    <div
      *ngIf="!itemsCollapsions[i]"
      class="accordion-body"
      [style.color]="colorContent"
      [ngStyle]="
        (backgroundImageContent && { background: 'url(' + backgroundImageContent + ') no-repeat' }) ||
        (backgroundColorContent && { background: backgroundColorContent })
      ">
      {{ meta.content | selectLocaleProperty }}
    </div>
  </div>
</div>
