<app-ecare-page-layout columnTemplate="1fr" title="{{
    (caRefNo
      ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
        ' ' +
        ('wc.ecare.dashboard.account.header' | translate) + ' '
      : (cuRefNo ? customerLocalStorageService.getCurrentCustomer().displayName + ' ' : '') + ' ') +
      ('wc.ecare.notifications.headline' | translate)
  }}">
  <ng-container page-actions>
    <app-acl-internal-evaluate-component class="padding-10">
      <app-button
        variant="primary"
        iconPath="/assets/svg/add.svg"
        label="{{ 'wc.ecare.notifications.new' | translate }}"
        (click)="newNotification()">
      </app-button>
    </app-acl-internal-evaluate-component>
  </ng-container>
  <div>
    <app-notifications-filter
      *ngIf="gateways"
      [gateways]="gateways"
      [filtering]="search.filtering"
      (filterChanged)="handleFilterChange()">
    </app-notifications-filter>
    <div class="dashboard notifications">
      <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
        <div class="dashboard_search">
          <ng-container *ngIf="notifications.length > 0; else no_results">
            <table class="ecare_listing-table orders-table table">
              <thead>
              <tr>
                <th></th>
                <th>{{ 'wc.ecare.notification.to' | translate }}</th>
                <th>{{ 'wc.ecare.notification.subject' | translate }}</th>
                <th>{{ 'wc.ecare.notification.send' | translate }}</th>
                <th>{{ 'wc.ecare.notification.action' | translate }}</th>
              </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let notification of notifications">
                  <tr class="ecare_listing-table-row pointer {{ selected === notification ? 'selected' : '' }}">
                    <td
                      class="images"
                      (click)="selected === notification ? (selected = null) : (selected = notification)">
                      <div class="images">
                        <img
                          width="20px"
                          [src]="'/assets/svg/' + (selected === notification ? 'up' : 'down') + '-arrow-icon.svg'" />
                        <div
                          class="type"
                          placement="bottom"
                          container="body"
                          ngbTooltip="{{
                            'wc.ecare.notifications.channelTypes.' +
                              getGatewayChannelType(notification.gateway).toUpperCase() | translate
                          }}">
                          <i
                            [inlineSVG]="
                              '/assets/img/notifications/' + getGatewayChannelType(notification.gateway) + '_icon.svg'
                            "></i>
                        </div>
                      </div>
                    </td>
                    <td
                      class="cut-text"
                      (click)="selected === notification ? (selected = null) : (selected = notification)">
                      <span class="bold">{{ getRecipientsNames(notification.recipients) }}</span>
                      <br />
                      <div class="cut-text">{{ getRecipientsAddresses(notification.recipients, 'TO') }}</div>
                    </td>
                    <td
                      class="cut-text"
                      (click)="selected === notification ? (selected = null) : (selected = notification)">
                      <div>
                        <div class="subject-state">
                          <span class="bold">{{ notification.subject }}</span>
                          <i
                            class="state-icon ml-2"
                            placement="bottom"
                            ngbTooltip="{{ 'wc.ecare.notifications.states.' + notification.state | translate }}"
                            [inlineSVG]="'/assets/img/notifications/status_' + notification.state + '.svg'"></i>
                        </div>
                        <div class="cut-text">{{ getNotificationBody(notification) }}</div>
                      </div>
                    </td>
                    <td (click)="selected === notification ? (selected = null) : (selected = notification)">
                      <span class="scheduled-on"
                      >{{ (!notification.sent ? 'wc.ecare.notifications.scheduledOn' : '') | translate}}<br
                      /></span>
                      <span class="send-date">{{
                        (notification.sent ? notification.sent : notification.toBeSent) | date : 'dd.MM.yyyy HH:mm'
                        }}</span>
                    </td>
                    <td class="no-wrap mobile-right">
                      <ng-container *ngTemplateOutlet="dropdownMenu; context: { notification: notification }">
                      </ng-container>
                    </td>
                  </tr>
                  <tr *ngIf="selected === notification" class="notification-window">
                    <td colspan="5" class="notification-window">
                      <ng-container *ngTemplateOutlet="dropdownNotification; context: { notification: notification }">
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="order-table-mobile">
              <ng-container *ngFor="let notification of notifications">
                <div class="mobile-table-row">
                  <div
                    class="order-row-wrapper"
                    (click)="selected === notification ? (selected = null) : (selected = notification)">
                    <div class="order-row-top">
                      <div class="left-part cut-text">
                        <div class="id-part">
                          <div class="order-id-wrapper cut-text">
                            <i
                              class="notification-type-icon"
                              [inlineSVG]="
                                '/assets/img/notifications/' + getGatewayChannelType(notification.gateway) + '_icon.svg'
                              "></i>
                            <div class="cut-text">
                              <span class="bold"
                              >{{ getRecipientsNames(notification.recipients) }}
                                {{ getRecipientsAddresses(notification.recipients, 'TO') }}</span
                              >
                              <br />
                              <div class="display-flex">
                                {{
                                (notification.sent ? notification.sent : notification.toBeSent)
                                  | date : 'dd.MM.yyyy HH:mm'
                                }}
                                <i
                                  class="state-icon ml-2"
                                  placement="bottom"
                                  ngbTooltip="{{ 'wc.ecare.notifications.states.' + notification.state | translate }}"
                                  [inlineSVG]="'/assets/img/notifications/status_' + notification.state + '.svg'">
                                </i>
                                <span *ngIf="!notification.sent" class="scheduled-on"
                                >{{ 'wc.ecare.notifications.scheduledOn' | translate }}<br
                                /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div class="right-part">
                        <ng-container *ngTemplateOutlet="dropdownMenu; context: { notification: notification }">
                        </ng-container>
                      </div>
                    </div>
                    <div class="order-row-bottom">
                      <div class="left-part cut-text">
                        <span class="bold">{{ notification.subject }}</span>
                        <span class="cut-text">{{ getNotificationBody(notification) }}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="selected === notification" class="order-row-top">
                    <ng-container *ngTemplateOutlet="dropdownNotification; context: { notification: notification }">
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #no_results>
            <app-ecare-listing-no-results></app-ecare-listing-no-results>
          </ng-template>
          <div *ngIf="moreRecordsExists">
            {{ 'wc.ecare.notifications.scrollDownForMoreRecords.text' | translate }}
          </div>
          <div *ngIf="canDisplayNoFinancialDocumentsText" class="no-invoices-text">
            {{ 'wc.ecare.notifications.noContactHistory.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
<ng-template #dropdownNotification let-data="notification">
  <div class="overview">
    <div class="subject">
      {{ data.subject }}
    </div>
    <div class="one-line notif-detail-attrs">
      <div class="state-icon notif-icon-container">
        <i [inlineSVG]="'/assets/img/notifications/status_' + data.state + '.svg'"></i>
        {{ 'wc.ecare.notifications.states.' + data.state | translate }}
      </div>
      <div
        class="notif-icon-container"
        placement="bottom"
        ngbTooltip="{{ 'wc.ecare.notifications.createdDate' | translate }}">
        <i [inlineSVG]="'/assets/img/notifications/notif-edit.svg'"></i>
        {{ data.created | date : 'dd.MM.yyyy HH:mm' }}
      </div>
      <ng-container *ngIf="!data.sent">
        <div
          class="notif-icon-container"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.notifications.scheduleDate' | translate }}">
          <i [inlineSVG]="'/assets/img/notifications/notif-send-date.svg'"></i>
          <span>{{ data.toBeSent | date : 'dd.MM.yyyy HH:mm' }}</span>
        </div>
      </ng-container>
      <div class="notif-icon-container" placement="bottom" ngbTooltip="{{ 'wc.ecare.notifications.id' | translate }}">
        <i [inlineSVG]="'/assets/img/notifications/notif-id.svg'"></i>
        {{ data.id }}
      </div>
      <div
        class="notif-icon-container"
        placement="bottom"
        ngbTooltip="{{ 'wc.ecare.notifications.notificationType' | translate }}">
        <i [inlineSVG]="'/assets/img/notifications/notif-type.svg'"></i>
        {{ data.notificationType }}
      </div>
      <div
        class="notif-icon-container"
        placement="bottom"
        ngbTooltip="{{ 'wc.ecare.notifications.gateway' | translate }}">
        <i [inlineSVG]="'/assets/img/notifications/notif-gateway.svg'"></i>
        {{ data.gateway }}
      </div>
    </div>
    <div class="one-line">
      <label class="info">
        {{ 'wc.ecare.notifications.at' | translate }}
      </label>
      <label class="value">
        {{ data.sent | date : 'dd.MM.yyyy HH:mm' }}
      </label>
    </div>
    <div class="one-line">
      <label class="info">{{ 'wc.ecare.notifications.from' | translate }} </label>
      {{ data.fromAddress }}
      <label class="info"> {{ 'wc.ecare.notifications.to' | translate }} </label>

      <div ngbDropdown placement="bottom-right">
        <div ngbDropdownToggle class="pointer">
          {{ getRecipientsAddresses(data.recipients, 'TO') }}
          <i class="header__icon header__icon-down"></i>
        </div>
        <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
          <label class="info">CC:</label>
          <label class="value">{{ getRecipientsAddresses(data.recipients, 'CC') }}</label>
          <label class="info">BCC:</label>
          <label class="value">{{ getRecipientsAddresses(data.recipients, 'BCC') }}</label>
        </div>
      </div>
    </div>
    <div class="middle-info">
      <div class="one-line">
        <div [innerHTML]="data.body | safeHtml"></div>
      </div>
      <div class="more-lines">
        <label class="info">{{ 'wc.ecare.ticket.attachments' | translate }}:</label>
        <app-form-attachments [value]="data.attachments" [showHeader]="false" [attachmentType]="attachmentType">
        </app-form-attachments>
      </div>
      <div class="more-lines">
        <label class="info">{{ 'wc.ecare.notifications.note' | translate }}:</label>
        <div class="value">{{ data.note }}</div>
      </div>
    </div>
    <div class="buttons">
      <button *ngIf="!data.sent" class="btn-new" type="button" (click)="delete(data)">
        <img height="auto" width="auto" [src]="'/assets/img/notifications/delete.svg'" />
        {{ 'wc.ecare.notifications.delete.button' | translate }}
      </button>
      <button class="btn-new" type="button" (click)="duplicate(data)">
        <img height="auto" width="auto" [src]="'/assets/img/notifications/duplicate.svg'" />
        {{ 'wc.ecare.notifications.duplicate.button' | translate }}
      </button>
      <button *ngIf="isResendAllowed(data)" class="btn-edit" type="button" (click)="resent(data)">
        <img height="auto" width="auto" [src]="'/assets/img/notifications/resend.svg'" />
        {{ 'wc.ecare.notifications.resent.button' | translate }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #dropdownMenu let-data="notification">
  <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
    <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle (click)="$event.stopPropagation()">
      <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
    </span>
    <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ data.id }}_popover-content">
      <div>
        <button class="btn btn-link action-list-more-actions-button" type="button" (click)="duplicate(data)">
          {{ 'wc.ecare.notifications.duplicate.button' | translate }}
        </button>
        <button
          *ngIf="!data.sent"
          class="btn btn-link action-list-more-actions-button"
          type="button"
          (click)="delete(data)">
          {{ 'wc.ecare.notifications.delete.button' | translate }}
        </button>
        <button
          *ngIf="isResendAllowed(data)"
          class="btn btn-link action-list-more-actions-button"
          type="button"
          (click)="resent(data)">
          {{ 'wc.ecare.notifications.resent.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
