<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
<link
  href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
  rel="stylesheet" />

<!-- Project in Adobe Fonts - Igor Milenkovic / Emeldi - IPKO Project -->
<link rel="stylesheet" href="https://use.typekit.net/fqz4brw.css" />

<block-ui [name]="mainBlockUIInstanceName" [template]="blockTemplate">
  <div class="body">
    <router-outlet></router-outlet>
  </div>
  <app-dynamic-component-registry-init></app-dynamic-component-registry-init>
</block-ui>
