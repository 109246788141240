<tr class="ecare_listing-table-row clickable" (click)="onRowClick()">
  <td class="no-wrap">
    {{ customer.subject.firstName }}
  </td>
  <td class="no-wrap">
    {{ customer.subject.lastName }}
  </td>
  <td class="no-wrap">
    {{ customer.subject.identityNumber }}
  </td>
  <td class="no-wrap">
    {{ customer.subject.registrationNumber }}
  </td>
  <td class="no-wrap">
    {{ customer.id }}
  </td>
  <td class="no-wrap content-sm">
    {{ customer.address | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
  </td>
  <td class="no-wrap content-sm">
    {{ customer.extId }}
  </td>
</tr>
