<app-ecare-page-layout columnTemplate="1fr" title="{{ 'wc.ecare.accountEntity.headline' | translate }}">
  <ng-container page-actions>
    <app-button
      *ngIf="getSelectedEntityRoles().length > 0"
      variant="secondary"
      iconPath="/assets/svg/delete.svg"
      label="{{ 'wc.ecare.ticket.delete.button' | translate }}"
      (click)="deleteEntityRoles()"></app-button>
    <app-button
      iconPath="/assets/svg/add.svg"
      label="{{ 'wc.ecare.accountEntity.newAccountEntityRole' | translate }}"
      (click)="new()"></app-button>
  </ng-container>
  <div>
    <div class="dashboard">
      <div *blockUI="blockUIElement.name; template: blockTemplate">
        <app-ecare-account-entity-filter [filtering]="search.filtering"
                                         (filterChanged)="handleFilterChange()">
        </app-ecare-account-entity-filter>
        <div class="dashboard_wrapper">
          <div class="dashboard_search">
            <ng-container *ngIf="entityRoles?.length > 0; else no_results">
              <div class="ecare-responsive-table">
                <app-ecare-account-entity-table
                  [entityRoles]="entityRoles"
                  [selectFormArray]="selectedFormArray"
                  (editEmitter)="edit($event)"
                  (deleteEmitter)="delete($event)"
                  (delegateEmitter)="delegate($event)">
                </app-ecare-account-entity-table>
                <app-ecare-account-entity-table-mobile
                  class="ecare-responsive-mobile"
                  [entityRoles]="entityRoles"
                  [selectFormArray]="selectedFormArray"
                  (editEmitter)="edit($event)"
                  (deleteEmitter)="delete($event)"
                  (delegateEmitter)="delegate($event)">
                </app-ecare-account-entity-table-mobile>
              </div>

              <app-ecare-pagination
                [page]="search.paging.page"
                [pageSize]="search.paging.pageSize"
                [totalCount]="pagedDto?.totalItems"
                [pagesToShow]="pagedDto?.totalPages"
                (goPrev)="onPrevPage()"
                (goNext)="onNextPage()"
                (goPage)="onSpecificPage($event)"
                (pageSizeChanged)="onPageSizeChanged($event)">
              </app-ecare-pagination>
            </ng-container>
            <ng-template #no_results>
              <app-ecare-listing-no-results></app-ecare-listing-no-results>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
