<app-cookie-policy-bar
  *ngIf="showCookieBar()"
  [availableCookieAgreements]="availableCookieAgreements"></app-cookie-policy-bar>

<footer class="footer" [style.margin-bottom.px]="footerOffsetBottom">
  <div class="container">
    <div class="footer__main">
      <div *ngIf="eshopMenuItems?.length" class="-column">
        <div class="-column_title">{{ 'wc.shopping.eshopLinks.title' | translate }}</div>
        <div
          *ngFor="let eshopLink of eshopMenuItems"
          class="-column_item --link"
          (click)="navigateToLink(eshopLink.targetUrl)">
          {{ eshopLink.title }}
        </div>
      </div>

      <div *ngIf="userNavigationMenuItems?.length" class="-column">
        <div class="-column_title">{{ 'wc.shopping.userNavigationLinks.title' | translate }}</div>
        <div
          *ngFor="let userNavLink of userNavigationMenuItems"
          class="-column_item --link"
          (click)="navigateToLink(userNavLink.targetUrl)">
          {{ userNavLink.title }}
        </div>
      </div>

      <div class="-column">
        <div class="-column_title">{{ 'wc.shopping.otherLinks.title' | translate }}</div>
        <div
          *ngFor="let content of footerContents"
          class="-column_item --link"
          (click)="navigateToLink('/' + getContentSeoUrl(content))">
          {{ getContentHrefText(content) }}
        </div>
        <div *ngIf="availableCookieAgreements?.length" class="-column_item --link" (click)="openCookiePolicySettings()">
          {{ 'wc.shopping.footer.cookiePolicySettings' | translate }}
        </div>
      </div>

      <div class="-column">
        <div class="-column_title">{{ 'wc.shopping.headOffice.title' | translate }}</div>
        <div class="-column_item">{{ 'wc.shopping.headOffice.address' | translate }}</div>
        <div class="-column_item">{{ 'wc.shopping.headOffice.phoneNo' | translate }}</div>
        <div class="-column_item">{{ 'wc.shopping.headOffice.email' | translate }}</div>
      </div>
    </div>
    <hr class="footer__separator" />
  </div>

  <div class="container">
    <div class="footer__bottom">
      <div class="footer__slogan">{{ 'wc.shopping.footer.slogan' | translate }}</div>

      <div class="footer__social-icons">
        <!-- todo: social links should be rendered according to CB configuration (https://emeldi-commerce.atlassian.net/browse/BTL-5448?focusedCommentId=232709) -->
        <i
          class="footer__social-icon"
          [inlineSVG]="'/assets/svg/footer/icon-facebook.svg'"
          (click)="openSocialLink('https://facebook.com/IPKO.Rrethiyt/?ref=hl&_rdr')"></i>
        <i
          class="footer__social-icon"
          [inlineSVG]="'/assets/svg/footer/icon-twitter.svg'"
          (click)="openSocialLink('https://twitter.com/ipkotelecom')"></i>
        <i
          class="footer__social-icon"
          [inlineSVG]="'/assets/svg/footer/icon-youtube.svg'"
          (click)="openSocialLink('https://www.youtube.com/user/IPKOReklamat/featured')"></i>
        <i
          class="footer__social-icon"
          [inlineSVG]="'/assets/svg/footer/icon-instagram.svg'"
          (click)="openSocialLink('https://www.instagram.com/ipko.rrethiyt/')"></i>
        <i
          class="footer__social-icon"
          [inlineSVG]="'/assets/svg/footer/icon-linkedin.svg'"
          (click)="openSocialLink('https://www.linkedin.com/company/ipkotelecommunications/')"></i>
      </div>
    </div>
  </div>
</footer>
