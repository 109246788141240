import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-ecare-fix-internet-tariff-widget, [app-ecare-fix-internet-tariff-widget]',
  templateUrl: './ecare-fix-internet-tariff-widget.component.html'
})
export class EcareFixInternetTariffWidgetComponent extends EcareAbstractTariffWidgetComponent {
  upSpeed;
  dnSpeed = 0;

  ngOnInit() {
    super.ngOnInit();
  }

  protected initTariffData() {
    super.initTariffData();
    const upSpeedParamNames = this.group.groupParams['widget_UpSpeedParamNames'];
    const dnSpeedParamNames = this.group.groupParams['widget_DnSpeedParamNames'];
    this.upSpeed = Number.parseInt(this.getFirstFoundParameterValue(this.subscription.assets[0], upSpeedParamNames));
    this.dnSpeed = Number.parseInt(this.getFirstFoundParameterValue(this.subscription.assets[0], dnSpeedParamNames));
  }

}
