<div class="tile -tile12">
  <div class="tile_inner">
    <div class="tile_headline toggle_details" (click)="isCollapsed = !isCollapsed">
      <div class="{{ isCollapsed ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down' }}"></div>
      {{ service['product']['name'] }}
    </div>
    <div [ngbCollapse]="!isCollapsed">
      <div class="tile_content">
        <table class="tariff_table">
          <tr class="tariff_row">
            <td>
              {{ 'wc.ecare.dashboard.identification.label' | translate }}
            </td>
            <td>
              {{ service.id }}
            </td>
            <td></td>
          </tr>
        </table>

        <p>
          <a *ngIf="service['state'] !== 'DEACTIVATED'" id="001_standalone_tile" class="link">
            {{ 'wc.ecare.dashboard.serviceDeactivation.label' | translate }}
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
