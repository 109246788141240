import { Component, HostListener, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AuthFactoryService,
  CodebookService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructFilterMiner,
  ControlStructSingletonFilterMiner,
  EnumsService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { AssignmentStateDto, BusinessStateDto, DynamicEnumService, EnumEntryDto, TicketTypeDto } from '@btl/order-bff';
import moment_ from 'moment';
import { takeUntil } from 'rxjs/operators';
import { from } from 'rxjs/internal/observable/from';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-filter',
  templateUrl: './ticket-filter.component.html',
})
export class TicketFilterComponent extends ComplexFilter {
  moment = moment_;

  @Input() cuRefNo;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  userLogin;
  userRoles;

  assignedToMode: 'MY' | 'TEAM' | 'ALL' = null;

  protected advancedFilterFields = [
    'area',
    'type',
    'assignmentState',
    'businessState',
    'assignedTo',
    'owner',
    'ticketRefNo',
    'createdFrom',
    'createdTo',
    'followUpDateFrom',
    'followUpDateTo',
    'entityType',
    'entity',
    'priority',
    'slaDueDate',
  ];

  readonly SOURCE_NAME = 'ticket';
  readonly TICKET_AREA_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketAreaType';
  readonly EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.ExternalEntityType';

  public ticketAreaTypes: EnumEntryDto[];
  public externalEntityTypes: EnumEntryDto[];
  public ticketTypes: TicketTypeDto[];
  public filteredByAreaTicketTypes: string[] = [];
  public assignmentStates: AssignmentStateDto[];
  public businessStates: BusinessStateDto[];

  filterForm = this.formBuilder.group({
    subject: [],
    area: [],
    type: [],
    assignmentState: [],
    businessState: [],
    assignedTo: [],
    team: [],
    owner: [],
    ticketRefNo: [],
    createdFrom: [],
    createdTo: [],
    followUpDateFrom: [],
    followUpDateTo: [],
    entityType: [],
    entity: [],
    priority: [],
    slaDueDate: [],
    slaDueDateCheckBox: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.subject, 'subject', CompareTypeDtoEnum.LIKE),

    new ControlStructFilterMiner(this.filterForm.controls.type, 'type', 'code', null),
    new ControlStructFilterMiner(this.filterForm.controls.area, 'type', 'areaType', null),

    new ControlFilterMiner(this.filterForm.controls.assignmentState, 'assignmentState', CompareTypeDtoEnum.IN),
    new ControlFilterMiner(this.filterForm.controls.businessState, 'businessState', CompareTypeDtoEnum.IN),
    new ControlFilterMiner(this.filterForm.controls.assignedTo, 'assignedTo', CompareTypeDtoEnum.IN),

    new ControlFilterMiner(this.filterForm.controls.team, 'teamsTickets', null),

    new ControlFilterMiner(this.filterForm.controls.owner, 'owner', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.ticketRefNo, 'id'),

    new ControlFilterMiner(this.filterForm.controls.createdFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdTo, 'created', CompareTypeDtoEnum.SMALLEREQ),
    new ControlFilterMiner(this.filterForm.controls.followUpDateFrom, 'followUpDate', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.followUpDateTo, 'followUpDate', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.slaDueDate, 'slaDueDate', CompareTypeDtoEnum.SMALLEREQ),

    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityType, 'references', 'entityType', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entity, 'references', 'entityId', null),
    new ControlFilterMiner(this.filterForm.controls.priority, 'priority'),
  ];

  constructor(
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private authFactoryService: AuthFactoryService,
    private enumService: EnumsService,
    private dynamicEnumService: DynamicEnumService,
    private ticketingService: TicketingService
  ) {
    super();
    this.loadTicketAreaTypes();
    this.loadExternalEntityTypes();
    this.loadTicketTypes();
    this.loadAssignmentStates();
    this.loadBusinessStates();
  }

  ngOnInit() {
    from(this.authFactoryService.getAuthService().getUserInfo()).subscribe(userProfile => {
      this.userLogin = userProfile.username;
      this.userRoles = this.authFactoryService.getAuthService().getUserRoles();
      this.filterForm.controls['assignedTo'].patchValue(this.userLogin);

      this.filterForm.controls.slaDueDateCheckBox.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(value => {
          this.changeFilterField('slaDueDateCheckBox', value);
          this.slaDueDateCheckBoxChanged(value);
        });
      if (this.cuRefNo) {
        this.filterMiners = this.filterMiners.filter(filter => filter.column !== 'references');
      }

      super.ngOnInit(true);

      this.assignedToChanged(this.userLogin);
    });
  }

  protected getDefaultFilterValues() {
    return {
      id: '',
      subject: '',
      area: '',
      type: '',
      assignmentState: [],
      businessState: [],
      assignedTo: '',
      owner: '',
      ticketRefNo: '',
      createdFrom: null,
      createdTo: null,
      followUpDateFrom: null,
      followUpDateTo: null,
      entityType: '',
      entity: '',
      priority: '',
      slaDueDate: '',
      slaDueDateCheckBox: false,
    };
  }

  slaDueDateCheckBoxChanged(event) {
    if (event) {
      const dateTime = this.moment(new Date(), 'DD.MM.YYYY').utc(false);
      this.filterForm.controls.slaDueDate.patchValue(dateTime.format());
    } else {
      this.filterForm.controls.slaDueDate.patchValue(null);
    }
  }

  private loadTicketAreaTypes() {
    this.dynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.TICKET_AREA_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
      this.ticketAreaTypes = result.data;
    });
  }

  private loadExternalEntityTypes() {
    this.dynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.EXTERNAL_ENTITY_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
      this.externalEntityTypes = result.data;
    });
  }

  private loadTicketTypes() {
    this.ticketingService.getTicketTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
      this.ticketTypes = result;
    });
  }

  private loadAssignmentStates() {
    this.ticketingService.getAssignmentStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
      this.assignmentStates = result.states;
    });
  }

  private loadBusinessStates() {
    this.ticketingService.getBusinessStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
      this.businessStates = result.states;
    });
  }

  typeChanged(type) {
    if (type === null) return;
    let a = null;
    this.ticketTypes.forEach(t => {
      if (t.code === type) {
        a = t.areaType;
      }
    });
    this.filterForm.controls.area.patchValue(a);
    this.filterTicketTypesByArea(a);
  }

  filterTicketTypesByArea(area) {
    this.filteredByAreaTicketTypes.length = 0;
    this.ticketTypes.forEach(t => {
      if (area === null || area === t.areaType) {
        this.filteredByAreaTicketTypes.push(t.code);
      }
    });
  }

  assignedToChanged(event: any) {
    if (event === this.userLogin) {
      this.assignedToMode = 'MY';
      this.filterForm.controls.assignedTo.patchValue(event);
      this.filterForm.controls.team.patchValue(null);
    } else if (event === this.userRoles) {
      this.assignedToMode = 'TEAM';
      this.filterForm.controls.assignedTo.patchValue(null);
      this.filterForm.controls.team.patchValue(event);
    } else {
      this.assignedToMode = 'ALL';
      this.filterForm.controls.assignedTo.patchValue(null);
      this.filterForm.controls.team.patchValue(null);
    }
  }
}
