<div
  class="completed d-print-none"
  [ngClass]="{
    paymentFailure: paymentTechnicalFailure || paymentFailure || paymentFailedReason || paymentCanceled,
    paymentCreated: paymentStatus === paymentStatusEnum.CREATED
  }">
  <div *ngIf="paymentParams" class="text-center">
    <ng-container
      *ngIf="paymentTechnicalFailure || paymentFailure || paymentFailedReason || paymentCanceled; else success">
      <div class="response-header">
        <i [inlineSVG]="'assets/svg/payment-error.svg'"></i>
        <h2>{{ 'wc.shopping.summaryPage.paymentProcessingError.text' | translate }}</h2>
      </div>

      <div class="response-body">
        <div class="response-body-container">
          <div *ngIf="paymentCanceled">
            <div>
              {{ 'wc.shopping.thankYou.paymentCanceled.text' | translate }}
            </div>
          </div>

          <div *ngIf="paymentFailedReason">
            <div>
              {{ paymentFailedReason }}
            </div>
          </div>

          <div *ngIf="paymentFailure">
            <div>
              {{ 'wc.shopping.generalPaymentFailure.text' | translate : paymentDetails }}
            </div>
          </div>

          <div *ngIf="paymentTechnicalFailure">
            <div>
              {{ 'wc.shopping.technicalPaymentFailure.text' | translate }}
            </div>
          </div>
        </div>
        <app-button
          variant="secondary"
          label="{{ 'wc.shopping.payAgain.button' | translate }}"
          (click)="payOrder()"></app-button>
      </div>
    </ng-container>

    <ng-template #success>
      <div>
        <div class="response-header">
          <i [inlineSVG]="'assets/svg/order-success.svg'"></i>
          <h2 id="001_payment_response">{{ 'wc.shopping.thankYou.orderPlacedSuccess.text' | translate }}</h2>
        </div>

        <div class="response-body">
          <div class="response-body-container">
            <p *ngIf="customerFirstName">
              {{ 'wc.shopping.thankYou.confirmationSent.greeting' | translate }} {{ customerFirstName }} ,
              {{ 'wc.shopping.thankYou.confirmationSent.thankYou' | translate }}.
            </p>
            <p *ngIf="!customerFirstName">{{ 'wc.shopping.thankYou.confirmationSent.thankYou' | translate }}.</p>
            <p>
              {{
                (orderDto && orderDto.orderStateType === orderStateTypeEnum.CONFIRMED
                  ? 'wc.shopping.thankYou.confirmationSent.text'
                  : 'wc.shopping.thankYou.orderSentToBackOffice.text'
                ) | translate
              }}
              <span *ngIf="customerEmail"
                >{{ 'wc.shopping.thankYou.confirmationSent.emailAddress' | translate }}
                <strong>{{ customerEmail }}</strong></span
              >
            </p>
          </div>
          <app-button label="Return to store" link="/" variant="secondary" size="shrink"></app-button>
        </div>

        <div class="response-footer">
          <p>{{ 'wc.shopping.thankYou.resendEmail.question' | translate }}?</p>
          <p>
            <u>{{ 'wc.shopping.thankYou.resendEmail.text' | translate }}</u>
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
