<div>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
      <div class="-filter-fields -filter-fields-group">
        <input
          *ngIf="!ecareSearch"
          id="002_orders_filter"
          type="text"
          formControlName="id"
          class="filter-input-id"
          placeholder="{{ 'wc.ecare.ordersFilter.id.placeholder' | translate }}" />

        <div app-acl-internal-evaluate-component>
          <app-advanced-filter
            clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
            [form]="filterForm"
            [isAdvancedFilterOpen]="isAdvancedFilterOpen"
            [changedFilterFieldsCount]="changedFilterFieldsCount"
            (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
            (resetFieldsEmitter)="resetFilterFields()">
            <app-advanced-filter-field
              labelLocalizedKey="wc.ecare.ordersFilter.status.defaultFilter"
              controlName="status"
              [fieldType]="'select'"
              optionLabel="labelKey"
              optionValue="value"
              [selectOptions]="orderStateTypes"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              labelLocalizedKey="wc.ecare.ordersFilter.channel"
              controlName="channel"
              optionLabel="labelKey"
              optionValue="code"
              [fieldType]="'select'"
              [selectOptions]="orderChannels"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              labelLocalizedKey="wc.ecare.ordersFilter.extId"
              controlName="extId"
              idAttribute="008_orders_filter"
              placeholderLocalizedKey="wc.ecare.ordersFilter.extId.placeholder"
              [fieldType]="'input'"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)" />
            <app-advanced-filter-field></app-advanced-filter-field>
            <app-advanced-filter-field
              labelLocalizedKey="wc.ecare.ordersFilter.stateDatePeriod"
              controlName="stateDateFrom"
              startOfTheDay="true"
              placeholderLocalizedKey="wc.ecare.ordersFilter.stateDatePeriod.placeholder.startDate"
              [fieldType]="'date'"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              controlName="stateDateTo"
              endOfTheDay="true"
              placeholderLocalizedKey="wc.ecare.ordersFilter.stateDatePeriod.placeholder.endDate"
              [fieldType]="'date'"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              labelLocalizedKey="wc.common.filter.created.header"
              controlName="createdFrom"
              startOfTheDay="true"
              placeholderLocalizedKey="wc.common.filter.createdFrom.label"
              [fieldType]="'date'"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              controlName="createdTo"
              endOfTheDay="true"
              placeholderLocalizedKey="wc.common.filter.createdTo.label"
              [fieldType]="'date'"
              (changeFilterFieldAction)="changeFilterField($event.controlName, $event)" />
            <app-advanced-filter-field
              *ngIf="isModuleBffCrmEnabled && !currentCustomer"
              fullWidth="true"
              labelLocalizedKey="wc.ecare.ordersFilter.customer"
              additionalCss="relative">
              <app-ecare-filter-autocomplete-box
                #customerSearch
                [searchedEntity]="'Customer'"
                (handleChangeEvent)="handleAutocompleteSearch(false, $event)" />
            </app-advanced-filter-field>
            <app-advanced-filter-field
              *ngIf="isModuleBffCrmEnabled && !customerLocalStorageService.getCurrentCustomerAccount()"
              fullWidth="true"
              labelLocalizedKey="wc.ecare.ordersFilter.customerAccount"
              additionalCss="relative">
              <ng-container *ngIf="cAStructure.length > 0; else isCASearch">
                <div class="input select">
                  <ng-select
                    type="text"
                    class="ecare-dropdown disabled-feel"
                    formControlName="customerAccountId"
                    [clearable]="false"
                    [clearOnBackspace]="false"
                    [searchable]="false"
                    (change)="handleAutocompleteSearch($event !== lastSearchedCu, $event, $event === lastSearchedCu)">
                    <ng-option [value]="lastSearchedCu">Search Customer Account</ng-option>
                    <ng-option *ngFor="let ca of cAStructure" [value]="ca.id">
                      {{ ca.displayName }}
                    </ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-template #isCASearch>
                <app-ecare-filter-autocomplete-box
                  #customerAccountSearch
                  [searchedEntity]="'CustomerAccount'"
                  (handleChangeEvent)="handleAutocompleteSearch(true, $event)" />
              </ng-template>
            </app-advanced-filter-field>
          </app-advanced-filter>
        </div>

      </div>
      <app-button
        id="004_orders_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()" />
    </form>
  </div>
</div>
