import moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { CodebookParamDto } from '@btl/order-bff';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DateService implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  private static readonly publicHolidaysStorageName = 'publicHolidays';
  private static readonly PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY';
  private static readonly PUBLIC_HOLIDAY_DATE_PARAMETER = 'PUBLIC_HOLIDAY_DATE';
  private static readonly PUBLIC_HOLIDAY_DATE_FORMAT = 'DD.MM.YYYY';
  private holidaysSubject: ReplaySubject<string[]> = new ReplaySubject<string[]>();
  private isSubscribed: boolean = false;

  constructor(private codebookService: CodebookService) {}

  public getHolidaysSubject(): ReplaySubject<string[]> {
    return this.holidaysSubject;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public isWorkingDay(date: Date, holidays): boolean {
    return !this.isWeekend(date) && !this.isPublicHoliday(date, holidays);
  }

  public isWeekend(date: Date): boolean {
    return [0, 6].some(day => day === date.getDay());
  }

  public getHolidaysFromCodebook() {
    if (localStorage.getItem(DateService.publicHolidaysStorageName)) {
      this.holidaysSubject.next(JSON.parse(localStorage.getItem(DateService.publicHolidaysStorageName)));
    } else {
      const holidays$ = this.codebookService
        .getCodebooks(DateService.PUBLIC_HOLIDAY)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(
          map(codebookDtos => {
            const holidays = new Array<any>();
            codebookDtos.forEach(codebookDto => {
              const holidayParams: CodebookParamDto[] = codebookDto.parameters.filter(
                param => param.name === DateService.PUBLIC_HOLIDAY_DATE_PARAMETER
              );
              if (holidayParams.length !== 0) {
                holidays.push(moment.utc(holidayParams[0].value, DateService.PUBLIC_HOLIDAY_DATE_FORMAT).toDate());
              }
            });
            return holidays;
          }),
          tap(holidays => {
            localStorage.setItem(DateService.publicHolidaysStorageName, JSON.stringify(Array.from(holidays)));
          })
        );
      if (!this.isSubscribed) {
        this.isSubscribed = true;
        holidays$.subscribe(holidays => this.holidaysSubject.next(holidays));
      }
    }
  }

  public isPublicHoliday(date: Date, holidays): boolean {
    return holidays.some(publicHoliday => this.isSameDay(date, new Date(publicHoliday)));
  }

  public isToday(date: Date): boolean {
    const today = new Date();
    return this.isSameDay(date, today);
  }

  public isTomorrow(date: Date): boolean {
    const tomorrow = this.addDays(new Date(), 1);
    return this.isSameDay(date, tomorrow);
  }

  public isSameDay(firstDay: Date, secondDate: Date): boolean {
    return (
      firstDay.getFullYear() === secondDate.getFullYear() &&
      firstDay.getMonth() === secondDate.getMonth() &&
      firstDay.getDate() === secondDate.getDate()
    );
  }

  public addDays(date: Date, numberOfDays: number): Date {
    return moment(date).add(numberOfDays, 'd').toDate();
  }

  /**
   * If given date is a working day, returns the date. Otherwise, returns the next closest working day.
   * A working day is a day that is not a weekend nor a public holiday.
   * @param date
   */
  public getWorkingDay(date: Date, holidays): Date {
    while (!this.isWorkingDay(date, holidays)) {
      date = this.addDays(date, 1);
    }
    return date;
  }
}
