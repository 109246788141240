import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Constants } from '../../../../constants';
import { ProductElasticFilter, SortTypeEnum } from '../../../../models/product-elastic-filter';
import { FilterService } from '@service/filter.service';
import { UrlParamsService } from '@service/url-params.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sorting,[app-sorting]',
  templateUrl: './sorting.component.html',
})
export class SortingComponent implements OnInit, OnDestroy {
  public productFilter: ProductElasticFilter;
  private paramsSubscription: Subscription;
  public form = this.fb.group({
    sort: ['RECOMMENDED'],
  });

  constructor(
    private filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private urlParamsService: UrlParamsService,
    private fb: FormBuilder,
  ) {}

  /**
   * Total number of products
   */
  @Input()
  totalResults: number;

  currentSorting: string;

  sortings = [
    { value: 'RECOMMENDED', label: 'recommended' },
    { value: 'CHEAPEST', label: 'cheapFirst' },
    { value: 'MOST_EXPENSIVE', label: 'expensiveFirst' },
    { value: 'NEWEST', label: 'newestFirst' },
    { value: 'MOST_BOUGHT', label: 'mostBoughtFirst' },
  ];

  ngOnInit() {
    this.productFilter = this.filterService.getProductFilter();
    this.form.controls.sort.patchValue(this.productFilter.sorting);
    this.paramsSubscription = this.route.queryParams.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(params => {
      const sorting = this.urlParamsService.getSort(params);
      this.changeSortingFilter(sorting);
    });
  }

  private changeSortingFilter(sorting) {
    if (sorting && sorting !== this.productFilter.sorting.toString()) {
      if (SortTypeEnum[sorting]) {
        this.productFilter.sorting = <SortTypeEnum>SortTypeEnum[sorting];
        this.form.controls.sort.patchValue(this.productFilter.sorting);
      } else {
        console.error(`Sorting type not found: ${sorting}`);
      }
      this.filterService.changeSorting();
    }
    this.currentSorting = this.productFilter.sorting.toString().replace(/_/g, ' ').toLowerCase();
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  public changeSorting(sorting?: string) {
    const url = this.router.createUrlTree(['.'], {
      relativeTo: this.route,
      queryParams: { sort: SortTypeEnum[sorting] },
    });
    this.location.go(url.toString());
    this.changeSortingFilter(sorting);
  }
}
