<div
  *ngIf="form"
  [class.section]="sectionView"
  [hidden]="inputMode && addressFormComponent && !addressFormComponent.formVisibility">
  <div class="inner" [formGroup]="form">
    <input id="001_address_form" formControlName="id" type="hidden"/>

    <app-section-header
      *ngIf="showHeader"
      id="collapseHeader"
      [title]="'wc.shopping.address.' + type + '.heading' | translate"
      [isCollapsed]="refEl.activeIds.length > 0"
      (click)="acc.toggle('toggleCategories')"
      rightText="{{inputMode ? null : (sameAsTranslationKey ? sameAsTranslationKey : 'wc.shopping.address.sameAsRegistration.label') | translate }}">
    </app-section-header>
    <ngb-accordion #acc="ngbAccordion" #refEl activeIds="toggleCategories" [destroyOnHide]="false">
      <ngb-panel id="toggleCategories">
        <ng-template ngbPanelContent>
          <ng-template [ngIf]="main === false">
            <div class="checkbox">
              <input
                type="checkbox"
                [checked]="inputMode === false"
                [disabled]="disabled"
                id="check_address_{{ type }}"
                (change)="setSameAs($event.target.checked)"/>
              <label for="check_address_{{ type }}">
                {{(sameAsTranslationKey ? sameAsTranslationKey : 'wc.shopping.address.sameAsRegistration.label') | translate }}
              </label>
            </div>
          </ng-template>
          <div
            *ngIf="entityGenerateForm"
            [ngClass]="{ 'customer_section -subsection': sectionView }"
            [hidden]="inputMode !== true && !sameAsObject">
            <app-entity-generated-form
              [hide]="hide"
              #addressEntityGeneratedForm
              sourceName="account"
              entityType="Address"
              [formName]="formName"
              [formIdPrefix]="formIdPrefix + '.address.' + type"
              [form]="form"
              [formGroup]="form"
              [resourceIdentification]="{ addressType: type }"
              [defaultValues]="{
                type: type
              }"
              [context]="{}"
              [validate]="(validate && inputMode) || main"
              [checkFormVisibility]="checkFormVisibility"
              [columnsCount]="columnsCount"
              (formGeneratedEmitter)="addressFormGenerated()">
            </app-entity-generated-form>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
