<div class="dashboard_wrapper">
  <div class="dashboard_search">
    <ng-container>
      <div class="">
        <app-contact-history-filter [filtering]="search.filtering" (filterChanged)="handleFilterChange()">
        </app-contact-history-filter>
      </div>
      <div class="customers-contact">
        <div class="customers-contact-table">
          <ng-container *ngIf="contacts.length > 0">
            <table class="ecare_listing-table">
              <thead>
                <tr>
                  <th>{{ 'wc.ecare.contact.type' | translate }}</th>
                  <th>{{ 'wc.ecare.contact.role' | translate }}</th>
                  <th>{{ 'wc.ecare.contact.firstName' | translate }}</th>
                  <th>{{ 'wc.ecare.contact.lastName' | translate }}</th>
                  <th>{{ 'wc.ecare.contact.phone' | translate }}</th>
                  <th>{{ 'wc.ecare.contact.email' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of contacts" class="ecare_listing-table-row">
                  <td>
                    <span class="information">{{ contact.type }}</span>
                  </td>
                  <td>
                    <app-sticker variant="neutral">{{ contact.role }}</app-sticker>
                  </td>
                  <td>
                    <span class="highlight">{{ contact.firstName }}</span>
                  </td>
                  <td>
                    <span class="highlight">{{ contact.lastName }}</span>
                  </td>
                  <td>
                    <div class="prefferedChannel">
                      <span>{{ contact.phone }}</span>
                      <div *ngIf="contact.channel === 'EMAIL' || contact.channel === 'ALL'">
                        <i
                        tooltipClass="ecare-settings-tooltip"
                        placement="bottom"
                        ngbTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}"
                        [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="prefferedChannel">
                      <span>{{ contact.email }}</span>
                      <div *ngIf="contact.channel === 'SMS' || contact.channel === 'ALL'">
                        <i
                        tooltipClass="ecare-settings-tooltip"
                        placement="bottom"
                        ngbTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}"
                        [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
        <app-customer-contacts-mobile
          class="customers-contact-mobile"
          [contacts]="contacts"></app-customer-contacts-mobile>
      </div>
      <div *ngIf="moreRecordsExists">
        {{ 'wc.ecare.contacts.scrollDownForMoreRecords.text' | translate }}
      </div>
      <div *ngIf="canDisplayNoDataText" class="no-invoices-text">
        {{ 'wc.ecare.contacts.noContacts.text' | translate }}
      </div>
    </ng-container>
  </div>
</div>
