<div class="pagination">
  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [ngClass]="{ disabled: page === 1 }">
        <a id="001_pagination" class="page-link" tabindex="-1" (click)="onPrev()">
          <span class="prev-arrow">&lt;</span>
          {{ 'wc.shopping.pagination.previous' | translate }}
        </a>
      </li>

      <ng-container *ngFor="let pageNo of getPages(); let i = index">
        <li class="page-item" [ngClass]="{ active: pageNo === page }">
          <a class="page-link" id="{{ i }}_002_pagination" (click)="onPage(pageNo)">
            {{ pageNo }}
          </a>
        </li>
      </ng-container>

      <li class="page-item" [ngClass]="{ disabled: lastPage() }">
        <a id="003_pagination" class="page-link" (click)="onNext(true)">
          {{ 'wc.shopping.pagination.next' | translate }} <span class="next-arrow">&gt;</span>
        </a>
      </li>
    </ul>
  </nav>
  <p *ngIf="showPageSize">{{ 'wc.shopping.pagination.pageSize' | translate }}</p>
  <div *ngIf="showPageSize" class="pageSizeOptions" [formGroup]="pageSizeForm">
    <select id="004_pagination" formControlName="pageSizeOption" class="select">
      <option *ngFor="let loopPageSize of getPageSizeOptions()" [ngValue]="loopPageSize">
        {{ loopPageSize }}
      </option>
    </select>
  </div>
</div>
