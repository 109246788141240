import { Component, HostListener } from '@angular/core';
import { from } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import {
  AccountService,
  AclService,
  AuthFactoryService,
  AuthService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
  CurrentLocaleService,
  EnumsService,
  ServiceUtils,
  UserInfo
} from '@btl/btl-fe-wc-common';
import { AccountDto, DynamicEnumService, EnumEntryDto, NotificationTypeFrontendService, RoleDto } from '@btl/order-bff';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-ecare-account-entity-filter',
  templateUrl: './ecare-account-entity-filter.component.html',
})
export class EcareAccountEntityFilterComponent extends ComplexFilter {
  protected advancedFilterFields = ['delegatedByLogin', 'entityType', 'entityId', 'validAt', 'role'];

  entityTypes: EnumEntryDto[];
  roles: RoleDto[];
  readonly EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.account.enums.ExternalEntityType';
  readonly SOURCE_NAME = 'account';

  filterForm = this.formBuilder.group({
    accountLogin: [],
    delegatedByLogin: [],
    entityType: [],
    entityId: [],
    validAt: [],
    role: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.accountLogin, 'login'),
    new ControlFilterMiner(this.filterForm.controls.delegatedByLogin, 'delegatedByAccountLogin'),

    new ControlStructSingletonFilterMiner(
      this.filterForm.controls.entityType,
      'entities',
      'entityType',
      CompareType.CompareTypeDtoEnum.EQUAL
    ),
    new ControlStructSingletonFilterMiner(
      this.filterForm.controls.entityId,
      'entities',
      'entityId',
      CompareType.CompareTypeDtoEnum.EQUAL
    ),

    new ControlFilterMiner(this.filterForm.controls.validAt, 'validAt'),
    new ControlFilterMiner(this.filterForm.controls.role, 'roleType'),
  ];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  constructor(
    private formBuilder: FormBuilder,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private dynamicEnumService: DynamicEnumService,
    private aclService: AclService,
    private authService: AuthService,
    private authFactoryService: AuthFactoryService,
    private accountService: AccountService,
    private enumsService: EnumsService,
    private notificationTypeFrontendService: NotificationTypeFrontendService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super();
    this.loadExternalEntityTypes();
    this.loadRoles();
  }

  private loadExternalEntityTypes() {
    this.dynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.EXTERNAL_ENTITY_TYPE_ENUM_NAME).subscribe(result => {
      this.entityTypes = result.data;
    });
  }

  private loadRoles() {
    from(this.authFactoryService.getAuthService().getUserInfo())
      .pipe(switchMap((userInfo: UserInfo) => this.accountService.getAccountByLogin(userInfo.username)))
      .subscribe((account: AccountDto) => {
        this.getRoles(account.external);
      });
  }

  private getRoles(isExternal: boolean) {
    const search = ServiceUtils.getUnlimitedSearch();
    if (isExternal) {
      search.filtering.push({
        column: 'external',
        compareType: CompareType.CompareTypeDtoEnum.EQUAL,
        value: true,
      });
    }
    this.aclService.filterRoles(search, null).subscribe(result => {
      this.roles = result.data;
    });
  }
}
