import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { OrderDto } from '@btl/order-bff';
import _ from 'lodash';
import { ColorService } from './color.service';
import { PropertyAccessorLocalService } from './property-accessor-local.service';
import { WcOrderingService } from './wc-ordering.service';
import { ShoppingCartService } from './shopping-cart.service';
import { Router } from '@angular/router';
import { ProductComparisonService } from './product-comparison.service';
import { Subscription } from 'rxjs';
import { ProductUtils } from '../helpers/product-utils';
import { NavigationMenuService } from './navigation-menu.service';

@Injectable({
  providedIn: 'root',
})
export class ProductListingService {
  private maxProductsSubsription: Subscription;
  public colorMap: Map<string, { name: string; rgb: string }>;
  public keyFeaturesCount: number;
  public allSubscriptions: Subscription[] = new Array<Subscription>();
  maxProductsInComparison;

  constructor(
    private colorService: ColorService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private router: Router,
    private productComparisonService: ProductComparisonService,
    private navigationMenuService: NavigationMenuService
  ) {}

  addProductToComparison(product: Product, maxProductsInComparison: number) {
    this.productComparisonService.addToComparison(product, maxProductsInComparison);
  }

  isProductInComparison(product) {
    return this.productComparisonService.isProductInComparison(product.seoUrl);
  }

  removeProductFromComparison(product) {
    this.productComparisonService.removeFromComparison(product.seoUrl);
  }

  getPriceRC(productPrice) {
    let rcPrice = 0;
    productPrice.forEach(price => {
      if (price.priceType === 'RC') {
        rcPrice = price.value;
      }
    });
    return rcPrice;
  }

  /**
   * Handle adding a product to the shopping cart.
   *
   * @param {string} productId The code of the product to be added.
   * @param originalProductPartyId
   * @param categoryId
   * @param partyId
   * @param parentInstanceId
   * @param parentProductId
   */
  handleProductAddingToCart(
    product: ProductAddingToCart,
    originalProductPartyId,
    partyId,
    parentInstanceId,
    parentProductId,
    checkScanRequired?: boolean,
    isAddToCart?: boolean
  ): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      if (checkScanRequired)
        return this.router.navigate(['/eshop/technology-check'], {
          queryParams: {
            productId: product.id,
            originalProductPartyId,
            categoryId: product.categoryId,
            partyId,
            parentInstanceId,
            parentProductId
          },
        });

      const navigateToShoppingCart = (order: OrderDto) => {
        if (order) {
          isAddToCart
            ? this.navigationMenuService.openShoppingCartPopup.emit()
            : this.router.navigate(['eshop/shopping-cart']);
        }
      };

      if (!_.isNil(originalProductPartyId)) {
        //tariff change
        this.orderingService
          .addProductToShoppingCartWithParent(
            currentOrder,
            product,
            null,
            null,
            originalProductPartyId,
            'MERGE'
          )
          .subscribe(navigateToShoppingCart);
      } else {
        this.orderingService
          .addProductToShoppingCartWithParent(
            currentOrder,
            product,
            parentProductId,
            parentInstanceId,
            partyId
          )
          .subscribe(navigateToShoppingCart);
      }
    };
    this.orderingService.getCurrentOrder().subscribe(addOrderItem);
  }

  initListing() {
    //color variants
    this.colorService.getColorsFromCodebook();
    const colorsSusbscription = this.colorService.getColors().subscribe(value => {
      this.colorMap = value;
    });
    this.allSubscriptions.push(colorsSusbscription);

    //key features
    const keyFeatureSubscription = this.propertyAccessorLocalService.getKeyFeaturesCount().subscribe(value => {
      this.keyFeaturesCount = value;
    });

    this.maxProductsSubsription = this.propertyAccessorLocalService
      .getMaxComparisonHwCount()
      .subscribe(maxComparisonHwCount => (this.maxProductsInComparison = maxComparisonHwCount));

    this.allSubscriptions.push(keyFeatureSubscription);
  }

  /**
   * Get localized text key for "Buy" button.
   */
  buyButtonLabel(originalProductPartyId): string {
    if (!_.isNil(originalProductPartyId)) {
      return 'wc.common.changeTariff.button';
    } else {
      return 'wc.common.buyNow.button';
    }
  }

  hasLinkToDetail(product): boolean {
    return ProductUtils.hasLinkToDetail(product);
  }

  public static asProductAddingToCart(product: Product): ProductAddingToCart {
    return {
      id: product.verCode,
      productCode: product.id,
      categoryId: product.categoryId,
      parametersStatic: product.parameters
    }
  }
}

export interface ProductAddingToCart {
  id: string,
  productCode: string,
  categoryId: string,
  parametersStatic: {},
}
