<div class="app-sticker-wrapper">
  <div
    class="app-sticker"
    [ngClass]="{
      default: variant === 'default',
      warning: variant === 'warning',
      inform: variant === 'inform',
      success: variant === 'success',
      important: variant === 'important',
      danger: variant === 'danger',
      neutral: variant === 'neutral',
      withIcon: iconPath !== undefined
    }">
    <i *ngIf="iconPath !== undefined" [inlineSVG]="iconPath"></i>
    {{ label }}
    <ng-content></ng-content>
    <i *ngIf="iconPathAfter !== undefined" class="iconAfter" [inlineSVG]="iconPathAfter"></i>
  </div>
</div>
