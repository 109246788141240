import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import _ from 'lodash';
import { OrderUtils } from './order-utils';
import { CookieService } from 'ngx-cookie-service';
import { OrderParamDto } from '@btl/order-bff';

@Injectable()
export class SystemIdInterceptor {
  readonly extIdEvent: EventEmitter<any> = new EventEmitter<any>();

  init(activatedRoute: ActivatedRoute, orderingService: WcOrderingService, cookieService: CookieService) {
    return new Promise<void>((resolve, reject) => {
      activatedRoute.queryParams.subscribe((value: Params) => {
        // 1. check if extIds present in the URL. If not present than no change.
        if (!_.isEmpty(value)) {
          if (value['extSystemId'] && value['extSystemId'] !== cookieService.get('extSystemId')) {
            cookieService.set('extSystemId', value['extSystemId'], 30, '/', null, false, 'Lax');
            this.extIdEvent.emit();
          }
          if (value['extSystemTrxId'] && value['extSystemTrxId'] !== cookieService.get('extSystemTrxId')) {
            cookieService.set('extSystemTrxId', value['extSystemTrxId'], 30, '/', null, false, 'Lax');
            this.extIdEvent.emit();
          }
        }

        // 2. update the extId order attributes if necessary
        const currentOrder = orderingService.getCurrentOrderFromSession();
        if (!_.isEmpty(currentOrder)) {
          let updated = false;

          const extSystemIdFromOrder = OrderUtils.getOrderAttributeValue(currentOrder, 'extSystemId');
          const extSystemTrxIdFromOrder = OrderUtils.getOrderAttributeValue(currentOrder, 'extSystemTrxId');

          const extSystemIdFromCookie = cookieService.get('extSystemId');
          const extSystemTrxIdFromCookie = cookieService.get('extSystemTrxId');

          const orderParamsDto: Array<OrderParamDto> = [];

          // 2.1 update value in the order - can even delete the original value
          if (extSystemIdFromOrder !== extSystemIdFromCookie && (extSystemIdFromOrder || extSystemIdFromCookie)) {
            updated = true;
            OrderUtils.updateOrderAttr(orderParamsDto, 'extSystemId', extSystemIdFromCookie);
          }
          if (
            extSystemTrxIdFromOrder !== extSystemTrxIdFromCookie &&
            (extSystemTrxIdFromOrder || extSystemTrxIdFromCookie)
          ) {
            updated = true;
            OrderUtils.updateOrderAttr(orderParamsDto, 'extSystemTrxId', extSystemTrxIdFromCookie);
          }

          // 2.2 patch order if extIds updated
          if (updated) {
            const orderAsMap = {
              orderAttributes: orderParamsDto,
              recordVersion: currentOrder.recordVersion,
            };
            orderingService.patchOrder(currentOrder.id, orderAsMap).subscribe(res => {
              resolve();
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    });
  }
}
