import { Component, Input } from '@angular/core';
import { CookiePolicyService } from '@btl/btl-fe-wc-common';
import { CookieAgreementDto } from '@btl/order-bff';

@Component({
  selector: 'app-cookie-policy-settings-modal',
  templateUrl: './cookie-policy-settings-modal.component.html',
})
export class CookiePolicySettingsModalComponent {
  @Input()
  dialogRef;

  @Input()
  availableCookieAgreements: CookieAgreementDto[];

  @Input()
  moduleId: string;

  constructor(private cookiePolicyService: CookiePolicyService) {}

  getAgreementLabel(agreement: CookieAgreementDto): string {
    return this.cookiePolicyService.getCookieAgreementText(agreement, 'AgreementTypeName');
  }

  getAgreementDescription(agreement: CookieAgreementDto): string {
    return this.cookiePolicyService.getCookieAgreementText(agreement, 'AgreementTypeDescription');
  }

  isAgreementAgreed(agreement: CookieAgreementDto): boolean {
    if (agreement.mandatory) {
      return true;
    }
    return this.cookiePolicyService.getAgreedAgreementsCodes(this.moduleId).has(agreement.code);
  }

  allowAllCookies() {
    this.availableCookieAgreements.forEach(agreement => {
      this.cookiePolicyService.addCookieAgreement(this.moduleId, agreement);
    });
    this.dialogRef.dismiss();
  }

  switchAgreement(agreement: CookieAgreementDto, agree: boolean): void {
    if (agree) {
      this.cookiePolicyService.addCookieAgreement(this.moduleId, agreement);
    } else {
      this.cookiePolicyService.removeCookieAgreement(this.moduleId, agreement);
    }
  }
}
