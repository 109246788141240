import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tariffs-component',
  templateUrl: './ecare-tariffs.component.html',
})
export class EcareTariffsComponent {
  toggle: Boolean = false;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'ecare');
  }

  toggleDetail() {
    if (this.toggle === false) {
      this.toggle = true;
    } else {
      this.toggle = false;
    }
  }
}
