<ng-container *ngIf="showFakeAttributes">
  <form [formGroup]="attributesFormGroup">
    <div class="product-attributes form">
      <ng-container>
        <app-input-row
          id="001_product_attributes_configuration"
          fieldName="iccId"
          translationPrefix="wc.shopping.productAttributes"
          [showLabel]="showLabel"
          [placeholder]="placeholder"
          [parentGroup]="attributesFormGroup"
          [isSIMcard]="isSIMcard"
          [mask]="mask"
          [isDisabled]="isDisabled"
          (onChange)="valueChanged($event)">
        </app-input-row>
      </ng-container>
    </div>
  </form>
</ng-container>
