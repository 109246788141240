import {
  AfterViewChecked,
  Component,
  ContentChildren, EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {AdvancedFilterFieldComponent} from "./advanced-filter-field.component";

@Component({
  selector: 'app-advanced-filter, [app-advanced-filter]',
  templateUrl: './advanced-filter.component.html'
})


export class AdvancedFilterComponent implements AfterViewChecked {

  @Input()
  form: FormGroup;

  @Input()
  isAdvancedFilterOpen = false;

  @Input()
  changedFilterFieldsCount: number;

  @Input()
  clearHistoryTextLocalizedKey: string;

  @Output()
  resetFieldsEmitter: EventEmitter<void> = new EventEmitter<void>();

  formPassed: boolean = false;

  @Output()
  openFilterEvent = new EventEmitter<boolean>();

  @ContentChildren(AdvancedFilterFieldComponent) filterFields: QueryList<AdvancedFilterFieldComponent>;

  resetFields() {
    this.resetFieldsEmitter.emit();
  }

  ngAfterViewChecked(): void {
    if (this.filterFields && this.filterFields.length && this.form && !this.formPassed) {
      this.filterFields.forEach(filterField => {
        filterField.form = this.form;
        this.formPassed = true;
      })
    }
  }

  advancedFilterEntryClick(): void {
    this.isAdvancedFilterOpen = !this.isAdvancedFilterOpen;
    this.openFilterEvent.emit(this.isAdvancedFilterOpen);
  }


}
