<div *ngFor="let keyFeatureParam of keyFeatureParams; let i = index" class="product__detail-featuresList">
  <div class="product__detail-featureItem">
    <div *ngIf="keyFeatureParam.icon" class="product__detail-featureItem-icon">
      <i
        *ngIf="keyFeatureParam.value !== 'iOS' && keyFeatureParam.value !== 'Android'"
        [inlineSVG]="'assets/svg/keyFeatures/' + keyFeatureParam.icon + '.svg'"></i>
      <i *ngIf="keyFeatureParam.value === 'iOS'" [inlineSVG]="'assets/svg/keyFeatures/mobile-phone-fill-ios.svg'"></i>
      <i
        *ngIf="keyFeatureParam.value === 'Android'"
        [inlineSVG]="'assets/svg/keyFeatures/mobile-phone-fill-android.svg'"></i>
    </div>
    <div class="product__detail-featureItem-data">
      <strong class="product__detail-featureItem-title">{{ keyFeatureParam.value + keyFeatureParam.unit }}</strong>
      <p *ngIf="keyFeatureParam.label">{{ keyFeatureParam.label }}</p>
    </div>
  </div>
</div>
