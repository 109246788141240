import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { BasicCmsPageTemplateComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { NavigationMenuComponent } from '../../../page/navigation-menu/navigation-menu.component';

@Component({
  selector: 'app-cms-page-template-blank',
  templateUrl: './cms-page-template-blank.component.html',
})
@EnableDynamicLoading({ customName: 'BLANK_PAGE' })
export class CmsPageTemplateBlankComponent extends BasicCmsPageTemplateComponent implements AfterViewInit {
  offsetTop: number;

  @ViewChild('navigationMenu') navigationMenuComponent: NavigationMenuComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.offsetTop = this.navigationMenuComponent.headerElementHeight;
  }

  ngAfterViewInit() {
    this.offsetTop = this.navigationMenuComponent.headerElementHeight;
  }
}
