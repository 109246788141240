<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.common.error.detail' | translate }}
  </h4>
  <div class="modal-close" (click)="cancel()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="form">
    <div class="form-row">
      <div class="error__page error-modal container">
        <h2 class="error-code">{{ 'wc.common.error' | translate }} {{ errorCode }}</h2>
        <div *ngIf="causedByMessage">
          <div class="error__page-error-code">
            {{ causedByMessage }}
          </div>
        </div>
        <ng-container *ngFor="let failure of failures">
          <div class="error_page-error-name">
            {{ failure.detail }}
          </div>
          <div class="error_page-error-description">
            {{ failure.localizedDescription ? failure.localizedDescription : failure.detail }}
          </div>
          <p>
            {{ failure.stackTrace }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-button variant="primary" label="{{ 'wc.common.cancel.button' | translate }}" (click)="cancel()"></app-button>
</div>
