<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.productConfiguration.heading' | translate }}
  </h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div *ngIf="dataTypeEnums" class="modal-body">
  <form class="form" [formGroup]="form">
    <div class="generated-form">
      <div *ngFor="let param of dynamicProductParameters" class="generated-input-container">
        <label>{{ param.name | translate }}</label>
        <div class="input">
          <ng-container [ngSwitch]="param.metadata['guiElementType']">
            <input *ngSwitchCase="'boolean'" type="checkbox" class="form__check" [formControlName]="param.name"/>

            <input
              *ngSwitchCase="'integer'"
              type="number"
              pattern="\d*"
              class="input__group"
              [formControlName]="param.name"/>

            <input *ngSwitchCase="'float'" type="number" class="input__group" [formControlName]="param.name"/>

            <app-date-picker
              *ngSwitchCase="'date'"
              class="input__group date-picker"
              style="display: flex"
              [formControlName]="param.name"
              [showTime]="false">
            </app-date-picker>

            <app-date-picker
              *ngSwitchCase="'dateTime'"
              class="input__group date-picker"
              style="display: flex"
              [formControlName]="param.name"
              [showTime]="true">
            </app-date-picker>

            <app-address-search-control
              *ngSwitchCase="'address'"
              [formControlName]="param.name"
              [control]="form.get(param.name)">
            </app-address-search-control>

            <app-simple-contact-control
              *ngSwitchCase="'contactSimple'"
              [formControlName]="param.name"
              [control]="form.get(param.name)">
            </app-simple-contact-control>

            <app-multi-ip-control
              *ngSwitchCase="'multiIP'"
              [pattern]="param.validationRegex"
              [formControlName]="param.name"
              [control]="form.get(param.name)">
            </app-multi-ip-control>

            <div *ngSwitchCase="'enum'" class="input__group">
              <ng-select
                class="ecare-dropdown w-100"
                [formControlName]="param.name"
                [clearable]="false"
                [clearOnBackspace]="false"
                [searchable]="false">
                <ng-option *ngFor="let item of dataTypeEnums[param.typeDetail]?.items" [value]="item.item">
                  {{ enumDataTypeService.localizedDataTypeEnumItemText(item) }}
                </ng-option>
              </ng-select>
            </div>

            <input *ngSwitchDefault type="text" [formControlName]="param.name"/>
          </ng-container>
        </div>
        <app-input-errors
          [control]="form.controls[param.name]"
          [translationPrefix]="'wc.ecare.productConfiguration.' + param.name + '.reason'">
        </app-input-errors>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <!-- <button id="004_sim_change" type="button" class="btn btn-cancel" ngbAutofocus (click)="dialogRef.dismiss()">
      {{'wc.common.cancel.button' | translate}}
    </button>
    <button id="005_sim_change" type="button" class="btn btn-default" (click)="clear()">
      {{'wc.ecare.productConfiguration.clear.button' | translate}}
    </button>
    <button id="006_sim_change" type="button" class="btn btn-default" (click)="set()">
      {{'wc.ecare.productConfiguration.set.button' | translate}}
    </button>     -->
    <app-button
      variant="link"
      ngbAutofocus
      label="{{ 'wc.common.cancel.button' | translate }}"
      (click)="dialogRef.dismiss()"></app-button>
    <app-button
      variant="primary"
      iconPath="/assets/svg/save.svg"
      label="{{ 'wc.ecare.productConfiguration.set.button' | translate }}"
      (click)="set()"></app-button>
  </div>
</div>
