<div class="tile -tile12">
  <div class="tile_inner">
    <div class="tile_headline" (click)="toggle()">
      <div [class]="isCollapsed ? 'fa fa-angle-double-right' : 'fa fa-angle-double-down'"></div>
      {{ customer.subject.titleBefore }} {{ customer.subject.firstName }} {{ customer.subject.middleName }}
      {{ customer.subject.lastName }} <span *ngIf="customer.subject.titleAfter">, </span
      >{{ customer.subject.titleAfter }}
    </div>
    <div *ngIf="!isCollapsed" class="tile_content">
      <table class="tariff_table">
        <tr class="tariff_row">
          <td>
            {{
              (isBusiness ? 'wc.ecare.dashboard.bulstat.label' : 'wc.ecare.dashboard.identityNumber.label') | translate
            }}
          </td>
          <td colspan="2">{{ customer.subject.identityNumber }}</td>
        </tr>
        <tr app-acl-internal-evaluate-component class="tariff_row">
          <td>{{ 'wc.shopping.customer.segment.label' | translate }}</td>
          <td>{{ 'wc.shopping.customer.segment.' + customer.segment + '.label' | translate }}</td>
          <td>{{ 'wc.ecare.dashboard.identification.label' | translate }}: {{ customer.id }}</td>
        </tr>
        <tr *ngIf="!isBusiness" class="tariff_row">
          <td>
            <ng-container *ngIf="codebookService.getCodebookTexts('ID_TYPE') | async as codebook">
              {{ codebook[customer.subject.idType] }}
            </ng-container>
          </td>
          <td>{{ customer.subject.idNo }}</td>
          <td>
            <input
              *ngIf="customer.subject.idVerified"
              type="checkbox"
              id="idVerified"
              name="idVerified"
              disabled="disabled"
              checked="checked" />
            <input
              *ngIf="!customer.subject.idVerified"
              type="checkbox"
              id="idNotVerified"
              name="idVerified"
              disabled="disabled" />
            <label for="idVerified">&nbsp;{{ 'wc.ecare.dashboard.idVerified.label' | translate }}</label>
          </td>
        </tr>
        <tr *ngIf="!isBusiness" class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.idIssueAuthority.label' | translate }}</td>
          <td colspan="2">
            <label> {{ customer.subject.idIssueAuthority }}</label>
          </td>
        </tr>
        <tr *ngIf="!isBusiness" class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.idValidTo.label' | translate }}</td>
          <td colspan="2">
            <label> {{ customer.subject.idValidTo }}</label>
          </td>
        </tr>
        <tr app-acl-internal-evaluate-component class="tariff_row">
          <td>
            <label>{{ 'wc.ecare.dashboard.subjectType.label' | translate }}</label>
          </td>
          <td colspan="2">
            <label *ngIf="customer.subject.subjectType"> {{ customer.subject.subjectType }}</label>
          </td>
        </tr>
        <tr *ngIf="!isBusiness && customer.subject.nationality" class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.nationality.label' | translate }}</td>
          <td colspan="2">
            <label> {{ customer.subject.nationality }}</label>
          </td>
        </tr>
        <tr *ngIf="!isBusiness && customer.subject.birthDate" class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.birthDate.label' | translate }}</td>
          <td colspan="2">
            <label> {{ customer.subject.birthDate }}</label>
          </td>
        </tr>
        <tr *ngIf="!isBusiness && customer.subject.sex" class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.sex.label' | translate }}</td>
          <td colspan="2">
            <label> {{ customer.subject.sex }}</label>
          </td>
        </tr>
        <tr class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.preferredChannel.label' | translate }}</td>
          <td colspan="2">
            <label *ngIf="customer.contact"> {{ customer.contact.preferredChannel }}</label>
          </td>
        </tr>
        <tr class="tariff_row">
          <td>{{ 'wc.ecare.dashboard.contact.label' | translate }}</td>
          <td colspan="2">
            <label *ngIf="customer.contact">
              {{ customer.contact.phone1 | phoneNumber }} , {{ customer.contact.email }}</label
            >
          </td>
        </tr>
        <tr class="tariff_row last">
          <td>{{ 'wc.ecare.dashboard.address.label' | translate }}</td>
          <td colspan="2">
            <label *ngIf="customer.address">
              {{ customer.address | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
            </label>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
