import { Component, Input, OnInit } from "@angular/core";
import { EcareReportsComponent } from "./ecare-reports.component";

@Component({
  selector: 'app-ecare-reports-mobile, [app-ecare-reports-mobile]',
  templateUrl: './ecare-reports-mobile.component.html',
})
export class EcareReportsMobileComponent implements OnInit {
  @Input()
  reports;

  constructor(public ecareReportsComponent: EcareReportsComponent) {
  }

  ngOnInit(): void {}
}
