import { PriceDto, PriceTypeDto } from '@btl/order-bff';
import { AbstractPriceComponent } from './abstract-price.component';
import { Component, Input } from '@angular/core';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { TranslateService } from '@ngx-translate/core';
import { ProductCustomService } from '@service/product-custom.service';

@Component({
  selector: 'app-recurrent-price',
  templateUrl: './recurrent-price.component.html',
})
export class RecurrentPriceComponent extends AbstractPriceComponent {
  /**
   * A flag specifying if the texts should be disabled.
   */
  @Input()
  disableTexts: boolean = false;
  @Input()
  cmsLabelKey: string = 'wc.common.price.monthly.label';

  constructor(
    protected currentLocaleService: CurrentLocaleService,
    protected translateService: TranslateService,
    protected productCustomService: ProductCustomService
  ) {
    super(currentLocaleService, translateService, productCustomService);
  }

  //region Helpers:

  protected getPriceWrapper(): PriceDto {
    if (this._product) {
      return this._product.prices[PriceTypeDto.RC];
    } else {
      return { price: this._price };
    }
  }
}
