import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContactDto } from '@btl/order-bff';

@Component({
  selector: 'app-new-invoice-details',
  templateUrl: './new-invoice-details.component.html',
})
export class NewInvoiceDetailsComponent {
  @Input() dialogRef;
  @Input() getHwInvoicingContactFormGroup;
  @Input() getHwInvoicingAddressFormGroup;
  @Input() formUserDetails: FormGroup;
  @Input() account;

  contactTypeEnum = ContactDto.TypeDtoEnum;

  closePopup(): void {
    this.dialogRef.dismiss();
  }
}
