import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { AccountDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-user-row',
  templateUrl: './ecare-user-row.component.html',
})
export class EcareUserRowComponent {
  blockTemplate = BlockTemplateComponent;

  @Input() user: AccountDto;
  @Output() readonly selectedUser = new EventEmitter<AccountDto>();

  addressTypes = addressTypes;

  onRowClick(): void {
    this.selectedUser.emit(this.user);
  }

  getAccountParamByName(name: string): string {
    let value = null;
    if (this.user) {
      value = this.user.parameters?.find(param => param.name === name)?.value;
    }
    return value;
  }
}
