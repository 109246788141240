import { Component, Input } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';
import { forkJoin, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GroupDto, PartyDto, ProductDetailDto } from '@btl/order-bff';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ecare-usage-alerts-widget, [app-ecare-usage-alerts-widget]',
  templateUrl: './ecare-usage-alerts-widget.component.html',
})
export class EcareUsageAlertsWidgetComponent extends EcareAbstractTariffWidgetComponent {
  @Input() widgets = new Map<GroupDto, PartyDto[]>();
  alertSubscriptionsUsages: SubscriptionServiceAlert[] = [];
  subscriptionServiceAlert: SubscriptionServiceAlert;
  selectedAlertIndex = 0;
  slidShowSub = null;
  widgetSwitchInternalSeconds = 5000;
  widgetUsageAlertTriggerPercentage = 90;

  constructor(
    protected customerLocalStorageService: CustomerLocalStorageService,
    public codebookService: CodebookService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    protected ngbModal: NgbModal
  ) {
    super(customerLocalStorageService, codebookService, ngbModal);
  }

  ngOnInit() {
    super.ngOnInit();

    const calls = [];
    const widgetSwitchInternalSeconds$ = this.propertyAccessorLocalService.getWidgetSwitchInternalSeconds();
    const widgetUsageAlertTriggerPercentage$ = this.propertyAccessorLocalService.getWidgetUsageAlertTriggerPercentage();
    forkJoin([widgetSwitchInternalSeconds$, widgetUsageAlertTriggerPercentage$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([widgetSwitchInternalSeconds, widgetUsageAlertTriggerPercentage]) => {
        if (widgetSwitchInternalSeconds) {
          this.widgetSwitchInternalSeconds = widgetSwitchInternalSeconds * 1000;
        }
        if (widgetUsageAlertTriggerPercentage) {
          this.widgetUsageAlertTriggerPercentage = widgetUsageAlertTriggerPercentage;
        }
        for (const [subscriptionId, usageInformation] of this.subscriptionsUsages) {
          let subscription = null;
          let subGroup = null;
          for (const [group, subscriptions] of this.widgets) {
            for (const sub of subscriptions) {
              if (sub.id === subscriptionId) {
                subscription = sub;
                subGroup = group;
                break;
              }
            }
            if (subscription) {
              break;
            }
          }
          if (subscription) {
            usageInformation.usage.forEach(usage => {
              let limit = null;
              for (const group of this.widgetElementsGroups) {
                if (group.groupParams['widget_ElementType'].toLowerCase() === usage.usageType) {
                  const groupAsset = this.getAssetByGroup(subscription.assets[0], group.id);
                  if (groupAsset) {
                    limit = Number.parseInt(
                      this.getFirstFoundParameterValue(groupAsset, group.groupParams['widget_BundleVolumeParamNames']),
                    );
                  }
                  break;
                }
              }

              if (limit) {
                const usedPercentage = Math.round((usage.usageVolumeWithinPackage / limit) * 100);

                if (usedPercentage > this.widgetUsageAlertTriggerPercentage) {
                  const subscriptionServiceAlert: SubscriptionServiceAlert = new SubscriptionServiceAlert();
                  subscriptionServiceAlert.serviceType = usage.usageType.toUpperCase();
                  subscriptionServiceAlert.usedPercentage = usedPercentage;
                  subscriptionServiceAlert.subIdentification = this.getTariffIdentification(subscription, subGroup);
                  subscriptionServiceAlert.subParentId = subscription.parentId;
                  subscriptionServiceAlert.tariffId = subscription.assets[0].id;
                  subscriptionServiceAlert.tariffProduct = subscription.assets[0]['product'];

                  this.prodcSuTpFixProduct = CustomerPartyUtil.getChildAssetByCategoryId(
                    subscription.assets[0],
                    CategoryTypeEnum.PRODC_SU_TP_FIX,
                  );
                  if (this.prodcSuTpFixProduct) {
                    subscriptionServiceAlert.locationId = CustomerPartyUtil.getAssetProductCharacteristicValue(
                      this.prodcSuTpFixProduct,
                      'locRefNo',
                    );
                    subscriptionServiceAlert.socketId = CustomerPartyUtil.getAssetProductCharacteristicValue(
                      this.prodcSuTpFixProduct,
                      'socketRefNo',
                    );
                  }

                  this.alertSubscriptionsUsages.push(subscriptionServiceAlert);
                }
              }
            });
          }
        }
        if (this.alertSubscriptionsUsages.length > 0) {
          this.subscriptionServiceAlert = this.alertSubscriptionsUsages[0];
        }

        this.startSlideShow();
      });
  }

  startSlideShow() {
    if (this.slidShowSub) {
      this.slidShowSub.unsubscribe();
    }
    this.slidShowSub = interval(this.widgetSwitchInternalSeconds)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(x => {
        this.selectedAlertIndex++;
        if (this.selectedAlertIndex >= this.alertSubscriptionsUsages.length) {
          this.selectedAlertIndex = 0;
        }
        this.subscriptionServiceAlert = this.alertSubscriptionsUsages[this.selectedAlertIndex];
      });
  }

  switchAlert(count: number) {
    this.startSlideShow();

    this.selectedAlertIndex += count;
    if (this.selectedAlertIndex < 0) {
      this.selectedAlertIndex = this.alertSubscriptionsUsages.length - 1;
    } else if (this.selectedAlertIndex >= this.alertSubscriptionsUsages.length) {
      this.selectedAlertIndex = 0;
    }
    this.subscriptionServiceAlert = this.alertSubscriptionsUsages[this.selectedAlertIndex];
  }

  prevAlert() {
    this.switchAlert(-1);
  }

  nextAlert() {
    this.switchAlert(1);
  }
}

export class SubscriptionServiceAlert {
  subIdentification: string;
  subParentId: string;
  serviceType: string;
  usedPercentage: number;
  tariffId: string;
  locationId: string;
  socketId: string;
  tariffProduct: ProductDetailDto;
}
