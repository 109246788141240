import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CurrentLocaleService,
  EnableDynamicLoading,
  ProductGroupService,
  ProductService,
  StoreService,
} from '@btl/btl-fe-wc-common';
import { BlockUIService } from 'ng-block-ui';
import { takeUntil } from 'rxjs/operators';
import { BaseProductDetailComponent } from '../../../wizard/detail/product-detail/base-product-detail.component';
import { ProductCustomService } from '@service/product-custom.service';
import { StockCustomService } from '@service/stock-custom.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { DateService } from '@service/date.service';
import { ProductComparisonService } from '@service/product-comparison.service';
import { PictureUtils } from '../../../../helpers/picture-utils';
import { PictureTypeEnum } from '../../../../models/PictureTypeEnum';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { Router } from '@angular/router';
import { NavigationMenuService } from '@service/navigation-menu.service';
import { WatchdogSubscriptionComponent } from 'app/components/wizard/detail/product-detail/watchdog-subscribe/watchdog-subscription.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WishListPopupComponent } from 'app/components/wizard/wish-list/wish-list-popup/wish-list-popup.component';

export declare type CikCakPhoneOrientation = 'LEFT' | 'RIGHT';

@Component({
  selector: '[app-cms-widget-cik-cak-phone]',
  templateUrl: './cms-widget-cik-cak-phone.component.html',
})
@EnableDynamicLoading({ customName: 'ZIG_ZAG' })
export class CmsWidgetCikCakPhoneComponent extends BaseProductDetailComponent implements OnInit, OnDestroy {
  @Input() productCode: string;
  @Input() orientation: CikCakPhoneOrientation;

  constructor(
    protected router: Router,
    protected currentLocaleService: CurrentLocaleService,
    protected productService: ProductService,
    protected productCustomService: ProductCustomService,
    protected stockCustomService: StockCustomService,
    private blockUIService: BlockUIService,
    protected propertyAccessorLocalService: PropertyAccessorLocalService,
    protected dateService: DateService,
    protected productGroupService: ProductGroupService,
    protected productComparisonService: ProductComparisonService,
    protected orderingService: WcOrderingService,
    protected shoppingCartService: ShoppingCartService,
    protected navigationMenuService: NavigationMenuService,
    private ngbModal: NgbModal,
    protected storeService: StoreService
  ) {
    super(
      router,
      productCustomService,
      stockCustomService,
      currentLocaleService,
      propertyAccessorLocalService,
      dateService,
      productGroupService,
      productComparisonService,
      orderingService,
      shoppingCartService,
      navigationMenuService,
      storeService
    );

    this.currentLocaleService.currentLocaleChange.subscribe(result => {
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.blockUIService.start('main');
    this.productService
      .getProductByProductCode(this.productCode)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(productDto => {
        this.product = productDto;
        if (!this.isTariff()) {
          this.initColorVariants();
          this.initStocks();
        }

        this.getGlobalDeliveryDaySubscription();

        this.blockUIService.stop('main');
      });
  }

  getPictureHref(): string {
    if (this.isTariff()) {
      return '/assets/img/products/tariff_default_product_image.png';
    }
    return PictureUtils.getPictureHrefByType(
      this.product,
      PictureTypeEnum.PRODUCT_GENERAL,
      'assets/img/products/phone_example.png'
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    for (const subscription of this.allSubscriptions) {
      subscription.unsubscribe();
    }
  }

  openWatchdogSubscriptionModal() {
    const modalRef = this.ngbModal.open(WatchdogSubscriptionComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });
    const watchdogSubscriptionModalComponent = <WatchdogSubscriptionComponent>modalRef.componentInstance;
    watchdogSubscriptionModalComponent.dialogRef = modalRef;
    watchdogSubscriptionModalComponent.productCode = this.product.productCode;
    watchdogSubscriptionModalComponent.showFeature = this.product.parametersStatic['watchdogEnabled'] === 'true';
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = this.product.productCode;
  }
}
