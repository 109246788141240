<div class="content-header">
  {{ 'wc.shopping.address.' + contactType + '.heading' | translate }}
</div>
<div *ngIf="contactDtos && contactDtos.length > 0" class="ecare-3columns">
  <div *ngFor="let contact of contactDtos; let first = first" class="editable-box">
    <div class="shipping-address" [class.first-item]="first">
      <div class="data">
        <div class="firstLineWrapper">
          <div class="-value semi-bold">{{ contact.firstName }} {{ contact.lastName }}</div>
          <!-- ADD LT instead of Default -->
          <app-sticker [style.display]="first ? '' : 'none'">Default</app-sticker>
        </div>
        <div class="prefferedChannel">
          <div class="-value">{{ contact.email }}</div>
          <div *ngIf="contact.preferredChannel === 'EMAIL' || contact.preferredChannel === 'ALL'">
            <i [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
          </div>
        </div>
        <div class="prefferedChannel">
          <div class="-value">{{ contact.phone1 }}</div>
          <div *ngIf="contact.preferredChannel === 'SMS' || contact.preferredChannel === 'ALL'">
            <i [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'"></i>
          </div>
        </div>
        <div class="-value">
          {{ contact.address | displayAddress }}
        </div>
        <div ngbDropdown class="edit-button-wrapper">
          <button class="edit-button cursor-pointer" ngbDropdownToggle>
            <i [inlineSVG]="'/assets/svg/feather-vertical-dots.svg'"></i>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
            <!--             <button ngbDropdownItem>Set as default</button> -->
            <button class="dropdown-item filled" ngbDropdownItem (click)="edit(contact)">
              <i [inlineSVG]="'/assets/svg/feather-edit.svg'"></i>{{ 'wc.common.edit' | translate }}
            </button>
            <button class="dropdown-item" ngbDropdownItem style="min-width: 185px" (click)="remove(contact)">
              <i [inlineSVG]="'/assets/svg/remove.svg'"></i>{{ 'wc.common.delete.button' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="buttons"
  app-button
  iconPath="/assets/svg/feather-plus-circle.svg"
  label="{{ 'wc.ecare.accountSettings.contacts.newContact.' + contactType | translate }}"
  (click)="addContact()"></div>
