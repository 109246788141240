<div class="widget--header">
  <div class="widget--header_title">
    <i [inlineSVG]="'/assets/svg/dashboard-widgets/last-orders.svg'"></i>
    <h6>{{ 'wc.ecare.widgets.' + componentType + '.header' | translate }}</h6>
  </div>
  <div class="widget--header_account">
    <a routerLink="/ecare/orders">{{ 'wc.ecare.widgets.lastOrders.viewAll' | translate }}</a>
  </div>
</div>
<div class="widget--content shrinked-gap ecare-ordersSearch">
  <app-ecare-order-table-mobile
    displayMode="lastOderWidget"
    [orders]="orders"
    [isWidget]="true"
    [maxThumbnailsToShow]="2">
  </app-ecare-order-table-mobile>
</div>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
