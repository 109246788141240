import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-customer-account-row',
  templateUrl: './ecare-customer-account-row.component.html',
})
export class EcareCustomerAccountRowComponent {
  blockTemplate = BlockTemplateComponent;

  @Input() customerAccount: CustomerAccountDto;
  @Output() readonly selectedCustomerAccount = new EventEmitter<CustomerDto>();

  addressTypes = addressTypes;

  onRowClick(): void {
    this.selectedCustomerAccount.emit(this.customerAccount);
  }
}
