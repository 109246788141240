import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductService } from '@btl/btl-fe-wc-common';
import { CustomerService } from 'app/services/customer.service';
import { BalancesAmountResultDto, CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-account-card',
  templateUrl: './ecare-customer-account-card.component.html',
})
export class EcareCustomerAccountCardComponent implements OnInit {
  @Input()
  customerAccount: CustomerAccountDto;

  @Input()
  customer: CustomerDto;

  PaymentTypeEnum = PaymentTypeEnum;
  openBalanceResult: BalancesAmountResultDto;

  hasSubscribtion: boolean = false;

  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private orderingService: WcOrderingService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private productService: ProductService,
    private customerService: CustomerService,
    private router: Router
  ) {
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit() {
    if (this.customer && this.customer.id) {
      this.customerService.getOpenBalance(this.customer.id, this.customerAccount.id).subscribe(openBalance => {
        this.openBalanceResult = openBalance;
      });
    }
    this.findSubscription();
  }

  loadSelectedCustomerAccount(selectedAccount: CustomerAccountDto, event): void {
    event.stopPropagation();
    // redirect to: /ecare/dashboard/:cuRefNo/:caRefNo
    this.router.navigateByUrl(`/ecare/dashboard/${this.customer.id}/${selectedAccount.id}`);
  }

  /**
   * Redirect the user to financial-documents.
   */
  goToFinancialDocuments(): void {
    this.router.navigate(['ecare', 'financial-documents'], {
      queryParams: {
        customerAccountId: this.customerAccount.id,
      },
    });
  }

  findSubscription() {
    this.hasSubscribtion = this.customerAccount.childParties.some(child => child.role === 'Subscription');
  }
}

export enum PaymentTypeEnum {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
}
