import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { BusinessEventDto, PagedBusinessEventsDto, PagedBusinessEventTypesDto } from '@btl/order-bff';
import { BlockTemplateComponent, EventService, Filter, Search, ServiceUtils } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-business-events',
  templateUrl: './business-events.component.html',
})
export class BusinessEventsComponent implements OnInit {
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  businessEventDtos: BusinessEventDto[] = [];
  pagedDto: PagedBusinessEventsDto;
  canDisplayNoOrdersText = false;

  @Input()
  constantFilter: Array<Filter> = [];

  @Input()
  entityType;

  @Input()
  entityId;

  @Input()
  showHeader;

  eventTypes: PagedBusinessEventTypesDto;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'happened', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  constructor(private renderer: Renderer2, private readonly eventService: EventService) {
    this.renderer.addClass(document.body, 'ecare');
    this.eventService.filterEventTypes(ServiceUtils.getUnlimitedSearch()).subscribe(result => {
      this.eventTypes = result;
    });
  }

  ngOnInit(): void {
    this.constantFilter.push({
      column: 'refs',
      compareType: null,
      value: [{ entityType: this.entityType, entityId: this.entityId }],
    });
    if (this.constantFilter) {
      this.search.filtering.length = 0;
      this.constantFilter.forEach(filter =>
        this.search.filtering.push({
          column: filter.column,
          compareType: filter.compareType,
          value: filter.value,
        })
      );
    }
    this.load(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.load(this.search);
  }

  private load(search) {
    this.blockUIElement.start();
    this.constantFilter.forEach(constFilter => {
      const existingFilter = this.search.filtering.find(
        filter => filter.column === constFilter.column && filter.compareType === constFilter.compareType
      );
      if (existingFilter) {
        existingFilter.value = constFilter.value;
      } else {
        this.search.filtering.push(constFilter);
      }
    });
    this.eventService
      .filterEvents(search)
      .pipe(
        finalize(() => {
          this.canDisplayNoOrdersText = _.isEmpty(this.businessEventDtos);
          this.blockUIElement.stop();
        })
      )
      .subscribe(result => {
        this.businessEventDtos = result.data;
      });
  }

  onPrevPage() {
    this.search.paging.page--;
    this.load(this.search);
  }

  onNextPage() {
    this.search.paging.page++;
    this.load(this.search);
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.load(this.search);
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.load(this.search);
  }

  getEventType(event: BusinessEventDto) {
    return this.eventTypes.data.find(type => type.code === event.type.code);
  }
}
