import { Component, Input } from '@angular/core';
import { CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-account-tile',
  templateUrl: './customer-account-tile.component.html',
})
export class CustomerAccountTileComponent {
  isCollapsed: boolean = true;

  @Input()
  customerAccount: CustomerAccountDto;

  @Input()
  customer: CustomerDto;

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
