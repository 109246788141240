<ng-template #generalTemplateInner>
  <div [ngbTooltip]="iconTooltip ? iconTooltip : null" *ngIf="iconBeforeValue && iconPath" placement="bottom">
    <i [inlineSVG]="iconPath"></i>
  </div>
  <div [ngbTooltip]="generalTooltip ? generalTooltip : null" [class.tooltiped]="generalTooltip" placement="bottom">
    <ng-container *ngIf="clipboardValue; else showContent">
      {{clipboardValue}}
    </ng-container>
    <ng-template #showContent>
      <ng-content></ng-content>
    </ng-template>
  </div>
  <div [ngbTooltip]="iconTooltip ? iconTooltip : null" *ngIf="!iconBeforeValue && iconPath" placement="bottom">
    <i [inlineSVG]="iconPath"></i>
  </div>
</ng-template>

<ng-template #sideboxRow>
  <div class="sidebox-label">
    <label>
      {{label}}
      <span *ngIf="require" class="required">*</span>
    </label>
  </div>
  <div class="sidebox-value">
    <div class="copy-to-clipboard" *ngIf="clipboardValue; else noClipboard" (click)="copyToClipboard(clipboardValue)" [ngbTooltip]="isCopied? 'Copied' : 'Copy to clipboard'" placement="bottom">
      <ng-container *ngTemplateOutlet="generalTemplateInner"></ng-container>
    </div>
    <ng-template #noClipboard>
      <ng-container *ngTemplateOutlet="generalTemplateInner"></ng-container>
    </ng-template>
  </div>
</ng-template>

<div *ngIf="!onlyForInternal; else internal">
  <div class="sidebox-row-wrapper">
    <ng-container *ngTemplateOutlet="sideboxRow"></ng-container>
  </div>
</div>

<ng-template #internal>
  <div app-acl-internal-evaluate-component class="sidebox-row-wrapper">
    <ng-container *ngTemplateOutlet="sideboxRow"></ng-container>
  </div>
</ng-template>
