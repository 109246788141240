import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationDialogBorderComponent } from '../../confirmation-dialog/confirmation-dialog-border.component';

@Component({
  selector: 'app-ecare-confirmation-dialog',
  templateUrl: './ecare-confirmation-dialog.component.html',
})
export class EcareConfirmationDialogComponent extends ConfirmationDialogBorderComponent implements OnInit {
  public static readonly POPUP_WINDOW_CSS_CLASSES = 'dialog dialog-confirmation';

  @Input()
  showCloseCrossIcon = true;

  @Input()
  messagesTextKey: string[];

  @Input()
  buttonNoTextKey = 'wc.common.no.button';

  @Input()
  buttonNoIconPath: string;

  @Input()
  buttonYesTextKey = 'wc.common.yes.button';

  @Input()
  buttonYesIconPath: string;

  @Input()
  hideButtonNo: boolean;

  @Input()
  hideButtonYes: boolean;

  @Input()
  buttonYesAdditionalClass: string;

  ngOnInit() {
    if (!this.dialogReference) {
      throw new Error('The dialogReference parameter must have non-null value.');
    }
  }
}
