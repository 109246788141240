<!-- Product -->
<div
  #productTile
  class="product__wrapper"
  [class.inComparison]="this.productListingService.isProductInComparison(product)"
  [style.min-height.px]="tileMinHeight"
  (resized)="productTileResized($event)">
  <a class="product__image" id="{{ parentId }}_001_box_item" [routerLink]="['/eshop/product-detail/' + product.seoUrl]">
    <!--TODO:delete --><img
      *ngIf="product.picture"
      alt="{{ product.name | selectLocaleProperty }}"
      src="{{ product.picture.href }}" /><!--TODO:delete -->
    <img
      *ngIf="product.image && product.image.medium"
      #productImage
      alt="{{ product.name | selectLocaleProperty }}"
      [lazyLoad]="'/api/bff/order/v1/img/' + product.image.medium"
      [defaultImage]="'/assets/img/products/loading.gif'"
      [style.min-height.px]="imageMinHeight" />
    <img
      *ngIf="(!product.image || !product.image.medium) && !product.picture"
      #productImage
      src="/assets/img/products/phone_example-listing.png"
      alt="{{ product.name | selectLocaleProperty }}"
      [style.min-height.px]="imageMinHeight" />
  </a>

  <div>
    <div
      class="product__rating"
      app-rating-stars
      [style.display]="product.rating ? 'block' : 'none'"
      [rating]="product.rating"
      [onlyFull]="false"
      title="{{ product.rating }}%"></div>

    <h3 #name class="product__name" id="{{ parentId }}_002_box_item" [style.min-height.px]="nameMinHeight">
      <a
        class="product__name"
        id="{{ parentId }}_003_box_item"
        [routerLink]="['/eshop/product-detail/' + product.seoUrl]"
        >{{ product.name | selectLocaleProperty }}</a
      >
      <div app-acl-internal-evaluate-component>
        <i
          class="product__code fa fa-chevron-circle-down"
          tooltipClass="product-code"
          placement="bottom"
          id="{{ parentId }}_004_box_item"
          ngbTooltip="{{ this.product.verCode }}"></i>
      </div>
    </h3>

    <div #description class="product__description" [style.min-height.px]="descriptionMinHeight">
      <div
        #ellipsisReload
        ellipsis
        [style.max-height.px]="75"
        [ellipsis-content]="product.description | selectLocaleProperty | stripHtmlTags"></div>
    </div>

    <div #prices class="product__footer" [style.min-height.px]="pricesMinHeight">
      <div class="product__footer-pricing">
        <div
          *ngIf="product.prices?.priceValidity | priorityPrice : 'priceSuperficial' as crossedPrice"
          class="product__footer-pricingSale">
          <div class="crossed_price_container">
            <span class="crossed_price_container_span" [innerHTML]="crossedPrice | price"></span>
            <hr />
          </div>
        </div>
        <div class="product__footer-pricingListPrice">
          <!--TODO:delete -->
          <one-time-price *ngIf="!product.prices?.priceIndex" [product]="product"></one-time-price>
          <!--TODO:delete -->
          <span [innerHTML]="product.prices?.priceIndex | price"></span>
        </div>
      </div>

      <div class="product__footer-button">
        <app-button
          *ngIf="product.stockStatus !== 'outOfStock'; else showDetail"
          size="fullWidth"
          iconPath="/assets/svg/shopping-cart-icon.svg"
          label="{{ 'wc.ecare.productListing.addToCart' | translate }}"
          (click)="buyProduct(product)">
        </app-button>
        <ng-template #showDetail
          ><app-button
            size="fullWidth"
            variant="whiteWithBorder"
            [routerLink]="['/eshop/product-detail/' + product.seoUrl]"
            label="{{ 'wc.shopping.gui_productDetail.watchdog.formButton' | translate }}"></app-button
        ></ng-template>
      </div>

      <div *ngIf="compareAvailable" class="product__compare">
        <a
          *ngIf="!this.productListingService.isProductInComparison(product); else showRemove"
          href="javascript:void(0)"
          id="{{ parentId }}_007_box_item"
          (click)="this.productListingService.addProductToComparison(product, maxProductsInComparison)"
          >{{ 'wc.shopping.gui_productDetail.productComparison.addLink' | translate }}</a
        >
        <ng-template #showRemove>
          <a
            href="javascript:void(0)"
            id="{{ parentId }}_008_box_item"
            (click)="this.productListingService.removeProductFromComparison(product)"
            >{{ 'wc.shopping.gui_productDetail.productComparison.removeLink' | translate }}</a
          >
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Product stickers -->
  <div class="product__labels">
    <app-sticker *ngFor="let sticker of product.stickers" variant="{{ sticker.type | stickerTypeClass }}">{{
      sticker.name | selectLocaleProperty
    }}</app-sticker>

    <ng-container *ngIf="product.stockStatus">
      <app-sticker [variant]="getStickerType(product.stockStatus)">
        {{ 'wc.shopping.gui_productCard.main.itemAvailability.' + product.stockStatus | translate }}</app-sticker
      >
    </ng-container>
    <!--<div class="product__label -sale">
    20% off
    </div>-->
  </div>
  <div
    *ngIf="product.parameters['wishlistEnabled'] === 'true'"
    class="wish-list-add"
    placement="top"
    ngbTooltip="{{ 'wc.shopping.gui_wishlist.addToWishList.heading' | translate }}"
    (click)="wishListPopup()">
    <i [inlineSVG]="inWishList ? '/assets/svg/red-heart-fill.svg' : '/assets/svg/red-heart-outlined.svg'"></i>
  </div>

  <!-- Product key features -->
  <div *ngIf="product.features && keyFeaturesCount" class="product__parameters">
    <div *ngFor="let feature of product.features.slice(0, keyFeaturesCount); let i = index" class="product__parameter">
      <i aria-hidden="true" id="{{ parentId }}_{{ i }}_009_box_item" class="fa fa-{{ feature.iconId }}"></i>
      <span *ngIf="feature && feature.value !== 'false' && feature.value !== 'true'" class="product__parameter-param">{{
        feature.value
      }}</span>
    </div>
    <ng-container
      *ngIf="this.colorMap && this.colorMap.size > 0 && product.colorVariants && product.colorVariants.length > 1"
      class="product__color-wrapper">
      <ng-container *ngFor="let color of product.colorVariants.slice(0, 3)">
        <div
          *ngIf="color.colorCode | rgb : this.colorMap"
          class="product__color"
          title="Product color"
          [style.background]="color.colorCode | rgb : this.colorMap"></div>
      </ng-container>
    </ng-container>
  </div>
  <!--<div class="product__parameters">
    <div class="product__parameter">
    <i class="fa fa-camera" aria-hidden="true"></i>
    <span class="product__parameter-param">15.4"</span>
    </div>
    <div class="product__parameter">
    <i class="fa fa-hdd-o" aria-hidden="true"></i>
    <span class="product__parameter-param">256GB</span>
    </div>
  </div>-->
</div>
