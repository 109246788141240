<div class="ecare dashboard tariffs">
  <div class="ecare-header_headline">Orders and tickets</div>
  <div class="dashboard_wrapper">
    <div class="dashboard_navigation">
      <div class="tile">
        <div class="tile_inner">
          <ul class="dashboard_navigation-menu">
            <li><i class="fa fa-dashboard"></i> Navigation item</li>
            <li>
              <a id="001_ecare_tariffs" href="#"
                ><i id="002_ecare_tariffs" class="fa fa-exclamation-circle"></i> Navigation item</a
              >
            </li>
            <li>
              <a id="003_ecare_tariffs" href="#"><i id="004_ecare_tariffs" class="fa fa-mobile"></i> Navigation item</a>
            </li>
            <li>
              <a id="005_ecare_tariffs" href="#"><i id="006_ecare_tariffs" class="fa fa-ticket"></i> Navigation item</a>
            </li>
            <li>
              <a id="007_ecare_tariffs" href="#"><i id="008_ecare_tariffs" class="fa fa-cog"></i> Navigation item</a>
            </li>
            <li>
              <a id="009_ecare_tariffs" href="#"><i id="010_ecare_tariffs" class="fa fa-ban"></i> Navigation item</a>
            </li>
            <li>
              <a id="011_ecare_tariffs" href="#"
                ><i id="012_ecare_tariffs" class="fa fa-suitcase"></i> Navigation item</a
              >
            </li>
            <li>
              <a id="013_ecare_tariffs" href="#"
                ><i id="014_ecare_tariffs" class="fa fa-puzzle-piece"></i> Navigation item</a
              >
            </li>
            <li>
              <a id="015_ecare_tariffs" href="#"
                ><i id="016_ecare_tariffs" class="fa fa-file-alt"></i> Navigation item</a
              >
            </li>
            <li>
              <a id="017_ecare_tariffs" href="#"><i id="018_ecare_tariffs" class="fa fa-link"></i> Navigation item</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="dashboard_content">
      <div class="ecare-header_subheadline">Current tariff</div>

      <div class="tiles_wrapper">
        <div class="tile -tile12">
          <div class="tile_inner">
            <div class="tile_headline">Mobile internet business plus</div>
            <div class="tile_content">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
              - Morbi malesuada orci ut lorem finibus, vitae imperdiet dui tempus.<br />
              - Nam eu tortor vitae urna blandit semper.<br />
              <div class="tariff_pricing">
                $9,99
                <small>per month</small>
              </div>
            </div>
          </div>
        </div>

        <div class="tile -tile12">
          <div class="ecare-header_subheadline">Choose your new tariff</div>
          <div class="ecare-header_headline-note">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        </div>

        <div class="tile -tile12">
          <div class="tile_inner">
            <div class="tile_headline">Mobile internet business plus</div>
            <div class="tile_content">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
              - Morbi malesuada orci ut lorem finibus, vitae imperdiet dui tempus.<br />
              - Nam eu tortor vitae urna blandit semper.<br />
              <div class="tariff_pricing">
                $9,99
                <small>per month</small>
              </div>
              <div class="tariff_buttons">
                <a id="019_ecare_tariffs" href="#" class="btn btn-default">order now</a>
                <a id="020_ecare_tariffs" class="toggle_details" (click)="toggleDetail()">show details</a>
              </div>
            </div>
            <div *ngIf="toggle" class="tariff_detail">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum
              lacus erat, non malesuada nisl maximus luctus. Morbi aliquam, lorem in vehicula congue, purus est
              tincidunt elit, ac consectetur massa leo faucibus leo. Cras lacinia venenatis tortor, quis feugiat risus
              ornare id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper.
              Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </div>
          </div>
        </div>

        <div class="tile -tile12">
          <div class="tile_inner">
            <div class="tile_headline">Mobile internet business plus</div>
            <div class="tile_content">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
              - Morbi malesuada orci ut lorem finibus, vitae imperdiet dui tempus.<br />
              - Nam eu tortor vitae urna blandit semper.<br />
              <div class="tariff_pricing">
                $9,99
                <small>per month</small>
              </div>
              <div class="tariff_buttons">
                <a id="021_ecare_tariffs" href="#" class="btn btn-default">order now</a>
                <!-- <a class="toggle_details">show details</a> -->
              </div>
            </div>
            <!--                         <div class="tariff_detail">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Morbi aliquam, lorem in vehicula congue, purus est tincidunt elit, ac consectetur massa leo faucibus leo. Cras lacinia venenatis tortor, quis feugiat risus ornare id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div> -->
          </div>
        </div>

        <div class="tile -tile12">
          <div class="tile_inner">
            <div class="tile_headline">Mobile internet business plus</div>
            <div class="tile_content">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
              - Morbi malesuada orci ut lorem finibus, vitae imperdiet dui tempus.<br />
              - Nam eu tortor vitae urna blandit semper.<br />
              <div class="tariff_pricing">
                $9,99
                <small>per month</small>
              </div>
              <div class="tariff_buttons">
                <a id="022_ecare_tariffs" href="#" class="btn btn-default">order now</a>
                <!-- <a class="toggle_details">show details</a> -->
              </div>
            </div>
            <!--                         <div class="tariff_detail">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Morbi aliquam, lorem in vehicula congue, purus est tincidunt elit, ac consectetur massa leo faucibus leo. Cras lacinia venenatis tortor, quis feugiat risus ornare id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div> -->
          </div>
        </div>

        <div class="tile -tile12">
          <div class="tile_inner">
            <div class="tile_headline">Mobile internet business plus</div>
            <div class="tile_content">
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
              - Morbi malesuada orci ut lorem finibus, vitae imperdiet dui tempus.<br />
              - Nam eu tortor vitae urna blandit semper.<br />
              <div class="tariff_pricing">
                $9,99
                <small>per month</small>
              </div>
              <div class="tariff_buttons">
                <a id="023_ecare_tariffs" href="#" class="btn btn-default">order now</a>
                <!-- <a class="toggle_details">show details</a> -->
              </div>
            </div>
            <!--                         <div class="tariff_detail">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Morbi aliquam, lorem in vehicula congue, purus est tincidunt elit, ac consectetur massa leo faucibus leo. Cras lacinia venenatis tortor, quis feugiat risus ornare id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue dictum semper. Vestibulum bibendum lacus erat, non malesuada nisl maximus luctus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
