import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stickerTypeClass',
})
export class StickerTypeClassPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'YELLOW':
        return 'warning';
      case 'GREEN':
        return 'success';
      case 'RED':
        return 'danger';
      case 'BLUE':
        return 'inform';
      case 'NEW':
        return 'important';
    }
    return 'success';
  }
}
