import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { CustomerBillDto, OrderDto } from '@btl/order-bff';
import { CustomerService } from '@service/customer.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  Search,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { InvoicePayComponent } from 'app/components/page/invoice-pay/invoice-pay.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { PaymentUtils } from '../../../helpers/payment-utils';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-financial-documents-component',
  templateUrl: './ecare-financial-documents.component.html',
})
@EnableDynamicLoading({ customName: EcareFinancialDocumentsComponent.PAGE_ID })
export class EcareFinancialDocumentsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareFinancialDocumentsComponent';

  pageId(): string {
    return EcareFinancialDocumentsComponent.PAGE_ID;
  }

  financialDocuments: CustomerBillDto[] = [];

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  customerAccountId;
  moreRecordsExists = false;
  canDisplayNoFinancialDocumentsText = false;

  paymentParams;

  orderDto;
  search: Search = {
    filtering: [],
    sorting: [{ column: 'billDate', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    private readonly fb: FormBuilder,
    private readonly customerLocalStorageService: CustomerLocalStorageService,
    private readonly orderingService: WcOrderingService,
    private readonly appBlockerService: AppBlockerService,
    public modal: NgbModal,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.paymentParams = queryParams;
      this.customerAccountId = this.paymentParams.customerAccountId;

      const successCallback = (order: OrderDto) => {
        this.orderDto = order;
      };
      PaymentUtils.confirmOrderInstantly(this.orderingService, this.paymentParams, successCallback);
      if (this.customerAccountId) {
        this.search.filtering.push({
          column: 'billingAccount.id',
          compareType: 'EQUAL',
          value: this.customerAccountId,
        });
      } else {
        this.search.filtering.push({
          column: 'billingAccount.id',
          compareType: 'IN',
          value: this.customerLocalStorageService.getCurrentFilterPartyIds(),
        });
      }

      const d = new Date();
      d.setMonth(d.getMonth() - 6);
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      this.search.filtering.push({
        column: 'billDate',
        compareType: 'BIGGEREQ',
        value: d,
      });
      this.loadFinancialDocumentsBySearch(this.search);
    });
  }

  onPay(invoiceDto: CustomerBillDto): void {
    this.paymentParams = null;
    const modalRef = this.modal.open(InvoicePayComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'invoice-pay-modal',
    });
    const invoicePayComponent = <InvoicePayComponent>modalRef.componentInstance;
    invoicePayComponent.dialogRef = modalRef;
    invoicePayComponent.setInvoice(invoiceDto);
    invoicePayComponent.customerAccountExtId = this.customerAccountId;
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadFinancialDocumentsBySearch(this.search);
  }

  loadFinancialDocumentsBySearch(search: Search) {
    search.filtering = search.filtering.filter(
      filter => !(filter.column === 'remainingAmount.amount' && !filter.value),
    );
    search.filtering
      .filter(filter => filter.column === 'remainingAmount.amount')
      .forEach(filter => {
        if (filter.value) {
          filter.value = 0;
          filter.compareType = CompareTypeDtoEnum.BIGGER;
        }
      });
    this.customerService
      .getFinancialDocumentsByFilter(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.financialDocuments = result.customerBills;
          if (result.customerBills.length > 0 && result.customerBills.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  getStickerType(value) {
    if (value == 'new') {
      return 'important';
    }
    if (value == 'onHold') {
      return 'danger';
    }
    if (value == 'validated') {
      return 'default';
    }
    if (value == 'sent') {
      return 'success';
    }
    if (value == 'settled') {
      return '';
    }
    if (value == 'partiallyPaid') {
      return 'warning';
    }
  }

  existsRemainingAmount(financialDocument: CustomerBillDto): boolean {
    return financialDocument.remainingAmount && financialDocument.remainingAmount.value > 0;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;

    if (pos === max && this.moreRecordsExists) {
      this.search.paging.page++;
      this.loadFinancialDocumentsBySearch(this.search);
    }
  }
}
