<div class="form ecare-filter" [formGroup]="filterForm">
  <div class="-filter-fields">
    <input
      id="002_notification_history_filter"
      type="text"
      formControlName="recipient"
      class="filter-input-id"
      placeholder="{{ 'wc.ecare.notificationsFilter.recipientAddress.placeholder' | translate }}" />

        <app-advanced-filter
          clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
          [form]="filterForm"
          [isAdvancedFilterOpen]="isAdvancedFilterOpen"
          [changedFilterFieldsCount]="changedFilterFieldsCount"
          (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
          (resetFieldsEmitter)="resetFilterFields()">
          <app-advanced-filter-field labelLocalizedKey="wc.ecare.notifications.notificationTypes" fieldType="select">
            <app-input-select-form-field
              formControlName="notificationType"
              translationPrefix="wc.ecare.notifications.notificationTypes"
              labelOption="notificationType"
              valueOption="notificationType"
              [showEmptyOption]="true"
              emptyOptionTranslationKey="wc.ecare.notifications.notificationTypes.default"
              [options]="notificationTypes"
              [noLabel]="true"
              (selectionChangeEmit)="changeFilterField('notificationType', $event?.code)" />
          </app-advanced-filter-field>
          <app-advanced-filter-field labelLocalizedKey="wc.ecare.notifications.gateways" fieldType="select">
            <app-input-select-form-field
              formControlName="gateway"
              translationPrefix="wc.ecare.notifications.gateways"
              labelOption="code"
              valueOption="code"
              [showEmptyOption]="true"
              emptyOptionTranslationKey="wc.ecare.notifications.gateways.default"
              [options]="gateways"
              [noLabel]="true"
              (selectionChangeEmit)="changeFilterField('gateway', $event?.code)" />
          </app-advanced-filter-field>
          <app-advanced-filter-field labelLocalizedKey="wc.ecare.notifications.channelTypes" fieldType="select">
            <app-input-select-form-field
              formControlName="channelType"
              translationPrefix="wc.ecare.notifications.channelTypes"
              labelOption="name"
              valueOption="name"
              [showEmptyOption]="true"
              emptyOptionTranslationKey="wc.ecare.notifications.channelTypes.default"
              [options]="channelTypes"
              [noLabel]="true"
              (selectionChangeEmit)="changeFilterField('channelType', $event?.code)" />
          </app-advanced-filter-field>
          <app-advanced-filter-field labelLocalizedKey="wc.ecare.notifications.states" fieldType="select">
            <app-input-select-form-field
              formControlName="state"
              translationPrefix="wc.ecare.notifications.states"
              labelOption="name"
              valueOption="name"
              [showEmptyOption]="true"
              emptyOptionTranslationKey="wc.ecare.notifications.states.default"
              [options]="states"
              [noLabel]="true"
              (selectionChangeEmit)="changeFilterField('state', $event?.code)" />
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.notifications.sentFrom"
            controlName="sentFrom"
            startOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.notifications.sentFrom.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            controlName="sentTo"
            endOfTheDay="true"
            placeholderLocalizedKey="wc.ecare.notifications.sentTo.placeholder"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
        </app-advanced-filter>
      </div>

      <app-button
        variant="primary"
        id="004_notification_history_filter"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </div>
