import { Component } from '@angular/core';
import { AbstractTicketExpandedRowComponent } from '../../tickets/abstract-ticket-expanded-row.component';

@Component({
  selector: 'app-contact-history-expanded-row-component',
  templateUrl: './ecare-contact-history-expanded-row.component.html'
})
export class EcareContactHistoryExpandedRowComponent extends AbstractTicketExpandedRowComponent {

}
