import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-customers-filter',
  templateUrl: './ecare-customers-filter.component.html',
})
export class EcareCustomersFilterComponent extends ComplexFilter {
  protected advancedFilterFields = ['firstName', 'lastName', 'identityNumber', 'registrationNumber'];

  filterForm = this.formBuilder.group({
    id: [],
    extId: [],
    firstName: [],
    lastName: [],
    identityNumber: [],
    registrationNumber: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id', CompareType.CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.extId, 'extId', CompareType.CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(
      this.filterForm.controls.firstName,
      'subject.firstName',
      CompareType.CompareTypeDtoEnum.LIKE
    ),
    new ControlFilterMiner(this.filterForm.controls.lastName, 'subject.lastName', CompareType.CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(
      this.filterForm.controls.identityNumber,
      'subject.identityNumber',
      CompareType.CompareTypeDtoEnum.LIKE
    ),
    new ControlFilterMiner(
      this.filterForm.controls.registrationNumber,
      'subject.registrationNumber',
      CompareType.CompareTypeDtoEnum.LIKE
    ),
  ];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected getDefaultFilterValues() {
    return {
      id: null,
      extId: null,
      firstName: null,
      lastName: null,
      identityNumber: null,
      registrationNumber: null,
    };
  }
}
