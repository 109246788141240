<ng-container *ngIf="showForm; else showResult">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.shopping.gui_productDetail.watchdog.formHeader' | translate }}
    </h4>
    <div class="modal-close" (click)="resetForm()">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>
  <div class="modal-body">
    <form #watchdogForm="ngForm" (ngSubmit)="onFormSubmit(watchdogForm)">
      <div class="watchdog-popup-indent">
        <div class="">
          <div class="">
            <input
              #emailInput="ngModel"
              required
              type="text"
              id="hw-watch-dog-email"
              class=""
              [ngModel]="emailField"
              [pattern]="emailPattern"
              [name]="FIELD_EMAIL"
              [class.o2-control__validation--error]="formSubmitted && emailInput.errors"
              placeholder="{{ 'wc.shopping.gui_productDetail.watchdog.contactEmail' | translate }}" />
          </div>
          <div *ngIf="formSubmitted && emailInput.errors?.pattern" class="alert alert-danger">
            {{ 'wc.shopping.gui_productDetail.watchdog.validationEmail' | translate }}
          </div>
          <div *ngIf="formSubmitted && emailInput.errors?.required" class="alert alert-danger">
            {{ 'wc.shopping.gui_productDetail.watchdog.validationEmail' | translate }}
          </div>
        </div>
      </div>
      <div class="watchdog-popup-indent">
        <div class="">
          <div class="">
            <textarea
              #noteInput="ngModel"
              class="form-control"
              rows="4"
              maxlength="2000"
              type="text"
              id="hw-watch-dog-note"
              [ngModel]="noteField"
              [name]="FIELD_NOTE"
              [class.o2-control__validation--error]="formSubmitted && noteInput.errors"
              placeholder="{{ 'wc.shopping.gui_productDetail.watchdog.note' | translate }}">
            </textarea>
          </div>
        </div>
      </div>
      <div class="watchdog-popup-indent">
        <div class=" ">
          <div class="">
            <div class="watchdog-popup-container">
              <div class="watchdog-gdpr">
                <input
                  #gdprCheck="ngModel"
                  required
                  type="checkbox"
                  class=""
                  id="hw-watch-dog-agreement-1"
                  [ngModel]="gdprCheckbox"
                  [name]="FIELD_GDPR"
                  [class.o2-control__validation--error]="formSubmitted && gdprCheck.errors" />
                <label
                  class=""
                  for="hw-watch-dog-agreement-1"
                  [innerHTML]="'wc.shopping.gui_productDetail.watchdog.gdprAgreement' | translate"></label>
              </div>
              <div *ngIf="formSubmitted && gdprCheck.errors?.required" class="alert alert-danger">
                {{ 'wc.shopping.general.checkBox.agreementIsMandatory' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      variant="primary"
      form="ngForm"
      size="fullWidth"
      label="{{ 'wc.shopping.gui_productDetail.watchdog.submitButton' | translate }}"
      (click)="watchdogForm.ngSubmit.emit()"></app-button>
  </div>
</ng-container>
<ng-template #showResult>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.shopping.gui_productDetail.watchdog.formHeader' | translate }}
    </h4>
    <div class="modal-close" (click)="resetForm()">
      <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="successful; else failed" class="watchdog-popup-indent">
      <div>
        <h3 id="005_watchdog_subscription" class="alert alert-success">
          {{ 'wc.shopping.gui_productDetail.watchdog.success' | translate }}
        </h3>
      </div>
      <div class="">
        {{ 'wc.shopping.gui_productDetail.watchdog.info' | translate }}
      </div>
    </div>
    <ng-template #failed>
      <div class="watchdog-popup-indent">
        <div class="alert alert-danger">
          {{ 'wc.shopping.gui_productDetail.watchdog.failure' | translate }}
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <app-button
      variant="secondary"
      size="fullWidth"
      label="{{ 'wc.common.ok.button' | translate }}"
      (click)="resetForm()"></app-button>
  </div>
</ng-template>
