import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AbstractPageComponent,
  AppBlockerService,
  BlockTemplateComponent,
  EnableDynamicLoading,
  Search,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportDto, ReportFrontendService } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-reports',
  templateUrl: './ecare-reports.component.html',
})
@EnableDynamicLoading({ customName: EcareReportsComponent.PAGE_ID })
export class EcareReportsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareReportsComponent';

  pageId(): string {
    return EcareReportsComponent.PAGE_ID;
  }

  reports: ReportDto[] = [];
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  moreRecordsExists = false;
  pagedDto;
  defaultReports;
  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private reportFrontendService: ReportFrontendService,
    private appBlockerService: AppBlockerService
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.loadReportsBySearch(this.search);
  }

  handleFilterChange() {
    this.search.paging.page = 1;
    this.loadReportsBySearch(this.search);
  }

  loadReportsBySearch(search: Search) {
    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);
    this.reportFrontendService
      .getReports()
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.reports = result.data;
          //ToDo remove when mock urls will be fixed BTL-6690
          result.data.forEach(report => {
            report.urlDetail = null;
            report.urlDownload = null;
          });

          this.defaultReports = result.data;
          if (result.data?.length > 0 && result.data?.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  openUrl(url) {
    window.open(url);
  }

  getReportTypeArea(typeArea) {
    let iconName;
    switch (typeArea) {
      case 'Financial':
        iconName = 'financial';
        break;
      case 'Resources':
        iconName = 'resources';
        break;
      case 'Trouble Ticketing':
        iconName = 'troubleTicketing';
        break;
      case 'Lead Management':
        iconName = 'management';
        break;
    }
    return iconName;
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadReportsBySearch(this.search);
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadReportsBySearch(this.search);
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadReportsBySearch(this.search);
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadReportsBySearch(this.search);
  }

  filterReportsByArea(filter) {
    if (filter.$ngOptionValue === null) {
      this.reports = this.defaultReports;
    } else {
      this.reports = this.defaultReports.filter(report => report.type.area === filter.area);
    }
  }

  filterReportsByType(filter) {
    if (filter.$ngOptionValue === null) {
      this.reports = this.defaultReports;
    } else {
      this.reports = this.defaultReports.filter(report => report.type.type === filter.type);
    }
  }
}
