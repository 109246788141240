<div *blockUI="blockUIElement.name; template: blockTemplate">
  <app-product-filter
    *ngIf="enableFiltering === true"
    [pagedProductInfosDto]="pagedProductInfosDto"
    (onChange)="handleFilterChange($event)">
  </app-product-filter>

  <div class="productListing productListing-{{ viewType }}">
    <div
      *ngIf="
        viewType !== productListingViewTypes.SELECT_BOX &&
        viewType !== productListingViewTypes.RADIO &&
        viewType !== productListingViewTypes.MIXED
      "
      class="products__list">
      <div
        *ngFor="let product of pagedProductInfosDto?.data"
        class="product"
        app-product-listing-item
        [product]="product"
        [parentProductId]="parentProductId"
        [parentInstanceIds]="parentInstanceIds"
        [partyId]="partyId"
        [originalProduct]="originalProduct"
        [originalProductPartyId]="originalProductPartyId"></div>
    </div>

    <ng-container *ngIf="viewType === productListingViewTypes.MIXED">
      <ng-container *ngIf="pagedProductInfosDto?.data.length > 2; else radio_button">
        <select id="001_product_listing_old" class="select" (change)="changeSelection($event)">
          <ng-container *ngIf="showEmptyOption">
            <option [value]=""></option>
          </ng-container>
          <option
            *ngFor="let product of pagedProductInfosDto?.data"
            [selected]="selectedValue === product.id"
            [value]="product.id">
            {{ product.name }}
          </option>
        </select>
      </ng-container>

      <ng-template #radio_button>
        <div class="tarif radio">
          <ng-container *ngIf="showEmptyOption">
            <input type="radio" id="0" name="tariff" value="" (change)="changeSelection($event)" />
            <label for="0">
              <span class="radioCheck"></span>
              <div class="tarif-name">{{ 'wc.shopping.gui_productListing.withoutTariff' | translate }}</div>
              <div class="tarif-price">0</div>
            </label>
          </ng-container>
          <ng-container *ngFor="let product of pagedProductInfosDto?.data">
            <input
              type="radio"
              name="tariff"
              id="{{ product.id }}"
              [value]="product.id"
              [checked]="selectedValue === product.id"
              (change)="changeSelection($event)" />
            <label for="{{ product.id }}">
              <span class="radioCheck"></span>
              <div class="tarif-name">{{ product.name }}</div>
              <div class="tarif-price" [innerHTML]="product.prices.RC.price | price"></div>
            </label>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>

    <select
      *ngIf="viewType === productListingViewTypes.SELECT_BOX"
      id="002_product_listing_old"
      class="select"
      (change)="changeSelection($event)">
      <ng-container *ngIf="showEmptyOption">
        <option [value]=""></option>
      </ng-container>
      <option
        *ngFor="let product of pagedProductInfosDto?.data"
        [selected]="selectedValue === product.id"
        [value]="product.id">
        {{ product.name }}
      </option>
    </select>

    <div *ngIf="shouldDisplayNoProductsText()" class="productListing__noProduct">
      {{ 'wc.shopping.productListing.noProducts.text' | translate }}
    </div>
  </div>
</div>
