<div class="modal-header">
  <h4 id="001_order_collision_popup" class="modal-title">{{ 'wc.shopping.collision.popup.header' | translate }}</h4>
  <div class="modal-close" (click)="close()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  {{ 'wc.shopping.collision.popup.description' | translate }}
</div>
<div class="modal-footer">
  <div>
    <app-button
      variant="link"
      ngbAutofocus
      label="{{ 'wc.shopping.collision.popup.button.cancel' | translate }}"
      (click)="close()"></app-button>
    <app-button
      variant="danger"
      label="{{ 'wc.shopping.collision.popup.button.continue' | translate }}"
      (click)="cancelOrderAndStartNewScenario()"></app-button>
  </div>
</div>
