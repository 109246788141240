<app-button
  variant="secondary"
  size="shrink"
  label="{{ 'wc.shopping.cart.backToCart' | translate }}"
  [routerLink]="['/eshop/shopping-cart']"></app-button>
<div ngbDropdown placement="bottom-right">
  <div
    app-button
    id="shoppingCartDropdown"
    ngbDropdownToggle
    [variant]="'secondary'"
    [iconPath]="'/assets/svg/three-dots.svg'"></div>
  <div ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
    <button ngbDropdownItem class="addAnotherProduct" [routerLink]="['/eshop/product-listing/hw']">
      <i class="add-product-icon" [inlineSVG]="'/assets/svg/add.svg'"></i
      >{{ 'wc.shopping.cart.addAnotherProduct' | translate }}
    </button>
    <button ngbDropdownItem (click)="suspendOrderPopup()">
      <i [inlineSVG]="'/assets/svg/pause.svg'"></i>{{ 'wc.shopping.cart.suspend.label' | translate }}
    </button>
  </div>
</div>
