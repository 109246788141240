import { PaymentComponent } from '../components/wizard/payment/payment.component';
import { Params } from '@angular/router';
import { OrderDto, OrderParamDto } from '@btl/order-bff';
import { OrderUtils } from './order-utils';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderStateTypeEnum } from '../models/orderStateTypeEnum';

export class PaymentUtils {
  /**
   * Test if the payment was successful. The test is based on URL query parameters.
   *
   * @param urlQueryParameters The URL query parameters.
   */
  static testPaymentSuccess(urlQueryParameters: Params): boolean {
    if (
      urlQueryParameters[PaymentComponent.PAYMENT_FAIL_URL_QUERY_PARAMETER] ||
      urlQueryParameters[PaymentComponent.PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER]
    ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get internal order ID from the URL query parameters.
   *
   * @param urlQueryParameters The URL query parameters.
   */
  static getInternalOrderId(urlQueryParameters: Params): string {
    return urlQueryParameters[PaymentComponent.NTS_INTERNAL_ORDER_ID_URL_QUERY_PARAMETER];
  }

  /**
   * Get NTS order ID from the URL query parameters.
   *
   * @param urlQueryParameters The URL query parameters.
   */
  static getNtsOrderId(urlQueryParameters: Params): string {
    return urlQueryParameters[PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER];
  }

  static confirmOrderInstantly(
    orderingService: WcOrderingService,
    urlQueryParameters: Params,
    successCallback: (OrderDto) => void,
    errorCallback: (any) => void = (error: any) => {}
  ): void {
    const orderId = PaymentUtils.getInternalOrderId(urlQueryParameters);
    if (orderId) {
      const ntsOrderId = PaymentUtils.getNtsOrderId(urlQueryParameters);
      const paymentSuccess = PaymentUtils.testPaymentSuccess(urlQueryParameters);

      if (ntsOrderId && paymentSuccess) {
        orderingService.getOrderByRefNo(orderId).subscribe((order1: OrderDto) => {
          if (
            !(Object.getOwnPropertyNames(order1).length === 0) &&
            order1.orderStateType !== OrderStateTypeEnum.CONFIRMED &&
            order1.orderStateType !== OrderStateTypeEnum.FINISHED
          ) {
            const orderParamsDto: Array<OrderParamDto> = [];
            OrderUtils.updateOrderAttr(orderParamsDto, 'PAYMENT_REF', ntsOrderId);

            const orderAsMap = {
              recordVersion: order1.recordVersion,
              orderAttributes: orderParamsDto,
              orderStateType: OrderStateTypeEnum.CONFIRMED,
            };
            orderingService.patchOrder(order1.id, orderAsMap, true).subscribe((order2: OrderDto) => {
              if (order2) {
                successCallback(order2);
              }
            }, errorCallback);
          }
        }, errorCallback);
      }
    }
  }
}
