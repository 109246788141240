import { Pipe, PipeTransform } from '@angular/core';
import { PriceValidity } from '../models/product';

/**
 * HTML template pipe to find the correct common price
 */
@Pipe({
  name: 'priorityPrice',
})
export class PriorityPricePipe implements PipeTransform {
  transform(prices: Array<PriceValidity>, priceType: string = 'default', returnTax: boolean = false): any {
    if (!Array.isArray(prices) || prices.length === 0) {
      return null;
    }
    const filteredByType = prices.filter(price => price.priceType === priceType);
    const currentDate = new Date();
    const filteredByDate = filteredByType.filter(function (price) {
      if (
        (!price.valid_from || new Date(price.valid_from) <= currentDate) &&
        (!price.valid_to || new Date(price.valid_to) >= currentDate)
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (!filteredByDate || filteredByDate.length === 0) {
      return null;
    }
    filteredByDate.sort((a, b) => b.value - a.value);
    if (returnTax) {
      return filteredByDate[filteredByDate.length - 1].taxRate;
    }
    return filteredByDate[filteredByDate.length - 1].value;
  }
}
