import { Component, Input } from '@angular/core';
import { InvoicePayComponent } from '../../../page/invoice-pay/invoice-pay.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerBillDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-last-payment-widget, [app-ecare-last-payment-widget]',
  templateUrl: './ecare-last-payment-widget.component.html'
})
export class EcareLastPaymentWidgetComponent {
  @Input() lastInvoice: CustomerBillDto;

  constructor(public modal: NgbModal) {
  }

  existsRemainingAmount(financialDocument: CustomerBillDto): boolean {
    return financialDocument?.remainingAmount && financialDocument?.remainingAmount.value > 0;
  }

  onPay(invoiceDto: CustomerBillDto): void {
    const modalRef = this.modal.open(InvoicePayComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'invoice-pay-modal'
    });
    const invoicePayComponent = <InvoicePayComponent>modalRef.componentInstance;
    invoicePayComponent.dialogRef = modalRef;
    invoicePayComponent.setInvoice(invoiceDto);
  }
}
