<div class="card status-{{ addonState }}">
  <div class="card__header">
    <p class="card__header_title">
      <span *ngIf="addonState">{{ 'wc.ecare.addons.state.' + addonState | translate }}</span>
    </p>
    <h3 class="card_label">{{ addonProduct.name }}</h3>
    <app-acl-evaluate privilege="ACTIVATE_ADDON">
      <label
        class="switch pull-right"
        id="{{ parentId }}_001_addon_activation_addon"
        (change)="createAddonOrder($event.target.checked)">
        <input type="checkbox" [checked]="addonState === 'ACTIVATED'" [disabled]="isChangeInProgress" />
        <span class="slider"></span>
      </label>
    </app-acl-evaluate>
    <p class="card_description">{{ addonProduct.shortDescription }}</p>

    <p class="card_actual_label">{{ 'wc.ecare.addons.activation.addon.activationPrice.label' | translate }}</p>
    <p class="card_actual">
      <one-time-price [price]="addon.prices['OC'] ? addon.prices['OC'].price : 0"></one-time-price>
    </p>
    <p class="card_overdue_label">{{ 'wc.ecare.addons.activation.addon.recurrentPrice.label' | translate }}</p>
    <p class="card_overdue">
      <app-recurrent-price
        [price]="addon.prices['RC'] ? addon.prices['RC'].price : 0"
        [disableTexts]="true"></app-recurrent-price>
    </p>
  </div>
</div>
