import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb, CurrentLocaleService, GroupTreeNode, ProductGroupService } from '@btl/btl-fe-wc-common';
import { Location } from '@angular/common';
import { Subject } from 'rxjs/internal/Subject';
import { SeoService } from '@service/seo.service';
import { Router } from '@angular/router';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-breadcrumb, [app-breadcrumb]',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  groups: Array<GroupTreeNode>;

  @Input()
  breadcrumbs: Array<Breadcrumb>;
  @Input()
  groupBasedBreadcrumbs = false;
  @Input()
  showBacklink: boolean;

  currentProductGroup;
  breadcrumbsSubscription;
  allGroups;
  @HostBinding('class') classes = 'breadcrumb';

  constructor(
    private location: Location,
    private productGroupService: ProductGroupService,
    private currentLocaleService: CurrentLocaleService,
    private seoService: SeoService,
    private router: Router
  ) {}

  goBack() {
    this.location.back();
  }

  ngOnInit(): void {
    if (this.groupBasedBreadcrumbs) {
      this.productGroupService
        .getCurrentProductGroup()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(currentGroupTreeNode => {
          this.initBreadcrumbs(currentGroupTreeNode);
          this.currentProductGroup = currentGroupTreeNode;
        });

      this.currentLocaleService.currentLocaleChange
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.initBreadcrumbs(this.currentProductGroup));
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    if (this.breadcrumbsSubscription) {
      this.breadcrumbsSubscription.unsubscribe();
    }
  }

  private initBreadcrumbs(currentGroupTreeNode: GroupTreeNode) {
    if (this.breadcrumbsSubscription) {
      this.breadcrumbsSubscription.unsubscribe();
    }
    if (currentGroupTreeNode) {
      let groupsSubscription = of(this.groups);
      if (!this.groups) {
        groupsSubscription = this.productGroupService.getTreePathToGroup([currentGroupTreeNode.group.id]);
      }
      this.breadcrumbsSubscription = this.productGroupService
        .getProductGroups()
        .pipe(
          switchMap(allGroups => {
            this.allGroups = allGroups;
            return groupsSubscription;
          })
        )
        .pipe(
          map(groups => {
            if (groups.length > 0) {
              if (groups[0].children.length > 0) {
                groups[0].group.seoUrl = groups[0].children[0].group.seoUrl;
              }
            }

            if (!groups || !groups.length) {
              return [{ name: 'wc.shopping.gui_wishlist.breadCrumb.productCatalogue', seoUrl: '/' }];
            }
            return groups.map(group => this.getBreadcrumb(group.group));
          })
        )
        .subscribe(breadcrumbs => {
          this.breadcrumbs = breadcrumbs;
          this.setSeoData();
        });
    }
  }

  private getBreadcrumb(group) {
    return { name: this.productGroupService.getGroupName(group), seoUrl: `/eshop/product-listing/${group.seoUrl}` };
  }

  private setSeoData() {
    const currentGroupName = this.breadcrumbs.length ? this.breadcrumbs[this.breadcrumbs.length - 1].name : '';
    let description = '';
    const breadCrumbsNames = this.breadcrumbs.map(breadcrumb => breadcrumb.name);
    description = breadCrumbsNames.reverse().join(', ');
    const keywords = `${description}, ${SeoService.STATIC_KEYWORDS}`;
    this.seoService.setMeta(currentGroupName, description, keywords, this.getCanonicalUrl(currentGroupName));
  }

  private getCanonicalUrl(currentGroup): string {
    //when on listing search, do not return canonical url
    return location && currentGroup ? location.origin + this.router.url.split('?')[0] : null;
  }
}
