import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '@service/customer.service';
import { Router } from '@angular/router';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { PaymentMethodEnum } from '../../../models/paymentMethodEnum';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { AddressDto, BalancesAmountResultDto, ContactDto, CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-account-detail',
  templateUrl: './customer-account-detail.component.html',
})
export class CustomerAccountDetailComponent implements OnInit {
  //region Data:

  /**
   * OpenBalance result for the customer account & customer.
   */
  openBalanceResult: BalancesAmountResultDto;

  //region IO:

  /**
   * The customer account to display.
   */
  @Input()
  customerAccount: CustomerAccountDto;

  /**
   * The customer
   */
  @Input()
  customer: CustomerDto;

  PaymentTypeEnum = PaymentTypeEnum;
  PaymentMethodEnum = PaymentMethodEnum;
  addressTypes = addressTypes;
  invoiceAddress;
  billingContact: ContactDto;
  invoicingContact: ContactDto;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    public codebookService: CodebookService,
  ) {
  }

  //region Life-cycle:

  ngOnInit(): void {
    this.validateInputs();
    if (this.customerAccount.address && this.customerAccount.address.type === AddressDto.TypeDtoEnum.INVOICING) {
      this.invoiceAddress = this.customerAccount.address;
    }
    if (this.customerAccount.billingContact) {
      this.billingContact = this.customerAccount.billingContact;
    }
    if (this.customerAccount.invoicingContact) {
      this.invoicingContact = this.customerAccount.invoicingContact;
    }
    CustomerPartyUtil.updateCustomerAccountContacts(this.customerAccount);

    if (this.customer && this.customer.id) {
      this.customerService.getOpenBalance(this.customer.id, this.customerAccount.id).subscribe(openBalance => {
        this.openBalanceResult = openBalance;
      });
    }
  }

  //region Event handling:

  /**
   * Redirect the user to financial-documents.
   */
  goToFinancialDocuments(): void {
    this.router.navigate(['ecare', 'financial-documents'], {
      queryParams: {
        customerAccountId: this.customerAccount.id,
      },
    });
  }

  //region Helpers:

  /**
   * Validate component inputs.
   */
  private validateInputs(): void {
    if (!this.customerAccount) {
      throw new Error('The customerAccount parameter must have non-null value.');
    }
  }
}

export enum PaymentTypeEnum {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
}
