import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-documents-popup',
  templateUrl: './documents-popup.component.html',
})
export class DocumentsPopupComponent {
  public showPopup: boolean = false;

  @Input() entityType;
  @Input() entityId;

  closePopup() {
    this.showPopup = false;
  }

  showPopUp() {
    this.showPopup = true;
  }
}
