// TODO: Fix Dto´s

import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  AuthorizationService,
  CodebookService,
  CompareType,
  CurrentLocaleService,
  DefaultErrorHandler,
  DmsService,
  ProductGroupService,
  ProductService,
  SalesRecordService,
  ServiceUtils,
  StockService,
  StoreService
} from '@btl/btl-fe-wc-common';
import { FilterService } from 'app/services/filter.service';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { Observable } from 'rxjs';
import { UrlParamsService } from 'app/services/url-params.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyAccessorLocalService } from 'app/services/property-accessor-local.service';
import { KeyFeature, ParameterSection, ProductCustomService } from '@service/product-custom.service';
import { ColorService } from 'app/services/color.service';
import { map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PictureUtils } from 'app/helpers/picture-utils';
import { HwStoreMaterialManagementService } from 'app/services/hw-store-material-management.service';
import { PictureTypeEnum } from 'app/models/PictureTypeEnum';
import { ProductComparisonService } from 'app/services/product-comparison.service';
import { Product } from 'app/models/product';
import { DateService } from 'app/services/date.service';
import { ProductDetailComparisonCommonService } from 'app/services/product-detail-comparison-common.service';
import { FacebookButtonConfig, TwitterButtonConfig } from './content-share/content-share-icons.component';
import { ProductGalleryComponent } from './product-gallery/product-gallery.component';
import { StockCustomService } from 'app/services/stock-custom.service';
import { Constants } from 'app/constants';
import {
  AggregatedRatingDto,
  BASE_PATH,
  DmsFileDto,
  OrderDto,
  OrderItemDto,
  OrderParamDto,
  PictureDto,
  PriceDto,
  PriceTypeDto,
  ProductBundleDto,
  ProductDetailDto,
  ProductPictureHrefDto,
  ProductSortAttributeDto,
  SalesRecordDataDto,
  SortDto
} from '@btl/order-bff';

import { ProductListingComponent } from '../../product-listing/product-listing.component';
import { CategoryTypeEnum, ProductFilter } from '../../../../models/product-filter';
import { ProductListingViewType } from '../../product-listing/product-listing-view-type.type';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductUtils } from '../../../../helpers/product-utils';
import { ProductRatingsAndReviewsComponent } from './product-ratings-and-reviews/product-ratings-and-reviews.component';
import { SeoService } from '@service/seo.service';
import { OrderUtils, ScenarioStepTypeEnum } from 'app/helpers/order-utils';
import { BaseProductDetailComponent } from './base-product-detail.component';
import { HttpClient } from '@angular/common/http';
import { ShopSelectionPopupComponent } from '../../../page/shop-selection-popup/shop-selection-popup.component';
import { NavigationMenuService } from '@service/navigation-menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WatchdogSubscriptionComponent } from './watchdog-subscribe/watchdog-subscription.component';
import { WishListPopupComponent } from '../../wish-list/wish-list-popup/wish-list-popup.component';
import { ProductAddingToCart } from '@service/product-listing.service';
import { ProductsViewedService } from '@service/products-viewed.service';

//import LinkTypeDtoEnum = AddToBasketRequestProductLinkDto.LinkTypeDtoEnum;
import CollectionTypeDtoEnum = SalesRecordDataDto.CollectionTypeDtoEnum;
import SortOrderDtoEnum = SortDto.SortOrderDtoEnum;

@Component({
  selector: 'app-product-detail, [app-product-detail]',
  templateUrl: './product-detail.component.html',
})
export class ProductDetailComponent extends BaseProductDetailComponent implements OnInit, OnDestroy {
  @ViewChild('tariffProductListing', { static: false }) tariffProductListing: ProductListingComponent;
  @ViewChildren(ProductGalleryComponent)
  productGalleryComponents: Array<ProductGalleryComponent>;

  private similarProducts: Array<ProductDetailDto>;
  public productBought;
  public productViewed;
  private compatibleAccessories: Array<ProductDetailDto>;
  private addToBasketDeeplinkUrl: string;
  private displayAddToBasketError: boolean = false;
  private sections$: Observable<Array<ParameterSection>>;

  private tariffBundles: Array<ProductBundleDto>;
  private giftBundles: Array<ProductBundleDto> = [];

  // TARIFF
  tariffHwProduct: ProductDetailDto;
  parentTariff: ProductDetailDto;
  selectedTariffId: string;
  selectedTariff: OrderItemDto;
  categoryTypeEnum = CategoryTypeEnum;

  selectedHwLeasingId: string;
  selectedHwLeasingDetail: ProductDetailDto;
  stockAvalibility: boolean = false;

  filter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_HW,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: 8,
    },
  };

  tariffFilter: ProductFilter = {
    attributes: {
      categoryId: CategoryTypeEnum.PRODC_SU_TARIFF,
      parametersStatic: {},
      priceRange: {},
      text: '',
    },
    sorting: {
      column: ProductSortAttributeDto.NAME,
      sortOrder: SortOrderDtoEnum.Desc,
    },
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  // STORE RESERVATION
  private ReservationStatus = ReservationStatus;
  private reservationStatus: ReservationStatus = ReservationStatus.NONE;
  //private selectedStore: StoreDto;
  private phoneNumber: string;
  private gdprCheckbox: boolean;
  private storePage: number = 0;
  private endIndex: number;
  public priceDiscount: number = 0;

  private readonly phoneNumberRegex = '^[2-7]\\d{8}$';

  private keyFeatureParams = Array<KeyFeature>();
  private similarProductCount: number;
  private accessoriesCount: number;
  private additionalServices: Array<AdditionalService>;
  private selectedAdditionalServices: Array<string> = Array<string>();
  private dimensionPictureHref;
  private viewedProductsCount: number;
  private customersBoughtFromDate: Date;
  private viewedProducts: Array<Product> = [];
  private soldProductsCount: number;
  private soldProducts: Array<Product> = [];
  public archivedFlag: boolean = false;
  public rating: number = null;
  public script3dModel: boolean = false;
  public reviewQuantity: number = 0;
  public reviewToFive: number = 0;
  public selectedTariffBundle = null;
  public selectedGiftBundle = null;

  routeSubscibe;
  cartOrderItemDto: OrderItemDto;
  productPictureHint: ProductPictureHrefDto;
  productListingViewTypes = ProductListingViewType;
  ScenarioStepTypeEnum = ScenarioStepTypeEnum;

  @ViewChild('sectionTabs', { read: ElementRef }) sectionTabs: ElementRef;
  @ViewChild(ProductRatingsAndReviewsComponent) productRatingsAndReviewsComponent;

  priceBoxElementId = Constants.PRICE_BOX_ELEMENT_ID;

  private documents: Array<DmsFileDto>;

  constructor(
    private errorHandler: DefaultErrorHandler,
    private filterService: FilterService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private urlParamsService: UrlParamsService,
    protected httpClient: HttpClient,
    protected shoppingCartService: ShoppingCartService,
    protected propertyAccessorLocalService: PropertyAccessorLocalService,
    protected productCustomService: ProductCustomService,
    private colorService: ColorService,
    private codebookService: CodebookService,
    private stockService: StockService,
    private hwStoreMaterialManagementService: HwStoreMaterialManagementService,
    protected productComparisonService: ProductComparisonService,
    protected dateService: DateService,
    private authorizationService: AuthorizationService,
    private productDetailAndComparisonCommonService: ProductDetailComparisonCommonService,
    protected productGroupService: ProductGroupService,
    protected stockCustomService: StockCustomService,
    private salesRecordService: SalesRecordService,
    protected currentLocaleService: CurrentLocaleService,
    protected orderingService: WcOrderingService,
    protected router: Router,
    private seoService: SeoService,
    private dmsService: DmsService,
    protected navigationMenuService: NavigationMenuService,
    private ngbModal: NgbModal,
    protected storeService: StoreService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(BASE_PATH) public basePath,
    private productsViewedService: ProductsViewedService
  ) {
    super(
      router,
      productCustomService,
      stockCustomService,
      currentLocaleService,
      propertyAccessorLocalService,
      dateService,
      productGroupService,
      productComparisonService,
      orderingService,
      shoppingCartService,
      navigationMenuService,
      storeService
    );

    this.currentLocaleService.currentLocaleChange.subscribe(result => {
      if (this.product) {
        this.loadProduct(this.product.seoUrl);
      }
    });
  }

  ngOnInit() {
    this.product = null;
    const seoUrlSubscription = this.route.params.subscribe(params => {
      if (this.urlParamsService.getSeoUrl(params)) {
        const url = params['seoUrl'];
        // TODO check this.urlParamsService.getSeoUrl(params);
        this.loadProduct(url);
      }
    });
    //const addToBasketSubscription = this.propertyAccessorLocalService.getAddToBasketDeeplinkUrl().subscribe(url => this.addToBasketDeeplinkUrl = url);
    this.getGlobalDeliveryDaySubscription();
    this.allSubscriptions.push(seoUrlSubscription);
    //this.allSubscriptions.push(addToBasketSubscription);
    this.invokeRedirectToAddBasket();
    this.propertyAccessorLocalService.getStockAvalibilityBoolean().subscribe(response => {
      this.stockAvalibility = response === 'true';
    });
  }

  addChat() {
    let src;
    let token;
    let company;

    this.propertyAccessorLocalService
      .getChatEnabled()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        if (response === 'true') {
          this.propertyAccessorLocalService
            .getChatSrc()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(response => {
              src = response;
              this.propertyAccessorLocalService
                .getChatToken()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(response => {
                  token = response;
                  this.propertyAccessorLocalService
                    .getChatCompany()
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(response => {
                      company = response;
                      const script = document.createElement('script');
                      script.type = 'text/javascript';
                      script.src = src;
                      //@ts-ignore
                      script.$owidgetOnLoad = function (owidget) {
                        if (!owidget.isSupported) {
                          return;
                        }
                        owidget.init(token, company);
                        owidget.connectToServer();
                      };
                      document.getElementsByTagName('head')[0].appendChild(script);
                    });
                });
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    for (const subscription of this.allSubscriptions) {
      subscription.unsubscribe();
    }
  }

  public getSalesRecStartTime(): Date {
    if (this.customersBoughtFromDate) {
      return this.customersBoughtFromDate;
    } else {
      const customersBoughtTtlSubscription = this.propertyAccessorLocalService
        .getCustomersBoughtTtl()
        .subscribe(count => {
          this.customersBoughtFromDate = this.dateService.addDays(new Date(), -count);
          return this.customersBoughtFromDate;
        });
      this.allSubscriptions.push(customersBoughtTtlSubscription);
    }
  }

  loadProduct(productSeoUrl): void {
    const sessionId = sessionStorage.getItem(Constants.SESSIONID);
    if (this.product && this.product.seoUrl !== productSeoUrl) {
      this.rating = undefined;
    }
    const subscription = this.productService.getProductBySeoUrl(productSeoUrl).subscribe(productDetailDto => {
      this.product = productDetailDto;
      this.productDetailAndComparisonCommonService.loadProduct.emit(productDetailDto);
      if (!this.isTariff()) {
        //this.initArchived();
        this.initColorVariants();
        this.initParameterList();
        if (!this.archivedFlag) {
          this.initStocks();
          this.orderingService
            .getCurrentOrder()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(order => this.handleOrder(order));
          this.initAdditionalServices();
        }
        this.initCarrousels();
      } else {
        this.initBreadcrumbs([this.product.id]);
      }

      this.initBundles();

      this.checkScanRequired$ = this.productCustomService.isScanRequired(this.product.productCode);

      const salesRecordsSubscription = this.salesRecordService
        .getAggregatedSalesRecords([this.product.productCode], null, this.getSalesRecStartTime())
        .subscribe(result => {
          if (result.data && result.data.length > 0) {
            const customersBoughtMinCountSubscription = this.propertyAccessorLocalService
              .getCustomersBoughtMinCount()
              .subscribe(boughtMinCount => {
                this.productBought = result.data[0].count;
                if (
                  this.product.parametersStatic['customersBoughtEnabled'] !== 'true' ||
                  this.productBought < boughtMinCount
                ) {
                  this.productBought = null;
                }

              });
            this.allSubscriptions.push(customersBoughtMinCountSubscription);
          }
        });
      this.allSubscriptions.push(salesRecordsSubscription);

      if (this.product.documents.length > 0) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'extId',
          compareType: CompareType.CompareTypeDtoEnum.IN,
          value: this.product.documents.map(document => document.extId),
        });
        const salesDocumentsSubscription = this.dmsService.filterFiles(search, null).subscribe(result => {
          this.documents = result.data;
        });
        this.allSubscriptions.push(salesDocumentsSubscription);
      }

      if (this.productGalleryComponents && this.productGalleryComponents.length > 0) {
        this.productGalleryComponents.forEach(productGalleryComponent => {
          productGalleryComponent.initPictureAndVideoGallery(
            this.product.pictures,
            this.product.parametersStatic['youtubeVideoUrl']
          );
        });
      }
      this.dimensionPictureHref = this.getDimensionPictureHref();
      this.productsViewedService.addViewedProduct(this.product.productCode);
      if (this.product.parametersStatic['chatEnabled'] === 'true') {
        this.addChat();
      }
    });
    this.allSubscriptions.push(subscription);
  }

  getDmsFileText(file: DmsFileDto, textType): string {
    let ret = '';
    const textDto = file.texts.find(
      text => text.textType === textType && text.locale === this.currentLocaleService.getCurrentLanguage()
    );
    if (textDto) {
      ret = textDto.message;
    }
    return ret;
  }

  download(file: DmsFileDto) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(file.contentHref, httpOptions).subscribe((data: any) => {
      const blob = new Blob([data]);
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = file.name;
      link.click();
    });
  }

  initBundles() {
    const productPrice = this.product.prices['OC']?.price;
    this.productService
      .getProductBundles(this.product.productCode, 'TARIFF_BUNDLE')
      .pipe(
        map(productBundles =>
          productBundles.filter(productBundle => {
            const discountProducts = productBundle.products.filter(product => product.parametersStatic['isDiscount']);
            if (
              this.checkIfOneProductOfCategory(productBundle, CategoryTypeEnum.PRODC_SU_TARIFF) &&
              discountProducts.length === 1 &&
              productPrice &&
              productPrice - this.getAbsolutePriceDiscount(productBundle, productPrice) > 0
            ) {
              return true;
            }
            return false;
          })
        )
      )
      .pipe(
        map(productBundles =>
          productBundles.sort(
            (a, b) => this.getAbsolutePriceDiscount(b, productPrice) - this.getAbsolutePriceDiscount(a, productPrice)
          )
        ),
        tap(productBundles => {
          this.selectedTariffBundle = null;
          this.priceDiscount = 0;
          this.productDetailAndComparisonCommonService.loadDiscount.emit(this.priceDiscount);
        })
      )
      .subscribe(productBundles => {
        this.tariffBundles = productBundles;
      });

    this.productService
      .getProductBundles(this.product.productCode, 'GIFT_BUNDLE')
      .pipe(
        map(productBundles =>
          productBundles.filter(productBundle => {
            const discountProducts = productBundle.products.filter(product => product.parametersStatic['isDiscount']);
            if (
              this.checkIfOneProductOfCategory(productBundle, CategoryTypeEnum.PRODC) &&
              !(discountProducts.length > 1) &&
              (this.checkProductPrices(productBundle, PriceTypeDto.OC) ||
                (this.checkProductPrices(productBundle, PriceTypeDto.RC) &&
                  this.getBundleDiscountPrice(productBundle).price -
                    this.getAbsolutePriceDiscount(
                      productBundle,
                      this.getBundleGiftProduct(productBundle).prices['OC'].price
                    ) > 0))
            ) {
              return true;
            }
            return false;
          })
        ),
        tap(productBundles => {
          this.selectedGiftBundle = null;
        })
      )
      .subscribe(productBundles => {
        this.giftBundles.length = 0;
        productBundles.forEach(giftBundle => {
          const giftProduct = this.getBundleGiftProduct(giftBundle);
          if (giftProduct.categoryId === CategoryTypeEnum.PRODC_GE_HW) {
            this.stockCustomService.getStockCentral(giftProduct.productCode).subscribe(stock => {
              if (stock && stock.realStock > 0) {
                this.giftBundles.push(giftBundle);
              }
            });
          } else {
            this.giftBundles.push(giftBundle);
          }
        });
      });
  }

  checkProductPrices(productBundleDto: ProductBundleDto, priceType: string): boolean {
    let pricesOK = true;
    productBundleDto.products.forEach(product => {
      if (!product.prices[priceType]) {
        pricesOK = false;
      }
    });

    return pricesOK;
  }

  checkIfOneProductOfCategory(productBundleDto: ProductBundleDto, categoryId: string): boolean {
    const products = productBundleDto.products.filter(
      product => ProductUtils.isOfCategory(product, categoryId) && !product.parametersStatic['isDiscount']
    );
    if (products.length === 1) {
      return true;
    }
    return false;
  }

  getBundleDiscountPrice(productBundleDto: ProductBundleDto): PriceDto {
    const discount = this.getBundleDiscountProduct(productBundleDto);
    return discount.prices['OC'];
  }

  getDimensionPictureHref() {
    return PictureUtils.getPictureHrefByType(this.product, PictureTypeEnum.PRODUCT_DIMENSIONS);
  }

  getBundleTariffPrice(productBundleDto: ProductBundleDto): PriceDto {
    const tariff = this.getBundleTariffProduct(productBundleDto);
    return tariff.prices['RC'];
  }

  public redirectToAddToBasket(preorder: boolean = false, fast: boolean = false) {
    /*    this.displayAddToBasketError = false;
        Async.async(this.shoppingCartService.getAddToBasketRequestData(this.getAddToBasketRequestDto(preorder, fast)))
          .then(addToBasketModel => {
            const addToBasketForm = window.document.createElement("form");
            addToBasketForm.setAttribute("method", "POST");
            addToBasketForm.setAttribute("action", this.addToBasketDeeplinkUrl);
            addToBasketModel.params.forEach(
              p => ProductDetailComponent.addHiddenField(addToBasketForm, p.name, p.value)
            );
            window.document.body.appendChild(addToBasketForm);
            addToBasketForm.submit();
            window.document.body.removeChild(addToBasketForm);
          }, error => {
            this.displayAddToBasketError = true;
            if (error.status === 404) { // out of stock
              this.initStocks();
            } else {
              this.errorHandler.handleError("Error has occurred while calling addToBasket", error);
            }
          });*/
  }

  handleProductAddingToCart(product: ProductDetailDto): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(currentOrder, product as ProductAddingToCart, this.selectedTariffId)
        .subscribe((order: OrderDto) => {
          if (order) {
            if (this.testHwLeasing()) {
              const hw = order.orderItems.find((orderItem: OrderItemDto) => {
                return product.id === orderItem.productId;
              });

              this.orderingService
                .addProductToShoppingCartWithParent(
                  order,
                  this.selectedHwLeasingDetail as ProductAddingToCart,
                  this.selectedTariff.productId,
                  this.selectedTariff.id
                )
                .subscribe((order2: OrderDto) => {
                  if (order2) {
                    this.router.navigate(['eshop/technology-check']);
                  }
                });
            } else {
              this.router.navigate(['eshop/technology-check']);
            }
          }
        });
    };
    this.orderingService.getCurrentOrder().subscribe(addOrderItem);
  }

  getCrossedPrice(productDetailDto: ProductDetailDto): number {
    return this.productCustomService.getCrossedPrice(productDetailDto);
  }

  public shouldDisplayPreorderButton(): boolean {
    const sysdate = new Date();
    const preorderDateFrom = this.product.parametersStatic['preorderDateFrom'];
    const preorderDateTo = this.product.parametersStatic['preorderDateTo'];
    if (
      preorderDateFrom &&
      preorderDateTo &&
      new Date(preorderDateFrom) <= sysdate &&
      sysdate <= new Date(preorderDateTo)
    ) {
      const shouldDisplayValue = this.realStockCentral === 0 && this.virtualStockCentral > 0;
      this.productDetailAndComparisonCommonService.showPreorderButton.emit(shouldDisplayValue);
      return shouldDisplayValue;
    } else {
      this.productDetailAndComparisonCommonService.showPreorderButton.emit(false);
      return false;
    }
  }

  private initParameterList() {
    const metadata$ = this.productService.getProductParameterMetadata(this.product.categoryId).pipe(share());
    const keyFeatureSubscription = this.productCustomService
      .getKeyFeatures(this.product, metadata$)
      .subscribe(keyFeatures => {
        this.keyFeatureParams = keyFeatures;
        this.setSeoData();
      });
    this.allSubscriptions.push(keyFeatureSubscription);
    this.sections$ = this.productCustomService.getParameterSections(this.product, metadata$);
  }

  selectTariffBundle(productBundleDto: ProductBundleDto, discountRadio: HTMLInputElement) {
    this.selectedTariffBundle = productBundleDto;
    this.selectedTariffId = this.getBundleTariffProduct(productBundleDto).id;
    this.priceDiscount = this.getAbsolutePriceDiscount(productBundleDto, this.product.prices['OC'].price);
    this.productDetailAndComparisonCommonService.loadDiscount.emit(this.priceDiscount);
  }

  selectHwProductVariant() {
    this.selectedTariffBundle = null;
    this.priceDiscount = 0;
    this.productDetailAndComparisonCommonService.loadDiscount.emit(this.priceDiscount);
  }

  private getAbsolutePriceDiscount(productBundleDto: ProductBundleDto, productPrice: number): number {
    const discountProductPrice = this.getBundleDiscountPrice(productBundleDto);
    let priceDiscount: number = 0;
    if (discountProductPrice.priceAppType === 'ABS') {
      priceDiscount = discountProductPrice.price ? discountProductPrice.price : 0;
    } else if (discountProductPrice.priceAppType === 'REL') {
      priceDiscount = (productPrice * discountProductPrice.price) / 100;
    }
    return priceDiscount;
  }

  toggleProperties(hwMoreProtertiesSm: HTMLDivElement, showPropertiesSm: HTMLAnchorElement) {
    if (hwMoreProtertiesSm.hidden) {
      hwMoreProtertiesSm.hidden = false;
      showPropertiesSm.text = showPropertiesSm.dataset.toggleOpenText;
    } else {
      hwMoreProtertiesSm.hidden = true;
      showPropertiesSm.text = showPropertiesSm.dataset.toggleClosedText;
    }
  }

  private initCarrousels() {
    this.initSimilarProducts();
    this.initCompatibleAccessories();
    const viewedProductsCountSubscription = this.propertyAccessorLocalService.getViewedProductCount().subscribe(count => {
      this.viewedProductsCount = count;
    });
    this.allSubscriptions.push(viewedProductsCountSubscription);
    this.initSalesProductsByCollectionType(this.viewedProducts, CollectionTypeDtoEnum.VIEWED);
    const soldProductsCountSubscription = this.propertyAccessorLocalService.getSoldProductCount().subscribe(count => {
      this.soldProductsCount = count;
    });
    this.allSubscriptions.push(soldProductsCountSubscription);
    this.initSalesProductsByCollectionType(this.soldProducts, CollectionTypeDtoEnum.SOLD);
  }

  private initSalesProductsByCollectionType(products: Array<Product>, collectionType: CollectionTypeDtoEnum) {
    const salesDataSubscription = this.salesRecordService
      .getAggregatedSalesData(
        this.product.productCode,
        collectionType,
        this.getSalesRecStartTime(),
        null,
        null,
        1,
        20
      )
      .subscribe(result => {
        const productCodes = result.data.map( productCount => productCount.productId)
          .filter(product => product != this.product.productCode);
        products.length = 0;
        if (result.data && result.data.length) {
          const productsSubscription = this.productCustomService
            .getProductsFromElasticByProductCodes(productCodes, true)
            .subscribe(elasticProducts => {
              products.push(...elasticProducts);
            });
          this.allSubscriptions.push(productsSubscription);
        }
      });
    this.allSubscriptions.push(salesDataSubscription);
  }

  private initSimilarProducts() {
    const similarProductsSubscription = this.productService
      .getGroupProducts(null, 'SIMILAR_PRODUCT', this.product.productCode)
      .subscribe(products => {
        this.similarProducts = undefined;
        this.similarProducts = products.filter(prod => this.product.productCode !== prod.productCode);
      });
    this.allSubscriptions.push(similarProductsSubscription);
    const subscription = this.propertyAccessorLocalService
      .getSimilarProductCount()
      .subscribe(count => (this.similarProductCount = count));
    this.allSubscriptions.push(subscription);
  }

  private initCompatibleAccessories() {
    const targetRelationshipsSubscription = this.productService
      .getTargetRelationshipProducts(this.product.productCode, 'COMPATIBLE_ACCESSORY')
      .pipe(switchMap(products => this.productCustomService.filterOutUnavailableProducts(products)))
      .subscribe(products => {
        this.compatibleAccessories = products;
      });
    this.allSubscriptions.push(targetRelationshipsSubscription);
    const subscription = this.propertyAccessorLocalService
      .getAccessoriesCount()
      .subscribe(count => (this.accessoriesCount = count));
    this.allSubscriptions.push(subscription);
  }

  private initAdditionalServices() {
    const targetRelationshipsSubscription = this.productService
      .getTargetRelationshipProducts(this.product.productCode, 'ADDITIONAL_SERVICE')
      .pipe(
        switchMap(products => this.productCustomService.filterOutUnavailableProducts(products)),
        map(products => {
          const additionalServices: Array<AdditionalService> = new Array<AdditionalService>();
          for (const product of products) {
            if (product.prices['OC'] || product.prices['RC']) {
              const additionalService: AdditionalService = {
                name: product.name,
                price: product.prices['OC'] ? product.prices['OC'].price : product.prices['RC'].price,
                recurrent: !product.prices['OC'],
                productCode: product.productCode,
              };
              additionalServices.push(additionalService);
            }
          }
          return additionalServices;
        })
      )
      .subscribe(products => {
        this.additionalServices = products;
      });
    this.allSubscriptions.push(targetRelationshipsSubscription);
  }

  private selectAdditionalService(additionalService: AdditionalService, additionalServiceCheckbox: HTMLInputElement) {
    if (additionalServiceCheckbox.checked) {
      this.selectedAdditionalServices.push(additionalService.productCode);
    } else {
      const index = this.selectedAdditionalServices.indexOf(additionalService.productCode);
      if (index !== -1) {
        this.selectedAdditionalServices.splice(index, 1);
      }
    }
  }

  private invokeRedirectToAddBasket() {
    this.productDetailAndComparisonCommonService.invokeAddToBasketEmitter.subscribe(params => {
      this.redirectToAddToBasket(params['preorder'], params['fast']);
    });
  }

  private initArchived() {
    if (this.product.validFor && this.product.validFor.endDateTime) {
      //should contain Date type, but is actually string
      const endDate = new Date(<string>(<unknown>this.product.validFor.endDateTime));
      this.archivedFlag = endDate < new Date();
    }
  }

  getStickerPictureByType(pictures: Array<PictureDto>, pictureType: string): PictureDto {
    if (pictures) {
      return pictures.find(p => p.pictureType === pictureType);
    }
    return undefined;
  }

  setRating(rating: number) {
    this.rating = rating;
  }

  setReviewDetails(reviewDetails: AggregatedRatingDto) {
    this.reviewQuantity = reviewDetails.totalReviewAmount;
    // Get 1 to 5 rating from percentages
    this.reviewToFive = (reviewDetails.averageRating * 5) / 100;
  }

  scrollToTabContainer() {
    this.scrollTo(this.sectionTabs);
  }

  private scrollTo(element: ElementRef<HTMLDivElement>) {
    const topOfElement = element.nativeElement.offsetTop;
    window.scroll({ top: topOfElement, behavior: 'smooth' });
  }

  tariffSelectionChanged(selectedTariffId): void {
    if (selectedTariffId) {
      this.selectedTariffId = selectedTariffId;
    } else {
      this.tariffHwProduct = undefined;
      this.selectedTariffId = undefined;
      this.filterHwTariffProduct(null);
    }
    this.getTariffHwProduct(selectedTariffId);
  }

  getTariffHwProduct(selectedTariffId): void {
    this.productService.getProductById(selectedTariffId).subscribe(tariffProduct => {
      if (tariffProduct) {
        this.filterHwTariffProduct(tariffProduct);
      }
    });
  }

  filterHwTariffProduct(tariffProduct): void {
    this.filter.attributes.parametersStatic = { sapCode: this.product.parametersStatic['sapCode'] };
    if (!tariffProduct) {
      this.filter.attributes.categoryId = CategoryTypeEnum.PRODC_FREE_HW;
    }
    this.productService
      .getProductsByFilter(this.filter, null, tariffProduct ? tariffProduct.productCode : '')
      .subscribe(productSearchResults => {
        this.tariffHwProduct = productSearchResults.data[0];
      });
    this.filter.attributes.parametersStatic = {};
    this.filter.attributes.categoryId = CategoryTypeEnum.PRODC_SU_HW;
  }

  private setSeoData() {
    let keywords = this.product.parametersStatic['seoKeywords'];
    if (!keywords) {
      keywords = this.product.name;
      keywords += `, ${this.keyFeatureParams.map(keyFeature => keyFeature.label).join(', ')}`;
      keywords += `, ${SeoService.STATIC_KEYWORDS}`;
    }
    this.seoService.setMeta(
      this.product.name,
      this.product.shortDescription,
      keywords,
      this.getCanonicalUrl(),
      this.product.picture ? this.product.picture.href : null
    );
  }

  private getCanonicalUrl(): string {
    const mainProduct = this.product;
    return location ? `${location.origin}/ehsop/product-detail/${mainProduct.seoUrl}` : null;
  }

  check3dModel() {
    console.log('check 3D model...');
    if (this.isParameterTrue(this.product, '3dModelEnabled')) {
      if (this.script3dModel || this.doc.getElementById('3d-model')) {
        this.init3dModel(false);
      } else {
        this.init3dModel();
      }
    } else {
      this.init3dModel(false, true);
    }
  }

  private isParameterTrue(product: ProductDetailDto, paramName: string): boolean {
    return (
      product && product.parametersStatic[paramName] && product.parametersStatic[paramName].toLowerCase() === 'true'
    );
  }

  private init3dModel(initBinkies: boolean = true, clear: boolean = false) {
    console.log('preparing 3D model to load...');
    const name = clear ? null : this.product.name;
    const selectedColor = clear ? null : this.selectedColor;

    if (this.doc) {
      const scriptInnerText = this.doc.getElementById('3d-model');
      if (scriptInnerText) {
        this.doc.getElementById('3d-model').remove();
      }

      // prepare productDetails for 3D model
      const scriptlet = this.doc.createElement('script');
      scriptlet.id = '3d-model';
      scriptlet.innerText =
        `` +
        `window.measure = {};` +
        `window.measure.productDetails = {};` +
        `window.measure.productDetails.name = ${name === null ? 'null' : `'${name}'`};` +
        `window.measure.productDetails.colorName = ${selectedColor === null ? 'null' : `'${selectedColor}'`};` +
        `window.triggerBinkiesMessage = function (msg) {` +
        `const message = [{element: 'HW 3D Model - ' + msg}]; console.log(message)};`;
      this.doc.getElementsByTagName('head')[0].appendChild(scriptlet);

      // init Binkies JS
      if (initBinkies) {
        console.log('preparing binkies to load...');
        const scriptElement = this.doc.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = 'https://embed.binkies3d.com/integrations/Cs1QqJAm/hmaa9ea1/script.js';
        scriptElement.async = true;
        scriptElement.referrerPolicy = 'no-referrer-when-downgrade';
        this.doc.getElementsByTagName('head')[0].appendChild(scriptElement);
        this.script3dModel = true;
        console.log('binkies loaded...');
      }
    }
  }

  openWatchdogSubscriptionModal() {
    const modalRef = this.ngbModal.open(WatchdogSubscriptionComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });
    const watchdogSubscriptionModalComponent = <WatchdogSubscriptionComponent>modalRef.componentInstance;
    watchdogSubscriptionModalComponent.dialogRef = modalRef;
    watchdogSubscriptionModalComponent.productCode = this.product.productCode;
    watchdogSubscriptionModalComponent.showFeature = this.product.parametersStatic['watchdogEnabled'] === 'true';
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = this.product.productCode;
  }

  get twitterButtonConfig(): TwitterButtonConfig {
    return {
      buttonSize: 'large',
      text: this.product.shortDescription,
      url: window.location.href,
      hashtags: ['EmeldiGroup'],
      via: 'EmeldiGroup',
      related: ['EmeldiGroup'],
    };
  }

  getCurrentHref() {
    return window.location.href;
  }

  get facebookButtonConfig(): FacebookButtonConfig {
    return {
      layout: 'button',
      size: 'large',
      href: window.location.href,
    };
  }

  /**
   * Does not run under angular universal (finds node js process)
   */
  public get isUniversal(): boolean {
    // @ts-ignore
    return typeof process !== 'undefined' && typeof process.versions.node !== 'undefined';
  }

  selectPreferredShop() {
    const modalRef = this.ngbModal.open(ShopSelectionPopupComponent, { windowClass: 'select-modal' });
    const modalSelectComponent = <ShopSelectionPopupComponent>modalRef.componentInstance;
    modalSelectComponent.dialogRef = modalRef;
    modalSelectComponent.productCode = this.product.productCode;
    modalSelectComponent.enrichAvailability = true;
    modalSelectComponent.checkAvailability = true;
    modalSelectComponent.handler = result => {
      if (this.currentOrder && result) {
        const orderParamsDto: Array<OrderParamDto> = [];
        OrderUtils.updateOrderAttr(orderParamsDto, this.PREFERRED_SHOP_ATTR, JSON.stringify(result._source));
        const orderAsMap = {
          orderAttributes: orderParamsDto,
          recordVersion: this.currentOrder.recordVersion,
        };
        this.orderingService
          .patchOrder(this.currentOrder.id, orderAsMap)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => this.handleOrder(order));
      }
    };
  }

  tabSwitchedToProductRating() {
    this.productRatingsAndReviewsComponent.checkFacebookComments();
  }
}

interface AdditionalService {
  name: string;
  price: number;
  recurrent: boolean;
  productCode: string;
}

enum ReservationStatus {
  NONE,
  OK,
  ERROR,
}
