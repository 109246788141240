import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export class FormUtils {
  // used in the application for email validations
  public static emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static disableEnableForm(form: FormGroup, disableCondition: boolean, excluds: string[]) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        if (excluds.indexOf(field) < 0) {
          if (disableCondition) {
            control.disable();
          } else {
            control.enable();
          }
        }
      } else if (control instanceof FormGroup) {
        this.disableEnableForm(control, disableCondition, excluds);
      }
    });
  }

  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (control.enabled) {
          control.markAsTouched({ onlySelf: true });
        }
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(arrayControl => this.validateAllFormFields(arrayControl as FormGroup));
      }
    });
  }

  public static setValidation(control: AbstractControl, validators: ValidatorFn | ValidatorFn[] | null) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  public static setAsyncValidation(control: AbstractControl, validators: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    control.setAsyncValidators(validators);
    control.updateValueAndValidity();
  }

  public static createFormGroupFromObject(object: any) {
    const formGroup = new FormGroup({});
    Object.keys(object).forEach(field => {
      const formControl = new FormControl(object[field] || '');
      formGroup.addControl(field, formControl);
    });
    return formGroup;
  }
}
