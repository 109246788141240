import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AppBlockerService,
  EnableDynamicLoading,
  SalesLeadService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ProductComparisonService } from '@service/product-comparison.service';
import { CallMeBackModalComponent } from '../../call-me-back-modal/call-me-back-modal.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { SalesLeadDto } from '@btl/order-bff';
import { OrderingWizardService, StepTypeInfo } from '@service/ordering-wizard-service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { NavigationMenuComponent } from '../navigation-menu/navigation-menu.component';

@Component({
  selector: 'app-page-template',
  templateUrl: './app-page-template.component.html',
})
@EnableDynamicLoading({ customName: AppPageTemplateComponent.PAGE_ID })
export class AppPageTemplateComponent extends AbstractPageComponent implements OnInit, AfterViewInit {
  public static readonly PAGE_ID = 'AppPageTemplateComponent';

  pageId(): string {
    return AppPageTemplateComponent.PAGE_ID;
  }

  containerClass = '';
  compareBarOpen: boolean = true;
  eshop: boolean = false;
  checkout: boolean = false;
  activeCompareBar: boolean = false;
  stepTypesInfos: Array<StepTypeInfo>;
  cmbEnabled: boolean = false;

  SHRINK_CSS_CLASS_NAME = 'header-shrink';
  shrinkCssClass: string = '';

  @ViewChild('navigationMenu') navigationMenuComponent: NavigationMenuComponent;
  offsetTop: number;

  constructor(
    private appBlockerService: AppBlockerService,
    private productComparisonService: ProductComparisonService,
    public stickyMessageService: StickyMessageService,
    public ngbModal: NgbModal,
    protected router: Router,
    protected route: ActivatedRoute,
    private salesLeadService: SalesLeadService,
    public customerLocalStorageService: CustomerLocalStorageService,
    private orderingWizardService: OrderingWizardService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        this.compareBarOpen = !this.router.url.includes(this.productComparisonService.COMPARISON_PAGE_BASE_PATH);
        return;
      } else {
        this.eshop = evt.url.startsWith('/eshop');
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    this.containerClass = this.route.snapshot.url.length
      ? this.route.snapshot.url[0].path === 'error'
        ? 'container error-page-container'
        : 'container page-container'
      : '';
    this.activeCompareBar = this.productComparisonService.getComparisonList().length !== 0 ? true : false;
    this.checkCheckoutProcess();
    this.productComparisonService.comparisonChange.subscribe(() => {
      this.activeCompareBar = this.productComparisonService.getComparisonList().length !== 0 ? true : false;
    });

    this.propertyAccessorLocalService.getCallMeBackBoolean().subscribe(response => {
      this.cmbEnabled = response === 'true';
    });
  }

  ngAfterViewInit() {
    this.offsetTop = this.navigationMenuComponent.headerElementHeight;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTopPosition = window.document.documentElement.scrollTop;
    this.shrinkCssClass = scrollTopPosition > this.offsetTop ? this.SHRINK_CSS_CLASS_NAME : '';
  }

  callMeBack() {
    const dialogReference = this.ngbModal.open(CallMeBackModalComponent, {
      windowClass: `dialog dialog-callMeBack${this.getMoveUpClass()}`,
      backdropClass: 'backDrop-callMeBack',
      keyboard: true,
      backdrop: false,
    });
    const callMeBackModalComponent = <CallMeBackModalComponent>dialogReference.componentInstance;
    callMeBackModalComponent.dialogRef = dialogReference;
    callMeBackModalComponent.handler = phoneNumber => {
      const salesLead: SalesLeadDto = {
        id: null,
        name: null,
        type: 'CALL_ME_BACK',
        rating: 'cold',
        referredDate: new Date(),
        estimatedRevenue: {
          value: 0,
        },
        status: 'acknowledged',
        relatedParty: null,
        prospectContact: [
          {
            mediumType: 'phoneNumber',
            characteristic: {
              phoneNumber: phoneNumber,
            },
          },
        ],
        channel: {
          name: 'visitedPageUrl',
          href: this.router.url,
        },
      };

      const currentCustomer = this.customerLocalStorageService.getCurrentCustomer();
      if (currentCustomer) {
        salesLead.name = `${currentCustomer.subject.firstName} ${currentCustomer.subject.lastName}`;
        salesLead.relatedParty = [
          {
            id: this.customerLocalStorageService.getCurrentCustomer().id,
            role: 'Customer',
          },
        ];
      } else {
        salesLead.name = 'anonymous';
      }

      this.salesLeadService
        .createSalesLead(salesLead)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(ticket => {
          dialogReference.dismiss();
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.callMeBackAdded');
        });
    };
  }

  isMluvii() {
    return document.getElementsByClassName('onopBanner')?.length > 0;
  }

  getMoveUpClass() {
    if (this.isMluvii()) {
      return ' moveUp';
    } else {
      return '';
    }
  }

  checkCheckoutProcess() {
    this.stepTypesInfos = Array.from(this.orderingWizardService.stepTypesInfos.values());
    this.stepTypesInfos.find(step => {
      if (step.url === this.router.url) {
        this.checkout = true;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.offsetTop = this.navigationMenuComponent.headerElementHeight;
  }
}
