<app-ecare-mobile-row-general *ngFor="let report of reports">
  <div class="mobile-table-row-wrapper">
    <div class="mobile-table-row-flex space-between">
      <div class="mobile-table-row-flex information">
        <div>
          {{ report.name }}
        </div>
        <div class="images">
          <div class="type" tooltipClass="ecare-settings-tooltip" placement="left" ngbTooltip="{{ report.type.area }}">
            <i
              class="listing-icon"
              [inlineSVG]="
                '/assets/img/reports/' + ecareReportsComponent.getReportTypeArea(report.type.area) + '.svg'
              "></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="report?.urlDetail && report?.urlDownload; else singleUrl">
        <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
          <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
            <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
          </span>

          <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ report.id }}_popover-content">
            <div>
              <button
                class="btn btn-link action-list-more-actions-button"
                type="button"
                (click)="ecareReportsComponent.openUrl(report.urlDetail)">
                {{ 'wc.ecare.reports.showDetails.button' | translate }}
              </button>
              <button
                class="btn btn-link action-list-more-actions-button"
                type="button"
                (click)="ecareReportsComponent.openUrl(report.urlDownload)">
                {{ 'wc.ecare.reports.download.button' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #singleUrl>
        <ng-container *ngIf="report.urlDownload">
          <i class="download-icon" [inlineSVG]="'/assets/svg/download.svg'"></i>
          <a class="table-link bold" (click)="ecareReportsComponent.openUrl(report.urlDownload)">
            {{ 'wc.ecare.reports.download.button' | translate }}
          </a>
        </ng-container>
        <ng-container *ngIf="report.urlDetail">
          <a class="table-link bold" (click)="ecareReportsComponent.openUrl(report.urlDetail)">
            {{ 'wc.ecare.reports.showDetails.button' | translate }}
          </a>
        </ng-container>
      </ng-template>
    </div>
    <div class="mobile-table-row-flex highlight">
      {{ report.owner }}
      <app-sticker variant="neutral">{{ report.type.id }}</app-sticker>
    </div>
    <div class="mobile-table-row-flex">{{ report.type.type }}</div>
  </div>
</app-ecare-mobile-row-general>
