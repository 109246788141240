<div class="orderingWizard">
  <ul>
    <ng-container *ngFor="let stepStatus of orderingWizardStepStatuses; let i = index">
      <h4 *ngIf="stepStatus === stepStatuses.ACTIVE" class="d-lg-none">
        {{ 'wc.shopping.wizard.' + stepTypesInfos[i].translation + '.button' | translate }}
      </h4>
      <ng-container *ngIf="orderingWizardService.checkStepVisibility(stepTypesInfos[i].scenarioStepTypeEnum)">
        <li *ngIf="stepTypesInfos[i].visibility === true" class="d-none d-lg-block step step-numbered {{ stepStatus }}">
          <div
            class="step-circle"
            title="{{ 'wc.shopping.wizard.' + stepTypesInfos[i].translation + '.button' | translate }}"></div>
          <a
            *ngIf="stepStatus === stepStatuses.DONE && clickable; else not_clickable_step"
            id="{{ i }}_001_ordering_wizard"
            [routerLink]="stepTypesInfos[i].url">
            <p class="step-title">
              {{ 'wc.shopping.wizard.' + stepTypesInfos[i].translation + '.button' | translate }}
            </p>
          </a>

          <ng-template #not_clickable_step>
            <p class="step-title">
              {{ 'wc.shopping.wizard.' + stepTypesInfos[i].translation + '.button' | translate }}
            </p>
          </ng-template>
        </li>
      </ng-container>
    </ng-container>

    <!-- TODO: Button's visibility must be controlled and link to next step must be generated. -->
    <!--<li class="step" *ngIf="true">
      <a routerLink="/eshop/checkout-page" class="btn btn-default">
        {{'wc.shopping.cart.continueToCheckout.button' | translate}}
      </a>
    </li>-->
  </ul>
</div>
