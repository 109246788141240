import { Component } from '@angular/core';
import { AclService, AuthFactoryService, AuthService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-checkout-account-selection',
  templateUrl: './checkout-account-selection.component.html',
})
export class CheckoutAccountSelectionComponent {
  authService: AuthService;

  constructor(private authFactoryService: AuthFactoryService, private aclService: AclService) {
    this.authService = authFactoryService.getAuthService();
  }

  loginKeyCloak() {
    this.aclService.emptyPrivilegesCache();
    this.authService
      .login()
      .then()
      .catch(reason => {
        throw new Error(`Cannot login: ${reason}`);
      });
  }
}
