import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AuthFactoryService,
  CodebookService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructKeyArrayFilterMiner,
  ControlStructSingletonFilterMiner,
  CurrentLocaleService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { from } from 'rxjs/internal/observable/from';
import { takeUntil } from 'rxjs/operators';
import { CodebookDto, TicketTypeDto, TicketTypeParamDto } from '@btl/order-bff';
import { OpportunityDetailsComponent } from '../opportunity-details/opportunity-details.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-opportunity-filter',
  templateUrl: './opportunity-filter.component.html',
})
export class OpportunityFilterComponent extends ComplexFilter {
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  protected advancedFilterFields = [
    'createdDateFrom',
    'createdDateTo',
    'relatedTo',
    'paramName',
    'paramValue',
    'status',
  ];

  filterForm = this.formBuilder.group({
    subject: [],
    createdDateFrom: [],
    createdDateTo: [],
    relatedTo: [],
    status: '',
    paramName: [],
    paramValue: [],
    assignedTo: [],
    my: [],
    team: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.subject, 'subject'),

    new ControlFilterMiner(this.filterForm.controls.createdDateFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdDateTo, 'created', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.my, 'assignedTo', CompareTypeDtoEnum.EQUAL),
    new ControlFilterMiner(this.filterForm.controls.team, 'teamsTickets', null),

    new ControlStructSingletonFilterMiner(this.filterForm.controls.paramName, 'parameters', 'name', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.paramValue, 'parameters', 'value', null),

    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.status,
      'parameters',
      'name',
      'OPPORTUNITY_STATE',
      'value',
      null
    ),
  ];

  userLogin;
  userRoles;

  opportunityStates: CodebookDto[];
  opportunityConfiguration: TicketTypeDto;
  parametersOptions = [];

  selectedParameter: TicketTypeParamDto;

  constructor(
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private authFactoryService: AuthFactoryService,
    private ticketingService: TicketingService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super();

    this.codebookService
      .getCodebooks('TICKET_OPPORTUNITY_STATE')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => (this.opportunityStates = cb));

    this.ticketingService
      .getTicketTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.opportunityConfiguration = result.find(
          type => type.code === OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE
        );
        this.setParameterOptions();
        this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          this.setParameterOptions();
        });
      });
  }

  changeFilterField(fieldName: string, value: any): void {
    super.changeFilterField(fieldName, value);
    if (fieldName === 'paramName') {
      this.selectedParameter = this.opportunityConfiguration?.parameters.find(param => param.name === value);
    }
  }

  setParameterOptions() {
    const paramOptions = [];
    if (this.opportunityConfiguration) {
      this.opportunityConfiguration.parameters.forEach((parameter: TicketTypeParamDto) =>
        paramOptions.push({
          value: parameter.name,
          label: parameter.localizedNames[this.currentLocaleService.getCurrentLanguage()]
        })
      );
    }
    this.parametersOptions = paramOptions;
  }

  ngOnInit() {
    from(this.authFactoryService.getAuthService().getUserInfo()).subscribe(userProfile => {
      this.userLogin = userProfile.username;
      this.userRoles = this.authFactoryService.getAuthService().getUserRoles();
      this.filterForm.controls['assignedTo'].patchValue(this.userLogin);

      super.ngOnInit(true);
      this.resetFilterFields();

      this.assignedToChanged(this.userLogin);
    });
  }

  protected getDefaultFilterValues() {
    return {
      id: '',
      createdDateFrom: '',
      createdDateTo: '',
      relatedTo: '',
      status: '',
      paramName: '',
      paramValue: '',
    };
  }

  assignedToChanged(event: any) {
    if (event === this.userLogin) {
      this.filterForm.controls.my.patchValue(event);
      this.filterForm.controls.team.patchValue(null);
    } else if (event === this.userRoles) {
      this.filterForm.controls.my.patchValue(null);
      this.filterForm.controls.team.patchValue(event);
    } else {
      this.filterForm.controls.my.patchValue(null);
      this.filterForm.controls.team.patchValue(null);
    }
  }
}
