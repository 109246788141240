<div class="ecare-account-settings -sub-section">
  <div class="section">
    <div class="section-row">
      <div class="label">
        {{ 'wc.ecare.accountSettings.selectAclRole' | translate }}
      </div>

      <div *ngIf="selectedUserRole" class="role-card selected">
        <div class="radio">
          <input type="radio" name="role-radio" checked />
          <div class="radio-label">
            <div class="role-info">
              <!-- todo: translate role name using LT key -->
              <div class="role-name">{{ selectedUserRole }}</div>
              <div class="role-description">
                <!-- todo: replace with correct ACL role description -->
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                rem aperiam. Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
