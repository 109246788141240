<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.recharge.heading' | translate }}
  </h4>
</div>
<div *blockUI="prepaidRechargeBlockUiElementName; template: blockTemplate" class="modal-body">
  <div *ngIf="generalFailure" class="alert alert-danger">
    <div>
      {{ 'wc.ecare.recharge.generalFailure.text' | translate }}
    </div>
  </div>

  <form class="form" [formGroup]="rechargeForm">
    <div class="form-group-row">
      <label>{{ 'wc.ecare.recharge.phoneNumber.label' | translate }}</label>
      <div class="input">
        <input id="001_prepaid_recharge" type="text" value="{{ msisdn }}" [disabled]="true" />
      </div>
    </div>
    <div class="form-group-row">
      <label>{{
        'wc.ecare.recharge.currentAmount.label' | translate: { currency: currencySymbolMessage | translate }
      }}</label>
      <div *ngIf="checkCreditResultDto" class="input">
        <input id="002_prepaid_recharge" type="text" value="{{ checkCreditResultDto.credit }}" [disabled]="true" />
      </div>
    </div>
    <app-input-row
      id="003_prepaid_recharge"
      fieldName="rechargeAmount"
      translationPrefix="wc.ecare"
      [parentGroup]="rechargeForm"
      [labelParameters]="{ currency: currencySymbolMessage | translate }">
    </app-input-row>
  </form>
</div>
<div class="modal-footer">
  <button id="004_prepaid_recharge" type="button" class="btn btn-cancel ml-auto" (click)="dialogRef.dismiss()">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button id="005_prepaid_recharge" type="button" class="btn btn-default mr-auto" (click)="recharge()">
    {{ 'wc.ecare.recharge.button' | translate }}
  </button>
</div>
