<div
  *ngIf="backgroundImage"
  class="cms-widget-page-image -wrapper"
  [attr.id]="urlAnchor"
  [style.color]="color"
  [style.background]="backgroundColor"
  [ngStyle]="getBackgroundImage() && { background: 'url(' + getBackgroundImage() + ') no-repeat' }">
  <div class="-caption-section">
    <div class="-caption-content">
      <div *ngIf="title || content" class="-text">
        <div app-cms-widget-headline [size]="'H1'" [title]="title"></div>
        <div app-cms-widget-rich-content [content]="content"></div>
      </div>

      <div *ngIf="buttonLabel && buttonType && buttonUrl" class="-btn-wrapper">
        <a [href]="buttonUrl | safeUrl" class="btn btn-default -btn-{{ buttonType?.toLowerCase() }}">
          {{ buttonLabel | selectLocaleProperty }}
        </a>
      </div>
    </div>
  </div>
</div>
