<swiper
  [slidesPerView]="2.5"
  [freeMode]="true"
  [loop]="true"
  [speed]="1000"
  [autoplay]="{
    delay: 3
  }"
  [breakpoints]="{
    '769': {
      slidesPerView: 4
    },
    '992': {
      slidesPerView: 5.5
    },
    '1200': {
      slidesPerView: 6.5
    }
  }">
  <ng-template swiperSlide *ngFor="let brandMeta of brandListMeta">
    <div class="brand-item" [routerLink]="brandMeta.linkUrl">
      <img loading="lazy" [src]="brandMeta.logoImage" [alt]="'brand'" />
    </div>
  </ng-template>
</swiper>
