<!-- TODO Review this component and form-attachments.component.html -->
<app-ecare-page-layout
  *ngIf="!simpleView"
  columnTemplate="1fr"
  title="{{
    (customerLocalStorageService.getCurrentCustomerAccount()
      ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
        ' ' +
        ('wc.ecare.dashboard.account.header' | translate) +
        ' '
      : (customerLocalStorageService.getCurrentCustomer()
          ? customerLocalStorageService.getCurrentCustomer().displayName + ' '
          : '') + ' ') + ('wc.ecare.documents.headline' | translate)
  }}">
  <ng-container page-actions>
    <app-button
      *ngIf="getSelectedFiles().length > 0"
      variant="secondary"
      iconPath="/assets/svg/delete.svg"
      label="{{ 'wc.ecare.ticket.delete.button' | translate }}"
      (click)="deleteFiles()">
    </app-button>
    <app-button
      variant="primary"
      iconPath="/assets/svg/upload-document.svg"
      label="{{ 'wc.ecare.documents.uploadDocument' | translate }}"
      (click)="add()">
    </app-button>
  </ng-container>
  <div>
    <div class="ecare dashboard">
      <div class="ecare-numberOforders">
        {{ 'wc.ecare.orders.showing.label' | translate }} {{ files.length }}
        {{ 'wc.ecare.documents.documents.label' | translate }}
      </div>
      <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
        <div class="">
          <app-documents-filter [filtering]="search.filtering" (filterChanged)="handleFilterChange()">
          </app-documents-filter>
        </div>

        <div class="dashboard_search ecare-ordersSearch">
          <table *ngIf="files.length > 0" class="ecare_listing-table orders-table table">
            <thead class="content-sm">
              <tr>
                <th></th>
                <th></th>
                <th>{{ 'wc.ecare.documents.table.mediaType.header' | translate }}</th>
                <th>{{ 'wc.ecare.documents.table.uploaded.header' | translate }}</th>
                <th>{{ 'wc.ecare.documents.table.note.header' | translate }}</th>
                <th>{{ 'wc.ecare.documents.table.fileName.header' | translate }}</th>
                <th>{{ 'wc.ecare.documents.table.documentType.header' | translate }}</th>
                <th>{{ 'wc.ecare.ticket.action' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <app-ecare-document-row
                *ngFor="let file of files; let i = index"
                [selectFormArray]="selectedFormArray"
                [index]="i"
                [file]="file"
                (deleteEvent)="deleteFile($event)"
                (downloadFileEvent)="download($event)">
              </app-ecare-document-row>
            </tbody>
          </table>

          <ng-container *ngIf="canDisplayNoDocumentsText">
            <app-ecare-listing-no-results></app-ecare-listing-no-results>
          </ng-container>
        </div>
        <div>
          <app-ecare-pagination
            [page]="search.paging.page"
            [pageSize]="search.paging.pageSize"
            [totalCount]="pagedDto?.totalItems"
            [pagesToShow]="pagedDto?.totalPages"
            (goPrev)="onPrevPage()"
            (goNext)="onNextPage()"
            (goPage)="onSpecificPage($event)"
            (pageSizeChanged)="onPageSizeChanged($event)">
          </app-ecare-pagination>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>

<div *ngIf="simpleView" class="form-attachments-wrapper">
  <div class="attachments">
    <ng-container *ngIf="this.files.length > 0; else emptyList">
      <ng-container *ngFor="let file of this.files; let i = index">
        <div class="attachment border">
          <div class="left-panel">
            <i [inlineSVG]="'/assets/svg/fileUploader/' + getTypeIcon(file) + '.svg'"></i>
            <div class="attachment-description">
              <div class="name">
                <ng-container *ngIf="shouldShortenFileName(file.name); else fullFileName">
                      <span placement="bottom" ngbTooltip="{{ file.name }}">
                        {{ formatFileName(file.name) }}
                      </span>
                </ng-container>
                <ng-template #fullFileName>
                  {{ formatFileName(file.name) }}
                </ng-template>
              </div>
              <div class="file-info">
                {{ file.created | date : 'dd.MM.yyyy HH:mm' }}
                &bull; {{ file.size | number : '1.0-0' }}KB
              </div>
            </div>
          </div>

          <ng-container *ngIf="enableDownload && !enableDelete; else multipleAction">
            <app-button
              *ngIf="enableDownload"
              variant="secondary"
              size="small"
              label="{{ 'wc.ecare.notifications.downloadAttachment' | translate }}"
              (click)="download(file)"></app-button>
          </ng-container>

          <ng-template #multipleAction>
            <div ngbDropdown placement="bottom" class="listing-row-actions-entry">
                  <span class="moreAction-wrapper">
                    <i ngbDropdownToggle [inlineSVG]="'assets/svg/three-dots.svg'"></i>
                  </span>

              <div ngbDropdownMenu class="order-list-dropdown-menu attachment-acctions-container">
                <app-button
                  *ngIf="enableDownload"
                  iconPath="/assets/svg/feather-download.svg"
                  variant="link"
                  size="fullWidth"
                  label="{{ 'wc.ecare.notifications.downloadAttachment' | translate }}"
                  (click)="download(file)"></app-button>
                <app-button
                  *ngIf="enableDelete"
                  iconPath="/assets/svg/remove.svg"
                  variant="link"
                  size="fullWidth"
                  label="{{ 'wc.ecare.notifications.deleteAttachment' | translate }}"
                  (click)="deleteFile(file.id)"></app-button>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="enableUpload && !disableUploadByProperty" class="buttons">
    <app-button
      variant="secondary"
      iconPath="/assets/svg/upload-document.svg"
      [disabled]=""
      label="{{ 'wc.ecare.attachments.upload-document' | translate }}"
      (click)="add()"></app-button>
  </div>
  <ng-template #noDataMessage>
    <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
  </ng-template>
  <ng-template #emptyList>
    <div class="no-data">{{ 'wc.ecare.documents.empty' | translate }}</div>
  </ng-template>
</div>
