import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CategoryTypeEnum } from 'app/models/product-filter';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { Router } from '@angular/router';
import { SubscriptionUtils } from 'app/helpers/subscription-utils';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetDto, ProductParamMetadataDto } from '@btl/order-bff';
import { ProductService } from '@btl/btl-fe-wc-common';

export class TariffResourceIdentifier {
  resourceType: string;
  resourceLabel: string;
  resourceValue: string;
}

@Component({
  selector: 'app-subscription-tile',
  templateUrl: './subscription-tile.component.html',
})
export class SubscriptionTileComponent implements OnInit, OnDestroy {
  isCollapsed: boolean = false;

  @Input()
  asset;

  @Input()
  customer;

  @Input()
  customerAccount;

  @Input()
  tariffSpace;

  @Input()
  isBusiness = false;

  @Output()
  readonly selectedTariff: EventEmitter<any> = new EventEmitter();

  tariffProduct;

  coreProduct;

  tariffIdentifiers: TariffResourceIdentifier[] = [];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private productService: ProductService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {}

  ngOnInit(): void {
    this.tariffProduct = this.asset['product'];
    this.coreProduct = CustomerPartyUtil.getChildAssetByCategoryId(this.asset, CategoryTypeEnum.PRODC_SU_CORE_GSM);

    this.propertyAccessorLocalService
      .getDashboardProductCategory()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          const rootAssetWithIdentifiers = CustomerPartyUtil.getChildAssetByCategoryId(
            this.asset,
            CategoryTypeEnum[result]
          );

          if (rootAssetWithIdentifiers) {
            this.fillTariffIdentifiers(rootAssetWithIdentifiers);
            rootAssetWithIdentifiers.childAssets.forEach(childAsset => this.fillTariffIdentifiers(childAsset));
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  isPostpaid(): boolean {
    return SubscriptionUtils.isPostpaid(this.tariffProduct);
  }

  selectTariff() {
    this.selectedTariff.emit(this.tariffSpace);
  }

  getResourceIdentifierLabel(resourceIdentifier: TariffResourceIdentifier): string {
    if (resourceIdentifier.resourceLabel) {
      return resourceIdentifier.resourceLabel;
    }
    return `wc.ecare.dashboard.resourceType.${resourceIdentifier.resourceType}`;
  }

  private fillTariffIdentifiers(asset: AssetDto): void {
    this.productService
      .getProductParameterMetadata(asset.product.categoryId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((params: Array<ProductParamMetadataDto>) => {
        params
          .filter(
            param =>
              !param.isStatic &&
              SubscriptionTileComponent.isTrue(param.metadata['isResource']) &&
              SubscriptionTileComponent.isTrue(param.metadata['guiVisibility']) &&
              SubscriptionTileComponent.isTrue(param.metadata['guiDisplayOnDashboard'])
          )
          .forEach(resourceParam => {
            this.tariffIdentifiers.push({
              resourceType: resourceParam.name,
              resourceLabel: resourceParam.label,
              resourceValue: asset.productCharacteristic.find(param => param.name === resourceParam.name).value,
            });
          });
      });
  }

  private static isTrue(value: string): boolean {
    return value && value === 'true';
  }
}
