import { Component, Input, ViewChild } from '@angular/core';
import {
  OrderingWizardActionsComponent,
} from 'app/components/page/ordering-wizard-actions/ordering-wizard-actions.component';

@Component({
  selector: 'app-checkout-shopping-cart',
  templateUrl: './checkout-shopping-cart.component.html',
})
export class CheckoutShoppingCartComponent {
  @ViewChild('orderingWizardActions', { static: false }) orderingWizardActions: OrderingWizardActionsComponent;

  @Input() checkFormAction: Function;
  @Input() disableContinueButton?: boolean = false;
  @Input() termsAndConditions?: boolean = false;
  @Input() alwaysShowNavigationButtons?: boolean = false;
}
