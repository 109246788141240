import { Component, HostListener, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CodebookService, CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import { CustomerBillStateTypeDto } from '@btl/order-bff';

@Component({
  selector: 'app-financial-document-filter',
  templateUrl: './financial-document-filter.component.html',
})
export class FinancialDocumentFilterComponent extends ComplexFilter {
  @Input() customerAccounts: FinancialDocsCustomerAccountMeta[] = [];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  categories = [];

  statusOptions = [
    {
      localizedTextKey: `wc.ecare.financialDocument.status.defaultFilter`,
      code: '',
    },
    {
      code: CustomerBillStateTypeDto.New,
      localizedTextKey: `wc.ecare.financialDocument.status.${CustomerBillStateTypeDto.New}`
    },
    {
      localizedTextKey: `wc.ecare.financialDocument.status.${CustomerBillStateTypeDto.OnHold}`,
      code: CustomerBillStateTypeDto.OnHold,
    },
    {
      localizedTextKey: `wc.ecare.financialDocument.status.${CustomerBillStateTypeDto.Validated}`,
      code: CustomerBillStateTypeDto.Validated,
    },
    {
      localizedTextKey: `wc.ecare.financialDocument.status.${CustomerBillStateTypeDto.Sent}`,
      code: CustomerBillStateTypeDto.Sent,
    },
    {
      localizedTextKey: `wc.ecare.financialDocument.status.${CustomerBillStateTypeDto.Settled}`,
      code: CustomerBillStateTypeDto.Settled,
    },
  ];

  filterForm = this.formBuilder.group({
    caRefNo: [],
    id: [],
    billDateFrom: [],
    billDateTo: [],
    category: [],
    status: [],
    openAmountOnly: [false],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.status, 'state'),
    new ControlFilterMiner(this.filterForm.controls.caRefNo, 'billingAccount.id'),
    new ControlFilterMiner(this.filterForm.controls.category, 'category'),
    new ControlFilterMiner(this.filterForm.controls.openAmountOnly, 'remainingAmount.amount'),
    new ControlFilterMiner(this.filterForm.controls.billDateFrom, 'billDate', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.billDateTo, 'billDate', CompareTypeDtoEnum.SMALLEREQ),
  ];

  protected advancedFilterFields = ['caRefNo', 'billDateFrom', 'billDateTo', 'category', 'status'];

  constructor(
    private formBuilder: FormBuilder,
    public codebookService: CodebookService,
    public customerLocalStorageService: CustomerLocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.customerAccounts = this.customerLocalStorageService
      .getCurrentCustomer()
      ?.childParties.map((partyDto): FinancialDocsCustomerAccountMeta => {
        return {
          label: partyDto['customName'] ? partyDto['customName'] : partyDto.id,
          value: partyDto.id,
        };
      });
    this.codebookService.getCodebooks('DOCUMENT_TYPE').subscribe(result => {
      result
        .filter(
          cb => cb.parameters.filter(param => param.name === 'dmsFileType' && param.value === 'FIN_DOCUMENT').length > 0,
        )
        .forEach(cb => {
          this.categories.push(cb);
        });

      this.resetFilterFields();
    });
  }

  resetFilterFields(): void {
    super.resetFilterFields();
    if (this.customerAccounts.length === 1) {
      this.filterForm.get('caRefNo').disable();
    }
  }

  protected getDefaultFilterValues() {
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 6);
    dateFrom.setHours(0, 0, 0, 0);
    return {
      caRefNo: '',
      billDateFrom: dateFrom,
      billDateTo: '',
      category: this.categories[0].code,
      status: this.statusOptions[0].code,
    };
  }
}

export interface FinancialDocsCustomerAccountMeta {
  label: string;
  value: string;
}
