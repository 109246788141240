<ng-container>
  <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
    <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle (click)="$event.stopPropagation()">
      <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
    </span>
    <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ customer.idNo }}_popover-content">
      <div>
        <ng-container *ngIf="customer.segment === 'R'; else b2bCustomer_selectCustomer">
          <button
            class="btn btn-link action-list-more-actions-button"
            id="{{ index }}_008_ecare_search"
            (click)="selectCustomer(customer.partyRefNo); $event.stopPropagation()">
            {{ 'wc.ecare.search.customer.action.selectCustomer' | translate }}
          </button>
        </ng-container>
        <ng-template #b2bCustomer_selectCustomer>
          <app-acl-evaluate privilege="SHOW_B2B_SUBS">
            <button
              class="btn btn-link action-list-more-actions-button"
              id="{{ index }}_009_ecare_search"
              (click)="selectCustomer(customer.partyRefNo); $event.stopPropagation()">
              {{ 'wc.ecare.search.customer.action.selectCustomer' | translate }}
            </button>
          </app-acl-evaluate>
        </ng-template>

        <app-acl-evaluate privilege="PAY_TELCO_BILL">
          <button
            id="025_navigation_menu"
            class="btn btn-link action-list-more-actions-button"
            (click)="goToFinancialDocuments(customer.partyRefNo); $event.stopPropagation()">
            {{ 'wc.ecare.search.customer.action.showDocuments' | translate }}
          </button>
        </app-acl-evaluate>

        <ng-container *ngIf="customer.segment === 'R'; else b2bCustomer_openInTab">
          <button
            class="btn btn-link action-list-more-actions-button"
            id="{{ index }}_010_ecare_search"
            (click)="openCustomerInTab(customer.partyRefNo); $event.stopPropagation()">
            <i [inlineSVG]="'/assets/svg/external-link.svg'"></i>
            {{ 'wc.ecare.search.customer.action.openInTab' | translate }}
          </button>
        </ng-container>
        <ng-template #b2bCustomer_openInTab>
          <app-acl-evaluate privilege="SHOW_B2B_SUBS">
            <button
              class="btn btn-link action-list-more-actions-button"
              id="{{ index }}_011_ecare_search"
              (click)="openCustomerInTab(customer.partyRefNo); $event.stopPropagation()">
              <i [inlineSVG]="'/assets/svg/external-link.svg'"></i>
              {{ 'wc.ecare.search.customer.action.openInTab' | translate }}
            </button>
          </app-acl-evaluate>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
