import { Component, Input, OnInit } from "@angular/core";
import { EcareOpportunitiesComponent } from "./ecare-opportunities.component";
import { CodebookService } from "@btl/btl-fe-wc-common";

@Component({
  selector: 'app-ecare-opportunities-mobile, [app-ecare-opportunities-mobile]',
  templateUrl: './ecare-opportunities-mobile.component.html',
})
export class EcareOpportunitiesMobileComponent implements OnInit {
  @Input()
  tickets;

  constructor(
    public ecareOpportunitiesComponent: EcareOpportunitiesComponent,
    public codebookService: CodebookService
  ) {}

  ngOnInit(): void {}
}
