<div *ngIf="homePageUrl" class="breadcrumb-container">
  <a id="001_breadcrumb_home" [routerLink]="homePageUrl">
    <i class="breadcrumb-home-icon" [inlineSVG]="'/assets/svg/breadcrumb-home.svg'"></i>
    {{ 'wc.common.navigation.home.button' | translate }}
  </a>

  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let i = index">
    <div *ngIf="i > 0 && breadcrumb.url != homePageUrl" class="breadcrumb-item">
      <i class="breadcrumb-slash" [inlineSVG]="'/assets/svg/breadcrumb-slash.svg'"></i>
      <a
        class="breadcrumb-item-link"
        [routerLink]="breadcrumb.url"
        [queryParams]="breadcrumb.params"
        [ngClass]="{ lastItem: last }"
        >{{ getModuleText(breadcrumb.code) }} {{ hasUsedParams(breadcrumb) }}</a
      >
    </div>
  </ng-container>
</div>
