import { Component, Input } from '@angular/core';
import { OrderDto, PartyDto } from '@btl/order-bff';

@Component({
  selector: 'app-order-selection-popup',
  templateUrl: './order-selection-popup.component.html',
})
export class OrderSelectionPopupComponent {
  @Input()
  dialogRef;

  @Input() party: PartyDto;

  @Input()
  handler = (orderDto: OrderDto) => {};

  public showPopup: boolean = false;

  closePopup() {
    this.dialogRef.dismiss();
  }

  rowSelected(orderDto: OrderDto) {
    this.handler(orderDto);
    this.dialogRef.dismiss();
  }
}
