<ng-container *ngIf="product.parametersStatic['ratingEnabled'] === 'true' && aggregatedRatingDto && aggregatedRatingDto.averageRating >= this.minRatingLimit">
  <ng-container *ngIf="aggregatedRatingDto.averageRating">
    <div class="product__detail-rating">
      <div class="product__detail-ratingSummary">
        <div class="product__detail-ratingSummary-Percentage">{{ aggregatedRatingDto.averageRating }}%</div>

        <div class="product__detail-ratingSummary-stars">
          <app-rating-stars
            class="stars"
            [rating]="aggregatedRatingDto.averageRating"
            [onlyFull]="false"></app-rating-stars>
        </div>

        <div class="product__detail-ratingSummary-reviews">
          Reviewed by {{ aggregatedRatingDto.totalReviewAmount }} customers
        </div>
      </div>

      <div class="product__detail-ratingList">
        <!-- ***** -->
        <div *ngFor="let staticStars of [5, 4, 3, 2, 1]" class="product__detail-ratingList-item">
          <div app-rating-star-info [aggregatedRatingDto]="aggregatedRatingDto" [starAmount]="staticStars"></div>
        </div>
      </div>
      <div class="product__detail-ratingStats">
        <div class="product__detail-ratingStats-item">
          <strong>1500 +</strong>
          <span>customers bought</span>
        </div>
        <div class="product__detail-ratingStats-item">
          <strong>85%</strong>
          <span>satisfied customers</span>
        </div>
        <div class="product__detail-ratingStats-item">
          <strong>{{ aggregatedRatingDto.totalReviewAmount }} x</strong>
          <span>customers reviews</span>
        </div>
      </div>
      <div class="product__detail-ratingLinks">
        <div class="product__detail-ratingLinks-item">
          <strong>Rate it!</strong>
          <span>Dont't forget to write review</span>
        </div>

        <div class="product__detail-ratingLinks-item">
          <strong>Recommend it!</strong>
          <span>Share this item to you friends!</span>
        </div>
      </div>

      <div *ngIf="reviewViewType === 'internal' || !reviewViewType">
        <!-- Reviews list -->
        <div class="product__detail-reviewFilter">
          <div class="product__detail-reviewFilter-display">Displaying page 1 from 5</div>
          <div class="product__detail-reviewFilter-sort">
            Sort By:
            <a id="002_product_ratings_and_reviews" href="#" class="active">Latest</a>
            <a id="003_product_ratings_and_reviews" href="#">Most helpful</a>
          </div>
        </div>

        <!-- Review list -->
        <div class="product__detail-reviewsList">
          <app-review *ngFor="let review of testReview" [reviewDto]="review"> </app-review>
          <div class="product__detail-reviewsList-more">
            <app-button
              *ngIf="showMoreReviews"
              varaint="primary"
              label="{{ 'wc.shopping.gui_productDetail.review.nextReviewsButton' | translate }}"
              [routerLink]=""
              (click)="moreReviews()"></app-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Facebook comments -->
<ng-container *ngIf="reviewViewType === 'facebook' && product">
  <div>
    <app-facebook-comments #facebookComments *ngIf="facebookCommentsLoaded; else commentsNotLoaded" [href]="getFacebookProductUrl()"></app-facebook-comments>
  </div>
  <ng-template #commentsNotLoaded>
    <div class="info info-alert">{{ 'wc.shopping.gui_productDetail.rating.facebook.error' | translate }}</div>
  </ng-template> 
</ng-container>
