<app-confirmation-dialog-border
  [dialogId]="confirmationDialogBlockUiElementName"
  [dialogReference]="dialogReference"
  [heading]="heading"
  [localizationParameters]="localizationParameters"
  [confirmationHandler]="confirmationHandler"
  [cancellationHandler]="cancellationHandler"
  [confirmationDisabled]="confirmationDisabled || failure"
  [cancellationDisabled]="cancellationDisabled">
  <div *ngIf="failure" class="alert alert-danger">
    <div>
      {{ 'wc.ecare.dashboard.tariffRestriction.confirmation.generalFailure.text' | translate: localizationParameters }}
    </div>
  </div>

  <ng-container *ngIf="!failure">
    <p>
      {{ 'wc.ecare.dashboard.tariffRestriction.currentState.text' | translate: localizationParameters }}:
      {{ tariff['state'] }}
    </p>

    <form class="form" *ngIf="reasons" [formGroup]="tariffRestrictionForm">
      <div class="form-group-row">
        <label>
          {{ 'wc.ecare.dashboard.tariffRestriction.confirmation.level.label' | translate }}
        </label>
        <div class="select">
          <app-input-select-form-field
            [noLabel] = true
            [options]="levels"
            [doNotShowInternalErrors]="true"
            [showEmptyOption] = false
            (ngModelChange)="loadReasonsFromCodebook"
            formControlName="level">
          </app-input-select-form-field>
        </div>
      </div>
      <div class="form-group-row">
        <label>
          {{ 'wc.ecare.dashboard.tariffRestriction.confirmation.reason.label' | translate }}
        </label>
        <div class="select">
          <app-input-select-form-field
            [noLabel] = true
            [options]="reasons"
            [doNotShowInternalErrors]="true"
            valueOption="value"
            labelOption="label"
            [showEmptyOption] = false
            translationPrefix="wc.ecare.dashboard.tariffDeactivation.confirmation.reason"
            formControlName="reason">
          </app-input-select-form-field>
<!--          <select id="001_tariff_restriction" formControlName="reason">-->
<!--            <option *ngFor="let reason of reasons" [ngValue]="reason">-->
<!--              <ng-container *ngIf="codebookService.getCodebookTexts('REASONSTATUS') | async as codebook">-->
<!--                {{ codebook[reason] }}-->
<!--              </ng-container>-->
<!--            </option>-->
<!--          </select>-->
          <app-input-errors
            translationPrefix="wc.ecare.dashboard.tariffRestriction.confirmation.reason"
            [control]="tariffRestrictionForm.controls['reason']">
          </app-input-errors>
        </div>
      </div>
    </form>
  </ng-container>
</app-confirmation-dialog-border>
