import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-confirmation-dialog-border',
  templateUrl: './confirmation-dialog-border.component.html',
})
export class ConfirmationDialogBorderComponent {
  /**
   * The UI block template.
   */
  blockTemplate = BlockTemplateComponent;

  /**
   * The ID of this dialog.
   */
  @Input()
  dialogId: string;

  /**
   * The localization key of the dialog heading.
   */
  @Input()
  heading: string;

  /**
   * Any object containing parameters for all localized text in the dialog.
   */
  @Input()
  localizationParameters: any;

  /**
   * The dialog reference.
   */
  @Input()
  dialogReference: NgbModalRef;

  /**
   * A handler handling user's click to Confirm in the dialog.
   */
  @Input()
  confirmationHandler: (dialogReference: NgbModalRef) => void;
  /**
   * A flag specifying if Confirm is disabled to be clicked in the dialog.
   */
  @Input()
  confirmationDisabled: boolean;

  @Input()
  confirmationLocalizedKey: string;

  @Input()
  confirmationIconPath: string;

  /**
   * A flag specifying if Cancel is disabled to be clicked in the dialog.
   */
  @Input()
  cancellationDisabled: boolean;

  @Input()
  cancellationVisible = true;

  @Input()
  cancelLocalizedKey: string;

  _cancellationHandler: (dialogReference: NgbModalRef) => void = (dialogReference: NgbModalRef) => {
    dialogReference.dismiss();
  };

  /**
   * A handler handling user's click to Cancel in the dialog.
   */
  @Input()
  set cancellationHandler(cancellationHandler: (dialogReference: NgbModalRef) => void) {
    if (cancellationHandler) {
      this._cancellationHandler = cancellationHandler;
    }
  }

  //region Event handling:

  /**
   * Handle user's click to Yes in the dialog.
   */
  handleYes(): void {
    this.confirmationHandler(this.dialogReference);
  }

  /**
   * Handle user's click to No in the dialog.
   */
  handleNo(): void {
    this._cancellationHandler(this.dialogReference);
  }
}
