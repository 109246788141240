import { Component, Input, OnInit } from '@angular/core';
import { DatePickerComponent } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-time-slot',
  templateUrl: './time-slot.component.html',
})
export class TimeSlotComponent implements OnInit {
  @Input() numberOfSlots: number = 0;
  @Input() timeSlotProposal: string;
  public timeSlots: Array<TimeSlotProposal> = [];
  private numberOfSlotParams = 4;
  @Input() heading: string;
  @Input() parentGroup: FormGroup;
  @Input() timeSlotId;
  @Input() orderItem;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.parentGroup.addControl(this.timeSlotId ? `timeSlot${this.timeSlotId}` : 'timeSlot', new FormControl(''));
    this.parseTimeSlotProposal(this.timeSlotProposal);
  }

  setDateTime(dateFrom: DatePickerComponent, dateTo: DatePickerComponent, selectValue: string) {
    if (selectValue) {
      const index = parseInt(selectValue);
      let from: Date = dateFrom.date.toDate();
      let to: Date = dateTo.date.toDate();
      this.timeSlots[index] = {
        timeFrom: from,
        timeTo: to,
        selected: true
      };
      this.parentGroup.controls[this.timeSlotId ? `timeSlot${this.timeSlotId}` : 'timeSlot'].patchValue(this.timeSlots);
    }
  }

  public getTimeSlotProposalAsString(): string {
    const propertyArr = [];
    this.timeSlots.forEach((val, index, arr) => {
      if (val) {
        propertyArr.push(val.timeFrom);
        propertyArr.push(val.timeTo);
        propertyArr.push(index);
        propertyArr.push(val.selected.toString());
      }
    });
    return propertyArr.join(';');
  }

  private parseDateTime(stringDateTime: string) {
    return new Date(stringDateTime);
  }

  private parseTimeSlotProposal(timeSlotProposal: string) {
    const splitted = [];
    if (timeSlotProposal) {
      const splitted = timeSlotProposal.split(';');
      if (splitted.length % this.numberOfSlotParams !== 0) {
        console.error(`Wrong number of params in time slot proposal: ${timeSlotProposal}`);
        return;
      }
    }

    const orderItemTimeSlots = [];
    if (this.orderItem) {
      const timeSlotAttr = this.orderItem.attributes.find(attr => attr.name === 'timeSlotProposal');
      if (timeSlotAttr) {
        const timeSlotProposal = JSON.parse(timeSlotAttr.value);
        if (timeSlotProposal && timeSlotProposal.length > 0) {
          const timeSlots = timeSlotProposal[this.timeSlotId ? this.timeSlotId - 1 : 0];
          timeSlots.forEach(timeSlot => {
            if (timeSlot) {
              orderItemTimeSlots.push({
                selected: timeSlot.selected,
                timeFrom: this.parseDateTime(timeSlot.timeFrom),
                timeTo: this.parseDateTime(timeSlot.timeTo),
              });
            }
          });
        }
      }
    }

    for (let i = 0; i < this.numberOfSlots; i++) {
      const index = splitted.findIndex(
        (value, index, obj) => index === this.numberOfSlotParams * i + 3 && value === i.toString()
      );
      if (index !== -1) {
        this.timeSlots[i] = {
          timeFrom: this.parseDateTime(splitted[index - 2]),
          timeTo: this.parseDateTime(splitted[index - 1]),
          selected: splitted[index + 1] === 'true',
        };
      } else {
        if (orderItemTimeSlots.length > i) {
          this.timeSlots[i] = orderItemTimeSlots[i];
        } else {
          this.timeSlots[i] = null;
        }
      }
    }
    if (this.parentGroup.controls[this.timeSlotId ? `timeSlot${this.timeSlotId}` : 'timeSlot']) {
      this.parentGroup.controls[this.timeSlotId ? `timeSlot${this.timeSlotId}` : 'timeSlot'].patchValue(this.timeSlots);
    }
  }

  clearDateTime(selectValue: string) {
    if (selectValue) {
      const index = parseInt(selectValue);
      this.timeSlots[index] = null;
    }
  }

  moveUp(selectValue: string) {
    if (selectValue) {
      const index = parseInt(selectValue);
      if (index !== 0) {
        const previous = this.timeSlots[index - 1];
        this.timeSlots[index - 1] = this.timeSlots[index];
        this.timeSlots[index] = previous;
      }
    }
  }

  moveDown(selectValue: string) {
    if (selectValue) {
      const index = parseInt(selectValue);
      if (index !== this.timeSlots.length - 1) {
        const next = this.timeSlots[index + 1];
        this.timeSlots[index + 1] = this.timeSlots[index];
        this.timeSlots[index] = next;
      }
    }
  }
}

export interface TimeSlotProposal {
  timeFrom: Date;
  timeTo: Date;
  selected: boolean;
}
