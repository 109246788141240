import { Component, Input, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-error-page-modal",
  templateUrl: "./error-page-modal.component.html",
})
export class ErrorPageModalComponent implements OnDestroy {
  @Input()
  dialogRef;

  @Input() failures;

  @Input() causedByMessage;

  @Input() errorCode

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor() {}

  cancel() {
    this.dialogRef.dismiss();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
