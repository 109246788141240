import { Component, Input } from '@angular/core';
import { CustomerAccountDto } from '@btl/order-bff';

@Component({
  selector: 'app-customer-accounts-popup',
  templateUrl: './customer-account-selection-popup.component.html',
})
export class CustomerAccountSelectionPopupComponent {
  @Input()
  dialogRef;

  @Input() customerId: string = null;
  @Input() canChangeCustomer = true;

  @Input()
  handler = (customerAccount: CustomerAccountDto) => {};

  public showPopup: boolean = false;

  closePopup() {
    this.dialogRef.dismiss();
  }

  customerAccountSelected(customerAccount: CustomerAccountDto) {
    this.handler(customerAccount);
    this.dialogRef.dismiss();
  }
}
