<app-ecare-page-layout
  columnTemplate="1fr"
  title="{{
    (!customerAccount?.recordVersion
      ? 'wc.ecare.createCustomerAccount.headline'
      : 'wc.ecare.editCustomerAccount.headline'
    ) | translate
  }}">
  <ng-container page-actions>
    <app-button
      label="{{
        (!customerAccount?.recordVersion
          ? 'wc.ecare.homepage.createCustomerAccount'
          : 'wc.ecare.opportunity.saveChanges'
        ) | translate
      }}"
      [iconPath]="!customerAccount?.recordVersion ? '/assets/img/ecare/feather-plus.svg' : '/assets/svg/save.svg'"
      (click)="save()"></app-button>
  </ng-container>
  <div>
    <div [formGroup]="createForm">
      <app-customer-account-form #customerAccountForm [visible]="true" [hideExistingCaList]="true">
      </app-customer-account-form>
      <app-button
        label="{{
          (!customerAccount?.recordVersion
            ? 'wc.ecare.homepage.createCustomerAccount'
            : 'wc.ecare.opportunity.saveChanges'
          ) | translate
        }}"
        [iconPath]="!customerAccount?.recordVersion ? '/assets/img/ecare/feather-plus.svg' : '/assets/svg/save.svg'"
        (click)="save()"></app-button>
    </div>
  </div>
</app-ecare-page-layout>
