import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ecare-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  @Input() page: number;
  @Input() totalCount: number;
  @Input() pageSize: number;
  @Input() pagesToShow: number;

  @Input() showPageSize = false;

  @Output() readonly goPrev = new EventEmitter<boolean>();
  @Output() readonly goNext = new EventEmitter<boolean>();
  @Output() readonly goPage = new EventEmitter<number>();
  @Output() readonly pageSizeChanged = new EventEmitter<number>();

  pageSizeForm: FormGroup;

  public static pageSizeOptions = [12, 24, 48, 96];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.pageSizeForm = this.fb.group({
      pageSizeOption: [this.pageSize],
    });
    this.pageSizeForm.get('pageSizeOption').valueChanges.subscribe(pageSize => {
      this.pageSizeChanged.emit(pageSize);
    });
  }

  onPage(pageNo: number): void {
    this.goPage.emit(pageNo);
  }

  onPrev(): void {
    this.goPrev.emit(true);
  }

  onNext(next: boolean): void {
    this.goNext.emit(next);
  }

  totalPages(): number {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  lastPage(): boolean {
    return this.pageSize * this.page > this.totalCount;
  }

  /**
   * Return array of visible pages numbers according to selected page and max number of pages that is allowed (pagesToShow).
   * @returns {number[]}  array of pages numbers
   */
  getPages(): number[] {
    const pages: number[] = [];
    pages.push(this.page);
    const times = this.pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < this.pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < this.pagesToShow) {
        if (Math.max.apply(null, pages) < this.totalPages()) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }

  getPageSizeOptions(): Array<number> {
    return PaginationComponent.pageSizeOptions;
  }
}
