import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges, ElementRef, HostListener } from '@angular/core';
import _ from 'lodash';
import { BlockUIService } from 'ng-block-ui';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CustomerDto, OrderDto, OrderItemDto, ProductDetailDto } from '@btl/order-bff';
import { AuthorizationService, BlockTemplateComponent, ProductService } from '@btl/btl-fe-wc-common';
import { Router } from '@angular/router';
import { TicketService } from '@service/ticket.service';
import { DocumentsPopupComponent } from '../../ecare-documents/documents-popup/documents-popup.component';
import { OrderStateTypeEnum } from '../../../../models/orderStateTypeEnum';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import { ProductUtils } from '../../../../helpers/product-utils';
import { ProductCustomService } from '@service/product-custom.service';
import { ThumbnailMap } from '../../../../models/orders-dtos';
import { OrderUtils } from '../../../../helpers/order-utils';
import { ThumbnailHelperService } from '@service/thumbnail-helper.service';
import { OrderingWizardService } from '@service/ordering-wizard-service';

@Component({
  selector: 'app-ecare-order-row',
  templateUrl: './ecare-order-row.component.html',
})
export class EcareOrderRowComponent implements OnInit, OnChanges {
  private static readonly NO_CHANGE_ACTION: string = 'NO_CHANGE';
  @Input() maxThumbnailsToShow: number = 5;

  @ViewChild(DocumentsPopupComponent) documentsPopup;

  blockTemplate = BlockTemplateComponent;

  @Input() order: OrderDto;
  @Input() opportunity: OrderDto[] = [];
  @Input() selectMode = false;
  @Input() isModuleBffCrmEnabled = true;
  @Input() priceRCexists: boolean = false;
  @Input() priceOCexists: boolean = false;

  @Output() readonly selectedOrder = new EventEmitter<OrderDto>();

  @Output() readonly selectCustomer: EventEmitter<string> = new EventEmitter();
  @Input() thumbnailMap: ThumbnailMap[];

  thumbnailsForRow: { thumbnail: string; name: string; count: number; omit: boolean }[] = [];
  unshowedThumbnails: { count: number; names: string };

  totalPriceOC: number;
  totalPriceRC: number;

  collapsed = false;
  hasProducts: boolean;
  productsAndActions: { name: string; action: string }[];
  isBlocked: boolean = false;
  isProcessingError: boolean = false;
  orderStateTypeEnum = OrderStateTypeEnum;

  private idsWithAction: {};
  private changedOrderItems: OrderItemDto[];

  constructor(
    private readonly productService: ProductService,
    private readonly blockService: BlockUIService,
    private router: Router,
    public authorizationService: AuthorizationService,
    private productCustomService: ProductCustomService,
    private thumbnailHelperService: ThumbnailHelperService,
    protected elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.changedOrderItems = _.filter(this.order.orderItems, orderItem => {
      if (orderItem.productId) {
        return !_.isEqual(orderItem.action, EcareOrderRowComponent.NO_CHANGE_ACTION);
      }
    });
    this.hasProducts = !_.isEmpty(this.changedOrderItems);
    this.getTotalPrice();
    this.prepareThumbnailsForRow();
    this.isBlocked = OrderUtils.getOrderAttributeValue(this.order, OrderingWizardService.IS_BLOCKED_ATTR) === 'true';
    this.isProcessingError = OrderUtils.getOrderAttributeValue(this.order, 'isProcessingError') === 'true';
  }


  ngOnChanges(changes: SimpleChanges) {
    const maxThumbnailsToShow = changes['maxThumbnailsToShow'];
    if (
      maxThumbnailsToShow &&
      maxThumbnailsToShow.currentValue !== maxThumbnailsToShow.previousValue &&
      !maxThumbnailsToShow.firstChange
    ) {
      this.prepareThumbnailsFromMap();
    }
  }

  getCustomerExtIdFromOrder(order: OrderDto): string {
    const customerAccountParamDto = _.find(
      order.orderAttributes,
      orderAttr => orderAttr.attr === 'customerAccount'
    ) as any;
    if (customerAccountParamDto) {
      const customerAccountParamValue = JSON.parse(customerAccountParamDto.value);
      return customerAccountParamValue.extId;
    }
  }

  /**
   * Get customer from the given order.
   *
   * @param order The source order.
   * @return The customer or null if no such customer exists in the order.
   */
  getCustomer(order: OrderDto): CustomerDto {
    const customerAttribute = order.orderAttributes.find(orderAttribute => orderAttribute.attr === 'customer');
    if (customerAttribute) {
      return JSON.parse(customerAttribute.value);
    } else {
      return null;
    }
  }

  selectCustomerInRow(customerId: string): void {
    this.selectCustomer.emit(customerId);
  }

  onRowClick(): void {
    if (!this.hasProducts) {
      return;
    } else if (this.collapsed === false) {
      this.collapsed = !this.collapsed;
      this.blockService.start(this.order.id);
      this.createIdsWithActionMap();
      this.getProductsNameByOrder()
        .pipe(finalize(() => this.blockService.stop(this.order.id)))
        .subscribe(productDetails => {
          this.productsAndActions = [];
          for (let i = 0; i < productDetails.length; i++) {
            if (
              productDetails[i].parametersStatic['orderSummary'] === 'true' &&
              (ProductUtils.isOfCategory(productDetails[i], CategoryTypeEnum.PRODC) ||
                ProductUtils.isOfCategory(productDetails[i], CategoryTypeEnum.PRD_L))
            ) {
              const productAndAction = {
                name: productDetails[i].name,
                action: this.idsWithAction[productDetails[i].id],
              };
              this.productsAndActions.push(productAndAction);
            }
          }
        });
    } else {
      this.collapsed = !this.collapsed;
    }
  }

  private getProductsNameByOrder(): Observable<ProductDetailDto[]> {
    return forkJoin(
      _.map(this.changedOrderItems, changedOrderItem => this.productService.getProductById(changedOrderItem.productId))
    );
  }

  private createIdsWithActionMap() {
    this.idsWithAction = _.chain(this.changedOrderItems).keyBy('productId').mapValues('action').value();
  }

  resumeOrder(order: OrderDto, event: any) {
    if (this.opportunity) {
      TicketService.setLocalOpportunity(this.opportunity);
    }
    if (order.authCode) {
      this.router.navigate([`/eshop/shopping-cart/${order.authCode}`]);
    } else {
      this.router.navigate([`/eshop/shopping-cart/id/${order.id}`]);
    }
    event.stopPropagation();
  }

  buttonInRowClick(event: any, order: any): void {
    event.stopPropagation();
    this.router.navigate([`/ecare/orders/${order.id}`]);
  }

  openDocumentPopUp() {
    this.documentsPopup.showPopUp();
  }

  protected prepareThumbnailsForRow() {
    if (!this.authorizationService.isInternal) {
      if (!this.thumbnailMap) {
        const assembledProductCodes = this.thumbnailHelperService.assembleUniqueProductIds([this.order]);
        if (assembledProductCodes.length > 0) {
          const productObservables: Array<Observable<ProductDetailDto>> =
            this.thumbnailHelperService.getProductDetailObservableList(assembledProductCodes);
          if (productObservables.length > 0) {
            forkJoin(productObservables)
              .pipe(
                map((productDtos: ProductDetailDto[]) => {
                  const thumbnailMap = [];
                  productDtos.forEach((productDto: ProductDetailDto) => {
                    thumbnailMap.push(this.thumbnailHelperService.getThumbnailMapRecord(productDto));
                  });
                  return thumbnailMap;
                })
              )
              .subscribe(thumbnailMap => {
                this.thumbnailMap = thumbnailMap;
                this.prepareThumbnailsFromMap();
              });
          }
        }
      } else {
        this.prepareThumbnailsFromMap();
      }
    }
  }

  protected prepareThumbnailsFromMap() {
    this.order.orderItems.forEach(orderItem => {
      const foundThumbnail = this.thumbnailMap.find(tb => tb.productId === orderItem.productId && !tb.omit);
      if (foundThumbnail) {
        const indexInArray = this.thumbnailsForRow.findIndex(tb => tb.name === foundThumbnail.productName);
        if (indexInArray !== -1) {
          const newCount = this.thumbnailsForRow[indexInArray].count + 1;
          this.thumbnailsForRow[indexInArray] = {
            thumbnail: foundThumbnail.thumbnail,
            name: foundThumbnail.productName,
            count: newCount,
            omit: !!foundThumbnail.omit,
          };
        } else {
          this.thumbnailsForRow.push({
            thumbnail: foundThumbnail.thumbnail,
            name: foundThumbnail.productName,
            count: 1,
            omit: !!foundThumbnail.omit,
          });
        }
      }
    });
    this.thumbnailsForRow.sort((a, b) => a.thumbnail?.localeCompare(b.thumbnail));
    if (this.thumbnailsForRow.length > this.maxThumbnailsToShow) {
      let count = 0;
      let names = '';
      for (let i = this.maxThumbnailsToShow; i < this.thumbnailsForRow.length; i++) {
        names += (count > 0 ? ', ' : '') + this.thumbnailsForRow[i].name;
        count++;
      }
      this.unshowedThumbnails = { count: count, names: names };
      this.thumbnailsForRow.splice(this.maxThumbnailsToShow);
    }
  }

  getTotalPrice() {
    this.totalPriceOC = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceOC'));
    this.totalPriceRC = JSON.parse(OrderUtils.getOrderAttributeValue(this.order, 'totalPriceRC'));
  }

  getStickerType() {
    return OrderUtils.getOrderStickerType(this.order);
  }
}
