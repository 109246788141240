<div
  class="slider-background"
  [ngClass]="{
    dark: titleColor === 'DARK',
    leftPosition: titlePosition === 'LEFT',
    centerPosition: titlePosition === 'CENTER',
    rightPosition: titlePosition === 'RIGHT'
  }"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }">
  <div
    class="container"
    [ngClass]="{
      leftPosition: titlePosition === 'LEFT',
      centerPosition: titlePosition === 'CENTER',
      rightPosition: titlePosition === 'RIGHT'
    }">
    <div class="content">
      <h2>
        {{ title | selectLocaleProperty }}
      </h2>
      <h5>
        <span app-cms-widget-rich-content [content]="content | selectLocaleProperty"></span>
      </h5>
      <div
        app-button
        [link]="buttonUrl"
        [label]="buttonLabel | selectLocaleProperty"
        [buttonNewTab]="buttonNewTab"
        [variant]="buttonColor === 'LIGHT' ? 'light' : buttonColor === 'DARK' ? 'dark' : 'primary'"></div>
    </div>
  </div>
</div>
