<div class="app-button-wrapper" [ngbTooltip]="noPrivilege ? noPrivilegeToolTip : ''">
  <a
    class="app-button-container"
    [attr.href]="link ? link : null"
    [target]="buttonNewTab === true ? '_blank' : ''"
    (click)="clicked($event)">
    <button
      class="app-button"
      [attr.type]="buttonType"
      [ngClass]="{
        primary: variant === 'primary',
        secondary: variant === 'secondary',
        tertiary: variant === 'tertiary',
        linkBtn: variant === 'link',
        withIcon: iconPath !== undefined,
        small: size === 'small',
        medium: size === 'medium',
        large: size === 'large',
        shrink: size === 'shrink',
        fullWidth: size === 'fullWidth',
        disabled: disabled,
        danger: variant === 'danger',
        whiteWithBorder: variant === 'whiteWithBorder',
        light: variant === 'light',
        dark: variant === 'dark'
      }">
      <i
        *ngIf="iconPath !== undefined"
        [inlineSVG]="iconPath"
        [ngClass]="{
          noLabel: !label
        }"></i>
      {{ label }}
      <ng-content></ng-content>
      <i *ngIf="iconPathAfter !== undefined" class="iconAfter" [inlineSVG]="iconPathAfter"></i>
    </button>
  </a>
</div>
