import {
  AuthenticationMethod,
  CmsUtils,
  CodebookService,
  ContentService,
  DynamicComponentRegistry,
  PageModuleService,
} from '@btl/btl-fe-wc-common';
import { environment } from '../environments/environment';
import { AuthFactoryService } from '@btl/btl-fe-wc-common/lib/services/auth-factory.service';
import { SystemIdInterceptor } from './helpers/system-id-interceptor';
import { ActivatedRoute, Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CookieService } from 'ngx-cookie-service';
import { APP_ROUTES } from './app-routing.module';
import { forkJoin } from 'rxjs';
import { DynamicRoutesConfig } from '@btl/btl-fe-wc-common/lib/models/dynamic-routes-config';

export function initializer(
  authFactory: AuthFactoryService,
  systemIdInterceptor: SystemIdInterceptor,
  activatedRoute: ActivatedRoute,
  orderingService: WcOrderingService,
  cookieService: CookieService,
  dynamicComponentRegistry: DynamicComponentRegistry,
  contentService: ContentService,
  codebookService: CodebookService,
  router: Router
): () => Promise<any> {
  let isRoutingConfigured = false;

  return (): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let authMethod = AuthenticationMethod[environment.authenticationMethod];
        // @ts-ignore
        if (typeof process !== 'undefined' && typeof process.versions.node !== 'undefined') {
          authMethod = AuthenticationMethod.MOCK;
          PageModuleService.resetVariables();
        }
        authFactory.setAuthenticationMethod(authMethod);
        // if ((environment.authenticationRoles) && authFactory.getAuthService() instanceof NcasService) {
        //   const allowedRoles = environment.authenticationRoles.split(",").map(role => role.trim());
        //   (<NcasService>authFactory.getAuthService()).setAlllowedRoles(allowedRoles);
        // }
        await authFactory.getAuthService().init({
          config: {
            url: environment.authenticationServerUri,
            realm: 'btl',
            clientId: 'btl-public',
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: false,
        });

        if (!isRoutingConfigured) {
          const cmsDynamicRoutesEnabled: boolean = environment.cmsDynamicRoutesEnabled.toLowerCase() === 'true';

          const finishRoutesInit = () => {
            isRoutingConfigured = true;
            router.initialNavigation();
            resolve(null);
          };

          const $contentRoutes = cmsDynamicRoutesEnabled ? contentService.getContentRoutes() : null;
          const $codebookModules = codebookService.getCodebookModules('MODULE_PAGE');

          forkJoin([$contentRoutes, $codebookModules]).subscribe(
            ([contentRoute, moduleRoutes]) => {
              const cmsDynamicRoutesConfig: DynamicRoutesConfig = {
                cmsRoutes: contentRoute,
                dynamicComponentRegistry: dynamicComponentRegistry,
                router: router,
                appRoutes: APP_ROUTES,
                defaultPageTemplateComponentId: DynamicComponentRegistry.DEFAULT_PAGE_TEMPLATE_COMPONENT_NAME,
              };
              CmsUtils.handleDynamicRoutes(cmsDynamicRoutesConfig);

              const pageDynamicRoutesConfig: DynamicRoutesConfig = {
                codebookModuleDtos: moduleRoutes.filter(module => module.moduleId === 'ecare'),
                dynamicComponentRegistry: dynamicComponentRegistry,
                router: router,
                appRoutes: APP_ROUTES,
                defaultPageTemplateComponentId: DynamicComponentRegistry.DEFAULT_PAGE_TEMPLATE_COMPONENT_NAME,
                defaultCanActivateGuardId: DynamicComponentRegistry.DEFAULT_CAN_ACTIVATE_GUARD,
              };
              PageModuleService.handleDynamicRoutes(pageDynamicRoutesConfig);
              finishRoutesInit();
            },
            err => {
              console.error('Error occurred while loading dynamic routes. Some pages may not be displayed.');
              console.error(err);
              finishRoutesInit();
            }
          );
        }
      } catch (error) {
        reject(error);
      }
    }).then(() => systemIdInterceptor.init(activatedRoute, orderingService, cookieService));
  };
}
