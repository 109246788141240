<app-ecare-page-layout columnTemplate="2fr 1fr" title="{{ 'wc.ecare.accountSettings.headline' | translate }}">
  <div left-side>
    <app-section-header
      class="cursor-pointer"
      title="{{ 'wc.ecare.accountDetails.manageContacts' | translate }}"
      [isCollapsed]="refContact.activeIds.length > 0"
      (click)="contact.toggle('toggleContact')">
    </app-section-header>
    <ngb-accordion #contact="ngbAccordion" #refContact activeIds="toggleContact" [destroyOnHide]="false">
      <ngb-panel id="toggleContact">
        <ng-template ngbPanelContent>
          <app-ecare-user-account-contacts></app-ecare-user-account-contacts>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div [hidden]="!formAgreementsComponent?.agreementsLoaded">
      <app-section-header
        class="cursor-pointer"
        title="{{ 'wc.shopping.summary.agreements' | translate }}"
        [isCollapsed]="refAgreement.activeIds.length > 0"
        (click)="agreemente.toggle('toggleAgreement')"
      >
      </app-section-header>
      <ngb-accordion
        #agreemente="ngbAccordion"
        #refAgreement
        activeIds="toggleAgreement"
        [destroyOnHide]="false"
      >
        <ngb-panel id="toggleAgreement">
          <ng-template ngbPanelContent [formGroup]="agreementsForm">
            <div class="section-content">
              <div class="ecare-account-settings -sub-section">
                <div class="section">
                  <app-form-agreements
                    #formAgreementsComponent
                    *ngIf="account"
                    entityType="com.emeldi.ecc.be.account.dto.Account"
                    [entityId]="account.id"
                    displayMode="ONLY_OPTIONAL"
                  >
                  </app-form-agreements>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <app-section-header
      class="cursor-pointer"
      title="{{ 'wc.ecare.accountSettings.appearance' | translate }}"
      [isCollapsed]="refApperance.activeIds.length > 0"
      (click)="apperance.toggle('toggleApperance')">
    </app-section-header>
    <ngb-accordion #apperance="ngbAccordion" #refApperance activeIds="toggleApperance" [destroyOnHide]="false">
      <ngb-panel id="toggleApperance">
        <ng-template ngbPanelContent>
          <div class="section-content">
            <app-ecare-user-account-appearance></app-ecare-user-account-appearance>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div right-side>
    <app-section-header
      class="cursor-pointer"
      title="{{ 'wc.ecare.accountSettings.details' | translate }}"
      [isCollapsed]="refDetails.activeIds.length > 0"
      (click)="details.toggle('toggleDetails')">
    </app-section-header>
    <ngb-accordion #details="ngbAccordion" #refDetails activeIds="toggleDetails" [destroyOnHide]="false">
      <ngb-panel id="toggleDetails">
        <ng-template ngbPanelContent>
          <div class="section-content">
            <div class="personalDetailsGrid">
              <app-sidebox-row
                label="{{ 'wc.ecare.accountEntity.firstName' | translate }}"
                >{{
                  generateAccountDetails(FIRSTNAME_PARAMETER)
                }}</app-sidebox-row
              >
              <app-sidebox-row
                label="{{ 'wc.ecare.accountEntity.lastName' | translate }}"
                >{{
                  generateAccountDetails(LASTNAME_PARAMETER)
                }}</app-sidebox-row
              >
              <app-sidebox-row
                label="{{ 'wc.shopping.contact.email.label' | translate }}"
                >{{ generateAccountDetails(LOGIN_PARAMETER) }}</app-sidebox-row
              >
              <app-sidebox-row
                label="{{ 'wc.ecare.dashboard.birthDate.label' | translate }}"
                >{{
                  generateAccountDetails(BIRTHDATE_PARAMETER) | date
                }}</app-sidebox-row
              >
              <app-sidebox-row
                label="{{
                  'wc.shopping.contact.contactPreferredLanguage.label'
                    | translate
                }}"
                >{{
                  "wc.common.general.language." +
                    generateAccountDetails(LOCALE_PARAMETER) | translate
                }}</app-sidebox-row
              >
              <!--                 <app-sidebox-row label="Preffered Channel">
                  <div class="settingsChannel">
                    <div class="order-status e-care-sticker">Email</div>
                    <div class="order-status e-care-sticker">SMS</div>
                  </div></app-sidebox-row
                > -->
              <app-sidebox-row [onlyForInternal]="true" label="{{ 'wc.ecare.accountEntity.roleType' | translate }}"
                >{{ generateAccountDetails(ROLENAME_PARAMETER) }}
              </app-sidebox-row>
            </div>
            <div class="personalButtons">
              <div
                app-button
                size="fullWidth"
                iconPath="/assets/svg/feather-edit.svg"
                label="{{ 'wc.ecare.accountDetails.updateData' | translate }}"
                (click)="userDetails()"></div>
              <div
                app-button
                variant="whiteWithBorder"
                size="fullWidth"
                iconPath="/assets/svg/feather-dark-lock.svg"
                label="{{ 'wc.ecare.accountDetails.changePassword' | translate }}"
                (click)="showSecurity()"></div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</app-ecare-page-layout>
