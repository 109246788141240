<div *ngIf="tipImagesMeta" class="container cms-widget-tip-images-list" [attr.id]="urlAnchor">
  <div class="-box-grid">
    <ng-container *ngFor="let tipImageMeta of tipImagesMeta; let i = index">
      <a class="-box" [class.active]="selectedTipIndex === i" (click)="boxClick(tipImageMeta, i)">
        <img
          class="-box-image"
          [lazyLoad]="tipImageMeta.tipImage"
          alt="{{ tipImageMeta.label | selectLocaleProperty }}" />

        <div class="-box-label">
          <h4>{{ tipImageMeta.label | selectLocaleProperty }}</h4>
        </div>
      </a>
    </ng-container>
  </div>

  <div class="-tip-content">
    <div app-cms-widget-rich-content [content]="renderContent"></div>
  </div>
</div>
