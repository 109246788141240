import { Component, Input } from '@angular/core';
import { CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-customers-popup',
  templateUrl: './customer-selection-popup.component.html',
})
export class CustomerSelectionPopupComponent {
  @Input()
  dialogRef;

  @Input()
  handler = (customer: CustomerDto) => {};

  public showPopup: boolean = false;

  closePopup() {
    this.dialogRef.dismiss();
  }

  customerSelected(customer: CustomerDto) {
    this.handler(customer);
    this.dialogRef.dismiss();
  }
}
