<div class="product-listing-buttons third-navigation">
  <div *ngIf="navigationGroupsTree.length > 0" class="product-listing-buttons_container">
    <button
      *ngIf="currentProductGroupParent && currentProductGroupParent.group.parentId"
      type="button"
      class="btn"
      [routerLink]="'/eshop/product-listing/' + currentProductGroupParent.group.seoUrl">
      <img height="auto" width="auto" [src]="'/assets/svg/upload-document.svg'" />
    </button>
    <ng-container *ngFor="let navigationGroupNode of navigationGroupsTree; let i = index">
      <button
        type="button"
        class="btn"
        [class.active]="
          currentProductGroup &&
          (currentProductGroup.group.id === navigationGroupNode.group.id ||
            currentProductGroup.group.parentId === navigationGroupNode.group.id)
        "
        [routerLink]="'/eshop/product-listing/' + navigationGroupNode.group.seoUrl">
        {{ getGroupName(navigationGroupNode.group) }}
      </button>
    </ng-container>
  </div>
</div>
