import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ErrorOnEnum, ErrorService, Failure, Failures } from '@btl/btl-fe-wc-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorPageModalComponent } from './error-page-modal/error-page-modal.component';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
  @HostBinding('class') class = 'error-page';

  error: HttpErrorResponse;
  errorCode = 0;
  failures: Array<Failure> = new Array<Failure>();
  errorOn;
  errorOnEnum = ErrorOnEnum;
  causedByMessage;
  modalScroll: boolean = false;

  routeSubscibe;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private ngbModal: NgbModal,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    this.routeSubscibe = this.route.queryParams.subscribe(params => {
      this.errorOn = params['errorOn'];
      const errorJson = this.errorService.getLastError()?.error;
      if (errorJson) {
        this.error = errorJson;
        this.errorCode = this.error.status;
        const failures: Failures = this.error.error;
        if (failures) {
          this.failures = failures.failures;
          if (this.failures && this.failures.length > 0) {
            if (this.failures[this.failures.length - 1].contextData) {
              this.causedByMessage = this.failures[this.failures.length - 1].contextData['causedBy.message'];
            } else {
              this.causedByMessage = false;
            }
          }
        }
      }
      if(this.failures.length == 0) {
        const failure: Failure = <Failure>{
          code: this.errorCode,
        };
        this.failures.push(failure);
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  goHomepage(): void {
    this.errorService.clearTopErrors();
    this.router.navigate(['/']);
  }

  openWindowCustomClass(content) {
    this.ngbModal.open(content, { size: 'md', windowClass: 'dialog dialog-input' });
  }

  openErrorPageModal() {
    const modalRef = this.ngbModal.open(ErrorPageModalComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
      backdrop: 'static',
      keyboard: false,
    });
    const errorPageModalComponent = <ErrorPageModalComponent>modalRef.componentInstance;
    errorPageModalComponent.failures = this.failures;
    errorPageModalComponent.causedByMessage = this.causedByMessage;
    errorPageModalComponent.errorCode = this.errorCode;
    errorPageModalComponent.dialogRef = modalRef;
  }
}
