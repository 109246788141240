import { ComplexFilter, ControlFilterMiner, ControlStructKeyArrayFilterMiner } from '@btl/btl-fe-wc-common';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';

@Component({
  selector: 'app-customer-accounts-filter',
  templateUrl: './ecare-customer-accounts-filter.component.html',
})
export class EcareCustomerAccountsFilterComponent extends ComplexFilter {
  protected advancedFilterFields = ['variableSymbol', 'id', 'extId', 'recursiveParentId'];

  @Input() customerId: string;
  @Input() canChangeCustomer = true;

  pickerInputType = PickerInputType;
  selectingField = false;
  filterForm = this.formBuilder.group({
    id: [],
    extId: [],
    variableSymbol: [],
    customName: [],
    recursiveParentId: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.extId, 'extId'),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.variableSymbol,
      'parameters',
      'name',
      'variableSymbol',
      'value',
      null
    ),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.customName,
      'parameters',
      'name',
      'customName',
      'value',
      null
    ),
    new ControlFilterMiner(this.filterForm.controls.recursiveParentId, 'recursiveParentId', null),
  ];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.selectingField) {
      this.closeAdvancedFilterOnGlobalClick(event);
    }
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected getDefaultFilterValues() {
    return {
      id: null,
      extId: null,
      variableSymbol: null,
      customName: null,
      recursiveParentId: this.customerId,
    };
  }
}
