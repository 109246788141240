import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailDto } from '@btl/order-bff';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-commitment-info',
  templateUrl: './product-commitment-info.component.html',
})
export class ProductCommitmentInfoComponent implements OnInit {
  @Input() product: ProductDetailDto;
  commitmentInfoText: string;

  ngOnInit(): void {
    this.commitmentInfoText = this.getCommitmentInfoText();
  }

  constructor(private readonly translateService: TranslateService) {}

  private getCommitmentInfoText(): string {
    return `${this.product.parametersStatic['duration']} ${this.translateService.instant(
      `wc.common.durationType.${this.product.parametersStatic['durationType']}`
    )}`;
  }
}
