<div>
  <div class="tile_content">
    <form class="ecare-filter dashboard-search_filter" [formGroup]="filterForm">
      <div class="-filter-fields -filter-fields-group">
        <input
          type="text"
          formControlName="accountLogin"
          class="filter-input-id"
          placeholder="{{ 'wc.ecare.accountEntityFilter.accountLogin.placeholder' | translate }}" />

        <app-advanced-filter
          clearHistoryTextLocalizedKey="wc.ecare.notifications.clearFilters"
          [form]="filterForm"
          [isAdvancedFilterOpen]="isAdvancedFilterOpen"
          [changedFilterFieldsCount]="changedFilterFieldsCount"
          (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
          (resetFieldsEmitter)="resetFilterFields()">
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.accountEntity.delegatedByLogin"
            controlName="delegatedByLogin"
            placeholderLocalizedKey="wc.ecare.accountEntity.delegatedByLogin"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            *ngIf="!presentInConstantFilter('entities')"
            labelLocalizedKey="wc.ecare.accountEntity.entityType"
            fieldType="select"
            controlName="entityType"
            idAttribute="013_account_entity_type"
            translationPrefix="wc.ecare.accountEntity.entityTypes"
            optionLabel="name"
            optionValue="name"
            [selectOptions]="entityTypes"
            [disableOptionsTranslate]="true"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            *ngIf="!presentInConstantFilter('entities')"
            labelLocalizedKey="wc.ecare.accountEntity.entityId"
            controlName="entityId"
            placeholderLocalizedKey="wc.ecare.accountEntity.entityId"
            [fieldType]="'input'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event.value)">
          </app-advanced-filter-field>
          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.accountEntityFilter.validAt"
            controlName="validAt"
            placeholderLocalizedKey="wc.ecare.accountEntityFilter.validAt"
            [fieldType]="'date'"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>

          <app-advanced-filter-field
            labelLocalizedKey="wc.ecare.accountEntity.role"
            fullWidth="true"
            fieldType="select"
            controlName="role"
            optionLabel="id"
            optionValue="id"
            [selectOptions]="roles"
            [disableOptionsTranslate]="true"
            (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
          </app-advanced-filter-field>
        </app-advanced-filter>
      </div>
      <app-button
        class="filter-search-button"
        iconPath="/assets/svg/search-icon.svg"
        label="{{ 'wc.common.search.button' | translate }}"
        (click)="handleChange()">
      </app-button>
    </form>
  </div>
</div>
