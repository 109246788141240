import { Component, Inject, Input, OnChanges, ViewChild } from '@angular/core';
import { BASE_PATH } from '@btl/order-bff';
import { Subscription } from 'rxjs';
import { CurrentLocaleService, GroupTreeNode } from '@btl/btl-fe-wc-common';
import { Product } from '../../../models/product';
import { FilterService } from '@service/filter.service';
import { ProductCustomService } from '@service/product-custom.service';
import { EllipsisDirective } from 'ngx-ellipsis';

@Component({
  selector: 'app-most-sold, [app-most-sold]',
  templateUrl: './most-sold.component.html',
})
export class MostSoldComponent implements OnChanges {
  public products: Array<Product>;
  @Input() public group: GroupTreeNode;
  private subscription: Subscription;

  @ViewChild(EllipsisDirective) ellipsisReload: EllipsisDirective;

  constructor(
    private productCustomService: ProductCustomService,
    private currentLocaleService: CurrentLocaleService,
    private filterService: FilterService,
    @Inject(BASE_PATH) public basePath
  ) {
    this.currentLocaleService.currentLocaleChange.subscribe(result => {
      this.ellipsisReload?.applyEllipsis();
    });
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.products = undefined;
    if (this.group && this.group.group) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (this.group.group.groupParams['bestsellingList']) {
        let bestSellingProductCodes = this.group.group.groupParams['bestsellingList'].split(';');
        if (this.group.group.groupParams['bestsellingMaxCount']) {
          bestSellingProductCodes = bestSellingProductCodes.slice(
            0,
            Number(this.group.group.groupParams['bestsellingMaxCount'])
          );
        }

        this.subscription = this.productCustomService
          .getProductsFromElasticByProductCodes(bestSellingProductCodes)
          .subscribe(bestSellingProducts => {
            this.products = bestSellingProductCodes
              .map(bestSellingProductCode => {
                const product = bestSellingProducts.find(prod =>
                  prod.colorVariants.some(colorVariant => colorVariant.id === bestSellingProductCode)
                );
                // use seoUrl of colorVariant if not main product
                if (product && product.id !== bestSellingProductCode) {
                  const colorVariant = product.colorVariants.find(
                    colorVariant => colorVariant.colorCode === bestSellingProductCode
                  );
                  if (colorVariant) {
                    product.seoUrl = colorVariant.colorCode;
                  }
                }
                return product;
              })
              .filter(bsp => !!bsp);
          });
      } else {
        const defaultProductFilter = this.filterService.getDefaultProductFilter();
        defaultProductFilter.attributes.groupId = this.group.group.id;
        // defaultProductFilter.sorting = SortTypeEnum.MOST_BOUGHT;
        defaultProductFilter.paging.pageSize = 3;
        this.subscription = this.productCustomService.getProductsByFilter(defaultProductFilter).subscribe(response => {
          this.products = response.hits.hits.map(hit => hit._source);
        });
      }
    }
  }

  public getCrossedPrice(priceSuperficial: string, ocPrice: number): number {
    if (priceSuperficial && ocPrice) {
      const priceSuperficialInt = parseInt(priceSuperficial);
      if (priceSuperficialInt > ocPrice) {
        return priceSuperficialInt;
      }
    }
    return null;
  }

  getStickerType(value) {
    if (value === 'outOfStock' && 'unavailable') {
      return 'neutral';
    } else {
      return 'success';
    }
  }
}
