<div class="modal-header">
  <h4 class="modal-title">
    {{ 'wc.ecare.search.customer.action.selectCustomer' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <app-ecare-customers (selectedCustomer)="customerSelected($event)" />
</div>

<div class="modal-footer">
  <app-button size="shrink" variant="secondary" [label]="'wc.common.close' | translate" (click)="closePopup()" />
</div>
