<div class="active_addons">
  <div class="addon_title-wrapper cursor-pointer" (click)="toggle()">
    <h3 class="addon_title">
      {{ addonsGroup.group?.name }}
    </h3>
    <div class="addon_toggle">
      <i class="arrow-triangle" [ngClass]="{ down: !isCollapsed, up: isCollapsed }"></i>
    </div>
  </div>

  <div *ngIf="!isCollapsed && addonsGroup.addons?.length > 0" class="card_grid">
    <app-addon-activation-addon
      *ngFor="let addon of addonsGroup.addons; let i = index"
      [addon]="addon"
      [partyId]="partyId"
      [parentId]="'listing_' + i">
    </app-addon-activation-addon>
  </div>
</div>
