<div
  *ngIf="linkToVideo"
  class="container cms-widget-video"
  [attr.id]="urlAnchor"
  [ngClass]="{ 'no-padding': !isStandaloneVideo }">
  <div class="-video-wrapper">
    <ng-container *ngIf="sourceType === 'YOUTUBE'; else html5_video">
      <iframe class="-video-player" [src]="linkToVideo | safeUrl"></iframe>
    </ng-container>

    <ng-template #html5_video>
      <video class="-video-player">
        <source [src]="linkToVideo | safeUrl" />
      </video>
    </ng-template>
  </div>
</div>
