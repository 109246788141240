import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentLocaleService, DmsService, ServiceUtils, AgreementService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DmsFileDto, PagedDmsFilesDto, AgreementTypeDto } from '@btl/order-bff';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-agreement',
  templateUrl: './form-agreement.component.html',
})
export class FormAgreementComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  collapsed = true;
  changing = false;

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  agreementForm: FormGroup;

  @Input()
  formMode = true;

  @Input()
  displayMode: string = "ALL";

  pagedDocuments: PagedDmsFilesDto;

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private dmsService: DmsService,
    private httpClient: HttpClient,
    private agreementService: AgreementService
  ) {}

  ngOnInit(): void {
    this.getFiles();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.getFiles());
  }

  getFiles() {
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'params',
      compareType: null,
      value: [
        {
          name: 'language',
          value: this.currentLocaleService.getCurrentLanguage(),
        },
      ],
    });
    search.filtering.push({
      column: 'references',
      compareType: null,
      value: [
        {
          entityType: 'com.emeldi.ecc.be.crm.dto.AgreementType',
          entityId: this.agreementForm.value.type.id,
        },
      ],
    });

    this.dmsService
      .filterFiles(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedDocuments = result;
      });
  }

  getAgreementTypeText(agreementType: AgreementTypeDto, textType: string): string {
    const text = agreementType.texts?.find(
      text => text.textType === textType && text.locale === this.currentLocaleService.getCurrentLanguage()
    );
    return text ? text.message : '';
  }

  download(dmsFile: DmsFileDto) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(dmsFile.contentHref, httpOptions).subscribe((data: any) => {
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = dmsFile.name;
      link.click();
    });
  }

  agreedChanged() {
    if (!this.changing && !this.formMode) {
      const agreement = this.agreementForm.value;
      delete agreement.when;
      delete agreement.type.code;
      delete agreement.type.mandatory;
      delete agreement.type.name;
      delete agreement.type.validFor;
      delete agreement.type.references;
      delete agreement.type.params;
      delete agreement.type.texts;
      this.agreementService.createOrUpdateAgreement(agreement).subscribe(result => {
        this.changing = true;
        this.agreementForm.patchValue(result);
        this.changing = false;
      });
    }
  }
}
