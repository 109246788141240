import { Component, Input, ViewChild } from '@angular/core';
import { EcareSettingsComponent } from '../../ecare-settings/ecare-settings.component';
import { CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-create-customer-popup',
  templateUrl: './create-customer-popup.component.html',
})
export class CreateCustomerPopupComponent {
  @ViewChild(EcareSettingsComponent) ecareSettings: EcareSettingsComponent;

  @Input() dialogRef;
  @Input() handler = (customer: CustomerDto) => {};

  closePopup() {
    this.dialogRef.dismiss();
  }

  saveNewCustomer() {
    this.ecareSettings.saveCustomer().subscribe(result => {
      if (result) {
        this.handler(this.ecareSettings.customer);
        this.dialogRef.dismiss();
      }
    });
  }
}
