import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AggregatedRatingDto, BASE_PATH, PagedReviewsDto, ReviewDto, SortDto } from '@btl/order-bff';
import { ProdRatingService, Search } from '@btl/btl-fe-wc-common';
import { PropertyAccessorLocalService } from 'app/services/property-accessor-local.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacebookCommentsComponent } from '../facebook-comments/facebook-comments.component';

@Component({
  selector: 'app-product-ratings-and-reviews, [app-product-ratings-and-reviews]',
  templateUrl: './product-ratings-and-reviews.component.html',
})
export class ProductRatingsAndReviewsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  product;
  @Input()
  productCode;
  @Output()
  readonly ratingLoaded: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  readonly reviewDetails: EventEmitter<AggregatedRatingDto> = new EventEmitter<AggregatedRatingDto>();
  public aggregatedRatingDto: AggregatedRatingDto;
  reviews: Array<ReviewDto> = [];
  currentReviewPage = 1;
  showMoreReviews = true;
  firstReview;
  private static defaultSearch: Search = {
    filtering: [],
    sorting: [
      {
        column: 'rating',
        sortOrder: SortDto.SortOrderDtoEnum.Desc,
      },
      {
        column: 'reviewedOn',
        sortOrder: SortDto.SortOrderDtoEnum.Desc,
      },
    ],
    paging: {
      page: 1,
      pageSize: 10,
    },
  };
  search: Search = JSON.parse(JSON.stringify(ProductRatingsAndReviewsComponent.defaultSearch));
  moreReviewAmount = 2;
  public minRatingLimit = 0;
  private onDestroy$: Subject<void> = new Subject<void>();
  public reviewViewType: string = '';
  facebookCommentsLoaded: boolean = true;

  @ViewChild(FacebookCommentsComponent) facebookComments;

  //Test data - delete after

  testReview: Array<ReviewDto> = [
    {
      extId: '1',
      id: '1',
      productId: '1',
      rating: 50,
      recommended: false,
      reviewCons: 'No proper dust and water resistance.',
      reviewedOn: new Date(2018, 5),
      reviewPros: 'Standout design, eye-catching colors options.',
      reviewSummary:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur distinctio fugit, laudantium perferendis sapiente tempore. Dolore et quae quibusdam! Assumenda beatae, consequatur consequuntur dignissimos dolorem nesciunt quod repudiandae similique suscipit?',
    },
    {
      extId: '2',
      id: '2',
      productId: '2',
      rating: 70,
      recommended: false,
      reviewCons: "Selfie camera is fixed-focus and there isn't really 32MP worth of detail in the images.",
      reviewedOn: new Date(2019, 12),
      reviewPros: 'Great image quality overall, some of the best low-light shots money can get you.',
      reviewSummary:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur distinctio fugit, laudantium perferendis sapiente tempore, xonsequatur consequuntur dignissimos dolorem nesciunt quod repudiandae similique suscipit?',
    },
  ];

  testAggregatedRatingDto: AggregatedRatingDto = { averageRating: 45, totalReviewAmount: 50, ratings: {} };

  constructor(
    private prodRatingService: ProdRatingService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    @Inject(BASE_PATH) public basePath
  ) {
    /*    this.propertyAccessorLocalService.getMinRatingLimit()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            if (result) {
              this.minRatingLimit = +result;
            }
          });*/

    this.minRatingLimit = 1;

    this.propertyAccessorLocalService
      .getMoreReviewAmount()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.moreReviewAmount = +result;
        }
      });
  }

  ngOnInit() {
    this.loadRatings();
    this.propertyAccessorLocalService.getReviewViewType().subscribe(reviewViewType => {
      this.reviewViewType = reviewViewType;
    });
  }

  private loadRatings() {
    if (this.productCode) {
      this.prodRatingService
        .getProductAggregatedRating(this.productCode)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result && result.totalReviewAmount > 0) {
            this.aggregatedRatingDto = result;
            this.reviewDetails.emit(this.aggregatedRatingDto);
            if (result.averageRating >= this.minRatingLimit) {
              this.ratingLoaded.emit(result.averageRating);
            }
          }
        });

      this.prodRatingService
        .getProductReviews(this.productCode, this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedReviewsDto) => {
          if (result) {
            this.firstReview = result.data.find(review => review.reviewSummary);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productCode'] && !changes['productCode'].isFirstChange()) {
      this.setDefaultValues();
      this.loadRatings();
    }
  }

  private setDefaultValues() {
    this.search = JSON.parse(JSON.stringify(ProductRatingsAndReviewsComponent.defaultSearch));
    this.reviews = [];
    this.currentReviewPage = 1;
    this.showMoreReviews = true;
    this.aggregatedRatingDto = undefined;
  }

  moreReviews() {
    this.search.filtering = [];
    this.search.sorting = [
      {
        column: 'reviewedOn',
        sortOrder: SortDto.SortOrderDtoEnum.Desc,
      },
    ];
    this.search.paging.page = this.currentReviewPage;
    this.search.paging.pageSize = this.moreReviewAmount;
    this.prodRatingService.getProductReviews(this.productCode, this.search).subscribe((result: PagedReviewsDto) => {
      if (result) {
        result.data.forEach(review => {
          if (!this.firstReview || this.firstReview.id !== review.id) {
            this.reviews.push(review);
          }
        });
        this.currentReviewPage++;
        if (this.currentReviewPage > result.totalPages) {
          this.showMoreReviews = false;
        }
      }
    });
  }

  checkFacebookComments() {
    if (this.reviewViewType === 'facebook') {
      setTimeout(() => {
        let facebookCommentsHeight = this.facebookComments.facebookCommentsApp.nativeElement.firstElementChild.offsetHeight
        if(facebookCommentsHeight === 0) {
          this.facebookCommentsLoaded = false;
        }
     }, 500);
    }
  }

  getFacebookProductUrl() {
    const path = this.basePath;
    //Uncomment if want to test it locally
    // if (path.indexOf('localhost')) {
    //   path = 'http://btl-systest02.emeldi.com:8080';
    // }
    return `${path}/eshop/product-detail/${this.product.seoUrl}`;
  }
}
