<div class="cookiebar-wrapper">
  <div class="cookiebar-main">
    <div class="container">
      <div class="cookiebar-container">
        <div class="cookiebar-msg">
          <i [inlineSVG]="'/assets/svg/awesome-cookie-bite.svg'"></i>
          <div>{{ 'wc.shopping.cookiePolicy.cookieBar.text' | translate }}</div>
        </div>
        <div class="cookiebar-cell">
          <app-button
            class="cookie-app-button"
            size="shrink"
            variant="primary"
            label="{{ 'wc.shopping.cookiePolicy.cookieBar.acceptAll' | translate }}"
            (click)="acceptAllCookieAgreements()"></app-button>
          <app-button
            class="cookie-app-button"
            size="shrink"
            variant="secondary"
            label="{{ 'wc.shopping.cookiePolicy.cookieBar.setting' | translate }}"
            (click)="openCookiePolicySettings()"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
