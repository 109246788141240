<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.shopping.reservation.failed.popup.header' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="reservation-error-modal">
    <p class="reservation-error-modal-label">{{ 'wc.shopping.reservation.failed.popup.description' | translate }}</p>
    <ng-container *ngFor="let product of products; index as index">
      <div class="reservation-error-modal-item">
        <div class="reservation-error-modal-item-leftSide">
          <!-- Image -->
          <img *ngIf="product.picturePreview" src="{{ product.picturePreview.href }}" alt="{{ product.name }}"/>
          <img
            *ngIf="!product.picturePreview"
            src="/assets/img/products/default_product_image.png"
            alt="{{ product.label | selectLocaleProperty }}"/>
        </div>
        <div class="reservation-error-modal-item-rightSide">
          <div class="error-modal-item-error">
            <span><i [inlineSVG]="'/assets/svg/danger-icon.svg'"></i></span>
            {{ 'wc.shopping.reservation.failed.' + errors[index] | translate }}
          </div>
          <p>{{ product.name }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <div>
    <app-button
      variant="primary"
      size="large"
      label="{{ 'wc.common.close.button' | translate }}"
      (click)="closePopup()">
    </app-button>
  </div>
</div>
