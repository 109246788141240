import { Component, Input, OnInit } from "@angular/core";
import { EcareContactHistoryComponent } from "./ecare-contact-history.component";
import { CodebookService } from "@btl/btl-fe-wc-common";

@Component({
  selector: 'app-ecare-contact-history-mobile, [app-ecare-contact-history-mobile]',
  templateUrl: './ecare-contact-history-mobile.component.html',
})
export class EcareContactHistoryMobileComponent implements OnInit {
  @Input()
  contactHistory;

  constructor(
    public ecareContactHistoryComponent: EcareContactHistoryComponent,
    public codebookService: CodebookService
  ) {}

  ngOnInit(): void {}
}
