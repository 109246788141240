import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent implements OnInit {
  @Input()
  setLocation;

  @Input()
  readOnly;

  @Input()
  parentGroup: FormGroup;

  locationForm: FormGroup;

  @Input()
  locationId;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.parentGroup) {
      this.locationForm = this.fb.group({
        address: [null],
        name: [null],
        block: [null],
        blockNo: [null],
        site: [null],
        adminCentre: [null],
        entrance: [null],
        floor: [null],
        apartment: [null],
        doorTitle: [null],
        extId: [null],
        municipality: [null],
        longitude: [null],
        latitude: [null],
        note: [null],
      });
      this.parentGroup.addControl(`location${this.locationId}`, this.locationForm);
      this.locationForm.disable();
    }
  }
}
