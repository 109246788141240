<div class="widget--content_mainInfo">
  <div *ngIf="label1" class="mainInfo_label {{labelAdditionalStyle}}">{{ label1 }}</div>
  <div *ngIf="label2" class="mainInfo_label {{labelAdditionalStyle}}">{{ label2 }}</div>
  <div class="mainInfo_price">
    <ng-container *ngIf="priceFormat else noPriceFormat">
      <div class="price" [innerHTML]="price | price"></div>
    </ng-container>
    <ng-template #noPriceFormat>
      {{price}}
    </ng-template>
    <span>{{ period }}</span>
  </div>
  <div *ngIf="price2" class="mainInfo_price second">
    <ng-container *ngIf="priceFormat else noPriceFormat">
      <div class="price" [innerHTML]="price2 | price"></div>
    </ng-container>
    <ng-template #noPriceFormat>
      {{price2}}
    </ng-template>
    <div>
      <span>{{ period2 }}</span>
    </div>
  </div>
  <div *ngIf="priceOnTop" class="mainInfo_price on-top">
    <ng-container *ngIf="priceFormat else noPriceFormat">
      <div>+</div>
      <div class="price" [innerHTML]="priceOnTop | price"></div>
    </ng-container>
    <ng-template #noPriceFormat>
      {{priceOnTop}}
    </ng-template>
    <span>{{ periodOnTop }}</span>
  </div>
  <div *ngIf="infoText" class="mainInfo_infoText">
    <i *ngIf="hasSuccessIcon" [inlineSVG]="'/assets/svg/check-FA.svg'"></i>{{ infoText }}
  </div>
  <ng-content></ng-content>
</div>


