<div
  class="section-header"
  [class.isCollapsed]="isCollapsed !== undefined"
  [ngClass]="{
    small: size === 'small',
    leftAlign: textAlign === 'left',
    centerAlign: textAlign === 'center',
    rightAlign: textAlign === 'right'
  }">
  <div>
    <h6>
      {{ title }}
      <i
        *ngIf="isCollapsed !== undefined"
        [inlineSVG]="'assets/svg/section-arrow.svg'"
        [ngStyle]="isCollapsed ? { transform: 'rotate(-180deg)' } : { transform: 'rotate(0)' }"></i>
    </h6>
    <a
      *ngIf="editable"
      class="edit-icon"
      placement="bottom"
      [attr.href]="link ? link : null"
      ngbTooltip="{{ 'wc.shopping.confirmation.changeInformation.button' | translate }}">
      <i [inlineSVG]="'assets/img/ecare/edit_icon.svg'"></i>
    </a>
  </div>

  <div class="section-header_content">
    <div *ngIf="rightText" class="section-additional-text">
      {{ rightText }}
    </div>
    <ng-content></ng-content>
  </div>
</div>
