import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketingService } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TicketTypeDto, TicketTypePurposeDto } from '@btl/order-bff';

@Component({
  selector: 'app-create-ticket-modal',
  templateUrl: './create-ticket-modal.component.html',
})
export class CreateTicketModalComponent {
  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  selectHandler;

  @Output()
  readonly createTicketEvent: EventEmitter<TicketTypeDto> = new EventEmitter<TicketTypeDto>();

  areas = [];
  codes = [];
  ticketTypes: TicketTypeDto[];

  newTicketForm: FormGroup = this.formBuilder.group({
    areaType: [null, Validators.required],
    code: [null, Validators.required],
  });

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private ticketingService: TicketingService) {
    this.ticketingService
      .getTicketTypes(TicketTypePurposeDto.CREATE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticketType => {
        this.ticketTypes = ticketType.sort((a, b) => a.areaType.localeCompare(b.areaType));
        this.areas = [...new Map(ticketType.map(item => [item.areaType, item])).values()];
        this.areas = this.areas.sort((a, b) => a.areaType.localeCompare(b));
      });
  }

  continue() {
    if (this.newTicketForm.valid) {
      this.createTicketEvent.emit(this.newTicketForm.getRawValue());
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ticketAreaChange(event) {
    this.codes = this.ticketTypes.filter(p => p.areaType === event).sort((a, b) => a.code.localeCompare(b.code));
  }
}
