<div class="accordion_panel">
  <div class="accordion_title" [ngClass]="{ open: !collapsed }" (click)="toggle()">
    {{ 'wc.ecare.dashboard.customerAccount.label' | translate }} {{ customerAccount.extId }}
  </div>
  <div *ngIf="!collapsed" class="tile -tile12 accordion_detail">
    <app-customer-account-detail [customerAccount]="customerAccount" [customer]="customer">
    </app-customer-account-detail>
  </div>
  <div class="accordion_child">
    <app-ecare-business-customer-account
      *ngFor="let customerAccount of customerAccounts"
      [customerAccount]="customerAccount"
      [customer]="customer">
    </app-ecare-business-customer-account>

    <app-subscription-tile
      *ngFor="let tariff of tariffs"
      [asset]="tariff.assets[0]"
      [customerAccount]="customerAccount"
      [customer]="customer"
      [isBusiness]="true"
      [tariffSpace]="tariff">
    </app-subscription-tile>
  </div>
</div>
