import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderDto } from '@btl/order-bff';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { NavigationExtras, Router } from '@angular/router';
import { BlockTemplateComponent, CurrentLocaleService, getCurrentHost } from '@btl/btl-fe-wc-common';
import { BlockUIService } from 'ng-block-ui';
import { FormUtils } from '../../../helpers/form-utils';

@Component({
  selector: 'app-prepaid-recharge',
  templateUrl: './prepaid-recharge.component.html',
})
export class PrepaidRechargeComponent {
  //region Data:

  /**
   * The UI block template.
   */
  blockTemplate = BlockTemplateComponent;

  /**
   * A flag specifying if the prepaid recharge failed (from any reason).
   */
  generalFailure: boolean = false;

  /**
   * The recharging form.
   */
  rechargeForm: FormGroup;

  //region IO:

  /**
   * The MSISDN to recharge.
   */
  @Input()
  msisdn;

  /**
   * The credit check result.
   */
  @Input()
  checkCreditResultDto;

  /**
   * The customer of the recharged MSISDN.
   */
  @Input()
  customer;

  /**
   * The customer account of the recharged MSISDN.
   */
  @Input()
  customerAccount;

  /**
   * The dialog reference.
   */
  @Input()
  dialogRef;

  /**
   * Message key holding localization for currency name shown with price
   */
  currencySymbolMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbModal,
    private orderingService: WcOrderingService,
    private shoppingCartService: ShoppingCartService,
    private blockUIService: BlockUIService,
    private currentLocaleService: CurrentLocaleService,
    private router: Router
  ) {
    this.currencySymbolMessage = this.currentLocaleService.getCurrencyMsgKey();

    this.rechargeForm = this.formBuilder.group({
      rechargeAmount: [6, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(6), Validators.max(99)]],
    });
  }

  /**
   * Perform recharging. A PRP_RECHARGE order is created and the recharged amount is set as its attribute. The user gets redirected to
   * payment.
   */
  recharge() {
    FormUtils.validateAllFormFields(this.rechargeForm);
    if (this.rechargeForm.invalid) {
      return;
    }

    this.generalFailure = false;
    this.blockUIService.start(this.prepaidRechargeBlockUiElementName);

    const newOrder: OrderDto = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.CHECKOUT_THANKYOU,
      ScenarioTypeEnum.PRP_RECHARGE
    );
    OrderUtils.addCustomerAttributeToOrder(newOrder, this.customer);
    OrderUtils.addCustomerAccountAttributeToOrder(newOrder, this.customerAccount);
    OrderUtils.addAttributeToOrder(newOrder, 'AMOUNT', this.rechargedAmount);
    OrderUtils.addAttributeToOrder(newOrder, 'MSISDN', this.msisdn);

    this.orderingService.createOrder(newOrder, true).subscribe((order1: OrderDto) => {
      if (order1 && !this.generalFailure) {
        this.orderingService.getOrderStoringHandler(order1).subscribe((order2: OrderDto) => {
          if (order2 && !this.generalFailure) {
            this.blockUIService.stop(this.prepaidRechargeBlockUiElementName);
            this.dialogRef.dismiss();

            const navigationExtras: NavigationExtras = {
              queryParams: {
                callbackUrlBase: `${getCurrentHost()}/ecare/`,
                successUrl: 'dashboard',
                errorUrl: 'dashboard',
              },
            };
            this.router.navigate(['/eshop/payment'], navigationExtras);
          }
        }, this.setFailure);
      }
    }, this.setFailure);
  }

  //region Helpers:

  /**
   * Get the recharged amount from the form.
   */
  get rechargedAmount(): string {
    return this.rechargeForm.controls['rechargeAmount'].value;
  }

  /**
   * Set SIM change failure.
   *
   * @param error The error.
   */
  private setFailure = (error: any) => {
    this.generalFailure = true;
    this.blockUIService.stop(this.prepaidRechargeBlockUiElementName);
  };

  /**
   * Get name of UI block element.
   */
  get prepaidRechargeBlockUiElementName() {
    return 'prepaidRecharge';
  }
}
