import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * {@code ProductRatingComponent} is a component responsible for displaying and handling product rating. It can be
 * editable (to allow the user to rate a product or for configuration of rating in product filter), or uneditable (just
 * for displaying the product rating).
 *
 * Clients of editable product rating can subscribe changes via {@link onChange}.
 */
@Component({
  selector: 'product-rating',
  templateUrl: './product-rating.component.html',
})
export class ProductRatingComponent implements OnInit {
  //region Data:

  /**
   * The options available to be selected as product rating. One option is a pair of value formatted for BFF and value
   * itself which can be used for making a localization key.
   */
  readonly availableOptions: [string, string][] = [];

  //region IO:

  /**
   * The current rating.
   */
  @Input()
  rating: string;

  /**
   * A flag configuring whether the rating is editable.
   * @type {boolean}
   */
  @Input()
  editable: boolean = false;

  /**
   * An event emitter activated when a change in the rating occurs. This is allowed only if the component is editable.
   */
  @Output()
  readonly onChange: EventEmitter<string> = new EventEmitter<string>();

  //region Life-cycle:

  public ngOnInit(): void {
    this.createAvailableOptions();
  }

  //region Event handling:

  /**
   * Handle change of editable rating. When a change happens, an event is emitted (see {@link onChange}).
   */
  public handleChange(): void {
    this.onChange.emit(this.rating);
  }

  //region Helpers:

  /**
   * Create options available to be selected as product rating.
   */
  private createAvailableOptions(): void {
    const numberOfOptions = 5;
    for (let option = 0; option <= numberOfOptions; option++) {
      this.availableOptions.push([`gte:${option}`, `${option}`]);
    }
  }
}
