import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { CodebookService, CurrentLocaleService, FormUtils } from '@btl/btl-fe-wc-common';
import { AddressHolder } from '../../models/address-holder';
import { ProductElasticFilter } from '../../models/product-elastic-filter';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FiltersComponent } from '../wizard/product-listing/filters/filters.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { addressTypes, DisplayAddressPipe } from '../../pipes/display-address.pipe';
import { Constants } from '../../constants';
import { PickUpPointService } from '@service/pick-up-point.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-form-pick-up-point-input',
  templateUrl: './form-pick-up-point-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPickUpPointInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormPickUpPointInputComponent),
    },
  ],
})
export class FormPickUpPointInputComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy, OnChanges {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private readonly DEFAULT_SHOW_POINTS_QUANTITY = 10;

  @Input()
  value;

  @Input()
  provider;

  @Input()
  disabled = false;

  @Input()
  control: AbstractControl;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  form: FormGroup = this.formBuilder.group({
    pickUpPoint: [],
  });

  lat: number;
  lng: number;

  currentLocation: LocationDto;

  zoom = 7;
  points: Array<PointDto>;
  showPoints: Array<PointDto> = [];
  showPointsQuantity = this.DEFAULT_SHOW_POINTS_QUANTITY;
  selectedPoint: PointDto;
  hoveredPoint: PointDto;
  town: string;
  mapProviderEnum = MapProviderEnum;

  @ViewChild('pointSearch', { static: false })
  private searchInput: ElementRef;
  private searchTerms = new Subject<string>();
  public autocompletePoints: Observable<AddressHolder[]>;
  private maxAutocompleteResults: number;
  private maxAutocompleteResultsSubscription: Subscription;
  public filter: ProductElasticFilter;
  addressTypes = addressTypes;
  public mapProvider: MapProviderEnum;

  constructor(
    private formBuilder: FormBuilder,
    private pickUpPointService: PickUpPointService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    public httpClient: HttpClient,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    this.propertyAccessorLocalService.getPickUpPointMapProvider().subscribe(result => {
      this.mapProvider = result as MapProviderEnum;

      this.pickUpPointService.searchByText('', 1000, this.getProviderProperty()).subscribe(result => {
        if (result.length > 0) {
          this.lng = 0;
          this.lat = 0;
          this.points = [];
          result.forEach(point => {
            this.points.push(point._source);
            this.lng += point._source['adrLocation'].lon;
            this.lat += point._source['adrLocation'].lat;
          });
          this.lng = this.lng / result.length;
          this.lat = this.lat / result.length;
        }
        if (this.control.value) {
          const searchSplit: [] = this.control.value.split(',');
          searchSplit.splice(-1);
          this.pickUpPointService
            .searchByText(searchSplit.join(','), 1, this.getProviderProperty())
            .subscribe(selectedResult => {
              if (selectedResult.length > 0) {
                this.pointClicked(selectedResult[0]._source);
              }
            });
        }
      });
    });
    this.initAddressAutocomplete();

    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled = changes['disabled'].currentValue;
    }
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  public searchChange(text: string) {
    this.searchTerms.next(text);
    if (text === '') {
      this.selectPickUpPoint(null);
    }
  }

  public autocompleteFocusOut(autocomplete: HTMLElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  private initAddressAutocomplete() {
    this.maxAutocompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .subscribe(maxAutocompleteResults => (this.maxAutocompleteResults = maxAutocompleteResults));
    this.autocompletePoints = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<AddressHolder[]>([]);
        } else {
          return this.pickUpPointService
            .searchByText(term, this.maxAutocompleteResults, this.getProviderProperty())
            .pipe(source => {
              return source;
            });
        }
      })
    );
  }

  getProviderProperty() {
    const properties = new Map<string, any>();
    if (this.provider) {
      properties.set('provider', this.provider);
    }

    return properties;
  }

  private setCurrentLocation() {
    this.zoom = 8;
    if (this.selectedPoint) {
      this.selectedPoint = null;
    }

    if (!this.currentLocation) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          this.currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        });
      }
    } else {
      this.lat = this.currentLocation.lat;
      this.lng = this.currentLocation.lng;
    }
  }

  fillPointsDistances() {
    this.points.sort((a, b) => (a['distance'] > b['distance'] ? 1 : -1));
    this.resetShowPoints();
    this.points.forEach(point => this.showPoints.push(point));
  }

  pointClicked(point: any) {
    this.goToPoint(point.adrLocation.lon, point.adrLocation.lat);
    this.showCityPoints(
      this.points.find(point => point.adrLocation.lon === this.lng && point.adrLocation.lat === this.lat)
    );
  }

  showCityPoints(point) {
    this.hoveredPoint = null;
    this.selectedPoint = point;
    if (point && (!this.town || (this.town && this.town !== point.adrCity))) {
      this.town = point.adrCity;
      this.pickUpPointService.searchByText(this.town, 1000, this.getProviderProperty()).subscribe(result => {
        this.resetShowPoints();
        if (result.length > 0) {
          result.forEach(resultPoint =>
            this.showPoints.push(
              this.points.find(findPoint => FormPickUpPointInputComponent.isSamePoint(findPoint, resultPoint._source))
            )
          );
        }
      });
    }
  }

  pointHover(point) {
    this.hoveredPoint = point;
  }

  resetShowPoints() {
    this.showPoints.length = 0;
    this.showPointsQuantity = this.DEFAULT_SHOW_POINTS_QUANTITY;
  }

  isSelectedPoint(point) {
    return FormPickUpPointInputComponent.isSamePoint(this.selectedPoint, point);
  }

  isHoveredPoint(point) {
    return FormPickUpPointInputComponent.isSamePoint(this.hoveredPoint, point);
  }

  public static isSamePoint(point1, point2) {
    return (
      point1 &&
      point2 &&
      point1['adrLocation'].lat === point2['adrLocation'].lat &&
      point1['adrLocation'].lon === point2['adrLocation'].lon
    );
  }

  public static getPointIcon(selectedPoint, hoveredPoint, point) {
    if (FormPickUpPointInputComponent.isSamePoint(selectedPoint, point)) {
      return '/assets/img/pin-active.png';
    } else if (FormPickUpPointInputComponent.isSamePoint(hoveredPoint, point)) {
      return '/assets/img/pin-hover.svg';
    } else {
      return '/assets/img/pin-normal.png';
    }
  }

  goToPoint(lng, lat) {
    this.lng = lng;
    this.lat = lat;
    this.zoom = 16;
  }

  private selectPickUpPoint(selectedPoint) {
    let point = null;
    if (selectedPoint) {
      point = selectedPoint._source;
      this.form.controls['pickUpPoint'].patchValue(point);
      const addressPipe = new DisplayAddressPipe(this.codebookService, this.currentLocaleService);
      this.searchInput.nativeElement.value = addressPipe.transform(point, addressTypes.PICK_UP_POINT);
      this.goToPoint(point.adrLocation.lon, point.adrLocation.lat);
      this.showCityPoints(point);
      this.onChange.emit(this.getValue());
    }
  }

  pointHoverEvent(point) {
    this.hoveredPoint = point;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    this.selectPickUpPoint(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return this.form.getRawValue().pickUpPoint;
    }
    return null;
  }
}

enum MapProviderEnum {
  OPEN_LAYER = 'Open',
  GOOGLE_MAP = 'GoogleMap',
}

export interface PointDto {
  id?: string;
  city?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  district?: string;
  country?: string;
  adrLocation?: {
    lon: number;
    lat: number;
  };
}

export interface LocationDto {
  lng: number;
  lat: number;
}
