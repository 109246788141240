import { Component, Input } from '@angular/core';
import { AccountDto } from '@btl/order-bff';

@Component({
  selector: 'app-users-popup',
  templateUrl: './user-selection-popup.component.html',
})
export class UserSelectionPopupComponent {
  @Input()
  dialogRef;

  @Input()
  includeExternal = false;

  @Input()
  handler = (user: AccountDto) => {};

  public showPopup: boolean = false;

  closePopup() {
    this.dialogRef.dismiss();
  }

  userSelected(user: AccountDto) {
    this.handler(user);
    this.dialogRef.dismiss();
  }
}
