<div class="checkout-page_cart">
  <div class="stickyCart" id="stickyCart">
    <div class="heading d-none d-lg-block">
      <app-page-header title="{{ 'wc.shopping.checkout.cart.heading' | translate }}">
        <app-checkout-shopping-cart-secondary-actions></app-checkout-shopping-cart-secondary-actions>
      </app-page-header>
    </div>
    <div class="items-section d-none d-lg-block">
      <app-shopping-cart-items
        #shoppingCartItems
        [showQuantityChange]="false"
        [showRemoveBtn]="false"
        [showDetails]="false"
        [showConfigurationBtn]="false"
        [displayMode]="'COMPACT'">
      </app-shopping-cart-items>
    </div>
    <div class="summary-section">
      <app-ordering-wizard-actions
        #orderingWizardActions
        [showPrice]="true"
        [displayMode]="'COMPACT'"
        [termsAndConditions]="termsAndConditions"
        [alwaysShowNavigationButtons]="alwaysShowNavigationButtons"
        [disableContinueButton]="disableContinueButton"
        (checkForm)="checkFormAction()">
      </app-ordering-wizard-actions>
    </div>
  </div>
</div>
