import { Component } from '@angular/core';
import {
  EcareAbstractTariffWidgetComponent,
} from '../ecare-abstract-tariff-widget/ecare-abstract-tariff-widget.component';

@Component({
  selector: 'app-ecare-banner-widget, [app-ecare-banner-widget]',
  templateUrl: './ecare-banner-widget.component.html',
})
export class EcareBannerWidgetComponent extends EcareAbstractTariffWidgetComponent {
}
