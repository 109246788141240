import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sticker, [app-sticker]',
  templateUrl: './sticker.component.html',
})
export class StickerComponent {
  @Input() label?: string;
  @Input() variant?: 'default' | 'warning' | 'inform' | 'success' | 'important' | 'danger' | 'neutral' = 'default';
  @Input() iconPath?;
  @Input() iconPathAfter?;
}
