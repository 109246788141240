import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CodebookDto, TicketDto } from '@btl/order-bff';
import { CustomerService } from '@service/customer.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  AppBlockerService,
  AuthFactoryService,
  BlockTemplateComponent,
  CodebookService,
  Search,
  StickyMessageService,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WcOrderingService } from '@service/wc-ordering.service';
import { Subject } from 'rxjs';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { from } from 'rxjs/internal/observable/from';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
})
export class TicketsComponent implements OnDestroy, OnInit {
  tickets: TicketDto[] = [];

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  moreRecordsExists = false;

  orderDto;
  search: Search = {
    filtering: [],
    sorting: [{ column: 'startFrom', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  opportunityStates: CodebookDto[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private readonly orderingService: WcOrderingService,
    private readonly appBlockerService: AppBlockerService,
    private readonly propertyAccessorLocalService: PropertyAccessorLocalService,
    private readonly ticketingService: TicketingService,
    private authFactoryService: AuthFactoryService,
    public modal: NgbModal,
    private codebookService: CodebookService,
    private stickyMessageService: StickyMessageService
  ) {
    this.renderer.addClass(document.body, 'ecare');

    this.codebookService
      .getCodebooks('TICKET_OPPORTUNITY_STATE')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => (this.opportunityStates = cb));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      from(this.authFactoryService.getAuthService().getUserInfo()).subscribe(userProfile => {
        this.search.filtering.push({
          column: 'assignedTo',
          compareType: 'EQUAL',
          value: userProfile.username,
        });
        this.loadTicketsBySearch(this.search);
      });
    });
  }

  loadTicketsBySearch(search: Search) {
    this.ticketingService
      .getTicketsByFilter(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.tickets = result.data;
          if (result.data.length > 0 && result.data.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadTicketsBySearch(this.search);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getCustomer(ticket) {
    return ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer')
      .map(ref => ref.entityId);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.search.paging.page++;
      this.loadTicketsBySearch(this.search);
    }
  }

  edit(ticketDto: TicketDto, inNewTab: boolean = false) {
    const urlTree = this.router.createUrlTree([ticketDto.id], { relativeTo: this.route });
    if (inNewTab) {
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigateByUrl(urlTree);
    }
  }

  getParamValue(params, paramName) {
    return params.find(findParam => findParam.name === paramName)?.value;
  }

  new() {
    const urlTree = this.router.createUrlTree(['newTicket'], { relativeTo: this.route });
    this.router.navigateByUrl(urlTree);
  }

  showAssignTo() {
    return this.search.filtering.find(filter => filter.column === 'assignTo' && filter.compareType === 'IN');
  }

  delete(ticket: TicketDto) {
    this.ticketingService
      .deleteTicket(ticket.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.ticket.ticketDeleted');
        this.loadTicketsBySearch(this.search);
      });
  }

  editInNewTab(ticket: TicketDto) {}
}
