/**
 * Global static constants
 */
export class Constants {
  public static readonly DEBOUNCE_TIME = 500;
  public static readonly SESSIONID = 'sessionid';
  //properties TTL in seconds
  public static readonly PROPERTIES_TTL: number = 60 * 60;

  // height of the fixed navigation
  public static readonly NAVIGATION_HEIGHT = '150px';
  public static readonly PRICE_BOX_ELEMENT_ID = 'priceBoxElement';
}
