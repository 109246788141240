<div class="content-header">
  {{ "wc.ecare.accountSettings.theme" | translate }}
  <div class="themes">
    <div
      *ngFor="let themeType of themeVariants"
      class="-theme-box cursor-pointer"
      [ngClass]="{ selected: selectedThemeCssClass === themeType }"
      (click)="chooseThemeVariant(themeType)"
    >
      <i [inlineSVG]="'/assets/svg/theme-' + themeType + '.svg'"></i>
    </div>
  </div>
</div>

<!--<div class="content-header">-->
<!--  {{ "wc.ecare.accountSettings.fontSize" | translate }}-->
<!--  <div class="font-settings">-->
<!--    <div-->
<!--      *ngFor="let font of fontSizes"-->
<!--      class="size size-{{ font }} cursor-pointer"-->
<!--      (click)="chooseFontSize(font)"-->
<!--    >-->
<!--      <div class="circle" [ngClass]="{ selected: selectedFontSize === font }">-->
<!--        A-->
<!--      </div>-->
<!--      <div class="name">-->
<!--        {{ "wc.ecare.accountSettings.fontSize." + font | translate }}-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
