import { Component, Input, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-viber-content-sharing-modal",
  templateUrl: "./viber-content-sharing-modal.component.html",
})
export class ViberContentSharingModalComponent implements OnDestroy {
  @Input()
  dialogRef;

  @Input() url;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor() {}

  cancel() {
    this.dialogRef.dismiss();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
