<div class="ecare-tariff-shared-units-swiper">
  <swiper
    [slidesPerView]="4"
    [centeredSlides]="false"
    [slidesPerGroupSkip]="1"
    [grabCursor]="false"
    [keyboard]="{ enabled: true }"
    [breakpoints]="{
      '769': {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    }"
    [scrollbar]="false"
    [navigation]="true"
    [pagination]="{ clickable: true }"
    [autoHeight]="true"
  >
    <ng-container *ngFor="let sharedUnit of sharedUnits">
      <ng-template swiperSlide>
        <app-tariff-shared-units-tile [unitsData]="sharedUnit">
        </app-tariff-shared-units-tile>
      </ng-template>
    </ng-container>
  </swiper>
</div>
