<app-ecare-mobile-row-general *ngIf="row">
  <div class="mobile-table-row-wrapper search" (click)="selectedRow.emit(row)">
    <ng-container [ngSwitch]="pickerInputType">
      <ng-container *ngSwitchCase="'customer'">
        <div class="mobile-table-row-flex space-between">
          <div class="mobile-table-row-flex">
            <div class="title-text font-highlight">
              {{ row.firstName ? row.firstName : row.subject?.firstName }}
              {{ row.middleName ? row.middleName : row.subject?.middleName }}
              {{ row.lastName ? row.lastName : row.subject?.lastName }}
            </div>
            <app-sticker variant="neutral">{{ row.segment }}</app-sticker>
          </div>
          <app-ecare-search-select-dropdown *ngIf="row.partyRefNo" [customer]="row" />
        </div>
        <div class="parameter-text">
          <span *ngIf="row.subjectType === 'Person' || row.subject?.subjectType === 'Person'; else notPerson">
            <i [inlineSVG]="'/assets/svg/customer-search-parameter-birth.svg'"></i>
            &nbsp;{{ row.birthDate ? row.birthDate : row.subject?.birthDate }}
          </span>
          <ng-template #notPerson>
            <span *ngIf="row.subjectType !== 'Person'">
              <i [inlineSVG]="'/assets/svg/customer-search-parameter-id.svg'"></i>
              &nbsp;{{ row.companyRegNo ? row.companyRegNo : row.subject?.registrationNumber }}
            </span>
          </ng-template>
        </div>
        <div class="last-line-text">
          {{ row.address | displayAddress : addressTypes.DASHBOARD_CA_DETAIL }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'user'">
        <div class="mobile-table-row-flex space-between">
          <div class="mobile-table-row-flex">
            <div class="title-text font-highlight">
              {{ row.login }} | {{ getParameterByName('firstName') }} {{ getParameterByName('lastName') }}
            </div>
            <app-sticker [variant]="row.state === 'ACTIVE' ? 'success' : 'danger'">{{ row.state }}</app-sticker>
          </div>
        </div>
        <div class="parameter-text">
          <span>
            <ng-container *ngIf="getParameterByName('birthdate')">
              <i  [inlineSVG]="'/assets/svg/customer-search-parameter-birth.svg'"></i>
              &nbsp;{{ getParameterByName('birthdate') }}
            </ng-container>

            {{ 'wc.ecare.users.' + (row.external ? 'external' : 'internal') | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</app-ecare-mobile-row-general>
