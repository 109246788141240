import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { CustomerService } from '@service/customer.service';
import { AbstractPageComponent, EnableDynamicLoading, StickyMessageService } from '@btl/btl-fe-wc-common';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import { CustomerAccountFormComponent } from '../../../page/customer-account-form/customer-account-form.component';
import { FormUtils } from '../../../../helpers/form-utils';
import { first, takeUntil } from 'rxjs/operators';
import { AddressDto, ContactDto, CustomerAccountDto, CustomerDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-customer-account-create',
  templateUrl: './ecare-customer-account-create.component.html',
})
@EnableDynamicLoading({ customName: EcareCustomerAccountCreateComponent.PAGE_ID })
export class EcareCustomerAccountCreateComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareCustomerAccountCreateComponent';

  pageId(): string {
    return EcareCustomerAccountCreateComponent.PAGE_ID;
  }

  createForm: FormGroup;

  @ViewChild('customerAccountForm', { static: true }) customerAccountFormComponent: CustomerAccountFormComponent;

  parentParty: CustomerDto | CustomerAccountDto;
  customerAccount: CustomerAccountDto;
  currentCustomer: CustomerDto;

  constructor(
    private customerLocalStorageService: CustomerLocalStorageService,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private stickyMessageService: StickyMessageService,
    protected router: Router
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      customerAccount: this.customerAccountFormComponent.getCustomerAccountGroupControl(),
    });

    this.currentCustomer = this.customerLocalStorageService.getCurrentCustomer();

    this.parentParty = this.currentCustomer;
    const customerAccountDto = this.customerLocalStorageService.getCurrentCustomerAccount();
    if (customerAccountDto) {
      this.parentParty = customerAccountDto;
    }

    this.route.url.pipe(takeUntil(this.onDestroy$)).subscribe(urlSegments => {
      if (urlSegments.find(urlSegment => urlSegment.path === 'edit')) {
        this.reloadForm(this.parentParty);
      } else if (urlSegments.find(urlSegment => urlSegment.path === 'new-ca')) {
        this.reloadForm(CustomerPartyUtil.getDefaultCustomerAccount());
      } else {
        if (!this.parentParty) {
          throw Error('No parent party found. New CA can not be created');
        }
      }
    });
  }

  reloadForm(customerAccount) {
    this.customerAccount = customerAccount;

    if (!this.customerAccount.address) {
      this.customerAccountFormComponent.addressFormComponent.inputMode = false;
      this.customerAccount.address = CustomerPartyUtil.getDefaultAddress(AddressDto.TypeDtoEnum.INVOICING);
    } else {
      if (
        this.currentCustomer.address &&
        CustomerPartyUtil.equalsWithExclude(this.currentCustomer.address, this.customerAccount.address, 'type', 'id')
      ) {
        this.customerAccountFormComponent.addressFormComponent.inputMode = false;
      } else {
        this.customerAccountFormComponent.addressFormComponent.inputMode = true;
      }
    }

    const customerContact: ContactDto = this.currentCustomer.contact;
    if (!this.customerAccount.invoicingContact) {
      this.customerAccountFormComponent.invoicingContactFormComponent.inputMode = false;
      this.customerAccountFormComponent.invoicingContactFormComponent.allowContact = false;
      this.customerAccount.invoicingContact = CustomerPartyUtil.getDefaultContact(ContactDto.TypeDtoEnum.INVOICING);
    } else {
      if (
        customerContact &&
        CustomerPartyUtil.equalsWithExclude(customerContact, this.customerAccount.invoicingContact, 'type', 'id')
      ) {
        this.customerAccountFormComponent.invoicingContactFormComponent.inputMode = false;
        this.customerAccountFormComponent.invoicingContactFormComponent.allowContact = true;
      } else {
        this.customerAccountFormComponent.invoicingContactFormComponent.inputMode = true;
        this.customerAccountFormComponent.invoicingContactFormComponent.allowContact = true;
      }
    }

    if (!this.customerAccount.billingContact) {
      this.customerAccountFormComponent.billingContactFormComponent.inputMode = false;
      this.customerAccountFormComponent.billingContactFormComponent.allowContact = false;
      this.customerAccount.billingContact = CustomerPartyUtil.getDefaultContact(ContactDto.TypeDtoEnum.BILLING);
    } else {
      if (
        customerContact &&
        CustomerPartyUtil.equalsWithExclude(customerContact, this.customerAccount.billingContact, 'type', 'id')
      ) {
        this.customerAccountFormComponent.billingContactFormComponent.inputMode = false;
        this.customerAccountFormComponent.billingContactFormComponent.allowContact = true;
      } else {
        this.customerAccountFormComponent.billingContactFormComponent.inputMode = true;
        this.customerAccountFormComponent.billingContactFormComponent.allowContact = true;
      }
    }

    this.customerAccountFormComponent.customerAccountForm.patchValue(
      CustomerPartyUtil.getFormCustomerAccount(this.customerAccount)
    );
    this.customerAccountFormComponent.customerAccountForm
      .get('allowBillingContact')
      .setValue(this.customerAccountFormComponent.billingContactFormComponent.allowContact);
    this.customerAccountFormComponent.customerAccountForm
      .get('allowInvoicingContact')
      .setValue(this.customerAccountFormComponent.invoicingContactFormComponent.allowContact);

    this.customerAccountFormComponent
      .generateForm(customerAccount)
      .pipe(first())
      .subscribe(() => {
        this.initParams();
        this.createForm.get('customerAccount').patchValue(this.customerAccount);
      });
  }

  initParams() {
    if (!this.customerAccount.parameters) {
      this.customerAccount.parameters = {};
    }
  }

  save() {
    FormUtils.validateAllFormFields(this.createForm);
    if (this.createForm.valid) {
      const customerAccount: CustomerAccountDto = this.createForm.getRawValue().customerAccount;

      if (!this.customerAccountFormComponent.addressFormComponent.inputMode) {
        CustomerPartyUtil.copyWithExclude(this.currentCustomer.address, customerAccount.address, 'type', 'id');
      }

      if (!this.customerAccountFormComponent.customerAccountForm.get('allowInvoicingContact').value) {
        customerAccount.invoicingContact = null;
      } else if (!this.customerAccountFormComponent.invoicingContactFormComponent.inputMode) {
        CustomerPartyUtil.copyWithExclude(this.currentCustomer.contact, customerAccount.invoicingContact, 'type', 'id');
      }

      if (!this.customerAccountFormComponent.customerAccountForm.get('allowBillingContact').value) {
        customerAccount.billingContact = null;
      } else if (!this.customerAccountFormComponent.billingContactFormComponent.inputMode) {
        CustomerPartyUtil.copyWithExclude(this.currentCustomer.contact, customerAccount.billingContact, 'type', 'id');
      }

      if (this.customerAccount?.recordVersion) {
        customerAccount.recordVersion = this.customerAccount.recordVersion;
        this.customerService
          .updateCustomerAccount(customerAccount.id, customerAccount)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.stickyMessageService.addStickySuccessMessage('wc.ecare.createCustomer.customerAccountUpdated');
            this.reloadForm(result);
          });
      } else {
        customerAccount.parentId = this.parentParty.id;

        const currentCustomer = this.customerLocalStorageService.getCurrentCustomer();
        if (!this.customerAccountFormComponent.customerAccountForm.get('allowBillingContact').value) {
          customerAccount.billingContact = null;
        } else if (!this.customerAccountFormComponent.billingContactFormComponent.inputMode) {
          CustomerPartyUtil.copyWithExclude(currentCustomer.contact, customerAccount.billingContact, 'type', 'id');
        }
        if (!this.customerAccountFormComponent.customerAccountForm.get('allowInvoicingContact').value) {
          customerAccount.invoicingContact = null;
        } else if (!this.customerAccountFormComponent.invoicingContactFormComponent.inputMode) {
          CustomerPartyUtil.copyWithExclude(currentCustomer.contact, customerAccount.invoicingContact, 'type', 'id');
        }

        this.customerService
          .createCustomerAccount(customerAccount)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.stickyMessageService.addStickySuccessMessage('wc.ecare.createCustomerAccount.created');
            this.customerLocalStorageService.refreshCustomerContext(result.id).subscribe(() => {
              this.router.navigate(['/ecare/dashboard'], {
                queryParams: {
                  cuRefNo: this.customerLocalStorageService.getCurrentCustomer().id,
                  caRefNo: this.customerLocalStorageService.getCurrentCustomerAccount().id,
                },
              });
            });
          });
      }
    }
  }
}
