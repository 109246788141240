<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.generateDocument.title' | translate }} - {{ 'wc.ecare.generateDocument.' + entityType | translate }}
  </h4>
  <div class="modal-close" (click)="dialogReference.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body">
  <div class="ecare_modal-pdf-preview-wrapper">
    <div class="-pdf-preview" (click)="openInNewTab()" *ngIf="pdfUrl">
      <div class="-pdf-thumbnail">
        <pdf-viewer
          class="view"
          [src]="pdfUrl"
          [show-all]="false"
          [zoom-scale]="'page-width'"
          [page]="1"
          [original-size]="false"
          [render-text]="false"
          (after-load-complete)="onDocumentGenerated()">
        </pdf-viewer>

        <div *ngIf="!documentGenerated" class="-document-preview-creation">
          <div class="-content-container">
            <i [inlineSVG]="'/assets/svg/metro-file-pdf.svg'"></i>
            <div class="-info-text">{{ 'wc.ecare.generateDocument.createPreview' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="-pdf-preview-footer">
      <div class="file-info">
        <div class="-filename">
          {{ documentName }}
        </div>
        <div *ngIf="documentCreatedDate" class="-created-date">
          {{ documentCreatedDate | date : 'dd.MM.yyyy HH:mm' }}
        </div>
      </div>

      <app-button
        *ngIf="documentGenerated"
        variant="secondary"
        iconPath="/assets/svg/feather-download.svg"
        label="{{ 'wc.ecare.generateDocument.download' | translate }}"
        (click)="downloadDocument()">
      </app-button>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div>
    <app-button variant="link" label="{{ 'wc.common.cancel.button' | translate }}" (click)="handleNo()"> </app-button>
    <app-button
      variant="primary"
      iconPath="/assets/svg/link.svg"
      label="{{ 'wc.ecare.generateDocument.attachFile' | translate }}"
      (click)="attachDocument()">
    </app-button>
  </div>
</div>
