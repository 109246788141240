import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlockUIService } from 'ng-block-ui';
import { DmsFileDto } from '@btl/order-bff';
import { BlockTemplateComponent, ProductService } from '@btl/btl-fe-wc-common';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-ecare-document-row',
  templateUrl: './ecare-document-row.component.html',
})
export class EcareDocumentRowComponent {
  blockTemplate = BlockTemplateComponent;

  @Input() file: DmsFileDto;
  @Output() readonly deleteEvent = new EventEmitter<string>();
  @Output() readonly downloadFileEvent = new EventEmitter<DmsFileDto>();
  @Input() selectFormArray: FormArray;
  @Input() index;

  collapsed = false;

  constructor(private readonly productService: ProductService, private readonly blockService: BlockUIService) {}

  onRowClick(): void {
    if (!this.file.params) {
      return;
    } else if (this.collapsed === false) {
      this.collapsed = !this.collapsed;
      this.blockService.start(this.file.id);
    } else {
      this.collapsed = !this.collapsed;
    }
  }

  buttonInRowClick(event: any): void {
    event.stopPropagation();
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  delete() {
    this.deleteEvent.emit(this.file.id);
  }

  download() {
    this.downloadFileEvent.emit(this.file);
  }
}
