import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PartyHolder } from '../../../models/party-holder';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-shop-selection-row',
  templateUrl: './shop-selection-row.component.html',
})
export class ShopSelectionRowComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input()
  shop;

  @Input()
  productCode;

  @Input()
  enrichAvailability = false;

  @Input()
  checkAvailability = false;

  @Output()
  readonly shopSelected = new EventEmitter<PartyHolder>();

  shopDetail = false;

  shopWorkingHours;

  ngOnInit(): void {
    this.shopWorkingHours = this.getShopWorkingHours();
  }

  getShopWorkingHours() {
    //Todo remove
    this.shop._source.parameters['workingHours'] =
      '[{"Monday":"09:00-12:00 13:00-18:00"},{"Tuesday":"09:00-12:00 13:00-18:00"},{"Wednesday":"09:00-12:00 13:00-18:00"},{"Thurday":"08:00-12:00 13:00-18:00"},{"Friday":"09:00-12:00 13:00-15:00"},{"Saturday":"closed"},{"Sunday":"closed"}]';

    const days = [];
    const workingHours = JSON.parse(this.shop._source.parameters['workingHours']);
    workingHours.forEach(workingDay =>
      Object.keys(workingDay).forEach(key =>
        days.push({
          day: key,
          hours: workingDay[key],
        })
      )
    );
    return days;
  }
}
