import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import SwiperCore, { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper';
import { CustomerAccountDto, CustomerDto, PartyDto } from '@btl/order-bff';

SwiperCore.use([Keyboard, Scrollbar, Navigation, Pagination]);

@Component({
  selector: 'app-swiper-example',
  templateUrl: './ecare-swiper.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class EcareSwiperComponent implements OnInit {
  @Input()
  tariffs: Array<PartyDto>;

  @Input()
  customerAccount: CustomerAccountDto;

  @Input()
  customer: CustomerDto;

  @Output()
  readonly selectedTariff: EventEmitter<any> = new EventEmitter();

  selectedTariffId: string;

  ngOnInit(): void {
    this.selectedTariffId = this.tariffs[0].id;
  }

  selectTariff(tariff) {
    this.selectedTariffId = tariff.id;
    this.selectedTariff.emit(tariff);
  }
}
