<div class="order-table-mobile" [ngClass]="{ 'widget-display': isWidget }">
  <app-ecare-order-row-mobile
    *ngFor="let order of orders"
    [displayMode]="displayMode"
    [order]="order"
    [opportunity]="opportunity"
    [selectMode]="selectMode"
    [thumbnailMap]="thumbnailMap"
    (selectedOrder)="itemSelected($event)"
    (selectCustomer)="selectCustomerInTable($event)" />
</div>
