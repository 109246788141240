<div class="section-widget">
  <app-section-header
    title="{{ sectionTitle }}"
    [isCollapsed]="ref.activeIds.length > 0"
    (click)="accordionRef.toggle('toggleSection')">
    <ng-content select="[sectionHeaderContent]"></ng-content>
  </app-section-header>
  <ngb-accordion #accordionRef="ngbAccordion" #ref activeIds="toggleSection" [destroyOnHide]="false">
    <ngb-panel id="toggleSection">
      <ng-template ngbPanelContent>
        <div class="section-content">
          <ng-content></ng-content>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
