<div>
  <div>
    <app-page-header title="{{ customerAccount.displayName }} - {{ customerAccount.id }}">
      <div class="mr-auto d-flex align-items-center">
        <div
          class="ecare-settings-icon-wrapper ecare-dashboard-icon"
          tooltipClass="ecare-settings-tooltip"
          placement="bottom"
          ngbTooltip="{{ 'wc.ecare.customerDetails.edit' | translate }}"
          (click)="gotToCa(false)">
          <img class="ecare-settings-pencil" src="../../../../assets/img/ecare/edit_icon.svg" width="22" height="22" />
        </div>
        <app-acl-internal-evaluate-component class="ca-state">{{
          customerAccount.state
        }}</app-acl-internal-evaluate-component>
      </div>
      <div class="ml-auto">
        <app-button
          variant="primary"
          iconPath="assets/img/ecare/feather-plus.svg"
          label="{{ 'wc.ecare.dashboard.createCa.link' | translate }}"
          (click)="gotToCa(true)">
        </app-button>
      </div>
    </app-page-header>
  </div>
</div>
<app-acl-internal-evaluate-component class="ecare-campaing-slot-wrapper">
  <div class="ecare-campaing-slot col-12 mb-3">
    <span>Retention discount:</span>
    <span>30%</span>
    <span
      >on Internet service for<br />
      each month!</span
    >
    <button [routerLink]="['/campaign-ret001']">Show more details</button>
  </div>
</app-acl-internal-evaluate-component>
<div class="ecare_columns_row">
  <div class="ecare_column">
    <div class="balance_card">
      <div class="balance_card__header">
        <p class="balance_card_actual_label">{{ 'wc.ecare.dashboard.actualBalance.label' | translate }}</p>
        <p class="balance_card_actual">
          <one-time-price *ngIf="openBalanceResult" [price]="openBalanceResult.openAmount"> </one-time-price>
        </p>
        <p
          *ngIf="customerAccount.parameters['paymentType'] === PaymentTypeEnum.POSTPAID"
          class="balance_card_overdue_label">
          {{ 'wc.ecare.dashboard.overdueAmount.label' | translate }}
        </p>
        <p class="balance_card_overdue">
          <one-time-price
            *ngIf="customerAccount.parameters['paymentType'] === PaymentTypeEnum.POSTPAID && openBalanceResult"
            [price]="openBalanceResult.dueAmount">
          </one-time-price>
        </p>
      </div>
    </div>
    <div class="ecare_btn_row">
      <app-acl-evaluate privilege="PAY_TELCO_BILL" class="financial_doc_btn_wrapper">
        <button class="btn financial_doc_btn" (click)="goToFinancialDocuments()">
          <img src="../../../../assets/img/ecare/financial-documents_icon.svg" />FINANCIAL DOCUMENTS
        </button>
      </app-acl-evaluate>
      <button class="btn show_tariff_btn" (click)="goToFinancialDocuments()">
        <img src="../../../../assets/img/ecare/tariff-details_icon.svg" />SHOW BALANCE DETAILS
      </button>
    </div>
  </div>
  <div class="ecare_column">
    <div class="ecare_short_details">
      <div class="ecare_short_details_row">
        <p class="ecare_short_detail_label">Payment method</p>
        <p class="ecare_short_detail -payment-method">
          <i [inlineSVG]="'../../../../assets/img/ecare/method-payment-card_icon.svg'"></i>
          {{ customerAccount.parameters['paymentMethod'] }}
        </p>
      </div>
      <div class="ecare_short_details_row">
        <p class="ecare_short_detail_label">{{ 'wc.ecare.dashboard.bankNumber.label' | translate }}</p>
        <p class="ecare_short_detail">
          {{
            customerAccount.parameters['bankAccountPrefix']
              ? customerAccount.parameters['bankAccountPrefix'] + '-'
              : ''
          }}{{ customerAccount.parameters['bankAccount'] }}
        </p>
      </div>
      <div class="ecare_short_details_row">
        <p class="ecare_short_detail_label">{{ 'wc.ecare.dashboard.billingCycle.label' | translate }}</p>
        <span class="ecare_short_billcycle_wrapper">
          <p class="ecare_short_billcycle">Billing monthly</p>
        </span>
      </div>
      <div class="ecare_short_details_row">
        <p class="ecare_short_detail_label">{{ 'wc.ecare.dashboard.invoiceAddress.label' | translate }}</p>
        <p *ngIf="invoiceAddress" class="ecare_short_detail">
          {{ invoiceAddress | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
        </p>
        <p *ngIf="!invoiceAddress" class="ecare_short_detail">
          {{ 'wc.ecare.dashboard.invoiceAddress.sameAsCustomer.label' | translate }}
        </p>
      </div>
    </div>
    <button class="btn ecare_show_all_btn" [class.collapsed]="isCollapsed" (click)="isCollapsed = !isCollapsed">
      <span *ngIf="isCollapsed">Show all details <img src="../../../../assets/img/ecare/arrow-down.svg" /> </span>
      <span *ngIf="!isCollapsed"
        >Hide details <img src="../../../../assets/img/ecare/arrow-down.svg" class="ecare_show_details_arrow_up"
      /></span>
    </button>
  </div>

  <!--<div class="ecare_column banner">
    <div class="ecare_short_details">
      <div>
        <span class="ecare_short_billcycle_wrapper">
          <p class="ecare_short_billcycle">
            CUSTOMER OFFER
          </p>
        </span>
      </div>
      <div>
        Activate
      </div>
      <div>
        NextTV Bronze
      </div>
      <div>
        and get major networks and<br/>
        some news, sports and<br/>
        kids programming
      </div>
    </div>
    <button class="btn ecare_show_all_btn">
      <span [routerLink]="['/campaign-acq001']">Learn more &nbsp; &nbsp; <img src="../../../../assets/img/ecare/feather-arrow-right.svg"/> </span>
    </button>
  </div>-->
  <div class="ecare_column banner">
    <div class="ecare_short_details">
      <div>
        <span class="ecare_short_billcycle_wrapper">
          <p class="ecare_short_billcycle">CUSTOMER OFFER</p>
        </span>
      </div>
      <div>SUMMER</div>
      <div>SALE UP TO</div>
      <div>40% <span>OFF!</span></div>
    </div>
    <button class="btn ecare_show_all_btn">
      <span [routerLink]="['/campaign-acq001']"
        >Learn more &nbsp; &nbsp; <img src="../../../../assets/img/ecare/feather-arrow-right.svg" />
      </span>
    </button>
  </div>
</div>
<div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
  <p class="dashboard-account-details-title">Payment</p>
  <hr class="ecare-separator" />
  <section class="ecare_details_payment">
    <div class="payment_card_label">
      <p class="ecare_details_label">Method</p>
      <p class="ecare_details_description">
        <img src="../../../../assets/img/ecare/method-payment-card_icon.svg" width="32px" height="14px" />
        {{ customerAccount.parameters['paymentMethod'] }}
      </p>
    </div>
    <div class="payment_card_label">
      <p class="ecare_details_label">{{ 'wc.ecare.dashboard.bankNumber.label' | translate }}</p>
      <p class="ecare_details_description">
        {{ customerAccount.parameters['bankAccountPrefix'] ? customerAccount.parameters['bankAccountPrefix'] + '-' : ''
        }}{{ customerAccount.parameters['bankAccount'] }}
      </p>
    </div>
    <div class="payment_card_label">
      <p class="ecare_details_label">Bank code</p>
      <p class="ecare_details_description">{{ customerAccount.parameters['bankCode'] }}</p>
    </div>
    <div class="payment_card_label">
      <p class="ecare_details_label">{{ 'wc.ecare.dashboard.directDebitLimit.label' | translate }}</p>
      <p class="ecare_details_description">
        {{ customerAccount.parameters['directDebitLimit'] ? customerAccount.parameters['directDebitLimit'] : '-' }}
      </p>
    </div>
    <div class="payment_card_label">
      <p class="ecare_details_label">{{ 'wc.ecare.dashboard.bank.label' | translate }}</p>
      <p *ngIf="codebookService.getCodebookTexts('BANK_CODE') | async as codebook" class="ecare_details_description">
        {{ codebook[customerAccount.parameters['bankCode']] }}
      </p>
    </div>
    <div *ngIf="customerAccount.parameters['billCycle']" class="payment_card_label">
      <p class="ecare_details_label">{{ 'wc.ecare.dashboard.billingCycle.label' | translate }}</p>
      <p class="ecare_details_description">{{ customerAccount.parameters['billCycle'] }}</p>
    </div>
    <ng-container *ngIf="customerAccount.billingContact && customerAccount.billingContact.preferredChannel !== 'NONE'">
      <div class="payment_card_label">
        <p class="ecare_details_label">{{ 'wc.ecare.dashboard.billingContactPreferredChannel.label' | translate }}</p>
        <p *ngIf="customerAccount.billingContact.preferredChannel === 'EMAIL'" class="ecare_details_description">
          <img src="../../../../assets/img/ecare/mail_icon.svg" width="23px" height="18px" />{{
            customerAccount.billingContact.email
          }}
        </p>
        <p *ngIf="customerAccount.billingContact.preferredChannel === 'SMS'" class="ecare_details_description">
          <img src="../../../../assets/img/ecare/sms_icon.svg" width="23px" height="18px" />{{
            customerAccount.billingContact.phone1 | phoneNumber
          }}
        </p>
      </div>
    </ng-container>
    <div class="payment_card_label">
      <p class="ecare_details_label">{{ 'wc.ecare.dashboard.invoiceDeliveryMethod.label' | translate }}</p>
      <p *ngIf="codebookService.getCodebookTexts('BILL_MEDIUM') | async as codebook" class="ecare_details_description">
        {{ codebook[customerAccount.parameters['billMedium']] }}
      </p>
    </div>
  </section>
  <div class="ecare_detail_card_grid">
    <div class="ecare_detail_bigcard">
      <p class="dashboard-account-details-title">{{ 'wc.ecare.dashboard.invoiceAddress.label' | translate }}</p>
      <hr class="ecare-separator" />
      <p *ngIf="!invoiceAddress" class="ecare_details_description">
        {{ customer.address | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
      </p>
      <p *ngIf="invoiceAddress" class="ecare_details_description">
        {{ invoiceAddress | displayAddress: addressTypes.DASHBOARD_CA_DETAIL }}
      </p>
      <p class="ecare-billing_contact">{{ 'wc.ecare.dashboard.billingContact.label' | translate }}</p>
      <hr class="ecare-separator" />
      <p *ngIf="!invoiceAddress" class="ecare_detail_same_address">
        {{ 'wc.ecare.dashboard.invoiceAddress.sameAsCustomer.label' | translate }}
      </p>
    </div>
    <div class="ecare_detail_biggercard">
      <p class="dashboard-account-details-title">{{ 'wc.ecare.dashboard.invoiceContact.label' | translate }}</p>
      <hr class="ecare-separator" />
      <div *ngIf="customerAccount.invoicingContact" class="ecare_detail_smallcard_grid">
        <div>
          <p class="ecare_details_label">{{ 'wc.ecare.dashboard.primaryContact.firstName.label' | translate }}</p>
          <p class="ecare_details_description">{{ customerAccount.invoicingContact.firstName }}</p>
        </div>
        <div>
          <p class="ecare_details_label">{{ 'wc.ecare.dashboard.primaryContact.lastName.label' | translate }}</p>
          <p class="ecare_details_description">{{ customerAccount.invoicingContact.lastName }}</p>
        </div>
        <div>
          <p class="ecare_details_label">{{ 'wc.ecare.dashboard.primaryContact.email.label' | translate }}</p>
          <p class="ecare_details_description">{{ customerAccount.invoicingContact.email }}</p>
        </div>
        <div>
          <p class="ecare_details_label">{{ 'wc.ecare.dashboard.primaryContact.phone.label' | translate }}</p>
          <p class="ecare_details_description">{{ customerAccount.invoicingContact.phone1 | phoneNumber }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="tariffs.length">
  <div class="ecare-header_headline">My services</div>
  <app-swiper-example [tariffs]="tariffs" (selectedTariff)="selectedTariff($event)"> </app-swiper-example>
  <div class="ecare_tariff_details_frame">
    <app-subscription-detail
      [tariffSpace]="tariffSpaceToShow"
      [customerAccount]="customerAccount"
      [customer]="customer"></app-subscription-detail>
  </div>
</ng-container>
