<div class="content-share_container">
  <div ngbDropdown class="d-inline-block" (openChange)="toggleSharingOptions($event)">
    <app-button
      variant="whiteWithBorder"
      size="small"
      iconPathAfter="/assets/svg/share-icon.svg"
      id="shareDropdown"
      ngbDropdownToggle
      label="{{ 'wc.common.share' | translate }}"></app-button>
    <div ngbDropdownMenu aria-labelledby="shareDropdown">
      <div class="dropdown-menu-container">
        <a
          *ngIf="twitterButtonConfig"
          href="https://twitter.com/intent/tweet"
          class="dropdown-item dropdown-menu__item"
          ngbDropdownItem
          [attr.data-size]="twitterButtonConfig.buttonSize"
          [attr.data-text]="twitterButtonConfig.text"
          [attr.data-url]="twitterButtonConfig.url"
          [attr.data-hashtags]="twitterButtonConfig.hashtags?.join(',')"
          [attr.data-via]="twitterButtonConfig.via"
          [attr.data-related]="twitterButtonConfig.related?.join(',')">
          <div class="social-button social-button-twitter">
            <i class="social-button-icon" [inlineSVG]="'/assets/svg/twitter_icon.svg'"></i>
            {{ 'wc.shopping.gui_wishlist.shareWishList.shareTwitter' | translate }}
          </div>
        </a>
        <a
          class="dropdown-item dropdown-menu__item"
          ngbDropdownItem
          href="https://api.whatsapp.com/send?text={{ url }}">
          <div class="social-button social-button-whatsapp">
            <i class="social-button-icon" [inlineSVG]="'/assets/svg/whatsapp_icon.svg'"></i>
            {{ 'wc.shopping.gui_wishlist.shareWishList.shareWhatsapp' | translate }}
          </div>
        </a>
        <!--https://developers.viber.com/docs/tools/share-button/-->
        <a class="dropdown-item dropdown-menu__item" ngbDropdownItem (click)="openViberContentSharingModal()">
          <div class="social-button social-button-viber">
            <i class="social-button-icon" [inlineSVG]="'/assets/svg/viber_icon.svg'"></i>
            {{ 'wc.shopping.gui_wishlist.shareWishList.shareViber' | translate }}
          </div>
        </a>
        <div
          *ngIf="facebookButtonConfig"
          class="fb-share-button"
          ngbDropdownItem
          [attr.data-href]="facebookButtonConfig.href"
          [attr.data-layout]="facebookButtonConfig.layout"
          [attr.data-size]="facebookButtonConfig.size"></div>
        <div
          *ngIf="allowEmailSharing"
          ngbDropdownItem
          class="dropdown-item dropdown-menu__item"
          (click)="openEmailContentSharingModal()">
          <div class="social-button social-button-mail">
            <i class="social-button-icon" [inlineSVG]="'/assets/svg/mail_icon.svg'"></i>
            {{ 'wc.shopping.gui_wishlist.shareWishList.shareViaEmail' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
