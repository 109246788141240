import { Component, Input, OnInit } from '@angular/core';
import { PartyElse } from '../../../../models/party-holder';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';

@Component({
  selector: 'app-ecare-search-select-dropdown',
  templateUrl: './ecare-search-select-dropdown.component.html',
})
export class EcareSearchSelectDropdownComponent {
  @Input()
  customer: PartyElse;

  @Input()
  index: number;

  inNewTab: boolean;

  constructor(private customerLocalStorageService: CustomerLocalStorageService) {}

  selectCustomer(customerId: string, targetUrl?: string) {
    this.customerLocalStorageService.selectSearchedCustomer(customerId, targetUrl);
  }

  openCustomerInTab(customerId: string, targetUrl?: string) {
    this.inNewTab = true;
    this.customerLocalStorageService.selectSearchedCustomer(customerId, targetUrl, this.inNewTab);
  }

  goToFinancialDocuments(cuRefNo: string) {
    this.selectCustomer(cuRefNo, '/ecare/financial-documents');
  }
}
