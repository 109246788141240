<div class="tile_inner">
  <div class="tile_headline" (click)="toggle()">
    <div [class]="isCollapsed ? 'fa fa-angle-double-right' : 'fa fa-angle-double-down'"></div>
    <span *ngIf="customerAccount.displayName; else defaultCustomName">&nbsp;{{ customerAccount.displayName }}</span>
    <ng-template #defaultCustomName>{{ 'wc.ecare.dashboard.customerAccount.header' | translate }}</ng-template>
    &nbsp;<i
      *ngIf="customerAccount.parameters['paymentResponsible']"
      id="001_customer_account_tile"
      class="fa fa-credit-card"></i>
    <span *ngIf="customerAccount.id">&nbsp;{{ customerAccount.id }}</span>
  </div>
  <div *ngIf="!isCollapsed" class="tile_content">
    <app-customer-account-detail
      [customerAccount]="customerAccount"
      [customer]="customer"></app-customer-account-detail>
  </div>

  <ng-container *ngFor="let tariffSpace of customerAccount.childParties" class="tariffs" id="tariffs">
    <app-subscription-tile
      *ngFor="let asset of tariffSpace['assets']"
      class="tile -tile12"
      [asset]="asset"
      [customerAccount]="customerAccount"
      [customer]="customer"
      [tariffSpace]="tariffSpace">
    </app-subscription-tile>
  </ng-container>
</div>
