<div class="product__detail">
  <div app-breadcrumb></div>

  <div *ngIf="product" class="product__detail-wrapper">
    <!--    <app- #gallery *ngIf="product.pictures && !isTariff()" [pictures]="product.pictures"
                         (selectedPictureChanged)="changeDetailPicture($event)"></app->-->

    <div
      *ngIf="product"
      class="{{ isTariff() ? 'product__detail-content-tariff' : 'product__detail-content' }} {{
        !product.pictures ? '-noGallery' : ''
      }}">
      <div class="inner">
        <div class="product__detail-product-image">
          <img *ngIf="productPictureHint" alt="{{ product.name }}" src="{{ productPictureHint.href }}" />
          <img
            *ngIf="!productPictureHint && isTariff()"
            src="/assets/img/products/tariff_default_product_image.png"
            alt="{{ product.name }}" />
        </div>
        <div class="product__detail-product-info">
          <div class="product__detail-product-info-top">
            <h1 id="001_service_detail" class="prouct__detail-name">{{ product.name }}</h1>
            <p *ngIf="canDisplayBrandName()" class="product__detail-text">
              {{ 'wc.shopping.brandName.label' | translate }}: {{ product.parametersStatic['brandName'] }}
            </p>
            <p class="product__detail-text">
              {{ product.shortDescription
              }}<span *blockUI="blockUIElement.name; template: blockTemplate"
                ><app-product-commitment-info *ngIf="canDisplayProductCommitmentInfo()" [product]="commitmentProduct"
                  >,
                </app-product-commitment-info>
              </span>
            </p>

            <app-iccid-attribute-configuration
              *ngIf="isTariff() && cartOrderItemDto"
              #iccidAttributeConfigurationComponent
              [orderItem]="cartOrderItemDto"
              [precalculatedShoppingCart]="preCalculatedShoppingCart"
              [product]="product">
            </app-iccid-attribute-configuration>

            <div *ngIf="!isTariff()">
              <product-rating rating="{{ product.ratingReview }}"></product-rating>

              <fieldset *ngIf="product.name && product.name.search('Huawei') > -1" class="color-select">
                <legend>Color select</legend>
                <input
                  checked
                  class="form-check-input"
                  id="colorselect"
                  name="colorselect"
                  type="radio"
                  value="option2" />
                <label class="form-check-label" for="colorselect"> gray </label>
                <input class="form-check-input" id="colorselect2" name="colorselect" type="radio" value="option2" />
                <label class="form-check-label" for="colorselect2"> black </label>
              </fieldset>
            </div>
          </div>

          <div class="product__detail-product-info-bottom">
            <!-- todo -->
            <div *ngIf="product && !isTariff() && !cartOrderItemDto && !parentTariff" class="plan-select align-bottom">
              <label>{{
                (cartOrderItemDto && parentTariff
                  ? 'wc.shopping.selectedRatePlan.label'
                  : 'wc.shopping.selectRatePlan.label'
                ) | translate
              }}</label>

              <app-product-listing-old
                *ngIf="!cartOrderItemDto && !parentTariff"
                #tariffProductListing
                [enableFiltering]="false"
                [enableGrouping]="false"
                [enablePaging]="false"
                [parentProductCode]="''"
                [filter]="tariffFilter"
                [selectedValue]="selectedTariffId"
                [viewType]="productListingViewTypes.SELECT_BOX"
                (selectionChanged)="tariffSelectionChanged($event)">
              </app-product-listing-old>
            </div>

            <div *ngIf="product && !isTariff() && !cartOrderItemDto && !parentTariff" class="plan-select align-bottom">
              <label *ngIf="!cartOrderItemDto && !parentTariff && testHwLeasing()">
                {{ 'wc.shopping.hwLeasing.label' | translate }}
              </label>

              <app-product-listing-old
                *ngIf="!cartOrderItemDto && !parentTariff && testHwLeasing() && selectedTariff"
                [enableFiltering]="false"
                [enableGrouping]="false"
                [enablePaging]="false"
                [parentProductId]="selectedTariff.productId"
                [parentInstanceIds]="selectedTariffIds"
                [filter]="hwLeasingFilter"
                [selectedValue]=""
                [showEmptyOption]="true"
                [viewType]="productListingViewTypes.SELECT_BOX"
                (selectionChanged)="hwLeasingChanged($event)">
              </app-product-listing-old>

              <label *ngIf="cartOrderItemDto && parentTariff">{{ parentTariff.name }}</label>
            </div>

            <div class="product__detail-prices">
              <ng-container *ngIf="testHwLeasing(); else non_hw_leasing">
                <div *ngIf="selectedHwLeasingId; else leasing_not_selected_yet" class="product__detail-price">
                  <div class="product__detail-price-tag">
                    {{ 'wc.shopping.hwLeasingPrice.label' | translate }}
                  </div>
                  <div *ngIf="selectedHwLeasingDetail" class="product__detail-price-val pull-right">
                    <app-recurrent-price [price]="selectedHwLeasingDetail.prices['RC'].price"> </app-recurrent-price>
                  </div>
                </div>
                <ng-template #leasing_not_selected_yet>
                  <div class="product__detail-price">
                    <div class="product__detail-price-tag">
                      {{ 'wc.shopping.selectHwLeasing.text' | translate }}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #non_hw_leasing>
                <div *ngIf="product.prices['OC']" class="product__detail-price">
                  <div class="product__detail-price-tag">
                    {{ 'wc.shopping.priceWithoutTariff.label' | translate }}
                  </div>
                  <div class="product__detail-price-val pull-right">
                    <one-time-price
                      [price]="
                        tariffHwProduct ? tariffHwProduct.prices['OC'].price : product.prices['OC'].price
                      "></one-time-price>
                  </div>
                </div>
                <div *ngIf="product.prices['RC']" class="product__detail-price product__detail-price--total">
                  <div class="product__detail-price-tag">
                    {{
                      (isTariff() ? 'wc.shopping.monthlyFee.label' : 'wc.shopping.priceWithTariff.label') | translate
                    }}
                  </div>
                  <div class="product__detail-price-val">
                    <one-time-price [price]="product.prices['RC'].price"></one-time-price>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="align-bottom">
              <a id="002_service_detail" class="link arrow-back catalogue" (click)="goBack()">
                {{ 'wc.common.back.button' | translate }}
              </a>
              <app-acl-evaluate privilege="ACTIVATION_B2C_SUBS">
                <a
                  *ngIf="!cartOrderItemDto"
                  id="003_service_detail"
                  class="btn btn-default pull-right"
                  [class.disabled]="testHwLeasing() && !selectedHwLeasingId"
                  (click)="handleProductAddingToCart(tariffHwProduct ? tariffHwProduct : product)">
                  {{ 'wc.common.buyNow.button' | translate }}
                </a>
                <a
                  *ngIf="isTariff() && cartOrderItemDto"
                  id="004_service_detail"
                  class="btn btn-default pull-right"
                  (click)="configure()">
                  {{ 'wc.common.configure.button' | translate }}
                </a>
              </app-acl-evaluate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="product && isTariff() && cartOrderItemDto && coreProductInShoppingCart">
    <div class="product__detail-subheader">
      {{ 'wc.shopping.tariffAddons.label' | translate }}
    </div>

    <app-product-listing-old
      [enableFiltering]="false"
      [enableGrouping]="false"
      [enablePaging]="false"
      [parentProductId]="product.id"
      [filter]="addonsFilter"
      [parentInstanceIds]="coreProductInShoppingCart.orderItems[0].id"
      [parentProductCode]="coreProductInShoppingCart.productDetail.productCode"
      [viewType]="productListingViewTypes.LIST">
    </app-product-listing-old>
  </div>

  <div *ngIf="product && isTariff()">
    <div class="product__detail-subheader">
      {{ 'wc.shopping.promotedSmartPhones.label' | translate }}
    </div>

    <app-product-listing-old
      [enableFiltering]="false"
      [enableGrouping]="false"
      [enablePaging]="false"
      [filter]="filter"
      [parentProductId]="product.id"
      [parentInstanceIds]="cartOrderItemDto ? cartOrderItemDto.id : null"
      [parentProductCode]="product.productCode"
      [viewType]="productListingViewTypes.GRID">
    </app-product-listing-old>
  </div>

  <!-- product specification -->
  <div *ngIf="product && !isTariff() && product.parametersStatic" class="product__detail-properties">
    <div *ngIf="product.parametersStatic" class="property__items">
      <div class="property__items-inner">
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.displayResolution' | translate">
            <i id="005_service_detail" class="fa fa-arrows-alt" aria-hidden="true"></i>
            <span>{{ product.parametersStatic['displayResolution'] }}</span>
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.sharedInternalMemory' | translate">
            <i id="006_service_detail" class="fa fa-hdd-o" aria-hidden="true"></i>
            <span>{{ product.parametersStatic['sharedInternalMemory'] }}</span>
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.operatingSystem' | translate">
            <i id="007_service_detail" class="fa fa-mobile" aria-hidden="true"></i>
            <span>{{ product.parametersStatic['operatingSystem'] }}</span>
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.microSDSupport' | translate">
            <i id="008_service_detail" class="fa fa-floppy-o" aria-hidden="true"></i>
            <span>{{ product.parametersStatic['microSDSupport'] }}</span>
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.dimensions' | translate">
            <i id="009_service_detail" class="fa fa-arrows-h" aria-hidden="true"></i>
            {{ product.parametersStatic['dimensions'] }}
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.cameraResolution' | translate">
            <i id="010_service_detail" class="fa fa-camera" aria-hidden="true"></i>
            {{ product.parametersStatic['cameraResolution'] }}
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.battery' | translate">
            <i id="011_service_detail" class="fa fa-battery" aria-hidden="true"></i>
            {{ product.parametersStatic['battery'] }}
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.processor' | translate">
            <i id="012_service_detail" class="fa fa-microchip" aria-hidden="true"></i>
            {{ product.parametersStatic['processor'] }}
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.ram' | translate">
            <i id="013_service_detail" class="fa fa-microchip" aria-hidden="true"></i>
            {{ product.parametersStatic['ram'] }}
          </div>
        </div>
        <div class="property__item">
          <div class="property__item-inner" [title]="'wc.shopping.specification.graphicCard' | translate">
            <i id="014_service_detail" class="fa fa-microchip" aria-hidden="true"></i>
            {{ product.parametersStatic['graphicCard'] }}
          </div>
        </div>
      </div>
    </div>
    <div class="property__image">
      <div class="property__image-inner">
        <div class="property__image-front">
          <svg id="phone-front" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 169">
            <style>
              .a {
                stroke: #000;
              }
            </style>
            <path
              d="M80.5 18L17.5 18C12.3 18 8 22.3 8 27.6L8 150.4C8 155.7 12.3 160 17.5 160L80.5 160C85.7 160 90 155.7 90 150.4L90 27.6C90 22.3 85.7 18 80.5 18ZM38.9 24.9L59.1 24.9C59.7 24.9 60.2 25.4 60.2 26.1 60.2 26.7 59.7 27.2 59.1 27.2L38.9 27.2C38.3 27.2 37.8 26.7 37.8 26.1 37.8 25.4 38.3 24.9 38.9 24.9ZM49 155.2C46.4 155.2 44.2 153.1 44.2 150.4 44.2 147.8 46.4 145.6 49 145.6 51.6 145.6 53.8 147.8 53.8 150.4 53.8 153.1 51.6 155.2 49 155.2ZM83.4 142.3L14.6 142.3 14.6 33.2 83.4 33.2 83.4 142.3Z" />
            <path d="M94.5 18.5L94.5 159.5" class="a" />
            <path d="M8.5 11.5L89.5 11.5" class="a" />
            <path d="M8.5 8.5L8.5 14.6" class="a" />
            <path d="M90.5 8.5L90.5 14.6" class="a" />
            <path d="M91.5 18L98.1 18" class="a" />
            <path d="M91.5 160L98.1 160" class="a" />
          </svg>
        </div>
        <div class="property__image-front">
          <svg id="phone-side" version="1.1" viewBox="0 0 113 169" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(49 7)">
                <rect x="4" y="4" width="8" height="156" rx="4" fill="#000" fill-rule="nonzero" />
                <rect x="1" y="10" width="8" height="17" rx="4" fill="#000" fill-rule="nonzero" />
                <path d="m1.2692 2h13.462" stroke="#000" stroke-linecap="square" />
                <path d="m14.333 0v3.7253" stroke="#000" stroke-linecap="square" />
                <path d="m0.33333 0v3.7253" stroke="#000" stroke-linecap="square" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="product && !isTariff()" class="product__detail-main-description">
    {{ product.longDescription }}
  </div>
</div>

<!-- product image mdal window -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-close" (click)="c('Close click')">
    <i id="015_service_detail" class="fa fa-times"></i>
  </div>
  <div class="modal-body">
    <img alt="Dialog image" src="{{ targetImage }}" />
  </div>
</ng-template>
