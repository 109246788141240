export class PageModuleIconMap {
  //Todo Use icon DMS extId from codebookModule
  private static iconMap = new Map([
    ['HOMEPAGE', 'crm-homepage'],
    ['EXT_HOMEPAGE', 'crm-homepage'],
    ['TASKS', 'crm-myTasks'],
    ['REPORTS', 'crm-myReports'],
    ['CONTACT_HISTORY', 'crm-contactHistory'],
    ['ECARE_NOTIFICATIONS', 'crm-notifications'],
    ['OPPORTUNITIES', 'crm-opportunities'],
    ['ECARE_ACCOUNT_ENTITIES', 'crm-usersAccess'],
    ['SEARCH', 'crm-searchCustomer'],
    ['ACCOUNT', 'crm-accountSettings'],
    ['SETTINGS', 'crm-accountSettings'],
    ['DOCUMENTS', 'crm-documents'],
    ['ACOUNT_DETAILS', 'account-details'],
    ['ACCOUNT_SKILLS', 'account-skills'],
    ['ACCOUNT_ACL', 'account-acl'],
    ['ACCOUNT_SECURITY', 'account-security'],
    ['ACCOUNT_LINKED_ACCOUNTS', 'external-link'],
    ['ACCOUNT_APPEARANCE', 'account-appearance'],
    ['ACCOUNT_PAYMENTS', 'account-payments'],
    ['ACCOUNT_NOTIFICATIONS', 'account-notifications'],
    ['ACCOUNT_AGREEMENTS', 'account-agreements'],
    ['ACCOUNT_CONTACTS', 'account-contacts'],
  ]);

  public static getIcon(iconName) {
    return this.iconMap.get(iconName);
  }
}
