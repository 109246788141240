import { Component, Input } from '@angular/core';
import { ReviewDto } from '@btl/order-bff';

@Component({
  selector: 'app-review, [app-review]',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent {
  @Input()
  reviewDto: ReviewDto;
}
