<div *ngIf="showPopup" class="overlay"></div>
<div *ngIf="showPopup" class="documents-popup">
  <div>
    <a class="wish-list-close-popup" (click)="closePopup()"></a>
    <div style="clear: both"></div>
  </div>

  <app-ecare-documents [entityType]="entityType" [entityId]="entityId" [pageSize]="4"> </app-ecare-documents>

  <div class="buttons">
    <button class="btn wish-list-button wish-list-done-button" (click)="closePopup()">
      {{ 'wc.shopping.gui_wishlist.button.done' | translate }}
    </button>
  </div>
</div>
