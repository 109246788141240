import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountContactsService, BlockTemplateComponent, FormUtils } from '@btl/btl-fe-wc-common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { FormBuilder } from '@angular/forms';
import { ContactFormComponent } from '../../../../page/contact-form/contact-form.component';
import { AddressFormComponent } from '../../../../page/address-form/address-form.component';
import { AccountContactDto, ContactDto } from '@btl/order-bff';

@Component({
  selector: 'app-contact-edit-popup',
  templateUrl: './contact-edit-popup.component.html',
})
export class ContactEditPopupComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('contactForm', { static: true }) contactForm: ContactFormComponent;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input()
  dialogRef;

  @Input()
  handler = (contact: AccountContactDto) => {};

  @Input()
  accountId;

  @Input()
  contact: AccountContactDto;

  @Input()
  contactType;

  @Input() sourceName = 'account';

  @Input() preferredContactWithoutNone = false;

  form = this.formBuilder.group({
    id: [null],
    type: [null],
    address: this.formBuilder.group(AddressFormComponent.getBaseFormConfiguration()),
  });

  constructor(private formBuilder: FormBuilder, private accountContactsService: AccountContactsService) {}

  ngOnInit(): void {
    if (!this.contactType && this.contact?.type) {
      this.contactType = this.contact.type;
    }
    this.contactForm.generateForm(this.contact);
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  save() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const contact = this.form.getRawValue();
      if (contact.id) {
        contact.id = null;
        if (contact.address) {
          contact.address.id = null;
        }
        contact['recordVersion'] = this.contact.recordVersion;

        if (this.sourceName === 'account') {
          this.accountContactsService
            .updateContact(this.accountId, this.contact.id, contact)
            .subscribe(result => this.handler(result));
        } else if ('crm') {
          this.handler(contact);
        }
      } else {
        if (this.sourceName === 'account') {
          this.accountContactsService.createContact(this.accountId, contact).subscribe(result => this.handler(result));
        } else {
          this.handler(contact);
        }
      }
    }
  }

  customerFormGenerated() {
    if (!this.contact) {
      this.form.get('type').patchValue(this.contactType);
      this.form.get('address').get('type').patchValue(this.contactType);
    }
  }
}
