<ng-container [formGroup]="parentGroup">
  <ng-select
    bindLabel="option[labelOption]"
    [class]="selectClass"
    [id]="id"
    [attr.disabled]="disabled"
    [formControlName]="controlName"
    [clearable]="false"
    [clearOnBackspace]="false"
    [searchable]="false"
    [multiple]="multipleSelect"
    (change)="selectionChange($event)">
    <ng-option *ngIf="showEmptyOption && !multipleSelect" [value]="emptyValue">
      {{ (emptyLocalizedKey ? emptyLocalizedKey : '') | translate }}
    </ng-option>
    <ng-option *ngIf="multipleSelect" [value]="null" (click)="handleSelectAll()">Select all</ng-option>
    <ng-option
      *ngFor="let option of options"
      [value]="valueOption ? option[valueOption] : option"
      (change)="change(option)">
      {{
        !disableOptionsTranslate && inputType !== 'codebook'
          ? ((labelOption ? option[labelOption] : option) | translate)
          : labelOption
          ? option[labelOption]
            ? option[labelOption]
            : option[valueOption]
          : option
      }}
    </ng-option>
  </ng-select>
  <app-input-errors [control]="parentGroup.controls[controlName]" [translationPrefix]="translationPrefix">
  </app-input-errors>
</ng-container>
