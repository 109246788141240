import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BlockTemplateComponent, CurrentLocaleService, ProductService } from '@btl/btl-fe-wc-common';
import { ProductFilterComponent } from 'app/components/page/product-filter/product-filter.component';
import { PaginationComponent } from 'app/components/page/pagination/pagination.component';
import { CategoryTypeEnum, ProductFilter } from 'app/models/product-filter';
import { PagedProductInfosDto, ProductDetailDto } from '@btl/order-bff';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductListingViewType } from '../product-listing/product-listing-view-type.type';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import _ from 'lodash';
import { WcOrderingService } from '@service/wc-ordering.service';

/**
 * ProductListingOldComponent is a component responsible for listing products. It can also display a filter (if enableFiltering is true) or
 * paging (if enablePaging is true).
 *
 * There are two possible view types (viewType): grid, and list. Listed products can be grouped (enableGrouping).
 *
 * If user filtering is not enabled, it is necessary to pass a filter (filter). It is also possible to specify some context of the listing
 * (contextCategory, context). "Context" data always override filter (regardless if explicit or derived from filter component).
 */
@Component({
  selector: 'app-product-listing-old',
  templateUrl: './product-listing-old.component.html',
})
export class ProductListingOldComponent implements OnInit, AfterViewInit {
  //region Data:

  /**
   * Product search results (matching the current filter) displayed on the current page.
   */
  pagedProductInfosDto: PagedProductInfosDto;

  //region IO:

  /**
   * A flag specifying if user filtering is visible.
   */
  @Input()
  enableFiltering: boolean = true;

  /**
   * A flag specifying if user paging is visible.
   */
  @Input()
  enablePaging: boolean = true;

  /**
   * A flag specifying if product grouping is active.
   */
  @Input()
  enableGrouping: boolean = false;

  /**
   * The product listing view type.
   */
  @Input()
  viewType: ProductListingViewType;

  @Input()
  showEmptyOption = true;

  @Input()
  selectedValue;

  /**
   * A context category. Only products of this category (or subcategories) are displayed. This category overrides product filter (if
   * present).
   */
  @Input()
  contextCategory: CategoryTypeEnum;

  /**
   * Context data for product listing
   *
   * TODO: Implement in filtering.
   */
  @Input()
  context: any;

  /**
   * The product filter.
   */
  @Input()
  filter: ProductFilter;

  @Input()
  parentInstanceIds: string;

  @Input()
  parentProductCode: string;

  @Input()
  parentProductId: string;

  @Input()
  partyId: string;

  /**
   * The ID of the party of the original product. This is only relevant for tariff change.
   */
  @Input()
  originalProductPartyId: string;

  /**
   * The original product. This is only relevant for tariff change.
   */
  @Input()
  originalProduct: ProductDetailDto;

  @Input()
  customerAccountId: string;

  @Output()
  readonly selectionChanged: EventEmitter<String> = new EventEmitter();

  //region Children:

  /**
   * A component filtering displayed products. If user filtering is disabled (enableFiltering is false), it is null.
   */
  @ViewChild(ProductFilterComponent, { static: false })
  filterComponent: ProductFilterComponent;

  /**
   * A component paging displayed products. If user paging is disabled (enablePaging is false), it is null.
   */
  @ViewChild(PaginationComponent, { static: false })
  paginationComponent: PaginationComponent;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  productListingViewTypes = ProductListingViewType;
  loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private currentLocaleService: CurrentLocaleService,
    private orderingService: WcOrderingService
  ) {
    // The component will reinitialize on every URL change:
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  //region Life-cycle:

  ngOnInit(): void {
    this.validateInputs();
  }

  ngAfterViewInit(): void {
    if (this.enableFiltering && this.filterComponent) {
      this.filter = this.filterComponent.filter;
    }

    if (this.contextCategory) {
      this.filter.attributes.categoryId = this.contextCategory;
    }

    this.loadProducts(this.filter);
    this.currentLocaleService.currentLocaleChange.subscribe(() => {
      this.loadProducts(this.filter);
    });
  }

  //region Event handling:

  /**
   * Handle changes on the filtering component.
   *
   * @param {ProductFilter} filter The product filter.
   */
  public handleFilterChange(filter: ProductFilter): void {
    this.loadProducts(filter);
    this.paginationComponent.onPage(1);
  }

  //region Helpers:

  /**
   * Validate component inputs.
   */
  private validateInputs(): void {
    if (this.enableFiltering === null || this.enableFiltering === undefined) {
      throw new Error('The enableFiltering parameter must have non-null value.');
    }
    if (!this.enableGrouping) {
      throw new Error('The enableGrouping parameter must have non-null value.');
    }
    if (!this.enablePaging) {
      throw new Error('The enablePaging parameter must have non-null value.');
    }
    if (!this.viewType) {
      throw new Error('The viewType parameter must have non-null value.');
    }
    if (this.enableFiltering === false && !this.filter) {
      throw new Error('The filter parameter must have non-null value if filtering is disabled.');
    }
  }

  /**
   * Load products from BFF by the given filter and store them in {@link products}.
   *
   * @param {ProductFilter} filter The product filter.
   */
  private loadProducts(filter: ProductFilter): void {
    this.blockUIElement.start();
    this.loading = true;
    this.orderingService.getCurrentOrder().subscribe(() => {
      const handlePagedProductInfosDto = (productSearchResults: PagedProductInfosDto): void => {
        this.pagedProductInfosDto = productSearchResults;
      };
      if (this.tariffChange && !_.isNil(this.originalProduct)) {
        this.productService
          .getAlternativeProductsByFilter(filter, this.originalProduct.productCode, this.originalProductPartyId)
          .pipe(finalize(this.finalizeLoadingProducts))
          .subscribe(handlePagedProductInfosDto);
      } else {
        this.productService
          .getProductsByFilter(filter, [this.parentInstanceIds], this.parentProductCode, this.partyId)
          .pipe(finalize(this.finalizeLoadingProducts))
          .subscribe(handlePagedProductInfosDto);
      }
    });
  }

  shouldDisplayNoProductsText(): boolean {
    return this.loading === false && (_.isNil(this.pagedProductInfosDto) || _.isEmpty(this.pagedProductInfosDto.data));
  }

  goToPage(pageNo: number): void {
    this.filter.paging.page = pageNo;
    this.loadProducts(this.filter);
  }

  onNext(): void {
    this.filter.paging.page++;
    this.loadProducts(this.filter);
  }

  onPrev(): void {
    this.filter.paging.page--;
    this.loadProducts(this.filter);
  }

  pageSizeChanged(pageSize: number): void {
    this.filter.paging.page = 1;
    this.filter.paging.pageSize = pageSize;
    this.loadProducts(this.filter);
  }

  changeSelection($event) {
    this.selectionChanged.emit($event.target.value);
  }

  /**
   * Get tariff change status.
   */
  get tariffChange(): boolean {
    const tariffChange = !_.isNil(this.originalProduct) && !_.isNil(this.originalProductPartyId);
    return tariffChange;
  }

  finalizeLoadingProducts = () => {
    this.loading = false;
    this.blockUIElement.stop();
  };
}
