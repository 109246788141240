import { Component, OnInit } from '@angular/core';
import { SearchEntityDataType } from '../ecare-search/ecare-search-filter/ecare-search-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCustomerPopupComponent } from './create-customer-popup/create-customer-popup.component';

@Component({
  selector: 'app-ecare-homepage',
  templateUrl: './ecare-homepage.component.html',
  styleUrls: ['./ecare-homepage.component.scss'],
})
@EnableDynamicLoading({ customName: EcareHomepageComponent.PAGE_ID })
export class EcareHomepageComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareHomepageComponent';

  pageId(): string {
    return EcareHomepageComponent.PAGE_ID;
  }

  createCustomer = false;
  isModuleBffCrmEnabled = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private ngbModal: NgbModal
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.isModuleBffCrmEnabled = bffCrmEnabled));
  }

  handleSearch(searchData: SearchEntityDataType) {
    this.navigateSibling('EcareSearchComponent', null, false, { state: { searchData: searchData } });
  }

  showAllTasks(cuRefNo: string) {
    this.navigateSibling('EcareTasksComponent', { cuRefNo: cuRefNo });
  }

  createCustomerPopup() {
    const modalRef = this.ngbModal.open(CreateCustomerPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const createCustomerComponent = <CreateCustomerPopupComponent>modalRef.componentInstance;
    createCustomerComponent.dialogRef = modalRef;
  }
}
