import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductDetailDto } from '@btl/order-bff';

@Component({
  selector: 'app-delivery-method, [app-delivery-method]',
  templateUrl: './delivery-method.component.html',
})
export class DeliveryMethodComponent {
  @Input() deliveryMethods: Array<ProductDetailDto>;
  @Input() changeDeliveryProduct: Function;
  @Input() public form: FormGroup;
}
