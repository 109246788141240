import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ecare-page-layout',
  templateUrl: './ecare-page-layout.component.html',
})
export class EcarePageLayoutComponent {
  @Input() title: string;
  @Input() isOneColumn: boolean = false;
  @Input() showBackButton: boolean = false;
  @Input() showBreadcrumbs: boolean = true;
  @Input() columnTemplate: '1fr' | '1fr 1fr' | '2fr 1fr' | '1fr 2fr' | '1fr 1fr 1fr' | '1fr 2fr 1fr';
}
